import React from 'react';
import styled from 'styled-components';
import { fullRentalPropType, officePoliciesPropType } from 'common/propTypes';
import PropTypes from 'prop-types';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { Collapsible } from 'ready/components';
import { ContentWrapper, Paragraph } from 'scenes/shared/InfoSidebar/components';
import TimeConfigurationList from './TimeConfigurationList';

const StyledContentWrapper = styled(ContentWrapper)`
  align-items: flex-start;
`;

const CheckIn = ({ t, rental, officePolicies }) => {
  return (
    <Collapsible title={t.checkInTitle} data-qa="Tab:Collapsible--checkIn">
      <StyledContentWrapper minHeight="0">
        <ContentWithLabel label={t.minCheckInTime}>
          <TimeConfigurationList
            times={[
              { hour: rental.office.min_check_in_time, icon: TimeConfigurationList.icons.v2.CITY, hint: t.officeLevel },
              { hour: rental.min_check_in_time, icon: TimeConfigurationList.icons.v2.HOME, hint: t.propertyLevel },
            ]}
          />
        </ContentWithLabel>
        <ContentWithLabel label={t.maxCheckInTime}>
          <TimeConfigurationList
            times={[
              { hour: rental.max_check_in_time, icon: TimeConfigurationList.icons.v2.HOME, hint: t.propertyLevel },
            ]}
          />
        </ContentWithLabel>
        <FullWidthContentWithLabel label={t.checkInProcess}>
          {officePolicies.check_in_process_comment && (
            <Paragraph
              element="div"
              dangerouslySetInnerHTML={{
                __html: officePolicies.check_in_process_comment,
              }}
            />
          )}
        </FullWidthContentWithLabel>
      </StyledContentWrapper>
    </Collapsible>
  );
};

CheckIn.propTypes = {
  t: PropTypes.shape({
    checkInTitle: PropTypes.string.isRequired,
    minCheckInTime: PropTypes.string.isRequired,
    officeLevel: PropTypes.string.isRequired,
    propertyLevel: PropTypes.string.isRequired,
    maxCheckInTime: PropTypes.string.isRequired,
    checkInProcess: PropTypes.string.isRequired,
  }),
  rental: fullRentalPropType,
  officePolicies: officePoliciesPropType,
};

export default CheckIn;
