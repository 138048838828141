import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { conversationPropType, guestPropType } from 'common/propTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getGuestData } from 'selectors/guest';
import { getIsFilterSidebarOpen } from 'selectors/view';
import { changeIsConversationListOpen, changeIsInfoSidebarOpen } from 'ducks/view';

import { Button, Text } from 'ready/components';

import { changeSortRouteParam } from 'browserHistory';
import { fetchMessagesRequested } from 'ducks/messages';
import { getConversationData } from 'selectors/conversation';
import FlagConversationButton from 'components/FlagConversation/FlagConversationButton';

const infoSidebarClosedStyle = ({ isFilterSidebarOpen }) =>
  !isFilterSidebarOpen &&
  css`
    grid-area: top-bar-right-collapsed;
    justify-self: flex-end;
  `;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 4.8rem;
  padding: ${props => `0 ${props.theme.spacing.m}`};
  ${infoSidebarClosedStyle}
  width: 100%;
`;

const TwoButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  ${infoSidebarClosedStyle}
`;

const ConversationTopBar = ({
  isFilterSidebarOpen,
  guest,
  className,
  dispatchChangeIsConversationListOpen,
  dispatchFetchMessagesRequested,
  conversation,
  dispatchChangeIsInfoSidebarOpen,
}) => {
  return (
    <Wrapper className={className} isFilterSidebarOpen={isFilterSidebarOpen}>
      <Button
        onClick={() => {
          dispatchChangeIsConversationListOpen();
          changeSortRouteParam('');
        }}
        icon={{ icon: Button.icon.icons.v2.ARROW_BACK }}
        size={Button.sizes.L}
        clean
        inline
      />
      <Text size={Text.sizes.XL}>{guest.first_name}</Text>
      <TwoButtonWrapper>
        {conversation && conversation.rentals[0].office.enabled_conversation_flags.length > 0 && (
          <FlagConversationButton />
        )}
        <Button
          disabled={!conversation}
          onClick={dispatchFetchMessagesRequested}
          icon={{ icon: Button.icon.icons.v2.REFRESH }}
          size={Button.sizes.L}
          clean
          inline
        />
        <Button
          onClick={dispatchChangeIsInfoSidebarOpen}
          icon={{ icon: Button.icon.icons.v2.BURGER }}
          size={Button.sizes.L}
          clean
          inline
        />
      </TwoButtonWrapper>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  guest: getGuestData(state),
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
  dispatchChangeIsConversationListOpen: changeIsConversationListOpen,
  conversation: getConversationData(state),
});

const mapDispatchToProps = {
  dispatchChangeIsConversationListOpen: changeIsConversationListOpen,
  dispatchFetchMessagesRequested: fetchMessagesRequested,
  dispatchChangeIsInfoSidebarOpen: changeIsInfoSidebarOpen,
};

ConversationTopBar.propTypes = {
  guest: guestPropType.isRequired,
  isFilterSidebarOpen: PropTypes.bool,
  className: PropTypes.string,
  dispatchChangeIsConversationListOpen: PropTypes.func.isRequired,
  dispatchFetchMessagesRequested: PropTypes.func.isRequired,
  conversation: conversationPropType,
  dispatchChangeIsInfoSidebarOpen: PropTypes.func.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ConversationTopBar);
