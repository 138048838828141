import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';
import { TextOrLink } from 'components';
import { Text } from 'ready/components';

import { replaceInUrl } from 'ready/utils';
import { links } from 'constants.js';
import { SHORT_DATE_FORMAT } from 'ready/utils/constants';
import { dateTimePropType } from 'ready/utils/propTypes';
import Chip from 'components/Chip/Chip';

export const Card = styled.div`
  border-radius: ${props => props.theme.borderRadius.m};
  border: ${props => props.theme.app.border.default};
  box-shadow: ${props => props.theme.boxShadow.s};
  padding-top: ${props => props.theme.spacing.m};
`;

const Issue = ({ id, category, parent_categories, creation_comment, origin, created_by, created_at, status, t }) => {
  const issuePath = replaceInUrl({
    url: links.ISSUE_DETAIL,
    placeholder: '{id}',
    value: id,
  });

  return (
    <Card key={id} data-qa="Issue:Card">
      <ContentWrapper>
        <ContentWithLabel label={t.issue} data-qa="Issue:ContentWithLabel__wrapper--id">
          <div>
            <TextOrLink href={issuePath} target="_blank">
              {id}
            </TextOrLink>{' '}
            <Chip label={status} />
          </div>
        </ContentWithLabel>
        <ContentWithLabel label={t.category} data-qa="Issue:ContentWithLabel--category">{`${parent_categories.join(
          ' > ',
        )} > ${category}`}</ContentWithLabel>
        <ContentWithLabel label={t.comment} data-qa="Issue:ContentWithLabel__wrapper--creation_comment">
          <Text dangerouslySetInnerHTML={{ __html: creation_comment }} />
        </ContentWithLabel>
        <FullWidthContentWithLabel label={t.origin}>{origin}</FullWidthContentWithLabel>
        <ContentWithLabel label={t.openedBy}>{created_by}</ContentWithLabel>
        <ContentWithLabel label={t.openedOn} data-qa="Issue:ContentWithLabel--created_at">
          {moment(created_at).format(SHORT_DATE_FORMAT)}
        </ContentWithLabel>
      </ContentWrapper>
    </Card>
  );
};

Issue.propTypes = {
  id: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  parent_categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  creation_comment: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  created_by: PropTypes.string.isRequired,
  created_at: dateTimePropType.isRequired,
  status: PropTypes.string.isRequired,
  t: PropTypes.shape({
    issue: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    openedBy: PropTypes.string.isRequired,
    openedOn: PropTypes.string.isRequired,
  }).isRequired,
};

export default Issue;
