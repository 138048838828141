import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { sanitize } from 'dompurify';
import { FormattedMessage } from 'react-intl';

import { SHORT_TIME_FORMAT, SHORT_DATE_FORMAT } from 'ready/utils/constants';
import { Text, Icon, Button } from 'ready/components';

import { isHTML, splitTextByLineBreak } from 'utils/html';

import { messagePropType, inquiryPropShape } from 'common/propTypes';
import { messageTypes } from 'constants.js';

const StyleText = styled(Text)`
  min-height: ${({ theme }) => theme.typography.text.m['line-height']};
`;

const SpecialOfferWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${props => props.theme.spacing.xs};
`;

const StyledTextLink = styled(StyleText)`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  user-select: none;
`;

const sanitizedMarkup = content => ({ __html: sanitize(content) });

const Content = ({ message, specialOffer, onEdit, t, dispatchOpenReservationAlterationEditForm }) => {
  if (message.reservation_alteration)
    return (
      <>
        <StyleText>{message.content} - </StyleText>
        <StyledTextLink onClick={() => dispatchOpenReservationAlterationEditForm(message.reservation_alteration)}>
          {t.manage}
        </StyledTextLink>
      </>
    );
  if (isHTML(message.content)) return <div dangerouslySetInnerHTML={sanitizedMarkup(message.content)} />;
  if (specialOffer && message.category === messageTypes.SPECIAL_OFFER)
    return (
      <FormattedMessage
        id="SPECIAL_OFFER.MESSAGE"
        values={{
          send_date: moment(specialOffer.start_date).format(SHORT_DATE_FORMAT),
          price: specialOffer.price || 'Not set',
          currency: specialOffer.native_currency || 'EUR',
          expire_date: moment(specialOffer.expires_at).format(SHORT_DATE_FORMAT),
          expire_time: moment(specialOffer.expires_at).format(SHORT_TIME_FORMAT),
        }}
      >
        {txt => (
          <SpecialOfferWrapper data-qa="Content:__specialOfferWrapper">
            <Icon icon={Icon.icons.GIFT} data-qa="Content:Icon" />
            <StyleText element="p" data-qa="Content:Text">
              {txt}
            </StyleText>
            <Button variation={Button.variations.OUTLINE} size={Button.sizes.S} onClick={onEdit}>
              {t.editSpecialOffer}
            </Button>
          </SpecialOfferWrapper>
        )}
      </FormattedMessage>
    );
  return splitTextByLineBreak(message.content).map((text, i) => (
    <StyleText key={i} element="p">
      {text}
    </StyleText>
  ));
};
Content.propTypes = {
  message: messagePropType.isRequired,
  onEdit: PropTypes.func.isRequired,
  specialOffer: inquiryPropShape.special_offer,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
  t: PropTypes.shape({
    editSpecialOffer: PropTypes.string.isRequired,
  }).isRequired,
};

export default Content;
