import styled from 'styled-components';
import { Button } from 'ready/components';

export { default as SectionHeading } from './SectionHeading';
export { default as CategoryList } from './CategoryList';
export { default as MultiSelectFilter } from './MultiSelectFilter';
export { default as MoreFilters } from './MoreFilters/MoreFilters';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  padding: ${props => `0 ${props.theme.spacing.m} ${props.theme.spacing.m} ${props.theme.spacing.m}`};
  overflow-y: auto;
`;

export const CenteredContentWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: ${props => props.theme.spacing.l};
  justify-content: center;
  height: 100%;
  grid-column: 1 / span 2;
`;

export const FiltersWrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.m};
`;

export const CategoryListWrapper = styled.div`
  margin: 0 -${props => props.theme.spacing.m};
`;

export const MoreFiltersButton = styled(Button)`
  margin-bottom: ${props => props.theme.spacing.xxs};
`;
