import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getMessages } from 'selectors/messages';
import { fetchMoreMessagesRequested } from 'ducks/messages';
import { getConversation } from 'selectors/conversation';
import { getBookingSpecialOffer } from 'selectors/booking';

import List from './List/List';
import LoadingIndicator from 'ready/components/LoadingIndicator/LoadingIndicator';
import { RequestError } from 'components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import usePrevious from 'hooks/usePrevious';
import { messagesPropTypeWithState, conversationPropTypeWithState, inquiryPropShape } from 'common/propTypes';
import { changeSpecialOfferFormVisibility } from 'ducks/specialOffer';
import { getAttachments } from 'selectors/attachments';
import { changeAttachmentsGalleryToggler, changeAttachmentsGalleryIndex } from 'ducks/attachments';
import { openReservationAlterationEditForm } from 'ducks/conversationDrawer';

const MessageList = ({
  messages,
  conversation,
  dispatchFetchMoreMessagesRequested,
  dispatchSpecialOfferFormChangeVisibility,
  ...props
}) => {
  if (messages.isFetching) return <LoadingIndicator />;
  if (messages.didInvalidate) return <RequestError errors={messages.errors} />;

  if (!conversation.data) return false;

  const scrollRef = React.createRef();
  const previousMessagesLength = usePrevious(messages.data.length);
  useEffect(() => {
    const messagesLengthDiff = messages.data.length - previousMessagesLength;
    if (messagesLengthDiff === 1) {
      const el = scrollRef.current;
      el.scroll({
        left: 0,
        top: el.scrollHeight,
      });
    }
  }, [messages.data.length]);

  return (
    <List
      {...props}
      scrollRef={scrollRef}
      hasNextPage={messages.page && !!messages.page.nextLink}
      messages={messages.data}
      conversation={conversation}
      hasError={messages.page.didInvalidate}
      reversed
      onLoadMore={dispatchFetchMoreMessagesRequested}
      onEdit={() => dispatchSpecialOfferFormChangeVisibility('visible', true)}
    />
  );
};

MessageList.propTypes = {
  messages: messagesPropTypeWithState.isRequired,
  conversation: conversationPropTypeWithState.isRequired,
  specialOffer: inquiryPropShape.special_offer,
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
    unknownSenderName: PropTypes.string.isRequired,
    staffSenderPlaceholder: PropTypes.string.isRequired,
    attachments: PropTypes.string.isRequired,
    editSpecialOffer: PropTypes.string.isRequired,
  }).isRequired,
  dispatchFetchMoreMessagesRequested: PropTypes.func.isRequired,
  dispatchSpecialOfferFormChangeVisibility: PropTypes.func.isRequired,
  conversationAttachments: PropTypes.object.isRequired,
  dispatchShowAttachmentsGallery: PropTypes.func.isRequired,
  dispatchSetAttachmentsGalleryIndex: PropTypes.func.isRequired,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  messages: getMessages(state),
  conversation: getConversation(state),
  specialOffer: getBookingSpecialOffer(state),
  conversationAttachments: getAttachments(state),
});

const mapDispatchToProps = {
  dispatchFetchMoreMessagesRequested: fetchMoreMessagesRequested,
  dispatchSpecialOfferFormChangeVisibility: changeSpecialOfferFormVisibility,
  dispatchShowAttachmentsGallery: changeAttachmentsGalleryToggler,
  dispatchSetAttachmentsGalleryIndex: changeAttachmentsGalleryIndex,
  dispatchOpenReservationAlterationEditForm: openReservationAlterationEditForm,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    loadMore: 'TEXT.SHARED.LOAD_MORE',
    loadMoreError: 'CONVERSATIONS.LOAD_MORE_ERROR',
    unknownSenderName: 'CONVERSATION.SENDER.UNKNOWN_GUEST_NAME',
    staffSenderPlaceholder: 'CONVERSATION.SENDER.GENERIC_STAFF',
    attachments: 'CONVERSATION.ATTACHMENTS',
    editSpecialOffer: 'SPECIAL_OFFER.EDIT',
    showMore: 'TEXT.SHARED.SHOW_MORE',
    showLess: 'TEXT.SHARED.SHOW_LESS',
    manage: 'TEXT.SHARED.MANAGE',
  }),
)(MessageList);
