import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Modal from 'ready/components/Modal/Modal';
import { OverlayBackground } from 'components';
import { getColor } from 'ready/styles/helpers.js';
import { variationsCssProps, variations } from 'ready/utils/constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  min-height: 24rem;
  min-width: 22rem;
  text-align: center;
  ${({ marginTop }) => marginTop && `margin-top: 4.3rem`};
`;

export const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 3.6rem;
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  text-align: center;
  font-size: 1.2rem;
  background-color: ${getColor(variations.PRIMARY, variationsCssProps.FADE_BG)};
`;

export const CallOverlay = ({ t, onClose, onMinimize, closeEnabled, minimizeEnabled, children, isRecorded }) => (
  <OverlayBackground>
    {isRecorded && <Banner>{t.recordingWarning}</Banner>}
    <Modal
      data-qa="CallOverlay:Modal"
      size={Modal.sizes.M}
      minimizeEnabled={minimizeEnabled}
      closeEnabled={closeEnabled}
      onMinimize={onMinimize}
      onClose={onClose}
    >
      <Container marginTop={!minimizeEnabled && !closeEnabled} data-qa="CallOverlay:__container">
        {children}
      </Container>
    </Modal>
  </OverlayBackground>
);

CallOverlay.propTypes = {
  t: PropTypes.shape({
    recordingWarning: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  minimizeEnabled: PropTypes.bool,
  closeEnabled: PropTypes.bool,
  children: PropTypes.node,
  isRecorded: PropTypes.bool,
};

CallOverlay.defaultProps = {
  closeEnabled: false,
  minimizeEnabled: false,
};

export default CallOverlay;
