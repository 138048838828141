import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Attachments from './components/Attachments';
import Content from './components/Content';
import Info from './components/Info';

import { variations } from 'ready/utils/constants';
import { withThemeColor } from 'ready/styles/helpers';

import { messagePropType, conversationPropTypeWithState } from 'common/propTypes';
import { messageTypes, messageOrigins } from 'constants.js';
import { Button } from 'ready/components';

const messageVariations = {
  [messageTypes.STAFF]: 't500',
  [messageTypes.GUEST]: variations.WHITE,
  [messageTypes.INTERNAL_NOTE]: variations.YELLOW,
  [messageTypes.SPECIAL_OFFER]: variations.INFO,
  [messageTypes.NOTIFICATION]: variations.PURPLE,
  [messageOrigins.SMS]: variations.BLUE,
  [messageOrigins.TWILIO]: variations.BLUE,
  [messageTypes.RESERVATION_ALTERATION]: variations.GREY,
};

const withFadedBackground = ({ type, category, origin }) => css`
  background-color: ${withThemeColor({
    lighten: 0.45,
    variation: messageVariations[category] || messageVariations[origin] || messageVariations[type] || 'n600',
  })};
`;

const withPaddingLeft = ({ theme }) => css`
  padding-left: ${theme.spacing.xxl};
`;

const withPaddingRight = ({ theme, type }) =>
  type === messageTypes.GUEST &&
  css`
    padding-right: ${theme.spacing.xxl};
  `;

const withNotificationHeight = ({ category, collapsed }) =>
  category === messageTypes.NOTIFICATION &&
  collapsed &&
  css`
    max-height: 15rem;
  `;

const ShowMoreWrapper = styled.div`
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    transparent,
    ${withThemeColor({ lighten: 0.45, variation: variations.PURPLE })} 25%
  );
  bottom: 0;
  display: flex;
  height: 7rem;
  left: 0;
  padding: ${props =>
    `${props.theme.spacing.xl} ${props.theme.spacing.l} ${props.theme.spacing.m} ${props.theme.spacing.l}`};
  position: absolute;
  width: 100%;
`;

const Container = styled.div`
  ${props => withPaddingRight(props) || withPaddingLeft(props)};
`;

const MessageWrapper = styled.div`
  border-radius: ${props => props.theme.borderRadius.m};
  box-shadow: ${props => props.theme.boxShadow.s};
  overflow: hidden;
  padding: ${props => `${props.theme.spacing.m} ${props.theme.spacing.l}`};
  position: relative;
  word-break: break-word;
  ${withFadedBackground};
  ${withNotificationHeight};
`;

const Message = ({
  className,
  specialOffer,
  message,
  conversation,
  t,
  onEdit,
  dispatchOpenReservationAlterationEditForm,
  ...props
}) => {
  const [collapsed, changeCollapsed] = useState(true);
  return (
    <Container className={className} type={message.sender} data-qa="MessageList:Message__container">
      <MessageWrapper origin={message.origin} type={message.sender} category={message.category} collapsed={collapsed}>
        <Content
          specialOffer={specialOffer}
          message={message}
          t={t}
          onEdit={onEdit}
          dispatchOpenReservationAlterationEditForm={dispatchOpenReservationAlterationEditForm}
        />
        <Attachments message={message} label={t.attachments} {...props} />
        {message.category === messageTypes.NOTIFICATION && (
          <ShowMoreWrapper>
            <Button clean active inline onClick={() => changeCollapsed(!collapsed)}>
              {collapsed ? t.showMore : t.showLess}
            </Button>
          </ShowMoreWrapper>
        )}
      </MessageWrapper>
      <Info message={message} conversation={conversation} t={t} />
    </Container>
  );
};

Message.propTypes = {
  className: PropTypes.string,
  message: messagePropType.isRequired,
  specialOffer: Content.propTypes.specialOffer,
  conversation: conversationPropTypeWithState.isRequired,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
  t: PropTypes.shape({
    unknownSenderName: PropTypes.string.isRequired,
    staffSenderPlaceholder: PropTypes.string.isRequired,
    attachments: PropTypes.string.isRequired,
    editSpecialOffer: PropTypes.string.isRequired,
  }),
  onEdit: PropTypes.func.isRequired,
};

export default Message;
