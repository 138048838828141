import moment from 'moment';

export const mapKeysToLowerCase = obj => Object.keys(obj).map(key => key.toLowerCase());
export const upperCaseFirstLetter = text => `${text[0].toUpperCase()}${text.substring(1)}`;

export const replaceInUrl = ({ url, placeholder, value }) => {
  if (!url || !url.match(placeholder) || typeof value === 'undefined') return;
  return url.replace(placeholder, value);
};

export const getFirstDayOfFirstWeekOfMonth = date => {
  const startOfMonth = date
    .clone()
    .startOf('month')
    .startOf('day');
  if (startOfMonth.day() === 0) {
    // if the start of the month is a Sunday, return the previous Monday
    return startOfMonth.day(-6);
  }
  // otherwise return the next Monday
  return startOfMonth.day(1);
};

export const getLastDayOfLastWeekOfMonth = date => {
  const endOfMonth = date
    .clone()
    .endOf('month')
    .startOf('day');
  // if the end of the month is a Sunday, return it
  if (endOfMonth.day() === 0) {
    return endOfMonth;
  }
  // otherwise return the next Sunday
  return endOfMonth.day(7);
};

export const getFirstDayOfWeek = date => {
  const firstDayOfWeek = date.clone().startOf('week');
  if (date.day() === 0) {
    return firstDayOfWeek.day(-6);
  }
  return firstDayOfWeek.day(1);
};

export const getWeekDays = date => {
  const week = [];
  const _date = getFirstDayOfWeek(date);
  while (week.length < 7) {
    week.push(_date.clone());
    _date.add(1, 'day');
  }
  return week;
};

export const getWeeksOfMonth = date => {
  const firstDayOfMonth = getFirstDayOfFirstWeekOfMonth(date);
  const endDayOfMonth = getLastDayOfLastWeekOfMonth(date);
  const weeksOfMonth = [];
  let _date = firstDayOfMonth.clone();
  while (_date.isBefore(endDayOfMonth)) {
    weeksOfMonth.push(getWeekDays(_date));
    _date.add(1, 'week');
  }
  return weeksOfMonth;
};

export const isSameMonth = (date1, date2) => date1.isSame(date2, 'year') && date1.isSame(date2, 'month');
export const isSameDate = (date1, date2) => isSameMonth(date1, date2) && date1.isSame(date2, 'day');

export const getDateTimeWithoutTimezone = dateTime => moment.utc(dateTime, 'YYYY-MM-DDTHH:mm:ss');

export const formatDate = (date, outputFormat, inputFormat = undefined) => {
  if (!date) return date;
  return moment(date, inputFormat).format(outputFormat);
};
