import React from 'react';
import PropTypes from 'prop-types';

import { selectOptionPropType } from 'ready/utils/propTypes';

import { Text, Select } from 'ready/components';
import { FieldGroup } from 'components/Field/Field';

const MultiSelectFilter = ({ name, id = name, options, onChangeSelectedFilter, selected, label, t, ...props }) => (
  <FieldGroup data-qa="MultiSelectFilter:FieldGroup">
    {label && (
      <Text as="label" bold htmlFor={id} data-qa="Field:Text" size={Text.sizes.S}>
        {label}
      </Text>
    )}
    <Select
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      value={selected}
      onChange={selectedOptions => onChangeSelectedFilter(selectedOptions, name)}
      options={options}
      isMulti
      isClearable={false}
      t={t}
      styles={{ control: { width: '100%' }, valueContainer: { width: '50px' } }}
      {...props}
    />
  </FieldGroup>
);

MultiSelectFilter.propTypes = {
  options: PropTypes.arrayOf(selectOptionPropType).isRequired,
  onChangeSelectedFilter: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(selectOptionPropType).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  t: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
  }).isRequired,
};

export default MultiSelectFilter;
