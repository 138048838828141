import { fromJS } from 'immutable';

export const FETCH_SMART_DEVICES_REQUESTED = 'FETCH_SMART_DEVICES_REQUESTED';
export const fetchSmartDevicesRequested = (options = {}) => ({
  type: FETCH_SMART_DEVICES_REQUESTED,
  options,
});

export const FETCH_SMART_DEVICES_SUCCEEDED = 'FETCH_SMART_DEVICES_SUCCEEDED';
export const fetchSmartDevicesSucceeded = smart_devices => ({
  type: FETCH_SMART_DEVICES_SUCCEEDED,
  payload: { smart_devices },
});

export const FETCH_SMART_DEVICES_FAILED = 'FETCH_SMART_DEVICES_FAILED';
export const fetchSmartDevicesFailed = errors => ({
  type: FETCH_SMART_DEVICES_FAILED,
  payload: { errors },
});

export const TOGGLE_SMART_DEVICE_REQUESTED = 'TOGGLE_SMART_DEVICE_REQUESTED';
export const toggleSmartDeviceRequested = deviceId => ({
  type: TOGGLE_SMART_DEVICE_REQUESTED,
  deviceId,
});

export const TOGGLE_SMART_DEVICE_SUCCEEDED = 'TOGGLE_SMART_DEVICE_SUCCEEDED';
export const toggleSmartDeviceSucceeded = smart_devices => ({
  type: TOGGLE_SMART_DEVICE_SUCCEEDED,
  payload: { smart_devices },
});

export const TOGGLE_SMART_DEVICE_FAILED = 'TOGGLE_SMART_DEVICE_FAILED';
export const toggleSmartDeviceFailed = errors => ({
  type: TOGGLE_SMART_DEVICE_FAILED,
  payload: { errors },
});

export const initialState = fromJS({
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  errors: {},
  data: null,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SMART_DEVICES_REQUESTED:
      return state.set('isFetching', true).set('didInvalidate', false);

    case FETCH_SMART_DEVICES_SUCCEEDED:
      return state.set('isFetching', false).set('data', fromJS(payload.smart_devices));

    case FETCH_SMART_DEVICES_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case TOGGLE_SMART_DEVICE_REQUESTED:
      return state
        .set('isUpdating', true)
        .set('didInvalidate', false)
        .set('errors', initialState.get('errors'));

    case TOGGLE_SMART_DEVICE_SUCCEEDED:
      return state.set('isFetching', false).set('data', fromJS(payload.smart_devices));

    case TOGGLE_SMART_DEVICE_FAILED:
      return state
        .set('isUpdating', false)
        .set('didInvalidate', true)
        .update('errors', value => value.set('updating', true).merge(payload.errors));

    default:
      return state;
  }
};
