import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import SearchList from './containers/SearchList';
import ViewItem from './containers/ViewItem';

const Container = styled.div`
  border-top: ${props => props.theme.app.border.default};
  background-color: ${props => props.theme.colors.n100};
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  min-height: 25dvh;
  max-height: 35dvh;
  padding: ${props => props.theme.spacing.m};
`;

export const SavedReplies = ({ match }) => (
  <Container data-qa="Conversation:SaveReplies__container">
    <Switch>
      <Route exact path={match.url} component={SearchList} />
      <Route path={`${match.url}/:savedReplyId`} component={ViewItem} />
    </Switch>
  </Container>
);

SavedReplies.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default SavedReplies;
