import { all, takeLatest, put, call } from 'redux-saga/effects';

import { fetchAttachmentsSuccessed, fetchAttachmentsFailed, FETCH_ATTACHMENTS_REQUESTED } from 'ducks/attachments';
import DataApi from 'api/DataApi';

export function* handleFetchAttachmentsRequested({ payload: { conversationId } }) {
  try {
    const attachments = yield call([DataApi.apiInstance(), 'getAttachments'], conversationId);
    yield put(fetchAttachmentsSuccessed(attachments));
  } catch (e) {
    yield put(fetchAttachmentsFailed(e.errors));
  }
}

export default function* watchAttachments() {
  yield all([takeLatest(FETCH_ATTACHMENTS_REQUESTED, handleFetchAttachmentsRequested)]);
}
