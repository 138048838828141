import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button, Toggle } from 'ready/components';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { conversationPropType, userPropType } from 'common/propTypes';
import { updateConversationRequested } from 'ducks/conversation';
import { getConversationData } from 'selectors/conversation';
import { getUserData } from 'selectors/user';
import { conversationAssigneesPayload } from 'utils/deserializers';
import { variations } from 'ready/utils/constants';
import useMobileView from 'hooks/useMobileView';

const AssignToMe = ({ t, conversation, user, dispatchUpdateConversation }) => {
  const [isChecked, setIsChecked] = useState(false);
  const isMobileView = useMobileView();

  useEffect(() => {
    const isAssignedToMe = conversation && conversation.assignees && conversation.assignees.includes(user.id);
    setIsChecked(isAssignedToMe);
  }, [conversation]);

  return (
    !isMobileView && (
      <Button clean hint={isChecked ? { text: t.hintIsChecked } : { text: t.hintIsNotChecked }}>
        <Toggle
          label={t.assignToMyself}
          variation={variations.SUCCESS}
          onClick={() => {
            const assignees = isChecked ? [] : [user.id];
            dispatchUpdateConversation(conversationAssigneesPayload(assignees), { removeConversationFromList: false });
            setIsChecked(!isChecked);
          }}
          enabled={isChecked}
          data-qa="AssignToMe:Toggle"
        />
      </Button>
    )
  );
};

const mapStateToProps = state => ({
  user: getUserData(state),
  conversation: getConversationData(state),
});

const mapDispatchToProps = {
  dispatchUpdateConversation: updateConversationRequested,
};

AssignToMe.propTypes = {
  user: userPropType.isRequired,
  dispatchUpdateConversation: PropTypes.func.isRequired,
  conversation: conversationPropType,
  t: PropTypes.shape({
    hintIsChecked: PropTypes.string.isRequired,
    hintIsNotChecked: PropTypes.string.isRequired,
    assignToMyself: PropTypes.string.isRequired,
  }),
};

export default compose(
  withMappedTranslations({
    hintIsChecked: 'ASSIGN_TO_ME.HINT_ON_CHECKED',
    hintIsNotChecked: 'ASSIGN_TO_ME.HINT_ON_NOT_CHECKED',
    assignToMyself: 'ASSIGN_TO_ME.ASSIGN_TO_MYSELF',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AssignToMe);
