import { createSelector } from 'reselect';

const _getFilterPresets = state => state.filterPresets;
export const getFilterPresets = createSelector(
  [_getFilterPresets],
  filterPresets => filterPresets.toJS(),
);

export const getFilterPresetsData = createSelector(
  [_getFilterPresets],
  filterPresets => filterPresets.get('data').toJS(),
);
