import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { userPropType } from 'common/propTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getUserData } from 'selectors/user';
import { getIsFilterSidebarOpen } from 'selectors/view';
import { logoutUserRequested } from 'ducks/user';
import { changeIsFilterSidebarOpen } from 'ducks/view';

import { SidebarToggle } from 'components';
import { Button, Avatar, DropdownButton, DropdownList, DropdownListItem } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

const infoSidebarClosedStyle = ({ isFilterSidebarOpen }) =>
  !isFilterSidebarOpen &&
  css`
    grid-area: top-bar-right-collapsed;
    justify-self: flex-end;
  `;

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  justify-content: space-between;
  height: 4.8rem;
  grid-area: top-bar-right;
  grid-template-columns: repeat(2, auto);
  min-width: 28rem;
  padding: ${props => `0 ${props.theme.spacing.m}`};
  ${infoSidebarClosedStyle}
  width: 100%
`;

const FilterSidebarTopBar = ({
  isFilterSidebarOpen,
  user,
  dispatchLogoutUserRequested,
  dispatchChangeIsFilterSidebarOpen,
  t,
  className,
}) => (
  <Wrapper className={className} isFilterSidebarOpen={isFilterSidebarOpen}>
    <Button
      onClick={dispatchChangeIsFilterSidebarOpen}
      icon={{ icon: SidebarToggle.icons.CROSS }}
      size={Button.sizes.L}
      clean
      inline
    />
    <DropdownButton
      horizontalPosition={DropdownButton.horizontalPositions.RIGHT}
      variation={DropdownButton.variations.OUTLINE}
      size={DropdownButton.sizes.L}
      clean
      renderButtonContent={() => <Avatar fallback={user.first_name[0]} />}
      renderDropdownContent={() => (
        <DropdownList>
          <DropdownListItem onClick={dispatchLogoutUserRequested}>{t.logout}</DropdownListItem>
        </DropdownList>
      )}
    />
  </Wrapper>
);

const mapStateToProps = state => ({
  user: getUserData(state),
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
});

const mapDispatchToProps = {
  dispatchLogoutUserRequested: logoutUserRequested,
  dispatchChangeIsFilterSidebarOpen: changeIsFilterSidebarOpen,
};

FilterSidebarTopBar.propTypes = {
  user: userPropType.isRequired,
  isFilterSidebarOpen: PropTypes.bool,
  dispatchLogoutUserRequested: PropTypes.func.isRequired,
  dispatchChangeIsFilterSidebarOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
  t: PropTypes.shape({
    logout: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    logout: 'TOP_BAR.LOGOUT',
    menu: 'TOP_BAR.MENU',
  }),
)(FilterSidebarTopBar);
