import { createBrowserHistory } from 'history';
import {
  getPathnameWithFilters,
  getSavedRepliesFullRoute,
  getConversationsFullRoute,
  getSearchObj,
  splitPathname,
  getSearchQuery,
} from 'utils/location';
import { paths } from 'constants.js';
import { getConversationData } from 'selectors/conversation';
import store from 'store';
import { fetchConversationRequested } from 'ducks/conversation';
import { changeFilters } from 'ducks/filters';
import { getFiltersData } from 'selectors/filters';
import { fetchConversationsRequested } from 'ducks/conversations';
import { sanitizePayload } from 'utils/index';

const history = createBrowserHistory();

/**
 * Change route partially, only conversation id keeping all the rest, filters and sub-routes if conversation id provided
 * Note: it works based on regular expression that only accept url with search query applied
 * @param {Number} conversationId conversation id
 */
export const changeConversationRoute = (conversationId, filters = null) => {
  let path = getConversationsFullRoute(conversationId);
  if (filters) {
    path = getPathnameWithFilters(path.replace(/(\?.*)/, ''), filters);
  }
  history.push(path);
};

export const changeFilterParams = filters => {
  const url = getPathnameWithFilters(window.location.pathname, sanitizePayload(filters));
  history.push(url, { filters });
};

export const changeSavedRepliesRoute = (savedReplyId, action = 'push') => {
  const path = getSavedRepliesFullRoute(savedReplyId);
  history[action](path, { savedReplyId });
};

export const replaceSavedRepliesRoute = id => changeSavedRepliesRoute(id, 'replace');

export const pushToSavedRepliesRoute = () => {
  history.push(`${window.location.pathname}${paths.SAVED_REPLIES}${window.location.search}`);
};

/**
 * Push to absolute conversations path like: /conversations(/[0-9]+){0,}(?.*){0,}
 * @param {Number} conversationId conversation id
 */
export const pushToConversation = conversationId => {
  const search = history.location.search || '';
  const [m1 = paths.APP, m2] = splitPathname(history.location.pathname);
  if (m2 || conversationId) return history.push(`${m1}/${conversationId || m2}${search}`);
  return history.push(`${m1}${search}`);
};

/**
 * Change sort route param to sortBy value
 * @param {String} sortBy constants conversations sort options string key
 */
export const changeSortRouteParam = sortBy => {
  const search = getSearchObj();
  search.sort = sortBy;
  history.push(`${history.location.pathname}${getSearchQuery(search)}`);
};
/**
 * Change search route param to term value
 * @param {String} term search term typed into search bar conversation list header
 */
export const changeSearchRouteParam = term => {
  const search = getSearchObj();
  search.search = term;
  if (!term) delete search.search;
  history.push(`${history.location.pathname}${getSearchQuery(search)}`);
};

// The action is one of PUSH, REPLACE, or POP depending on how the user got to the current URL.
history.listen((location, action) => {
  if (!location || action !== 'POP') return;
  const { pathname = '', search = '' } = location;

  const state = store.getState();
  const previousSearch = getSearchQuery(sanitizePayload(getFiltersData(state)));
  /**
   * If previous location search is different that current one, then
   * change store state filters with filters from current location search
   */
  if (search !== previousSearch) {
    const filters = getSearchObj(search);
    store.dispatch(changeFilters(filters));
    store.dispatch(fetchConversationsRequested());
  }

  const previousConversationId = getConversationData(state);
  const [, conversationId] = splitPathname(pathname);

  if (conversationId !== previousConversationId) store.dispatch(fetchConversationRequested(conversationId));
});

export default history;
