import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Conversation from 'scenes/shared/Conversation/Conversation';
import ConversationList from './scenes/ConversationList/ConversationList';
import InfoSidebar from './scenes/InfoSidebar/InfoSidebar';
import Filters from './scenes/Filters/Filters';
import { getIsFilterSidebarOpen } from 'selectors/view';
import TopBarLeft from 'scenes/shared/TopBar/TopBarLeft';
import TopBarRight from 'scenes/shared/TopBar/TopBarRight';
import TopBarCenter from 'scenes/shared/TopBar/TopBarCenter';
import useInitDataSetup from 'hooks/useInitDataSetup';
import useIsTabActive from 'hooks/useIsTabActive';
import { matchPropType } from 'common/propTypes';

const Wrapper = styled.div`
  height: 100dvh;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: 'top-bar-left top-bar-center top-bar-right-collapsed top-bar-right' 'left-sidebars conversation conversation info-sidebar';
`;

const StyledTopBarLeft = styled(TopBarLeft)`
  border-right: ${props => props.theme.app.border.default};
`;

const StyledTopBarRight = styled(TopBarRight)`
  border-left: ${props => props.theme.app.border.default};
`;

const LeftSidebarWrapper = styled.div`
  display: grid;
  grid-area: left-sidebars;
  grid-template-columns: ${props => (props.isFilterSidebarOpen ? '4rem 16.4rem 27.2rem' : '4rem 23.2rem')};
  grid-template-rows: auto minmax(0, 1fr);
  border-top: ${props => props.theme.app.border.default};
  ${props => filterSidebarOpenStyle(props) || filterSidebarClosedStyle()};
  grid-auto-rows: 0;
`;

const filterSidebarOpenStyle = ({ isFilterSidebarOpen }) =>
  isFilterSidebarOpen &&
  css`
    grid-template-areas: 'filter-toggle filter-header conversation-list-header' 'filter filter conversation-list';
  `;

const filterSidebarClosedStyle = () => css`
  grid-template-areas: 'filter-toggle conversation-list-header' 'conversation-list conversation-list';
`;

const Main = ({ isFilterSidebarOpen, match, dispatch }) => {
  useIsTabActive({ dispatch });
  useInitDataSetup({ dispatch, match, payload: { isFilterSidebarOpen: true, isInfoSidebarOpen: true } });

  return (
    <Wrapper>
      <StyledTopBarLeft />
      <TopBarCenter />
      <StyledTopBarRight />
      <LeftSidebarWrapper isFilterSidebarOpen={isFilterSidebarOpen}>
        <Filters />
        <ConversationList />
      </LeftSidebarWrapper>
      <Conversation />
      <InfoSidebar />
    </Wrapper>
  );
};

Main.propTypes = {
  isFilterSidebarOpen: PropTypes.bool,
  match: matchPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
});

export default connect(mapStateToProps)(Main);
