import React from 'react';
import styled from 'styled-components';

import ActionsContainer from './ActionsContainer';
import ActionButton from './ActionButton';
import MuteAction from './MuteAction';
import Notes from './Notes';
import CallReason from './CallReason';
import Button from 'ready/components/Button/Button';
import Keypad from './Keypad';

import { shouldEnableCloseCallCenter, canForwardCall } from 'utils/businessLogic';
import { twilioConnectionStatuses, connectionTypes, callCenterPages } from 'constants.js';

const StyledButton = styled(Button)`
  margin: ${props => props.theme.spacing.xs} 0;
`;

const Actions = ({ t, data, connection, actions, view, callForwarding, callReasons }) => {
  const { status, isMuted, type, digits } = connection;
  const { conversation, note } = data;

  if (status === twilioConnectionStatuses.READY) {
    return (
      <ActionsContainer>
        <ActionButton icon={ActionButton.icons.CROSS} onClick={actions.closeCall} label={t.cancel} />
        <ActionButton
          icon={ActionButton.icons.PHONE}
          size={ActionButton.sizes.L}
          variation={ActionButton.variations.SUCCESS}
          onClick={actions.placeCall}
          label={t.callGuest}
        />
        <ActionButton icon={ActionButton.icons.CALL_FORWARD} disabled label={t.forward} />
      </ActionsContainer>
    );
  }

  if (status === twilioConnectionStatuses.CALLING) {
    return (
      <ActionsContainer>
        <ActionButton icon={ActionButton.icons.CROSS} onClick={actions.closeCall} label={t.cancel} />
        <ActionButton
          icon={ActionButton.icons.PHONE}
          size={ActionButton.sizes.L}
          variation={ActionButton.variations.ERROR}
          onClick={actions.cancelCall}
          label={t.endCall}
          rotate={135}
        />
        <ActionButton icon={ActionButton.icons.CALL_FORWARD} disabled label={t.forward} />
      </ActionsContainer>
    );
  }

  if (status === twilioConnectionStatuses.RINGING) {
    return (
      <ActionsContainer>
        <ActionButton icon={ActionButton.icons.CROSS} onClick={actions.rejectCall} label={t.reject} />
        <ActionButton
          icon={ActionButton.icons.PHONE}
          size={ActionButton.sizes.L}
          variation={ActionButton.variations.SUCCESS}
          onClick={actions.answerCall}
          label={t.answer}
          ringing
        />
        <ActionButton icon={ActionButton.icons.CALL_FORWARD} disabled label={t.forward} />
      </ActionsContainer>
    );
  }

  if (status === twilioConnectionStatuses.IN_PROGRESS) {
    if (view.page === callCenterPages.KEYPAD) {
      return (
        <Keypad
          t={t}
          digits={digits}
          onClose={() => actions.changePage()}
          onDigitClick={actions.sendDigit}
          onEraseClick={actions.eraseDigit}
        />
      );
    }
    return (
      <div>
        <ActionsContainer>
          <MuteAction t={t} onClick={actions.muteCall} muted={isMuted} />
          <ActionButton
            icon={ActionButton.icons.PHONE}
            size={ActionButton.sizes.L}
            variation={ActionButton.variations.ERROR}
            onClick={actions.endCall}
            label={t.endCall}
            rotate={135}
          />
          <ActionButton
            disabled={!canForwardCall({ data, connection, callForwarding })}
            icon={ActionButton.icons.CALL_FORWARD}
            label={t.forward}
            onClick={() => actions.changePage(callCenterPages.FORWARD_LIST)}
          />
        </ActionsContainer>
        {type === connectionTypes.OUTBOUND && (
          <StyledButton
            icon={{ icon: Button.icon.icons.GRID }}
            size={Button.sizes.S}
            onClick={() => actions.changePage(callCenterPages.KEYPAD)}
            inline
            variation={Button.variations.OUTLINE}
          >
            {t.keypad}
          </StyledButton>
        )}
        {!!conversation && (
          <Notes
            note={data.note}
            onSubmit={actions.submitNote}
            onChange={actions.changeNote}
            placeholder={t.notesPlaceholder}
            buttonLabel={t.notesSend}
          />
        )}
      </div>
    );
  }

  if (status === twilioConnectionStatuses.COMPLETED) {
    const disabled = !shouldEnableCloseCallCenter(data, connection, view);
    return (
      <div>
        {type !== connectionTypes.OUTBOUND && (
          <CallReason t={t} onChange={actions.changeCallReason} callReasons={callReasons} />
        )}
        {!!conversation && type !== connectionTypes.OUTBOUND && (
          <StyledButton
            variation={StyledButton.variations.PRIMARY}
            size={StyledButton.sizes.S}
            onClick={actions.goBack}
            disabled={disabled}
          >
            {t.backToPreviousConversation}
          </StyledButton>
        )}
        <StyledButton
          variation={StyledButton.variations.OUTLINE}
          size={StyledButton.sizes.S}
          onClick={actions.closeCall}
          disabled={disabled}
        >
          {t.stayAtThisConversation}
        </StyledButton>
        {!!conversation && (
          <Notes
            note={note}
            onSubmit={actions.submitNote}
            onChange={actions.changeNote}
            placeholder={t.notesPlaceholder}
            buttonLabel={t.notesSend}
          />
        )}
      </div>
    );
  }

  return false;
};

export default Actions;
