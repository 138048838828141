import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

import Header from './components/Header';
import { datePropType } from 'ready/utils/propTypes';
import CalendarDays from './components/CalendarDays';

const Container = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.xs};
`;

const getStartOfDay = date => moment.utc(date).startOf('day');

const Datepicker = ({ startDate, selectedDate, className, onDatePick }) => {
  const [mDate, setMDate] = useState(getStartOfDay(startDate));
  const [mSelectedDate, setMSelectedDate] = useState((selectedDate && getStartOfDay(selectedDate)) || null);

  useEffect(() => {
    setMDate(getStartOfDay(startDate));
  }, [startDate]);

  useEffect(() => {
    setMSelectedDate((selectedDate && getStartOfDay(selectedDate)) || null);
  }, [selectedDate]);

  const handleSelectDate = useCallback(date => {
    setMDate(date.clone());
    setMSelectedDate(date.clone());
    onDatePick(date);
  }, []);

  const handleClickNext = useCallback(() => {
    setMDate(mDate.clone().add(1, 'month'));
  }, [mDate]);

  const handleClickPrev = useCallback(() => {
    setMDate(mDate.clone().subtract(1, 'month'));
  }, [mDate]);

  return (
    <Container
      className={className}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Header onClickNext={handleClickNext} onClickPrev={handleClickPrev}>
        {mDate.format('MMMM YYYY')}
      </Header>
      <CalendarDays date={mDate} selectedDate={mSelectedDate} onSelectDay={handleSelectDate} />
    </Container>
  );
};

Datepicker.propTypes = {
  className: PropTypes.string,
  startDate: datePropType,
  selectedDate: datePropType,
  onDatePick: PropTypes.func.isRequired,
};

Datepicker.defaultProps = {
  startDate: moment.utc().startOf('day'),
};

export default Datepicker;
