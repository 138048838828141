import PropTypes from 'prop-types';

import { mapKeysToLowerCase } from './';
import { colors } from 'ready/styles/theme';

export const variationPropType = PropTypes.oneOf(mapKeysToLowerCase(colors));
export const sizePropType = sizes => PropTypes.oneOf(mapKeysToLowerCase(sizes));

const dateRegex = new RegExp(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/);
const dateTimeRegex = new RegExp(
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T(((0|1)[0-9])|2[0-4]):([0-5][0-9]):([0-5][0-9]))(.([0-9]{6}))?(Z|\+((((0|1)[0-9])|2[0-4]):([0-5][0-9])))?$/,
);
const timeRegex = new RegExp(/(((0|1)[0-9])|2[0-4]):([0-5][0-9]):([0-5][0-9])/);

const datePropValidation = (props, propName, componentName) => {
  if (props[propName] && !dateRegex.test(props[propName])) {
    return new Error(`Invalid prop ${propName} of ${props[propName]} supplied to ${componentName}. Validation failed.`);
  }
};

const dateTimePropValidation = (props, propName, componentName) => {
  if (props[propName] && !dateTimeRegex.test(props[propName])) {
    return new Error(`Invalid prop ${propName} of ${props[propName]} supplied to ${componentName}. Validation failed.`);
  }
};

const timePropValidation = (props, propName, componentName) => {
  if (props[propName] && !timeRegex.test(props[propName])) {
    return new Error(`Invalid prop ${propName} of ${props[propName]} supplied to ${componentName}. Validation failed.`);
  }
};

const dateTimeProp = dateTimePropValidation;
const dateProp = datePropValidation;
const timeProp = timePropValidation;

dateTimeProp.isRequired = (props, propName, componentName) => {
  if (!props[propName]) {
    return new Error(`Prop ${propName} supplied to ${componentName} is required. Validation failed.`);
  }

  return dateTimePropValidation(props, propName, componentName);
};

dateProp.isRequired = (props, propName, componentName) => {
  if (!props[propName]) {
    return new Error(`Prop ${propName} supplied to ${componentName} is required. Validation failed.`);
  }

  return datePropValidation(props, propName, componentName);
};

timeProp.isRequired = (props, propName, componentName) => {
  if (!props[propName]) {
    return new Error(`Prop ${propName} supplied to ${componentName} is required. Validation failed.`);
  }

  return timePropValidation(props, propName, componentName);
};

export const dateTimePropType = dateTimeProp;
export const datePropType = dateProp;
export const timePropType = timeProp;

export const childrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

export const selectOptionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
});
export const selectOptionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }),
);
