import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'ready/components/typography';

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing.xxxs};
  min-width: 50%;
  margin-bottom: ${props => props.theme.spacing.l};
  word-break: break-word;
  white-space: pre-wrap;
`;

const hasChildren = children => children === 0 || (children && !(Array.isArray(children) && children.length === 0));
const hasReactChildren = children => React.isValidElement(children) || React.Children.toArray(children).length > 1;

const ContentWithLabel = ({ children, label, ifBlank, className, ...props }) => {
  const isEmpty = !hasChildren(children);
  if (isEmpty && ifBlank === 'hide') return false;

  const _hasReactChildren = hasReactChildren(children);
  const emptyContent = ifBlank === 'dash' && <Text>-</Text>;
  const content = _hasReactChildren ? children : <Text data-qa="ContentWithLabel:Text--children">{children}</Text>;

  return (
    <Wrapper className={className} {...props}>
      <Text size={Text.sizes.S} bold data-qa="ContentWithLabel:Text--label">
        {label}
      </Text>
      {isEmpty ? emptyContent : content}
    </Wrapper>
  );
};

ContentWithLabel.ifBlank = {
  DASH: 'dash',
  HIDE: 'hide',
};

ContentWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ifBlank: PropTypes.oneOf(Object.values(ContentWithLabel.ifBlank)),
  className: PropTypes.string,
};

ContentWithLabel.defaultProps = {
  ifBlank: ContentWithLabel.ifBlank.DASH,
};

export const FullWidthContentWithLabel = styled(ContentWithLabel)`
  width: 100%;
`;

export default ContentWithLabel;
