import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { bookingPropTypeWithState } from 'common/propTypes';

import LoadingIndicator from 'ready/components/LoadingIndicator/LoadingIndicator';
import CreateReservationAlteration from './ReservationAlteration/CreateReservationAlteration';
import EditReservationAlteration from './ReservationAlteration/EditReservationAlteration';
import CreateSpecialOffer from './SpecialOffer/CreateSpecialOffer';

const Container = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const CenteredLoadingIndicatorWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const ConversationDrawerContent = ({
  booking,
  reservationAlteration,
  specialOffer,
  dispatchCloseConversationDrawer,
  t,
  ...props
}) => {
  const hideLoading =
    props.conversationDrawerBooking ||
    (props.conversationDrawerReservationAlteration && !reservationAlteration.isFetching) ||
    props.conversationDrawerInquiry;

  return (
    <>
      {hideLoading ? (
        <Container>
          {props.conversationDrawerBooking && (
            <CreateReservationAlteration
              booking={booking}
              selectedBookingId={props.conversationDrawerBooking}
              suggestedAlterationPrice={reservationAlteration.suggestedPrice}
              dispatchChangeShowProposeAlterationModal={props.dispatchChangeShowProposeAlterationModal}
              dispatchFetchAlterationPriceRequested={props.dispatchFetchAlterationPriceRequested}
              setReservationAlterationProposal={props.setReservationAlterationProposal}
              isCreating={reservationAlteration.isCreating}
              errors={reservationAlteration.errors}
              t={t}
            />
          )}
          {props.conversationDrawerReservationAlteration && (
            <EditReservationAlteration
              booking={booking}
              reservationAlteration={reservationAlteration}
              dispatchChangeShowAcceptAlterationModal={props.dispatchChangeShowAcceptAlterationModal}
              dispatchChangeShowRejectAlterationModal={props.dispatchChangeShowRejectAlterationModal}
              t={t}
            />
          )}
          {props.conversationDrawerInquiry && (
            <CreateSpecialOffer
              booking={booking}
              specialOffer={specialOffer}
              dispatchPostSpecialOfferRequested={props.dispatchPostSpecialOfferRequested}
              dispatchCloseConversationDrawer={dispatchCloseConversationDrawer}
              t={t}
            />
          )}{' '}
        </Container>
      ) : (
        <CenteredLoadingIndicatorWrapper>
          <LoadingIndicator />
        </CenteredLoadingIndicatorWrapper>
      )}
    </>
  );
};

ConversationDrawerContent.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  reservationAlteration: PropTypes.object,
  inquiry: PropTypes.object,
  t: PropTypes.shape({
    reservation: PropTypes.string.isRequired,
    reject: PropTypes.string.isRequired,
    approve: PropTypes.string.isRequired,
    reset: PropTypes.string.isRequired,
    apply: PropTypes.string.isRequired,
    back: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    requestedByGuest: PropTypes.string.isRequired,
    propose: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired,
    proposal: PropTypes.string.isRequired,
    checkIn: PropTypes.string.isRequired,
    checkOut: PropTypes.string.isRequired,
    guests: PropTypes.string.isRequired,
    adults: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    pets: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    seeCalendar: PropTypes.string.isRequired,
    edit: PropTypes.string.isRequired,
    updateError: PropTypes.string.isRequired,
    totalPrice: PropTypes.string.isRequired,
    rentalIncome: PropTypes.string.isRequired,
    proposePrice: PropTypes.string.isRequired,
    selectGuests: PropTypes.string.isRequired,
    noPetsAllowed: PropTypes.string.isRequired,
    selectCheckIn: PropTypes.string.isRequired,
    selectCheckOut: PropTypes.string.isRequired,
    accepted: PropTypes.string.isRequired,
    rejected: PropTypes.string.isRequired,
    pending: PropTypes.string.isRequired,
    specialOffer: PropTypes.string.isRequired,
    sendSpecialOffer: PropTypes.string.isRequired,
    specialOfferFormDetails: PropTypes.string.isRequired,
    specialOfferFormInfo: PropTypes.string.isRequired,
    specialOfferFormCleaning: PropTypes.string.isRequired,
    specialOfferFormExtra: PropTypes.string.isRequired,
    specialOfferFormTotal: PropTypes.string.isRequired,
    specialOfferFormEditTotal: PropTypes.string.isRequired,
    specialOfferFormEditTotalDetails: PropTypes.string.isRequired,
  }),
};

export default ConversationDrawerContent;
