import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text, Icon } from 'ready/components';
import { FormattedMessage } from 'react-intl';

const BathroomItemWrapper = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.xs};
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
`;

const BathroomItem = ({ item }) => (
  <BathroomItemWrapper>
    <Icon color="primary" size={Icon.sizes.L} icon={Icon.icons[item.toUpperCase()]} />
    <FormattedMessage id={`AMENITIES.BATHROOM.${item.toUpperCase()}`} defaultMessage="">
      {txt => <Text>{txt}</Text>}
    </FormattedMessage>
  </BathroomItemWrapper>
);

BathroomItem.propTypes = {
  item: PropTypes.string.isRequired,
};

const BathroomWrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.xs};
`;

export const Bathroom = ({ id, label, items }) => (
  <BathroomWrapper>
    <Text size={Text.sizes.S} bold>
      {label}
    </Text>
    {(items.length > 0 && items.map(item => <BathroomItem key={`${id}_${item}`} item={item} />)) || '-'}
  </BathroomWrapper>
);

Bathroom.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.array,
};

const BathroomsWrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.l};
  margin-bottom: ${props => props.theme.spacing.l};
  min-width: 100%;
`;

const Bathrooms = ({ t, bathrooms }) =>
  !!bathrooms &&
  bathrooms.length > 0 && (
    <BathroomsWrapper>
      {bathrooms.map((bathroom, i) => (
        <Bathroom
          key={bathroom.id}
          id={bathroom.id}
          label={`${t.bathroom} ${i + 1}`}
          items={getBathroomItems(bathroom)}
        />
      ))}
    </BathroomsWrapper>
  );

export const getBathroomItems = bathroom => Object.keys(bathroom).filter(key => key !== 'id' && bathroom[key]);

Bathrooms.propTypes = {
  bathrooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
};

export default Bathrooms;
