import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Icon, Button } from 'ready/components';
import { FormattedMessage } from 'react-intl';

const StyledBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
  cursor: pointer;
  user-select: none;
  margin-bottom: ${props => props.theme.spacing.l};
`;

const StyledBackButtonText = styled(Text)`
  text-decoration: underline;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 ${props => props.theme.spacing.s} ${props => props.theme.spacing.s} ${props => props.theme.spacing.s};
`;

const DetailsWrapper = styled.div`
  margin: ${props => props.theme.spacing.xs} 0;
`;
const DetailsItem = styled.div`
  display: flex;
  flexflow: column wrap;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.xs} 0;
`;

const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

const GuestsWrapper = styled.div`
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.l};
  padding: ${props => props.theme.spacing.m};
`;

const GuestsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${props => props.theme.spacing.s} 0;
`;

const GuestsCountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.s};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.l};
  margin: ${props => props.theme.spacing.l} 0;
`;

const StyledTextLink = styled(Text)`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
`;

const EditGuestsForm = ({ guests, setGuests, setShowEditGuestsForm, petsAllowed, maxGuests, t }) => {
  const [newGuestsData, setNewGuestsData] = useState([
    {
      label: t.adults,
      value: guests.adults,
      person: true,
    },
    {
      label: t.children,
      value: guests.children,
      person: true,
    },
    {
      label: t.pets,
      value: guests.pets,
      person: false,
    },
  ]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let newTotalCount = 0;
    newGuestsData.forEach(guest => {
      if (guest.person) {
        newTotalCount += guest.value;
      }
    });
    setTotalCount(newTotalCount);
  }, [newGuestsData]);

  function handleIncrementClick(index) {
    const updatedGuestsData = newGuestsData.map((guest, i) => {
      if (i === index) {
        guest.value = guest.value + 1;
      }
      return guest;
    });
    setNewGuestsData(updatedGuestsData);
  }
  function handleDecrementClick(index) {
    const updatedGuestsData = newGuestsData.map((guest, i) => {
      if (i === index) {
        guest.value = guest.value - 1;
      }
      return guest;
    });
    setNewGuestsData(updatedGuestsData);
  }

  function reset() {
    setNewGuestsData([
      {
        label: t.adults,
        value: guests.adults,
        person: true,
      },
      {
        label: t.children,
        value: guests.children,
        person: true,
      },
      {
        label: t.pets,
        value: guests.pets,
        person: false,
      },
    ]);
  }
  function apply() {
    setGuests({
      adults: newGuestsData[0].value,
      children: newGuestsData[1].value,
      pets: petsAllowed ? newGuestsData[2].value : 0,
    });
    setShowEditGuestsForm(false);
  }
  return (
    <>
      <ContentWrapper>
        <StyledBackButton onClick={() => setShowEditGuestsForm(false)}>
          <Icon size={Icon.sizes.L} icon={Icon.icons.ARROW_LEFT} />
          <StyledBackButtonText>{t.back}</StyledBackButtonText>
        </StyledBackButton>
        <Text size={Text.sizes.XL}>{t.selectGuests}</Text>
        <DetailsWrapper>
          <DetailsItem>
            <Icon size={Icon.sizes.L} icon={Icon.icons.v2.USER} />
            <Text>
              <FormattedMessage
                id="BOOKING.ALTERATION.FORM.EDIT.GUESTS.MAX_GUESTS"
                values={{ max_guests: maxGuests }}
              />
            </Text>
          </DetailsItem>
          {!petsAllowed && (
            <DetailsItem>
              <Icon size={Icon.sizes.L} icon={Icon.icons.v2.NO_PETS} />
              <Text>{t.noPetsAllowed}</Text>
            </DetailsItem>
          )}
        </DetailsWrapper>
        <GuestsWrapper>
          {newGuestsData.map((guest, i) =>
            guest.person || petsAllowed ? (
              <GuestsRow key={guest.label}>
                <CapitalizedText bold>{guest.label}</CapitalizedText>
                <GuestsCountWrapper>
                  <Button
                    variation={Button.variations.OUTLINE}
                    size={Button.sizes.M}
                    inline
                    disabled={guest.value <= 0}
                    onClick={() => handleDecrementClick(i)}
                  >
                    <Icon size={Icon.sizes.M} icon={Icon.icons.MINUS} />
                  </Button>
                  <Text bold>{guest.value}</Text>
                  <Button
                    variation={Button.variations.OUTLINE}
                    size={Button.sizes.M}
                    inline
                    disabled={guest.person && totalCount >= maxGuests}
                    onClick={() => handleIncrementClick(i)}
                  >
                    <Icon size={Icon.sizes.L} icon={Icon.icons.PLUS} />
                  </Button>
                </GuestsCountWrapper>
              </GuestsRow>
            ) : null,
          )}
        </GuestsWrapper>

        <ButtonsWrapper>
          <StyledTextLink onClick={reset}>{t.reset}</StyledTextLink>
          <Button
            variation={Button.variations.PRIMARY}
            size={Button.sizes.M}
            inline
            onClick={apply}
            disabled={totalCount === 0}
          >
            {t.apply}
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </>
  );
};

EditGuestsForm.propTypes = {
  guests: PropTypes.object.isRequired,
  setGuests: PropTypes.func.isRequired,
  setShowEditGuestsForm: PropTypes.func.isRequired,
  petsAllowed: PropTypes.bool.isRequired,
  maxGuests: PropTypes.number.isRequired,
  t: PropTypes.shape({
    back: PropTypes.string.isRequired,
    reset: PropTypes.string.isRequired,
    apply: PropTypes.string.isRequired,
    selectGuests: PropTypes.string.isRequired,
    adults: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    pets: PropTypes.string.isRequired,
    noPetsAllowed: PropTypes.string.isRequired,
  }),
};
export default EditGuestsForm;
