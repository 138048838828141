export const CONVERSATION = {
  ATTACHMENT: {
    TOO_MANY_FILES: 'You can only send one file at a time.',
    SHOW_PHOTOS: 'Show photos',
    REMOVE_ALL: 'Remove all',
    SUPPORTED_FILE_TYPES: 'image, video or PDF (max. {size}MB)',
    SUPPORTED_FILE_TYPES_ALL: 'image, video or PDF (max. {size}MB)',
    SUPPORTED_IMG_TYPES: 'SVG, PNG, JPG or GIF (max. {size}MB)',
    DROP: 'Drop here',
    FILES_AND_MEDIA: 'Files and media',
    SUPPORTED_FILE_TYPES_NO_VIDEO: 'image or PDF (max. {size}MB)',
    SHOW_FILES: 'Show files',
    TEXT_AND_ATTACHMENTS_NOT_SUPPORTED: 'You cannot send text with an attachment. Please send the attachment first.',
    SHOW_VIDEOS: 'Show videos',
    DISABLED: 'This feature is not available with the integration and/or communication platform used.',
    WARNING: 'Please do not share any sensitive documentation, such as IDs, with guests.',
  },
  SENT_VIA: 'Sent via {origin}',
  DECLINE_INQUIRY_HINT: 'You can decline only through Airbnb directly',
  MESSAGE_CHANNELS: {
    AIRBNB_QA: 'Airbnb QA',
    VRBO_QA: 'Vrbo QA',
    VRBO: 'Vrbo',
    AIRBNB: 'Airbnb',
    HOMEAWAY: 'HomeAway',
    GUESTSPACE: 'Guest space',
    WHATSAPP: 'WhatsApp',
    HOMELIKE: 'Homelike',
    BOOKING_QA: 'Booking QA',
    BOOKING: 'Booking.com',
    SMS: 'SMS',
    HOMELIKE_QA: 'Homelike QA',
    EMAIL: 'Email',
    INTERNAL: 'Internal note',
  },
  RELOCATION: {
    FROM: 'This guest has been relocated from a previous reservation ({link})',
    TO: 'This guest has been relocated to a new reservation ({link})',
  },
  SEND: 'Send',
  SEND_AND_PREAPPROVE: 'Send and pre-approve',
  TENTATIVE_PRE_APPROVAL_FAILED:
    'Pre approval action failed. To accept or decline, you need to connect on the airbnb account.',
  TENTATIVE: 'This is a tentative for Airbnb. Accepting this tentative will validate the reservation.',
  OUTGOING_MESSAGE_TYPES: {
    AIRBNB: 'Airbnb',
    INTERNAL_NOTE: 'Internal note',
    SMS: 'SMS',
    WHATSAPP: 'WhatsApp',
  },
  WHATSAPP_CONVERSATION:
    'This is a WhatsApp conversation without a reservation linked to the guest’s phone number. The property displayed is not accurate!',
  SAVED_REPLIES: 'Saved replies',
  ATTACHMENTS: 'Attachments',
  AI_REPLIES: {
    GENERATE: 'Generate AI reply',
    IMPROVE: 'Improve with AI',
  },
  CANCELLATION_REQUEST: {
    TEXT:
      'Warning: A cancellation request has been made on this reservation for the following reason: {reason}. Requested by {created_by} on {created}',
  },
  SENDER: {
    UNKNOWN_NAME: 'Sender name unknown',
    UNKNOWN_GUEST_NAME: 'Unknown guest name',
    GENERIC_STAFF: 'Staff',
    PROPERTY_MANAGER_STAFF: '{property_manager} Staff',
  },
  FLAG_CONVERSATION: {
    FLAG: '{color} flag',
    HELP_TEXT: 'Flagged conversations can be found under the folder with flags instead of reservation status.',
    NO_FLAG: 'No Flag',
    SECTION_TITLE: 'Flagged',
    TITLE: 'Flag conversation',
  },
  SEND_AND_DECLINE: 'Send and decline',
  SEND_AND_CLOSE: 'Send and close',
  ACCEPT_ENQUIRY_QUESTION: 'Do you want to accept this enquiry?',
  VIPGUEST: {
    LABEL: 'VIP guest:',
    TEXT: 'Extra care is required for this reservation',
  },
  BLACKLIST: {
    LABEL: 'Blacklisted',
    TEXT:
      'This guest has been blacklisted for the following reason(s): {reasons}. Please cancel/refuse reservation as soon as possible.',
  },
  PRE_APPROVAL_FAILED: 'Pre approval action failed, please try again.',
  INQUIRY_NOT_FOUND:
    'This inquiry is not imported in our system. To accept, decline or send a special offer, you need to connect on the airbnb account.',
  PENDING_PAYMENT: {
    TEXT:
      'Warning: This reservation is not paid or not fully paid. Please hold with providing any information about check-in or self check-in to the guest.',
  },
  PRE_APPROVAL: {
    LABEL: 'Pre-approval instructions',
    UPDATE_FAILED: 'Pre approval action failed, please try again.',
  },
};

export const SAVE_ERROR = {
  HEADER: 'Something went wrong.',
  MESSAGES_1: 'We are having trouble communicating with the server.',
  MESSAGES_2: 'Please check your internet connection and click to try again.',
};

export const TOP_BAR = {
  TURN_OFF_CALL_CENTER: 'Turn off call center',
  CALL_CENTER_DISABLED: 'Call center disabled',
  CALL_CENTER_OFF: 'Call center off',
  HINT: {
    CALL_CENTER_DISABLED: 'Call center is not enabled',
    SNOOZE: 'Snooze',
    CHANGE_LANGUAGE: 'Change language',
    CLOSE_CONVERSATION: 'Close conversation',
    CALL_CENTER_NOT_STARTED: 'Call center is not started',
    PAUSE_CALL_CENTER:
      'You can pause the callcenter in order to prevent receiving calls. If you pause it, you will still be able to initiate calls',
    GIFT: 'Special Offer',
    REFRESH_MESSAGES: 'Refresh messages',
    CALL_CENTER_PAUSED: 'You have paused your callcenter. You can make calls but you cannot receive any calls.',
    CALL_GUEST: 'Open call center',
    OPEN_CONVERSATION: 'Open conversation',
  },
  CALL_CENTER_ON: 'Call center on',
  LOGOUT: 'Logout',
  PAUSE_CALL_CENTER: 'Pause call center',
  MAILBOX: 'Mailbox',
  UNPAUSE_CALL_CENTER: 'Unpause call center',
  UNPPAUSE_CALL_CENTER: 'Pause call center',
  START_CALL_CENTER: 'Start call center',
  DISABLE_CALL_CENTER_IN_TAB: 'Disable in this tab',
  MENU: 'Menu',
};

export const LOGIN = {
  SIGN_IN: {
    BUTTON: 'Sign In',
    PASSWORD: 'Password',
    USERNAME: 'Username',
  },
};

export const INFO_SIDEBAR = {
  OVERVIEW: 'Overview',
  PROPERTY: {
    BATHROOMS: {
      BATHROOM: 'Bathroom',
      NUMBER_OF_BATHROOMS: 'Nr of bathrooms',
      TITLE: 'Bathrooms',
    },
    GENERAL: {
      SIZE: 'Size',
      POSTAL_CODE: 'Postal code',
      NUMBER_OF_BEDROOMS: 'Nr of bedrooms',
      WELCOME_PICKUP_URL: 'WelcomePickup URL',
      WELCOME_PICKUPS_URL: 'Welcome Pickups URL',
      PDF_DOWNLOAD_LABEL: 'House instructions PDF',
      FLOOR: 'Floor',
      CLOSEST_STATION: 'Closest station',
      MAX_GUESTS_NUMBER: 'Max nr of guests',
      DIRECTIONS: 'Directions',
      ADDRESS: 'Address',
      PROPERTY_NAME: 'Property name',
      TITLE: 'General',
      ACCESS_CODE_1: 'Access code 1',
      ACCESS_CODE_2: 'Access code 2',
      PDF_DOWNLOAD_BUTTON: 'Download',
      PDF_DOWNLOAD_LABEL_MIDTERM: 'Mid-term house instructions PDF',
    },
    BED_BATH_ROOMS: {
      BATHROOM: 'Bathroom',
      NUMBER_OF_BATHROOMS: 'Nr of bathrooms',
      NUMBER_OF_BEDROOMS: 'Nr of bedrooms',
      TITLE: 'Bedrooms and Bathrooms',
    },
    CHECKOUT_CLEANING: {
      CHECKOUT_INSTRUCTIONS: 'Checkout instructions',
      CHECKOUT_TIME: 'Checkout time',
      CLEANING_INSTRUCTIONS: 'Cleaning instructions',
      TITLE: 'Checkout and Cleaning',
    },
    MAINTENANCE: {
      BREAKER_LOCATION: 'Breaker location',
      TITLE: 'Maintenance',
      TRASH_LOCATION: 'Trash location',
      WATER_LOCATION: 'Water location',
    },
    WIFI: {
      INFO: 'Info',
      NAME: 'Name',
      PASSWORD: 'Password',
      TITLE: 'Wifi',
    },
    BEDROOMS: {
      NUMBER_OF_BEDROOMS: 'Nr of bedrooms',
      TITLE: 'Bedrooms',
    },
    SELECT_PROPERTY: 'Select property',
    TITLE: 'Property',
    PARKING: {
      INSTRUCTIONS: 'Parking instructions',
      PRIVATE: 'Private parking',
      TITLE: 'Parking',
    },
    NEARBY: {
      POI: 'Points of interest',
      TITLE: 'Nearby',
    },
  },
  CALENDAR: {
    OPEN_FULL_CALENDAR: 'Open full calendar',
    PROPERTY_CALENDAR: 'Property calendar',
    TITLE: 'Calendar',
  },
  AMENITIES: {
    BRAND: 'Brand',
    DETAILS: 'Details',
    LOCATION: 'Location',
    MODEL: 'Model',
    TITLE: 'Amenities',
    SEARCH: 'Search amenity',
  },
  AUTOMATION: {
    AUTOMATED_MESSAGES: {
      SENT_ON: 'Sent on',
      TITLE: 'Automated messages',
    },
    AUTO_REVIEW: {
      CANCEL: 'Cancel auto-review',
      DISABLED_OR_SENT: 'Already sent or disabled',
      GUEST_REVIEW: 'Guest auto-review',
      TITLE: 'Auto-review',
      ENABLE: {
        TITLE: 'Enable automatic AI reply',
      },
      DISABLE: {
        TITLE: 'Disable automatic AI reply',
      },
    },
    TITLE: 'Automation',
    AUTO_REPLY: {
      DISABLE: {
        TITLE: 'Disable automatic AI reply',
      },
      ENABLE: {
        TITLE: 'Enable automatic AI reply',
      },
      SETTING_IS_RELATED: 'Setting is related to the auto-reply feature',
      TITLE: 'AI Generated Reply',
    },
  },
  ISSUES: {
    ORIGIN: 'Origin',
    CREATE_MISSION: 'Add a mission',
    OPENED_BY: 'Opened by',
    NO_RESERVATION: 'No reservation found. missions/tickets will be attached to the property.',
    CREATE_TICKET: 'Open a ticket',
    OPENED_ON: 'Opened on',
    TITLE: 'Tickets',
    ISSUE: 'Ticket',
    CATEGORY: 'Category',
    COMMENT: 'Comment',
  },
  OFFICE_POLICIES: {
    SPECIAL_REQUESTS: {
      TITLE: 'Special requests',
    },
    GUEST_VETTING: {
      TITLE: 'Guest vetting',
    },
    IN_STAY_CLEANING_PRICE: {
      TITLE: 'In-stay cleaning price',
    },
    DISCOUNT_POLICY: {
      TITLE: 'Discount policy',
    },
    EMERGENCY_CONTACT: {
      TITLE: 'Emergency contact',
    },
    PETS: {
      TITLE: 'Pets',
    },
    CHECK_OUT: {
      CHECK_OUT_PROCESS: 'Check-out process',
      MAX_CHECK_OUT_TIME: 'Max. check-out time',
      TITLE: 'Check-out',
    },
    SUBTITLE: '{office} policies ({property_manager})',
    LUGGAGE_DROP: {
      TITLE: 'Luggage drop',
    },
    TITLE: 'Office policies',
    EVENTS: {
      TITLE: 'Events',
    },
    CHECK_IN: {
      CHECK_IN_PROCESS: 'Check-in process',
      MAX_CHECK_IN_TIME: 'Max. check-in time',
      MIN_CHECK_IN_TIME: 'Min. check-in time',
      OFFICE_LEVEL: 'Office level',
      PROPERTY_LEVEL: 'Property level',
      TITLE: 'Check-in',
    },
    EXTRA_INFORMATION: {
      TITLE: 'Extra information',
    },
    SECURITY_DEPOSIT: {
      AIRBNB_GUEST_ID_POLICY: 'Airbnb guest ID policy',
      GUEST_ID_REVIEW: 'Guest ID review',
      TITLE: 'Security deposit / guest ID',
    },
  },
};

export const ERRORS = {
  FIELD_ERROR: 'There was an error with this field',
  FAILED_TO_INITIALISE_TASK_ROUTER: 'Failed to initialise task router',
};

export const MISSION = {
  AGENT_NAME: 'Agent name',
  AGENT_PHONE_NUMBER: 'Agent phone number',
  MISSION_ID: 'Mission ID',
  CHECKLIST_STATUS: 'Checklist Status',
  CHECKLIST_URL: 'Checklist URL',
  CHECKLIST: {
    STATUS: {
      NEW: 'Not yet filled',
      STARTED: 'Started',
      SUBMITTED: 'Submitted',
      REVIEWED: 'Reviewed',
    },
  },
  CHECKLIST_TITLE: 'View checklist',
};

export const SMART_DEVICE = {
  TITLE: 'Smart devices',
};

export const SERVER_ERROR = {
  HEADER: 'Something went wrong.',
  MESSAGES_1: 'We are having trouble communicating with the server.',
  MESSAGES_2: 'Please wait while we try to reconnect.',
  MESSAGES_3: 'Please try again later.',
};

export const ASSIGN_TO_ME = {
  HINT_ON_CHECKED: 'Unassign this conversation from me',
  HINT_ON_NOT_CHECKED: 'Assign this conversation to me',
  ASSIGN_TO_MYSELF: 'Assign to myself',
  ASSIGN_TO_MYSELF_UNTIL: 'until {time}',
};

export const TEXT = {
  SHARED: {
    ALL: 'All',
    NO: 'No',
    ENABLED: 'Enabled',
    MANAGE: 'Manage',
    LOAD: 'Load',
    BACK: 'Back',
    YES: 'Yes',
    ACCEPT: 'Accept',
    ERROR: 'Error: ',
    SEND: 'Send',
    UNKNOWN: 'Unknown',
    EDIT: 'Edit',
    WITHDRAW: 'Withdraw',
    FROM: 'From',
    APPROVE: 'Approve',
    LOAD_MORE: 'Load more',
    APPLY: 'Apply',
    RESET: 'Reset',
    OTHER: 'Other',
    COPY: 'Copy',
    DECLINE: 'Decline',
    SHOW_MORE: 'Show more',
    SELECT_OPTION: 'Select option',
    NO_RESULTS: 'No results found',
    CANCEL: 'Cancel',
    RESEND: 'Resend',
    SHOW_LESS: 'Show less',
    TO: 'To',
    DISABLED: 'Disabled',
    TRY_AGAIN: 'Try again',
    SAVE: 'Save',
    SELECT_ALL: 'Select all',
    REQUIRED: 'Required',
    REJECT: 'Reject',
  },
};

export const PREVIOUS_CLEANING_MISSION = {
  TITLE: 'Previous cleaning',
};

export const CALL_INFO = {
  OFFICE: 'Office: {office}',
  LANGUAGE: 'Guest speaks {language} (preferred language)',
  COUNTRY: 'Reservation is in {country_name}',
};

export const RECEPTION = {
  ADDRESS: 'Address',
  DESCRIPTION: 'Description',
  OPENING_HOURS: 'Opening Hours',
  NAME: 'Reception name',
  TITLE: 'Reception',
};

export const CONVERSATIONS = {
  FIRST_RESERVATION_TOOLTIP: '1st reservation on this listing',
  BACK_TO_CONVERSATIONS: 'Back to conversations',
  SEARCH: {
    PLACEHOLDER: 'Search by reservation ID, guest, etc',
  },
  PERMALINK: 'Loaded from permalink',
  FIRST_RESERVATIONS: '1st reservation',
  LOAD_MORE_ERROR: "Couldn't load more messages",
  FIRST_RESERVATIONS_TOOLTIP: 'One of the 1st five reservations on this listing',
  FIRST_RESERVATION: '1st reservation',
  SORT: {
    OPTIONS: {
      CHECK_IN_DATE: 'Sort by check-in time',
      RECEIVED_DATE: 'Sort by date received',
      PROPERTY_CLASS: 'Sort by importance',
      RECEIVED_DATE_ASC: 'Oldest first',
      RECEIVED_DATE_DESC: 'Newest first',
    },
    TOOLTIP: 'Sort conversation list',
  },
};

export const SAVED_REPLIES = {
  BUTTON: 'Saved replies',
  LIST: {
    ITEM: {
      ADD: 'Add',
      VIEW: 'View',
    },
  },
  SEARCH_INPUT: {
    PLACEHOLDER: 'Search saved replies',
  },
};

export const ESCALATE = {
  HINT_ON_CHECKED: 'This conversation is marked as priority',
  HINT_ON_NOT_CHECKED: 'Mark this conversation as priority',
  DEESCALATE: 'Unmark as priority',
  ESCALATE: 'Mark as priority',
};

export const KEY_STRATEGY = {
  KEY_STRATEGY: 'Key Strategy',
};

export const CHECKOUT_MISSION = {
  TITLE: 'Check-out',
};

export const UNAUTHORIZED = {
  HEADER: 'Unauthorized access.',
  TEXT: 'To get access to this section, please reach out to a member of our product team.',
};

export const FAVICON = {
  ICON: 'Icon',
};

export const PROPERTY = {
  MAX_GUESTS_NUMBER: 'Max nr of guests',
  NAME: 'Property name',
  SIZE: 'Size',
  TITLE: 'Property',
  NUMBER_OF_BEDROOMS: 'Number of bedrooms',
  ADDRESS: 'Address',
  SELF_CHECK_IN: {
    LABEL: 'SCI',
    TOOLTIP: 'This property is a self check-in',
  },
};

export const CALL_CENTER = {
  END_CALL: 'End call',
  CALLING: 'Calling ...',
  FORWARD: 'Forward',
  NOTES_SEND: 'Add notes',
  OFF_NOTIFICATION_BUTTON_OFF: 'Keep it off',
  OFF_NOTIFICATION_BUTTON_ON: 'Switch it on',
  FORWARD_PAGE: {
    FORWARD: 'Forward',
    HEADLINE: 'Forward call',
  },
  CALL_CENTER_ERROR_HEADER: 'Call center setup error',
  OUTBOUND_CALL: 'Outbound call',
  CALL_CENTER_ERROR_TEXT:
    'Something went wrong with call center setup. Refresh the page to try again. If it still does not work, please get in touch with support team.',
  RECORDING_WARNING: 'Warning: This call is recorded for quality and training purpose!',
  PHONE_NUMBER_PLACEHOLDER: 'Enter phone number',
  NOTE_WARNING: 'Call note required',
  OFF_NOTIFICATION_TEXT: 'The call center has been switched off because you missed a call previously.',
  OFF_NOTIFICATION_HEADER: 'Call center is off',
  UNMUTE: 'Unmute',
  RINGING: 'Ringing ...',
  KEYPAD: 'Keypad',
  NOTES_PLACEHOLDER: 'Call notes *',
  NO_AGENTS_AVAILABLE: 'No agents available',
  NOTES_LABEL: 'Call Notes *',
  CALL_GUEST: 'Call',
  INCOMING_CALL: 'Incoming call',
  CALL_ENDED: 'Call ended',
  MUTE: 'Mute',
  CALL_REASON_LABEL: 'Call reason *',
  CANCEL: 'Cancel',
  EXPAND: 'Expand',
  OUTGOING_NUMBER: 'Call from:',
  FORWARDING_TO: 'Forwarding to',
  STAY_AT_THIS_CONVERSATION: 'Stay at this conversation',
  TABS: {
    GUEST: 'Guest',
    OTHER: 'Other',
  },
  ANSWER: 'Answer',
  BACK_TO_PREVIOUS_CONVERSATION: 'Back to previous conversation',
  REJECT: 'Reject',
};

export const SPECIAL_OFFER = {
  FORM: {
    CLEANING: 'Cleaning fee',
    DETAILS: 'Offer a special price to the guest. Guest will have 24 hours to accept.',
    EDIT_TOTAL: 'Edit total price',
    EDIT_TOTAL_DETAILS: 'Be sure to include any additional fees, such as the cleaning fee',
    EXTRA: 'Extra fee',
    INCOME: 'Price for {nights} nights',
    INFO: 'The offered amount must include any additional fees, such as the cleaning fee',
    TOTAL: 'Total price',
  },
  KEEP: 'Keep it',
  HINT: 'Offer a discount to the guest',
  MESSAGE: 'Special offer of {price} {currency} sent on {send_date} expires on {expire_date} at {expire_time}.',
  SEND: 'Send special offer',
  UPDATE: 'Update offer',
  CONFIRM_WITHDRAW: 'Do you want to withdraw the special offer?',
  EDIT: 'Edit',
  NOTIFICATION: 'Offer of {price} {currency} expires on {expire_date} at {expire_time}.',
  DELETE: 'Delete offer',
  WITHDRAW_DETAILS:
    'If you decide to withdraw your offer, potential reservation will be declined and removed from the mailbox.',
  PENDING_SPECIAL_OFFER: 'Special offer to be accepted by guest by {expire_date}',
  NEW_AMOUNT: 'New amount',
  TITLE: 'Special offer',
  CURRENT_AMOUNT: 'Current amount',
};

export const AMENITIES = {
  BATHROOM: {
    JACUZZI: 'Jacuzzi',
    WASTEBASKET: 'Wastebasket',
    SHOWER: 'Shower',
    TOILET: 'Toilet',
    SPA_BATH: 'Spa bath',
    TOWEL_WARMER: 'Towel warmer',
    BATH: 'Bath',
    TOOTHBRUSH_HOLDER: 'Toothbrush holder',
    HAIR_DRYER: 'Hair drier',
    MIRROR: 'Mirror',
  },
};

export const GUEST = {
  MOOD_BAD_TOOLTIP: 'The guest is in a negative mood. Please respond accordingly and provide additional care',
  MOOD_GOOD: 'Good',
  REVIEW_COUNT: 'Nr of reviews',
  VERIFIED: 'Verified',
  MOOD_GOOD_TOOLTIP: 'The guest is in a positive mood. Please ensure it remains so by maintaining excellent service',
  NAME: 'Guest name',
  ID_VERIFIED: 'Guest ID verified',
  COUNT: 'Nr of guests',
  CONTACTS: 'Guest contacts',
  NOT_VERIFIED: 'Not verified',
  LOCATION: 'Location',
  AIRBNB_PROFILE: 'Airbnb guest profile',
  TITLE: 'Guests',
  ID_NOT_VERIFIED: 'Guest ID not verified',
  AIRBNB_ACCOUNT: 'Airbnb account',
  MOOD_BAD: 'Bad',
};

export const BOOKING = {
  STATUSES: {
    NEW: 'New',
    ARCHIVED: 'Archived',
    EXPIRED: 'Expired',
    INQUIRY_DECLINED: 'Inquiry declined',
    INQUIRY: 'Inquiry',
    ACCEPTED: 'Accepted',
    TENTATIVE: 'Tentative',
    CANCELLED: 'Cancelled',
    AWAITING_PAYMENT: 'Awaiting payment',
    REQUEST_WITHDRAWN: 'Request withdrawn',
    REQUEST_EXPIRED: 'Request expired',
    NOT_FOUND: 'Not Found',
    SPECIAL_OFFER: 'Special Offer',
    TIMEDOUT: 'Timed out',
    PENDING: 'Pending',
    CANCELLED_FEE: 'Cancelled Fee',
    CANCELED: 'Cancelled',
    PREAPPROVED: 'Pre-Approved',
    DENIED: 'Denied',
    REQUEST_TO_BOOK: 'Request to book',
    NOT_POSSIBLE: 'Not Possible',
    REQUEST_DECLINED: 'Request declined',
  },
  DBW_LINK: 'DBW link',
  CHECK_IN_DELAY: 'Check in delay',
  BOOKING_SELECT: 'Reservation select',
  PROPERTY_MANAGER: 'Property manager',
  LISTING: 'Listing',
  COPY_LINK: 'Copy link',
  INQUIRIES: 'Inquiries',
  PRICE: 'Price',
  FRAIS_MENAGE: 'New amount must include all fees, including cleaning fees. Cleaning fees for this property are {fees}',
  TIME_NO_RESULTS: ' - ',
  EDIT: 'Edit',
  NUMBER_OF_GUESTS: 'Nr of guests',
  AIRBNB_PASSWORD: 'Airbnb password',
  CANCELLATION_POLICY: 'Cancellation policy',
  STATUS: 'Status',
  GUEST_REVIEWS: {
    NO_RATING: 'No rating',
    SEE_REVIEW: 'See review',
    UNDER_INVESTIGATION: 'Under investigation',
  },
  DURATION: 'Nr of nights',
  TBC: 'TBC',
  MAX_GUESTS: 'max:',
  CHECK_OUT: 'Check out',
  CONFIRMATION_CODE: 'Reference',
  TITLE: 'Reservation',
  SEE_LISTING: 'See listing',
  GUEST_COMMENT: 'Guest comment',
  STAY_EXTENSION: {
    TITLE: 'Stay extension',
  },
  AIRBNB_ACCOUNT: 'Airbnb account',
  BOOKINGS: 'Reservations',
  LINK: 'Link',
  SOURCE: 'Platform',
  ID: 'ID',
  CHECK_IN: 'Check in',
  ALTERATION: {
    REQUESTED_BY_GUEST: 'Alteration requested by guest',
    PROPOSE_CHANGES: 'Propose changes to guest',
    FORM: {
      SEE_CALENDAR: 'See calendar',
      EDIT: {
        CALENDAR: {
          MIN_STAY: 'Min. stay is {nights} nights',
          SELECT_CHECK_IN: 'Select new check-in date',
          SELECT_CHECK_OUT: 'Select new check-out date',
        },
        GUESTS: {
          MAX_GUESTS: 'Max. nr. of guests is {max_guests}',
          NO_PETS_ALLOWED: 'Pets are not allowed',
          SELECT_GUESTS: 'Select guests',
        },
        PRICE: {
          ESTIMATED_RENTAL_INCOME: 'The rental income estimated to given change is {total_rental_income}',
          RENTAL_INCOME: 'Rental income',
          ESTIMATED_PRICE: 'The total price estimated to given change is {estimated_price}.',
          PROPOSE_PRICE: 'Propose new price',
          ESTIMATION: 'Approximately {estimated_price} x {number_of_nights} nights',
          ESTIMATED_TOTAL_PRICE: 'The estimated total price is {total_estimated_price}',
          TOTAL_PRICE: 'Total price',
          WITHOUT_CITY_TAX: "The total price doesn't include city tax of {city_tax}",
          WITH_CITY_TAX: 'The total price includes city tax of {city_tax}',
          WITH_PLATFORM_FEE: 'The estimated platform fee of the reservation is {platform_fee}',
          WITH_EXTRA_FEES: 'The estimated extra fees of the reservation are {extra_fees}',
          WITH_CLEANING_FEE: 'This total price includes cleaning fee of {cleaning_fee}',
          MIN_PRICE: 'Min. price is {min_price} in total.',
        },
      },
      MODAL: {
        ACCEPT_CONTENT: 'Are you sure you want to approve these changes?',
        ACCEPT_HEADER: 'You are about to approve changes in this reservation',
        ACCEPT_ACTION: 'Approve changes',
        REJECT_HEADER: 'You are about to reject changes in this reservation',
        PROPOSE_HEADER: 'You are about to propose changes to the guest',
        PROPOSE_CONTENT_NOTIFICATION: 'Guest will receive them in their Airbnb inbox.',
        REJECT_ACTION: 'Reject changes',
        PROPOSE_ACTION: 'Propose changes',
        REJECT_CONTENT: 'Are you sure you want to reject these changes?',
        PROPOSE_CONTENT_CONFIRMATION: 'Are you sure you want to propose these changes?',
        REJECT_AND_PROPOSE_ACTION: 'Reject and propose new',
      },
      PRITCE: 'Price',
      ORIGINAL: 'Original',
      PROPOSAL: 'Proposal',
      UPDATE_ERROR: 'Something went wrong.',
      PETS: 'pets',
      PROPERTY: 'Property',
      CHECK_OUT: 'Check-out',
      ADULTS: 'adults',
      GUESTS: 'Guests',
      CHECK_IN: 'Check-in',
      PROPOSE: 'Propose to guest',
      CHILDREN: 'children',
    },
    ACCEPTED: 'Proposal accepted',
    PROPOSED_BY_PM: 'Alteration proposed by {property_manager}',
    GUEST_PROPOSED: 'Guest proposed changes',
    GUEST_REVIEWS: {
      NO_RATING: 'No rating',
      SEE_REVIEW: 'See review',
      UNDER_INVESTIGATION: 'Under investigation',
    },
    PENDING: 'Proposal pending',
    REJECTED: 'Proposal rejected',
  },
};

export const UPLOAD_FILES = {
  BUTTON: 'Upload files',
};

export const CHECKIN_MISSION = {
  AGENT_NAME: 'Agent name',
  AGENT_PHONE_NUMBER: 'Agent phone number',
  COMMENTS: 'Check-in comments',
  INSTRUCTIONS: 'Self Check-in instructions',
  TITLE: 'Check-in',
  TYPE: 'Check-in type',
  TYPE_OPTIONS: {
    IN_PERSON: 'In person',
    SELF: 'Self check-in',
    ARRIVED: 'Arrived',
    CANCELLED: 'Cancelled',
    LATE: 'Late',
    NOT_ARRIVED: 'Not arrived',
    NO_SHOW: 'No show',
    UNKNOWN: 'Unknown',
  },
  LOCKBOX_CODE: 'Lockbox code',
};

export const FILTERS = {
  DELETE_PRESET_CONFIRMATION: 'Delete "{preset}"?',
  GUEST_PHONE_NUMBER: 'Guest phone number',
  GUEST_FIRST_NAME: 'Guest first name',
  SAVE_PRESET: 'Save preset',
  OPENED: 'Open',
  MORE_FILTERS_SELECTED: '{counter, number} More {counter, plural, one {filter} other {filters}} selected',
  CITIES: 'Office',
  CLEAR_FILTERS: 'Clear filters',
  APPLY_FILTERS: 'Apply',
  SECTION_DATES: 'Reservation dates',
  CLOSED: 'Closed',
  GUEST_LAST_NAME: 'Guest last name',
  RESERVATION_CHECK_OUT_BETWEEN: 'Check out between',
  RESERVATION_PLATFORMS: 'Reservation platform',
  SECTION_GENERAL: 'General',
  RESERVATION_ID: 'Reservation ID',
  RESERVATION_HAS_REVIEW: 'Reservation has review?',
  MORE_FILTERS: 'More filters',
  NEW_PRESET_PLACEHOLDER: 'Name your preset or select an existing one',
  SECTION_RESERVATION: 'Reservation',
  RESERVATION_STATUSES: 'Reservation status',
  PROPERTY_NAME: 'Property',
  SELECT_PRESET_PLACEHOLDER: 'Select a preset',
  LOAD_PRESET: 'Load/manage presets',
  BOOKING_STATUS: 'Reservation status',
  RESERVATION_CHECK_IN_BETWEEN: 'Check in between',
  INQUIRY_STATUSES: 'AirBnB inquiry status',
  FILTERS: 'Filters',
  LANGUAGES: 'Language',
  RESERVATION_CREATED_BETWEEN: 'Created between',
  SNOOZED: 'Snoozed',
  SECTION_GUEST: 'Guest',
};

export const ACTIONS = 'Actions';
