import React, { useState } from 'react';
import { Modal, Button, Toggle } from 'ready/components';
import { getAttachments } from 'selectors/attachments';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AttachmentPreview } from 'components';
import {
  changeAttachmentsModalVisibility,
  changeAttachmentsGalleryToggler,
  changeAttachmentsGalleryIndex,
} from 'ducks/attachments';
import FsLightbox from 'fslightbox-react';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

const StyledModal = styled(Modal)`
  right: 0;
  top: 0;
  z-index: ${props => props.theme.zIndex.modal - 1};
  height: 100dvh;
  width: 72rem;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing.l};
  gap: ${props => props.theme.spacing.s};
`;
const OverflowContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const MediaAttachmentsWrapper = styled(Wrapper)`
  flex-flow: row wrap;
`;

const FileAttachmentsWrapper = styled(Wrapper)`
  flex-flow: column wrap;
`;

const Attachments = ({ attachments, dispatchShowModal, dispatchShowGallery, dispatchSetGalleryIndex, t }) => {
  const { modal, gallery, data } = attachments;
  const [showPhotos, setShowPhotos] = useState(true);
  const [showVideos, setShowVideos] = useState(true);
  const [showFiles, setShowFiles] = useState(true);

  const showMedia = showPhotos || showVideos;

  const hasAttachments = data.length > 0;
  const mediaAttachments = data.filter(item => item.file_content_type && item.file_content_type !== 'application/pdf');

  const handleAttachmentClick = attachmentId => {
    const attachmentIndex = mediaAttachments.map(attachment => attachment.id).indexOf(attachmentId);
    dispatchSetGalleryIndex(attachmentIndex >= 0 ? attachmentIndex : 0);
    dispatchShowGallery(!gallery.toggler);
  };

  return (
    <>
      {modal.visible && hasAttachments && (
        <StyledModal onClose={() => dispatchShowModal(false)} closeEnabled heading={t.filesAndMedia}>
          <Wrapper>
            <Toggle
              label={t.showPhotos}
              variation={Toggle.variations.SUCCESS}
              onClick={() => {
                setShowPhotos(!showPhotos);
              }}
              enabled={showPhotos}
            />
            <Toggle
              label={t.showVideos}
              variation={Toggle.variations.SUCCESS}
              onClick={() => {
                setShowVideos(!showVideos);
              }}
              enabled={showVideos}
            />
            <Toggle
              label={t.showFiles}
              variation={Toggle.variations.SUCCESS}
              onClick={() => {
                setShowFiles(!showFiles);
              }}
              enabled={showFiles}
            />
          </Wrapper>
          <OverflowContainer>
            {showMedia && (
              <MediaAttachmentsWrapper>
                {data
                  .filter(
                    item =>
                      item.file_content_type &&
                      ((showPhotos && item.file_content_type.startsWith('image/')) ||
                        (showVideos && item.file_content_type.startsWith('video/'))),
                  )
                  .map((attachment, i) => (
                    <AttachmentPreview
                      key={attachment.id}
                      size={AttachmentPreview.sizes.M}
                      src={attachment.url}
                      name={attachment.name}
                      type={attachment.file_content_type}
                      onClick={() => handleAttachmentClick(attachment.id)}
                    />
                  ))}
              </MediaAttachmentsWrapper>
            )}
            {showFiles && (
              <FileAttachmentsWrapper>
                {data
                  .filter(item => item.file_content_type === 'application/pdf')
                  .map((attachment, i) => (
                    <AttachmentPreview
                      key={attachment.id}
                      name={attachment.name}
                      type={attachment.file_content_type}
                      onClick={() => window.open(attachment.url)}
                    />
                  ))}
              </FileAttachmentsWrapper>
            )}
          </OverflowContainer>
        </StyledModal>
      )}
      <FsLightbox
        toggler={gallery.toggler}
        sources={mediaAttachments.map(el => el.url)}
        sourceIndex={gallery.sourceIndex}
        onClose={() => dispatchSetGalleryIndex(undefined)}
        key={mediaAttachments}
      />
      <Button
        size={Button.sizes.L}
        icon={{ icon: Button.icon.icons.v2.CLIP }}
        disabled={!hasAttachments}
        clean
        hint={{ text: t.attachments }}
        onClick={() => dispatchShowModal(!modal.visible)}
      />
    </>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.object,
  dispatchShowModal: PropTypes.func.isRequired,
  dispatchShowGallery: PropTypes.func.isRequired,
  dispatchSetGalleryIndex: PropTypes.func.isRequired,
  t: PropTypes.shape({
    attachments: PropTypes.string.isRequired,
    filesAndMedia: PropTypes.string.isRequired,
    showPhotos: PropTypes.string.isRequired,
    showVideos: PropTypes.string.isRequired,
    showFiles: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  attachments: getAttachments(state),
});

const mapDispatchToProps = {
  dispatchShowModal: changeAttachmentsModalVisibility,
  dispatchShowGallery: changeAttachmentsGalleryToggler,
  dispatchSetGalleryIndex: changeAttachmentsGalleryIndex,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    attachments: 'CONVERSATION.ATTACHMENTS',
    filesAndMedia: 'CONVERSATION.ATTACHMENT.FILES_AND_MEDIA',
    showPhotos: 'CONVERSATION.ATTACHMENT.SHOW_PHOTOS',
    showVideos: 'CONVERSATION.ATTACHMENT.SHOW_VIDEOS',
    showFiles: 'CONVERSATION.ATTACHMENT.SHOW_FILES',
  }),
)(Attachments);
