import { all, takeLatest, put, call } from 'redux-saga/effects';

import DataApi from 'api/DataApi';
import {
  FETCH_GUEST_REQUESTED,
  fetchGuestSuccessed,
  fetchGuestFailed,
  updateGuestSuccessed,
  updateGuestFailed,
  UPDATE_GUEST_REQUESTED,
} from 'ducks/guest';

export function* handleFetchGuestRequested({ payload: { id } }) {
  try {
    if (!id) return yield put(fetchGuestSuccessed());
    const guest = yield call([DataApi.apiInstance(), 'getGuest'], id);
    yield put(fetchGuestSuccessed(guest));
  } catch (e) {
    yield put(fetchGuestFailed(e.errors));
  }
}

export function* handleUpdateGuestRequested({ payload: { id, data } }) {
  try {
    if (!id) return yield put(updateGuestSuccessed());
    const guest = yield call([DataApi.apiInstance(), 'updateGuest'], id, data);
    yield put(updateGuestSuccessed(guest));
  } catch (e) {
    yield put(updateGuestFailed(e.errors));
  }
}

export default function* watchGuest() {
  yield all([
    takeLatest(FETCH_GUEST_REQUESTED, handleFetchGuestRequested),
    takeLatest(UPDATE_GUEST_REQUESTED, handleUpdateGuestRequested),
  ]);
}
