import { all, takeLatest, put, call } from 'redux-saga/effects';

import {
  fetchSmartDevicesSucceeded,
  fetchSmartDevicesFailed,
  toggleSmartDeviceSucceeded,
  toggleSmartDeviceFailed,
  FETCH_SMART_DEVICES_REQUESTED,
  TOGGLE_SMART_DEVICE_REQUESTED,
} from 'ducks/smartDevices';
import DataApi from 'api/DataApi';

export function* handleFetchSmartDevicesRequested({ type, options: rentalId }) {
  try {
    const smart_devices = yield call([DataApi.apiInstance(), 'getSmartDevices'], rentalId);
    yield put(fetchSmartDevicesSucceeded(smart_devices));
  } catch (e) {
    yield put(fetchSmartDevicesFailed(e.errors));
  }
}

export function* handleToggleSmartDeviceRequested({ type, deviceId }) {
  try {
    const smart_devices = yield call([DataApi.apiInstance(), 'toggleSmartDevice'], deviceId);
    if (smart_devices) {
      yield put(toggleSmartDeviceSucceeded(smart_devices));
    }
  } catch (e) {
    yield put(toggleSmartDeviceFailed(e.errors));
  }
}

export default function* watchSmartDevices() {
  yield all([
    takeLatest(FETCH_SMART_DEVICES_REQUESTED, handleFetchSmartDevicesRequested),
    takeLatest(TOGGLE_SMART_DEVICE_REQUESTED, handleToggleSmartDeviceRequested),
  ]);
}
