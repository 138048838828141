import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getIsFilterSidebarOpen } from 'selectors/view';
import { changeIsFilterSidebarOpen } from 'ducks/view';

import { Button, Text } from 'ready/components';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

const infoSidebarClosedStyle = ({ isFilterSidebarOpen }) =>
  !isFilterSidebarOpen &&
  css`
    grid-area: top-bar-right-collapsed;
    justify-self: flex-end;
  `;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 4.8rem;
  min-width: 28rem;
  padding: ${props => `0 ${props.theme.spacing.m}`};
  ${infoSidebarClosedStyle}
  width: 100%;
`;

const ConversationListTopBar = ({ isFilterSidebarOpen, dispatchChangeIsFilterSidebarOpen, className, t }) => (
  <Wrapper className={className} isFilterSidebarOpen={isFilterSidebarOpen}>
    <Button
      onClick={dispatchChangeIsFilterSidebarOpen}
      icon={{ icon: Button.icon.icons.v2.BURGER }}
      size={Button.sizes.L}
      clean
      inline
    />
    <Text size={Text.sizes.XL}>{t.mailbox}</Text>
    <div style={{ width: '1px' }} />
  </Wrapper>
);

const mapStateToProps = state => ({
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
});

const mapDispatchToProps = {
  dispatchChangeIsFilterSidebarOpen: changeIsFilterSidebarOpen,
};

ConversationListTopBar.propTypes = {
  isFilterSidebarOpen: PropTypes.bool,
  dispatchChangeIsFilterSidebarOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
  t: PropTypes.shape({
    mailbox: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    mailbox: 'TOP_BAR.MAILBOX',
  }),
)(ConversationListTopBar);
