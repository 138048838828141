import { all, put, call, select, debounce, takeLatest } from 'redux-saga/effects';

import DataApi from 'api/DataApi';
import { getConversationData } from 'selectors/conversation';
import { getGuest } from 'selectors/guest';
import {
  fetchSavedRepliesSuccessed,
  fetchSavedRepliesFailed,
  FETCH_SAVED_REPLIES_REQUESTED,
  FETCH_MORE_SAVED_REPLIES_REQUESTED,
  fetchMoreSavedRepliesRequested,
  fetchMoreSavedRepliesSuccessed,
  fetchMoreSavedRepliesFailed,
  UPDATE_SAVED_REPLY_CLICK_COUNT,
} from 'ducks/savedReplies';
import { getNextPageLink } from 'selectors/savedReplies';
import { getSelectedCategoryId } from 'selectors/filters';

export const FETCH_SAVED_REPLIES_DEBOUNCE_TIME = 500;

export function* handleFetchMoreSavedRepliesRequested() {
  try {
    const nextLink = yield select(getNextPageLink);
    if (!nextLink) return yield put(fetchMoreSavedRepliesRequested({ results: [] }));
    const savedReplies = yield call([DataApi.apiInstance(), 'getFromUrl'], nextLink);
    yield put(fetchMoreSavedRepliesSuccessed(savedReplies));
  } catch (e) {
    yield put(fetchMoreSavedRepliesFailed(e.errors));
  }
}

export function* handleFetchSavedReplies({ payload: { search } }) {
  try {
    const conversation = yield select(getConversationData);
    const guest = yield select(getGuest);
    const categories = yield select(getSelectedCategoryId);
    if (!conversation) return yield put(fetchSavedRepliesSuccessed({ results: [] }));

    const filters = {
      language: guest.data.language,
      name: search,
      conversation: conversation.id,
      categories,
    };
    const savedReplies = yield call([DataApi.apiInstance(), 'getSavedReplies'], filters);
    yield put(fetchSavedRepliesSuccessed(savedReplies));
  } catch (e) {
    yield put(fetchSavedRepliesFailed(e.errors));
  }
}

export function* handleupdateSavedReplyClickCount({ payload: { id } }) {
  try {
    yield call([DataApi.apiInstance(), 'updateSavedReplyClickCount'], id);
  } catch (e) {
    // fail silently
  }
}

export default function* watchSavedReplies() {
  yield all([
    debounce(500, FETCH_SAVED_REPLIES_REQUESTED, handleFetchSavedReplies),
    takeLatest(FETCH_MORE_SAVED_REPLIES_REQUESTED, handleFetchMoreSavedRepliesRequested),
    takeLatest(UPDATE_SAVED_REPLY_CLICK_COUNT, handleupdateSavedReplyClickCount),
  ]);
}
