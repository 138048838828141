export const contexts = {
  notifications: {
    PRE_APPROVAL: 'pre_approval',
  },
  infoSidebar: {
    BOOKING: 'booking',
    AUTO_REVIEW: 'auto_review',
  },
};

/**
 * Mix of message.type and message.category
 */
export const messageTypes = {
  GUEST: 'guest',
  STAFF: 'staff',
  INTERNAL_NOTE: 'internal_note',
  SPECIAL_OFFER: 'special_offer',
  NOTIFICATION: 'notification',
  RESERVATION_ALTERATION: 'reservation_alteration',
};

export const messageOrigins = {
  TWILIO: 'twilio',
  SMS: 'sms',
  GUESTSPACE: 'guestspace',
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
  BOOKINGSYNC: 'bookingsync_inbox',
};

export const messageChannels = {
  AIRBNB: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.AIRBNB',
    value: 'airbnb',
  },
  AIRBNB_QA: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.AIRBNB_QA',
    value: 'airbnb_qa',
  },
  SMS: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.SMS',
    value: 'sms',
  },
  INTERNAL: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.INTERNAL',
    value: 'internal',
  },
  GUESTSPACE: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.GUESTSPACE',
    value: 'guestspace',
  },
  HOMEAWAY: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.HOMEAWAY',
    value: 'homeaway',
  },
  EMAIL: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.EMAIL',
    value: 'email',
  },
  BOOKING: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.BOOKING',
    value: 'booking',
  },
  'BOOKING-COM': {
    key: 'CONVERSATION.MESSAGE_CHANNELS.BOOKING',
    value: 'booking-com',
  },
  'BOOKING-QA': {
    key: 'CONVERSATION.MESSAGE_CHANNELS.BOOKING_QA',
    value: 'booking-qa',
  },
  VRBO: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.VRBO',
    value: 'vrbo',
  },
  VRBO_QA: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.VRBO_QA',
    value: 'vrbo_qa',
  },
  HOMELIKE: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.HOMELIKE',
    value: 'homelike',
  },
  HOMELIKE_QA: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.HOMELIKE_QA',
    value: 'homelike_qa',
  },
  WHATSAPP: {
    key: 'CONVERSATION.MESSAGE_CHANNELS.WHATSAPP',
    value: 'whatsapp',
  },
};

export const conversationStatuses = {
  OPENED: 'opened',
  SNOOZED: 'snoozed',
  CLOSED: 'closed',
};

export const conversationDefaultChannels = {
  EMAIL: 'email',
  AIRBNB: 'airbnb',
  BOOKING_COM: 'booking-com',
  HOMEAWAY: 'homeaway',
};

export const bookingSelectTypes = {
  INQUIRY: 'inquiry',
  RESERVATION: 'reservation',
};

export const inquiryStatuses = {
  INQUIRY: 'inquiry',
  DENIED: 'denied',
  PRE_APPROVED: 'preapproved',
  NOT_POSSIBLE: 'not_possible',
  ACCEPTED: 'accepted',
  CANCELLED: 'cancelled',
  SPECIAL_OFFER: 'special_offer',
  TIMEDOUT: 'timedout',
  NOT_FOUND: 'not_found',
  PENDING: 'pending',
};

export const reservationTypes = {
  OWNER: 'PROPRIETAIRE', // Blocked
  CLASSIC: 'CLASSIQUE', // Guest
};

export const BOOKING_ORIGINS = {
  AIRBNB_MESSAGE: 'airbnb_message',
  AIRBNB_EMAIL: 'airbnb_email',
  AIRBNB_TENTATIVE: 'airbnb_tentative',
  CHANNEL_MANAGER: 'channel_manager',
};
export const INQUIRY_ORIGIN_FOR_PRE_APPROVAL = [
  BOOKING_ORIGINS.AIRBNB_MESSAGE,
  BOOKING_ORIGINS.AIRBNB_EMAIL,
  BOOKING_ORIGINS.AIRBNB_TENTATIVE,
];

export const BOOKING_PLATFORMS = {
  AIRBNB: 'airbnb',
};

export const savedReplies = {
  page: {
    LIMIT: 10,
    INIT_OFFSET: 1,
  },
};

export const conversations = {
  page: {
    LIMIT: 15,
    INIT_OFFSET: 1,
  },
  /**
   * sort options key must map exactly same translation key names
   */
  sort: {
    keys: {
      CHECK_IN_DATE: 'CHECK_IN_DATE',
      RECEIVED_DATE_ASC: 'RECEIVED_DATE_ASC',
      RECEIVED_DATE_DESC: 'RECEIVED_DATE_DESC',
      PROPERTY_CLASS: 'PROPERTY_CLASS',
    },
    values: {
      PROPERTY_CLASS: '-rentals__premium_class',
      CHECK_IN_DATE:
        'set_last,reservations__date_debut_reservation,reservations__heure_arrivee,inquiries__checkin_date,created',
      INQUIRIES_CHECK_IN_DATE:
        'set_last,inquiries__checkin_date,reservations__date_debut_reservation,reservations__heure_arrivee,created',
      RECEIVED_DATE_ASC: 'last_message__sent_at',
      RECEIVED_DATE_DESC: '-last_message__sent_at',
    },
  },
};

export const messages = {
  page: {
    LIMIT: 15,
    INIT_OFFSET: 1,
  },
};

export const links = {
  CHECK_IN_TASK: `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_ADMIN_TASK_PATH}`,
  BOOKING: `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_ADMIN_BOOKING_PATH}`,
  PROPERTY: `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_ADMIN_PROPERTY_PATH}`,
  ISSUE_DETAIL: `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_ISSUE_DETAIL_PATH}`,
};

export const paths = {
  LOGIN: '/login',
  ROOT: '/',
  APP: '/conversations',
  SAVED_REPLIES: '/saved-replies',
  FAVICON: '/favicon.ico',
};

export const REG_EXP = {
  SAVED_REPLIES: `(${paths.APP}/[0-9]{1,}${paths.SAVED_REPLIES})(/[0-9]+){0,1}(.{0,})`,
  CONVERSATION_ID: `(${paths.APP})(/[0-9]+){0,1}(.{0,})(\\?.{0,})`,
};

export const localStorageKeys = {
  TOKEN: 'token',
};

export const keyCodes = {
  ENTER: 13,
};

export const errorCodes = {
  NOT_FOUND: 404,
};

export const FILTER_REFRESH_INTERVAL = 1000 * 60 * 5;
export const SETUP_RETRY_INTERVAL = 1000 * 10;

// time in minutes
const day = 1440;
export const snoozeDurations = [30, 60, 120, 180, 360, day, 3 * day, 7 * day, 15 * day, 60 * day];

export const languageSwitcherOptions = [
  { label: 'EN', value: 'en' },
  { label: 'FR', value: 'fr' },
  { label: 'PT', value: 'pt' },
];

export const missionChecklistStatusTypes = {
  NEW: 'NEW',
  STARTED: 'STARTED',
  SUBMITTED: 'SUBMITTED',
  REVIEWED: 'REVIEWED',
};

export const checkInMissionTypes = {
  SELF: 'SELF',
  NOT_ARRIVED: 'NOT_ARRIVED',
  ARRIVED: 'ARRIVED',
  LATE: 'LATE',
  UNKNOWN: 'UNKNOWN',
  NO_SHOW: 'NO_SHOW',
  CANCELLED: 'CANCELLED',
};

export const callCenterPages = {
  DEFAULT: 'DEFAULT',
  KEYPAD: 'KEYPAD',
  FORWARD_LIST: 'FORWARD_LIST',
  FORWARDING: 'FORWARDING',
};

export const callCenterTabs = {
  GUEST: 0,
  OTHER: 1,
};

export const callCenterStatuses = {
  CLOSED: 'CLOSED',
  OPENED: 'OPENED',
  MINIMIZED: 'MINIMIZED',
};

export const twilioDeviceStatuses = {
  READY: 'ready',
  OFFLINE: 'offline',
  BUSY: 'busy',
  CONNECTING: 'connecting',
  ERROR: 'error',
};

export const callForwardingStatuses = {
  INITIALIZING: 'initializing',
  READY: 'ready',
  FETCHING: 'fetching',
  FORWARDING: 'forwarding',
  ERROR: 'error',
};

export const twilioConnectionStatuses = {
  READY: 'ready',
  CALLING: 'calling',
  RINGING: 'ringing',
  IN_PROGRESS: 'in-progress',
  OFFLINE: 'offline',
  COMPLETED: 'completed',
};

export const connectionTypes = {
  OUTBOUND: 'OUTBOUND',
  INBOUND: 'INBOUND',
  TRANSFER: 'TRANSFER',
};

export const twilioDeviceEvents = {
  CANCEL: 'cancel',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  ERROR: 'error',
  INCOMING: 'incoming',
  OFFLINE: 'offline',
  READY: 'ready',
};

export const taskRouterEvents = {
  RESERVATION_CREATED: 'reservation.created',
  RESERVATION_CANCELED: 'reservation.canceled',
  RESERVATION_TIMEOUT: 'reservation.timeout',
  RESERVATION_ACCEPTED: 'reservation.accepted',
  RESERVATION_COMPLETED: 'reservation.completed',
  ERROR: 'error',
  OFFLINE: 'offline',
  READY: 'ready',
  ACTIVITY_UPDATE: 'activity.update',
};

export const taskRouterReservationStatuses = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  TIMEOUT: 'timeout',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const taskRouterActivities = {
  OFFLINE: 'Offline',
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  BUSY: 'Busy',
  RESERVED: 'Reserved',
  IDLE: 'Idle',
  PAUSED: 'Paused',
};

export const featureFlags = {
  TWILIO_CALL_CENTER: 'twilio_call_center',
  ASSIGNMENT_IN_MAILBOX: 'can_see_self_assignment_in_mailbox',
};

export const premiumClasses = {
  GOLD: 'GOLD',
  BRONZE: 'BRONZE',
  SILVER: 'SILVER',
  MID_TERM: 'MID-TERM',
  CLASSIC: 'CLASSIC',
  DIAMOND: 'DIAMOND',
};

export const guestMoods = {
  GOOD: 'GOOD',
  BAD: 'BAD',
};

export const filterKeys = {
  CITIES: 'cities',
  LANGUAGES: 'languages',
  PROPERTY_NAME: 'propertyName',
  CATEGORY_ID: 'categoryId',
  RESERVATION_ID: 'reservationId',
  RESERVATION_STATUSES: 'reservationStatuses',
  INQUIRY_STATUSES: 'inquiryStatuses',
  RESERVATION_PLATFORMS: 'reservationPlatforms',
  GUEST_FIRST_NAME: 'guestFirstName',
  GUEST_LAST_NAME: 'guestLastName',
  GUEST_PHONE_NUMBER: 'guestPhoneNumber',
  RESERVATION_CREATED_FROM: 'reservationCreatedFrom',
  RESERVATION_CREATED_TO: 'reservationCreatedTo',
  RESERVATION_CHECK_IN_FROM: 'reservationCheckInFrom',
  RESERVATION_CHECK_IN_TO: 'reservationCheckInTo',
  RESERVATION_CHECK_OUT_FROM: 'reservationCheckOutFrom',
  RESERVATION_CHECK_OUT_TO: 'reservationCheckOutTo',
  RESERVATION_HAS_REVIEW: 'reservationHasReview',
  FLAG_ID: 'flagId',
};

export const twilioErrorCodes = {
  CONNECTION_DECLINED: 31002,
};

export const reservationCalendarDateRangeVariations = {
  [reservationTypes.CLASSIC]: {
    color: 'white',
    backgroundColor: 'b500_v2',
  },
  [reservationTypes.OWNER]: {
    color: 'white',
    backgroundColor: 'n600',
  },
};

export const integrationPlatforms = {
  BOOKINGSYNC: 'bookingsync',
  CHANNEL_MANAGER: 'channel_manager',
};

export const reservationAlterationStatuses = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
};

export const reservationAlterationInitiatedBy = {
  HOST: 'host',
  GUEST: 'guest',
};
