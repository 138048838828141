import React from 'react';
import PropTypes from 'prop-types';

import IconWrapper from '../components/IconWrapper';
import { childrenPropType, variationPropType } from 'ready/utils/propTypes';
import { getVariationOf } from 'ready/styles/helpers';
import { variationsCssProps } from 'ready/utils/constants';

function withIconWrapper(EnhancedComponent) {
  function wrapIcon({ icon, children, ...props }) {
    if (!icon) return <EnhancedComponent {...props}>{children}</EnhancedComponent>;

    return (
      <EnhancedComponent {...props}>
        <IconWrapper
          color={getVariationOf(props.variation, props.active ? variationsCssProps.ACTIVE : variationsCssProps.COLOR)}
          size={props.size}
          {...icon}
        >
          {children}
        </IconWrapper>
      </EnhancedComponent>
    );
  }

  wrapIcon.propTypes = {
    icon: PropTypes.shape(IconWrapper.propTypes),
    variation: variationPropType,
    children: childrenPropType,
    size: IconWrapper.propTypes.size,
    active: PropTypes.bool,
  };

  return wrapIcon;
}

withIconWrapper.positions = IconWrapper.positions;
withIconWrapper.icons = IconWrapper.icons;

export default withIconWrapper;
