import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import Input from 'ready/components/Input/Input';
import { missionPropType } from 'common/propTypes';
import { TextOrLink } from 'components';

const TextArea = styled(Input)`
  height: auto;
  resize: none;
`;

const InPersonForm = ({ checkIn, t, children, onBlur, hasError }) => {
  const [comment, onChange] = useState(checkIn.comment);

  const checklistTitle = checkIn.checklist_url && (
    <TextOrLink href={checkIn.checklist_url} target="_blank">
      {t.checklistTitle}
    </TextOrLink>
  );

  useEffect(() => {
    if (hasError) onChange(checkIn.comment);
  }, [hasError]);

  return (
    <>
      <FullWidthContentWithLabel label={t.agentName}>{checkIn.agent_full_name}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.agentPhoneNumber}>{checkIn.agent_phone_number}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.missionID}>{checkIn.id}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.checklistStatus}>{t[checkIn.checklist_status]}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel>{checklistTitle}</FullWidthContentWithLabel>
      {children}
      <FullWidthContentWithLabel label={t.comments} data-qa="InPersonForm:FullWidthContentWithLabel--comments">
        <TextArea
          data-qa="InPersonForm:TextArea--comments"
          as="textarea"
          name="check-in-mission-comments"
          rows="4"
          value={comment}
          onChange={e => onChange(e.target.value)}
          onBlur={e => onBlur(comment, e)}
        />
      </FullWidthContentWithLabel>
    </>
  );
};

InPersonForm.propTypes = {
  checkIn: missionPropType,
  hasError: PropTypes.bool,
  t: PropTypes.shape({
    instructions: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
  onBlur: PropTypes.func.isRequired,
};

export default InPersonForm;
