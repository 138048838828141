import { fromJS } from 'immutable';

export const FETCH_RESERVATION_ALTERATION_REQUESTED = 'FETCH_RESERVATION_ALTERATION_REQUESTED';
export const fetchReservationAlterationRequested = id => ({
  type: FETCH_RESERVATION_ALTERATION_REQUESTED,
  payload: { id },
});

export const FETCH_RESERVATION_ALTERATION_SUCCESSED = 'FETCH_RESERVATION_ALTERATION_SUCCESSED';
export const fetchReservationAlterationSuccessed = reservationAlteration => ({
  type: FETCH_RESERVATION_ALTERATION_SUCCESSED,
  payload: { reservationAlteration },
});

export const FETCH_RESERVATION_ALTERATION_FAILED = 'FETCH_RESERVATION_ALTERATION_FAILED';
export const fetchReservationAlterationFailed = errors => ({
  type: FETCH_RESERVATION_ALTERATION_FAILED,
  payload: { errors },
});

export const ACCEPT_RESERVATION_ALTERATION_REQUESTED = 'ACCEPT_RESERVATION_ALTERATION_REQUESTED';
export const acceptReservationAlterationRequested = id => ({
  type: ACCEPT_RESERVATION_ALTERATION_REQUESTED,
  payload: { id },
});

export const ACCEPT_RESERVATION_ALTERATION_SUCCESSED = 'ACCEPT_RESERVATION_ALTERATION_SUCCESSED';
export const acceptReservationAlterationSuccessed = () => ({
  type: ACCEPT_RESERVATION_ALTERATION_SUCCESSED,
});

export const ACCEPT_RESERVATION_ALTERATION_FAILED = 'ACCEPT_RESERVATION_ALTERATION_FAILED';
export const acceptReservationAlterationFailed = errors => ({
  type: ACCEPT_RESERVATION_ALTERATION_FAILED,
  payload: { errors },
});

export const REJECT_RESERVATION_ALTERATION_REQUESTED = 'REJECT_RESERVATION_ALTERATION_REQUESTED';
export const rejectReservationAlterationRequested = (id, propose, bookingId) => ({
  type: REJECT_RESERVATION_ALTERATION_REQUESTED,
  payload: { id, propose, bookingId },
});

export const REJECT_RESERVATION_ALTERATION_SUCCESSED = 'REJECT_RESERVATION_ALTERATION_SUCCESSED';
export const rejectReservationAlterationSuccessed = () => ({
  type: REJECT_RESERVATION_ALTERATION_SUCCESSED,
});

export const REJECT_RESERVATION_ALTERATION_FAILED = 'REJECT_RESERVATION_ALTERATION_FAILED';
export const rejectReservationAlterationFailed = errors => ({
  type: REJECT_RESERVATION_ALTERATION_FAILED,
  payload: { errors },
});

export const SET_RESERVATION_ALTERATION_PROPOSAL = 'SET_RESERVATION_ALTERATION_PROPOSAL';
export const setReservationAlterationProposal = data => ({
  type: SET_RESERVATION_ALTERATION_PROPOSAL,
  payload: { data },
});

export const PROPOSE_RESERVATION_ALTERATION_REQUESTED = 'PROPOSE_RESERVATION_ALTERATION_REQUESTED';
export const proposeReservationAlterationRequested = () => ({
  type: PROPOSE_RESERVATION_ALTERATION_REQUESTED,
});

export const PROPOSE_RESERVATION_ALTERATION_SUCCESSED = 'PROPOSE_RESERVATION_ALTERATION_SUCCESSED';
export const proposeReservationAlterationSuccessed = () => ({
  type: PROPOSE_RESERVATION_ALTERATION_SUCCESSED,
});

export const PROPOSE_RESERVATION_ALTERATION_FAILED = 'PROPOSE_RESERVATION_ALTERATION_FAILED';
export const proposeReservationAlterationFailed = errors => ({
  type: PROPOSE_RESERVATION_ALTERATION_FAILED,
  payload: { errors },
});

export const CLEAR_RESERVATION_ALTERATION = 'CLEAR_RESERVATION_ALTERATION';
export const clearReservationAlteration = () => ({
  type: CLEAR_RESERVATION_ALTERATION,
});

export const FETCH_ALTERATION_PRICE_REQUESTED = 'FETCH_ALTERATION_PRICE_REQUESTED';
export const fetchAlterationPriceRequested = (id, data) => ({
  type: FETCH_ALTERATION_PRICE_REQUESTED,
  payload: { id, data },
});

export const FETCH_ALTERATION_PRICE_SUCCESSED = 'FETCH_ALTERATION_PRICE_SUCCESSED';
export const fetchAlterationPriceSuccessed = data => ({
  type: FETCH_ALTERATION_PRICE_SUCCESSED,
  payload: { data },
});

export const FETCH_ALTERATION_PRICE_FAILED = 'FETCH_ALTERATION_PRICE_FAILED';
export const fetchAlterationPriceFailed = errors => ({
  type: FETCH_ALTERATION_PRICE_FAILED,
  payload: { errors },
});

export const initialState = fromJS({
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  didInvalidate: false,
  data: null,
  proposal: null,
  suggestedPrice: {
    newPrice: null,
    minimumPrice: null,
    totalPrice: null,
    cityTax: null,
    cleaningFee: null,
    platformFee: null,
    extraFees: null,
    isFetching: false,
    warning: null,
  },
  errors: {
    context: null,
    creating: false,
    updating: false,
    fetching: false,
    fetchingPrice: false,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RESERVATION_ALTERATION_REQUESTED:
      return state.set('isFetching', true).set('didInvalidate', false);

    case FETCH_RESERVATION_ALTERATION_SUCCESSED:
      return state.set('isFetching', false).set('data', fromJS(payload.reservationAlteration));

    case FETCH_RESERVATION_ALTERATION_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case ACCEPT_RESERVATION_ALTERATION_REQUESTED:
    case REJECT_RESERVATION_ALTERATION_REQUESTED:
      return state.set('isUpdating', true).set('didInvalidate', false);

    case ACCEPT_RESERVATION_ALTERATION_SUCCESSED:
    case REJECT_RESERVATION_ALTERATION_SUCCESSED:
      return state.set('isUpdating', false);

    case ACCEPT_RESERVATION_ALTERATION_FAILED:
    case REJECT_RESERVATION_ALTERATION_FAILED:
      return state
        .set('isUpdating', false)
        .set('didInvalidate', true)
        .setIn(['errors', 'updating'], true)
        .setIn(['errors', 'context'], fromJS(payload.errors));

    case SET_RESERVATION_ALTERATION_PROPOSAL:
      return state.set('proposal', fromJS(payload.data));

    case PROPOSE_RESERVATION_ALTERATION_REQUESTED:
      return state.set('isCreating', true).set('didInvalidate', false);

    case PROPOSE_RESERVATION_ALTERATION_SUCCESSED:
      return state.set('isCreating', false);

    case PROPOSE_RESERVATION_ALTERATION_FAILED:
      return state
        .set('isCreating', false)
        .set('didInvalidate', true)
        .setIn(['errors', 'creating'], true)
        .setIn(['errors', 'context'], fromJS(payload.errors));

    case FETCH_ALTERATION_PRICE_REQUESTED:
      return state
        .setIn(['suggestedPrice', 'newPrice'], null)
        .setIn(['suggestedPrice', 'minimumPrice'], null)
        .setIn(['suggestedPrice', 'totalPrice'], null)
        .setIn(['suggestedPrice', 'cityTax'], null)
        .setIn(['suggestedPrice', 'cleaningFee'], null)
        .setIn(['suggestedPrice', 'platformFee'], null)
        .setIn(['suggestedPrice', 'extraFees'], null)
        .setIn(['suggestedPrice', 'isFetching'], true)
        .setIn(['errors', 'fetchingPrice'], false)
        .set('didInvalidate', false);

    case FETCH_ALTERATION_PRICE_SUCCESSED:
      return state
        .setIn(['suggestedPrice', 'isFetching'], false)
        .setIn(['suggestedPrice', 'newPrice'], fromJS(payload.data.rental_income))
        .setIn(['suggestedPrice', 'minimumPrice'], fromJS(payload.data.minimum_rental_income))
        .setIn(['suggestedPrice', 'totalPrice'], fromJS(payload.data.total_price))
        .setIn(['suggestedPrice', 'cityTax'], fromJS(payload.data.city_tax))
        .setIn(['suggestedPrice', 'cleaningFee'], fromJS(payload.data.cleaning_fee))
        .setIn(['suggestedPrice', 'platformFee'], fromJS(payload.data.platform_fee))
        .setIn(['suggestedPrice', 'extraFees'], fromJS(payload.data.extra_fees));

    case FETCH_ALTERATION_PRICE_FAILED:
      return state
        .set('didInvalidate', true)
        .setIn(['suggestedPrice', 'isFetching'], false)
        .setIn(['errors', 'fetchingPrice'], true)
        .setIn(['errors', 'context'], fromJS(payload.errors));

    case CLEAR_RESERVATION_ALTERATION:
      return state
        .set('data', null)
        .set('proposal', null)
        .setIn(['errors', 'updating'], false)
        .setIn(['errors', 'creating'], false)
        .setIn(['errors', 'context'], null);

    default:
      return state;
  }
};
