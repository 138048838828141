import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { SidebarToggle } from 'components';
import Filters from 'scenes/shared/Filters/Filters';

import { changeIsFilterSidebarOpen } from 'ducks/view';
import { getIsFilterSidebarOpen } from 'selectors/view';

const Toggle = styled(SidebarToggle)`
  border-right: ${props => props.theme.app.border.default};
  border-bottom: ${props => props.theme.app.border.default};
  grid-area: filter-toggle;
`;

const Shadow = styled.div`
  grid-row: 1 / -1;
  grid-column: conversation-list;
  background: ${props => props.theme.app.gradient.filterSidebarShadow};
  width: ${props => props.theme.spacing.s};
  position: relative;
  border-left: ${props => props.theme.app.border.default};
  display: ${props => !props.visible && 'none'};
`;

const MainFiltersScene = ({ isFilterSidebarOpen, dispatchChangeIsFilterSidebarOpen }) => (
  <>
    <Toggle icon={Toggle.icons.FILTERS} onClick={dispatchChangeIsFilterSidebarOpen} />
    <Filters />
    <Shadow visible={isFilterSidebarOpen} />
  </>
);

MainFiltersScene.propTypes = {
  isFilterSidebarOpen: PropTypes.bool,
  dispatchChangeIsFilterSidebarOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
});

const mapDispatchToProps = {
  dispatchChangeIsFilterSidebarOpen: changeIsFilterSidebarOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainFiltersScene);
