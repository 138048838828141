import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container } from './CallOverlay';
import { OverlayBackground } from 'components';
import { Modal, Icon, Text, Button } from 'ready/components';

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: ${props => props.theme.spacing.s};
`;

const StyledText = styled(Text)`
  width: 26.5rem;
`;

const StyledContainer = styled(Container)`
  min-height: 20rem;
  padding: ${props => props.theme.spacing.s};
`;

const CallCenterOfflineWarning = ({ t, onSwitchOn, onSwitchOff }) => (
  <OverlayBackground>
    <Modal heading={t.callCenterOffHeader} size={Modal.sizes.M}>
      <StyledContainer>
        <Icon icon={Icon.icons.CALL_FORWARD} size={Icon.sizes.XL} />
        <StyledText>{t.callCenterOffText}</StyledText>
        <ActionsWrapper>
          <Button variation={Button.variations.OUTLINE} onClick={onSwitchOff}>
            {t.callCenterOffButtonOff}
          </Button>
          <Button variation={Button.variations.PRIMARY} onClick={onSwitchOn}>
            {t.callCenterOffButtonOn}
          </Button>
        </ActionsWrapper>
      </StyledContainer>
    </Modal>
  </OverlayBackground>
);

CallCenterOfflineWarning.propTypes = {
  t: PropTypes.shape({
    callCenterOffButtonOff: PropTypes.string.isRequired,
    callCenterOffButtonOn: PropTypes.string.isRequired,
    callCenterOffHeader: PropTypes.string.isRequired,
    callCenterOffText: PropTypes.string.isRequired,
  }),
  onSwitchOff: PropTypes.func.isRequired,
  onSwitchOn: PropTypes.func.isRequired,
};

export default CallCenterOfflineWarning;
