import styled from 'styled-components';
import { RequestError, FieldGroup } from 'components';
import { Text, Modal } from 'ready/components';

export const StyledRequestError = styled(RequestError)`
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.xxs};
  grid-auto-flow: column;
  justify-content: flex-start;
  padding: ${props => props.theme.spacing.s};
  padding-bottom: ${props => props.theme.spacing.m};
`;

export const SmallStyledRequestError = styled(StyledRequestError)`
  padding: 0;
  padding-bottom: ${props => props.theme.spacing.s};
`;

export const ResponsiveModal = styled(Modal)`
  min-width: 400px;
  @media (max-width: ${props => props.theme.sizes.tablet}px) {
    height: 100%;
    width: 100%;
    min-width: 100%;
  }
`;

export const ActionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spacing.s};

  @media (max-width: ${props => props.theme.sizes.mobileSmall}px) {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    width: 100%;

    & > * {
      margin: ${props => props.theme.spacing.xxs} 0;
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: ${props => `${props.theme.spacing.s} calc(${props.theme.spacing.s} - ${props.theme.spacing.xs})`};

  @media (max-width: ${props => props.theme.sizes.tablet}px) {
    height: calc(100% - 12.5rem);
    overflow-y: auto;
  }

  @media (max-width: ${props => props.theme.sizes.mobileSmall}px) {
    height: calc(100% - 23rem);
  }
`;

export const Footer = styled.footer`
  align-items: center;
  border-top: ${props => props.theme.app.border.default};
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.s};

  @media (max-width: ${props => props.theme.sizes.mobileSmall}px) {
    flex-direction: column-reverse;
  }
`;

export const DateWrapper = styled(FieldGroup)`
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: ${props => props.theme.spacing.xs};
  width: 21.5rem;

  @media (max-width: ${props => props.theme.sizes.tablet}px) {
    width: 100%;
  }
`;

export const DateLabel = styled(Text)`
  grid-column-start: 1;
  grid-column-end: -1;
`;

export const Column = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.xs};
  min-width: 17.3rem;

  @media (max-width: ${props => props.theme.sizes.tablet}px) {
    width: 100%;
  }
`;
