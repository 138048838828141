import React from 'react';
import PropTypes from 'prop-types';
import { fullRentalPropType, officePoliciesPropType } from 'common/propTypes';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { Collapsible } from 'ready/components';
import { ContentWrapper, Paragraph } from 'scenes/shared/InfoSidebar/components';
import TimeConfigurationList from './TimeConfigurationList';

const CheckOut = ({ t, rental, officePolicies }) => (
  <Collapsible title="Check-out" data-qa="Tab:Collapsible--checkOut">
    <ContentWrapper minHeight="0">
      <ContentWithLabel label={t.maxCheckOutTime}>
        <TimeConfigurationList
          times={[
            { hour: rental.office.max_check_out_time, icon: TimeConfigurationList.icons.v2.CITY, hint: t.officeLevel },
            { hour: rental.check_out_time, icon: TimeConfigurationList.icons.v2.HOME, hint: t.propertyLevel },
          ]}
        />
      </ContentWithLabel>
      <FullWidthContentWithLabel label={t.checkOutProcess}>
        {officePolicies.check_out_process_comment && (
          <Paragraph element="div" dangerouslySetInnerHTML={{ __html: officePolicies.check_out_process_comment }} />
        )}
      </FullWidthContentWithLabel>
    </ContentWrapper>
  </Collapsible>
);

CheckOut.propTypes = {
  t: PropTypes.shape({
    maxCheckOutTime: PropTypes.string.isRequired,
    officeLevel: PropTypes.string.isRequired,
    propertyLevel: PropTypes.string.isRequired,
    maxCheckInTime: PropTypes.string.isRequired,
    checkOutProcess: PropTypes.string.isRequired,
  }),
  rental: fullRentalPropType,
  officePolicies: officePoliciesPropType,
};

export default CheckOut;
