import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tag } from 'ready/components';

const StyledTag = styled(Tag)`
  padding: ${props => `${props.theme.spacing.xxxs} ${props.theme.spacing.xxs}`};
`;

const OfficeTag = ({ office }) => {
  if (!office) return false;

  return (
    <StyledTag variation={Tag.variations.PRIMARY} size={Tag.sizes.XS} data-qa="OfficeTag:Tag">
      {office}
    </StyledTag>
  );
};

OfficeTag.propTypes = {
  office: PropTypes.string,
};

export default OfficeTag;
