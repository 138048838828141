import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import {
  fetchMoreMessagesFailed,
  fetchMessagesFailed,
  fetchMoreMessagesSuccessed,
  fetchMessagesSuccessed,
  FETCH_MESSAGES_REQUESTED,
  FETCH_MORE_MESSAGES_REQUESTED,
} from 'ducks/messages';
import { getSelectedConversationId } from 'selectors/conversation';
import { getNextPageLink } from 'selectors/messages';
import DataApi from 'api/DataApi';

export function* handleFetchMoreMessagesRequested() {
  try {
    const nextLink = yield select(getNextPageLink);
    if (!nextLink) return yield put(fetchMoreMessagesSuccessed({ results: [] }));
    const messages = yield call([DataApi.apiInstance(), 'getFromUrl'], nextLink);
    yield put(fetchMoreMessagesSuccessed(messages));
  } catch (e) {
    yield put(fetchMoreMessagesFailed(e.errors || ['SERVER_ERROR.HEADER', 'SERVER_ERROR.MESSAGES_3']));
  }
}

export function* handleFetchMessagesRequested() {
  try {
    const conversationId = yield select(getSelectedConversationId);
    if (!conversationId) return yield put(fetchMessagesSuccessed({ results: [] }));

    const messages = yield call([DataApi.apiInstance(), 'getMessages'], conversationId);
    yield put(fetchMessagesSuccessed(messages));
  } catch (e) {
    yield put(fetchMessagesFailed(e.errors));
  }
}

export default function* watchMessages() {
  yield all([
    takeLatest(FETCH_MESSAGES_REQUESTED, handleFetchMessagesRequested),
    takeLatest(FETCH_MORE_MESSAGES_REQUESTED, handleFetchMoreMessagesRequested),
  ]);
}
