import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Icon } from 'ready/components';

const Wrapper = styled.div`
  display: flex;
  flexflow: column wrap;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.xs} 0;
`;

const AlterationStatusDisplay = ({ status, t }) => {
  const statusDisplay = {
    ACCEPTED: {
      icon: Icon.icons.CHECK,
      color: Icon.colors.success,
      text: t.accepted,
    },
    DECLINED: {
      icon: Icon.icons.CROSS,
      color: Icon.colors.error,
      text: t.rejected,
    },
    PENDING: {
      icon: Icon.icons.MINUS,
      color: Icon.colors.warning,
      text: t.pending,
    },
  };
  return (
    <>
      {statusDisplay[status] && (
        <Wrapper>
          <Icon size={Icon.sizes.L} icon={statusDisplay[status].icon} color={statusDisplay[status].color} />
          <Text>{statusDisplay[status].text}</Text>
        </Wrapper>
      )}
    </>
  );
};

AlterationStatusDisplay.propTypes = {
  status: PropTypes.string.isRequired,
  t: PropTypes.shape({
    accepted: PropTypes.string.isRequired,
    rejected: PropTypes.string.isRequired,
    pending: PropTypes.string.isRequired,
  }),
};

export default AlterationStatusDisplay;
