import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HtmlElement from 'ready/components/HtmlElement';
import { sizes, variations } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';

const { L, M, S } = sizes;

const StyledTextUppercase = styled(HtmlElement)`
  color: ${props => props.theme.colors[props.variation]};
  margin: 0;
  font-weight: ${props => props.theme.typography.fontWeight[props.bold ? 'medium' : 'normal']};
  ${props => ({ ...props.theme.typography.textUppercase[props.size] })};
`;

const TextUppercase = ({ blacklist, ...props }) => (
  <StyledTextUppercase {...props} blacklist={{ ...blacklist, bold: true, size: true, variation: true }} />
);

TextUppercase.sizes = { L, M, S };
TextUppercase.variations = variations;

TextUppercase.propTypes = {
  size: sizePropType(TextUppercase.sizes),
  className: PropTypes.string,
  element: PropTypes.oneOf(['span', 'p']),
  blacklist: PropTypes.object,
};

TextUppercase.defaultProps = {
  variation: variations.DARK,
  element: 'span',
  size: TextUppercase.sizes.M,
  className: '',
  children: null,
};

export default TextUppercase;
