import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from 'ready/components';

const Wrapper = styled.span`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  cursor: pointer;
  user-select: none;
  pointer-events: all;
`;

const SidebarToggle = ({ icon, onClick, className }) => (
  <Wrapper onClick={onClick} className={className}>
    <Icon icon={icon} />
  </Wrapper>
);

SidebarToggle.icons = Icon.icons;

SidebarToggle.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default SidebarToggle;
