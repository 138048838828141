export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const SHORT_DATE_FORMAT = 'DD/MM/YY';
export const SHORT_TIME_FORMAT = 'HH:mm';
export const LONG_TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const sizes = {
  NONE: 'none',
  XXXS: 'xxxs',
  XXS: 'xxs',
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
  XXL: 'xxl',
  XXXL: 'xxxl',
};

export const variations = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  LINK: 'link',
  DARK: 'dark',
  GREY: 'grey',
  WHITE: 'white',
  YELLOW: 'yellow',
  BLUE: 'blue',
  PURPLE: 'purple',
  OUTLINE: 'outline',
  VIOLET: 'violet',
};

export const variationsCssProps = {
  BG: 'backgroundColor',
  FADE_BG: 'fadeBackgroundColor',
  HOVER_BG: 'hoverBackgroundColor',
  COLOR: 'color',
  BORDER_COLOR: 'borderColor',
  ACTIVE: 'active',
};
