import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { InlineTimeSelect } from 'components';
import { Text } from 'ready/components/typography';

import { sizePropType, datePropType, timePropType } from 'ready/utils/propTypes';
import { LONG_TIME_FORMAT, SHORT_TIME_FORMAT } from 'ready/utils/constants';

const StyledInlineTimeSelect = styled(InlineTimeSelect)`
  left: -6px;
`;

const Wrapper = styled.span`
  display: inline-grid;
  grid-column-gap: ${props => props.theme.spacing.xs};
  grid-auto-flow: column;
  justify-content: flex-start;
`;

const BookingDateTime = ({ date, time, t, start, end, onChange, disabled, hasError, 'data-qa': dataQA }) => {
  const _date = moment.utc(date).format('D MMM');
  const [_time, setTime] = useState(time && moment.utc(time, LONG_TIME_FORMAT).format(SHORT_TIME_FORMAT));
  const [value, setSelectedValue] = useState();

  const handleChange = option => {
    setTime(option.value);
    onChange(option);
  };

  useEffect(() => {
    if (_time) setSelectedValue({ value: _time, label: _time });
    else setSelectedValue();
  }, [_time]);

  useEffect(() => {
    if (hasError) setTime(time && moment.utc(time, LONG_TIME_FORMAT).format(SHORT_TIME_FORMAT));
  }, [hasError]);

  return (
    <Wrapper data-qa={dataQA}>
      <Text data-qa="BookingDateTime:Text">{_date}</Text>
      <StyledInlineTimeSelect
        placeholder={t.tbc}
        t={t}
        value={value}
        range={15}
        start={start}
        end={end}
        onChange={handleChange}
        closeMenuOnSelect
        blurInputOnSelect
        disabled={disabled}
        data-qa="BookingDateTime:InlineTimeSelect"
        textProps={{
          bold: true,
          variation: InlineTimeSelect.variations.PRIMARY,
        }}
      />
    </Wrapper>
  );
};

BookingDateTime.propTypes = {
  className: PropTypes.string,
  date: datePropType.isRequired,
  time: timePropType,
  size: sizePropType(Text),
  tbc: PropTypes.bool,
  start: timePropType.isRequired,
  end: timePropType.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  'data-qa': PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  t: PropTypes.shape({
    tbc: PropTypes.string,
    noResultsText: PropTypes.string,
  }),
};

BookingDateTime.defaultProps = {
  'data-qa': 'BookingDateTime:__wrapper',
  time: '',
};

export default BookingDateTime;
