/* globals Twilio */
import { all, take, takeLatest, call, put, select, fork, takeLeading, throttle } from 'redux-saga/effects';
import { Device } from 'twilio-client';
import * as Sentry from '@sentry/react';
import { connectionTypes } from 'constants.js';

import {
  SETUP_CALL_CENTER_REQUESTED,
  setupCallCenterFinished,
  enableTaskRouter,
  callCenterError,
  setDeviceStatus,
  setDeviceError,
  connectCall,
  callEnded,
  PLACE_CALL,
  cancelCall,
  CANCEL_CALL,
  END_CALL,
  MUTE_CALL_REQUESTED,
  muteCallSuccessed,
  CLOSE_CALL,
  changeViewStatus,
  enableConnection,
  SUBMIT_NOTE_REQUESTED,
  submitNoteFailed,
  submitNoteSuccessed,
  SUBMIT_CALL_REASON_REQUESTED,
  submitCallReasonRequested,
  submitCallReasonFailed,
  submitCallReasonSuccessed,
  REJECT_CALL,
  ANSWER_CALL,
  fetchCallInfoFailed,
  FETCH_CALL_INFO_REQUESTED,
  fetchCallInfoRequested,
  FETCH_CALL_INFO_BY_SID_REQUESTED,
  fetchCallInfoBySidRequested,
  fetchCallInfoSuccessed,
  closeCall,
  endCall,
  EXPAND_CALL,
  offTaskRouter,
  fetchTaskRouterActivitiesSuccessed,
  UPDATE_TASK_ROUTER_STATUS_REQUESTED,
  SEND_DIGIT,
  entities,
  setCallCenterConversationIfAllowed,
  PLACE_CUSTOM_CALL,
  updateTaskRouterStatusSuccessed,
  updateTaskRouterStatusRequested,
  REJECT_RESERVATION,
  rejectReservation,
  ENABLE_CONNECTION,
  CALL_CENTER_ERROR,
  CALL_CENTER_START_TIMER_ERROR,
  callCenterStartTimerError,
  FETCH_WORKER_LIST_REQUESTED,
  fetchWorkerListSuccessed,
  fetchWorkerListFailed,
  FORWARD_CALL_REQUESTED,
  forwardCallFailed,
  forwardCallSuccessed,
  setupCallForwardingFinished,
  fetchTransferInfoRequested,
  FETCH_TRANSFER_INFO_REQUESTED,
  fetchTransferInfoSuccessed,
  CHANGE_PAGE,
  fetchWorkerListRequested,
  callIncoming,
  changeOutgoingPhoneNumber,
  FETCH_CALL_REASONS_REQUESTED,
  fetchCallReasonsRequested,
  fetchCallReasonsSuccessed,
  FETCH_TWILIO_TOKENS_REQUESTED,
  fetchTwilioTokensSuccessed,
  fetchTwilioTokensFailed,
  reinitializeTwilioDevice,
  REINITIALIZE_TWILIO_DEVICE,
  UPDATE_WORKER_ACTIVITY_LOG,
  updateWorkerActivityLog,
  HANDLE_CONNECTION_FAILED,
  handleConnectionFailed,
} from 'ducks/callCenter';
import DataApi from 'api/DataApi';
import { callCenterNotePayload, callCenterCallReasonPayload } from 'utils/deserializers';
import { getSelectedConversationId, getConversationData } from 'selectors/conversation';
import { sendMessageSuccessed } from 'ducks/message';
import store from 'store';
import {
  twilioDeviceEvents,
  twilioDeviceStatuses,
  callCenterStatuses,
  paths,
  twilioConnectionStatuses,
  taskRouterActivities,
  taskRouterEvents,
  callCenterPages,
  taskRouterReservationStatuses,
  SETUP_RETRY_INTERVAL,
  twilioErrorCodes,
} from 'constants.js';
import {
  getCallCenterNote,
  getCallCenter,
  getCallCenterConversation,
  getCallCenterData,
  getCallCenterViewStatus,
  getCallCenterOutgoingPhoneNumber,
  getTaskRouterActivitySid,
  getTaskRouterActivityName,
  getTaskRouterOnPause,
  getCallSid,
  getTaskRouterWorkerToken,
  getCallCenterDeviceToken,
} from 'selectors/callCenter';
import { getUserData, getTwilioPhoneNumbers } from 'selectors/user';
import TaskRouterApi from 'api/TaskRouterApi';
import history, { changeConversationRoute } from 'browserHistory';
import { fetchInitConversationRequested, fetchConversationRequested } from 'ducks/conversation';
import { changeFilters } from 'ducks/filters';
import { isTaskRouterActivityOff } from 'utils/businessLogic';
import { getDefaultOutgoingPhoneNumber } from 'utils/serializers';
import { createSupportLog } from 'utils/errors';

function* pushToCallCenterConversationIfHas() {
  const conversation = yield select(getCallCenterConversation);
  if (!conversation) return;

  yield all([
    call(history.push, `${paths.APP}/${conversation.id}`),
    put(changeFilters({})),
    put(fetchInitConversationRequested(conversation.id)),
  ]);
}

function* handleSubmitCallReasonRequested({ payload: { callReason, call_sid, conversation } }) {
  try {
    if (conversation) {
      const message = yield call(
        [DataApi.apiInstance(), 'postMessage'],
        callCenterCallReasonPayload(conversation.id, callReason.content_en),
      );
      yield put(sendMessageSuccessed(message));
    }

    yield call([TaskRouterApi.apiInstance(), 'setCallData'], { call_reason: callReason.id, call_sid });
    yield put(submitCallReasonSuccessed());
  } catch (e) {
    yield put(submitCallReasonFailed(e.errors));
  }
}

export function* registerDeviceEvents(device) {
  /**
   * For calls coming via the task router,
   * the connection has twilio details instead of caller details.
   * For transferred calls, the connection has the caller details.
   */
  let conn = null;
  let callCenterPreviousState;
  device.on(twilioDeviceEvents.READY, function() {
    store.dispatch(setDeviceStatus(twilioDeviceStatuses.READY));
  });

  device.on(twilioDeviceEvents.ERROR, function(e) {
    if (e.code === twilioErrorCodes.CONNECTION_DECLINED) {
      store.dispatch(handleConnectionFailed());
    } else {
      Sentry.captureException(e, {
        level: 'log',
        tags: { ...(e.twilioError && { twilioErrorCode: e.twilioError.code }) },
      });
      store.dispatch(callCenterStartTimerError(entities.DEVICE, e));
      store.dispatch(setDeviceError(e.message));
    }
  });

  device.on(twilioDeviceEvents.OFFLINE, function() {
    store.dispatch(setDeviceStatus(twilioDeviceStatuses.OFFLINE));
    store.dispatch(reinitializeTwilioDevice());
  });

  device.on(twilioDeviceEvents.INCOMING, function(connection) {
    // when receiving a call, store the previous call center state so we can reinstate it if the call is rejected
    callCenterPreviousState = getCallCenter(store.getState());
    conn = connection;
    const from = conn && conn.parameters.From;
    store.dispatch(callIncoming());
    store.dispatch(fetchTransferInfoRequested({ phone: from }));
  });

  device.on(twilioDeviceEvents.CONNECT, function(connection) {
    conn = connection;
    const callCenterState = getCallCenter(store.getState());
    const callCenterConnectionType = callCenterState.connection.type;
    const call_sid =
      callCenterConnectionType === connectionTypes.OUTBOUND
        ? connection.parameters.CallSid
        : callCenterState.data.call_sid;
    store.dispatch(connectCall(connection));
    store.dispatch(fetchCallInfoBySidRequested(call_sid));
    connection.on('disconnect', () => {
      store.dispatch(callEnded());
    });
    connection.on('mute', () => {
      store.dispatch(muteCallSuccessed(conn.isMuted()));
    });
  });

  function placeCall({ phoneNumber, callerNumber }) {
    device.connect({ phoneNumber, callerNumber });
    store.dispatch(updateTaskRouterStatusRequested(taskRouterActivities.BUSY));
  }

  function* handleConnectionFailedRequested() {
    store.dispatch(cancelCall());
    const onPause = yield select(getTaskRouterOnPause);
    store.dispatch(
      updateTaskRouterStatusRequested(onPause ? taskRouterActivities.PAUSED : taskRouterActivities.AVAILABLE),
    );
  }

  function* handlePlaceCustomCall({ payload: { phoneNumber } }) {
    const outgoingPhoneNumber = yield select(getCallCenterOutgoingPhoneNumber);
    placeCall({ phoneNumber, callerNumber: outgoingPhoneNumber });
  }

  function* handlePlaceCall() {
    const { guest, outgoingPhoneNumber } = yield select(getCallCenterData);
    placeCall({ phoneNumber: guest.phone_number, callerNumber: outgoingPhoneNumber });
  }

  function* handleForwardCallRequested({ payload: { target } }) {
    try {
      const call_sid = yield select(getCallSid);
      yield call([TaskRouterApi.apiInstance(), 'transferCall'], { target: target.friendlyName, call_sid });
      yield put(forwardCallSuccessed());
    } catch (e) {
      yield put(forwardCallFailed({ message: e.message || 'Call forwarding failed' }));
    }
  }

  function handleCancelCall() {
    device.disconnectAll();
    if (conn) {
      conn.ignore();
      conn.disconnect();
    }
    store.dispatch(enableConnection());
  }

  function handleMuteCall() {
    conn.mute(!conn.isMuted());
  }

  function* handleEndCall() {
    const viewStatus = yield select(getCallCenterViewStatus);
    const call_sid = yield select(getCallSid);
    if (viewStatus === callCenterStatuses.MINIMIZED) yield call(handleExpandCall);
    try {
      yield call([TaskRouterApi.apiInstance(), 'sendFeedbackSurvey'], { call_sid });
    } catch (e) {
      conn.disconnect();
    }
  }

  function* handleCloseCall() {
    const {
      data: { conversation, callReason, call_sid },
      connection: { status },
    } = yield select(getCallCenter);

    // submit call reason when call center is closed after a call
    if (status === twilioConnectionStatuses.COMPLETED) {
      yield put(submitCallReasonRequested({ call_sid, callReason, conversation }));
    }

    handleCancelCall();
    yield put(changeViewStatus(callCenterStatuses.CLOSED));

    const activityName = yield select(getTaskRouterActivityName);
    if (isTaskRouterActivityOff({ activityName })) return;

    yield put(rejectReservation());

    const onPause = yield select(getTaskRouterOnPause);
    yield put(updateTaskRouterStatusRequested(onPause ? taskRouterActivities.PAUSED : taskRouterActivities.AVAILABLE));
  }

  function* handleRejectCall() {
    conn.reject();
    if (callCenterPreviousState) {
      yield all([
        put(changeViewStatus(callCenterPreviousState.view.status)),
        put(setCallCenterConversationIfAllowed(callCenterPreviousState.data.conversation)),
      ]);
    }
  }

  function* handleAnswer() {
    conn.accept();
    yield call(pushToCallCenterConversationIfHas);
  }

  function* handleExpandCall() {
    const selectedConversationId = yield select(getSelectedConversationId);
    const callCenterConversation = yield select(getCallCenterConversation);
    /**
     * Note: This will only work for OUTBOUND calls,
     * since INBOUND calls does not have search query
     */
    if (selectedConversationId && callCenterConversation && selectedConversationId !== callCenterConversation.id) {
      yield call(changeConversationRoute, callCenterConversation.id);
      yield put(fetchConversationRequested(callCenterConversation.id));
    }

    yield put(changeViewStatus(callCenterStatuses.OPENED));
  }

  function handleSendDigit({ payload: { digit } }) {
    if (digit !== 0 && !digit) return;
    /**
     * Send Digits to twilio API
     * @params {String} digit Twilio.Connection.sendDigits expects a string, thus the casting
     */
    conn.sendDigits(`${digit}`);
  }

  function* handleReinitializeTwilioDevice() {
    try {
      const { call_token } = yield call([TaskRouterApi.apiInstance(), 'getTokens']);
      device.setup(call_token, device.options);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Couldn't re-fetch call token from RentalReady");
    }
  }

  function* handleUpdateWorkerActivityLog({ payload: { activityName } }) {
    try {
      yield call([TaskRouterApi.apiInstance(), 'postWorkerActivityLog'], activityName);
    } catch (e) {
      return;
    }
  }

  yield all([
    takeLatest(PLACE_CUSTOM_CALL, handlePlaceCustomCall),
    takeLatest(FORWARD_CALL_REQUESTED, handleForwardCallRequested),
    takeLatest(PLACE_CALL, handlePlaceCall),
    takeLatest(CANCEL_CALL, handleCancelCall),
    takeLatest(MUTE_CALL_REQUESTED, handleMuteCall),
    takeLatest(END_CALL, handleEndCall),
    takeLatest(CLOSE_CALL, handleCloseCall),
    takeLatest(REJECT_CALL, handleRejectCall),
    takeLatest(ANSWER_CALL, handleAnswer),
    takeLatest(EXPAND_CALL, handleExpandCall),
    takeLatest(SEND_DIGIT, handleSendDigit),
    takeLatest(UPDATE_WORKER_ACTIVITY_LOG, handleUpdateWorkerActivityLog),
    takeLatest(HANDLE_CONNECTION_FAILED, handleConnectionFailedRequested),
    throttle(SETUP_RETRY_INTERVAL, REINITIALIZE_TWILIO_DEVICE, handleReinitializeTwilioDevice),
  ]);
}

function* handleFetchCallInfo({ payload: { phone } }) {
  try {
    const callDetails = yield call([TaskRouterApi.apiInstance(), 'getCallDetails'], phone);
    yield put(fetchCallInfoSuccessed(callDetails));
  } catch (e) {
    yield put(fetchCallInfoFailed(e));
  }
}

function* handleFetchCallInfoBySid({ payload: { call_sid } }) {
  try {
    const callsDetails = yield call([TaskRouterApi.apiInstance(), 'getCallDetailsBySid'], call_sid);
    yield put(fetchCallInfoSuccessed(callsDetails[0]));
  } catch (e) {
    yield put(fetchCallInfoFailed(e));
  }
}

function* handleFetchTransferInfo({ payload: { phone } }) {
  try {
    const transfer = yield call([TaskRouterApi.apiInstance(), 'getTransfer'], phone);
    if (transfer) yield put(fetchTransferInfoSuccessed(transfer.call_settings));
  } catch (e) {
    // do nothing - this just means the incoming call is not a forwarded call
  }
}

export function* registerTaskRouterEvents(taskRouter) {
  let reservation = null;

  taskRouter.on(taskRouterEvents.READY, function() {
    store.dispatch(enableTaskRouter());
    store.dispatch(updateTaskRouterStatusRequested(taskRouterActivities.AVAILABLE));
  });

  taskRouter.on(taskRouterEvents.ERROR, function(e) {
    Sentry.captureException(e, { level: 'log' });
    store.dispatch(callCenterStartTimerError(entities.TASK_ROUTER, e));
  });

  /**
   * The only way to get caller number is through task router reservation
   * that is why there is a listening on reservation.created event
   */
  taskRouter.on(taskRouterEvents.RESERVATION_CREATED, function(_reservation) {
    reservation = _reservation;
    const phone = _reservation.task.attributes.from;
    store.dispatch(fetchCallInfoRequested(phone));
  });

  taskRouter.on(taskRouterEvents.RESERVATION_CANCELED, function() {
    reservation = null;
    const { status } = getCallCenter(store.getState()).connection;
    /**
     * If connection status is not in progress it means it is "ringing",
     * so if it is canceled by caller before staff answering it, then cancel and close call
     */
    if (status !== twilioConnectionStatuses.IN_PROGRESS) {
      store.dispatch(closeCall());
      const selectedConversation = getConversationData(store.getState());
      store.dispatch(setCallCenterConversationIfAllowed(selectedConversation));
      return;
    }
    /**
     * Otherwise end call in a way to required notes if applied
     */
    store.dispatch(endCall());
  });

  taskRouter.on(taskRouterEvents.RESERVATION_ACCEPTED, function(_reservation) {
    reservation = _reservation;
  });

  taskRouter.on(taskRouterEvents.RESERVATION_COMPLETED, function() {
    reservation = null;
  });

  taskRouter.on(taskRouterEvents.RESERVATION_TIMEOUT, function() {
    reservation = null;
    store.dispatch(closeCall());
  });

  taskRouter.on(taskRouterEvents.ACTIVITY_UPDATE, function(worker) {
    store.dispatch(updateTaskRouterStatusRequested(worker.activityName));
    switch (worker.activityName) {
      case taskRouterActivities.IDLE:
      case taskRouterActivities.AVAILABLE:
      case taskRouterActivities.PAUSED:
        store.dispatch(enableTaskRouter());
        break;
      case taskRouterActivities.UNAVAILABLE:
      case taskRouterActivities.OFFLINE:
        store.dispatch(changeViewStatus(callCenterStatuses.CLOSED));
        store.dispatch(offTaskRouter(worker.activityName));
        break;
      default:
        store.dispatch(updateTaskRouterStatusSuccessed(worker.activityName));
    }
  });

  function* handleUpdateTaskRouterStatus({ payload: { activityName } }) {
    const oldActivityName = yield select(getTaskRouterActivityName);
    if (oldActivityName === activityName) return;

    const ActivitySid = yield select(getTaskRouterActivitySid, activityName);
    taskRouter.update({ ActivitySid });
    store.dispatch(updateTaskRouterStatusSuccessed(activityName));
    store.dispatch(updateWorkerActivityLog(activityName));
  }

  function* handleRejectReservation() {
    if (!reservation) return;
    if (reservation.reservationStatus === taskRouterReservationStatuses.PENDING) {
      const pausedActivitySid = yield select(getTaskRouterActivitySid, taskRouterActivities.PAUSED);
      return reservation.reject(pausedActivitySid);
    }
    if (reservation.reservationStatus === taskRouterReservationStatuses.ACCEPTED) return reservation.complete();
  }

  yield all([
    takeLatest(UPDATE_TASK_ROUTER_STATUS_REQUESTED, handleUpdateTaskRouterStatus),
    takeLatest([REJECT_RESERVATION, REJECT_CALL], handleRejectReservation),
  ]);
}

export function* registerWorkspaceEvents(workspace) {
  const currentUser = yield select(getUserData);

  function* handleFetchWorkerList() {
    yield workspace.workers.fetch({ Available: true }, function(e, workerList) {
      if (e) {
        Sentry.captureException(e, { level: 'log' });
        const message = e.message || 'Failed to fetch list of agents';
        store.dispatch(fetchWorkerListFailed({ message }));
        return;
      }

      const workers = workerList.data
        .filter(worker => worker.attributes.username !== currentUser.username)
        .map(worker => ({
          username: worker.attributes.username,
          friendlyName: worker.friendlyName,
        }));

      store.dispatch(fetchWorkerListSuccessed(workers));
    });
  }

  yield put(setupCallForwardingFinished());
  yield all([takeLatest(FETCH_WORKER_LIST_REQUESTED, handleFetchWorkerList)]);
}

function fetchTaskRouterActivities(taskRouter) {
  taskRouter.activities.fetch(function(e, activityList) {
    if (e) {
      store.dispatch(callCenterError(entities.TASK_ROUTER, { message: e.message }));
      createSupportLog('Failed to fetch task router activities from Twilio:', e);
      return;
    }

    const activities = activityList.data.reduce((acc, activity) => {
      acc[activity.friendlyName] = activity.sid;
      return acc;
    }, {});
    store.dispatch(fetchTaskRouterActivitiesSuccessed(activities));
  });
}

function* setDefaultOutgoingPhoneNumber() {
  const twilioPhoneNumbers = yield select(getTwilioPhoneNumbers);
  const outgoingPhoneNumber = getDefaultOutgoingPhoneNumber(twilioPhoneNumbers);
  yield put(changeOutgoingPhoneNumber(outgoingPhoneNumber));
}

function* handleFetchCallReasonsRequested() {
  try {
    const callReasons = yield call([TaskRouterApi.apiInstance(), 'getCallReasons']);
    yield put(fetchCallReasonsSuccessed(callReasons));
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* handleFetchTwilioTokensRequested() {
  try {
    const tokens = yield call([TaskRouterApi.apiInstance(), 'getTokens']);
    yield put(fetchTwilioTokensSuccessed(tokens));
  } catch (e) {
    yield put(fetchTwilioTokensFailed(e));
  }
}

function* handleSetupCallCenter() {
  const workerToken = yield select(getTaskRouterWorkerToken);
  const callToken = yield select(getCallCenterDeviceToken);
  const { token: workspace_token } = yield call([TaskRouterApi.apiInstance(), 'getManagerToken']);

  yield put(fetchCallReasonsRequested());
  yield setDefaultOutgoingPhoneNumber();

  if (!window.callCenterDevice) {
    const device = new Device(callToken, {
      codecPreferences: ['opus', 'pcmu'],
      fakeLocalDTMF: true,
      enableRingingState: true,
    });
    window.callCenterDevice = device;
    yield fork(registerDeviceEvents, device);
  }

  try {
    if (!window.callCenterTaskRouter) {
      const taskRouter = new Twilio.TaskRouter.Worker(workerToken);
      window.callCenterTaskRouter = taskRouter;

      fetchTaskRouterActivities(taskRouter);
      yield fork(registerTaskRouterEvents, taskRouter);
    }

    if (!window.callCenterWorkspace) {
      const workspace = new Twilio.TaskRouter.Workspace(workspace_token);
      window.callCenterWorkspace = workspace;

      yield fork(registerWorkspaceEvents, workspace);
    }
  } catch (e) {
    Sentry.captureException(e, { level: 'log' });
    store.dispatch(callCenterError(entities.TASK_ROUTER, { message: e.message }));
  }

  yield put(setupCallCenterFinished());
}

export function* handleSubmitNoteRequested() {
  try {
    const note = yield select(getCallCenterNote);
    const {
      data: { conversation, call_sid },
    } = yield select(getCallCenter);
    const message = yield call(
      [DataApi.apiInstance(), 'postMessage'],
      callCenterNotePayload(conversation.id, note, call_sid),
    );
    yield put(sendMessageSuccessed(message));
    yield put(submitNoteSuccessed());
  } catch (e) {
    yield put(submitNoteFailed(e.errors));
  }
}

export function* handleCallCenterError({ payload: { entity, errors } }) {
  const timeoutId = setTimeout(() => {
    store.dispatch(callCenterError(entity, { message: errors.message }));
  }, 1000 * 60);
  /**
   * If something is wrong with either TaskRouter or Device whenever they both are ready again
   * connection will be enable, thus timeout is cleared
   * If it display the error, it should start again the timer in order to display again and
   * it will only take action again if it completes the current execution
   */
  yield take([ENABLE_CONNECTION, CALL_CENTER_ERROR]);
  clearTimeout(timeoutId);
}

export function* handleChangePage({ payload: { page } }) {
  if (page === callCenterPages.FORWARD_LIST) {
    yield put(fetchWorkerListRequested());
  }
}

export default function* watchCallCenter() {
  yield all([
    takeLeading(FETCH_TWILIO_TOKENS_REQUESTED, handleFetchTwilioTokensRequested),
    takeLatest(SETUP_CALL_CENTER_REQUESTED, handleSetupCallCenter),
    takeLatest(SUBMIT_NOTE_REQUESTED, handleSubmitNoteRequested),
    takeLatest(FETCH_CALL_INFO_REQUESTED, handleFetchCallInfo),
    takeLatest(FETCH_CALL_INFO_BY_SID_REQUESTED, handleFetchCallInfoBySid),
    takeLatest(FETCH_TRANSFER_INFO_REQUESTED, handleFetchTransferInfo),
    takeLatest(FETCH_CALL_REASONS_REQUESTED, handleFetchCallReasonsRequested),
    takeLatest(SUBMIT_CALL_REASON_REQUESTED, handleSubmitCallReasonRequested),
    takeLeading(CALL_CENTER_START_TIMER_ERROR, handleCallCenterError),
    takeLeading(CHANGE_PAGE, handleChangePage),
  ]);
}
