import React from 'react';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';

import { Text } from 'ready/components/typography';

const CurrencyText = ({ price, currency, ...props }) => (
  /* eslint react/style-prop-object: 0 */
  <FormattedNumber value={price} currency={currency} style="currency">
    {number => <Text {...props}>{number}</Text>}
  </FormattedNumber>
);

CurrencyText.sizes = Text.sizes;
CurrencyText.variations = Text.variations;

CurrencyText.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

CurrencyText.defaultProps = {
  currency: 'EUR',
};

export default CurrencyText;
