import { useEffect } from 'react';
import { setIsTabActive } from 'ducks/view';

function useIsTabActive({ dispatch }) {
  const onFocus = () => dispatch(setIsTabActive(true));
  const onBlur = () => dispatch(setIsTabActive(false));

  return useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  });
}

export default useIsTabActive;
