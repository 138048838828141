import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import logo from 'assets/images/GR_Logo2x.png';

const LogoImage = styled.img`
  max-width: 16rem;
`;

const Logo = ({ className, width }) => {
  return <LogoImage className={className} src={logo} width={width} alt="GuestReady Logo" />;
};

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  width: '100%',
};

export default Logo;
