import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Input } from 'ready/components';

export const FieldGroup = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.xxs};
  width: 100%;
`;

const Field = ({ className, name, id = name, label, errors, 'data-qa': dataQA, ...props }) => {
  const labelElement = label && (
    <Text as="label" bold htmlFor={id} data-qa="Field:Text" size={Text.sizes.S}>
      {label}
    </Text>
  );

  const errorElement =
    errors &&
    [].concat(errors).map((error, i) => (
      <Text key={`error_${i}`} size={Text.sizes.S} bold variation={Text.variations.ERROR} data-qa="Field:Text--error">
        {error}
      </Text>
    ));

  return (
    <FieldGroup className={className} data-qa={`[app]${dataQA}`}>
      {labelElement}
      <Input error={!!errors} name={name} id={id} data-qa="Field:Input" {...props} />
      {errorElement}
    </FieldGroup>
  );
};

Field.defaultProps = {
  'data-qa': 'Field:__wrapper',
};

Field.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'data-qa': PropTypes.string,
};

export default Field;
