import { fromJS } from 'immutable';

import {
  twilioDeviceStatuses,
  twilioConnectionStatuses,
  connectionTypes,
  callCenterStatuses,
  taskRouterActivities,
  callCenterPages,
  callForwardingStatuses,
  callCenterTabs,
} from 'constants.js';
import { isCallCenterConversationChangeAllowed, shouldEnableGuestCall } from 'utils/businessLogic';

export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS';
export const setDeviceStatus = status => ({ type: SET_DEVICE_STATUS, payload: { status } });

export const SET_DEVICE_ERROR = 'SET_DEVICE_ERROR';
export const setDeviceError = error => ({ type: SET_DEVICE_ERROR, payload: { error } });

export const ENABLE_TASK_ROUTER = 'ENABLE_TASK_ROUTER';
export const enableTaskRouter = () => ({ type: ENABLE_TASK_ROUTER });

export const ENABLE_CONNECTION = 'ENABLE_CONNECTION';
export const enableConnection = () => ({ type: ENABLE_CONNECTION });
/**
 * Cancel call action to disconnect before connection is established, before recipient has answered
 * Connection status is is CALLING when action is triggered
 */
export const CANCEL_CALL = 'CANCEL_CALL';
export const cancelCall = () => ({ type: CANCEL_CALL });
/**
 * End call action to disconnect anytime after connection is established, after recipient has answered
 * Connection status is is IN_PROGRESS when action is triggered
 */
export const END_CALL = 'END_CALL';
export const endCall = () => ({ type: END_CALL });
/**
 * Call is finished either from staff or guest
 */
export const CALL_ENDED = 'CALL_ENDED';
export const callEnded = () => ({ type: CALL_ENDED });

export const CALL_INCOMING = 'CALL_INCOMING';
export const callIncoming = () => ({ type: CALL_INCOMING });

export const PLACE_CALL = 'PLACE_CALL';
export const placeCall = () => ({ type: PLACE_CALL });

export const PLACE_CUSTOM_CALL = 'PLACE_CUSTOM_CALL';
export const placeCustomCall = phoneNumber => ({ type: PLACE_CUSTOM_CALL, payload: { phoneNumber } });

export const CHANGE_VIEW_STATUS = 'CHANGE_VIEW_STATUS';
export const changeViewStatus = status => ({ type: CHANGE_VIEW_STATUS, payload: { status } });

export const SETUP_CALL_CENTER_REQUESTED = 'SETUP_CALL_CENTER_REQUESTED';
export const setupCallCenterRequested = () => ({ type: SETUP_CALL_CENTER_REQUESTED });

export const SETUP_CALL_CENTER_FINISHED = 'SETUP_CALL_CENTER_FINISHED';
export const setupCallCenterFinished = () => ({ type: SETUP_CALL_CENTER_FINISHED });

export const SETUP_CALL_FORWARDING_FINISHED = 'SETUP_CALL_FORWARDING_FINISHED';
export const setupCallForwardingFinished = () => ({ type: SETUP_CALL_FORWARDING_FINISHED });

export const CONNECT_CALL = 'CONNECT_CALL';
export const connectCall = connection => ({ type: CONNECT_CALL, payload: { connection } });

export const MUTE_CALL_REQUESTED = 'MUTE_CALL_REQUESTED';
export const muteCallRequested = () => ({ type: MUTE_CALL_REQUESTED });

export const MUTE_CALL_SUCCESSED = 'MUTE_CALL_SUCCESSED';
export const muteCallSuccessed = muted => ({ type: MUTE_CALL_SUCCESSED, payload: { muted } });

export const CLOSE_CALL = 'CLOSE_CALL';
export const closeCall = () => ({ type: CLOSE_CALL });

export const EXPAND_CALL = 'EXPAND_CALL';
export const expandCall = () => ({ type: EXPAND_CALL });

export const CHANGE_NOTE = 'CHANGE_NOTE';
export const changeNote = note => ({ type: CHANGE_NOTE, payload: { note } });

export const SUBMIT_NOTE_REQUESTED = 'SUBMIT_NOTE_REQUESTED';
export const submitNoteRequested = () => ({ type: SUBMIT_NOTE_REQUESTED });

export const SUBMIT_NOTE_SUCCESSED = 'SUBMIT_NOTE_SUCCESSED';
export const submitNoteSuccessed = () => ({ type: SUBMIT_NOTE_SUCCESSED });

export const SUBMIT_NOTE_FAILED = 'SUBMIT_NOTE_FAILED';
export const submitNoteFailed = errors => ({ type: SUBMIT_NOTE_FAILED, payload: { errors } });

export const CHANGE_CALL_REASON = 'CHANGE_CALL_REASON';
export const changeCallReason = callReason => ({ type: CHANGE_CALL_REASON, payload: { callReason } });

export const SUBMIT_CALL_REASON_REQUESTED = 'SUBMIT_CALL_REASON_REQUESTED';
export const submitCallReasonRequested = ({ callReason, call_sid, conversation }) => ({
  type: SUBMIT_CALL_REASON_REQUESTED,
  payload: { callReason, call_sid, conversation },
});

export const SUBMIT_CALL_REASON_SUCCESSED = 'SUBMIT_CALL_REASON_SUCCESSED';
export const submitCallReasonSuccessed = () => ({ type: SUBMIT_CALL_REASON_SUCCESSED });

export const SUBMIT_CALL_REASON_FAILED = 'SUBMIT_CALL_REASON_FAILED';
export const submitCallReasonFailed = errors => ({ type: SUBMIT_CALL_REASON_FAILED, payload: { errors } });

export const RECEIVING_CALL = 'RECEIVING_CALL';
export const receivingCall = phone => ({ type: RECEIVING_CALL, payload: { phone } });

export const ANSWER_CALL = 'ANSWER_CALL';
export const answerCall = () => ({ type: ANSWER_CALL });

export const FORWARD_CALL_REQUESTED = 'FORWARD_CALL_REQUESTED';
export const forwardCallRequested = ({ target, call_sid }) => ({
  type: FORWARD_CALL_REQUESTED,
  payload: { target, call_sid },
});

export const FORWARD_CALL_SUCCESSED = 'FORWARD_CALL_SUCCESSED';
export const forwardCallSuccessed = () => ({ type: FORWARD_CALL_SUCCESSED });

export const FORWARD_CALL_FAILED = 'FORWARD_CALL_FAILED';
export const forwardCallFailed = errors => ({ type: FORWARD_CALL_FAILED, payload: { errors } });

export const CLEAR_CALL_FORWARDING_ERRORS = 'CLEAR_CALL_FORWARDING_ERRORS';
export const clearCallForwardingErrors = () => ({ type: CLEAR_CALL_FORWARDING_ERRORS });

export const REJECT_CALL = 'REJECT_CALL';
export const rejectCall = () => ({ type: REJECT_CALL });

export const REJECT_RESERVATION = 'REJECT_RESERVATION';
export const rejectReservation = () => ({ type: REJECT_RESERVATION });

export const FETCH_TWILIO_TOKENS_REQUESTED = 'FETCH_TWILIO_TOKENS_REQUESTED';
export const fetchTwilioTokensRequested = () => ({ type: FETCH_TWILIO_TOKENS_REQUESTED });

export const FETCH_TWILIO_TOKENS_SUCCESSED = 'FETCH_TWILIO_TOKENS_SUCCESSED';
export const fetchTwilioTokensSuccessed = ({ call_token, worker_token }) => ({
  type: FETCH_TWILIO_TOKENS_SUCCESSED,
  payload: { call_token, worker_token },
});

export const FETCH_TWILIO_TOKENS_FAILED = 'FETCH_TWILIO_TOKENS_FAILED';
export const fetchTwilioTokensFailed = errors => ({ type: FETCH_TWILIO_TOKENS_FAILED, payload: { errors } });

export const FETCH_CALL_INFO_REQUESTED = 'FETCH_CALL_INFO_REQUESTED';
export const fetchCallInfoRequested = phone => ({ type: FETCH_CALL_INFO_REQUESTED, payload: { phone } });

export const FETCH_CALL_INFO_BY_SID_REQUESTED = 'FETCH_CALL_INFO_BY_SID_REQUESTED';
export const fetchCallInfoBySidRequested = call_sid => ({
  type: FETCH_CALL_INFO_BY_SID_REQUESTED,
  payload: { call_sid },
});

export const FETCH_CALL_INFO_SUCCESSED = 'FETCH_CALL_INFO_SUCCESSED';
export const fetchCallInfoSuccessed = ({ call_sid, conversations, guest, category, is_recorded }) => ({
  type: FETCH_CALL_INFO_SUCCESSED,
  payload: { call_sid, conversations, guest, category, is_recorded },
});

export const FETCH_CALL_INFO_FAILED = 'FETCH_CALL_INFO_FAILED';
export const fetchCallInfoFailed = errors => ({ type: FETCH_CALL_INFO_FAILED, payload: { errors } });

export const FETCH_TRANSFER_INFO_REQUESTED = 'FETCH_TRANSFER_INFO_REQUESTED';
export const fetchTransferInfoRequested = ({ phone }) => ({
  type: FETCH_TRANSFER_INFO_REQUESTED,
  payload: { phone },
});

export const FETCH_TRANSFER_INFO_SUCCESSED = 'FETCH_TRANSFER_INFO_SUCCESSED';
export const fetchTransferInfoSuccessed = ({ conversations, guest, category, caller_number, is_recorded }) => ({
  type: FETCH_TRANSFER_INFO_SUCCESSED,
  payload: { conversations, guest, category, caller_number, is_recorded },
});

export const FETCH_WORKER_LIST_REQUESTED = 'FETCH_WORKER_LIST_REQUESTED';
export const fetchWorkerListRequested = () => ({ type: FETCH_WORKER_LIST_REQUESTED });

export const FETCH_WORKER_LIST_SUCCESSED = 'FETCH_WORKER_LIST_SUCCESSED';
export const fetchWorkerListSuccessed = workers => ({
  type: FETCH_WORKER_LIST_SUCCESSED,
  payload: { workers },
});

export const FETCH_CALL_REASONS_REQUESTED = 'FETCH_CALL_REASONS_REQUESTED';
export const fetchCallReasonsRequested = () => ({ type: FETCH_CALL_REASONS_REQUESTED });

export const FETCH_CALL_REASONS_SUCCESSED = 'FETCH_CALL_REASONS_SUCCESSED';
export const fetchCallReasonsSuccessed = callReasons => ({
  type: FETCH_CALL_REASONS_SUCCESSED,
  payload: { callReasons },
});

export const FETCH_WORKER_LIST_FAILED = 'FETCH_WORKER_LIST_FAILED';
export const fetchWorkerListFailed = errors => ({ type: FETCH_WORKER_LIST_FAILED, payload: { errors } });

export const OFF_TASK_ROUTER = 'OFF_TASK_ROUTER';
export const offTaskRouter = status => ({ type: OFF_TASK_ROUTER, payload: { status } });

export const FETCH_TASK_ROUTER_ACTIVITIES_SUCCESSED = 'FETCH_TASK_ROUTER_ACTIVITIES_SUCCESSED';
export const fetchTaskRouterActivitiesSuccessed = activities => ({
  type: FETCH_TASK_ROUTER_ACTIVITIES_SUCCESSED,
  payload: { activities },
});

export const UPDATE_TASK_ROUTER_STATUS_REQUESTED = 'UPDATE_TASK_ROUTER_STATUS_REQUESTED';
export const updateTaskRouterStatusRequested = activityName => ({
  type: UPDATE_TASK_ROUTER_STATUS_REQUESTED,
  payload: { activityName },
});

export const UPDATE_TASK_ROUTER_STATUS_SUCCESSED = 'UPDATE_TASK_ROUTER_STATUS_SUCCESSED';
export const updateTaskRouterStatusSuccessed = status => ({
  type: UPDATE_TASK_ROUTER_STATUS_SUCCESSED,
  payload: { status },
});

export const UPDATE_TASK_ROUTER_STATUS_FAILED = 'UPDATE_TASK_ROUTER_STATUS_FAILED';
export const updateTaskRouterStatusFailed = errors => ({
  type: UPDATE_TASK_ROUTER_STATUS_FAILED,
  payload: { errors },
});

export const SET_CALL_CENTER_CONVERSATION = 'SET_CALL_CENTER_CONVERSATION';
export const setCallCenterConversationIfAllowed = conversation => ({
  type: SET_CALL_CENTER_CONVERSATION,
  payload: { conversation },
});

export const ERASE_DIGIT = 'ERASE_DIGIT';
export const eraseDigit = () => ({ type: ERASE_DIGIT });

export const SEND_DIGIT = 'SEND_DIGIT';
export const sendDigit = digit => ({ type: SEND_DIGIT, payload: { digit } });

export const CHANGE_PAGE = 'CHANGE_PAGE';
export const changePage = page => ({ type: CHANGE_PAGE, payload: { page } });

export const CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER';
export const changePhoneNumber = phoneNumber => ({ type: CHANGE_PHONE_NUMBER, payload: { phoneNumber } });

export const CHANGE_OUTGOING_PHONE_NUMBER = 'CHANGE_OUTGOING_PHONE_NUMBER';
export const changeOutgoingPhoneNumber = phoneNumber => ({
  type: CHANGE_OUTGOING_PHONE_NUMBER,
  payload: { phoneNumber },
});

export const CHANGE_TAB = 'CHANGE_TAB';
export const changeTab = tab => ({ type: CHANGE_TAB, payload: { tab } });

export const SETUP_CALL_CENTER = 'SETUP_CALL_CENTER';
export const setupCallCenter = () => ({ type: SETUP_CALL_CENTER });

export const CALL_CENTER_START_TIMER_ERROR = 'CALL_CENTER_START_TIMER_ERROR';
export const callCenterStartTimerError = (entity, errors) => ({
  type: CALL_CENTER_START_TIMER_ERROR,
  payload: { entity, errors },
});

export const CALL_CENTER_ERROR = 'CALL_CENTER_ERROR';
export const callCenterError = (entity, errors) => ({
  type: CALL_CENTER_ERROR,
  payload: { entity, errors },
});

export const HIDE_CALL_CENTER_ERROR = 'HIDE_CALL_CENTER_ERROR';
export const hideCallCenterError = () => ({ type: HIDE_CALL_CENTER_ERROR });

export const REINITIALIZE_TWILIO_DEVICE = 'REINITIALIZE_TWILIO_DEVICE';
export const reinitializeTwilioDevice = () => ({ type: REINITIALIZE_TWILIO_DEVICE });

export const UPDATE_WORKER_ACTIVITY_LOG = 'UPDATE_WORKER_ACTIVITY_LOG';
export const updateWorkerActivityLog = activityName => ({
  type: UPDATE_WORKER_ACTIVITY_LOG,
  payload: { activityName },
});

export const HANDLE_CONNECTION_FAILED = 'HANDLE_CONNECTION_FAILED';
export const handleConnectionFailed = () => ({ type: HANDLE_CONNECTION_FAILED });

export const actions = {
  enableConnection,
  changeViewStatus,
  cancelCall,
  endCall,
  placeCall,
  muteCall: muteCallRequested,
  closeCall,
  changeNote,
  changeCallReason,
  submitNote: submitNoteRequested,
  answerCall,
  forwardCall: forwardCallRequested,
  clearCallForwardingErrors,
  rejectCall,
  expandCall,
  updateTaskRouterStatusRequested,
  sendDigit,
  changePage,
  eraseDigit,
  placeCustomCall,
  changeTab,
  changePhoneNumber,
  changeOutgoingPhoneNumber,
  hideCallCenterError,
  fetchWorkerListRequested,
  handleConnectionFailed,
};

export const entities = {
  DEVICE: 'device',
  CONNECTION: 'connection',
  TASK_ROUTER: 'taskRouter',
  VIEW: 'view',
  DATA: 'data',
};

export const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  /**
   * taskRouter is the staff twilio status
   * i.e. manage all staff activities, for example if they are BUSY, AVAILABLE or OFFLINE
   */
  taskRouter: {
    activities: {},
    activityName: null,
    onPause: false,
    workerToken: null,
  },
  callForwarding: {
    workers: [],
    status: callForwardingStatuses.INITIALIZING,
    target: null,
  },
  device: {
    status: null,
    error: null,
    callToken: null,
  },
  connection: {
    instance: null,
    status: null,
    duration: null,
    startedAt: null,
    isMuted: false,
    type: connectionTypes.OUTBOUND,
    digits: '',
  },
  view: {
    status: callCenterStatuses.CLOSED,
    page: callCenterPages.DEFAULT,
    tab: 1,
  },
  data: {
    note: '',
    isNoteSubmitted: false,
    conversation: null,
    guest: null,
    category: null,
    customPhoneNumber: '',
    outgoingPhoneNumber: '',
    call_sid: null,
    callReason: null,
    isRecorded: false,
  },
  config: {
    callReasons: [],
  },
  errors: {
    visible: false,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DEVICE_STATUS:
      return state.setIn(['device', 'status'], payload.status).setIn(['device', 'error'], null);

    case SET_DEVICE_ERROR:
      return state.setIn(['device', 'error'], payload.error);

    case FETCH_TWILIO_TOKENS_SUCCESSED:
      return state
        .setIn(['taskRouter', 'workerToken'], payload.worker_token)
        .setIn(['device', 'callToken'], payload.call_token);

    case ENABLE_TASK_ROUTER:
      return state.setIn(['taskRouter', 'errors'], fromJS({}));

    case ENABLE_CONNECTION:
      return state
        .setIn(['connection', 'status'], twilioConnectionStatuses.READY)
        .setIn(['connection', 'errors'], fromJS({}));

    case CHANGE_VIEW_STATUS: {
      const nextState = state.setIn(['view', 'status'], payload.status);

      if (payload.status === callCenterStatuses.CLOSED)
        return nextState
          .setIn(['connection', 'type'], connectionTypes.OUTBOUND)
          .setIn(['connection', 'isMuted'], false)
          .setIn(['connection', 'digits'], '')
          .setIn(['data', 'isNoteSubmitted'], false)
          .setIn(['data', 'note'], '')
          .setIn(['data', 'category'], null)
          .setIn(['data', 'errors'], fromJS({}))
          .setIn(['view', 'page'], callCenterPages.DEFAULT)
          .setIn(['data', 'customPhoneNumber'], '')
          .setIn(['data', 'isRecorded'], false)
          .setIn(['view', 'tab'], shouldEnableGuestCall(state.get('data').toJS().guest || {}) ? 0 : 1);

      return nextState;
    }

    case FETCH_TASK_ROUTER_ACTIVITIES_SUCCESSED:
      return state.setIn(['taskRouter', 'activities'], fromJS(payload.activities));

    case FETCH_WORKER_LIST_REQUESTED:
      return state.setIn(['callForwarding', 'status'], callForwardingStatuses.FETCHING);

    case FETCH_WORKER_LIST_SUCCESSED:
      return state
        .setIn(['callForwarding', 'workers'], fromJS(payload.workers))
        .setIn(['callForwarding', 'status'], callForwardingStatuses.READY);

    case FETCH_WORKER_LIST_FAILED:
    case FORWARD_CALL_FAILED:
      return state
        .setIn(['callForwarding', 'status'], callForwardingStatuses.ERROR)
        .setIn(['callForwarding', 'errors'], payload.errors);

    case SETUP_CALL_FORWARDING_FINISHED:
    case CLEAR_CALL_FORWARDING_ERRORS:
      return state
        .setIn(['callForwarding', 'status'], callForwardingStatuses.READY)
        .setIn(['callForwarding', 'errors'], null);

    case FORWARD_CALL_REQUESTED:
      return state
        .setIn(['callForwarding', 'status'], callForwardingStatuses.FORWARDING)
        .setIn(['callForwarding', 'target'], payload.target)
        .setIn(['callForwarding', 'errors'], null)
        .setIn(['view', 'page'], callCenterPages.FORWARDING);

    case FORWARD_CALL_SUCCESSED:
      return state
        .setIn(['callForwarding', 'status'], callForwardingStatuses.READY)
        .setIn(['callForwarding', 'target'], null)
        .setIn(['view', 'page'], callCenterPages.DEFAULT);

    case OFF_TASK_ROUTER:
      return state.setIn(['taskRouter', 'activityName'], payload.status || taskRouterActivities.OFFLINE);

    case UPDATE_TASK_ROUTER_STATUS_SUCCESSED:
      const newOnPause = status => {
        switch (status) {
          case taskRouterActivities.PAUSED:
            return true;
          case taskRouterActivities.AVAILABLE:
          case taskRouterActivities.IDLE:
            return false;
          default:
            return state.getIn(['taskRouter', 'onPause']);
        }
      };

      return state
        .setIn(['taskRouter', 'activityName'], payload.status)
        .setIn(['taskRouter', 'onPause'], newOnPause(payload.status));

    case FETCH_CALL_REASONS_SUCCESSED:
      return state.setIn(['config', 'callReasons'], fromJS(payload.callReasons));

    /**
     * Shared call action handlers
     */
    case CALL_ENDED:
      return state
        .setIn(['connection', 'instance'], null)
        .setIn(['connection', 'status'], twilioConnectionStatuses.COMPLETED);

    case REJECT_CALL:
      return state
        .setIn(['connection', 'instance'], null)
        .setIn(['connection', 'status'], twilioConnectionStatuses.READY);

    case MUTE_CALL_SUCCESSED:
      return state.setIn(['connection', 'isMuted'], payload.muted);

    case CHANGE_NOTE:
      return state.setIn(['data', 'note'], payload.note);

    case CHANGE_CALL_REASON:
      return state.setIn(['data', 'callReason'], fromJS(payload.callReason));

    case SUBMIT_NOTE_SUCCESSED:
      return state.setIn(['data', 'note'], '').setIn(['data', 'isNoteSubmitted'], true);

    case SUBMIT_NOTE_FAILED:
      return state.setIn(['data', 'errors'], payload.errors);

    case SUBMIT_CALL_REASON_SUCCESSED:
    case SUBMIT_CALL_REASON_FAILED:
      return state.setIn(['data', 'callReason'], null);

    case CALL_CENTER_ERROR:
      return state
        .setIn(['errors', 'visible'], true)
        .setIn(['errors', payload.entity], fromJS(payload.errors))
        .setIn([payload.entity, 'status'], twilioDeviceStatuses.ERROR);

    case HIDE_CALL_CENTER_ERROR:
      return state.setIn(['errors', 'visible'], false);
    /**
     * ^^^^^^^^^^^^^^^^^^^^^^^^^^
     * Shared call action handlers
     */
    /**
     * Outbound call action handlers
     */
    case PLACE_CUSTOM_CALL:
    case PLACE_CALL:
      return state
        .setIn(['connection', 'status'], twilioConnectionStatuses.CALLING)
        .setIn(['connection', 'type'], connectionTypes.OUTBOUND);

    case CONNECT_CALL:
      const call_sid = state.getIn(['data', 'call_sid']);
      const new_call_sid = !call_sid ? payload.connection.parameters['CallSid'] : call_sid;
      return state
        .setIn(['connection', 'instance'], payload.connection)
        .setIn(['connection', 'startedAt'], new Date())
        .setIn(['connection', 'status'], twilioConnectionStatuses.IN_PROGRESS)
        .setIn(['data', 'call_sid'], new_call_sid);

    case SET_CALL_CENTER_CONVERSATION: {
      if (!isCallCenterConversationChangeAllowed(state.getIn(['connection', 'status']))) {
        return state;
      }
      return state
        .setIn(['data', 'conversation'], fromJS(payload.conversation))
        .setIn(['data', 'guest'], fromJS((payload.conversation || {}).guest))
        .setIn(
          ['view', 'tab'],
          payload.conversation && payload.conversation.guest && payload.conversation.guest.phone_number ? 0 : 1,
        );
    }

    case SEND_DIGIT:
      return state.updateIn(['connection', 'digits'], value => value.concat(payload.digit));

    case ERASE_DIGIT:
      return state.updateIn(['connection', 'digits'], value => value.slice(0, -1));

    case CHANGE_PAGE:
      return state.setIn(['view', 'page'], payload.page || callCenterPages.DEFAULT);

    case CHANGE_PHONE_NUMBER:
      return state.setIn(['data', 'customPhoneNumber'], payload.phoneNumber);

    case CHANGE_OUTGOING_PHONE_NUMBER:
      return state.setIn(['data', 'outgoingPhoneNumber'], payload.phoneNumber);

    case CHANGE_TAB:
      return state.setIn(['view', 'tab'], payload.tab || 0);
    /**
     * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^
     * Outbound call action handlers
     */
    /**
     * Inbound call action handlers
     */
    case CALL_INCOMING:
      return state
        .setIn(['connection', 'status'], twilioConnectionStatuses.RINGING)
        .setIn(['connection', 'type'], connectionTypes.INBOUND)
        .setIn(['view', 'status'], callCenterStatuses.OPENED)
        .setIn(['view', 'tab'], callCenterTabs.GUEST);

    case FETCH_CALL_INFO_REQUESTED:
      return state.setIn(['data', 'guest'], fromJS({ phone_number: payload.phone })).set('isFetching', true);

    case FETCH_CALL_INFO_BY_SID_REQUESTED:
      return state.setIn(['data', 'call_sid'], payload.call_sid).set('isFetching', true);

    case FETCH_TRANSFER_INFO_REQUESTED:
      return state
        .updateIn(['data', 'guest'], value =>
          value && value.get('phone_number') ? value : fromJS({ phone_number: payload.phone }),
        )
        .set('isFetching', true);

    case FETCH_CALL_INFO_SUCCESSED:
      return state
        .setIn(['data', 'conversation'], (payload.conversations && fromJS(payload.conversations[0])) || null)
        .updateIn(['data', 'guest'], value => (payload.guest ? fromJS(payload.guest) : value))
        .setIn(['data', 'category'], payload.category ? payload.category.name : null)
        .setIn(['data', 'call_sid'], payload.call_sid)
        .setIn(['data', 'isRecorded'], payload.is_recorded)
        .set('isFetching', false);

    case FETCH_TRANSFER_INFO_SUCCESSED:
      return state
        .setIn(['data', 'guest'], fromJS({ phone_number: payload.caller_number }))
        .updateIn(['data', 'guest'], value => (payload.guest ? fromJS(payload.guest) : value))
        .setIn(['connection', 'type'], connectionTypes.TRANSFER)
        .setIn(['data', 'conversation'], (payload.conversations && fromJS(payload.conversations[0])) || null)
        .setIn(['data', 'category'], payload.category.name)
        .setIn(['data', 'isRecorded'], payload.is_recorded)
        .set('isFetching', false);

    case FETCH_CALL_INFO_FAILED:
      return state
        .setIn(['errors', 'visible'], false)
        .setIn(['errors', payload.entity], fromJS(payload.errors))
        .set('didInvalidate', true)
        .set('isFetching', false);
    /**
     * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^
     * Inbound call action handlers
     */
    default:
      return state;
  }
};
