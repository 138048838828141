import React from 'react';
import { ChatNotification } from 'components';
import { links } from 'constants.js';
import { replaceInUrl } from 'ready/utils';
import { Link, Text } from 'ready/components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Relocation = ({ id, messageId, t, 'data-qa': dataQA }) => {
  const href = replaceInUrl({
    url: links.BOOKING,
    placeholder: '{id}',
    value: id,
  });

  return (
    <FormattedMessage
      id={messageId}
      defaultMessage=""
      values={{
        link: (
          <Link href={href} target="_blank">
            {id}
          </Link>
        ),
      }}
    >
      {(...chunks) => (
        <ChatNotification t={t} variation={ChatNotification.variations.WARNING} data-qa={dataQA}>
          {chunks.map((txt, i) => (
            <Text key={i}>{txt}</Text>
          ))}
        </ChatNotification>
      )}
    </FormattedMessage>
  );
};

Relocation.propTypes = {
  id: PropTypes.number,
  messageId: PropTypes.string.isRequired,
  'data-qa': PropTypes.string.isRequired,
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
  }).isRequired,
};

const Relocations = ({ from, to, t }) => (
  <>
    {to && (
      <Relocation
        id={to}
        messageId="CONVERSATION.RELOCATION.TO"
        data-qa="Notification:ChatNotification--relocatedToNewBooking"
        t={t}
      />
    )}
    {from && (
      <Relocation
        id={from}
        messageId="CONVERSATION.RELOCATION.FROM"
        data-qa="Notification:ChatNotification--relocatedFromPreviousBooking"
        t={t}
      />
    )}
  </>
);

Relocations.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
  }).isRequired,
};

export default Relocations;
