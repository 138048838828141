import { Map } from 'immutable';

export const VALIDATE_API = 'VALIDATE_API';
export const validateApi = () => ({ type: VALIDATE_API });

export const INVALIDATE_API = 'INVALIDATE_API';
export const invalidateApi = () => ({ type: INVALIDATE_API });

export const STOP_HEALTH_CHECK = 'STOP_HEALTH_CHECK';
export const stopHealthCheck = () => ({ type: STOP_HEALTH_CHECK });

export const HEALTH_CHECK_STARTED = 'HEALTH_CHECK_STARTED';
export const healthCheckStarted = () => ({ type: HEALTH_CHECK_STARTED });

export const HEALTH_CHECK_STOPPED = 'HEALTH_CHECK_STOPPED';
export const healthCheckStopped = () => ({ type: HEALTH_CHECK_STOPPED });

export const SET_LOCALE = 'SET_LOCALE';
export const setLocale = locale => ({ type: SET_LOCALE, payload: { locale } });

export const CODE_CRASHED = 'CODE_CRASHED';
export const codeCrashed = () => ({ type: CODE_CRASHED });

export const SET_URL = 'SET_URL';
export const setUrl = url => ({ type: SET_URL, payload: { url } });

export const SET_IS_TAB_ACTIVE = 'SET_IS_TAB_ACTIVE';
export const setIsTabActive = value => ({ type: SET_IS_TAB_ACTIVE, payload: { value } });

export const CHANGE_IS_FILTER_SIDEBAR_OPEN = 'CHANGE_IS_FILTER_SIDEBAR_OPEN';
export const changeIsFilterSidebarOpen = value => ({ type: CHANGE_IS_FILTER_SIDEBAR_OPEN, payload: { value } });

export const CHANGE_IS_INFO_SIDEBAR_OPEN = 'CHANGE_IS_INFO_SIDEBAR_OPEN';
export const changeIsInfoSidebarOpen = value => ({ type: CHANGE_IS_INFO_SIDEBAR_OPEN, payload: { value } });

export const CHANGE_IS_CONVERSATION_LIST_OPEN = 'CHANGE_IS_CONVERSATION_LIST_OPEN';
export const changeIsConversationListOpen = value => ({ type: CHANGE_IS_CONVERSATION_LIST_OPEN, payload: { value } });

export const CHANGE_IS_MORE_FILTERS_OPEN = 'CHANGE_IS_MORE_FILTERS_OPEN';
export const changeIsMoreFiltersOpen = value => ({ type: CHANGE_IS_MORE_FILTERS_OPEN, payload: { value } });

export const SETUP_INIT_DATA = 'SETUP_INIT_DATA';
export const setupInitData = payload => ({ type: SETUP_INIT_DATA, payload });

export const initialState = Map({
  apiDidInvalidate: false,
  isLoading: false,
  locale: 'en',
  isFilterSidebarOpen: true,
  isInfoSidebarOpen: true,
  isConversationListOpen: true,
  isMoreFiltersOpen: false,
  url: '/',
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CODE_CRASHED:
      return state
        .set('apiDidInvalidate', true)
        .set('formDidInvalidate', true)
        .set('isFetching', false);

    case VALIDATE_API:
      return state.set('apiDidInvalidate', false);

    case INVALIDATE_API:
      return state.set('apiDidInvalidate', true);

    case SET_LOCALE:
      return state.set('locale', payload.locale);

    case SET_URL:
      return state.set('url', payload.url);

    case CHANGE_IS_FILTER_SIDEBAR_OPEN:
      return state.update('isFilterSidebarOpen', updateBoolValue.bind(null, payload));

    case CHANGE_IS_INFO_SIDEBAR_OPEN:
      return state.update('isInfoSidebarOpen', updateBoolValue.bind(null, payload));

    case CHANGE_IS_CONVERSATION_LIST_OPEN:
      return state.update('isConversationListOpen', updateBoolValue.bind(null, payload));

    case CHANGE_IS_MORE_FILTERS_OPEN:
      return state.update('isMoreFiltersOpen', updateBoolValue.bind(null, payload));

    case SETUP_INIT_DATA:
      return state
        .set('isFilterSidebarOpen', !!payload.isFilterSidebarOpen)
        .set('isInfoSidebarOpen', !!payload.isInfoSidebarOpen);

    default:
      return state;
  }
};

const updateBoolValue = (payload, value) => (typeof payload.value === 'boolean' ? payload.value : !value);
