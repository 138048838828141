import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getSelectedConversationId } from 'selectors/conversation';
import { getIsConversationListOpen, getIsFilterSidebarOpen, getIsInfoSidebarOpen } from 'selectors/view';
import { matchPropType } from 'common/propTypes';
import FilterSidebarTopBar from '../scenes/FilterSidebarDrawer/components/FilterSidebarTopBar';
import InfoSidebarTopBar from '../scenes/InfoSidebarDrawer/components/InfoSidebarTopBar';
import ConversationListTopBar from '../scenes/ConversationListDrawer/components/ConversationListTopBar';
import ConversationTopBar from '../scenes/Conversation/components/ConversationTopBar';

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${props => props.theme.app.border.default};
  width: 100vw;
`;

const TopBarRouter = ({ isFilterSidebarOpen, isInfoSidebarOpen, isConversationListOpen }) => {
  return (
    <>
      {isFilterSidebarOpen && (
        <TopBar data-qa="Mobile:__topBar">
          <FilterSidebarTopBar />
        </TopBar>
      )}
      {isInfoSidebarOpen && (
        <TopBar data-qa="Mobile:__topBar">
          <InfoSidebarTopBar />
        </TopBar>
      )}
      {isConversationListOpen && !isFilterSidebarOpen && (
        <TopBar data-qa="Mobile:__topBar">
          <ConversationListTopBar />
        </TopBar>
      )}
      {!isConversationListOpen && !isInfoSidebarOpen && (
        <TopBar data-qa="Mobile:__topBar">
          <ConversationTopBar />
        </TopBar>
      )}
    </>
  );
};

TopBarRouter.propTypes = {
  match: matchPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFilterSidebarOpen: PropTypes.bool,
  isInfoSidebarOpen: PropTypes.bool,
  isConversationListOpen: PropTypes.bool,
  selectedConversationId: PropTypes.number,
};

const mapStateToProps = state => ({
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
  isInfoSidebarOpen: getIsInfoSidebarOpen(state),
  isConversationListOpen: getIsConversationListOpen(state),
  selectedConversationId: getSelectedConversationId(state),
});

export default connect(mapStateToProps)(TopBarRouter);
