import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Button, TruncatedText } from 'ready/components';
import { savedReplyPropType } from 'common/propTypes';

const Container = styled.div`
  align-items: center;
  padding: ${props => props.theme.spacing.xs};
  display: grid;
  grid-template-areas: 'title title' 'content actions';
  grid-template-columns: auto 12rem;
  margin-bottom: ${props => props.theme.spacing.xs};
  ${props => props.theme.app.card};

  &:last-child {
    margin-bottom: 0;
  }
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-area: actions;
  grid-template-columns: auto auto;
  grid-column-gap: ${props => props.theme.spacing.xxs};
`;

const Title = styled(TruncatedText)`
  grid-area: title;
`;

const Content = styled(TruncatedText)`
  grid-area: content;
`;

const Item = ({ onViewClick, onAddClick, savedReply, t }) => (
  <Container data-qa={`SaveRepliesItem:__container--${savedReply.id}`}>
    <Title bold>{savedReply.name}</Title>
    <Content>{savedReply.templated}</Content>
    <ActionsWrapper>
      <Button variation={Button.variations.OUTLINE} onClick={() => onViewClick(savedReply)} size={Button.sizes.S}>
        {t.view}
      </Button>
      <Button variation={Button.variations.PRIMARY} onClick={() => onAddClick(savedReply)} size={Button.sizes.S}>
        {t.add}
      </Button>
    </ActionsWrapper>
  </Container>
);

Item.propTypes = {
  t: PropTypes.shape({
    view: PropTypes.string.isRequired,
    add: PropTypes.string.isRequired,
  }).isRequired,
  onViewClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  savedReply: savedReplyPropType.isRequired,
};

export default Item;
