import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Icon, Button, Datepicker } from 'ready/components';
import { FormattedMessage } from 'react-intl';

export const DateTypes = {
  CHECK_IN: 'CHECK-IN',
  CHECK_OUT: 'CHECK-OUT',
};

const StyledBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
  cursor: pointer;
  user-select: none;
  margin-bottom: ${props => props.theme.spacing.l};
`;

const StyledBackButtonText = styled(Text)`
  text-decoration: underline;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 ${props => props.theme.spacing.s} ${props => props.theme.spacing.s} ${props => props.theme.spacing.s};
`;

const DetailsWrapper = styled.div`
  margin: ${props => props.theme.spacing.m} 0;
  display: flex;
  flexflow: column wrap;
  gap: ${props => props.theme.spacing.xs};
`;

const CalendarWrapper = styled.div`
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.l};
  padding: ${props => props.theme.spacing.m};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.l};
  margin: ${props => props.theme.spacing.l} 0;
`;
const StyledTextLink = styled(Text)`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
`;

const EditDateForm = ({ date, setDate, setShowDateForm, minStay, type, t }) => {
  const [newDate, setNewDate] = useState(date);

  function handleDatePick(date) {
    setNewDate(date.format('YYYY-MM-DD'));
  }
  function reset() {
    setNewDate(date);
  }
  function apply() {
    setDate(newDate);
    setShowDateForm(false);
  }

  return (
    <>
      <ContentWrapper>
        <StyledBackButton onClick={() => setShowDateForm(false)}>
          <Icon size={Icon.sizes.L} icon={Icon.icons.ARROW_LEFT} />
          <StyledBackButtonText>{t.back}</StyledBackButtonText>
        </StyledBackButton>
        <Text size={Text.sizes.XL}>{type === DateTypes.CHECK_IN ? t.selectCheckIn : t.selectCheckOut}</Text>
        <DetailsWrapper>
          <Icon size={Icon.sizes.L} icon={Icon.icons.v2.NIGHT} />
          <Text>
            <FormattedMessage id="BOOKING.ALTERATION.FORM.EDIT.CALENDAR.MIN_STAY" values={{ nights: minStay }} />
          </Text>
        </DetailsWrapper>
        <CalendarWrapper>
          <Datepicker startDate={newDate} selectedDate={newDate} onDatePick={handleDatePick} />
        </CalendarWrapper>
        <ButtonsWrapper>
          <StyledTextLink onClick={reset}>{t.reset}</StyledTextLink>
          <Button variation={Button.variations.PRIMARY} size={Button.sizes.M} inline onClick={apply}>
            {t.apply}
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </>
  );
};

EditDateForm.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  setShowDateForm: PropTypes.func.isRequired,
  minStay: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.shape({
    back: PropTypes.string.isRequired,
    reset: PropTypes.string.isRequired,
    apply: PropTypes.string.isRequired,
    selectCheckIn: PropTypes.string.isRequired,
    selectCheckOut: PropTypes.string.isRequired,
  }),
};
export default EditDateForm;
