import React from 'react';
import { StyledCategoryRow } from '../CategoryRow';
import { getSelectedFlagId } from 'selectors/filters';
import { connect } from 'react-redux';
import FlagIcon, { formatClassName } from 'components/FlagConversation/FlagIcon';
import { Text } from 'ready/components';

const FlagRow = ({ className, selectedId, flag, onClick }) => {
  return (
    <StyledCategoryRow className={className} selected={selectedId === flag.id} onClick={onClick}>
      <div>
        <FlagIcon color={formatClassName('ac_' + flag.name.toLowerCase())} />
        <Text> {flag.name}</Text>
      </div>
      <Text>{typeof flag.count === 'number' ? flag.count : '–'}</Text>
    </StyledCategoryRow>
  );
};

const mapStateToProps = state => ({
  selectedId: getSelectedFlagId(state),
});

export default connect(mapStateToProps)(FlagRow);
