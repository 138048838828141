import { fromJS } from 'immutable';

export const FETCH_GUEST_REQUESTED = 'FETCH_GUEST_REQUESTED';
export const fetchGuestRequested = id => ({ type: FETCH_GUEST_REQUESTED, payload: { id } });

export const FETCH_GUEST_SUCCESSED = 'FETCH_GUEST_SUCCESSED';
export const fetchGuestSuccessed = guest => ({ type: FETCH_GUEST_SUCCESSED, payload: { guest } });

export const FETCH_GUEST_FAILED = 'FETCH_GUEST_FAILED';
export const fetchGuestFailed = errors => ({ type: FETCH_GUEST_FAILED, payload: { errors } });

export const UPDATE_GUEST_REQUESTED = 'UPDATE_GUEST_REQUESTED';
export const updateGuestRequested = (id, data) => ({
  type: UPDATE_GUEST_REQUESTED,
  payload: { id, data },
});

export const UPDATE_GUEST_SUCCESSED = 'UPDATE_GUEST_SUCCESSED';
export const updateGuestSuccessed = guest => ({
  type: UPDATE_GUEST_SUCCESSED,
  payload: { guest },
});

export const UPDATE_GUEST_FAILED = 'UPDATE_GUEST_FAILED';
export const updateGuestFailed = errors => ({
  type: UPDATE_GUEST_FAILED,
  payload: { errors },
});

const DEFAULT_GUEST_LANGUAGE = 'en';

export const initialState = fromJS({
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  data: { language: DEFAULT_GUEST_LANGUAGE },
  errors: {
    context: null,
    updating: false,
    fetching: false,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_GUEST_REQUESTED:
      return state
        .set('isFetching', true)
        .set('didInvalidate', false)
        .set('errors', initialState.get('errors'));

    case FETCH_GUEST_SUCCESSED:
      return state
        .set('isFetching', false)
        .set('data', fromJS(payload.guest || {}))
        .updateIn(['data', 'language'], value => value || DEFAULT_GUEST_LANGUAGE);

    case FETCH_GUEST_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .update('errors', value => value.set('fetching', true).merge(payload.errors));

    case UPDATE_GUEST_REQUESTED:
      return state
        .set('isUpdating', true)
        .set('didInvalidate', false)
        .set('errors', initialState.get('errors'));

    case UPDATE_GUEST_SUCCESSED:
      return state
        .set('isUpdating', false)
        .set('data', fromJS(payload.guest || {}))
        .updateIn(['data', 'language'], value => value || DEFAULT_GUEST_LANGUAGE);

    case UPDATE_GUEST_FAILED:
      return state
        .set('isUpdating', false)
        .set('didInvalidate', true)
        .update('errors', value => value.set('updating', true).merge(payload.errors));

    default:
      return state;
  }
};
