import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { variations, sizes } from 'ready/utils/constants';
import { Text } from '../typography';
import { sizePropType, variationPropType } from 'ready/utils/propTypes';

const { S, M, L } = sizes;

const withDisabledStyle = ({ disabled }) =>
  disabled &&
  css`
    opacity: 0.5;
  `;

const withDefaultToggleStyle = ({ theme }) => css`
  background-color: ${theme.colors.n500};
  left: 0;
`;

const withEnabledToggleStyle = ({ enabled, theme, variation, size }) =>
  enabled &&
  css`
    background-color: ${theme.colors[variation]};
    left: calc(${theme.spacing[size]} * 0.6);
  `;

const Background = styled.span`
  display: inline-block;
  position: relative;
  width: ${props => `calc(${props.theme.spacing[props.size]} * 1.6)`};
  height: ${props => `calc(${props.theme.spacing[props.size]} * 1)`};
  background-color: ${props => props.theme.colors.n200};
  border-radius: ${props => props.theme.spacing[props.size]};
  transition: ${props => props.theme.transition.default};
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: ${props => props.theme.spacing[props.size]};
    height: ${props => props.theme.spacing[props.size]};
    border-radius: ${props => props.theme.spacing[props.size]};
    transition: ${props => props.theme.transition.default};
    ${props => withEnabledToggleStyle(props) || withDefaultToggleStyle(props)};
  }
`;

const Label = styled(Text)`
  cursor: pointer;
  margin-left: ${props => props.theme.spacing.xxs};
  margin-right: ${props => props.theme.spacing.xxs};
`;

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  ${withDisabledStyle};
`;

const Toggle = ({ size, enabled, variation, label, position, ...props }) => (
  <Wrapper {...props}>
    {position === Toggle.positions.RIGHT && label && <Label size={size}>{label}</Label>}
    <Background size={size} enabled={enabled} variation={variation} />
    {position === Toggle.positions.LEFT && label && <Label size={size}>{label}</Label>}
  </Wrapper>
);

Toggle.positions = {
  LEFT: 'left',
  RIGHT: 'right',
};
Toggle.sizes = { S, M, L };
Toggle.variations = variations;

Toggle.propTypes = {
  size: sizePropType(Toggle.sizes),
  enabled: PropTypes.bool,
  variation: variationPropType,
  onClick: PropTypes.func,
  label: PropTypes.string,
  position: PropTypes.string.isRequired,
};

Toggle.defaultProps = {
  size: Toggle.sizes.M,
  variation: variations.PRIMARY,
  enabled: false,
  position: Toggle.positions.RIGHT,
};

export default Toggle;
