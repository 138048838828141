import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { twilioConnectionStatuses } from 'constants.js';

import { Text, Select } from 'ready/components';

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing.xxxs};
  margin-top: ${props => props.theme.spacing.l};
  text-align: left;
`;

const OutgoingNumberSelect = ({ phoneNumbers, value, onChange, status, t }) => {
  if (status !== twilioConnectionStatuses.READY) return false;

  const options = phoneNumbers.map(number => ({ label: number.name, value: number.phone }));
  const valueOption = options.find(option => option.value === value);
  return (
    <Wrapper>
      <Text size={Text.sizes.S} bold>
        {t.outgoingNumber}
      </Text>
      <Select value={valueOption} options={options} onChange={onChange} closeMenuOnSelect t={t} />
    </Wrapper>
  );
};

OutgoingNumberSelect.propTypes = {
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      phone: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  t: PropTypes.shape({
    outgoingNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export default OutgoingNumberSelect;
