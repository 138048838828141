import React from 'react';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text } from 'ready/components/typography';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { SHORT_DATE_FORMAT } from 'ready/utils/constants';

const CancellationRequest = ({ t, request, ...props }) => (
  <FormattedMessage
    id="CONVERSATION.CANCELLATION_REQUEST.TEXT"
    values={{
      created_by: request.created_by,
      reason: <Text bold>{request.reason}</Text>,
      created: moment(request.created).format(SHORT_DATE_FORMAT),
    }}
  >
    {(...chunks) => (
      <ChatNotification
        variation={ChatNotification.variations.ERROR}
        data-qa="Notification:CancellationRequest__ChatNotification"
        t={t}
        {...props}
      >
        {chunks.map((txt, i) => (
          <Text key={i}>{txt}</Text>
        ))}
      </ChatNotification>
    )}
  </FormattedMessage>
);

CancellationRequest.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
  }),
  request: PropTypes.shape({
    reason: PropTypes.string.isRequired,
    created_by: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
  }),
};

export default CancellationRequest;
