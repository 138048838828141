import React from 'react';
import ReactSelect, { components } from 'react-select';
import CreatableReactSelect from 'react-select/lib/Creatable';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import { colors, borderWidth, borderRadius, zIndex, typography, boxShadow, spacing } from 'ready/styles/theme';
import Icon from 'ready/components/Icon/Icon';
import { sizes } from 'ready/utils/constants';
import { sizePropType, selectOptionPropType, selectOptionsPropType } from 'ready/utils/propTypes';

const { L, M, S } = sizes;

const variationsSize = {
  [L]: {
    typography: L,
    valueContainer: '4rem',
  },
  [M]: {
    typography: M,
    valueContainer: '3.4rem',
  },
  [S]: {
    typography: S,
    valueContainer: '3rem',
  },
};

const optionColor = ({ isSelected }) => (isSelected && colors.white) || colors.dark;
const getTypography = size => ({
  fontSize: typography.text[variationsSize[size].typography]['font-size'],
  lineHeight: typography.text[variationsSize[size].typography]['line-height'],
  letterSpacing: typography.text[variationsSize[size].typography]['letter-spacing'] || 0,
});

const baseStyles = ({ styles: customStyles, size, isResizable }) => ({
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled && 0.5,
    ...getTypography(size),
    ...customStyles.container,
  }),
  control: (_, state) => ({
    border: `${borderWidth.m} solid ${colors.n300}`,
    borderRadius: borderRadius.m,
    display: 'flex',
    backgroundColor: state.isDisabled && colors.n300,
    boxShadow: state.isFocused && boxShadow.s,
    ...customStyles.control,
  }),
  option: (base, state) => ({
    ...base,
    color: optionColor(state),
    ...customStyles.option,
  }),
  menu: base => ({
    ...base,
    zIndex: zIndex.absolute,
    ...customStyles.menu,
  }),
  menuList: base => ({
    ...base,
    ...customStyles.menuList,
  }),
  menuPortal: base => ({
    ...base,
    ...customStyles.menuPortal,
  }),
  singleValue: base => ({
    ...base,
    padding: `0 ${spacing.xxs}`,
    ...customStyles.singleValue,
  }),
  placeholder: base => ({
    ...base,
    padding: `0 ${spacing.xxs}`,
    ...customStyles.placeholder,
  }),
  valueContainer: base => ({
    ...base,
    color: colors.dark,
    flexWrap: isResizable ? 'wrap' : 'nowrap',
    minHeight: variationsSize[size].valueContainer,
    maxHeight: isResizable ? 'none' : variationsSize[size].valueContainer,
    overflowX: 'auto',
    padding: `${spacing.xxxs} ${spacing.xxs}`,
    ...customStyles.valueContainer,
  }),
  multiValue: base => ({
    ...base,
    borderRadius: spacing.m,
    backgroundColor: colors.n100,
    border: `${borderWidth.m} solid ${colors.n200}`,
    fontWeight: 500,
    overflow: 'hidden',
    flex: '0 0 auto',
    maxWidth: isResizable ? '100%' : '78%',
  }),
  multiValueRemove: base => ({
    ...base,
    cursor: 'pointer',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  multiValueLabel: base => ({
    ...base,
    padding: 0,
    paddingTop: spacing.xxxs,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xxs,
    color: colors.n700,
    ...getTypography(size),
  }),
  indicatorSeparator: () => ({}),
  input: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: `0 ${spacing.xxs}`,
    color: colors.dark,
    ...customStyles.input,
  }),
  indicatorsContainer: base => ({
    ...base,
    ...customStyles.indicatorsContainer,
  }),
});

const theme = baseTheme => ({
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: colors.primary,
    primary25: colors.n200,
    primary50: colors.n300,
  },
});

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon icon={Icon.icons.ARROW_DOWN} />
      </components.DropdownIndicator>
    )
  );
};

const Control = props => <components.Control className="data-qa_ReactSelect-Control" {...props} />;

const Option = props => <components.Option className="data-qa_ReactSelect-Option" {...props} />;

const Input = props => <components.Input className="data-qa_ReactSelect-Input" {...props} />;

const ValueContainer = ({ children, getValue, hasValue, selectProps, ...props }) => {
  if (!selectProps.isMulti || selectProps.isResizable || !hasValue)
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;

  const counter = getValue().length - 1;
  const content = [fromJS(children[0][0]).toJS()];
  const input = children.find(child => child.type && child.type.name === 'Input');
  if (counter) content.push(`+${counter}`);

  return (
    <components.ValueContainer {...props}>
      {content}
      {input}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object,
  hasValue: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
};

const Select = ({ t, value, options, placeholder, className, components: customComponents, ...props }) => {
  return (
    <ReactSelect
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      {...props}
      className={className}
      value={value}
      options={options}
      styles={baseStyles(props)}
      components={{ DropdownIndicator, Control, Option, ValueContainer, ...customComponents }}
      noOptionsMessage={() => t.noResultsText}
      placeholder={placeholder || t.placeholder}
      theme={theme}
    />
  );
};

export const CreatableSelect = ({
  t,
  value,
  options,
  placeholder,
  className,
  components: customComponents,
  ...props
}) => {
  return (
    <CreatableReactSelect
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      {...props}
      className={className}
      value={value}
      options={options}
      styles={baseStyles(props)}
      components={{ DropdownIndicator, Control, Option, ValueContainer, Input, ...customComponents }}
      noOptionsMessage={() => t.noResultsText}
      placeholder={placeholder || t.placeholder}
      theme={theme}
    />
  );
};

Select.sizes = CreatableSelect.sizes = { L, M, S };
Select.components = CreatableSelect.components = components;

Select.defaultProps = CreatableSelect.defaultProps = {
  styles: {},
  size: M,
};

Select.propTypes = CreatableSelect.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  components: PropTypes.object,
  value: PropTypes.oneOfType([selectOptionPropType, selectOptionsPropType]),
  defaultValue: PropTypes.oneOfType([selectOptionPropType, selectOptionsPropType]),
  options: PropTypes.oneOfType([
    selectOptionsPropType,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        options: selectOptionsPropType,
      }),
    ),
  ]).isRequired,
  t: PropTypes.shape({
    noResultsText: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }).isRequired,
  styles: PropTypes.object,
  placeholder: PropTypes.string,
  size: sizePropType(Select.sizes),
  className: PropTypes.string,
};

export default Select;
