import React from 'react';
import PropTypes from 'prop-types';
import FlagRow from './FlagRow';

const FlagList = ({ flags, onChangeSelectedFilter }) =>
  flags.map(flag => (
    <FlagRow
      key={flag.id}
      onClick={() => {
        onChangeSelectedFilter(flag.id);
      }}
      flag={flag}
    />
  ));

FlagList.propTypes = {
  flags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onChangeSelectedFilter: PropTypes.func.isRequired,
};

export default FlagList;
