import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Input, Icon, Button } from 'ready/components';
import { FormattedMessage } from 'react-intl';

const StyledBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
  cursor: pointer;
  user-select: none;
  margin-bottom: ${props => props.theme.spacing.l};
`;

const StyledBackButtonText = styled(Text)`
  text-decoration: underline;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 ${props => props.theme.spacing.s} ${props => props.theme.spacing.s} ${props => props.theme.spacing.s};
`;

const StyledHeader = styled(Text)`
  margin-bottom: ${props => props.theme.spacing.xs};
`;

const StyledText = styled(Text)`
  margin-top: ${props => props.theme.spacing.xs};
`;

const InputWrapper = styled.div`
  margin: ${props => props.theme.spacing.m} 0;
`;

const StyledInput = styled(Input)`
  padding: ${props => props.theme.spacing.l} ${props => props.theme.spacing.s};
  border: ${props => props.theme.app.border.accent};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.l};
  margin: ${props => props.theme.spacing.l} 0;
`;
const StyledTextLink = styled(Text)`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
`;

const EditPriceForm = ({
  price,
  setPrice,
  setShowEditPriceForm,
  currency,
  numberOfNights,
  suggestedAlterationPrice,
  getTotalPrice,
  t,
}) => {
  const [newPrice, setNewPrice] = useState(price);
  const [totalPrice, setTotalPrice] = useState(getTotalPrice());

  function reset() {
    setNewPrice(price);
  }
  function apply() {
    setPrice(newPrice);
    setShowEditPriceForm(false);
  }
  useEffect(() => {
    setTotalPrice(
      parseInt(newPrice) +
        suggestedAlterationPrice.cleaningFee +
        suggestedAlterationPrice.extraFees -
        suggestedAlterationPrice.platformFee,
    );
  }, [newPrice]);

  return (
    <>
      <ContentWrapper>
        <StyledBackButton onClick={() => setShowEditPriceForm(false)}>
          <Icon size={Icon.sizes.L} icon={Icon.icons.ARROW_LEFT} />
          <StyledBackButtonText>{t.back}</StyledBackButtonText>
        </StyledBackButton>
        <StyledHeader size={Text.sizes.XL}>{t.proposePrice}</StyledHeader>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.MIN_PRICE"
            values={{ min_price: suggestedAlterationPrice.minimumPrice + ' ' + currency }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.ESTIMATED_PRICE"
            values={{
              estimated_price: suggestedAlterationPrice.totalPrice + ' ' + currency,
            }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.WITHOUT_CITY_TAX"
            values={{ city_tax: suggestedAlterationPrice.cityTax + ' ' + currency }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.WITH_CLEANING_FEE"
            values={{ cleaning_fee: suggestedAlterationPrice.cleaningFee + ' ' + currency }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.WITH_PLATFORM_FEE"
            values={{ platform_fee: suggestedAlterationPrice.platformFee + ' ' + currency }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.WITH_EXTRA_FEES"
            values={{ extra_fees: suggestedAlterationPrice.extraFees + ' ' + currency }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.ESTIMATED_RENTAL_INCOME"
            values={{
              total_rental_income: suggestedAlterationPrice.newPrice + ' ' + currency,
            }}
          />
        </StyledText>
        <InputWrapper>
          <StyledText>
            {t.rentalIncome} {currency}
          </StyledText>
          <StyledInput name="price" type="number" onChange={e => setNewPrice(e.target.value)} value={newPrice} />
        </InputWrapper>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.ESTIMATED_TOTAL_PRICE"
            values={{
              total_estimated_price: totalPrice + ' ' + currency,
            }}
          />
        </StyledText>
        <StyledText>
          <FormattedMessage
            id="BOOKING.ALTERATION.FORM.EDIT.PRICE.ESTIMATION"
            values={{
              estimated_price: Math.round((totalPrice / numberOfNights) * 100) / 100 + ' ' + currency,
              number_of_nights: numberOfNights,
            }}
          />
        </StyledText>
        <ButtonsWrapper>
          <StyledTextLink onClick={reset}>{t.reset}</StyledTextLink>
          <Button
            variation={Button.variations.PRIMARY}
            size={Button.sizes.M}
            inline
            disabled={newPrice < suggestedAlterationPrice.minimumPrice}
            onClick={apply}
          >
            {t.apply}
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </>
  );
};

EditPriceForm.propTypes = {
  price: PropTypes.number.isRequired,
  setPrice: PropTypes.func.isRequired,
  setShowEditPriceForm: PropTypes.func.isRequired,
  numberOfNights: PropTypes.number.isRequired,
  t: PropTypes.shape({
    back: PropTypes.string.isRequired,
    reset: PropTypes.string.isRequired,
    apply: PropTypes.string.isRequired,
    proposePrice: PropTypes.string.isRequired,
    totalPrice: PropTypes.string.isRequired,
  }),
};
export default EditPriceForm;
