import React from 'react';
import styled from 'styled-components';
import { variationsCssProps, variations } from 'ready/utils/constants';
import { getColor } from 'ready/styles/helpers';

const Label = styled.label`
  margin-left: 35px;
`;

const Switch = styled.div`
  position: relative;
  width: 55px;
  height: 20px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${getColor(variations.OUTLINE, variationsCssProps.ACTIVE)};

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

const ToggleSwitch = ({ ...props }) => {
  return (
    <Label>
      <Input type="checkbox" {...props} />
      <Switch />
    </Label>
  );
};

export default ToggleSwitch;
