import React from 'react';
import PropTypes from 'prop-types';

import { getVariationOf } from 'ready/styles/helpers';
import { Text } from 'ready/components/typography';
import { variationsCssProps } from 'ready/utils/constants';
import { COLORS, SIZES } from '../constants';
import { childrenPropType, sizePropType } from 'ready/utils/propTypes';

function withTextWrapper(EnhancedComponent) {
  function wrapText({ children, size, variation, ...props }) {
    return (
      <EnhancedComponent {...props} size={size} variation={variation}>
        {typeof children === 'string' ? (
          <Text
            data-qa="[ready]Button:TextWrapper"
            variation={getVariationOf(
              variation,
              props.active ? variationsCssProps.ACTIVE : variationsCssProps.COLOR,
              COLORS,
            )}
            size={SIZES[size].text}
          >
            {children}
          </Text>
        ) : (
          children
        )}
      </EnhancedComponent>
    );
  }

  wrapText.propTypes = {
    children: childrenPropType,
    size: sizePropType(Text.sizes),
    variation: Text.propTypes.variation,
    active: PropTypes.bool,
  };

  return wrapText;
}

export default withTextWrapper;
