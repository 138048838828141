import React from 'react';
import PropTypes from 'prop-types';

import AutomatedMessage from './AutomatedMessage';
import CollapsibleWithState from '../../../components/CollapsibleWithState';

import { hasErrors } from 'utils';

const AutomatedMessages = ({
  t,
  automatedMessages,
  dispatchSendAutomatedMessageRequested,
  dispatchUpdateAutomatedMessageRequested,
}) => {
  const { isFetching, didInvalidate, data, errors, selectedId } = automatedMessages;

  return (
    <CollapsibleWithState
      title={t.automatedMessagesTitle}
      isFetching={isFetching}
      didInvalidate={didInvalidate}
      errors={errors}
      data-qa="Tab:Collapsible--automatedMessages"
    >
      {data.map(am => (
        <AutomatedMessage
          key={am.id}
          automatedMessage={am}
          t={t}
          sendMessage={() => dispatchSendAutomatedMessageRequested(am)}
          onToggle={() => dispatchUpdateAutomatedMessageRequested(am)}
          disabled={!hasErrors(errors) && am.id === selectedId}
        />
      ))}
    </CollapsibleWithState>
  );
};

AutomatedMessages.propTypes = {
  t: PropTypes.shape({
    automatedMessagesTitle: PropTypes.string.isRequired,
  }).isRequired,
  dispatchUpdateAutomatedMessageRequested: PropTypes.func.isRequired,
  dispatchSendAutomatedMessageRequested: PropTypes.func.isRequired,
  automatedMessages: PropTypes.object,
};

export default AutomatedMessages;
