import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Select } from 'ready/components';
import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';

import { fullRentalPropType } from 'common/propTypes';
import { getRentalName } from 'utils/serializers';

const SelectLabel = styled(FullWidthContentWithLabel)`
  margin-bottom: ${props => props.theme.spacing.s};
`;

export const formatRentalSelectOption = rental =>
  (rental && { id: rental.id, label: getRentalName(rental), value: rental.id }) || undefined;

const SelectProperty = ({ rentals, t, rental, onChange }) => (
  <SelectLabel ifBlank={SelectLabel.ifBlank.HIDE} label={t.selectProperty} data-qa="Amenities:__selectLabel">
    {rentals.length > 1 && (
      <Select
        value={formatRentalSelectOption(rental)}
        onChange={onChange}
        options={rentals.map(formatRentalSelectOption)}
        closeMenuOnSelect
        t={t}
      />
    )}
  </SelectLabel>
);

SelectProperty.propTypes = {
  rental: fullRentalPropType,
  t: PropTypes.object.isRequired,
  rentals: PropTypes.arrayOf(fullRentalPropType).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectProperty;
