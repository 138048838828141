import { useEffect } from 'react';

import { setupInitData } from 'ducks/view';
import { getSearchObj } from 'utils/location';

function useInitDataSetup({ dispatch, match, payload = {} }) {
  useEffect(() => {
    dispatch(
      setupInitData({
        conversationId: (match.params.id && Number(match.params.id)) || null,
        ...getSearchObj(),
        ...payload,
      }),
    );
  }, []);
}

export default useInitDataSetup;
