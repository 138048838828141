import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  changeMessageContent,
  sendMessageRequested,
  changeMessageChannel,
  changeMessageCursorPosition,
  fetchSuggestedReplyRequested,
} from 'ducks/message';
import { updateInquiryRequested } from 'ducks/booking';
import { fetchSavedRepliesRequested } from 'ducks/savedReplies';
import { getMessage, getSuggestedReply } from 'selectors/message';
import { getBookingData, getBookingId } from 'selectors/booking';

import { RequestError } from 'components';
import { Button, DropdownList, DropdownListItem, LoadingIndicator, SplitDropdownButton, Text } from 'ready/components';
import { variationsCssProps, variations } from 'ready/utils/constants';
import { getColor } from 'ready/styles/helpers';

import withTranslations from 'hoc/withTranslations/withTranslations';
import { messageChannels, inquiryStatuses, keyCodes, paths } from 'constants.js';
import { outgoingMessagePropTypeWithState } from 'common/propTypes';
import { isEligibleForPreApproval } from 'utils/businessLogic';
import { inquiryStatusPayload } from 'utils/deserializers';
import { pushToSavedRepliesRoute, pushToConversation } from 'browserHistory';
import { getConversation } from 'selectors/conversation';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: ${props => props.theme.app.border.default};
`;

const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: ${props => props.theme.spacing.xxs};
  }
`;

const RepliesButtonsWrapper = styled(ButtonsWrapper)`
  justify-content: flex-start;
`;

const ActionButtonsWrapper = styled(ButtonsWrapper)`
  justify-content: flex-end;
`;

const TextArea = styled.textarea`
  color: ${props => props.theme.colors.dark};
  height: 11.3rem;
  max-height: 30dvh;
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.m};
  outline: none;
  margin: ${props => props.theme.spacing.s};
  padding: ${props => props.theme.spacing.s};
  padding-top: ${props => props.theme.spacing.xs};
  resize: vertical;
  ${props => props.theme.typography.text.m}
  transition: ${props => props.theme.transition.veryFast};
  &:focus {
    border: ${props => props.theme.app.border.accent};
  }
`;

const Header = styled.div`
  padding: ${props => props.theme.spacing.m};
  padding-left: ${props => props.theme.spacing.xs};
`;

const Footer = styled.div`
  min-height: 6.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.s} ${props => props.theme.spacing.xs};
  border-top: ${props => props.theme.app.border.default};

  @media (max-width: ${props => props.theme.sizes.mobileSmall}px) {
    padding: ${props => props.theme.spacing.xs};
  }
`;

const MessageType = styled.span`
  margin: ${props => props.theme.spacing.xs};
  padding: ${props => props.theme.spacing.xxxs};
  border-bottom: ${props => props.selected && `2px solid ${getColor(variations.OUTLINE, variationsCssProps.ACTIVE)}`};
  cursor: pointer;
`;

const StyledRequestError = styled(RequestError)`
  display: grid;
  background: ${props => props.theme.colors.r100};
  padding: ${props => props.theme.spacing.s};
  margin-bottom: ${props => props.theme.spacing.xs};
  grid-column-gap: ${props => props.theme.spacing.xxs};
  grid-auto-flow: column;
  justify-content: flex-start;
`;

const MessageInputArea = ({
  hasAttachments,
  removeAllAttachments,
  integrationPlatform,
  isDisabled,
  handleIsDisabled,
  open,
  message,
  bookingId,
  isBookingEligibleForPreApproval,
  dispatchChangeMessageContent,
  dispatchSendMessageRequested,
  dispatchChangeMessageChannel,
  dispatchUpdateInquiryRequested,
  dispatchChangeMessageCursorPosition,
  t,
  location,
  dispatchFetchSavedRepliesRequired,
  dispatchFetchSuggestedReply,
  conversationId,
  assistedAnswers,
  suggestedReply,
  isMessageInvalidated,
  tooManyFiles,
}) => {
  useEffect(() => {
    handleIsDisabled(integrationPlatform, message.data.channel);
  }, [message.data.channel, bookingId]);

  const [loadingSuggestedReply, setLoadingSuggestedReply] = useState(false);
  const isSavedRepliesOpened = location.pathname.indexOf(paths.SAVED_REPLIES) > -1;

  const handleSaveRepliesClick = () => {
    if (isSavedRepliesOpened) return pushToConversation();
    dispatchFetchSavedRepliesRequired();
    return pushToSavedRepliesRoute();
  };

  const handleKeyDown = e => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.ENTER) {
      dispatchSendMessageRequested({ close: true });
      e.stopPropagation();
    }
  };

  const handleSendMessageRequest = ({ close } = {}) => {
    dispatchSendMessageRequested({ close });
    removeAllAttachments();
  };

  const handleSelect = e => {
    if (!message.data.content || e.target.selectionStart === message.data.content.length) return;
    dispatchChangeMessageCursorPosition(e.target.selectionStart);
  };

  const handleSuggestedReply = () => {
    setLoadingSuggestedReply(true);
    dispatchFetchSuggestedReply(conversationId, message.data.content.trim());
  };

  const isMessageBoxEmpty = !message.data.content.trim();
  const sendDisabled =
    message.isSending || (isMessageBoxEmpty && !hasAttachments) || !message.data.conversation || tooManyFiles;

  useEffect(() => {
    if (suggestedReply) {
      dispatchChangeMessageContent(suggestedReply);
      setLoadingSuggestedReply(false);
    }
  }, [suggestedReply]);

  useEffect(() => {
    if (isMessageInvalidated) {
      setLoadingSuggestedReply(false);
    }
  }, [isMessageInvalidated]);

  return (
    <Wrapper data-qa="Conversation:MessageInputArea__wrapper">
      <Header>
        {message.config.channels.map(key => {
          const channel = messageChannels[key.toUpperCase()];
          return (
            <MessageType
              onClick={() => dispatchChangeMessageChannel(channel.value)}
              key={channel.value}
              selected={message.data.channel === channel.value}
              data-qa={`MessageInputArea:__messageType--${channel.value}`}
            >
              <Text size={Text.sizes.S}>{t(channel.key)}</Text>
            </MessageType>
          );
        })}
      </Header>
      {message.didInvalidate && <StyledRequestError errors={message.errors} />}
      <TextArea
        onKeyDown={handleKeyDown}
        onChange={e => dispatchChangeMessageContent(e.target.value)}
        onSelect={handleSelect}
        value={message.data.content}
        data-qa="MessageInputArea:Textarea"
        disabled={hasAttachments}
      />
      <Footer data-qa="MessageInputArea:__footer">
        <RepliesButtonsWrapper>
          <Button
            icon={{ icon: Button.icon.icons.v2.FILE }}
            onClick={handleSaveRepliesClick}
            size={Button.sizes.S}
            variation={Button.variations.OUTLINE}
            active={isSavedRepliesOpened}
            data-qa="MessageInputArea:Button--savedReplies"
            inline
          >
            {t('SAVED_REPLIES.BUTTON')}
          </Button>
          {assistedAnswers &&
            (loadingSuggestedReply ? (
              <LoadingIndicator size="xs" />
            ) : (
              <Button
                onClick={handleSuggestedReply}
                icon={{ icon: Button.icon.icons.v2.CLEAN }}
                inline
                size={Button.sizes.S}
                variation={Button.variations.OUTLINE}
                disabled={!conversationId}
              >
                {isMessageBoxEmpty ? t('CONVERSATION.AI_REPLIES.GENERATE') : t('CONVERSATION.AI_REPLIES.IMPROVE')}
              </Button>
            ))}

          <Button
            icon={{ icon: Button.icon.icons.v2.UPLOAD }}
            onClick={open}
            size={Button.sizes.S}
            variation={Button.variations.OUTLINE}
            data-qa="MessageInputArea:Button--uploadFiles"
            title={isDisabled ? t('CONVERSATION.ATTACHMENT.DISABLED') : ''}
            disabled={isDisabled}
            inline
          >
            {t('UPLOAD_FILES.BUTTON')}
          </Button>
        </RepliesButtonsWrapper>
        <ActionButtonsWrapper data-qa="MessageInputArea:__wrapper--sendActions">
          {isBookingEligibleForPreApproval ? (
            <SplitDropdownButton
              data-qa="MessageInputArea:SplitDropdownButton"
              onClick={() => dispatchSendMessageRequested()}
              disabled={sendDisabled}
              variation={SplitDropdownButton.variations.OUTLINE}
              verticalPosition={SplitDropdownButton.verticalPositions.TOP}
              horizontalPosition={SplitDropdownButton.horizontalPositions.RIGHT}
              renderButtonContent={() => t('CONVERSATION.SEND')}
              renderDropdownContent={({ closeDropdown }) => (
                <DropdownList data-qa="MessageInputArea:DropdownList">
                  <DropdownListItem
                    data-qa="MessageInputArea:DropdownListItem--sendAndPreApprove"
                    onClick={() => {
                      closeDropdown();
                      dispatchSendMessageRequested();
                      dispatchUpdateInquiryRequested(bookingId, inquiryStatusPayload(inquiryStatuses.PRE_APPROVED));
                    }}
                  >
                    {t('CONVERSATION.SEND_AND_PREAPPROVE')}
                  </DropdownListItem>
                  <DropdownListItem
                    data-qa="MessageInputArea:DropdownListItem--sendAndDecline"
                    onClick={() => {
                      closeDropdown();
                      dispatchSendMessageRequested();
                      dispatchUpdateInquiryRequested(bookingId, inquiryStatusPayload(inquiryStatuses.DENIED));
                    }}
                  >
                    {t('CONVERSATION.SEND_AND_DECLINE')}
                  </DropdownListItem>
                </DropdownList>
              )}
            />
          ) : (
            <Button
              inline
              onClick={() => handleSendMessageRequest()}
              disabled={sendDisabled}
              data-qa="MessageInputArea:Button--send"
              size={Button.sizes.S}
              variation={Button.variations.OUTLINE}
            >
              {t('CONVERSATION.SEND')}
            </Button>
          )}
          <Button
            disabled={sendDisabled}
            onClick={() => handleSendMessageRequest({ close: true })}
            inline
            data-qa="MessageInputArea:Button--sendAndClose"
            size={Button.sizes.S}
          >
            {t('CONVERSATION.SEND_AND_CLOSE')}
          </Button>
        </ActionButtonsWrapper>
      </Footer>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  dispatchChangeMessageContent: changeMessageContent,
  dispatchSendMessageRequested: sendMessageRequested,
  dispatchChangeMessageChannel: changeMessageChannel,
  dispatchUpdateInquiryRequested: updateInquiryRequested,
  dispatchFetchSavedRepliesRequired: fetchSavedRepliesRequested,
  dispatchChangeMessageCursorPosition: changeMessageCursorPosition,
  dispatchFetchSuggestedReply: fetchSuggestedReplyRequested,
};

const mapStateToProps = state => {
  const booking = getBookingData(state);
  const conversation = getConversation(state);
  const message = getMessage(state);
  const conversationId = conversation && conversation.data ? conversation.data.id : null;
  const isMessageInvalidated = message && message.didInvalidate;
  const assistedAnswers = conversation && conversation.data ? conversation.data.assisted_answers : false;
  return {
    message: getMessage(state),
    suggestedReply: getSuggestedReply(state),
    isMessageInvalidated,
    bookingId: getBookingId(state),
    conversationId,
    assistedAnswers,
    isBookingEligibleForPreApproval: isEligibleForPreApproval(booking),
  };
};

MessageInputArea.propTypes = {
  message: outgoingMessagePropTypeWithState.isRequired,
  dispatchChangeMessageCursorPosition: PropTypes.func.isRequired,
  dispatchChangeMessageContent: PropTypes.func.isRequired,
  dispatchSendMessageRequested: PropTypes.func.isRequired,
  dispatchChangeMessageChannel: PropTypes.func.isRequired,
  dispatchUpdateInquiryRequested: PropTypes.func.isRequired,
  dispatchFetchSavedRepliesRequired: PropTypes.func.isRequired,
  dispatchFetchSuggestedReply: PropTypes.func.isRequired,
  suggestedReply: PropTypes.string,
  conversationId: PropTypes.number,
  assistedAnswers: PropTypes.bool,
  bookingId: PropTypes.number,
  isMessageInvalidated: PropTypes.bool,
  isBookingEligibleForPreApproval: PropTypes.bool,
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  withTranslations,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MessageInputArea);
