import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tag, Tooltip, Text } from 'ready/components';

const StyledTag = styled(Tag)`
  display: inline-flex;
  min-width: auto;
`;

const Wrapper = styled.div`
  position: relative;
`;

const SelfCheckInTag = ({ selfCheckIn, tooltipPosition, t }) => {
  if (!selfCheckIn) return false;

  const [visibility, setVisibility] = useState(false);
  return (
    <Wrapper onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
      {visibility && (
        <Tooltip position={tooltipPosition} variation={Tooltip.variations.WHITE} data-qa="SelfCheckInTag:Tooltip">
          <Text size={Text.sizes.S} variation={Text.variations.DARK} data-qa="SelfCheckInTag:Text">
            {t.tooltip}
          </Text>
        </Tooltip>
      )}
      <StyledTag variation={Tag.variations.SUCCESS} widthBounds size={Tag.sizes.XS} data-qa="SelfCheckInTag:Tag">
        {t.label}
      </StyledTag>
    </Wrapper>
  );
};

SelfCheckInTag.propTypes = {
  selfCheckIn: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  t: PropTypes.shape({
    tooltip: PropTypes.string,
    label: PropTypes.string,
  }),
};

SelfCheckInTag.defaultProps = {
  tooltipPosition: Tooltip.positions.RIGHT,
};

export default SelfCheckInTag;
