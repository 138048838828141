import styled, { css } from 'styled-components';

import { variations, variationsCssProps } from 'ready/utils/constants';
import { withThemeColor, getVariationOf } from 'ready/styles/helpers';
import { COLORS, SIZES } from '../constants';
import { inlineDisplay, baseDisplay } from '../styles';

const withBackground = ({ variation, light, dark }) =>
  css`
    background-color: ${withThemeColor({
      lighten: light && 0.1,
      darken: dark && 0.1,
      variation: COLORS[variation].backgroundColor,
    })};
  `;

const withOutlineStyles = ({ variation, light, dark, theme }) =>
  variation === variations.OUTLINE &&
  css`
    border-color: ${withThemeColor({
      lighten: light && 0.1,
      darken: dark && 0.1,
      variation: getVariationOf(variation, variationsCssProps.BORDER_COLOR),
    })};
    text-shadow: ${theme.textShadow.s};
  `;

const withRegularStyles = ({ variation, theme }) =>
  css`
    border-radius: ${theme.borderRadius.m};
    border: 1px solid transparent;
    transition: ${theme.transition.fast};
    white-space: nowrap;
    text-overflow: ellipsis;
    &:not([disabled]) {
      &:hover,
      &:focus {
        box-shadow: ${theme.boxShadow.m};
      }
      &:hover {
        background-color: ${withThemeColor({ darken: 0.1, variation: COLORS[variation].backgroundColor })};
      }
    }
    ${props => SIZES[props.size].button};
    ${withOutlineStyles};
    ${withBackground};
  `;

const RegularButton = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  user-select: none;
  ${withRegularStyles};
  ${props => inlineDisplay(props) || baseDisplay()}

  &[disabled] {
    opacity: 0.5;
    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }
`;

export default RegularButton;
