import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { sizes } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';
import { Button, Icon } from 'ready/components';

const { XXS, XS, S, M, L, XL, XXL } = sizes;

const StyledAttachmentPreview = styled.div`
  position: relative;
`;

const clickable = ({ onClick }) =>
  onClick &&
  css`
    cursor: pointer;
  `;

const StyledImagePreview = styled.img`
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.l};
  width: ${props => props.theme.imageSize[props.size]};
  height: ${props => props.theme.imageSize[props.size]};
  background-color: ${props => props.theme.colors.white};
  object-fit: contain;
  ${clickable}
`;

const StyledVideoPreview = styled.div`
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.l};
  width: ${props => props.theme.imageSize[props.size]};
  height: ${props => props.theme.imageSize[props.size]};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.n900};
  display: flex;
  justify-content: center;
  align-items: center;
  ${clickable}
`;

const CloseButton = styled.div`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.n800};
  border-radius: 50%;
  width: ${props => props.theme.spacing.m};
  height: ${props => props.theme.spacing.m};
  line-height: ${props => props.theme.spacing.m};
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -${props => props.theme.spacing.xs};
  right: -${props => props.theme.spacing.xs};
`;

const AttachmentPreview = ({ onClick, onClose, ...props }) => (
  <StyledAttachmentPreview {...props}>
    {props.type && props.type.startsWith('image/') && (
      <StyledImagePreview onClick={onClick} src={props.src} {...props} />
    )}
    {props.type && props.type.startsWith('video/') && (
      <StyledVideoPreview onClick={onClick} {...props}>
        <Icon color="white" size={Icon.sizes.XXL} icon={Icon.icons.v2.PLAY} />
      </StyledVideoPreview>
    )}
    {props.type && props.type === 'application/pdf' && (
      <Button
        icon={{ icon: Button.icon.icons.v2.FILE }}
        variation={Button.variations.OUTLINE}
        onClick={onClick}
        disabled={props.closable}
        inline
      >
        {props.name}
      </Button>
    )}
    {props.closable && <CloseButton onClick={onClose}>x</CloseButton>}
  </StyledAttachmentPreview>
);

AttachmentPreview.sizes = { XXS, XS, S, M, L, XL, XXL };

AttachmentPreview.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  size: sizePropType(AttachmentPreview.sizes),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  closable: PropTypes.bool,
};

AttachmentPreview.defaultProps = {
  size: XXS,
  closable: false,
};

export default AttachmentPreview;
