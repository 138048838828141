import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { keyCodes } from 'constants.js';

import { getUser } from 'selectors/user';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { fetchUserRequested } from 'ducks/user';

import { Logo, Field, RequestError } from 'components';
import { Button, LoadingIndicator } from 'ready/components';

const LoginPage = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.n100};
  display: grid;
  grid-template-rows: 3fr auto 4fr;
  grid-template-columns: 1fr auto 1fr;
  height: 100dvh;
  justify-content: center;
  width: 100vw;
`;

const LoginForm = styled.form`
  width: 28.8rem;
  min-height: 41rem;
  box-shadow: ${props => props.theme.boxShadow.m};
  padding: ${props => props.theme.spacing.xl};
  grid-column: 2;
  grid-row: 2;
  display: grid;
  justify-items: center;
  align-content: center;
  grid-row-gap: ${props => props.theme.spacing.m};
`;

const StyledLogo = styled(Logo)`
  flex: 1 0 auto;
  margin-bottom: ${props => props.theme.spacing.m};
  max-width: 14.5rem;
`;

const Login = ({ dispatchFetchUserRequested, t, isFetching, errors, didInvalidate }) => {
  const [{ username, password }, setCredentials] = useState({ username: '', password: '' });

  const onSubmit = () => {
    dispatchFetchUserRequested({ username, password });
    setCredentials({ username, password: '' });
  };

  const handleOnKeyDown = e => {
    if (e.keyCode === keyCodes.ENTER) {
      onSubmit();
    }
  };

  return (
    <LoginPage data-qa="root:Login__loginPage">
      <LoginForm onKeyDown={handleOnKeyDown} data-qa="Login:__loginForm">
        <StyledLogo />
        {didInvalidate && <RequestError errors={errors} bold />}
        <Field
          label={t.username}
          placeholder={t.username}
          value={username}
          onChange={e => setCredentials({ password, username: e.target.value })}
          errors={errors.username}
          name="username"
          autoComplete="username"
          data-qa="Login:Field--username"
        />
        <Field
          label={t.password}
          placeholder={t.password}
          value={password}
          onChange={e => setCredentials({ password: e.target.value, username })}
          type="password"
          errors={errors.password}
          name="password"
          autoComplete="current-password"
          data-qa="Login:Field--password"
        />
        <Button disabled={isFetching} onClick={onSubmit}>
          {isFetching ? <LoadingIndicator color="white" size={LoadingIndicator.sizes.XS} /> : t.signInButton}
        </Button>
      </LoginForm>
    </LoginPage>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
  dispatchFetchUserRequested: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  didInvalidate: PropTypes.bool,
  errors: PropTypes.shape({
    non_field_errors: PropTypes.arrayOf(PropTypes.string),
    username: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.arrayOf(PropTypes.string),
  }),
  t: PropTypes.shape({
    signInButton: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => getUser(state);

export default compose(
  connect(
    mapStateToProps,
    { dispatchFetchUserRequested: fetchUserRequested },
  ),
  withMappedTranslations({
    signInButton: 'LOGIN.SIGN_IN.BUTTON',
    username: 'LOGIN.SIGN_IN.USERNAME',
    password: 'LOGIN.SIGN_IN.PASSWORD',
  }),
)(Login);
