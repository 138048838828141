import React from 'react';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text } from 'ready/components/typography';

const PendingPaymentWarning = ({ t, ...props }) => (
  <ChatNotification
    variation={ChatNotification.variations.ERROR}
    data-qa="Notification:PendingPaymentWarning__ChatNotification"
    t={t}
    {...props}
  >
    <Text data-qa="PendingPaymentWarning:Text--instructions"> {t.pendingPayment}</Text>
  </ChatNotification>
);

PendingPaymentWarning.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
    pendingPayment: PropTypes.string.isRequired,
  }),
};

export default PendingPaymentWarning;
