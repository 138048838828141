import { createSelector } from 'reselect';

const _getSavedReplies = state => state.savedReplies;
export const getSavedReplies = createSelector(
  [_getSavedReplies],
  savedReplies => savedReplies.toJS(),
);

export const getNextPageLink = createSelector(
  [_getSavedReplies],
  messages => messages.getIn(['page', 'nextLink']),
);
