import { fromJS } from 'immutable';

export const SAVE_FILTER_PRESET_REQUESTED = 'SAVE_FILTER_PRESET_REQUESTED';
export const saveFilterPresetRequested = ({ name, filters, id }) => ({
  type: SAVE_FILTER_PRESET_REQUESTED,
  payload: { name, filters, id },
});

export const SAVE_FILTER_PRESET_SUCCESSED = 'SAVE_FILTER_PRESET_SUCCESSED';
export const saveFilterPresetSuccessed = () => ({
  type: SAVE_FILTER_PRESET_SUCCESSED,
});

export const SAVE_FILTER_PRESET_FAILED = 'SAVE_FILTER_PRESET_FAILED';
export const saveFilterPresetFailed = ({ errors }) => ({
  type: SAVE_FILTER_PRESET_FAILED,
  payload: { errors },
});

export const FETCH_FILTER_PRESETS_REQUESTED = 'FETCH_FILTER_PRESETS_REQUESTED';
export const fetchFilterPresetsRequested = () => ({
  type: FETCH_FILTER_PRESETS_REQUESTED,
});

export const FETCH_FILTER_PRESETS_SUCCESSED = 'FETCH_FILTER_PRESETS_SUCCESSED';
export const fetchFilterPresetsSuccessed = data => ({
  type: FETCH_FILTER_PRESETS_SUCCESSED,
  payload: { data },
});

export const FETCH_FILTER_PRESETS_FAILED = 'FETCH_FILTER_PRESETS_FAILED';
export const fetchFilterPresetsFailed = error => ({
  type: FETCH_FILTER_PRESETS_FAILED,
  payload: { error },
});

export const DELETE_FILTER_PRESET_REQUESTED = 'DELETE_FILTER_PRESET_REQUESTED';
export const deleteFilterPresetRequested = id => ({
  type: DELETE_FILTER_PRESET_REQUESTED,
  payload: { id },
});

export const DELETE_FILTER_PRESET_SUCCESSED = 'DELETE_FILTER_PRESET_SUCCESSED';
export const deleteFilterPresetSuccessed = id => ({
  type: DELETE_FILTER_PRESET_SUCCESSED,
  payload: { id },
});

export const DELETE_FILTER_PRESET_FAILED = 'DELETE_FILTER_PRESET_FAILED';
export const deleteFilterPresetFailed = error => ({
  type: DELETE_FILTER_PRESET_FAILED,
  payload: { error },
});

export const CHANGE_IS_SAVE_PRESET_OPEN = 'CHANGE_IS_SAVE_PRESET_OPEN';
export const changeIsSavePresetOpen = value => ({ type: CHANGE_IS_SAVE_PRESET_OPEN, payload: { value } });

export const CHANGE_IS_LOAD_PRESET_OPEN = 'CHANGE_IS_LOAD_PRESET_OPEN';
export const changeIsLoadPresetOpen = value => ({ type: CHANGE_IS_LOAD_PRESET_OPEN, payload: { value } });

export const initialState = fromJS({
  data: [],
  isFetching: false,
  didInvalidate: false,
  errors: {},
  view: {
    isLoadPresetOpen: false,
    isSavePresetOpen: false,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FILTER_PRESETS_REQUESTED:
    case DELETE_FILTER_PRESET_REQUESTED:
      return state.set('didInvalidate', false).set('errors', initialState.get('errors'));

    case FETCH_FILTER_PRESETS_SUCCESSED:
      return state.set('data', fromJS(payload.data));

    case DELETE_FILTER_PRESET_SUCCESSED:
      return state.update('data', presets => presets.filterNot(preset => +preset.get('id') === payload.id));

    case FETCH_FILTER_PRESETS_FAILED:
      return state
        .set('didInvalidate', true)
        .update('errors', value => value.set('fetching', true).merge(payload.errors));

    case DELETE_FILTER_PRESET_FAILED:
    case SAVE_FILTER_PRESET_FAILED:
      return state
        .set('didInvalidate', true)
        .update('errors', value => value.set('updating', true).merge(payload.errors));

    case CHANGE_IS_LOAD_PRESET_OPEN:
      return state.setIn(['view', 'isLoadPresetOpen'], payload.value);

    case CHANGE_IS_SAVE_PRESET_OPEN:
      return state.setIn(['view', 'isSavePresetOpen'], payload.value);

    default:
      return state;
  }
};
