import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { callCenterPropShape } from 'common/propTypes';

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  width: 100%;
  max-height: 45dvh;
  overflow-y: auto;
  border-top: ${props => `${props.theme.borderWidth.m} solid ${props.theme.colors.n200}`};
`;

const Item = styled.li`
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.s}`};
  box-sizing: border-box;
  border-bottom: ${props => props.theme.app.border.default};
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.typography.text.m};
  &:hover {
    background-color: ${props => props.theme.colors.n200};
  }
`;

const ForwardList = ({ actions, callForwarding }) => (
  <List>
    {callForwarding.workers.map(worker => (
      <Item onClick={() => actions.forwardCall({ target: worker })} key={worker.friendlyName}>
        {worker.username}
      </Item>
    ))}
  </List>
);

ForwardList.propTypes = {
  actions: PropTypes.shape({
    forwardCall: PropTypes.func.isRequired,
  }),
  callForwarding: callCenterPropShape.callForwarding,
};

export default ForwardList;
