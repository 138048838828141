import React from 'react';
import PropTypes from 'prop-types';

import stackedLogo from 'assets/images/rr-logo.svg';
import inlineLogo from 'assets/images/rr-logo.svg';

const Logo = ({ className, width, variation }) => {
  const src = variation === Logo.variations.STACKED ? stackedLogo : inlineLogo;
  return <img className={className} src={src} width={width} alt="GuestReady Communication Logo" />;
};

Logo.variations = {
  STACKED: 'stacked',
  INLINE: 'inline',
};

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string.isRequired,
  variation: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  width: '100%',
  variation: Logo.variations.STACKED,
};

export default Logo;
