import React, { useEffect } from 'react';
import styled from 'styled-components';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getCallCenter, getCallCenterErrors, getCallReasons } from 'selectors/callCenter';
import { getTwilioPhoneNumbers } from 'selectors/user';
import { actions as duckActions } from 'ducks/callCenter';

import CallOverlay from './components/CallOverlay';
import CallForwarding from './components/CallForwarding';
import CallInfo from './components/CallInfo';
import OutgoingNumberSelect from './components/OutgoingNumberSelect';
import Actions from './components/Actions';
import ActionDetails from './components/ActionDetails';
import MinimizedCall from './components/MinimizedCall';
import CallCenterOfflineWarning from './components/CallCenterOfflineWarning';
import { OverlayBackground, RequestError } from 'components';
import { Text, Modal, Button, Tabs, Input } from 'ready/components';
import { getDefaultReservationFromConversation } from 'utils/businessLogic';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { getUserFullName } from 'utils/user';
import {
  isCallCenterReady,
  shouldEnableCloseCallCenter,
  shouldDisplayCallCenterOffWarning,
  shouldEnableGuestCall,
  isCallCenterOn,
  canForwardCall,
} from 'utils/businessLogic';
import {
  callCenterStatuses,
  twilioConnectionStatuses,
  taskRouterActivities,
  callCenterPages,
  callCenterTabs,
} from 'constants.js';
import { callCenterPropShape, callReasonsPropType } from 'common/propTypes';
import history from 'browserHistory';
import { phoneNumberMask } from 'utils/index';

const StyledModal = styled(Modal)`
  width: 40rem;
`;

const StyledRequestError = styled(RequestError)`
  display: inline-block;
  margin-bottom: ${props => props.theme.spacing.s};
`;

const Tab = styled.div`
  display: grid;
  min-height: 20rem;
  min-width: 22rem;
  padding: ${props => props.theme.spacing.s};
  width: 100%;
`;

const StyledInput = styled(Input)`
  margin-bottom: ${props => props.theme.spacing.l};
  margin-top: ${props => props.theme.spacing.xxs};
`;

const ErrorWrapper = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const isTabDisabled = (status, selectedTabIndex, tabIndex) =>
  status !== twilioConnectionStatuses.READY && selectedTabIndex !== tabIndex;

export const CallCenter = ({
  t,
  callCenterReady,
  callCenterOn,
  guestCallEnabled,
  closeEnabled,
  taskRouter,
  callForwarding,
  device,
  connection,
  callReasons,
  data,
  view,
  errors,
  isErrorVisible,
  twilioPhoneNumbers,
  ...actions
}) => {
  const conversation = data.conversation;
  let language_name;
  let country_code;
  let country_name;
  if (conversation) {
    const reservation = getDefaultReservationFromConversation(conversation);
    if (reservation) {
      language_name = reservation.language_name;
      country_code = reservation.country_code;
      country_name = reservation.country_name;
    }
  }
  useEffect(() => {
    if (isCallCenterReady(taskRouter, device) && connection.status !== twilioConnectionStatuses.COMPLETED)
      actions.enableConnection();
  }, [device.status, taskRouter.activityName]);

  actions.goBack = () => {
    history.goBack();
    actions.closeCall();
  };

  return (
    <>
      {callCenterOn && view.status === callCenterStatuses.OPENED && (
        <CallOverlay
          t={t}
          minimizeEnabled={connection.status === twilioConnectionStatuses.IN_PROGRESS}
          closeEnabled={closeEnabled}
          onClose={actions.closeCall}
          onMinimize={() => actions.changeViewStatus(callCenterStatuses.MINIMIZED)}
          isRecorded={data.isRecorded}
        >
          {[callCenterPages.FORWARD_LIST, callCenterPages.FORWARDING].includes(view.page) && (
            <CallForwarding view={view} callForwarding={callForwarding} actions={actions} t={t} />
          )}
          {[callCenterPages.DEFAULT, callCenterPages.KEYPAD].includes(view.page) && (
            <Tabs alignTabs={Tabs.alignment.FIT_CONTENT} onChange={actions.changeTab} tabIndex={view.tab}>
              <Tab
                title={t.tabGuest}
                disabled={!guestCallEnabled || isTabDisabled(connection.status, view.tab, callCenterTabs.GUEST)}
              >
                {guestCallEnabled && (
                  <>
                    <CallInfo
                      t={t}
                      name={getUserFullName(data.guest)}
                      category={data.category}
                      phone={data.guest.phone_number}
                      language={language_name}
                      country_code={country_code}
                      country_name={country_name}
                    />
                    <ActionDetails status={connection.status} startedAt={connection.startedAt} t={t} />
                    <Actions
                      callReasons={callReasons}
                      data={data}
                      view={view}
                      connection={connection}
                      callForwarding={callForwarding}
                      t={t}
                      actions={actions}
                    />
                    <OutgoingNumberSelect
                      t={t}
                      status={connection.status}
                      phoneNumbers={twilioPhoneNumbers}
                      value={data.outgoingPhoneNumber}
                      onChange={e => actions.changeOutgoingPhoneNumber(e.value)}
                    />
                  </>
                )}
              </Tab>
              <Tab title={t.tabOther} disabled={isTabDisabled(connection.status, view.tab, callCenterTabs.OTHER)}>
                <StyledInput
                  name="customPhoneNumber"
                  placeholder={t.phoneNumberPlaceholder}
                  onChange={e => actions.changePhoneNumber(phoneNumberMask(e.target.value))}
                  value={data.customPhoneNumber}
                />
                <ActionDetails status={connection.status} startedAt={connection.startedAt} t={t} />
                <Actions
                  callReasons={callReasons}
                  data={data}
                  view={view}
                  connection={connection}
                  callForwarding={callForwarding}
                  t={t}
                  actions={{ ...actions, placeCall: () => actions.placeCustomCall(data.customPhoneNumber) }}
                />
                <OutgoingNumberSelect
                  t={t}
                  status={connection.status}
                  phoneNumbers={twilioPhoneNumbers}
                  value={data.outgoingPhoneNumber}
                  onChange={e => actions.changeOutgoingPhoneNumber(e.value)}
                />
              </Tab>
            </Tabs>
          )}
        </CallOverlay>
      )}
      {callCenterOn && view.status === callCenterStatuses.MINIMIZED && (
        <MinimizedCall
          text={view.tab === 0 ? getUserFullName(data.guest) : data.customPhoneNumber}
          t={t}
          actions={actions}
          startedAt={connection.startedAt}
          isMuted={connection.isMuted}
          canForwardCall={canForwardCall({ data, connection, callForwarding })}
          isRecorded={data.isRecorded}
        />
      )}
      {shouldDisplayCallCenterOffWarning(device, taskRouter) && (
        <CallCenterOfflineWarning
          t={pick(t, ['callCenterOffButtonOff', 'callCenterOffButtonOn', 'callCenterOffHeader', 'callCenterOffText'])}
          onSwitchOn={() => actions.updateTaskRouterStatusRequested(taskRouterActivities.AVAILABLE)}
          onSwitchOff={() => actions.updateTaskRouterStatusRequested(taskRouterActivities.UNAVAILABLE)}
        />
      )}
      {isErrorVisible && !!Object.keys(errors).length && (
        <OverlayBackground>
          <StyledModal
            heading={t.errorHeader}
            size={Modal.sizes.M}
            closeEnabled
            onClose={() => actions.hideCallCenterError()}
          >
            <ErrorWrapper>
              <Text variation={Text.variations.ERROR} bold>
                {t.errorLabel}
              </Text>
              {errors.map((error, index) => (
                <StyledRequestError key={`error_${index}`} errors={error} />
              ))}
              <Text variation={Text.variations.GREY} element="p" size={Text.sizes.S}>
                {t.errorText}
              </Text>
            </ErrorWrapper>
          </StyledModal>
        </OverlayBackground>
      )}
      <Button
        size={Button.sizes.L}
        icon={{ icon: Button.icon.icons.PHONE }}
        disabled={!callCenterOn}
        onClick={actions.expandCall}
        hint={{ text: callCenterOn ? t.hintCallGuest : t.hintCallCenterNotStarted }}
        clean
      />
    </>
  );
};

CallCenter.propTypes = {
  callCenterReady: PropTypes.bool,
  callCenterOn: PropTypes.bool,
  guestCallEnabled: PropTypes.bool,
  closeEnabled: PropTypes.bool,
  twilioPhoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({ phone: PropTypes.string.isRequired, name: PropTypes.string.isRequired }),
  ),
  taskRouter: callCenterPropShape.taskRouter,
  callForwarding: callCenterPropShape.callForwarding,
  device: callCenterPropShape.device,
  connection: callCenterPropShape.connection,
  data: callCenterPropShape.data,
  callReasons: callReasonsPropType,
  view: callCenterPropShape.view,
  errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
  isErrorVisible: PropTypes.bool,
  t: PropTypes.shape({
    incomingCall: PropTypes.string.isRequired,
    callEnded: PropTypes.string.isRequired,
    mute: PropTypes.string.isRequired,
    unmute: PropTypes.string.isRequired,
    endCall: PropTypes.string.isRequired,
    forward: PropTypes.string.isRequired,
    backToPreviousConversation: PropTypes.string.isRequired,
    stayAtThisConversation: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    reject: PropTypes.string.isRequired,
    notesLabel: PropTypes.string.isRequired,
    notesSend: PropTypes.string.isRequired,
    outboundCall: PropTypes.string.isRequired,
    callGuest: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    calling: PropTypes.string.isRequired,
    ringing: PropTypes.string.isRequired,
    expand: PropTypes.string.isRequired,
    phoneNumberPlaceholder: PropTypes.string.isRequired,
    forwardingTo: PropTypes.string.isRequired,
    noAgentsAvailable: PropTypes.string.isRequired,
    recordingWarning: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => {
  const { data, connection, device, view, taskRouter, callForwarding, errors } = getCallCenter(state);
  return {
    callCenterReady: isCallCenterReady(taskRouter, device),
    callCenterOn: isCallCenterOn(taskRouter, device),
    guestCallEnabled: shouldEnableGuestCall(data.guest),
    closeEnabled: shouldEnableCloseCallCenter(data, connection, view),
    twilioPhoneNumbers: getTwilioPhoneNumbers(state),
    callReasons: getCallReasons(state),
    taskRouter,
    callForwarding,
    device,
    connection,
    data,
    view,
    errors: getCallCenterErrors(state),
    isErrorVisible: errors.visible,
  };
};

export default compose(
  withMappedTranslations({
    incomingCall: 'CALL_CENTER.INCOMING_CALL',
    callEnded: 'CALL_CENTER.CALL_ENDED',
    callGuest: 'CALL_CENTER.CALL_GUEST',
    mute: 'CALL_CENTER.MUTE',
    unmute: 'CALL_CENTER.UNMUTE',
    endCall: 'CALL_CENTER.END_CALL',
    forward: 'CALL_CENTER.FORWARD',
    backToPreviousConversation: 'CALL_CENTER.BACK_TO_PREVIOUS_CONVERSATION',
    stayAtThisConversation: 'CALL_CENTER.STAY_AT_THIS_CONVERSATION',
    answer: 'CALL_CENTER.ANSWER',
    reject: 'CALL_CENTER.REJECT',
    notesLabel: 'CALL_CENTER.NOTES_LABEL',
    notesSend: 'CALL_CENTER.NOTES_SEND',
    notesPlaceholder: 'CALL_CENTER.NOTES_PLACEHOLDER',
    callReasonLabel: 'CALL_CENTER.CALL_REASON_LABEL',
    outboundCall: 'CALL_CENTER.OUTBOUND_CALL',
    noteWarning: 'CALL_CENTER.NOTE_WARNING',
    cancel: 'CALL_CENTER.CANCEL',
    calling: 'CALL_CENTER.CALLING',
    ringing: 'CALL_CENTER.RINGING',
    expand: 'CALL_CENTER.EXPAND',
    callCenterOffButtonOff: 'CALL_CENTER.OFF_NOTIFICATION_BUTTON_OFF',
    callCenterOffButtonOn: 'CALL_CENTER.OFF_NOTIFICATION_BUTTON_ON',
    callCenterOffHeader: 'CALL_CENTER.OFF_NOTIFICATION_HEADER',
    callCenterOffText: 'CALL_CENTER.OFF_NOTIFICATION_TEXT',
    keypad: 'CALL_CENTER.KEYPAD',
    back: 'TEXT.SHARED.BACK',
    errorHeader: 'CALL_CENTER.CALL_CENTER_ERROR_HEADER',
    errorText: 'CALL_CENTER.CALL_CENTER_ERROR_TEXT',
    errorLabel: 'TEXT.SHARED.ERROR',
    hintCallGuest: 'TOP_BAR.HINT.CALL_GUEST',
    hintCallCenterNotStarted: 'TOP_BAR.HINT.CALL_CENTER_NOT_STARTED',
    tabGuest: 'CALL_CENTER.TABS.GUEST',
    tabOther: 'CALL_CENTER.TABS.OTHER',
    phoneNumberPlaceholder: 'CALL_CENTER.PHONE_NUMBER_PLACEHOLDER',
    forwardingTo: 'CALL_CENTER.FORWARDING_TO',
    noAgentsAvailable: 'CALL_CENTER.NO_AGENTS_AVAILABLE',
    outgoingNumber: 'CALL_CENTER.OUTGOING_NUMBER',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
    selectOption: 'TEXT.SHARED.SELECT_OPTION',
    recordingWarning: 'CALL_CENTER.RECORDING_WARNING',
  }),
  connect(
    mapStateToProps,
    duckActions,
  ),
)(CallCenter);
