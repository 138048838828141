import React from 'react';
import PropTypes from 'prop-types';
import { userPropType, callCenterPropShape } from 'common/propTypes';

import { Button } from 'ready/components';

import {
  isCallCenterOn,
  isCallCenterEnabled,
  isTaskRouterActivityBusy,
  isTaskRouterActivityPaused,
  canEnableCallCenter,
} from 'utils/businessLogic';
import { taskRouterActivities } from 'constants.js';

const CallCenterButton = ({
  taskRouter,
  device,
  user,
  dispatchSetupCallCenterRequested,
  dispatchUpdateTaskRouterStatusRequested,
  t,
}) => {
  const canEnable = canEnableCallCenter({ device, taskRouter }, user);
  const callCenterEnabled = isCallCenterEnabled({ device, taskRouter }, user);
  const callCenterOn = isCallCenterOn(taskRouter, device);
  const callCenterBusy = isTaskRouterActivityBusy(taskRouter);
  const callCenterPaused = isTaskRouterActivityPaused(taskRouter);

  if (!canEnable) {
    return (
      <Button
        size={Button.sizes.S}
        variation={Button.variations.GREY}
        hint={{ enabled: true, text: t.hintCallCenterDisabled }}
        disabled
      >
        {t.callCenterStart}
      </Button>
    );
  }

  if (!callCenterEnabled) {
    return (
      <Button
        size={Button.sizes.S}
        variation={Button.variations.OUTLINE}
        onClick={() => dispatchSetupCallCenterRequested()}
      >
        {t.callCenterStart}
      </Button>
    );
  }

  if (callCenterOn) {
    return (
      <Button
        size={Button.sizes.S}
        variation={Button.variations.SUCCESS}
        onClick={() => dispatchUpdateTaskRouterStatusRequested(taskRouterActivities.UNAVAILABLE)}
        disabled={callCenterBusy || callCenterPaused}
      >
        {t.callCenterOn}
      </Button>
    );
  }

  return (
    <Button
      size={Button.sizes.S}
      variation={Button.variations.ERROR}
      onClick={() => dispatchUpdateTaskRouterStatusRequested(taskRouterActivities.AVAILABLE)}
    >
      {t.callCenterOff}
    </Button>
  );
};

CallCenterButton.propTypes = {
  taskRouter: callCenterPropShape.taskRouter,
  device: callCenterPropShape.device,
  user: userPropType.isRequired,
  dispatchSetupCallCenterRequested: PropTypes.func.isRequired,
  dispatchUpdateTaskRouterStatusRequested: PropTypes.func.isRequired,
  t: PropTypes.shape({
    callCenterOn: PropTypes.string.isRequired,
    callCenterOff: PropTypes.string.isRequired,
    callCenterDisabled: PropTypes.string.isRequired,
    callCenterTurnOff: PropTypes.string.isRequired,
    callCenterStart: PropTypes.string.isRequired,
    hintCallCenterDisabled: PropTypes.string.isRequired,
  }),
};

export default CallCenterButton;
