import { createSelector } from 'reselect';
import pick from 'lodash.pick';
import { getDefaultRentalIdFromConversation, isReservation } from 'utils/businessLogic';
import { _getConversationData } from './conversation';
import { getBookingData } from './booking';

export const makeGetRentalSelector = (conversation, isSelected, selectedBooking) => {
  const fields = ['premium_class', 'id', 'name'];
  return createSelector(
    [() => true],
    () => {
      if (isSelected && selectedBooking) return selectedBooking.rental && pick(selectedBooking.rental, fields);

      const rental = getDefaultRentalIdFromConversation(conversation);
      return rental && pick(rental, fields);
    },
  );
};

export const shouldDisplayAutomationTab = createSelector(
  [_getConversationData, getBookingData],
  (conversation, booking) =>
    !!((!booking && !!(conversation && conversation.get('reservations').size)) || isReservation(booking)),
);
