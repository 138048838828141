import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledRequestError, SmallStyledRequestError, ResponsiveModal, Footer, ActionsWrapper } from '.';
import { OverlayBackground } from 'components';
import { Modal, Button } from 'ready/components';
import LoadFilterPresetOption from './LoadFilterPresetOption';

import Select from 'ready/components/Input/Select/Select';

export const Container = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const Content = ({ filterPresets, t, onDelete, onClose, onLoad }) => {
  if (filterPresets.didInvalidate && filterPresets.errors.fetching)
    return <StyledRequestError errors={filterPresets.errors} />;

  const [selectedValue, setSelectedValue] = useState();
  const handleDelete = value => {
    onDelete(value);
    if (selectedValue && selectedValue.value === value) {
      setSelectedValue(null);
    }
  };
  const Option = props => <LoadFilterPresetOption {...props} onDelete={handleDelete} />;

  return (
    <>
      <Container data-qa="LoadFilterPreset:__container">
        {filterPresets.didInvalidate && filterPresets.errors.updating && (
          <SmallStyledRequestError errors={filterPresets.errors} />
        )}
        <Select
          closeMenuOnSelect
          placeholder={t.selectPresetPlaceholder}
          options={filterPresets.data.map(preset => ({ value: preset.id, label: preset.name }))}
          value={[selectedValue]}
          onChange={val => setSelectedValue(val)}
          styles={{ container: { width: '100%' } }}
          components={{ Option }}
          t={t}
        />
      </Container>
      <Footer>
        <ActionsWrapper>
          <Button
            size={Button.sizes.S}
            variation={Button.variations.OUTLINE}
            onClick={onClose}
            data-qa="LoadFilterPreset:Button--clear"
          >
            {t.cancel}
          </Button>
          <Button
            onClick={() => onLoad(selectedValue.value)}
            size={Button.sizes.S}
            data-qa="LoadFilterPreset:Button--load"
          >
            {t.load}
          </Button>
        </ActionsWrapper>
      </Footer>
    </>
  );
};

Content.propTypes = {
  filterPresets: PropTypes.shape({
    data: PropTypes.array,
    dataOptions: PropTypes.array,
  }).isRequired,
  t: PropTypes.shape({
    cancel: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    newPresetPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const LoadFilterPreset = ({ onClose, filterPresets, onDelete, onLoad, t }) => {
  return (
    <OverlayBackground>
      <ResponsiveModal
        data-qa="LoadFilterPreset:Modal"
        size={Modal.sizes.M}
        closeEnabled
        heading={t.loadPreset}
        onClose={onClose}
      >
        <Content filterPresets={filterPresets} onDelete={onDelete} onClose={onClose} onLoad={onLoad} t={t} />
      </ResponsiveModal>
    </OverlayBackground>
  );
};

LoadFilterPreset.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  filterPresets: PropTypes.shape({
    data: PropTypes.array,
    dataOptions: PropTypes.array,
  }).isRequired,
  t: PropTypes.shape({
    loadPreset: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    load: PropTypes.string.isRequired,
    selectPresetPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
};

export default LoadFilterPreset;
