import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { updateRentalRequested, fetchRentalRequested } from 'ducks/rental';
import { getRental } from 'selectors/rental';

import {
  CenteredLoadingIndicator,
  CenteredRequestError,
  TabContentWrapper,
  FormRow,
  TabWithForm,
} from '../../components';
import SelectProperty from '../../components/SelectProperty';

import { withMemoMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { fullRentalPropTypeWithState, fullRentalPropType } from 'common/propTypes';
import General from './components/General';
import Wifi from './components/Wifi';
import Bedrooms from './components/Bedrooms';
import Bathrooms from './components/Bathrooms';
import Parking from './components/Parking';
import Maintenance from './components/Maintenance';
import CheckoutCleaning from './components/CheckoutCleaning';
import Nearby from './components/Nearby';

const PropertyContent = ({ t, rental, dispatchUpdateRentalRequested }) => {
  if (rental.isFetching) return <CenteredLoadingIndicator />;

  if (rental.didInvalidate) return <CenteredRequestError errors={rental.errors} />;

  if (!rental.data) return false;

  return (
    <TabContentWrapper>
      <General t={t} property={rental.data} />
      <Wifi t={t} property={rental.data} />
      <Bedrooms t={t} property={rental.data} />
      <Bathrooms t={t} property={rental.data} />
      <Parking t={t} property={rental.data} />
      <Maintenance t={t} property={rental.data} />
      <CheckoutCleaning t={t} property={rental.data} onCheckoutTimeChange={dispatchUpdateRentalRequested} />
      <Nearby t={t} property={rental.data} />
    </TabContentWrapper>
  );
};
PropertyContent.propTypes = {
  dispatchUpdateRentalRequested: PropTypes.func.isRequired,
  rental: fullRentalPropTypeWithState.isRequired,
  t: PropTypes.object.isRequired,
};

const Property = ({ t, rental, rentals, dispatchUpdateRentalRequested, dispatchFetchRentalRequested }) => (
  <TabWithForm data-qa="Tab:__innerWrapper--property">
    <FormRow isEmpty={rentals.length < 2} data-qa="Tab:Property__FormRow">
      <SelectProperty
        rentals={rentals}
        rental={rental.data}
        t={t}
        onChange={option => dispatchFetchRentalRequested(option.value)}
      />
    </FormRow>
    <PropertyContent rental={rental} t={t} dispatchUpdateRentalRequested={dispatchUpdateRentalRequested} />
  </TabWithForm>
);

Property.propTypes = {
  dispatchFetchRentalRequested: PropTypes.func.isRequired,
  rentals: PropTypes.arrayOf(fullRentalPropType).isRequired,
  ...PropertyContent.propTypes,
};

const mapStateToProps = state => ({
  rental: getRental(state),
});

const mapDispatchToProps = {
  dispatchUpdateRentalRequested: updateRentalRequested,
  dispatchFetchRentalRequested: fetchRentalRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMemoMappedTranslations({
    selectProperty: 'INFO_SIDEBAR.PROPERTY.SELECT_PROPERTY',
    bedroomsTitle: 'INFO_SIDEBAR.PROPERTY.BEDROOMS.TITLE',
    bathroomsTitle: 'INFO_SIDEBAR.PROPERTY.BATHROOMS.TITLE',
    numberOfBedrooms: 'INFO_SIDEBAR.PROPERTY.BEDROOMS.NUMBER_OF_BEDROOMS',
    numberOfBathrooms: 'INFO_SIDEBAR.PROPERTY.BATHROOMS.NUMBER_OF_BATHROOMS',
    bathroom: 'INFO_SIDEBAR.PROPERTY.BATHROOMS.BATHROOM',
    checkoutCleaningTitle: 'INFO_SIDEBAR.PROPERTY.CHECKOUT_CLEANING.TITLE',
    checkoutTime: 'INFO_SIDEBAR.PROPERTY.CHECKOUT_CLEANING.CHECKOUT_TIME',
    checkoutInstructions: 'INFO_SIDEBAR.PROPERTY.CHECKOUT_CLEANING.CHECKOUT_INSTRUCTIONS',
    cleaningInstructions: 'INFO_SIDEBAR.PROPERTY.CHECKOUT_CLEANING.CLEANING_INSTRUCTIONS',
    generalTitle: 'INFO_SIDEBAR.PROPERTY.GENERAL.TITLE',
    propertyName: 'INFO_SIDEBAR.PROPERTY.GENERAL.PROPERTY_NAME',
    propertyAddress: 'INFO_SIDEBAR.PROPERTY.GENERAL.ADDRESS',
    postalCode: 'INFO_SIDEBAR.PROPERTY.GENERAL.POSTAL_CODE',
    floor: 'INFO_SIDEBAR.PROPERTY.GENERAL.FLOOR',
    size: 'INFO_SIDEBAR.PROPERTY.GENERAL.SIZE',
    maxGuestsNumber: 'INFO_SIDEBAR.PROPERTY.GENERAL.MAX_GUESTS_NUMBER',
    closestStation: 'INFO_SIDEBAR.PROPERTY.GENERAL.CLOSEST_STATION',
    welcomePickupsUrl: 'INFO_SIDEBAR.PROPERTY.GENERAL.WELCOME_PICKUPS_URL',
    pdfDownloadButton: 'INFO_SIDEBAR.PROPERTY.GENERAL.PDF_DOWNLOAD_BUTTON',
    pdfDownloadLabel: 'INFO_SIDEBAR.PROPERTY.GENERAL.PDF_DOWNLOAD_LABEL',
    pdfDownloadLabelMidTerm: 'INFO_SIDEBAR.PROPERTY.GENERAL.PDF_DOWNLOAD_LABEL_MIDTERM',
    directions: 'INFO_SIDEBAR.PROPERTY.GENERAL.DIRECTIONS',
    accessCode1: 'INFO_SIDEBAR.PROPERTY.GENERAL.ACCESS_CODE_1',
    accessCode2: 'INFO_SIDEBAR.PROPERTY.GENERAL.ACCESS_CODE_2',
    maintenanceTitle: 'INFO_SIDEBAR.PROPERTY.MAINTENANCE.TITLE',
    breakerLocation: 'INFO_SIDEBAR.PROPERTY.MAINTENANCE.BREAKER_LOCATION',
    waterLocation: 'INFO_SIDEBAR.PROPERTY.MAINTENANCE.WATER_LOCATION',
    trashLocation: 'INFO_SIDEBAR.PROPERTY.MAINTENANCE.TRASH_LOCATION',
    nearbyTitle: 'INFO_SIDEBAR.PROPERTY.NEARBY.TITLE',
    poi: 'INFO_SIDEBAR.PROPERTY.NEARBY.POI',
    parkingTitle: 'INFO_SIDEBAR.PROPERTY.PARKING.TITLE',
    privateParking: 'INFO_SIDEBAR.PROPERTY.PARKING.PRIVATE',
    parkingInstructions: 'INFO_SIDEBAR.PROPERTY.PARKING.INSTRUCTIONS',
    selfCheckInLabel: 'PROPERTY.SELF_CHECK_IN.LABEL',
    selfCheckInTooltip: 'PROPERTY.SELF_CHECK_IN.TOOLTIP',
    wifiTitle: 'INFO_SIDEBAR.PROPERTY.WIFI.TITLE',
    wifiName: 'INFO_SIDEBAR.PROPERTY.WIFI.NAME',
    wifiPassword: 'INFO_SIDEBAR.PROPERTY.WIFI.PASSWORD',
    wifiInfo: 'INFO_SIDEBAR.PROPERTY.WIFI.INFO',
    yes: 'TEXT.SHARED.YES',
    no: 'TEXT.SHARED.NO',
    tbc: 'BOOKING.TBC',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
  }),
)(Property);
