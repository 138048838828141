import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { ContentWrapper } from '../../../../components';
import BookingContent from './components/BookingContent';
import Collapsible from 'ready/components/Collapsible/Collapsible';
import { bookingPropTypeWithState, guestPropType } from 'common/propTypes';

const Booking = ({
  guest,
  booking,
  reservations,
  inquiries,
  onBookingSelectChange,
  onGuestCheckInChange,
  onGuestCheckOutChange,
  dispatchOpenReservationAlterationCreateForm,
  dispatchOpenReservationAlterationEditForm,
  t,
  intl,
}) => (
  <Collapsible title={t.bookingTitle} autoResize={!booking.isFetching} data-qa="Tab:Collapsible--booking">
    <ContentWrapper>
      <BookingContent
        booking={booking.data}
        reservations={reservations}
        inquiries={inquiries}
        guest={guest}
        t={t}
        onBookingSelectChanged={onBookingSelectChange}
        onGuestCheckInChange={onGuestCheckInChange}
        onGuestCheckOutChange={onGuestCheckOutChange}
        dispatchOpenReservationAlterationCreateForm={dispatchOpenReservationAlterationCreateForm}
        dispatchOpenReservationAlterationEditForm={dispatchOpenReservationAlterationEditForm}
        errors={booking.errors}
        isUpdating={booking.isUpdating}
        intl={intl}
      />
    </ContentWrapper>
  </Collapsible>
);

Booking.propTypes = {
  guest: guestPropType.isRequired,
  onBookingSelectChange: PropTypes.func.isRequired,
  onGuestCheckInChange: PropTypes.func.isRequired,
  onGuestCheckOutChange: PropTypes.func.isRequired,
  reservations: BookingContent.propTypes.reservations,
  inquiries: BookingContent.propTypes.inquiries,
  booking: bookingPropTypeWithState.isRequired,
  dispatchOpenReservationAlterationCreateForm: PropTypes.func.isRequired,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
  t: BookingContent.propTypes.t,
  intl: intlShape,
};

export default Booking;
