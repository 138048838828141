import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tag, Tooltip, Text } from 'ready/components';
import { upperCaseFirstLetter } from 'ready/utils';
import { premiumClasses } from 'constants.js';

const { GOLD, BRONZE, SILVER, MID_TERM, CLASSIC, DIAMOND } = premiumClasses;
const StyledTag = styled(Tag)`
  display: inline-flex;
  min-width: auto;
`;

const premiumClassTheme = {
  [GOLD]: {
    color: Tag.variations.DARK,
    backgroundColor: '#ffc400',
  },
  [BRONZE]: {
    color: Tag.variations.WHITE,
    backgroundColor: '#b38b05',
  },
  [SILVER]: {
    color: Tag.variations.DARK,
    backgroundColor: '#c0c0c0',
  },
  [MID_TERM]: {
    color: Tag.variations.DARK,
    backgroundColor: '#bbc8fc',
  },
  [DIAMOND]: {
    color: Tag.variations.DARK,
    backgroundColor: '#B9FAF4',
  },
  fallback: {
    color: Tag.variations.DARK,
    backgroundColor: '#eef0f4',
  },
};

const Wrapper = styled.div`
  position: relative;
`;

const PremiumClassTag = ({ short, premiumClass }) => {
  if (!premiumClass || premiumClass === CLASSIC) return false;

  const [visibility, setVisibility] = useState(false);
  const text = upperCaseFirstLetter(premiumClass.toLowerCase());
  return (
    <Wrapper onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
      {visibility && short && (
        <Tooltip variation={Tooltip.variations.WHITE} data-qa="PremiumClassTag:Tooltip">
          <Text size={Text.sizes.S} variation={Text.variations.DARK} data-qa="PremiumClassTag:Text">
            {text}
          </Text>
        </Tooltip>
      )}
      <StyledTag
        variation={premiumClass}
        widthBounds
        size={Tag.sizes.XS}
        _theme={premiumClassTheme}
        data-qa="PremiumClassTag:Tag"
      >
        {(short && premiumClass[0]) || text}
      </StyledTag>
    </Wrapper>
  );
};

PremiumClassTag.propTypes = {
  premiumClass: PropTypes.string,
  short: PropTypes.bool,
};

export default PremiumClassTag;
