import React from 'react';
import PropTypes from 'prop-types';

import ContentWithLabel from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible } from 'ready/components';

import { fullRentalPropType } from 'common/propTypes';
import BathroomsComponent from 'scenes/shared/InfoSidebar/components/Bathrooms';

const Bathrooms = ({ property, t }) => (
  <Collapsible title={t.bathroomsTitle} data-qa="Tab:Collapsible--bathrooms" collapsed>
    <ContentWrapper minHeight="19.2rem">
      <BathroomsContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

Bathrooms.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    bathroomsTitle: PropTypes.string.isRequired,
    numberOfBathrooms: PropTypes.string.isRequired,
    bathroom: PropTypes.string.isRequired,
  }).isRequired,
};

const BathroomsContent = ({ property, t }) => {
  return (
    <>
      <ContentWithLabel label={t.numberOfBathrooms}>{property.bathroom_set.length}</ContentWithLabel>
      <BathroomsComponent t={t} bathrooms={property.bathroom_set} />
    </>
  );
};

BathroomsContent.propTypes = Bathrooms.propTypes;

export default Bathrooms;
