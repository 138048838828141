import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TextOrLink } from 'components';
import ContentWithLabel from 'components/ContentWithLabel/ContentWithLabel';
import { Text, Collapsible, Tooltip, Tag } from 'ready/components';
import GuestVerifiedNotification from './components/GuestVerifiedNotification';
import GuestProfileNotification from './components/GuestProfileNotification';
import { CenteredLoadingIndicator } from '../../../../components';
import GuestReviews from '../Booking/components/GuestReviews';

import { guestPropType, bookingPropType, guestPropTypeWithState } from 'common/propTypes';
import { getUserFullName, getUserContactList } from 'utils/user';
import { guestMoods } from 'constants.js';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${props => props.theme.spacing.l};
`;

const MoodsWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  min-width: auto;
`;

const Subheading = styled(Text)`
  width: 100%;
  padding-bottom: ${props => props.theme.spacing.m};
`;

const StyledLoadingIndicator = styled(CenteredLoadingIndicator)`
  padding-bottom: ${props => props.theme.spacing.l};
`;

const NumberOfGuests = ({ number_of_guests, max_number_of_guests, t }) => {
  if (max_number_of_guests !== null) {
    return (
      <ContentWithLabel label={t.count}>
        {`${number_of_guests} (${t.maxGuests} ${max_number_of_guests})`}
      </ContentWithLabel>
    );
  } else {
    return <ContentWithLabel label={t.count}>{number_of_guests}</ContentWithLabel>;
  }
};

const { GOOD, BAD } = guestMoods;
const guestMoodTheme = {
  [GOOD]: {
    color: Tag.variations.SUCCESS,
    backgroundColor: '#E1FCEF',
  },
  [BAD]: {
    color: Tag.variations.ERROR,
    backgroundColor: '#FBEAEB',
  },
};

const GuestContent = ({ guest, booking, t }) => {
  const { isFetching, data } = guest;
  const [visibility, setVisibility] = useState(false);

  if (isFetching) return <StyledLoadingIndicator />;

  const guestName = getUserFullName(data);
  const nameLink = guestName && (
    <TextOrLink href={data.profile_url} target="_blank">
      {guestName}
    </TextOrLink>
  );

  return (
    <MainWrapper>
      <MoodsWrapper onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
        {visibility && (
          <Tooltip variation={Tooltip.variations.WHITE} data-qa="GuestMood:Tooltip">
            <Text size={Text.sizes.S} variation={Text.variations.DARK} data-qa="GuestMood:Text">
              {booking.is_bad_guest_mood ? t.moodBadTooltip : t.moodGoodTooltip}
            </Text>
          </Tooltip>
        )}
        <StyledTag
          variation={booking.is_bad_guest_mood ? BAD : GOOD}
          widthBounds
          size={Tag.sizes.XS}
          _theme={guestMoodTheme}
          data-qa="GuestMood:Tag"
        >
          {booking.is_bad_guest_mood ? t.moodBad : t.moodGood}
        </StyledTag>
      </MoodsWrapper>
      {moment.utc(moment.utc()).isAfter(booking.checkout_date) && (
        <GuestReviews reviews={booking.guest_reviews} url={booking.base_url} uuid={booking.short_uuid} t={t} />
      )}
      <Wrapper>
        <NumberOfGuests
          number_of_guests={booking.number_of_guests}
          max_number_of_guests={booking.rental.max_number_of_guests}
          t={t}
        />
        <ContentWithLabel label={t.guestName}>{nameLink}</ContentWithLabel>
        <ContentWithLabel label={t.contacts} ifBlank={ContentWithLabel.ifBlank.HIDE}>
          {getUserContactList(data).map((field, i) => (
            <Text key={i}>{field}</Text>
          ))}
        </ContentWithLabel>
        <Subheading bold size={Text.sizes.L}>
          {t.guestAirbnbAccount}
        </Subheading>
        <ContentWithLabel label={t.reviewCount}>{data.reviews && data.reviews.length}</ContentWithLabel>
        <ContentWithLabel label={t.location}>{data.location}</ContentWithLabel>
        {data.platform_id && <GuestProfileNotification platform_id={data.platform_id} t={t} />}
        <GuestVerifiedNotification verified={data.verified} t={t} />
      </Wrapper>
    </MainWrapper>
  );
};

GuestContent.propTypes = {
  guest: guestPropTypeWithState,
  booking: bookingPropType,
  t: PropTypes.object,
};

const Guest = ({ guest, booking, t }) => {
  const hasGuestData = !guest.isFetching && Object.keys(guest.data).filter(key => key !== 'language').length;
  if (!booking && !hasGuestData) return false;

  return (
    <Collapsible title={t.guestTitle} data-qa="Tab:Collapsible--guest">
      <GuestContent guest={guest} booking={booking} t={t} />
    </Collapsible>
  );
};

Guest.propTypes = {
  guest: guestPropType,
  booking: bookingPropType,
  t: PropTypes.shape({
    guestTitle: PropTypes.string,
    guestName: PropTypes.string,
    count: PropTypes.string,
    contacts: PropTypes.string,
    verified: PropTypes.string,
    notVerified: PropTypes.string,
    airbnbProfile: PropTypes.string,
    guestAirbnbAccount: PropTypes.string,
    reviewCount: PropTypes.string,
    moodGoodTooltip: PropTypes.string,
    moodBadTooltip: PropTypes.string,
    moodGood: PropTypes.string,
    moodBad: PropTypes.string,
  }),
};

export default Guest;
