import { all, takeLatest, put, call } from 'redux-saga/effects';
import DataApi from 'api/DataApi';
import { bookingSelectTypes } from 'constants.js';

import {
  fetchReservationRequested,
  FETCH_RESERVATION_REQUESTED,
  fetchInquiryRequested,
  fetchBookingSuccessed,
  fetchBookingFailed,
  FETCH_INQUIRY_REQUESTED,
  FETCH_INIT_BOOKING_REQUESTED,
  FETCH_BOOKING_REQUESTED,
  UPDATE_INQUIRY_REQUESTED,
  updateBookingSuccessed,
  updateBookingFailed,
  UPDATE_RESERVATION_REQUESTED,
} from 'ducks/booking';
import { getDefaultReservationFromConversation, getDefaultInquiryFromConversation } from 'utils/businessLogic';

export function* handleFetchInitBooking({ payload: { conversation } }) {
  const reservation = yield call(getDefaultReservationFromConversation, conversation);
  if (reservation) {
    return yield put(fetchReservationRequested(reservation.id));
  }
  const inquiry = yield call(getDefaultInquiryFromConversation, conversation);
  if (inquiry) {
    return yield put(fetchInquiryRequested(inquiry.id));
  }
  return yield put(fetchBookingSuccessed(null));
}

export function* handleFetchBooking({ payload: { type, id } }) {
  if (!(type && id)) return;
  switch (type) {
    case bookingSelectTypes.INQUIRY:
      return yield put(fetchInquiryRequested(id));
    case bookingSelectTypes.RESERVATION:
      return yield put(fetchReservationRequested(id));
    default:
      return;
  }
}

// TODO: transform this into a called function not an action
export function* handleFetchReservationRequested({ payload: { id } }) {
  try {
    if (!id) return yield put(fetchBookingSuccessed(null));

    const reservation = yield call([DataApi.apiInstance(), 'getReservation'], id);
    yield put(fetchBookingSuccessed(reservation));
  } catch (e) {
    yield put(fetchBookingFailed(e.errors));
  }
}

// TODO: transform this into a called function not an action
export function* handleFetchInquiryRequested({ payload: { id } }) {
  try {
    if (!id) return yield put(fetchBookingSuccessed(null));

    const inquiry = yield call([DataApi.apiInstance(), 'getInquiry'], id);
    yield put(fetchBookingSuccessed(inquiry));
  } catch (e) {
    yield put(fetchBookingFailed(e.errors));
  }
}

export function* updateBooking(apiName, id, payload) {
  try {
    const booking = yield call([DataApi.apiInstance(), apiName], id, payload);
    yield put(updateBookingSuccessed(booking));
  } catch (e) {
    yield put(updateBookingFailed(e.errors));
  }
}

export function* handleUpdateInquiryRequested({ payload: { id, payload } }) {
  yield call(updateBooking, 'updateInquiry', id, payload);
}

export function* handleUpdateReservationRequested({ payload: { id, payload } }) {
  yield call(updateBooking, 'updateReservation', id, payload);
}

export default function* watchInquiry() {
  yield all([
    takeLatest(UPDATE_INQUIRY_REQUESTED, handleUpdateInquiryRequested),
    takeLatest(UPDATE_RESERVATION_REQUESTED, handleUpdateReservationRequested),
    takeLatest(FETCH_INIT_BOOKING_REQUESTED, handleFetchInitBooking),
    takeLatest(FETCH_BOOKING_REQUESTED, handleFetchBooking),
    takeLatest(FETCH_RESERVATION_REQUESTED, handleFetchReservationRequested),
    takeLatest(FETCH_INQUIRY_REQUESTED, handleFetchInquiryRequested),
  ]);
}
