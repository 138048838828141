import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getBookingIntegrationPlatform } from 'selectors/booking';
import { messageChannels, integrationPlatforms } from 'constants.js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MessageInputArea from './MessageInputArea';
import { appendAttachments } from 'ducks/message';
import { Button, Text, Icon } from 'ready/components';
import { AttachmentPreview } from 'components';
import { Wrapper } from 'scenes/shared/Filters/components';
import withTranslations from 'hoc/withTranslations/withTranslations';
import { FormattedMessage } from 'react-intl';

const MAX_FILE_SIZE = 3;

const getColorExtended = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  return '#eeeeee';
};

const AttachmentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-color: ${props => getColorExtended(props)};
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  position: relative;
`;

const ActiveDropzoneOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const ActiveDropzoneOverlayBackdrop = styled(ActiveDropzoneOverlay)`
  background-color: ${props => props.theme.colors.n400};
  opacity: 0.2;
`;
const ActiveDropzoneOverlayContent = styled(ActiveDropzoneOverlay)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacing.s};
`;

const StyledText = styled(Text)`
  margin-left: ${props => props.theme.spacing.xxs};
`;

const AttachmentPreviewsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.s};
  gap: ${props => props.theme.spacing.s};
  align-items: center;
`;

const AttachmentButton = styled(Button)`
  margin: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.m}`};
`;

const DropzoneMessageInputArea = ({ dispatchAppendAttachments, location, integrationPlatform, t }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [allowVideoFileTypes, setAllowVideoFileTypes] = useState(true);
  const [tooManyFiles, setTooManyFiles] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    dispatchAppendAttachments(attachments);
    setTooManyFiles(attachments.length > 1);
  }, [attachments]);

  const handleIsDisabled = (integration, channel) => {
    const channelManagerCompatiblePlatforms = [
      messageChannels.AIRBNB.value,
      messageChannels.AIRBNB_QA.value,
      messageChannels.BOOKING.value,
      messageChannels['BOOKING-QA'].value,
      messageChannels.VRBO.value,
      messageChannels.VRBO_QA.value,
    ];

    const compatiblePlatforms = [messageChannels.WHATSAPP.value];

    if (
      (channelManagerCompatiblePlatforms.includes(channel) && integration === integrationPlatforms.CHANNEL_MANAGER) ||
      compatiblePlatforms.includes(channel)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      removeAllAttachments();
    }
    setAllowVideoFileTypes(!channelManagerCompatiblePlatforms.includes(channel));
  };

  const removeAttachment = fileIndex => {
    setAttachments(prevAttachments => {
      const newAttachments = [...prevAttachments];
      newAttachments.splice(fileIndex, 1);
      return newAttachments;
    });
  };

  const removeAllAttachments = () => {
    setAttachments([]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open, isDragActive } = useDropzone({
    onDrop: acceptedAttachments => {
      acceptedAttachments.forEach(attachment => {
        if (attachment.type.startsWith('image/')) {
          Object.assign(attachment, {
            preview: URL.createObjectURL(attachment),
          });
        }
      });
      setAttachments(prevAttachments => [...prevAttachments, ...acceptedAttachments]);
    },
    accept: allowVideoFileTypes
      ? {
          'image/*': [],
          'video/*': [],
          'application/pdf': ['.pdf'],
        }
      : {
          'image/*': [],
          'application/pdf': ['.pdf'],
        },
    noClick: true,
    noKeyboard: true,
    disabled: isDisabled,
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => attachments.forEach(attachment => URL.revokeObjectURL(attachment.preview));
  }, []);

  const handleAttachmentPreviewClose = (attachment, index) => {
    removeAttachment(index);
    URL.revokeObjectURL(attachment.preview);
  };

  return (
    <AttachmentWrapper {...getRootProps({ isFocused, isDragAccept, isDragReject, isDragActive })}>
      <input {...getInputProps()} />
      <MessageInputArea
        location={location}
        open={open}
        isDisabled={isDisabled}
        integrationPlatform={integrationPlatform}
        handleIsDisabled={handleIsDisabled}
        removeAllAttachments={removeAllAttachments}
        tooManyFiles={tooManyFiles}
        hasAttachments={attachments.length > 0}
      />
      {attachments.length > 0 && (
        <div>
          <Wrapper>
            <Text element="p" variation={Text.variations.WARNING} data-qa="MessageInputAreaError:Text--generic">
              {t('CONVERSATION.ATTACHMENT.TEXT_AND_ATTACHMENTS_NOT_SUPPORTED')}
            </Text>
            <Text element="p" variation={Text.variations.WARNING} data-qa="RequestError:Text--generic">
              {t('CONVERSATION.ATTACHMENT.WARNING')}
            </Text>
            {tooManyFiles && (
              <Text element="p" variation={Text.variations.ERROR} data-qa="RequestError:Text--generic">
                {t('CONVERSATION.ATTACHMENT.TOO_MANY_FILES')}
              </Text>
            )}
          </Wrapper>
          <AttachmentPreviewsWrapper>
            {attachments.map((attachment, index) => (
              <AttachmentPreview
                closable
                onClose={() => handleAttachmentPreviewClose(attachment, index)}
                src={attachment.preview}
                name={attachment.name}
                type={attachment.type}
              />
            ))}
          </AttachmentPreviewsWrapper>
          <AttachmentButton onClick={removeAllAttachments} inline size={Button.sizes.S}>
            {t('CONVERSATION.ATTACHMENT.REMOVE_ALL')}
          </AttachmentButton>
        </div>
      )}
      {isDragActive && (
        <>
          <ActiveDropzoneOverlayBackdrop />
          <ActiveDropzoneOverlayContent>
            <StyledIcon size={Icon.sizes.XL} icon={Icon.icons.v2.FILE_UPLOAD} color={Icon.colors.primary} />
            <StyledText size={Text.sizes.M} bold>
              {t('CONVERSATION.ATTACHMENT.DROP')}
            </StyledText>
            <StyledText size={Text.sizes.M}>
              <FormattedMessage
                id={
                  allowVideoFileTypes
                    ? 'CONVERSATION.ATTACHMENT.SUPPORTED_FILE_TYPES_ALL'
                    : 'CONVERSATION.ATTACHMENT.SUPPORTED_FILE_TYPES_NO_VIDEO'
                }
                values={{ size: MAX_FILE_SIZE }}
              />
            </StyledText>
          </ActiveDropzoneOverlayContent>
        </>
      )}
    </AttachmentWrapper>
  );
};
const mapStateToProps = state => {
  return {
    integrationPlatform: getBookingIntegrationPlatform(state),
  };
};

const mapDispatchToProps = {
  dispatchAppendAttachments: appendAttachments,
};

DropzoneMessageInputArea.propTypes = {
  dispatchAppendAttachments: PropTypes.func.isRequired,
};

export default compose(
  withTranslations,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(DropzoneMessageInputArea);
