import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Router, Route } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';

import GlobalStyle from 'ready/styles/global-styles';

import store from './store';
import history from './browserHistory';
import * as theme from 'ready/styles/theme';
import * as appTheme from 'theme.js';
import Scenes from './scenes';

let locale = 'en';
let messages = null;

try {
  const navLocale = (navigator.language || navigator.browserLanguage || locale).toLowerCase().split('-')[0];
  messages = require(`translations/${navLocale}.json`);
  locale = navLocale;
} catch (e) {
  messages = require(`translations/${locale}.json`);
}

const localeData = require(`react-intl/locale-data/${locale.split('-')[0]}`);

addLocaleData([...localeData]);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
  });
}

const App = () => (
  <ThemeProvider theme={{ ...theme, app: appTheme }}>
    <>
      <GlobalStyle />
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        <Provider store={store}>
          <Router history={history}>
            <Route path="/" component={Scenes} />
          </Router>
        </Provider>
      </IntlProvider>
    </>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
