import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { InlineTimeSelect } from 'components';
import { Collapsible } from 'ready/components';
import { ContentWrapper } from '../../../components';

import { fullRentalPropType } from 'common/propTypes';

const CheckoutCleaning = ({ property, t, onCheckoutTimeChange }) => (
  <Collapsible title={t.checkoutCleaningTitle} data-qa="CheckoutCleaning:Collapsible--checkoutCleaning" collapsed>
    <ContentWrapper minHeight="19.2rem">
      <CheckoutCleaningContent property={property} t={t} onCheckoutTimeChange={onCheckoutTimeChange} />
    </ContentWrapper>
  </Collapsible>
);

CheckoutCleaning.propTypes = {
  property: fullRentalPropType.isRequired,
  onCheckoutTimeChange: PropTypes.func.isRequired,
  t: PropTypes.shape({
    checkoutCleaningTitle: PropTypes.string.isRequired,
    checkoutTime: PropTypes.string.isRequired,
    checkoutInstructions: PropTypes.string.isRequired,
    cleaningInstructions: PropTypes.string.isRequired,
    tbc: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
  }).isRequired,
};

const CheckoutCleaningContent = ({ property, t, onCheckoutTimeChange }) => (
  <>
    <FullWidthContentWithLabel label={t.checkoutTime}>
      <InlineTimeSelect
        placeholder={t.tbc}
        t={t}
        value={property.check_out_time ? { value: property.check_out_time, label: property.check_out_time } : undefined}
        range={15}
        start="11:00:00"
        end="24:00:00"
        onChange={onCheckoutTimeChange}
        closeMenuOnSelect
        blurInputOnSelect
        // waiting the api to support update
        disabled
        data-qa="CheckoutCleaning:InlineTimeSelect"
        textProps={{
          bold: true,
          variation: InlineTimeSelect.variations.PRIMARY,
        }}
      />
    </FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.checkoutInstructions}>
      {property.checkout_instructions}
    </FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.cleaningInstructions}>
      {property.cleaning_instructions}
    </FullWidthContentWithLabel>
  </>
);

CheckoutCleaningContent.propTypes = CheckoutCleaning.propTypes;

export default CheckoutCleaning;
