import React from 'react';
import PropTypes from 'prop-types';
import { userPropType, callCenterPropShape } from 'common/propTypes';

import { Button } from 'ready/components';

import {
  isCallCenterOn,
  isCallCenterEnabled,
  isTaskRouterActivityPaused,
  isTaskRouterActivityBusy,
  isTaskRouterActivityOff,
} from 'utils/businessLogic';
import { taskRouterActivities } from 'constants.js';

const CallCenterButton = ({ taskRouter, device, user, dispatchUpdateTaskRouterStatusRequested, t }) => {
  const callCenterEnabled = isCallCenterEnabled({ device, taskRouter }, user);
  const callCenterOn = isCallCenterOn(taskRouter, device);
  const callCenterPaused = isTaskRouterActivityPaused(taskRouter);
  const callCenterBusy = isTaskRouterActivityBusy(taskRouter);
  const callCenterOff = isTaskRouterActivityOff(taskRouter, device);

  if (!callCenterEnabled || callCenterOff) {
    return (
      <Button
        size={Button.sizes.S}
        variation={Button.variations.GREY}
        hint={{ enabled: true, text: t.hintCallCenterNotStarted }}
        disabled
      >
        {t.callCenterPause}
      </Button>
    );
  }

  if (callCenterOn && callCenterPaused) {
    return (
      <Button
        size={Button.sizes.S}
        variation={Button.variations.WARNING}
        hint={{ enabled: true, text: t.hintCallCenterPaused }}
        onClick={() => dispatchUpdateTaskRouterStatusRequested(taskRouterActivities.AVAILABLE)}
      >
        {t.callCenterUnpause}
      </Button>
    );
  }

  if (callCenterBusy && taskRouter.onPause) {
    return (
      <Button size={Button.sizes.S} variation={Button.variations.WARNING} disabled>
        {t.callCenterUnpause}
      </Button>
    );
  }

  return (
    <Button
      size={Button.sizes.S}
      variation={Button.variations.OUTLINE}
      hint={{ enabled: true, text: t.hintPauseCallCenter }}
      onClick={() => dispatchUpdateTaskRouterStatusRequested(taskRouterActivities.PAUSED)}
      disabled={callCenterBusy}
    >
      {t.callCenterPause}
    </Button>
  );
};

CallCenterButton.propTypes = {
  taskRouter: callCenterPropShape.taskRouter,
  device: callCenterPropShape.device,
  user: userPropType.isRequired,
  dispatchUpdateTaskRouterStatusRequested: PropTypes.func.isRequired,
  t: PropTypes.shape({
    callCenterPause: PropTypes.string.isRequired,
    callCenterUnpause: PropTypes.string.isRequired,
    hintCallCenterNotStarted: PropTypes.string.isRequired,
    hintCallCenterPaused: PropTypes.string.isRequired,
    hintPauseCallCenter: PropTypes.string.isRequired,
  }),
};

export default CallCenterButton;
