import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { bookingPropTypeWithState } from 'common/propTypes';
import { reservationAlterationInitiatedBy, reservationAlterationStatuses } from 'constants.js';

import { FormattedMessage } from 'react-intl';
import { Text, Button } from 'ready/components';
import AlterationDetailsTable from './components/AlterationDetailsTable';
import AlterationStatusDisplay from './components/AlterationStatusDisplay';

const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 ${props => props.theme.spacing.s} ${props => props.theme.spacing.s} ${props => props.theme.spacing.s};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.s};
  margin: ${props => props.theme.spacing.m} 0;
`;

const EditReservationAlteration = ({
  booking,
  reservationAlteration,
  dispatchChangeShowAcceptAlterationModal,
  dispatchChangeShowRejectAlterationModal,
  t,
}) => {
  const [reservationAlterationDefaultReservation, setReservationAlterationDefaultReservation] = useState(null);
  const [showButtons, setShowButtons] = useState(true);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (reservationAlteration.data && booking.data && reservationAlteration.data.reservation === booking.data.id) {
      setReservationAlterationDefaultReservation(booking);
    }
  }, [booking, reservationAlteration]);

  useEffect(() => {
    const canShowError =
      !reservationAlteration.isUpdating &&
      reservationAlteration.errors.context &&
      reservationAlteration.errors.updating;
    setShowError(canShowError);
  }, [reservationAlteration]);

  useEffect(() => {
    const canShowButtons =
      reservationAlterationDefaultReservation &&
      reservationAlteration.data &&
      reservationAlteration.data.initiated_by === reservationAlterationInitiatedBy.GUEST &&
      reservationAlteration.data.status === reservationAlterationStatuses.PENDING &&
      moment
        .utc(
          reservationAlterationDefaultReservation.data.guest_checkout_at
            ? `${reservationAlterationDefaultReservation.data.checkout_date} ${
                reservationAlterationDefaultReservation.data.guest_checkout_at
              }`
            : reservationAlterationDefaultReservation.data.checkout_date,
        )
        .isAfter(moment.utc());
    setShowButtons(canShowButtons);
  }, [reservationAlterationDefaultReservation, reservationAlteration]);

  return (
    reservationAlterationDefaultReservation && (
      <>
        <DetailsWrapper>
          <Text size={Text.sizes.XL}>
            {reservationAlteration.data.initiated_by === reservationAlterationInitiatedBy.HOST ? (
              <FormattedMessage
                id="BOOKING.ALTERATION.PROPOSED_BY_PM"
                values={{ property_manager: booking.data.rental.office.property_manager.name }}
              />
            ) : (
              t.requestedByGuest
            )}
          </Text>
          <Text>
            {t.reservation} {reservationAlterationDefaultReservation && reservationAlterationDefaultReservation.data.id}
          </Text>
          <AlterationStatusDisplay status={reservationAlteration.data.status.toUpperCase()} t={t} />
        </DetailsWrapper>

        <AlterationDetailsTable
          reservation={reservationAlterationDefaultReservation.data}
          reservationAlteration={reservationAlteration.data}
          t={t}
        />
        {showError && (
          <Text variation={Text.variations.ERROR}>
            {reservationAlteration.errors.context.error ? reservationAlteration.errors.context.error : t.updateError}
          </Text>
        )}
        {showButtons && (
          <ButtonsWrapper>
            <Button
              variation={Button.variations.ERROR}
              size={Button.sizes.M}
              inline
              disabled={reservationAlteration.isUpdating}
              onClick={() => dispatchChangeShowRejectAlterationModal(true)}
            >
              {t.reject}
            </Button>
            <Button
              variation={Button.variations.PRIMARY}
              size={Button.sizes.M}
              inline
              disabled={reservationAlteration.isUpdating}
              onClick={() => dispatchChangeShowAcceptAlterationModal(true)}
            >
              {t.approve}
            </Button>
          </ButtonsWrapper>
        )}
      </>
    )
  );
};

EditReservationAlteration.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  reservationAlteration: PropTypes.object,
  dispatchChangeShowAcceptAlterationModal: PropTypes.func.isRequired,
  dispatchChangeShowRejectAlterationModal: PropTypes.func.isRequired,
  t: PropTypes.shape({
    reservation: PropTypes.string.isRequired,
    reject: PropTypes.string.isRequired,
    approve: PropTypes.string.isRequired,
    requestedByGuest: PropTypes.string.isRequired,
    updateError: PropTypes.string.isRequired,
    accepted: PropTypes.string.isRequired,
    rejected: PropTypes.string.isRequired,
    pending: PropTypes.string.isRequired,
  }),
};

export default EditReservationAlteration;
