import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import SearchBar from 'ready/components/SearchBar/SearchBar';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { changeConversationsSearchTerm } from 'ducks/conversations';
import { getConversationsSearchTerm } from 'selectors/conversations';

const ConversationsSearchBar = ({ t, dispatchChangeConversationsSearchTerm, searchTerm }) => (
  <SearchBar
    value={searchTerm}
    onChange={e => {
      dispatchChangeConversationsSearchTerm(e.target.value);
    }}
    placeholder={t.searchPlaceholder}
    clean
    data-qa="ConversationsSearchBar:SearchBar"
  />
);

ConversationsSearchBar.propTypes = {
  t: PropTypes.shape({
    searchPlaceholder: PropTypes.string.isRequired,
  }),
  dispatchChangeConversationsSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};

const mapDispatchToProps = {
  dispatchChangeConversationsSearchTerm: changeConversationsSearchTerm,
};

const mapStateToProps = state => ({
  searchTerm: getConversationsSearchTerm(state),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    searchPlaceholder: 'CONVERSATIONS.SEARCH.PLACEHOLDER',
  }),
)(ConversationsSearchBar);
