import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 20px;
  height: auto;
  margin-top: 2.5px;
  fill: ${props => props.theme.colors[props.color] || 'transparent'};
  stroke: ${props => props.theme.colors[props.color] || 'black'};
`;

export function formatClassName(str) {
  return str.replace(/-/g, '_');
}

const FlagIcon = props => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="230 295 340 200"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    {...props}
  >
    <defs>
      <clipPath id="d09aae8435">
        <path
          d="M 237.867188 296.988281 L 560 296.988281 L 560 489.542969 L 237.867188 489.542969 Z M 237.867188 296.988281"
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="166716a8d2">
        <path
          d="M 452.542969 296.988281 L 256.617188 296.988281 C 251.644531 296.988281 246.875 298.960938 243.359375 302.476562 C 239.839844 305.996094 237.867188 310.761719 237.867188 315.738281 L 237.867188 470.792969 C 237.867188 475.769531 239.839844 480.535156 243.359375 484.054688 C 246.875 487.570312 251.644531 489.542969 256.617188 489.542969 L 452.542969 489.542969 C 464.546875 489.542969 476.058594 484.777344 484.550781 476.285156 L 554.3125 406.523438 C 557.828125 403.007812 559.804688 398.238281 559.804688 393.265625 C 559.804688 388.292969 557.828125 383.523438 554.3125 380.007812 L 484.550781 310.246094 C 476.058594 301.753906 464.546875 296.988281 452.542969 296.988281 Z M 452.542969 296.988281"
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="a5d43ad5b3">
        <path
          d="M 237.867188 296.988281 L 560 296.988281 L 560 489.539062 L 237.867188 489.539062 Z M 237.867188 296.988281"
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="23dcefd045">
        <path
          d="M 452.535156 296.988281 L 256.617188 296.988281 C 251.644531 296.988281 246.875 298.960938 243.359375 302.476562 C 239.84375 305.996094 237.867188 310.761719 237.867188 315.734375 L 237.867188 470.789062 C 237.867188 475.761719 239.84375 480.53125 243.359375 484.046875 C 246.875 487.5625 251.644531 489.539062 256.617188 489.539062 L 452.535156 489.539062 C 464.539062 489.539062 476.054688 484.769531 484.542969 476.28125 L 554.300781 406.519531 C 557.816406 403.003906 559.792969 398.234375 559.792969 393.261719 C 559.792969 388.289062 557.816406 383.519531 554.300781 380.003906 L 484.542969 310.246094 C 476.054688 301.753906 464.539062 296.988281 452.535156 296.988281 Z M 452.535156 296.988281"
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#d09aae8435)">
      <g clipPath="url(#166716a8d2)">
        <path
          d="M 237.867188 296.988281 L 567.320312 296.988281 L 567.320312 489.542969 L 237.867188 489.542969 Z M 237.867188 296.988281"
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </g>
    <g clipPath="url(#a5d43ad5b3)">
      <g clipPath="url(#23dcefd045)">
        <path
          strokeLinecap="butt"
          transform="matrix(0.75, 0, 0, 0.75, 237.868218, 296.98644)"
          strokeLinejoin="miter"
          d="M 286.222599 0.00245465 L 24.998627 0.00245465 C 18.368419 0.00245465 12.009043 2.632663 7.321543 7.320163 C 2.634043 12.012872 -0.00137391 18.367039 -0.00137391 24.997248 L -0.00137391 231.736841 C -0.00137391 238.36705 2.634043 244.726425 7.321543 249.413926 C 12.009043 254.101426 18.368419 256.736843 24.998627 256.736843 L 286.222599 256.736843 C 302.227808 256.736843 317.581975 250.377467 328.899684 239.059758 L 421.910106 146.044129 C 426.597606 141.356628 429.233023 134.997253 429.233023 128.367044 C 429.233023 121.736836 426.597606 115.37746 421.910106 110.68996 L 328.899684 17.679539 C 317.581975 6.356622 302.227808 0.00245465 286.222599 0.00245465 Z M 286.222599 0.00245465"
          strokeWidth="72"
          strokeOpacity="1"
          strokeMiterlimit="4"
        />
      </g>
    </g>
  </Svg>
);

export default FlagIcon;
