import { css } from 'styled-components';

export { default as typography } from './typography/typography';
export { default as helpers } from './helpers';
export { default as colors, paletteDeprecated as palette } from './colors/colors';

export const iconSize = {
  xxs: '0.4rem',
  xs: '0.8rem',
  s: '1.2rem',
  m: '1.6rem',
  l: '2.0rem',
  xl: '2.4rem',
  xxl: '3.2rem',
  xxxl: '4.0rem',
};

export const imageSize = {
  xxs: '6rem',
  xs: '8rem',
  s: '10rem',
  m: '12rem',
  l: '16rem',
  xl: '20rem',
  xxl: '24rem',
  xxxl: '28rem',
};

export const spacing = {
  none: '0',
  xxxs: '0.2rem',
  xxs: '0.4rem',
  xs: '0.8rem',
  s: '1.2rem',
  m: '1.6rem',
  l: '2.4rem',
  xl: '3.2rem',
  xxl: '4.0rem',
  xxxl: '4.8rem',
};

export const borderWidth = {
  m: '0.1rem',
  l: '0.2rem',
  xl: '0.4rem',
};

export const borderRadius = {
  m: '0.4rem',
  l: '0.7rem',
};

export const zIndex = {
  default: 0,
  absolute: 1,
  overlay: 49,
  tooltip: 50,
  sidebarNotification: 51,
  modal: 100,
  drawer: [150, 151, 152],
  topLayer: 200,
};

const transitionDuration = {
  default: '0.3s',
  fast: '0.2s',
  veryFast: '0.1s',
};
const transitionTimingFunction = {
  default: 'ease-in-out',
};
const getTransition = (property = 'all', duration = 'default', timingFunction = 'default') =>
  `${property} ${transitionDuration[duration]} ${transitionTimingFunction[timingFunction]}`;

export const transition = {
  duration: transitionDuration,
  timingFunction: transitionTimingFunction,
  default: getTransition(),
  fast: getTransition(undefined, 'fast'),
  veryFast: getTransition(undefined, 'fast'),
  custom: getTransition,
};

/*
 * DEPRECATED: to be removed
 */
export const icons = {
  scale: (original, size) => {
    if (typeof size === 'string' && size.match(/r?em$/)) {
      size = +size.replace(/[^\d.]/g, '') * 16;
    }
    return (original * +size) / 120;
  },
};

/*
 * DEPRECATED: to be removed
 */
export const buttons = {
  shadowColor: hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgba(
          ${parseInt(result[1], 16)},
          ${parseInt(result[2], 16)},
          ${parseInt(result[3], 16)},
          0.1)`
      : null;
  },
};

/*
 * DEPRECATED: to be removed
 */
export const font = {
  family: {
    default: "'Fira Sans', sans-serif",
  },
};

export const boxShadow = {
  s: '0 0.1rem 0.4rem 0 rgba(0,0,0,0.1)',
  m: '0 0.5rem 0.7rem 0 rgba(0,0,0,0.1)',
  l: '0 0.8rem 0.8rem 0 rgba(0,0,0,0.1)',
};

export const textShadow = {
  s: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.05)',
};

export const overlayColor = {
  default: 'rgba(40, 55, 85, 0.6)',
};

export const shadow = {
  /*
   * DEPRECATED: to be removed
   */
  s: 'box-shadow: 0 0.1rem 0.4rem 0 rgba(0,0,0,0.1)',
  m: 'box-shadow: 0 0.5rem 0.7rem 0 rgba(0,0,0,0.1)',
  l: 'box-shadow: 0 0.8rem 0.8rem 0 rgba(0,0,0,0.1)',
  big: '0.2rem 1.3rem 1.1rem rgba(0, 0, 0, 0.1)',
  default: '0 0.6rem 1.1rem rgba(0, 0, 0, 0.07)',
  small: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.05)',
  modal: '0 0.5rem 1.3rem rgba(0, 0, 0, 0.07)',
  focus: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.25)',
  iconButton: size => {
    return `
      0
      ${icons.scale(0.5, size)}rem
      ${icons.scale(1.3, size)}rem
      rgba(0, 0, 0, 0.07)`;
  },
  iconButtonHover: size => {
    return `
      ${icons.scale(0.2, size)}rem
      ${icons.scale(1.3, size)}rem
      ${icons.scale(1.1, size)}rem
      rgba(0, 0, 0, 0.1)`;
  },
  buttons: color => {
    const rgba = buttons.shadowColor(color);
    return `
      0.2rem
      1.3rem
      1.1rem
      ${rgba}`;
  },
};

/*
 * DEPRECATED: to be removed
 */
export const sizes = {
  desktop: 1439,
  tablet: 1024,
  mobile: 767,
  mobileSmall: 479,
};

/*
 * DEPRECATED: to be removed
 */
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
