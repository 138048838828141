import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { conversationPropType, userPropType } from 'common/propTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedRelative } from 'react-intl';
import moment from 'moment';

import { getUserData } from 'selectors/user';
import { getIsFilterSidebarOpen } from 'selectors/view';
import { logoutUserRequested } from 'ducks/user';
import { changeIsInfoSidebarOpen } from 'ducks/view';

import { SidebarToggle } from 'components';
import { Button, DropdownButton, DropdownList, DropdownListItem } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { updateConversationRequested } from 'ducks/conversation';
import { conversationSnoozePayload, conversationStatusPayload } from 'utils/deserializers';
import { conversationStatuses, snoozeDurations } from 'constants.js';
import { getConversationData } from 'selectors/conversation';

const infoSidebarClosedStyle = ({ isFilterSidebarOpen }) =>
  !isFilterSidebarOpen &&
  css`
    grid-area: top-bar-right-collapsed;
    justify-self: flex-end;
  `;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 4.8rem;
  min-width: 28rem;
  padding: ${props => `0 ${props.theme.spacing.m}`};
  ${infoSidebarClosedStyle}
  width: 100%
`;

const TwoButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  ${infoSidebarClosedStyle}
`;

const getSnoozedDurations = () => snoozeDurations.map(duration => moment().add(duration, 'minutes'));

const InfoSidebarTopBar = ({
  isFilterSidebarOpen,
  t,
  className,
  dispatchUpdateConversation,
  dispatchChangeIsInfoSidebarOpen,
}) => {
  return (
    <Wrapper className={className} isFilterSidebarOpen={isFilterSidebarOpen}>
      <TwoButtonWrapper>
        <DropdownButton
          icon={{ icon: DropdownButton.icon.icons.ARROW_DOWN, position: DropdownButton.icon.positions.RIGHT }}
          renderButtonContent={() => t.actions}
          renderDropdownContent={() => (
            <DropdownList>
              <DropdownListItem
                onClick={() => dispatchUpdateConversation(conversationStatusPayload(conversationStatuses.CLOSED))}
              >
                {t.hintCloseConversation}
              </DropdownListItem>
              <DropdownListItem
                onClick={() => dispatchUpdateConversation(conversationStatusPayload(conversationStatuses.OPENED))}
              >
                {t.hintOpenConversation}
              </DropdownListItem>
            </DropdownList>
          )}
        />
        <DropdownButton
          variation={DropdownButton.variations.OUTLINE}
          icon={{ icon: DropdownButton.icon.icons.ARROW_DOWN, position: DropdownButton.icon.positions.RIGHT }}
          renderButtonContent={() => t.snooze}
          renderDropdownContent={({ closeDropdown }) => (
            <DropdownList>
              {getSnoozedDurations().map(duration => (
                <DropdownListItem
                  data-qa="TopBarCenter:DropdownListItem--snooze"
                  key={duration}
                  onClick={() => {
                    dispatchUpdateConversation(conversationSnoozePayload(duration));
                    closeDropdown();
                  }}
                >
                  <FormattedRelative value={duration} updateIntervalInSeconds={false} />
                </DropdownListItem>
              ))}
            </DropdownList>
          )}
        />
      </TwoButtonWrapper>
      <Button
        onClick={dispatchChangeIsInfoSidebarOpen}
        bold
        icon={{ icon: SidebarToggle.icons.CROSS }}
        size={Button.sizes.L}
        clean
        inline
      />
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  user: getUserData(state),
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
  conversation: getConversationData(state),
});

const mapDispatchToProps = {
  dispatchLogoutUserRequested: logoutUserRequested,
  dispatchChangeIsInfoSidebarOpen: changeIsInfoSidebarOpen,
  dispatchUpdateConversation: updateConversationRequested,
};

InfoSidebarTopBar.propTypes = {
  user: userPropType.isRequired,
  isFilterSidebarOpen: PropTypes.bool,
  dispatchLogoutUserRequested: PropTypes.func.isRequired,
  dispatchChangeIsInfoSidebarOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
  t: PropTypes.shape({
    logout: PropTypes.string.isRequired,
    menu: PropTypes.string.isRequired,
    hintCloseConversation: PropTypes.string.isRequired,
    hintOpenConversation: PropTypes.string.isRequired,
    actions: PropTypes.string.isRequired,
    snooze: PropTypes.string.isRequired,
  }).isRequired,
  dispatchUpdateConversation: PropTypes.func.isRequired,
  conversation: conversationPropType,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    logout: 'TOP_BAR.LOGOUT',
    menu: 'TOP_BAR.MENU',
    hintCloseConversation: 'TOP_BAR.HINT.CLOSE_CONVERSATION',
    hintOpenConversation: 'TOP_BAR.HINT.OPEN_CONVERSATION',
    actions: 'ACTIONS',
    snooze: 'TOP_BAR.HINT.SNOOZE',
  }),
)(InfoSidebarTopBar);
