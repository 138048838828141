import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getConversation, hasRental, hasBooking } from 'selectors/conversation';
import { getIssuesCount } from 'selectors/rental';
import { getInfoSidebarErrors } from 'selectors/errors';
import { shouldDisplayAutomationTab } from 'selectors/decoupling';

import Overview from './containers/Overview/Overview';
import Property from './containers/Property/Property';
import Calendar from './containers/Calendar/Calendar';
import Amenities from './containers/Amenities/Amenities';
import Automation from './containers/Automation/Automation';
import Issues from './containers/Issues/Issues';
import OfficePolicies from './containers/OfficePolicies/OfficePolicies';

import { CenteredRequestError } from './components';
import { RequestError } from 'components';
import { Tabs, Tab, Notification } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { conversationPropTypeWithState } from 'common/propTypes';
import { hasErrors } from 'utils/index';
import { variations } from 'ready/utils/constants';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
`;

const ErrorWrapper = styled.div`
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.sidebarNotification};
`;

const StyledRequestError = styled(RequestError)`
  margin-left: ${props => props.theme.spacing.xs};
  text-align: start;
  width: 100%;
`;

const tabs = {
  0: 'overview',
  1: 'property',
  2: 'calendar',
  3: 'amenities',
  4: 'automation',
  5: 'issues',
  6: 'officePolicies',
};

const InfoSidebar = ({ conversation, errors, issuesCount, hasBooking, hasRental, hasAutomation, t }) => {
  const { isFetching, didInvalidate, data, errors: conversationErrors } = conversation;
  if (isFetching || (!didInvalidate && !data)) return false;

  if (didInvalidate) return <CenteredRequestError errors={conversationErrors} />;

  const [tabIndex, changeTabIndex] = useState(0);
  const [showError, shouldShowError] = useState(true);

  const tabIndexErrors = errors[tabs[tabIndex]];
  const _hasErrors = hasErrors(tabIndexErrors);

  useEffect(() => {
    changeTabIndex(0);
  }, [data.id]);

  useEffect(() => {
    !_hasErrors && shouldShowError(!_hasErrors);
  }, [_hasErrors]);

  return (
    <Wrapper data-qa="shared:InfoSidebar__wrapper">
      <Tabs
        size={Tabs.sizes.M}
        data-qa="InfoSidebar:Tabs"
        onChange={changeTabIndex}
        tabIndex={tabIndex}
        variation={Tabs.variations.OUTLINE}
        fixedComponents={
          showError &&
          _hasErrors && (
            <ErrorWrapper>
              <Notification
                variation={Notification.variations.ERROR}
                data-qa="InfoSidebar:Notification--update-error"
                onClose={() => shouldShowError(false)}
              >
                <StyledRequestError errors={tabIndexErrors} />
              </Notification>
            </ErrorWrapper>
          )
        }
      >
        <Tab
          icon={{ icon: Tabs.icons.v2.OVERVIEW }}
          hint={{ text: t.overview }}
          data-qa="Tabs:Tab--overview"
          disabled={!hasBooking}
        >
          <Overview conversation={data} />
        </Tab>
        <Tab
          icon={{ icon: Tabs.icons.v2.HOME_2 }}
          hint={{ text: t.property }}
          data-qa="Tabs:Tab--property"
          disabled={!hasRental}
        >
          <Property rentals={data.rentals} />
        </Tab>
        <Tab
          icon={{ icon: Tabs.icons.v2.CALENDAR }}
          hint={{ text: t.calendar }}
          data-qa="Tabs:Tab--calendar"
          disabled={!hasRental}
        >
          <Calendar />
        </Tab>
        <Tab
          icon={{ icon: Tabs.icons.v2.LAUNDRY }}
          hint={{ text: t.amenities }}
          data-qa="Tabs:Tab--amenities"
          disabled={!hasRental}
        >
          <Amenities rentals={data.rentals} />
        </Tab>
        <Tab
          icon={{ icon: Tabs.icons.v2.AUTOMATION }}
          hint={{ text: t.automation }}
          data-qa="Tabs:Tab--automation"
          disabled={!hasAutomation}
        >
          <Automation />
        </Tab>
        <Tab
          badge={{ show: !!issuesCount, variation: variations.ERROR, count: issuesCount }}
          icon={{ icon: Tabs.icons.v2.ISSUES }}
          hint={{ text: t.issues }}
          data-qa="Tabs:Tab--issues"
        >
          <Issues rentals={data.rentals} reservations={data.reservations} />
        </Tab>
        <Tab
          icon={{ icon: Tabs.icons.v2.OFFICE_POLICIES }}
          hint={{ text: t.officePolicies, position: Tabs.hint.positions.LEFT }}
          data-qa="Tabs:Tab--officePolicies"
        >
          <OfficePolicies rentals={data.rentals} />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

InfoSidebar.propTypes = {
  conversation: conversationPropTypeWithState.isRequired,
  errors: PropTypes.object,
  hasAutomation: PropTypes.bool,
  hasBooking: PropTypes.bool,
  hasRental: PropTypes.bool,
  issuesCount: PropTypes.number,
  t: PropTypes.shape({
    overview: PropTypes.string.isRequired,
    automation: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    calendar: PropTypes.string.isRequired,
    amenities: PropTypes.string.isRequired,
    issues: PropTypes.string.isRequired,
    officePolicies: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  conversation: getConversation(state),
  errors: getInfoSidebarErrors(state),
  hasAutomation: shouldDisplayAutomationTab(state),
  hasBooking: hasBooking(state),
  hasRental: hasRental(state),
  issuesCount: getIssuesCount(state),
});

export default compose(
  withMappedTranslations({
    overview: 'INFO_SIDEBAR.OVERVIEW',
    automation: 'INFO_SIDEBAR.AUTOMATION.TITLE',
    property: 'INFO_SIDEBAR.PROPERTY.TITLE',
    calendar: 'INFO_SIDEBAR.CALENDAR.TITLE',
    amenities: 'INFO_SIDEBAR.AMENITIES.TITLE',
    issues: 'INFO_SIDEBAR.ISSUES.TITLE',
    officePolicies: 'INFO_SIDEBAR.OFFICE_POLICIES.TITLE',
  }),
  connect(mapStateToProps),
)(InfoSidebar);
