import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSelectedCategoryId } from 'selectors/filters';

import { Text } from 'ready/components/typography';
import { getColor } from 'ready/styles/helpers';
import { variationsCssProps, variations } from 'ready/utils/constants';

import { categoryPropType } from 'common/propTypes';

const selectedStyle = ({ selected, theme }) =>
  selected &&
  css`
    font-weight: ${theme.typography.fontWeight.medium};
    border-left-color: ${getColor(variations.OUTLINE, variationsCssProps.ACTIVE)};
  `;

export const StyledCategoryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.s}`};
  cursor: pointer;
  color: ${props => props.theme.colors.dark};
  border-left: ${props => props.theme.spacing.xxs} solid transparent;
  border-right: ${props => props.theme.spacing.xxs} solid transparent;
  ${props => props.theme.typography.text.m};
  ${selectedStyle};
  &:hover {
    background-color: ${props => props.theme.colors.n200};
  }
`;

const CategoryRow = ({ className, selectedId, category, onClick }) => (
  <StyledCategoryRow className={className} selected={selectedId === category.id} onClick={onClick}>
    <Text>{category.name}</Text>
    <Text>{typeof category.count === 'number' ? category.count : '–'}</Text>
  </StyledCategoryRow>
);

CategoryRow.propTypes = {
  className: PropTypes.string,
  selectedId: PropTypes.number,
  category: categoryPropType.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedId: getSelectedCategoryId(state),
});

export default connect(mapStateToProps)(CategoryRow);
