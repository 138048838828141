import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text, Button, Icon, Tag } from 'ready/components';
import { TextOrLink } from 'components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.l};
`;

const Review = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StarsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xxs};
  align-items: center;
`;
const copyToClipboard = text => navigator.clipboard.writeText(text);

const getStarColor = rating => {
  if (rating >= 4.6) {
    return Icon.colors.g500;
  } else if (rating >= 4.4 && rating < 4.6) {
    return Icon.colors.y500;
  } else if (rating > 3.5 && rating < 4.4) {
    return Icon.colors.o500;
  } else {
    return Icon.colors.r500;
  }
};

const GuestReviews = ({ reviews, url, uuid, t }) => {
  const stars = [1, 2, 3, 4, 5];
  return (
    <Wrapper>
      {reviews && reviews.length > 0 ? (
        <>
          {reviews.map(review => (
            <>
              <Review key={review.id}>
                <StarsWrapper>
                  {stars.map(star => (
                    <Icon
                      size={Icon.sizes.S}
                      icon={Icon.icons.v2.STAR}
                      color={review.rating_accurate >= star ? getStarColor(review.rating_accurate) : Icon.colors.n400}
                    />
                  ))}
                  <Text>{review.rating_accurate}</Text>
                </StarsWrapper>
                <TextOrLink href={url + '/reservation_reviews/reply/' + review.id} target="_blank">
                  {t.seeReview}
                </TextOrLink>
              </Review>
              {review.is_under_investigation && (
                <Tag widthBounds size={Tag.sizes.XS} variation={Tag.variations.WARNING}>
                  {t.underInvestigation}
                </Tag>
              )}
            </>
          ))}
        </>
      ) : (
        <Review>
          <StarsWrapper>
            {stars.map(star => (
              <Icon size={Icon.sizes.S} icon={Icon.icons.v2.STAR} color={Icon.colors.n400} />
            ))}
            {t.noRating}
          </StarsWrapper>
          <Button
            icon={{ icon: Button.icon.icons.COPY }}
            onClick={() => copyToClipboard(url + '/reservation_reviews/reviews/' + uuid)}
            hint={{ text: t.copyLink }}
            clean
            active
            inline
          >
            {t.copy}
          </Button>
        </Review>
      )}
    </Wrapper>
  );
};

GuestReviews.propTypes = {
  t: PropTypes.shape({}),
};

export default GuestReviews;
