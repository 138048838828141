import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import styled from 'styled-components';
import pick from 'lodash.pick';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import BookingDateTime from './BookingDateTime';
import PropertyManager from './PropertyManager';
import AlterationOptionsGroup from './AlterationOptionsGroup';
import GuestReviews from './GuestReviews';
import { TextOrLink } from 'components';

import { Text, CurrencyText, Icon, Select, Button, Tag, Tooltip } from 'ready/components';
import { replaceInUrl } from 'ready/utils';
import { datePropType } from 'ready/utils/propTypes';

import { bookingPropType, guestPropType } from 'common/propTypes';
import { links, bookingSelectTypes, contexts, messageChannels, integrationPlatforms } from 'constants.js';
import { hasErrors } from 'utils';
import { getAirbnbEmail, getAirbnbPassword, getBookingCheckInDelay } from 'utils/serializers';
import CheckInDelay from './CheckInDelay';
import moment from 'moment';

const StyledContentWithLabel = styled(ContentWithLabel)`
  width: 100%;
  grid-row-gap: ${props => props.theme.spacing.xxs};
`;

const Subheading = styled(Text)`
  width: 100%;
  padding-bottom: ${props => props.theme.spacing.m};
`;

const DWBLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.s};
`;

const ListingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.s};
`;

const BookingsWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  min-width: auto;
`;

const TagGroup = styled.div`
  align-items: center;
  display: flex;
  margin: ${props => `0 -${props.theme.spacing.xxxs} 0`};
  & > * {
    margin: 0 ${props => props.theme.spacing.xxxs};
  }
`;

const formatBookingSelectOption = item => ({
  id: item.id,
  value: item.id,
  label: `${item.id} - ${item.checkin_date}`,
});

const copyToClipboard = text => navigator.clipboard.writeText(text);

const BookingContent = ({
  booking,
  reservations,
  inquiries,
  t,
  onBookingSelectChanged,
  onGuestCheckInChange,
  onGuestCheckOutChange,
  dispatchOpenReservationAlterationCreateForm,
  dispatchOpenReservationAlterationEditForm,
  intl,
  errors,
  isUpdating,
}) => {
  const formattedBookingStatus =
    booking.status && intl.formatMessage({ id: `BOOKING.STATUSES.${booking.status.toUpperCase()}` });

  const checkInTaskPath = replaceInUrl({
    url: links.CHECK_IN_TASK,
    placeholder: '{id}',
    value: booking.check_in_task_id,
  });
  const dateTimeT = pick(t, ['tbc', 'noResultsText']);
  const checkInDelay = getBookingCheckInDelay(booking);
  const [visibility, setVisibility] = useState(false);
  const reservationAlterationCompatiblePlatforms = [
    messageChannels.AIRBNB.value.toUpperCase(),
    messageChannels.AIRBNB_QA.value.toUpperCase,
  ];
  const isStayExtension = (booking || {}).is_stay_extension;
  const isWithinTopFiveReservationsExceptFirst = (booking || {}).is_within_top_five_reservations_except_first;
  const isFirstReservation = (booking || {}).is_first_reservation;
  const showTagsContainer = isStayExtension || isWithinTopFiveReservationsExceptFirst || isFirstReservation;

  return (
    <>
      {[...reservations, ...inquiries].length > 1 && (
        <StyledContentWithLabel label={t.bookingSelectLabel}>
          <Select
            value={formatBookingSelectOption(booking)}
            onChange={onBookingSelectChanged}
            options={[
              {
                label: t.inquiries,
                options: inquiries.map(inquiry => ({
                  ...formatBookingSelectOption(inquiry),
                  type: bookingSelectTypes.INQUIRY,
                })),
              },
              {
                label: t.bookings,
                options: reservations.map(reservation => ({
                  ...formatBookingSelectOption(reservation),
                  type: bookingSelectTypes.RESERVATION,
                })),
              },
            ]}
            t={t}
          />
        </StyledContentWithLabel>
      )}
      {showTagsContainer && (
        <FullWidthContentWithLabel>
          <TagGroup>
            {(isWithinTopFiveReservationsExceptFirst || isFirstReservation) && (
              <BookingsWrapper onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
                {visibility && (
                  <Tooltip
                    variation={Tooltip.variations.WHITE}
                    position={Tooltip.positions.BOTTOM}
                    data-qa="Bookings:Tooltip"
                  >
                    <Text size={Text.sizes.S} variation={Text.variations.DARK} data-qa="Bookings:Text">
                      {booking.is_within_top_five_reservations_except_first
                        ? t.first_reservations_tooltip
                        : t.first_reservation_tooltip}
                    </Text>
                  </Tooltip>
                )}
                <StyledTag
                  variation={Tag.variations.VIOLET}
                  widthBounds
                  size={Tag.sizes.XS}
                  data-qa="FirstReservations:Tag"
                >
                  {booking.is_within_top_five_reservations_except_first ? t.first_reservations : t.first_reservation}
                </StyledTag>
              </BookingsWrapper>
            )}
            {isStayExtension && (
              <StyledTag
                variation={Tag.variations.VIOLET}
                widthBounds
                size={Tag.sizes.XS}
                data-qa="StayExtensionTag:Tag"
              >
                {t.stayExtension}
              </StyledTag>
            )}
          </TagGroup>
        </FullWidthContentWithLabel>
      )}
      <ContentWithLabel
        label={
          <>
            <Icon icon={Icon.icons.CHECK_IN} />{' '}
            <TextOrLink size={Text.sizes.S} href={checkInTaskPath} target="_blank">
              {t.checkIn}
            </TextOrLink>
          </>
        }
      >
        <BookingDateTime
          t={dateTimeT}
          date={booking.checkin_date}
          time={booking.guest_checkin_at || undefined}
          start="11:00:00"
          end="24:00:00"
          onChange={onGuestCheckInChange}
          disabled={isUpdating}
          data-qa="BookingContent:BookingDateTime--checkin"
          hasError={hasErrors(errors, contexts.infoSidebar.BOOKING)}
        />
      </ContentWithLabel>
      <ContentWithLabel
        label={
          <>
            <Icon icon={Icon.icons.CHECK_OUT} /> {t.checkOut}
          </>
        }
      >
        <BookingDateTime
          date={booking.checkout_date}
          t={dateTimeT}
          time={booking.guest_checkout_at || undefined}
          start="00:00:00"
          end="11:15:00"
          onChange={onGuestCheckOutChange}
          disabled={isUpdating}
          data-qa="BookingContent:BookingDateTime--checkout"
          hasError={hasErrors(errors, contexts.infoSidebar.BOOKING)}
        />
      </ContentWithLabel>
      {checkInDelay && (
        <CheckInDelay reason={checkInDelay.reason} datetime={checkInDelay.new_arrival_at} label={t.checkInDelay} />
      )}
      {booking.rental.key_strategy && booking.rental.key_strategy !== 'OFFICE' && (
        <ContentWithLabel label={t.keyStrategy}>{booking.rental.key_strategy}</ContentWithLabel>
      )}
      <ContentWithLabel label={t.duration}>{booking.duration}</ContentWithLabel>
      <ContentWithLabel label={t.price}>
        {typeof booking.price === 'number' && (
          <CurrencyText price={booking.price} currency={booking.currency || undefined} />
        )}
      </ContentWithLabel>
      <ContentWithLabel label={t.dbwLink} ifBlank="hide">
        {booking.booking_url && (
          <DWBLinkWrapper>
            <TextOrLink href={booking.booking_url} target="_blank">
              {t.link}
            </TextOrLink>
            <Button
              size={Button.sizes.M}
              icon={{ icon: Button.icon.icons.COPY }}
              onClick={() => copyToClipboard(booking.booking_url)}
              hint={{ text: t.copyLink }}
              clean
            />
          </DWBLinkWrapper>
        )}
      </ContentWithLabel>
      <ContentWithLabel label={t.id} data-qa="BookingContent:ContentWithLabel__wrapper--id" ifBlank="hide">
        {booking.detail_url && (
          <TextOrLink href={booking.detail_url} target="_blank" data-qa="BookingContent:TextOrLink--id">
            {booking.id}
          </TextOrLink>
        )}
      </ContentWithLabel>
      <ContentWithLabel label={t.listing} data-qa="BookingContent:ContentWithLabel__wrapper--listing">
        {booking.listing_url ? (
          <ListingWrapper>
            <TextOrLink href={booking.listing_url} target="_blank" data-qa="BookingContent:TextOrLink--listing_url">
              {t.seeListing}
            </TextOrLink>
            <Button
              size={Button.sizes.M}
              icon={{ icon: Button.icon.icons.COPY }}
              onClick={() => copyToClipboard(booking.listing_url)}
              hint={{ text: t.copyLink }}
              active
              clean
            />
          </ListingWrapper>
        ) : (
          '-'
        )}
      </ContentWithLabel>
      <ContentWithLabel label={t.source}>
        {booking.platform_name && (
          <TextOrLink href={booking.listing_url} target="_blank">
            {booking.platform_name}
          </TextOrLink>
        )}
      </ContentWithLabel>
      <FullWidthContentWithLabel
        label={t.airbnbAccount}
        ifBlank="hide"
        data-qa="BookingContent:FullWidthContentWithLabel--airbnbAccount"
      >
        {getAirbnbEmail(booking)}
      </FullWidthContentWithLabel>
      <FullWidthContentWithLabel
        label={t.airbnbPassword}
        ifBlank="hide"
        data-qa="BookingContent:FullWidthContentWithLabel--airbnbPassword"
      >
        {getAirbnbPassword(booking)}
      </FullWidthContentWithLabel>
      <ContentWithLabel label={t.status} data-qa="BookingContent:ContentWithLabel__wrapper--status">
        {formattedBookingStatus}
      </ContentWithLabel>
      {moment.utc(moment.utc()).isAfter(booking.checkout_date) && (
        <GuestReviews reviews={booking.guest_reviews} url={booking.base_url} uuid={booking.short_uuid} t={t} />
      )}
      <ContentWithLabel label={t.cancellationPolicy} data-qa="BookingContent:ContentWithLabel__wrapper--status">
        {booking.cancellation_policy_category}
      </ContentWithLabel>
      {booking.guest_comment && (
        <FullWidthContentWithLabel label={t.guestComment} ifBlank="hide">
          {booking.guest_comment}
        </FullWidthContentWithLabel>
      )}
      {booking.rental && <PropertyManager office={booking.rental.office} label={t.propertyManager} />}
      {booking.check_in_reception && (
        <>
          <Subheading bold size={Text.sizes.L}>
            {t.reception}
          </Subheading>
          <ContentWithLabel label={t.receptionName}>{booking.check_in_reception.name}</ContentWithLabel>
          <ContentWithLabel label={t.receptionAddress}>{booking.check_in_reception.address}</ContentWithLabel>
          <ContentWithLabel label={t.receptionDescription}>{booking.check_in_reception.description}</ContentWithLabel>
          <ContentWithLabel label={t.receptionHours}>{booking.check_in_reception.opening_hours}</ContentWithLabel>
        </>
      )}
      {reservationAlterationCompatiblePlatforms.includes(booking.platform_name) &&
        booking.integration_platform === integrationPlatforms.CHANNEL_MANAGER &&
        moment
          .utc(
            booking.guest_checkout_at ? `${booking.checkout_date} ${booking.guest_checkout_at}` : booking.checkout_date,
          )
          .isAfter(moment.utc()) && (
          <AlterationOptionsGroup
            booking={booking}
            t={t}
            dispatchOpenReservationAlterationCreateForm={dispatchOpenReservationAlterationCreateForm}
            dispatchOpenReservationAlterationEditForm={dispatchOpenReservationAlterationEditForm}
          />
        )}
    </>
  );
};

BookingContent.propTypes = {
  intl: intlShape,
  guest: guestPropType.isRequired,
  booking: bookingPropType.isRequired,
  onBookingSelectChanged: PropTypes.func.isRequired,
  onGuestCheckInChange: PropTypes.func.isRequired,
  onGuestCheckOutChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isUpdating: PropTypes.bool,
  reservations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      checkin_date: datePropType.isRequired,
    }),
  ).isRequired,
  inquiries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      checkin_date: datePropType.isRequired,
    }),
  ).isRequired,
  dispatchOpenReservationAlterationCreateForm: PropTypes.func.isRequired,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
  t: PropTypes.shape({
    airbnbAccount: PropTypes.string.isRequired,
    airbnbPassword: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    listing: PropTypes.string.isRequired,
    seeListing: PropTypes.string.isRequired,
    cancellationPolicy: PropTypes.string.isRequired,
    checkIn: PropTypes.string.isRequired,
    checkOut: PropTypes.string.isRequired,
    checkInDelay: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    dbw_link: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    copyLink: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    tbc: PropTypes.string.isRequired,
    bookingTitle: PropTypes.string.isRequired,
    bookingSelectLabel: PropTypes.string.isRequired,
    bookings: PropTypes.string.isRequired,
    inquiries: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    propertyManager: PropTypes.string.isRequired,
    keyStrategy: PropTypes.string.isRequired,
    reception: PropTypes.string.isRequired,
    receptionName: PropTypes.string.isRequired,
    receptionAddress: PropTypes.string.isRequired,
    receptionDescription: PropTypes.string.isRequired,
    receptionHours: PropTypes.string.isRequired,
    guestComment: PropTypes.string.isRequired,
    first_reservations: PropTypes.string.isRequired,
    first_reservation: PropTypes.string.isRequired,
    first_reservations_tooltip: PropTypes.string.isRequired,
    first_reservation_tooltip: PropTypes.string.isRequired,
    manage: PropTypes.string.isRequired,
    proposeChanges: PropTypes.string.isRequired,
    guestProposed: PropTypes.string.isRequired,
    stayExtension: PropTypes.string.isRequired,
  }).isRequired,
};

export default BookingContent;
