import React from 'react';
import propTypes from 'prop-types';

import { Text } from 'ready/components';
import styled from 'styled-components';

const ChipText = styled(Text)`
  padding: 0.3em 0.6em;
  border-radius: 1em;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
`;

const Chip = ({ label }) => {
  return (
    <ChipText element="span" size={Text.sizes.XS}>
      {label}
    </ChipText>
  );
};

Chip.propTypes = {
  label: propTypes.string.isRequired,
};

export default Chip;
