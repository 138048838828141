import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash.pick';

import { sizes, variations } from 'ready/utils/constants';

import CleanButton from './components/CleanButton';
import RegularButton from './components/RegularButton';
import withIconWrapper from './hoc/withIconWrapper';
import withHintWrapper from './hoc/withHintWrapper';
import withTextWrapper from './hoc/withTextWrapper';
import { variationPropType } from 'ready/utils/propTypes';

/**
 * Button uses `<a></a>` tag because there are some styling issues on Safari if we try to use `display: inline-flex` or `display: flex`
 */
const EnhancedCleanButton = withTextWrapper(withIconWrapper(CleanButton));
const EnhancedRegularButton = withTextWrapper(withIconWrapper(RegularButton));

const Button = withHintWrapper(({ clean, variation, onClick, ...props }) => {
  const handleClick = e => {
    !props.disabled && onClick && onClick(e);
  };
  if (clean) {
    return (
      <EnhancedCleanButton
        variation={variation || variations.OUTLINE}
        data-qa="[ready]Button:CleanButton"
        onClick={handleClick}
        {...props}
      />
    );
  }
  return (
    <EnhancedRegularButton
      variation={variation || variations.PRIMARY}
      data-qa="[ready]Button:RegularButton"
      onClick={handleClick}
      {...props}
    />
  );
});

Button.sizes = pick(sizes, ['S', 'M', 'L']);
Button.variations = variations;
Button.icon = {
  positions: withIconWrapper.positions,
  icons: withIconWrapper.icons,
};
Button.hint = {
  positions: withHintWrapper.positions,
};

Button.propTypes = {
  clean: PropTypes.bool,
  variation: variationPropType,
  icon: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }),
  hint: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
};

Button.defaultProps = {
  size: Button.sizes.M,
};

export default Button;
