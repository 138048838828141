import { fromJS } from 'immutable';
import { SEND_MESSAGE_SUCCESSED } from 'ducks/message';

export const FETCH_MESSAGES_REQUESTED = 'FETCH_MESSAGES_REQUESTED';
export const fetchMessagesRequested = (options = {}) => ({
  type: FETCH_MESSAGES_REQUESTED,
  options,
});

export const FETCH_MESSAGES_SUCCESSED = 'FETCH_MESSAGES_SUCCESSED';
export const fetchMessagesSuccessed = messages => ({
  type: FETCH_MESSAGES_SUCCESSED,
  payload: { messages },
});

export const FETCH_MESSAGES_FAILED = 'FETCH_MESSAGES_FAILED';
export const fetchMessagesFailed = errors => ({
  type: FETCH_MESSAGES_FAILED,
  payload: { errors },
});

export const FETCH_MORE_MESSAGES_REQUESTED = 'FETCH_MORE_MESSAGES_REQUESTED';
export const fetchMoreMessagesRequested = () => ({
  type: FETCH_MORE_MESSAGES_REQUESTED,
});

export const FETCH_MORE_MESSAGES_SUCCESSED = 'FETCH_MORE_MESSAGES_SUCCESSED';
export const fetchMoreMessagesSuccessed = messages => ({
  type: FETCH_MORE_MESSAGES_SUCCESSED,
  payload: { messages },
});

export const FETCH_MORE_MESSAGES_FAILED = 'FETCH_MORE_MESSAGES_FAILED';
export const fetchMoreMessagesFailed = error => ({
  type: FETCH_MORE_MESSAGES_FAILED,
  payload: { error },
});

export const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  errors: {},
  data: [],
  page: {
    nextLink: undefined,
    didInvalidate: false,
    isFetching: false,
    errors: {},
  },
});

export default (state = initialState, { type, payload, options }) => {
  switch (type) {
    case FETCH_MESSAGES_REQUESTED:
      return state.set('isFetching', !options.silent).set('didInvalidate', false);

    case FETCH_MESSAGES_SUCCESSED:
      return state
        .set('isFetching', false)
        .set('data', fromJS(payload.messages.results))
        .setIn(['page', 'nextLink'], payload.messages.next);

    case FETCH_MESSAGES_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case FETCH_MORE_MESSAGES_REQUESTED:
      return state.setIn(['page', 'isFetching'], true).setIn(['page', 'didInvalidate'], false);

    case FETCH_MORE_MESSAGES_SUCCESSED:
      return state
        .setIn(['page', 'isFetching'], false)
        .update('data', value => value.concat(fromJS(payload.messages.results)))
        .setIn(['page', 'nextLink'], payload.messages.next);

    case FETCH_MORE_MESSAGES_FAILED:
      return state.setIn(['page', 'didInvalidate'], true).setIn(['page', 'errors'], fromJS(payload.error));

    case SEND_MESSAGE_SUCCESSED:
      return state.update('data', messages => messages.insert(0, fromJS(payload.message)));

    default:
      return state;
  }
};
