import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { missionPropType } from 'common/propTypes';
import { TextOrLink } from 'components';

const CheckOutForm = ({ checkOut, t, children }) => {
  const checklistTitle = checkOut.checklist_url && (
    <TextOrLink href={checkOut.checklist_url} target="_blank">
      {t.checklistTitle}
    </TextOrLink>
  );

  return (
    <>
      <FullWidthContentWithLabel label={t.agentName}>{checkOut.agent_full_name}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.agentPhoneNumber}>{checkOut.agent_phone_number}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.missionID}>{checkOut.id}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.checklistStatus}>{t[checkOut.checklist_status]}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel>{checklistTitle}</FullWidthContentWithLabel>
      {children}
    </>
  );
};

CheckOutForm.propTypes = {
  checkOut: missionPropType,
  hasError: PropTypes.bool,
  t: PropTypes.shape({
    instructions: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
  onBlur: PropTypes.func.isRequired,
};

export default CheckOutForm;
