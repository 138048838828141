import { all, takeLatest, put, select, call } from 'redux-saga/effects';

import {
  fetchConversationFailed,
  fetchConversationSuccessed,
  FETCH_CONVERSATION_REQUESTED,
  FETCH_INIT_CONVERSATION_REQUESTED,
  fetchInitConversationSuccessed,
  fetchInitConversationFailed,
  updateConversationFailed,
  updateConversationSuccessed,
  UPDATE_CONVERSATION_REQUESTED,
} from 'ducks/conversation';
import { getConversationById } from 'selectors/conversations';
import DataApi from 'api/DataApi';
import browserHistory from 'browserHistory';
import { paths, errorCodes } from 'constants.js';
import { getFiltersData } from 'selectors/filters';
import { getSelectedConversationId } from 'selectors/conversation';
import { getPathnameWithFilters } from 'utils/location';
import { filtersParams } from 'utils/deserializers';
import { sanitizePayload } from 'utils';

function* fetchConversation(id, { force } = {}) {
  if (!id) return null;

  let params = {};
  let conversation = !force && (yield select(getConversationById, id));
  const selectedFilters = yield select(getFiltersData);
  try {
    if (!conversation) {
      params = yield call(filtersParams, selectedFilters);
      conversation = yield call([DataApi.apiInstance(), 'getConversation'], id, params);
    }
  } catch (e) {
    if (e.status === errorCodes.NOT_FOUND) {
      return yield call(
        [browserHistory, 'replace'],
        getPathnameWithFilters(paths.APP, sanitizePayload(selectedFilters)),
      );
    }
    throw e;
  }
  return conversation;
}
/**
 * Update conversation used to change conversation status to close or to snooze a conversation
 * @param {Object} data conversation attributes payload to be updated
 * @param {Object} options options for payload handling
 */
function* handleUpdateConversation({ payload: { data, options } }) {
  try {
    const id = yield select(getSelectedConversationId);
    if (!id) return;

    const conversation = yield call([DataApi.apiInstance(), 'updateConversation'], id, data);
    yield put(updateConversationSuccessed(conversation, options));
  } catch (e) {
    yield put(updateConversationFailed(e.errors));
  }
}

export function* handleFetchConversationRequested({ payload: { id, options } }) {
  try {
    const conversation = yield call(fetchConversation, id, options);
    yield put(fetchConversationSuccessed(conversation));
  } catch (e) {
    yield put(fetchConversationFailed(e.errors));
  }
}

export function* handleFetchInitConversationRequested({ payload: { id } }) {
  try {
    const conversation = yield call(fetchConversation, id);
    if (conversation) conversation.permalink = true;
    yield put(fetchInitConversationSuccessed(conversation));
  } catch (e) {
    yield put(fetchInitConversationFailed(e.errors));
  }
}

export default function* watchConversations() {
  yield all([
    takeLatest(UPDATE_CONVERSATION_REQUESTED, handleUpdateConversation),
    takeLatest(FETCH_CONVERSATION_REQUESTED, handleFetchConversationRequested),
    takeLatest(FETCH_INIT_CONVERSATION_REQUESTED, handleFetchInitConversationRequested),
  ]);
}
