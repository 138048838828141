import React from 'react';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text } from 'ready/components/typography';

const WhatsAppConversationWarning = ({ t, ...props }) => (
  <ChatNotification data-qa="Notification:WhatsAppConversationWarning__ChatNotification" t={t} {...props}>
    <Text data-qa="WhatsAppConversationWarning:Text"> {t.whatsappConversationText}</Text>
  </ChatNotification>
);

WhatsAppConversationWarning.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
    whatsappConversationText: PropTypes.string.isRequired,
  }),
};

export default WhatsAppConversationWarning;
