import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getBooking } from 'selectors/booking';
import { getGuest } from 'selectors/guest';
import { fetchBookingRequested, updateReservationRequested } from 'ducks/booking';
import { fetchSmartDevicesRequested, toggleSmartDeviceRequested } from 'ducks/smartDevices';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { conversationPropType, guestPropType } from 'common/propTypes';

import Booking from './components/Booking/Booking';
import CheckIn from './components/CheckIn/CheckIn';
import CheckOut from './components/CheckOut/CheckOut';
import PreviousCleaning from './components/PreviousCleaning/PreviousCleaning';
import SmartDevices from './components/SmartDevices/SmartDevices';

import Guest from './components/Guest/Guest';
import Property from './components/Property/Property';
import { CenteredLoadingIndicator, CenteredRequestError, TabContentWrapper } from '../../components';

import { guestCheckInPayload, guestCheckOutPayload, guestCheckInMissionCommentPayload } from 'utils/deserializers';
import { contexts } from 'constants.js';
import { getSmartDevices } from 'selectors/smartDevices';
import { openReservationAlterationCreateForm, openReservationAlterationEditForm } from 'ducks/conversationDrawer';

const Overview = ({
  intl,
  conversation,
  booking,
  smart_devices,
  t,
  dispatchFetchBookingRequested,
  dispatchUpdateReservationRequested,
  dispatchToggleDeviceRequested,
  dispatchOpenReservationAlterationCreateForm,
  dispatchOpenReservationAlterationEditForm,
  guest,
}) => {
  if (booking.isFetching) return <CenteredLoadingIndicator />;

  if (booking.didInvalidate) return <CenteredRequestError errors={booking.errors} />;

  if (!booking.data) return false;

  return (
    <TabContentWrapper data-qa="Tab:__innerWrapper--overview">
      <Booking
        guest={guest}
        intl={intl}
        t={t}
        booking={booking}
        reservations={conversation.reservations}
        inquiries={conversation.inquiries}
        onBookingSelectChange={dispatchFetchBookingRequested}
        onGuestCheckInChange={option =>
          dispatchUpdateReservationRequested(
            booking.data.id,
            guestCheckInPayload(option.value),
            contexts.infoSidebar.BOOKING,
          )
        }
        onGuestCheckOutChange={option =>
          dispatchUpdateReservationRequested(
            booking.data.id,
            guestCheckOutPayload(option.value),
            contexts.infoSidebar.BOOKING,
          )
        }
        dispatchOpenReservationAlterationCreateForm={dispatchOpenReservationAlterationCreateForm}
        dispatchOpenReservationAlterationEditForm={dispatchOpenReservationAlterationEditForm}
      />
      <CheckIn
        t={t}
        booking={booking}
        onCommentBlur={comment =>
          dispatchUpdateReservationRequested(
            booking.data.id,
            guestCheckInMissionCommentPayload(comment),
            contexts.infoSidebar.BOOKING,
          )
        }
      />
      <CheckOut t={t} booking={booking} />
      <PreviousCleaning t={t} booking={booking} />
      <Guest t={t} guest={guest} booking={booking.data} />
      <Property t={t} booking={booking.data} />
      <SmartDevices t={t} onToggleDevice={dispatchToggleDeviceRequested} smart_devices={smart_devices.data} />
    </TabContentWrapper>
  );
};

Overview.propTypes = {
  dispatchFetchBookingRequested: PropTypes.func.isRequired,
  dispatchUpdateReservationRequested: PropTypes.func.isRequired,
  dispatchFetchDevicesRequested: PropTypes.func.isRequired,
  dispatchToggleDeviceRequested: PropTypes.func.isRequired,
  dispatchOpenReservationAlterationCreateForm: PropTypes.func.isRequired,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
  conversation: conversationPropType.isRequired,
  booking: Booking.propTypes.booking,
  guest: guestPropType.isRequired,
  t: PropTypes.object.isRequired,
  intl: intlShape,
};

const mapStateToProps = state => ({
  booking: getBooking(state),
  guest: getGuest(state),
  smart_devices: getSmartDevices(state),
});

const mapDispatchToProps = {
  dispatchFetchBookingRequested: fetchBookingRequested,
  dispatchUpdateReservationRequested: updateReservationRequested,
  dispatchFetchDevicesRequested: fetchSmartDevicesRequested,
  dispatchToggleDeviceRequested: toggleSmartDeviceRequested,
  dispatchOpenReservationAlterationCreateForm: openReservationAlterationCreateForm,
  dispatchOpenReservationAlterationEditForm: openReservationAlterationEditForm,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    airbnbAccount: 'BOOKING.AIRBNB_ACCOUNT',
    airbnbPassword: 'BOOKING.AIRBNB_PASSWORD',
    status: 'BOOKING.STATUS',
    checkIn: 'BOOKING.CHECK_IN',
    checkOut: 'BOOKING.CHECK_OUT',
    checkInDelay: 'BOOKING.CHECK_IN_DELAY',
    confirmationCode: 'BOOKING.CONFIRMATION_CODE',
    id: 'BOOKING.ID',
    duration: 'BOOKING.DURATION',
    price: 'BOOKING.PRICE',
    dbwLink: 'BOOKING.DBW_LINK',
    link: 'BOOKING.LINK',
    copyLink: 'BOOKING.COPY_LINK',
    source: 'BOOKING.SOURCE',
    tbc: 'BOOKING.TBC',
    bookingTitle: 'BOOKING.TITLE',
    bookingSelectLabel: 'BOOKING.BOOKING_SELECT',
    bookings: 'BOOKING.BOOKINGS',
    inquiries: 'BOOKING.INQUIRIES',
    noResultsText: 'BOOKING.TIME_NO_RESULTS',
    placeholder: 'BOOKING.TBC',
    propertyManager: 'BOOKING.PROPERTY_MANAGER',
    missionID: 'MISSION.MISSION_ID',
    agentName: 'MISSION.AGENT_NAME',
    agentPhoneNumber: 'MISSION.AGENT_PHONE_NUMBER',
    checklistStatus: 'MISSION.CHECKLIST_STATUS',
    checklistUrl: 'MISSION.CHECKLIST_URL',
    checklistTitle: 'MISSION.CHECKLIST_TITLE',
    NEW: 'MISSION.CHECKLIST.STATUS.NEW',
    STARTED: 'MISSION.CHECKLIST.STATUS.STARTED',
    SUBMITTED: 'MISSION.CHECKLIST.STATUS.SUBMITTED',
    REVIEWED: 'MISSION.CHECKLIST.STATUS.REVIEWED',
    checkInTitle: 'CHECKIN_MISSION.TITLE',
    instructions: 'CHECKIN_MISSION.INSTRUCTIONS',
    lockboxCode: 'CHECKIN_MISSION.LOCKBOX_CODE',
    type: 'CHECKIN_MISSION.TYPE',
    comments: 'CHECKIN_MISSION.COMMENTS',
    IN_PERSON: 'CHECKIN_MISSION.TYPE_OPTIONS.IN_PERSON',
    SELF: 'CHECKIN_MISSION.TYPE_OPTIONS.SELF',
    NOT_ARRIVED: 'CHECKIN_MISSION.TYPE_OPTIONS.NOT_ARRIVED',
    ARRIVED: 'CHECKIN_MISSION.TYPE_OPTIONS.ARRIVED',
    LATE: 'CHECKIN_MISSION.TYPE_OPTIONS.LATE',
    UNKNOWN: 'CHECKIN_MISSION.TYPE_OPTIONS.UNKNOWN',
    NO_SHOW: 'CHECKIN_MISSION.TYPE_OPTIONS.NO_SHOW',
    CANCELLED: 'CHECKIN_MISSION.TYPE_OPTIONS.CANCELLED',
    checkOutTitle: 'CHECKOUT_MISSION.TITLE',
    previousCleaningTitle: 'PREVIOUS_CLEANING_MISSION.TITLE',
    guestTitle: 'GUEST.TITLE',
    guestName: 'GUEST.NAME',
    count: 'GUEST.COUNT',
    contacts: 'GUEST.CONTACTS',
    verified: 'GUEST.VERIFIED',
    notVerified: 'GUEST.NOT_VERIFIED',
    airbnbProfile: 'GUEST.AIRBNB_PROFILE',
    reviewCount: 'GUEST.REVIEW_COUNT',
    location: 'GUEST.LOCATION',
    guestAirbnbAccount: 'GUEST.AIRBNB_ACCOUNT',
    propertyTitle: 'PROPERTY.TITLE',
    propertyName: 'PROPERTY.NAME',
    size: 'PROPERTY.SIZE',
    address: 'PROPERTY.ADDRESS',
    floor: 'INFO_SIDEBAR.PROPERTY.GENERAL.FLOOR',
    numberOfBedrooms: 'PROPERTY.NUMBER_OF_BEDROOMS',
    selfCheckInLabel: 'PROPERTY.SELF_CHECK_IN.LABEL',
    selfCheckInTooltip: 'PROPERTY.SELF_CHECK_IN.TOOLTIP',
    smartDeviceTitle: 'SMART_DEVICE.TITLE',
    keyStrategy: 'KEY_STRATEGY.KEY_STRATEGY',
    reception: 'RECEPTION.TITLE',
    receptionName: 'RECEPTION.NAME',
    receptionAddress: 'RECEPTION.ADDRESS',
    receptionDescription: 'RECEPTION.DESCRIPTION',
    receptionHours: 'RECEPTION.OPENING_HOURS',
    numberOfGuests: 'BOOKING.NUMBER_OF_GUESTS',
    maxGuests: 'BOOKING.MAX_GUESTS',
    guestComment: 'BOOKING.GUEST_COMMENT',
    cancellationPolicy: 'BOOKING.CANCELLATION_POLICY',
    listing: 'BOOKING.LISTING',
    seeListing: 'BOOKING.SEE_LISTING',
    moodGoodTooltip: 'GUEST.MOOD_GOOD_TOOLTIP',
    moodGood: 'GUEST.MOOD_GOOD',
    moodBadTooltip: 'GUEST.MOOD_BAD_TOOLTIP',
    moodBad: 'GUEST.MOOD_BAD',
    first_reservations: 'CONVERSATIONS.FIRST_RESERVATIONS',
    first_reservation: 'CONVERSATIONS.FIRST_RESERVATION',
    first_reservations_tooltip: 'CONVERSATIONS.FIRST_RESERVATIONS_TOOLTIP',
    first_reservation_tooltip: 'CONVERSATIONS.FIRST_RESERVATION_TOOLTIP',
    manage: 'TEXT.SHARED.MANAGE',
    proposeChanges: 'BOOKING.ALTERATION.PROPOSE_CHANGES',
    guestProposed: 'BOOKING.ALTERATION.GUEST_PROPOSED',
    seeReview: 'BOOKING.GUEST_REVIEWS.SEE_REVIEW',
    noRating: 'BOOKING.GUEST_REVIEWS.NO_RATING',
    underInvestigation: 'BOOKING.GUEST_REVIEWS.UNDER_INVESTIGATION',
    copy: 'TEXT.SHARED.COPY',
    stayExtension: 'BOOKING.STAY_EXTENSION.TITLE',
  }),
)(Overview);
