import { fromJS } from 'immutable';

export const FETCH_INQUIRY_REQUESTED = 'FETCH_INQUIRY_REQUESTED';
export const fetchInquiryRequested = (id, options) => ({
  type: FETCH_INQUIRY_REQUESTED,
  payload: { id },
  options,
});

export const FETCH_RESERVATION_REQUESTED = 'FETCH_RESERVATION_REQUESTED';
export const fetchReservationRequested = id => ({ type: FETCH_RESERVATION_REQUESTED, payload: { id } });

export const FETCH_INIT_BOOKING_REQUESTED = 'FETCH_INIT_BOOKING_REQUESTED';
export const fetchInitBookingRequested = conversation => ({
  type: FETCH_INIT_BOOKING_REQUESTED,
  payload: { conversation },
});

export const FETCH_BOOKING_REQUESTED = 'FETCH_BOOKING_REQUESTED';
export const fetchBookingRequested = ({ type, id }) => ({
  type: FETCH_BOOKING_REQUESTED,
  payload: { type, id },
});

export const FETCH_BOOKING_SUCCESSED = 'FETCH_BOOKING_SUCCESSED';
export const fetchBookingSuccessed = booking => ({
  type: FETCH_BOOKING_SUCCESSED,
  payload: { booking },
});

export const FETCH_BOOKING_FAILED = 'FETCH_BOOKING_FAILED';
export const fetchBookingFailed = errors => ({
  type: FETCH_BOOKING_FAILED,
  payload: { errors },
});

export const UPDATE_INQUIRY_REQUESTED = 'UPDATE_INQUIRY_REQUESTED';
export const updateInquiryRequested = (id, payload, context) => ({
  type: UPDATE_INQUIRY_REQUESTED,
  payload: { id, payload, context },
});

export const UPDATE_RESERVATION_REQUESTED = 'UPDATE_RESERVATION_REQUESTED';
export const updateReservationRequested = (id, payload, context) => ({
  type: UPDATE_RESERVATION_REQUESTED,
  payload: { id, payload, context },
});

export const UPDATE_BOOKING_SUCCESSED = 'UPDATE_BOOKING_SUCCESSED';
export const updateBookingSuccessed = booking => ({
  type: UPDATE_BOOKING_SUCCESSED,
  payload: { booking },
});

export const UPDATE_BOOKING_FAILED = 'UPDATE_BOOKING_FAILED';
export const updateBookingFailed = (errors, context) => ({
  type: UPDATE_BOOKING_FAILED,
  payload: { errors, context },
});

export const initialState = fromJS({
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  data: null,
  errors: {
    context: null,
    updating: false,
    fetching: false,
  },
});

export default (state = initialState, { type, payload, options = {} } = { options: {} }) => {
  switch (type) {
    case FETCH_INIT_BOOKING_REQUESTED:
    case FETCH_INQUIRY_REQUESTED:
    case FETCH_RESERVATION_REQUESTED:
      return state
        .set('isFetching', !options.silent)
        .set('didInvalidate', false)
        .set('errors', initialState.get('errors'))
        .update('data', value => (options.silent ? value : null));

    case UPDATE_INQUIRY_REQUESTED:
    case UPDATE_RESERVATION_REQUESTED:
      return state.set('errors', initialState.get('errors').set('context', payload.context)).set('isUpdating', true);

    case FETCH_BOOKING_SUCCESSED:
      return state.set('isFetching', false).set('data', fromJS(payload.booking));

    case UPDATE_BOOKING_SUCCESSED:
      return state.set('isUpdating', false).set('data', fromJS(payload.booking));

    case FETCH_BOOKING_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .update('errors', value => value.set('fetching', true).merge(payload.errors));

    case UPDATE_BOOKING_FAILED:
      return state
        .set('isUpdating', false)
        .update('errors', value => value.set('updating', true).merge(payload.errors));

    default:
      return state;
  }
};
