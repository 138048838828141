import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { Modal } from 'ready/components';
import { OverlayBackground } from 'components';
import { getShowRejectAlterationModal } from 'selectors/conversationDrawer';
import { changeShowRejectAlterationModal } from 'ducks/conversationDrawer';
import { Text, Button } from 'ready/components';
import { rejectReservationAlterationRequested } from 'ducks/reservationAlteration';
import { getReservationAlteration } from 'selectors/reservationAlteration';

const StyledOverlayBackground = styled(OverlayBackground)`
  z-index: ${props => props.theme.zIndex.drawer[2]};
`;

const Content = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.m} 0;
`;

const RejectAlterationModal = ({
  showRejectAlterationModal,
  reservationAlteration,
  dispatchChangeShowRejectAlterationModal,
  dispatchRejectReservationAlteration,
  t,
}) => {
  function rejectChanges() {
    dispatchChangeShowRejectAlterationModal(false);
    dispatchRejectReservationAlteration(reservationAlteration.data.id);
  }
  function rejectAndProposeChanges() {
    dispatchChangeShowRejectAlterationModal(false);
    dispatchRejectReservationAlteration(reservationAlteration.data.id, true, reservationAlteration.data.reservation);
  }
  return (
    <>
      {showRejectAlterationModal && (
        <StyledOverlayBackground>
          <Modal heading={t.rejectHeader} size={Modal.sizes.M}>
            <Content>
              <Text>{t.rejectContent}</Text>
              <ButtonsWrapper>
                <Button
                  variation={Button.variations.OUTLINE}
                  size={Button.sizes.M}
                  inline
                  onClick={() => dispatchChangeShowRejectAlterationModal(false)}
                >
                  {t.cancel}
                </Button>
                <Button
                  variation={Button.variations.OUTLINE}
                  size={Button.sizes.M}
                  inline
                  onClick={rejectAndProposeChanges}
                >
                  {t.rejectAndProposeAction}
                </Button>
                <Button variation={Button.variations.ERROR} size={Button.sizes.M} inline onClick={rejectChanges}>
                  {t.rejectAction}
                </Button>
              </ButtonsWrapper>
            </Content>
          </Modal>
        </StyledOverlayBackground>
      )}
    </>
  );
};

RejectAlterationModal.propTypes = {
  showRejectAlterationModal: PropTypes.bool.isRequired,
  reservationAlteration: PropTypes.object,
  dispatchChangeShowRejectAlterationModal: PropTypes.func.isRequired,
  dispatchRejectReservationAlteration: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  showRejectAlterationModal: getShowRejectAlterationModal(state),
  reservationAlteration: getReservationAlteration(state),
});

const mapDispatchToProps = {
  dispatchChangeShowRejectAlterationModal: changeShowRejectAlterationModal,
  dispatchRejectReservationAlteration: rejectReservationAlterationRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    rejectHeader: 'BOOKING.ALTERATION.FORM.MODAL.REJECT_HEADER',
    rejectContent: 'BOOKING.ALTERATION.FORM.MODAL.REJECT_CONTENT',
    rejectAction: 'BOOKING.ALTERATION.FORM.MODAL.REJECT_ACTION',
    rejectAndProposeAction: 'BOOKING.ALTERATION.FORM.MODAL.REJECT_AND_PROPOSE_ACTION',
    cancel: 'TEXT.SHARED.CANCEL',
  }),
)(RejectAlterationModal);
