import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text, Select } from 'ready/components';

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing.xxxs};
  margin-top: ${props => props.theme.spacing.l};
  text-align: left;
`;

const CallReason = ({ callReasons, value = '', onChange, t }) => {
  const valueOption = callReasons.find(option => option.value && option.value.id === value);
  return (
    <Wrapper>
      <Text size={Text.sizes.S} bold>
        {t.callReasonLabel}
      </Text>
      <Select value={valueOption} options={callReasons} onChange={e => onChange(e.value)} closeMenuOnSelect t={t} />
    </Wrapper>
  );
};

CallReason.propTypes = {
  callReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      content_en: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.shape({
    callReasonLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default CallReason;
