import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';

import Notifications from 'scenes/shared/Notifications/Notifications';
import MessageArea from './containers/MessageArea/MessageArea';
import DropzoneMessageInputArea from './containers/MessageInputArea/DropzoneMessageInputArea';
import SavedReplies from './containers/SaveReplies/SavedReplies';
import { paths } from 'constants.js';
import ConversationDrawer from 'scenes/shared/ConversationDrawer/ConversationDrawer';
import AcceptAlterationModal from 'scenes/shared/ConversationDrawer/components/ReservationAlteration/components/AcceptAlterationModal';
import RejectAlterationModal from 'scenes/shared/ConversationDrawer/components/ReservationAlteration/components/RejectAlterationModal';
import ProposeAlterationModal from 'scenes/shared/ConversationDrawer/components/ReservationAlteration/components/ProposeAlterationModal';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-area: conversation;
  position: relative;
`;

const Conversation = ({ className, location, match }) => (
  <>
    <Wrapper className={className} data-qa="shared:Conversation__wrapper">
      <ConversationDrawer />
      <Notifications />
      <MessageArea />
      <DropzoneMessageInputArea location={location} />
      <Route path={`${match.path}${paths.SAVED_REPLIES}`} component={SavedReplies} />
    </Wrapper>
    <AcceptAlterationModal />
    <RejectAlterationModal />
    <ProposeAlterationModal />
  </>
);

Conversation.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Conversation);
