import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getAutomatedMessages } from 'selectors/automatedMessages';
import { updateAutomatedMessageRequested, sendAutomatedMessageRequested } from 'ducks/automatedMessages';
import { updateConversationRequested } from 'ducks/conversation';
import { getConversation } from 'selectors/conversation';

import AutomatedMessages from './components/AutomatedMessages';
import { TabContentWrapper } from '../../components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import AutoReply from './components/AutoReply';
import { updateReservationRequested } from 'ducks/booking';
import AutoReview from './components/AutoReview';
import { getBooking } from 'selectors/booking';

const Automation = ({
  automatedMessages,
  booking,
  conversation,
  t,
  dispatchUpdateAutomatedMessageRequested,
  dispatchSendAutomatedMessageRequested,
  dispatchUpdateReservationRequested,
  dispatchUpdateConversationRequested,
}) => {
  return (
    <TabContentWrapper data-qa="Tab:__innerWrapper--automatedMessages">
      <AutoReply
        t={t}
        conversation={conversation}
        dispatchUpdateConversationRequested={dispatchUpdateConversationRequested}
      />
      <AutoReview dispatchUpdateReservationRequested={dispatchUpdateReservationRequested} booking={booking} t={t} />
      <AutomatedMessages
        automatedMessages={automatedMessages}
        dispatchUpdateAutomatedMessageRequested={dispatchUpdateAutomatedMessageRequested}
        dispatchSendAutomatedMessageRequested={dispatchSendAutomatedMessageRequested}
        t={t}
      />
    </TabContentWrapper>
  );
};

Automation.propTypes = {
  dispatchUpdateAutomatedMessageRequested: PropTypes.func.isRequired,
  dispatchSendAutomatedMessageRequested: PropTypes.func.isRequired,
  dispatchUpdateReservationRequested: PropTypes.func.isRequired,
  dispatchUpdateConversationRequested: PropTypes.func.isRequired,
  booking: PropTypes.object,
  conversation: PropTypes.object,
  automatedMessages: PropTypes.object,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  automatedMessages: getAutomatedMessages(state),
  booking: getBooking(state),
  conversation: getConversation(state),
});

const mapDispatchToProps = {
  dispatchUpdateAutomatedMessageRequested: updateAutomatedMessageRequested,
  dispatchSendAutomatedMessageRequested: sendAutomatedMessageRequested,
  dispatchUpdateReservationRequested: updateReservationRequested,
  dispatchUpdateConversationRequested: updateConversationRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    sentOn: 'INFO_SIDEBAR.AUTOMATION.AUTOMATED_MESSAGES.SENT_ON',
    automatedMessagesTitle: 'INFO_SIDEBAR.AUTOMATION.AUTOMATED_MESSAGES.TITLE',
    autoReviewTitle: 'INFO_SIDEBAR.AUTOMATION.AUTO_REVIEW.TITLE',
    guestAutoReview: 'INFO_SIDEBAR.AUTOMATION.AUTO_REVIEW.GUEST_REVIEW',
    cancelAutoReview: 'INFO_SIDEBAR.AUTOMATION.AUTO_REVIEW.CANCEL',
    autoReviewDisabled: 'INFO_SIDEBAR.AUTOMATION.AUTO_REVIEW.DISABLED_OR_SENT',
    autoReplyTitle: 'INFO_SIDEBAR.AUTOMATION.AUTO_REPLY.TITLE',
    autoReplySettingIsRelated: 'INFO_SIDEBAR.AUTOMATION.AUTO_REPLY.SETTING_IS_RELATED',
    enableAutomaticAIReply: 'INFO_SIDEBAR.AUTOMATION.AUTO_REPLY.ENABLE.TITLE',
    disableAutomaticAIReply: 'INFO_SIDEBAR.AUTOMATION.AUTO_REPLY.DISABLE.TITLE',
    enabled: 'TEXT.SHARED.ENABLED',
    disabled: 'TEXT.SHARED.DISABLED',
    send: 'TEXT.SHARED.SEND',
    resend: 'TEXT.SHARED.RESEND',
  }),
)(Automation);
