import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { LoadingIndicator, Text } from 'ready/components';
import { RequestError } from 'components';
import { withPadding } from 'ready/styles/helpers';
import { sizes } from 'ready/utils/constants';

const CenteredContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: ${props => `0 ${props.theme.spacing.l}`};
  min-height: ${props => props.minHeight || '22rem'};
  ${withPadding};
`;
ContentWrapper.sizes = sizes;

export const TabContentWrapper = styled.div`
  overflow-y: auto;
  max-height: 100%;
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.xs};
  padding: ${props => props.theme.spacing.xs};
  align-content: flex-start;
  position: relative;

  & > div:last-child {
    margin-bottom: ${props => props.theme.spacing.xs};
  }
`;

export const CenteredLoadingIndicator = ({ className }) => (
  <CenteredContentWrapper data-qa="CenteredLoadingIndicator" className={className}>
    <LoadingIndicator />
  </CenteredContentWrapper>
);

CenteredLoadingIndicator.propTypes = {
  className: PropTypes.string,
};

export const CenteredRequestError = ({ errors, className }) => (
  <CenteredContentWrapper className={className}>
    <RequestError errors={errors} />
  </CenteredContentWrapper>
);

CenteredRequestError.propTypes = {
  errors: PropTypes.object,
  className: PropTypes.string,
};

export const FormRow = styled.div`
  padding: ${({ theme, isEmpty }) => (isEmpty ? 0 : theme.spacing.xs)};
`;

export const TabWithForm = styled(TabContentWrapper)`
  align-items: flex-start;
  padding: 0;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0;
`;

export const Paragraph = styled(Text)`
  white-space: normal;
  word-break: break-word;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;
