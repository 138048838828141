import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'ready/components';
import { bookingPropType } from 'common/propTypes';

import Notification from 'ready/components/Notification/Notification';

const clickable = ({ onClick }) =>
  onClick &&
  css`
    cursor: pointer;
    user-select: none;
  `;

const uppercase = ({ uppercase }) =>
  uppercase &&
  css`
    text-transform: uppercase;
  `;

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: ${props => props.theme.spacing.s};
  margin-bottom: ${props => props.theme.spacing.m};
`;

const StyledTextLink = styled(Text)`
  color: ${props => props.theme.colors.primary};
  ${clickable}
`;

const StyledNotification = styled(Notification)`
  width: 100%;
  padding: ${props => props.theme.spacing.xs};
  color: ${props => props.theme.colors.b700};
`;

const StyledNotificationText = styled(Text)`
  color: ${props => props.theme.colors.b700};
  margin: 0 ${props => props.theme.spacing.xs};
  ${clickable}
  ${uppercase}
`;

const AlterationOptionsGroup = ({
  booking,
  t,
  dispatchOpenReservationAlterationCreateForm,
  dispatchOpenReservationAlterationEditForm,
}) => {
  return (
    <Container>
      {booking.last_guest_reservation_alteration && (
        <StyledNotification size={Notification.sizes.S} variation={Notification.variations.INFO}>
          <StyledNotificationText size={Text.sizes.S}>{t.guestProposed}</StyledNotificationText>
          <StyledNotificationText
            bold
            size={Text.sizes.S}
            onClick={() => dispatchOpenReservationAlterationEditForm(booking.last_guest_reservation_alteration)}
            uppercase
          >
            {t.manage}
          </StyledNotificationText>
        </StyledNotification>
      )}
      <StyledTextLink size={Text.sizes.S} onClick={() => dispatchOpenReservationAlterationCreateForm(booking.id)}>
        {t.proposeChanges}
      </StyledTextLink>
    </Container>
  );
};

AlterationOptionsGroup.propTypes = {
  booking: bookingPropType.isRequired,
  dispatchOpenReservationAlterationCreateForm: PropTypes.func.isRequired,
  dispatchOpenReservationAlterationEditForm: PropTypes.func.isRequired,
  t: PropTypes.shape({
    manage: PropTypes.string.isRequired,
    proposeChanges: PropTypes.string.isRequired,
    guestProposed: PropTypes.string.isRequired,
  }),
};

export default AlterationOptionsGroup;
