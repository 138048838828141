import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import DataApi from 'api/DataApi';

import {
  FETCH_AUTOMATED_MESSAGES_REQUESTED,
  UPDATE_AUTOMATED_MESSAGE_REQUESTED,
  SEND_AUTOMATED_MESSAGE_REQUESTED,
  updateAutomatedMessageFailed,
  updateAutomatedMessageSuccessed,
  fetchAutomatedMessagesFailed,
  fetchAutomatedMessagesSuccessed,
  sendAutomatedMessageFailed,
  sendAutomatedMessageSuccessed,
} from 'ducks/automatedMessages';
import { getBookingData } from 'selectors/booking';
import { automatedMessagePayload } from 'utils/deserializers';
import { fetchMessagesRequested } from 'ducks/messages';

export function* handleFetchAutomatedMessages({ payload: { reservationId } }) {
  try {
    const automatedMessages = yield call([DataApi.apiInstance(), 'getAutomatedMessages'], {
      reservation_id: reservationId,
    });
    yield put(fetchAutomatedMessagesSuccessed(automatedMessages));
  } catch (e) {
    yield put(fetchAutomatedMessagesFailed(e.errors));
  }
}

export function* handleUpdateAutomatedMessage({ payload: { automatedMessage } }) {
  try {
    const booking = yield select(getBookingData);
    const updatedAutomatedMessage = yield call(
      [DataApi.apiInstance(), 'putAutomatedMessage'],
      automatedMessage.id,
      automatedMessagePayload(automatedMessage, booking),
    );

    yield put(updateAutomatedMessageSuccessed(updatedAutomatedMessage));
  } catch (e) {
    yield put(updateAutomatedMessageFailed(e.errors));
  }
}

export function* handleSendAutomatedMessage({ payload: { automatedMessage } }) {
  try {
    const booking = yield select(getBookingData);
    const updatedAutomatedMessage = yield call(
      [DataApi.apiInstance(), 'sendAutomatedMessage'],
      booking.id,
      automatedMessage.trigger,
    );

    yield put(sendAutomatedMessageSuccessed(updatedAutomatedMessage));
    yield put(fetchMessagesRequested());
  } catch (e) {
    yield put(sendAutomatedMessageFailed(e.errors));
  }
}

export default function* watchInquiry() {
  yield all([
    takeLatest(FETCH_AUTOMATED_MESSAGES_REQUESTED, handleFetchAutomatedMessages),
    takeLatest(UPDATE_AUTOMATED_MESSAGE_REQUESTED, handleUpdateAutomatedMessage),
    takeLatest(SEND_AUTOMATED_MESSAGE_REQUESTED, handleSendAutomatedMessage),
  ]);
}
