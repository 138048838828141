import { css } from 'styled-components';

const withoutTextDecorationStyles = ({ withTextDecoration }) =>
  !withTextDecoration &&
  css`
    text-decoration: none;
  `;

export const baseDisplay = () =>
  css`
    display: flex;
    width: 100%;
    ${withoutTextDecorationStyles}
  `;

export const inlineDisplay = ({ inline }) =>
  inline &&
  css`
    display: inline-flex;
    ${withoutTextDecorationStyles}
  `;
