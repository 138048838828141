import { fromJS } from 'immutable';

export const FETCH_SAVED_REPLIES_REQUESTED = 'FETCH_SAVED_REPLIES_REQUESTED';
export const fetchSavedRepliesRequested = (search = '') => ({
  type: FETCH_SAVED_REPLIES_REQUESTED,
  payload: { search },
});

export const FETCH_SAVED_REPLIES_SUCCESSED = 'FETCH_SAVED_REPLIES_SUCCESSED';
export const fetchSavedRepliesSuccessed = savedReplies => ({
  type: FETCH_SAVED_REPLIES_SUCCESSED,
  payload: { savedReplies },
});

export const FETCH_SAVED_REPLIES_FAILED = 'FETCH_SAVED_REPLIES_FAILED';
export const fetchSavedRepliesFailed = errors => ({ type: FETCH_SAVED_REPLIES_FAILED, payload: { errors } });

export const FETCH_MORE_SAVED_REPLIES_REQUESTED = 'FETCH_MORE_SAVED_REPLIES_REQUESTED';
export const fetchMoreSavedRepliesRequested = () => ({ type: FETCH_MORE_SAVED_REPLIES_REQUESTED });

export const FETCH_MORE_SAVED_REPLIES_SUCCESSED = 'FETCH_MORE_SAVED_REPLIES_SUCCESSED';
export const fetchMoreSavedRepliesSuccessed = savedReplies => ({
  type: FETCH_MORE_SAVED_REPLIES_SUCCESSED,
  payload: { savedReplies },
});

export const FETCH_MORE_SAVED_REPLIES_FAILED = 'FETCH_MORE_SAVED_REPLIES_FAILED';
export const fetchMoreSavedRepliesFailed = errors => ({ type: FETCH_MORE_SAVED_REPLIES_FAILED, payload: { errors } });

export const SAVED_REPLY_ADDED_TO_MESSAGE = 'SAVED_REPLY_ADDED_TO_MESSAGE';
export const savedReplyAddedToMessage = savedReply => ({ type: SAVED_REPLY_ADDED_TO_MESSAGE, payload: { savedReply } });

export const UPDATE_SAVED_REPLY_CLICK_COUNT = 'UPDATE_SAVED_REPLY_CLICK_COUNT';
export const updateSavedReplyClickCount = id => ({
  type: UPDATE_SAVED_REPLY_CLICK_COUNT,
  payload: { id },
});

export const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  data: [],
  errors: {},
  page: {
    nextLink: '',
    previousLink: '',
    didInvalidate: false,
    isFetching: false,
    errors: {},
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SAVED_REPLIES_REQUESTED:
      return initialState.set('isFetching', true);

    case FETCH_MORE_SAVED_REPLIES_REQUESTED:
      return state.setIn(['page', 'isFetching'], true).setIn(['page', 'didInvalidate'], false);

    case FETCH_SAVED_REPLIES_SUCCESSED:
      return state
        .set('isFetching', false)
        .set('data', fromJS(payload.savedReplies.results))
        .setIn(['page', 'nextLink'], payload.savedReplies.next);

    case FETCH_SAVED_REPLIES_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case FETCH_MORE_SAVED_REPLIES_SUCCESSED:
      return state
        .setIn(['page', 'isFetching'], false)
        .update('data', value => value.concat(fromJS(payload.savedReplies.results)))
        .setIn(['page', 'nextLink'], payload.savedReplies.next);

    case FETCH_MORE_SAVED_REPLIES_FAILED:
      return state
        .setIn(['page', 'isFetching'], false)
        .setIn(['page', 'didInvalidate'], true)
        .setIn(['page', 'errors'], fromJS(payload.errors));
    default:
      return state;
  }
};
