import { combineReducers } from 'redux';

import view from './view';
import filters from './filters';
import filterPresets from './filterPresets';
import conversation from './conversation';
import conversations from './conversations';
import user from './user';
import messages from './messages';
import message from './message';
import booking from './booking';
import savedReplies from './savedReplies';
import callCenter from './callCenter';
import smartDevices from './smartDevices';
import specialOffer from './specialOffer';
import automatedMessages from './automatedMessages';
import rental from './rental';
import guest from './guest';
import attachments from './attachments';
import conversationDrawer from './conversationDrawer';
import reservationAlteration from './reservationAlteration';

export default () =>
  combineReducers({
    view,
    filters,
    filterPresets,
    conversation,
    conversations,
    user,
    messages,
    message,
    booking,
    savedReplies,
    callCenter,
    smartDevices,
    specialOffer,
    automatedMessages,
    rental,
    guest,
    attachments,
    conversationDrawer,
    reservationAlteration,
  });
