import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'ready/styles/theme';

import { CenteredRequestError } from '../components';
import { Collapsible, LoadingIndicator } from 'ready/components';

const RequestError = styled(CenteredRequestError)`
  padding-bottom: ${props => props.theme.spacing.m};
`;

const Content = ({ didInvalidate, isFetching, errors, children }) => {
  if (isFetching) return false;

  if (didInvalidate) return <RequestError errors={errors} />;

  return children;
};

Content.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  didInvalidate: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errors: PropTypes.shape({
    non_field_errors: PropTypes.arrayOf(PropTypes.string.isRequired),
    detail: PropTypes.string,
    message: PropTypes.string,
  }),
};

const CollapsibleWithState = ({ children, isFetching, didInvalidate, errors, ...props }) => {
  return (
    <Collapsible
      iconComponent={isFetching && <LoadingIndicator color={colors.n400} size={LoadingIndicator.sizes.XXS} />}
      data-qa="CollapsibleWithState"
      {...props}
    >
      <Content isFetching={isFetching} didInvalidate={didInvalidate} errors={errors}>
        {children}
      </Content>
    </Collapsible>
  );
};

CollapsibleWithState.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  didInvalidate: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errors: Content.propTypes.errors,
};

export default CollapsibleWithState;
