import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  SAVE_FILTER_PRESET_REQUESTED,
  FETCH_FILTER_PRESETS_REQUESTED,
  DELETE_FILTER_PRESET_REQUESTED,
  fetchFilterPresetsSuccessed,
  fetchFilterPresetsFailed,
  deleteFilterPresetSuccessed,
  deleteFilterPresetFailed,
  saveFilterPresetSuccessed,
  saveFilterPresetFailed,
  changeIsSavePresetOpen,
} from 'ducks/filterPresets';
import DataApi from 'api/DataApi';
import { stringifyFiltersForPreset } from 'utils/deserializers';

export function* handleFetchFilterPresetsRequested() {
  try {
    const presets = yield call([DataApi.apiInstance(), 'getFilterPresets']);
    const parsedPresets = presets.map(preset => ({ ...preset, filters: JSON.parse(preset.filters) }));
    yield put(fetchFilterPresetsSuccessed(parsedPresets));
  } catch (e) {
    yield put(fetchFilterPresetsFailed(e.errors));
  }
}

export function* handleDeleteFilterPresetRequested({ payload: { id } }) {
  try {
    yield call([DataApi.apiInstance(), 'deleteFilterPreset'], id);
    yield put(deleteFilterPresetSuccessed(id));
  } catch (e) {
    yield put(deleteFilterPresetFailed(e.errors));
  }
}

export function* handleSaveFilterPresetRequested({ payload: { name, filters, id } }) {
  try {
    const stringifiedFilters = stringifyFiltersForPreset(filters);
    if (id) {
      yield call([DataApi.apiInstance(), 'updateFilterPreset'], { filters: stringifiedFilters, id });
    } else {
      yield call([DataApi.apiInstance(), 'postFilterPreset'], { name, filters: stringifiedFilters });
    }
    yield put(saveFilterPresetSuccessed());
    yield put(changeIsSavePresetOpen(false));
  } catch (e) {
    yield put(saveFilterPresetFailed(e.errors));
  }
}

export default function* watchFilters() {
  yield all([
    takeLatest(FETCH_FILTER_PRESETS_REQUESTED, handleFetchFilterPresetsRequested),
    takeLatest(SAVE_FILTER_PRESET_REQUESTED, handleSaveFilterPresetRequested),
    takeLatest(DELETE_FILTER_PRESET_REQUESTED, handleDeleteFilterPresetRequested),
  ]);
}
