import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { zIndex } from 'ready/styles/theme';
import Conversation from 'scenes/Mobile/scenes/Conversation/Conversation';
import InfoSidebarDrawer from 'scenes/Mobile/scenes/InfoSidebarDrawer/InfoSidebarDrawer';
import FilterSidebarDrawer from 'scenes/Mobile/scenes/FilterSidebarDrawer/FilterSidebarDrawer';
import ConversationListDrawer from 'scenes/Mobile/scenes/ConversationListDrawer/ConversationListDrawer';
import ConversationSidebarDrawer from 'scenes/Mobile/scenes/ConversationSidebarDrawer/ConversationSidebarDrawer';
import useInitDataSetup from 'hooks/useInitDataSetup';
import useIsTabActive from 'hooks/useIsTabActive';
import { matchPropType } from 'common/propTypes';
import { getIsFilterSidebarOpen, getIsConversationListOpen, getIsInfoSidebarOpen } from 'selectors/view';
import { getSelectedConversationId } from 'selectors/conversation';
import TopBarRouter from './components/TopBarRouter';

const drawerZIndexes = {
  FILTER_SIDEBAR: zIndex.drawer[1],
  CONVERSATION_LIST_DRAWER: zIndex.drawer[0],
  INFO_SIDEBAR: zIndex.drawer[1],
};

const Wrapper = styled.div`
  height: 100dvh;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: 'top-bar' 'content';
  overflow-x: hidden;
  overflow-y: auto;
`;

const Mobile = ({ dispatch, match }) => {
  useIsTabActive({ dispatch });
  useInitDataSetup({ dispatch, match, payload: { isFilterSidebarOpen: false, isInfoSidebarOpen: false } });

  return (
    <Wrapper data-qa="Mobile:__wrapper">
      <TopBarRouter />
      <FilterSidebarDrawer zIndex={drawerZIndexes.FILTER_SIDEBAR} />
      <ConversationListDrawer zIndex={drawerZIndexes.CONVERSATION_LIST_DRAWER} />
      <Conversation />
      <InfoSidebarDrawer zIndex={drawerZIndexes.INFO_SIDEBAR} />
      <ConversationSidebarDrawer zIndex={drawerZIndexes.INFO_SIDEBAR} />
    </Wrapper>
  );
};

Mobile.propTypes = {
  match: matchPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFilterSidebarOpen: PropTypes.bool,
  isInfoSidebarOpen: PropTypes.bool,
  isConversationListOpen: PropTypes.bool,
  selectedConversationId: PropTypes.number,
};

const mapStateToProps = state => ({
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
  isInfoSidebarOpen: getIsInfoSidebarOpen(state),
  isConversationListOpen: getIsConversationListOpen(state),
  selectedConversationId: getSelectedConversationId(state),
});

export default connect(mapStateToProps)(Mobile);
