import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fetchRentalRequested } from 'ducks/rental';
import { getRental } from 'selectors/rental';

import { withMemoMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { fullRentalPropTypeWithState, fullRentalPropType } from 'common/propTypes';

import { Text, SearchBar } from 'ready/components';
import {
  CenteredLoadingIndicator,
  CenteredRequestError,
  TabContentWrapper,
  TabWithForm,
  FormRow,
} from '../../components';
import Amenity from './components/Amenity';

import SelectProperty from '../../components/SelectProperty';

const AmenitiesCard = styled.div`
  border-radius: ${props => props.theme.borderRadius.m};
  border: ${props => props.theme.app.border.default};
  box-shadow: ${props => props.theme.boxShadow.s};
  padding: ${props => `${props.theme.spacing.m} ${props.theme.spacing.l}`};
  overflow-y: hidden;
`;

const AmenitiesContent = ({ t, rental, search }) => {
  if (rental.isFetching) return <CenteredLoadingIndicator />;

  if (rental.didInvalidate) return <CenteredRequestError errors={rental.errors} />;

  if (!rental.data) return false;

  return (
    <TabContentWrapper data-qa="AmenitiesWrapper">
      <AmenitiesCard data-qa="AmenitiesCard">
        {Object.keys(rental.data.amenities)
          .sort()
          .map(category => {
            const filteredAmenities = rental.data.amenities[category]
              .filter(({ amenity }) => !search || amenity.title.toLowerCase().includes(search.toLowerCase()))
              .sort((a, b) => a.amenity.title.localeCompare(b.amenity.title));
            if (!filteredAmenities || !filteredAmenities.length) return false;
            return (
              <div key={category}>
                <Text key={category} size={Text.sizes.L} bold>
                  {category}
                </Text>
                <>
                  {filteredAmenities.map((amenity, i) => (
                    <Amenity key={`${amenity.amenity.key}_${i}`} amenity={amenity} t={t} />
                  ))}
                </>
              </div>
            );
          })}
      </AmenitiesCard>
    </TabContentWrapper>
  );
};
AmenitiesContent.propTypes = {
  rental: fullRentalPropTypeWithState.isRequired,
  t: PropTypes.object.isRequired,
};

const Amenities = ({ t, rental, rentals, dispatchFetchRentalRequested }) => {
  const [search, changeSearch] = useState('');
  return (
    <TabWithForm data-qa="Tab:__innerWrapper--amenities">
      <FormRow>
        <SelectProperty
          rentals={rentals}
          rental={rental.data}
          t={t}
          onChange={option => dispatchFetchRentalRequested(option.value)}
        />
        <SearchBar placeholder={t.search} value={search} onChange={e => changeSearch(e.target.value)} />
      </FormRow>
      <AmenitiesContent rental={rental} t={t} search={search} />
    </TabWithForm>
  );
};

Amenities.propTypes = {
  dispatchFetchRentalRequested: PropTypes.func.isRequired,
  rentals: PropTypes.arrayOf(fullRentalPropType).isRequired,
  ...AmenitiesContent.propTypes,
};

const mapStateToProps = state => ({
  rental: getRental(state),
});

const mapDispatchToProps = {
  dispatchFetchRentalRequested: fetchRentalRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMemoMappedTranslations({
    amenitiesTitle: 'INFO_SIDEBAR.AMENITIES.TITLE',
    model: 'INFO_SIDEBAR.AMENITIES.MODEL',
    brand: 'INFO_SIDEBAR.AMENITIES.BRAND',
    location: 'INFO_SIDEBAR.AMENITIES.LOCATION',
    details: 'INFO_SIDEBAR.AMENITIES.DETAILS',
    search: 'INFO_SIDEBAR.AMENITIES.SEARCH',
    selectProperty: 'INFO_SIDEBAR.PROPERTY.SELECT_PROPERTY',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
  }),
)(Amenities);
