import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRental } from 'selectors/rental';

import { TabContentWrapper, ContentWrapper, CenteredLoadingIndicator, CenteredRequestError } from '../../components';

import { withMemoMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { Collapsible, DateRangeCalendar, Button } from 'ready/components';

import { fullRentalPropTypeWithState } from 'common/propTypes';
import { getFutureReservationsDateRanges } from 'utils/serializers';
import { reservationCalendarDateRangeVariations } from 'constants.js';

const CalendarWrapper = styled(ContentWrapper)`
  padding-bottom: ${props => props.theme.spacing.xl};
`;

const Calendar = ({ t, rental }) => {
  if (rental.isFetching) return <CenteredLoadingIndicator />;

  if (rental.didInvalidate) return <CenteredRequestError errors={rental.errors} />;

  if (!rental.data) return false;

  const property = rental.data;
  return (
    <TabContentWrapper data-qa="Tab:__innerWrapper--property">
      <Collapsible title={t.propertyCalendar} data-qa="Tab:Collapsible--propertyCalendar">
        <CalendarWrapper minHeight="35rem">
          <DateRangeCalendar
            dateRanges={getFutureReservationsDateRanges(property.future_reservations)}
            size={DateRangeCalendar.sizes.L}
            dateRangeVariations={reservationCalendarDateRangeVariations}
          />
          <Button href={property.calendar_url} variation={Button.variations.OUTLINE} target="_blank">
            {t.openFullCalendar}
          </Button>
        </CalendarWrapper>
      </Collapsible>
    </TabContentWrapper>
  );
};

Calendar.propTypes = {
  rental: fullRentalPropTypeWithState.isRequired,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  rental: getRental(state),
});

export default compose(
  connect(mapStateToProps),
  withMemoMappedTranslations({
    propertyCalendar: 'INFO_SIDEBAR.CALENDAR.PROPERTY_CALENDAR',
    openFullCalendar: 'INFO_SIDEBAR.CALENDAR.OPEN_FULL_CALENDAR',
  }),
)(Calendar);
