import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'ready/components/Button/Button';
import { keyCodes } from 'constants.js';

export const TextArea = styled.textarea`
  border-radius: ${props => props.theme.borderRadius.m};
  border-color: ${props => props.theme.colors.n500};
  color: ${props => props.theme.colors.dark};
  max-height: 30dvh;
  margin: ${props => `${props.theme.spacing.xxs} 0`};
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.s}`};
  outline: none;
  resize: none;
  width: 100%;
  ${props => props.theme.typography.text.m}
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  margin-top: ${props => props.theme.spacing.s};
`;

const Notes = ({ note, onSubmit, onChange, placeholder, buttonLabel }) => {
  const handleKeyDown = e => {
    e.stopPropagation();
    if ((e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.ENTER) onSubmit();
  };

  return (
    <Container>
      <TextArea
        onKeyDown={handleKeyDown}
        value={note}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        rows="5"
      />
      <Button size={Button.sizes.S} onClick={onSubmit}>
        {buttonLabel}
      </Button>
    </Container>
  );
};

Notes.propTypes = {
  note: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default Notes;
