import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../../Icon/Icon';
import { Text } from 'ready/components/typography';
import { sizePropType } from 'ready/utils/propTypes';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${props => `${props.theme.spacing.xs} 0`};
  user-select: none;
`;

const ClickableIcon = styled(Icon)`
  cursor: pointer;
  pointer-events: auto;
`;

const Header = ({ children, onClickPrev, onClickNext, size }) => (
  <Container>
    <ClickableIcon icon={Icon.icons.ARROW_LEFT} onClick={onClickPrev} />
    <Text size={size} element="p" bold>
      {children}
    </Text>
    <ClickableIcon icon={Icon.icons.ARROW_RIGHT} onClick={onClickNext} />
  </Container>
);

Header.sizes = Text.sizes;

Header.propTypes = {
  size: sizePropType(Header.sizes),
  children: PropTypes.string.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
};

Header.defaultProps = {
  size: Text.sizes.M,
};

export default Header;
