import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  REQUEST_GOOGLE_SIGNIN_SUCCESSED,
  fetchUserSuccessed,
  fetchUserRequested,
  fetchUserFailed,
  FETCH_USER_REQUESTED,
  FETCH_USER_BY_TOKEN_REQUESTED,
  fetchUserByTokenFailed,
  LOGOUT_USER_REQUESTED,
  logoutUserSuccessed,
} from 'ducks/user';
import history from 'browserHistory';
import { getUrl } from 'selectors/view';
import { localStorageKeys, paths } from 'constants.js';
import DataApi from 'api/DataApi';
import TaskRouterApi from 'api/TaskRouterApi';
import { localStorageProxy } from 'utils/localStorage';

function setSentryUser(user) {
  if (user) {
    Sentry.setUser({ username: user.username, id: user.id });
  }
}

export function* processUserWithToken(token) {
  yield call([DataApi.apiInstance(), 'setDefaultAuthorization'], `token ${token}`);
  yield call([TaskRouterApi.apiInstance(), 'setDefaultAuthorization'], `token ${token}`);

  const user = yield call([DataApi.apiInstance(), 'getUserByToken']);
  yield put(fetchUserSuccessed(user));
  setSentryUser(user);
}

export function* handleFetchUserRequested({ payload: { user: loginUser } }) {
  try {
    const { token } = yield call([DataApi.apiInstance(), 'getTokenByLogin'], loginUser);
    yield call([localStorageProxy, 'setItem'], localStorageKeys.TOKEN, token);
    yield call(processUserWithToken, token);

    const url = yield select(getUrl);
    yield call([history, 'push'], url);
  } catch (e) {
    yield put(fetchUserFailed(e.errors));
  }
}

export function* handleFetchUserByTokenRequested() {
  try {
    const token = localStorageProxy.getItem(localStorageKeys.TOKEN);
    if (!token) return yield put(fetchUserByTokenFailed());

    yield call(processUserWithToken, token);
    const url = yield select(getUrl);
    yield call([history, 'replace'], url);
  } catch (e) {
    yield call([DataApi.apiInstance(), 'setDefaultAuthorization']);
    yield call([localStorageProxy, 'removeItem'], localStorageKeys.TOKEN);
    yield put(fetchUserByTokenFailed(e.errors));
  }
}

export function* handleLogoutUserRequested() {
  yield call([DataApi.apiInstance(), 'setDefaultAuthorization']);
  yield call([localStorageProxy, 'removeItem'], localStorageKeys.TOKEN);
  yield put(logoutUserSuccessed());
  yield call([history, 'push'], paths.LOGIN);
}

export function* handleGoogleLoginSuccessed({ payload: { user: googleUser } }) {
  const profile = googleUser.getBasicProfile();

  yield put(fetchUserRequested({ email: profile.getEmail() }));
}

export default function* watchUser() {
  yield all([
    takeLatest(FETCH_USER_REQUESTED, handleFetchUserRequested),
    takeLatest(FETCH_USER_BY_TOKEN_REQUESTED, handleFetchUserByTokenRequested),
    takeLatest(LOGOUT_USER_REQUESTED, handleLogoutUserRequested),
    takeLatest(REQUEST_GOOGLE_SIGNIN_SUCCESSED, handleFetchUserRequested),
    takeLatest(REQUEST_GOOGLE_SIGNIN_SUCCESSED, handleGoogleLoginSuccessed),
  ]);
}
