import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fullRentalPropType, officePoliciesPropType } from 'common/propTypes';

import { Collapsible } from 'ready/components';
import { ContentWrapper, Paragraph } from 'scenes/shared/InfoSidebar/components';
import ContentWithLabel from 'components/ContentWithLabel/ContentWithLabel';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 2.4rem;
`;

const SecurityDeposit = ({ t, rental, officePolicies }) => (
  <Collapsible title={t.securityDepositTitle} data-qa="Tab:Collapsible--securityDeposit" collapsed>
    <ContentWrapper minHeight="0">
      {officePolicies.security_deposit_guest_id_process && (
        <StyledParagraph
          element="div"
          dangerouslySetInnerHTML={{
            __html: officePolicies.security_deposit_guest_id_process,
          }}
        />
      )}
      <ContentWithLabel label={t.airbnbGuestIdPolicy}>{rental.office.guest_id_airbnb_policy}</ContentWithLabel>
      <ContentWithLabel label={t.guestIdReview}>
        {rental.office.reviewed_id_as_trigger_for_sci ? t.yes : t.no}
      </ContentWithLabel>
    </ContentWrapper>
  </Collapsible>
);

SecurityDeposit.propTypes = {
  t: PropTypes.shape({
    securityDepositTitle: PropTypes.string.isRequired,
    airbnbGuestIdPolicy: PropTypes.string.isRequired,
    guestIdReview: PropTypes.string.isRequired,
    yes: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
  }),
  rental: fullRentalPropType,
  officePolicies: officePoliciesPropType,
};

export default SecurityDeposit;
