import moment from 'moment';
import { TEXT } from 'translations';
import { SHORT_DATE_FORMAT } from 'ready/utils/constants';
import { reservationTypes } from 'constants.js';
import { fromJS } from 'immutable';

export const mapStringToSelectOptionObj = string => ({ value: string, label: string });
export const mapArrayToSelectOptionObj = array => ({ value: array[0], label: array[1] });

export const trueOrUndefined = boolean => boolean || undefined;

const _propertyNameIncludesId = rental => rental.name.indexOf(rental.id) > -1;
export const getRentalName = (rental, fallback = TEXT.SHARED.UNKNOWN) => {
  if (!rental) return fallback;
  if (!rental.name) return rental.id;
  if (_propertyNameIncludesId(rental)) return rental.name;
  return `${rental.id} - ${rental.name}`;
};

export const getAirbnbEmail = booking => booking.airbnb_account && booking.airbnb_account.email;
export const getAirbnbPassword = booking => booking.airbnb_account && booking.airbnb_account.password;

export const getBookingCheckInDate = ({ reservations, inquiries }, fallback) => {
  if ((!reservations[0] || !reservations[0].checkin_date) && (!inquiries[0] || !inquiries[0].checkin_date))
    return fallback;
  return moment((reservations[0] || inquiries[0]).checkin_date).format(SHORT_DATE_FORMAT);
};

export const getBookingCheckOutDate = ({ reservations, inquiries }, fallback) => {
  if ((!reservations[0] || !reservations[0].checkout_date) && (!inquiries[0] || !inquiries[0].checkout_date))
    return fallback;
  return moment((reservations[0] || inquiries[0]).checkout_date).format(SHORT_DATE_FORMAT);
};

export const getRentalArea = rental => rental.surface_area && `${rental.surface_area}m²`;

export const getRentalOfficeName = rental => rental.office && rental.office.name;

export const getFutureReservationsDateRanges = futureReservations =>
  (!futureReservations && []) ||
  futureReservations
    .filter(({ type_reservation }) => [reservationTypes.CLASSIC, reservationTypes.OWNER].includes(type_reservation))
    .map(({ checkin_date, checkout_date, type_reservation }) => ({
      from: checkin_date,
      to: checkout_date,
      variation: type_reservation,
    }));
/**
 * Get rental which is the first item in the conversation rentals
 * return null if no booking or no rental
 * @param {bookingPropType} booking
 */
export const getBookingRental = booking => (booking && booking.rental) || null;
/**
 * Get booking (inquiry) pre approval instructions
 * This is done because it is not intuitive that pre approval instructions is `indication_pre_approval`
 * @param {bookingPropType} booking
 */
export const getBookingPreApprovalInstructions = booking => {
  const rental = getBookingRental(booking) || {};
  if (booking && booking.is_mid_term && rental.midterm_indication_pre_approval) {
    return rental.midterm_indication_pre_approval;
  }
  return rental.indication_pre_approval;
};
/**
 * Get booking special instructions
 * * This is done because it is not intuitive that pre special instructions is `indication_mailbox_bnblord`
 * @param {bookingPropType} booking
 */
export const getBookingSpecialInstructions = booking => {
  const rental = getBookingRental(booking) || {};
  if (booking && booking.is_mid_term && rental.midterm_indication_mailbox_bnblord) {
    return rental.midterm_indication_mailbox_bnblord;
  }
  return rental.indication_mailbox_bnblord;
};
export const getRentalCleaningFees = booking => (getBookingRental(booking) || {}).cleaning_fees;

export const mapBlacklistReasons = ({ blacklists = [] }) =>
  fromJS(blacklists)
    .map(blacklist => blacklist.get('reasons'))
    .flatten()
    .join(', ');

export const getBookingBlacklistReasons = booking => mapBlacklistReasons(booking || {});

export const getBookingCheckInMission = booking => (booking || {}).check_in_mission;

export const getBookingCheckInDelay = booking => (getBookingCheckInMission(booking) || {}).check_in_delay;

export const getBookingCancellationRequest = booking => ((booking || {}).cancellation_requests || [])[0];

export const getPropertyManagerName = conversation => {
  /**
   * Get property manager name from conversation's rentals
   * If there are multiple property managers, return null
   * @param {conversationPropType} conversation
   */
  const propertyManagers = fromJS(conversation || {})
    .get('rentals', fromJS([]))
    .map(rental => rental.getIn(['office', 'property_manager', 'name']))
    .filter(value => value)
    .toSet();
  return propertyManagers.size === 1 ? propertyManagers.first() : null;
};

export const getDefaultOutgoingPhoneNumber = twilioPhoneNumbers => {
  if (twilioPhoneNumbers.length) {
    return twilioPhoneNumbers[0].phone;
  }
  return '';
};
