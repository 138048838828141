import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { sizes } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';
import { iconSize } from 'ready/styles/theme';

const { M } = sizes;
const avatarSizeVariations = {
  [M]: iconSize.xl,
};

const withFallbackText = ({ fallback, imageUrl, size, theme }) =>
  !imageUrl &&
  fallback &&
  css`
  &::after {
    content: '${fallback}';
    text-transform: uppercase;
    display: flex;
    height: ${avatarSizeVariations[size]};
    width: ${avatarSizeVariations[size]};
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    border-radius: 50%;
    overflow: hidden;
    ${theme.typography.text.l};
  }`;

const Avatar = styled.span`
  height: ${props => avatarSizeVariations[props.size]};
  width: ${props => avatarSizeVariations[props.size]};
  display: inline-block;
  background-color: ${props => props.theme.colors.n500};
  border-radius: 50%;
  background-image: ${props => `url(${props.imageUrl})`};
  background-size: cover;
  ${withFallbackText};
`;

Avatar.sizes = { M };

Avatar.propTypes = {
  size: sizePropType(Avatar.sizes),
  imageUrl: PropTypes.string,
  fallback: PropTypes.string,
};

Avatar.defaultProps = {
  size: Avatar.sizes.M,
};

export default Avatar;
