import { all, takeLatest, put, call } from 'redux-saga/effects';
import DataApi from 'api/DataApi';
import {
  postSpecialOfferFailed,
  POST_SPECIAL_OFFER_REQUESTED,
  DELETE_SPECIAL_OFFER_REQUESTED,
  deleteSpecialOfferFailed,
  deleteSpecialOfferSuccessed,
  UPDATE_SPECIAL_OFFER_REQUESTED,
  postSpecialOfferSuccessed,
  FETCH_SPECIAL_OFFER_PRICE_REQUESTED,
  fetchSpecialOfferPriceSuccessed,
  fetchSpecialOfferPriceFailed,
} from 'ducks/specialOffer';
import { closeConversationDrawer } from 'ducks/conversationDrawer';

export function* handlePostSpecialOfferRequested({ payload: { payload } }) {
  try {
    const specialOffer = yield call([DataApi.apiInstance(), 'postSpecialOffer'], payload);
    yield put(postSpecialOfferSuccessed(specialOffer));
    yield put(closeConversationDrawer());
  } catch (e) {
    yield put(postSpecialOfferFailed(e.errors));
  }
}

export function* handleDeleteSpecialOfferRequested({ payload: { id } }) {
  try {
    yield call([DataApi.apiInstance(), 'withdrawSpecialOffer'], id);
    yield put(deleteSpecialOfferSuccessed());
  } catch (e) {
    yield put(deleteSpecialOfferFailed(e.errors));
  }
}

/**
 * At third party platforms it is not possible to update an offer, thus
 * it is needed to delete and create a new one
 * @param {Object} payload special offer payload
 *  @attributes {Number} id special offer id
 *  @attributes {Number} inquiry inquiry id
 *  @attributes {String} price special offer price
 */
export function* handleUpdateSpecialOfferRequested({ payload: { id, payload } }) {
  try {
    yield call([DataApi.apiInstance(), 'deleteSpecialOffer'], id);
    const specialOffer = yield call([DataApi.apiInstance(), 'postSpecialOffer'], payload);
    yield put(postSpecialOfferSuccessed(specialOffer));
  } catch (e) {
    yield put(postSpecialOfferFailed(e.errors));
  }
}

export function* handleFetchSpecialOfferPriceRequested({ payload: { id } }) {
  try {
    const response = yield call([DataApi.apiInstance(), 'retrieveSpecialOfferPrice'], id);
    yield put(
      fetchSpecialOfferPriceSuccessed(
        response.rental_income,
        response.cleaning_fee,
        response.extra_fee,
        response.total_price,
      ),
    );
  } catch (e) {
    yield put(fetchSpecialOfferPriceFailed(e.errors));
  }
}

export default function* watchInquiry() {
  yield all([
    takeLatest(POST_SPECIAL_OFFER_REQUESTED, handlePostSpecialOfferRequested),
    takeLatest(DELETE_SPECIAL_OFFER_REQUESTED, handleDeleteSpecialOfferRequested),
    takeLatest(UPDATE_SPECIAL_OFFER_REQUESTED, handleUpdateSpecialOfferRequested),
    takeLatest(FETCH_SPECIAL_OFFER_PRICE_REQUESTED, handleFetchSpecialOfferPriceRequested),
  ]);
}
