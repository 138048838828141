import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'ready/components/typography';

const StyledText = styled(Text)`
  &[disabled] {
    opacity: 0.5;
  }
`;

const withInlineEdition = (Component, serializer = value => value) => {
  const ComposedComponent = ({
    value,
    elRef,
    placeholder,
    onBlur,
    textProps,
    disabled,
    'data-qa': dataQA,
    ...props
  }) => {
    const [editing, setEditing] = useState(false);
    const [text, setText] = useState(serializer(value));

    useEffect(() => {
      setText(serializer(value));
    }, [value]);

    const handleBlur = e => {
      setEditing(false);
      onBlur(e);
    };

    return editing ? (
      <Component
        ref={elRef}
        autoFocus
        placeholder={placeholder}
        value={value}
        onBlur={handleBlur}
        disabled={disabled}
        data-qa={dataQA}
        {...props}
      />
    ) : (
      <StyledText
        data-qa={dataQA}
        tabIndex="0"
        onFocus={() => !disabled && setEditing(true)}
        disabled={disabled}
        {...textProps}
      >
        {text || placeholder}
      </StyledText>
    );
  };

  ComposedComponent.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    elRef: PropTypes.shape({ current: PropTypes.object }),
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    textClassName: PropTypes.string,
    textProps: PropTypes.object,
    disabled: PropTypes.bool,
    'data-qa': PropTypes.string,
  };

  ComposedComponent.defaultProps = {
    'data-qa': 'HOC:withInlineEdition',
    elRef: null,
    onBlur: () => {},
  };

  return ComposedComponent;
};

export default withInlineEdition;
