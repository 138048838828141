import { fromJS } from 'immutable';

export const FETCH_RENTAL_REQUESTED = 'FETCH_RENTAL_REQUESTED';
export const fetchRentalRequested = id => ({ type: FETCH_RENTAL_REQUESTED, payload: { id } });

export const FETCH_RENTAL_SUCCESSED = 'FETCH_RENTAL_SUCCESSED';
export const fetchRentalSuccessed = rental => ({ type: FETCH_RENTAL_SUCCESSED, payload: { rental } });

export const FETCH_RENTAL_FAILED = 'FETCH_RENTAL_FAILED';
export const fetchRentalFailed = errors => ({ type: FETCH_RENTAL_FAILED, payload: { errors } });

export const UPDATE_RENTAL_REQUESTED = 'UPDATE_RENTAL_REQUESTED';
export const updateRentalRequested = (id, payload, context) => ({
  type: UPDATE_RENTAL_REQUESTED,
  payload: { id, payload, context },
});

export const UPDATE_RENTAL_SUCCESSED = 'UPDATE_RENTAL_SUCCESSED';
export const updateRentalSuccessed = booking => ({
  type: UPDATE_RENTAL_SUCCESSED,
  payload: { booking },
});

export const UPDATE_RENTAL_FAILED = 'UPDATE_RENTAL_FAILED';
export const updateRentalFailed = (errors, context) => ({
  type: UPDATE_RENTAL_FAILED,
  payload: { errors, context },
});

export const initialState = fromJS({
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  data: null,
  errors: {
    context: null,
    updating: false,
    fetching: false,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RENTAL_REQUESTED:
      return state
        .set('isFetching', true)
        .set('didInvalidate', false)
        .set('errors', initialState.get('errors'));

    case UPDATE_RENTAL_REQUESTED:
      return state.set('errors', initialState.get('errors').set('context', payload.context)).set('isUpdating', true);

    case FETCH_RENTAL_SUCCESSED:
      return state.set('isFetching', false).set('data', fromJS(payload.rental));

    case UPDATE_RENTAL_SUCCESSED:
      return state.set('isUpdating', false).set('data', fromJS(payload.rental));

    case FETCH_RENTAL_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .update('errors', value => value.set('fetching', true).merge(payload.errors));

    case UPDATE_RENTAL_FAILED:
      return state
        .set('isUpdating', false)
        .update('errors', value => value.set('updating', true).merge(payload.errors));

    default:
      return state;
  }
};
