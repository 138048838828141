import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

/**
 * Create selector that only updates if something related to data changed
 * - fetching
 * - invalidated
 * - pagination
 */
const createStateEqualSelector = createSelectorCreator(defaultMemoize, (previous, next) => {
  if (previous.get('data').size !== next.get('data').size) return false;

  const isEqual = previous
    .delete('data')
    .delete('search')
    .equals(next.delete('data').delete('search'));
  return isEqual;
});
/**
 * Create selector that only updates if search term changes
 */
const createSearchEqualSelector = createSelectorCreator(
  defaultMemoize,
  (previous, next) => previous.getIn(['search', 'term']) === next.getIn(['search', 'term']),
);
/**
 * Create selector that only updates if sort key changes
 */
const createSortEqualSelector = createSelectorCreator(
  defaultMemoize,
  (previous, next) => previous.getIn(['sort', 'key']) === next.getIn(['sort', 'key']),
);

const _getConversations = state => state.conversations;
/**
 * Return conversations main attributes related to data
 */
export const getConversations = createStateEqualSelector([_getConversations], conversations =>
  conversations
    .delete('sort')
    .delete('search')
    .toJS(),
);

export const getNextPageLink = createStateEqualSelector([_getConversations], conversations =>
  conversations.getIn(['page', 'nextLink']),
);

const _getConversationsData = createSelector(
  [_getConversations],
  conversations => conversations.get('data'),
);

const _getConversationById = createSelector(
  [(_, id) => id, _getConversationsData],
  (id, conversations) => conversations.find(conversation => conversation.get('id') === id),
);
export const getConversationById = createSelector(
  [_getConversationById],
  conversation => (conversation && conversation.toJS()) || null,
);

export const getConversationsSortKey = createSortEqualSelector([_getConversations], conversations =>
  conversations.getIn(['sort', 'key']),
);

export const getConversationsSearchTerm = createSearchEqualSelector([_getConversations], conversations =>
  conversations.getIn(['search', 'term']),
);
