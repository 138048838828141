import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { dateTimePropType } from 'ready/utils/propTypes';
import { Text } from 'ready/components/typography';
import { Icon } from 'ready/components';
import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';

const Wrapper = styled(FullWidthContentWithLabel)`
  background: ${props => props.theme.colors.r100};
  border-radius: ${props => props.theme.borderRadius.m};
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.s}`};
  margin-left: ${props => `-${props.theme.spacing.s}`};
  margin-right: ${props => `-${props.theme.spacing.s}`};
  width: ${props => `calc(100% + ${props.theme.spacing.s} * 2)`};
`;

const FlexWrapper = styled.div`
  display: flex;
  column-gap: ${props => props.theme.spacing.xxs};
`;

const CheckInDelay = ({ datetime, reason, label }) => {
  const date = moment(datetime).format('D MMM');
  const time = moment(datetime).format('HH:mm');

  return (
    <Wrapper
      data-qa="CheckInDelay:__wrapper"
      label={
        <>
          <Icon icon={Icon.icons.BAN} color={Icon.colors.error} /> {label}
        </>
      }
    >
      <FlexWrapper>
        <Text data-qa="CheckInDelay:Date">{date}</Text>
        <Text bold data-qa="CheckInDelay:Time">
          {time}
        </Text>
      </FlexWrapper>
      <Text data-qa="CheckInDelay:Reason">{reason}</Text>
    </Wrapper>
  );
};

CheckInDelay.propTypes = {
  className: PropTypes.string,
  datetime: dateTimePropType.isRequired,
  reason: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckInDelay;
