import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getSavedReplies } from 'selectors/savedReplies';
import { savedReplyAddedToMessage } from 'ducks/savedReplies';

import { Icon, Button, Text, TruncatedText } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { savedRepliesPropTypeWithState } from 'common/propTypes';
import { changeSavedRepliesRoute, replaceSavedRepliesRoute } from 'browserHistory';

const Container = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: 'icon title add' 'content content content';
  grid-column-gap: ${props => props.theme.spacing.xs};
  grid-row-gap: ${props => props.theme.spacing.xs};
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto minmax(0, 1fr);
  padding: ${props => props.theme.spacing.xs};
  ${props => props.theme.app.card};
`;

const ArrowIcon = styled(Icon)`
  cursor: pointer;
  grid-area: icon;
`;

const Title = styled(TruncatedText)`
  grid-area: title;
`;

const AddButton = styled(Button)`
  grid-area: add;
`;

const Content = styled(Text)`
  white-space: pre-wrap;
`;

const ContentWrapper = styled.div`
  grid-area: content;
  height: 100%;
  overflow-y: auto;
`;
const ViewItem = ({ savedReplies, t, dispatchSavedReplyAddedToMessage, match }) => {
  const savedReply = useMemo(() => savedReplies.data.find(sr => sr.id === Number(match.params.savedReplyId)), [
    savedReplies.data,
  ]);
  if (!savedReply) {
    replaceSavedRepliesRoute();
    return false;
  }

  return (
    <Container data-qa="SavedRepliesViewItem:__container">
      <ArrowIcon
        data-qa="SavedRepliesViewItem:__arrowIcon"
        icon={Icon.icons.ARROW_LEFT}
        onClick={() => changeSavedRepliesRoute()}
      />
      <Title bold size={TruncatedText.sizes.L}>
        {savedReply.name}
      </Title>
      <AddButton size={Button.sizes.S} onClick={() => dispatchSavedReplyAddedToMessage(savedReply)}>
        {t.add}
      </AddButton>
      <ContentWrapper>
        <Content element="p">{savedReply.templated}</Content>
      </ContentWrapper>
    </Container>
  );
};

ViewItem.propTypes = {
  t: PropTypes.shape({
    add: PropTypes.string.isRequired,
  }).isRequired,
  dispatchSavedReplyAddedToMessage: PropTypes.func.isRequired,
  savedReplies: savedRepliesPropTypeWithState.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  savedReplies: getSavedReplies(state),
});

const mapDispatchToProps = {
  dispatchSavedReplyAddedToMessage: savedReplyAddedToMessage,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    add: 'SAVED_REPLIES.LIST.ITEM.ADD',
  }),
)(ViewItem);
