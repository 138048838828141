import { createSelector } from 'reselect';

const _getMessages = state => state.messages;
export const getMessages = createSelector(
  [_getMessages],
  messages => (messages || Map()).toJS(),
);

export const getNextPageLink = createSelector(
  [_getMessages],
  messages => messages.getIn(['page', 'nextLink']),
);

export const _getMessagesData = state => state.messages.get('data');
export const getMessagesData = createSelector(
  [_getMessagesData],
  messages => messages.toJS(),
);
/**
 * Get last message created.
 * As it is sorted desc the first one is the last.
 */
export const getLastMessage = createSelector(
  [_getMessagesData],
  messages => (messages.size ? messages.first().toJS() : null),
);
