import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsInfoSidebarOpen } from 'selectors/view';
import { changeIsInfoSidebarOpen } from 'ducks/view';

import Drawer, { Panel } from 'ready/components/Drawer/Drawer';
import InfoSidebar from 'scenes/shared/InfoSidebar/InfoSidebar';

const InfoSidebarDrawer = styled(Drawer)`
  grid-area: content;
  position: relative;
`;

const InfoSidebarPanel = styled(Panel)`
  width: 28rem;
`;

const MobileInfoSidebarScene = ({ zIndex, isInfoSidebarOpen, dispatchChangeIsInfoSidebarOpen }) => {
  return (
    <InfoSidebarDrawer
      handleClose={() => dispatchChangeIsInfoSidebarOpen(false)}
      hasOverlay
      from={Drawer.from.RIGHT}
      zIndex={zIndex}
      show={isInfoSidebarOpen}
      renderPanel={props => (
        <InfoSidebarPanel {...props}>
          <InfoSidebar />
        </InfoSidebarPanel>
      )}
    />
  );
};

MobileInfoSidebarScene.propTypes = {
  isInfoSidebarOpen: PropTypes.bool,
  zIndex: PropTypes.number,
  dispatchChangeIsInfoSidebarOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isInfoSidebarOpen: getIsInfoSidebarOpen(state),
});

const mapDispatchToProps = {
  dispatchChangeIsInfoSidebarOpen: changeIsInfoSidebarOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileInfoSidebarScene);
