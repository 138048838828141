import { all, takeLatest, put, select, take, call, debounce } from 'redux-saga/effects';

import {
  fetchConversationsRequested,
  fetchConversationsSuccessed,
  updateConversationsConversationSuccessed,
} from 'ducks/conversations';
import {
  FETCH_CONVERSATION_REQUESTED,
  FETCH_CONVERSATION_SUCCESSED,
  FETCH_INIT_CONVERSATION_SUCCESSED,
  FETCH_INIT_CONVERSATION_FAILED,
  fetchInitConversationRequested,
  fetchConversationRequested,
  UPDATE_CONVERSATION_SUCCESSED,
  fetchConversationSuccessed,
  updateConversationRequested,
} from 'ducks/conversation';
import { UPDATE_GUEST_SUCCESSED, FETCH_GUEST_SUCCESSED } from 'ducks/guest';
import { fetchMessagesRequested, fetchMessagesSuccessed } from 'ducks/messages';
import { setupMessage, SEND_MESSAGE_SUCCESSED, SEND_MESSAGE_REQUESTED, appendMessageContent } from 'ducks/message';
import { SETUP_INIT_DATA } from 'ducks/view';
import {
  fetchCategoriesRequested,
  startAutoRefreshFilters,
  changeSelectedFilter,
  CHANGE_AND_APPLY_SELECTED_FILTER,
  APPLY_SELECTED_FILTERS,
} from 'ducks/filters';
import {
  fetchInitBookingRequested,
  fetchBookingRequested,
  fetchInquiryRequested,
  FETCH_BOOKING_SUCCESSED,
} from 'ducks/booking';
import { fetchGuestRequested } from 'ducks/guest';
import { DELETE_SPECIAL_OFFER_SUCCESSED, POST_SPECIAL_OFFER_SUCCESSED } from 'ducks/specialOffer';
import { fetchTwilioTokensRequested, setCallCenterConversationIfAllowed } from 'ducks/callCenter';
import {
  fetchSavedRepliesRequested,
  SAVED_REPLY_ADDED_TO_MESSAGE,
  updateSavedReplyClickCount,
} from 'ducks/savedReplies';
import { FETCH_RESERVATION_ALTERATION_SUCCESSED } from 'ducks/reservationAlteration';

import { getSelectedConversationId, getConversationData } from 'selectors/conversation';
import { getConversations } from 'selectors/conversations';
import { getFiltersData } from 'selectors/filters';
import { getUserFeatureFlags } from 'selectors/user';
import { handleFetchCategoriesRequested, handleFetchConversationCountRequested } from './filters';
import { getBooking } from 'selectors/booking';
import { getReservationAlteration } from 'selectors/reservationAlteration';

import {
  conversationStatuses,
  paths,
  messageChannels,
  featureFlags,
  filterKeys,
  bookingSelectTypes,
} from 'constants.js';
import { conversationStatusPayload } from 'utils/deserializers';
import history, { changeConversationRoute, changeFilterParams } from 'browserHistory';
import { getDefaultRentalIdFromConversation, isReservation } from 'utils/businessLogic';
import {
  fetchAutomatedMessagesRequested,
  fetchAutomatedMessagesSuccessed,
  clearAutomatedMessages,
} from 'ducks/automatedMessages';
import { fetchRentalRequested } from 'ducks/rental';
import { fetchSmartDevicesRequested } from 'ducks/smartDevices';
import { fetchAttachmentsRequested, changeAttachmentsModalVisibility } from 'ducks/attachments';
import { closeConversationDrawer } from 'ducks/conversationDrawer';

export function* decoupleFetchInitConversation() {
  /*
   * Decouple fetch conversation init successed or failed into fetch
   * conversations because there might be a selected conversation
   * from outside of current page that we might have to inject
   * into conversations list. It is done this way in order to avoid
   * mapping full conversation list when loading more
   * TODO: re-evaluate if it is possible to improve this logic
   */
  yield put(fetchConversationsRequested());
}

export function* decoupleFetchConversationSuccessed({ payload: { conversation } }) {
  const _conversation = {
    channels: [messageChannels.INTERNAL.value],
    reservations: [],
    inquiries: [],
    ...conversation,
  };

  const message = { channels: _conversation.channels, conversationId: _conversation.id, content: '' };
  const conversationId = (_conversation.id && _conversation) || null;
  const guestId = _conversation.guest && _conversation.guest.id;

  yield all([
    put(setupMessage(message)),
    // TODO: re-evaluate if this could be changed to
    // fetchBookingRequested(_conversation.id)
    put(fetchInitBookingRequested(_conversation)),
    put(fetchMessagesRequested()),
    put(fetchGuestRequested(guestId)),
    put(setCallCenterConversationIfAllowed(conversationId)),
    put(fetchAttachmentsRequested(_conversation.id)),
  ]);
}

export function* decoupleFetchGuestSuccessed() {
  yield put(fetchSavedRepliesRequested());
}

export function* decoupleSetupInitData({ payload }) {
  const enableFeatures = yield select(getUserFeatureFlags);

  /**
   * Fetch and put categories to store.state.filters, plus return categories
   * With categories loaded it is possible to preset it to url if none yet and
   * put to store.state.filters.data too
   */
  const categories = yield call(handleFetchCategoriesRequested);
  yield call(handleFetchConversationCountRequested);

  if (categories && categories.length && !payload.categoryId && !payload.conversationId) {
    const categoryId = categories[0].id;
    yield put(changeSelectedFilter(filterKeys.CATEGORY_ID, categoryId));
    yield call([history, 'replace'], `${paths.APP}?${filterKeys.CATEGORY_ID}=${categoryId}`);
  }

  if (process.env.REACT_APP_HOST_ENV === 'production') yield put(startAutoRefreshFilters());

  if (process.env.REACT_APP_HOST_ENV !== 'test' && enableFeatures.includes(featureFlags.TWILIO_CALL_CENTER))
    yield put(fetchTwilioTokensRequested());

  if (!payload.conversationId) return yield put(fetchConversationsRequested());

  yield put(fetchInitConversationRequested(payload.conversationId));
}

export function* decoupleFiltersChanged() {
  const id = yield select(getSelectedConversationId);
  const selectedFilters = yield select(getFiltersData);
  yield all([
    call(changeFilterParams, selectedFilters),
    put(fetchConversationRequested(id, { force: true })),
    put(fetchConversationsRequested()),
    call(handleFetchConversationCountRequested),
    put(fetchMessagesSuccessed({ results: [] })),
  ]);
}

/**
 * Decouple update conversation into:
 * if options.removeConversationFromList is true:
 *  - remove conversation updated from conversations list
 *  - change url route
 *  - change conversation selected to the next one on the list
 * always:
 *  - re-fetch categories counters
 * @param {Object} conversation updated conversation
 * @param {Object} options options for payload handling
 * @param {boolean} options.removeConversationFromList
 *     should conversation be removed from list after update (default: true)
 */
export function* decoupleUpdateConversationSuccessed({ payload: { conversation, options } }) {
  if (options.removeConversationFromList) {
    const conversations = yield select(getConversations);
    let index = null;
    // get id of updated conversation and remove it from conversation data list
    const nextConversations = conversations.data.filter((c, k) => {
      if (c.id === conversation.id) {
        index = k;
        return false;
      }
      return true;
    });

    yield put(
      fetchConversationsSuccessed({
        next: conversations.page.nextLink,
        previous: conversations.page.previousLink,
        results: nextConversations,
      }),
    );

    // select conversation right after the updated one if inside conversation data list
    if (index !== null && index < nextConversations.length) {
      yield all([
        call(changeConversationRoute, nextConversations[index].id),
        put(fetchConversationSuccessed(nextConversations[index])),
      ]);
    } else {
      yield all([call([history, 'push'], `${paths.APP}${history.location.search}`), put(fetchConversationSuccessed())]);
    }
  } else {
    yield put(updateConversationsConversationSuccessed(conversation));
  }

  yield put(fetchCategoriesRequested({ silent: true }));
  yield call(handleFetchConversationCountRequested);
}

export function* decoupleUpdateGuestSuccessed() {
  yield put(fetchSavedRepliesRequested());
}

export function* decoupleSavedReplyAddedToMessage({ payload: { savedReply } }) {
  yield all([put(appendMessageContent(savedReply.templated)), put(updateSavedReplyClickCount(savedReply.id))]);
}

export function* decoupleSendMessageRequested({ payload: { close } }) {
  while (close) {
    yield take(SEND_MESSAGE_SUCCESSED);
    yield put(updateConversationRequested(conversationStatusPayload(conversationStatuses.CLOSED)));
  }
}

export function* decoupleUpdateSpecialOfferSuccessed() {
  const booking = yield select(getBooking);
  yield put(fetchInquiryRequested(booking.data.id, { silent: true }));
  yield put(fetchMessagesRequested({ silent: true }));
}

export function* decoupleFetchConversationRequested() {
  yield put(clearAutomatedMessages());
  yield put(changeAttachmentsModalVisibility(false));
  yield put(closeConversationDrawer());
}

export function* decoupleFetchBookingSuccessed({ payload: { booking } }) {
  const conversation = yield select(getConversationData);
  const rental = (booking || {}).rental || (yield call(getDefaultRentalIdFromConversation, conversation));
  yield all([
    isReservation(booking)
      ? put(fetchAutomatedMessagesRequested(booking.id))
      : put(fetchAutomatedMessagesSuccessed([])),
    rental && put(fetchRentalRequested(rental.id)),
    rental && put(fetchSmartDevicesRequested(rental.id)),
  ]);
}

export function* decoupleFetchReservationAlterationSuccessed() {
  const booking = yield select(getBooking);
  const reservationAlteration = yield select(getReservationAlteration);
  if (reservationAlteration.data.reservation && booking.data.id !== reservationAlteration.data.reservation) {
    yield put(
      fetchBookingRequested({ type: bookingSelectTypes.RESERVATION, id: reservationAlteration.data.reservation }),
    );
  }
}

export default function* watchCouplingActions() {
  yield all([
    takeLatest(SETUP_INIT_DATA, decoupleSetupInitData),
    takeLatest([FETCH_INIT_CONVERSATION_SUCCESSED, FETCH_INIT_CONVERSATION_FAILED], decoupleFetchInitConversation),
    takeLatest([FETCH_INIT_CONVERSATION_SUCCESSED, FETCH_CONVERSATION_SUCCESSED], decoupleFetchConversationSuccessed),
    takeLatest([CHANGE_AND_APPLY_SELECTED_FILTER, APPLY_SELECTED_FILTERS], decoupleFiltersChanged),
    takeLatest(UPDATE_CONVERSATION_SUCCESSED, decoupleUpdateConversationSuccessed),
    takeLatest(SEND_MESSAGE_REQUESTED, decoupleSendMessageRequested),
    takeLatest(FETCH_GUEST_SUCCESSED, decoupleFetchGuestSuccessed),
    takeLatest(UPDATE_GUEST_SUCCESSED, decoupleUpdateGuestSuccessed),
    takeLatest(FETCH_CONVERSATION_REQUESTED, decoupleFetchConversationRequested),
    takeLatest(FETCH_BOOKING_SUCCESSED, decoupleFetchBookingSuccessed),
    takeLatest(SAVED_REPLY_ADDED_TO_MESSAGE, decoupleSavedReplyAddedToMessage),
    takeLatest(FETCH_RESERVATION_ALTERATION_SUCCESSED, decoupleFetchReservationAlterationSuccessed),
    debounce(300, [POST_SPECIAL_OFFER_SUCCESSED, DELETE_SPECIAL_OFFER_SUCCESSED], decoupleUpdateSpecialOfferSuccessed),
  ]);
}
