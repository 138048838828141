import { fromJS } from 'immutable';
import { css } from 'styled-components';
import { darken as pDarken, lighten as pLighten } from 'polished';

import { variations } from '../utils/constants';
import colors, { paletteDeprecated as palette } from './colors/colors';
import { withPaddingAxis, withPaddingRight, withPaddingLeft, withPaddingTop, withPaddingBottom } from './padding';

const { PRIMARY, SECONDARY, SUCCESS, ERROR, WARNING, DARK, GREY, WHITE } = variations;

const withDarkenColor = ({ darken, variation }) => darken && pDarken(darken, colors[variation]);
const withLightenColor = ({ lighten, variation }) => lighten && pLighten(lighten, colors[variation]);
const withVariationColor = ({ variation }) => colors[variation];
export const withThemeColor = ({ variation, lighten, darken }) =>
  withDarkenColor({ darken, variation }) ||
  withLightenColor({ lighten, variation }) ||
  withVariationColor({ variation });

export const withPadding = props =>
  withPaddingAxis(props) ||
  css`
    ${withPaddingRight};
    ${withPaddingLeft};
    ${withPaddingTop};
    ${withPaddingBottom};
  `;

export const themes = {
  primary: {
    color: WHITE,
    backgroundColor: PRIMARY,
    fadeBackgroundColor: 't300',
    borderColor: WHITE,
    active: WHITE,
  },
  secondary: {
    color: SECONDARY,
    backgroundColor: WHITE,
    fadeBackgroundColor: WHITE,
    borderColor: 'n300',
    active: WHITE,
  },
  success: {
    color: WHITE,
    backgroundColor: SUCCESS,
    fadeBackgroundColor: 'g300',
    borderColor: WHITE,
    active: WHITE,
  },
  error: {
    color: WHITE,
    backgroundColor: ERROR,
    fadeBackgroundColor: 'r300',
    borderColor: WHITE,
    active: WHITE,
  },
  warning: {
    color: WHITE,
    backgroundColor: WARNING,
    fadeBackgroundColor: 'o300',
    borderColor: WHITE,
    active: WHITE,
  },
  dark: {
    color: WHITE,
    backgroundColor: 'n800',
    fadeBackgroundColor: GREY,
    borderColor: WHITE,
    active: WHITE,
  },
  grey: {
    color: DARK,
    backgroundColor: GREY,
    fadeBackgroundColor: 'n300',
    borderColor: DARK,
    active: WHITE,
  },
  white: {
    color: DARK,
    backgroundColor: WHITE,
    fadeBackgroundColor: WHITE,
    borderColor: 'n300',
    active: 'b600_v2',
  },
  yellow: {
    color: WHITE,
    backgroundColor: 'y500',
    fadeBackgroundColor: 'y300',
    borderColor: 'o300',
  },
  blue: {
    color: WHITE,
    backgroundColor: 'b500',
    fadeBackgroundColor: 'b300',
    borderColor: WHITE,
  },
  purple: {
    color: WHITE,
    backgroundColor: 'pu500',
    fadeBackgroundColor: 'pu300',
    borderColor: WHITE,
  },
  violet: {
    color: WHITE,
    backgroundColor: 'violet',
    fadeBackgroundColor: 'violet',
    borderColor: WHITE,
  },
  info: {
    color: WHITE,
    backgroundColor: 'b500',
    fadeBackgroundColor: 'b300',
    borderColor: WHITE,
  },
  outline: {
    color: DARK,
    backgroundColor: WHITE,
    borderColor: 'n300',
    active: 'b600_v2',
  },
  fallback: {
    color: DARK,
    backgroundColor: WHITE,
    borderColor: 'n300',
    active: 'b600_v2',
  },
};

export const getVariationOf = (variation, property, _themes = themes) =>
  (_themes[variation] || _themes.fallback)[property];

export const getColor = (variation, property, _themes = themes) => {
  const color = getVariationOf(variation, property, _themes);
  return colors[color] || color;
};

export default {
  /*
   * DEPRECATED: to be removed
   */
  rem: size => {
    if (typeof size === 'string' && size.match(/r?em$/)) {
      return size;
    }
    return +('' + size).replace(/[^\d.]/g, '') / 10 + 'rem';
  },
  parseColor: value => {
    if (value && value.match(/^theme\./)) {
      const path = value.replace('theme.', '').split('.');
      return fromJS({ colors, palette }).getIn(path);
    }
    return value;
  },
};
