import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizes } from 'ready/utils/constants';
import * as icons from './icons';
import * as iconsV2 from './icons_v2';
import { sizePropType } from 'ready/utils/propTypes';
import { colors } from 'ready/styles/theme';

const { XS, S, M, L, XL, XXL } = sizes;

export const StyledSVG = styled.svg`
  height: ${props => props.theme.iconSize[props.size]};
  width: ${props => props.theme.iconSize[props.size]};
`;

const Path = styled.path`
  fill: ${props => props.theme.colors[props.color] || props.color};
`;

const Icon = ({ icon, color, ...props }) => (
  <StyledSVG {...props}>
    <Path d={icon} color={color} />
  </StyledSVG>
);

Icon.sizes = { XS, S, M, L, XL, XXL };
Icon.icons = icons;
Icon.icons.v2 = iconsV2;
Icon.colors = colors;

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  viewBox: PropTypes.string,
  size: sizePropType(Icon.sizes),
};

Icon.defaultProps = {
  viewBox: '0 0 1024 1024',
  size: M,
  color: 'n500',
};

export default Icon;
