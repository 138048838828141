import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import icon from '../../assets/images/android-chrome-512x512.png';

const Favicon = ({ t }) => {
  return <img src={icon} alt={t.logo} />;
};

Favicon.propTypes = {
  t: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  withMappedTranslations({
    logo: 'FAVICON.ICON',
  }),
)(Favicon);
