import qs from 'qs';
import * as Sentry from '@sentry/react';

export function createFormData(options) {
  // TODO: Remove?
  const data = new FormData();
  Object.keys(options).forEach(key => data.append(key, options[key]));
  return data;
}

export function createFormDataWithAttachments(data) {
  const { attachments, ...message } = data;
  const formData = new FormData();

  Object.keys(message).forEach(key => formData.append(key, message[key]));

  attachments.forEach((file, i) => {
    formData.append(`attachments[${i}]file`, file);
  });

  for (let entry of formData.entries()) {
    const [key, value] = entry;
    console.log(`${key}: ${value}`);
  }
  return formData;
}

export default class API {
  constructor({ baseUrl, authorization, locale }) {
    this._baseUrl = baseUrl;
    this._authorization = authorization;
    this._locale = locale;
  }

  get(url = '', options = {}) {
    return this._request(url, this._options({ method: 'GET', ...options }));
  }

  post(url = '', data = {}, options = {}) {
    return this._request(url, this._options({ method: 'POST', data, ...options }));
  }

  put(url = '', data = {}, options = {}) {
    return this._request(url, this._options({ method: 'PUT', data, ...options }));
  }

  patch(url = '', data = {}, options = {}) {
    return this._request(url, this._options({ method: 'PATCH', data, ...options }));
  }

  delete(url, ids) {
    return Promise.all([].concat(ids).map(id => this._request(`${url}/${id}/`, this._options({ method: 'DELETE' }))));
  }

  _options({ method, data, headers = {}, ...options }) {
    switch (method) {
      case 'PUT':
      case 'POST':
      case 'PATCH':
        return {
          method: method,
          body: this._body(data, { headers, ...options }),
          headers: this._headers(headers),
          ...options,
        };
      default:
        return { ...options, method, headers: this._headers(headers) };
    }
  }

  _headers({ contentType, ...headers }) {
    if (!contentType) headers['content-type'] = 'application/json';
    if (this._authorization) headers['Authorization'] = this._authorization;
    if (this._locale) headers['Accept-Language'] = this._locale;
    return headers;
  }

  _body(data, { headers, ...options }) {
    switch (headers.contentType) {
      case 'form-data': {
        return createFormData({ file: data, ...options });
      } // Remove?
      case 'multipart/form-data': {
        return createFormDataWithAttachments(data);
      }
      default:
        return JSON.stringify(data);
    }
  }

  _path(url, options) {
    return options.ignoreBaseUrl ? url : `${this._baseUrl}${url}`;
  }

  _queryString(options) {
    const { search } = options;
    const queryString = qs.stringify(search, { arrayFormat: 'repeat' });
    return (queryString && `?${queryString}`) || '';
  }

  async _request(url, options) {
    const path = `${this._path(url, options)}${this._queryString(options)}`;
    let res = null;
    try {
      res = await fetch(`${path}`, options);
      /**
       * If res.status equals 204 response has no content to be parsed
       */
      const result = (res.status === 204 && res.ok) || (await res.json());
      if (!res.ok) {
        const error = new Error(res.statusText);
        error.status = res.status;
        error.errors = result || {};
        throw error;
      }
      return result;
    } catch (error) {
      error.errors = error.errors || {};
      triggerAPIErrorToSentry(error);
      throw error;
    }
  }
}

const triggerAPIErrorToSentry = error => {
  if (error.status >= 500) {
    Sentry.captureException(error, { level: 'log' });
  }
};
