import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

export const _getConversation = state => state.conversation;
export const getConversation = createSelector(
  [_getConversation],
  conversation => conversation.toJS(),
);

export const _getConversationData = state => state.conversation.get('data');
export const getConversationData = createSelector(
  [_getConversationData],
  conversation => (conversation && conversation.toJS()) || null,
);

export const getSelectedConversationId = state => {
  const data = state.conversation.get('data') || fromJS({});
  return data.get('id');
};

export const _getInitConversation = state => state.conversation.get('initConversation');
export const getInitConversation = createSelector(
  [_getInitConversation],
  initConversation => (initConversation && initConversation.toJS()) || null,
);

export const hasBooking = createSelector(
  [_getConversationData],
  conversation => !!(conversation && conversation.get('reservations').concat(conversation.get('inquiries')).size),
);
export const hasRental = createSelector(
  [_getConversationData],
  conversation => !!(conversation && conversation.get('rentals').size),
);
