import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text, Button } from 'ready/components';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spacing.xs};
`;

const AcceptEnquiry = ({ t, onDecline, onAccept, onSendSpecialOffer, disabled }) => (
  <ChatNotification t={t} data-qa="Notification:AcceptEnquiry__ChatNotification">
    <ContentWrapper>
      <Text bold data-qa="AcceptEnquiry:Text">
        {t.acceptEnquiryQuestion}
      </Text>
      <ActionsWrapper>
        <Button
          inline
          size={Button.sizes.S}
          variation={Button.variations.OUTLINE}
          onClick={onSendSpecialOffer}
          disabled={disabled || !onSendSpecialOffer}
          // TODO: fix button hints
          // hint={{ enabled: true, text: t.specialOfferHint }}
          data-qa="AcceptEnquiry:Button--specialOffer"
        >
          {t.specialOffer}
        </Button>
        <Button
          inline
          size={Button.sizes.S}
          onClick={onAccept}
          disabled={disabled}
          data-qa="AcceptEnquiry:Button--accept"
        >
          {t.accept}
        </Button>
        <Button
          inline
          size={Button.sizes.S}
          variation={Button.variations.OUTLINE}
          icon={{ icon: Button.icon.icons.CROSS }}
          onClick={onDecline}
          disabled={disabled || !onDecline}
          // TODO: fix button hints
          // hint={{ enabled: true, text: t.declineInquiryHint }}
          data-qa="AcceptEnquiry:Button--decline"
        />
      </ActionsWrapper>
    </ContentWrapper>
  </ChatNotification>
);

AcceptEnquiry.propTypes = {
  onDecline: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  onSendSpecialOffer: PropTypes.func,
  disabled: PropTypes.bool,
  t: PropTypes.shape({
    decline: PropTypes.string,
    accept: PropTypes.string,
    showMore: PropTypes.string,
    showLess: PropTypes.string,
    specialOffer: PropTypes.string,
    specialOfferHint: PropTypes.string,
    declineInquiryHint: PropTypes.string,
  }),
};

export default AcceptEnquiry;
