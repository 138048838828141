import styled from 'styled-components';

import { baseDisplay, inlineDisplay } from '../styles';

const CleanButton = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  user-select: none;
  ${props => inlineDisplay(props) || baseDisplay()}

  &[disabled] {
    opacity: 0.5;
    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }
`;

export default CleanButton;
