import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsFilterSidebarOpen } from 'selectors/view';
import { changeIsFilterSidebarOpen } from 'ducks/view';

import Drawer, { Panel } from 'ready/components/Drawer/Drawer';
import Filters from '../ConversationListDrawer/components/Filters';

const FilterSidebarDrawer = styled(Drawer)`
  grid-area: content;
  position: relative;
`;

const FilterSidebarPanel = styled(Panel)`
  width: 28rem;
`;

const MobileFilterSidebarScene = ({ zIndex, isFilterSidebarOpen, dispatchChangeIsFilterSidebarOpen }) => (
  <FilterSidebarDrawer
    handleClose={() => dispatchChangeIsFilterSidebarOpen(false)}
    hasOverlay
    from={Drawer.from.LEFT}
    zIndex={zIndex}
    show={isFilterSidebarOpen}
    renderPanel={props => (
      <FilterSidebarPanel {...props}>
        <Filters />
      </FilterSidebarPanel>
    )}
  />
);

MobileFilterSidebarScene.propTypes = {
  isFilterSidebarOpen: PropTypes.bool,
  zIndex: PropTypes.number,
  dispatchChangeIsFilterSidebarOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFilterSidebarOpen: getIsFilterSidebarOpen(state),
});

const mapDispatchToProps = {
  dispatchChangeIsFilterSidebarOpen: changeIsFilterSidebarOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileFilterSidebarScene);
