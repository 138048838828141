import {
  inquiryStatuses,
  INQUIRY_ORIGIN_FOR_PRE_APPROVAL,
  twilioDeviceStatuses,
  twilioConnectionStatuses,
  taskRouterActivities,
  featureFlags,
  BOOKING_ORIGINS,
  BOOKING_PLATFORMS,
  connectionTypes,
  callForwardingStatuses,
  callCenterTabs,
} from 'constants.js';

/**
 * Validate if booking is inquiry
 * @param {bookingPropType} booking
 */
export const isInquiry = booking => booking && booking.status === inquiryStatuses.INQUIRY;
/**
 * Validate if booking is reservation
 * @param {bookingPropType} booking
 */
export const isReservation = booking => booking && booking.booked === undefined;
/**
 * Validate if inquiry booking is not found
 * Not found does not really means not found by our backend or at http request,
 * it means it was not property found in some third party integration application, like airbnb
 * @param {bookingPropType} booking
 */
export const isBookingNotFound = booking => booking && booking.status === inquiryStatuses.NOT_FOUND;
/**
 * Validate if booking should be pre approved accept/decline before turning out into reservation
 * according its origin. Airbnb bookings demand approval
 * @param {bookingPropType} booking
 */
export const shouldBePreApproved = booking =>
  INQUIRY_ORIGIN_FOR_PRE_APPROVAL.includes(booking.origin) ||
  (booking.origin === BOOKING_ORIGINS.CHANNEL_MANAGER && booking.platform_name === BOOKING_PLATFORMS.AIRBNB);
/**
 * Validate if booking is eligible for pre approval
 * @param {bookingPropType} booking
 */
export const isEligibleForPreApproval = booking =>
  booking &&
  [inquiryStatuses.INQUIRY, inquiryStatuses.PENDING].includes(booking.status) &&
  shouldBePreApproved(booking);
/**
 * Validate if pre approval instructions should be displayed
 * @param {bookingPropType} booking
 */
export const shouldDisplayPreApprovalInstructions = booking =>
  booking && [inquiryStatuses.INQUIRY, inquiryStatuses.NOT_FOUND, inquiryStatuses.PENDING].includes(booking.status);
/**
/**
 * Validate if booking has been relocated to or from another booking
 * @param {bookingPropType} booking
 */
export const isBookingRelocated = booking =>
  booking && (booking.relocated_to_id !== null || booking.relocated_from_id !== null);
/**
 * Enable call center for outbound call only if guest exists, guest have phone number and twilio device status is ready
 * @param {Object} taskRouter callCenterShape.taskRouter
 * @param {Object} device callCenterShape.device
 */
export const isCallCenterReady = (taskRouter, device) =>
  [taskRouterActivities.IDLE, taskRouterActivities.AVAILABLE, taskRouterActivities.PAUSED].includes(
    taskRouter.activityName,
  ) && device.status === twilioDeviceStatuses.READY;
/**
 * Enable guest call if it is ready and it has guest and guest phone
 * @param {Object} guest guestPropType
 */
export const shouldEnableGuestCall = guest => guest && !!guest.phone_number;

/**
 * Close modal through close button is only enabled if a call center was opened from staff and it is still offline
 * or if note about call is already added
 * @param {Object} data call center data state
 * @param {Object} connection call center connection state
 */
export const shouldEnableCloseCallCenter = (data, connection, view) =>
  connection.status !== twilioConnectionStatuses.IN_PROGRESS &&
  (view.tab !== callCenterTabs.GUEST ||
    (connection.type === connectionTypes.OUTBOUND && data.isNoteSubmitted) ||
    (!data.conversation && !!data.callReason) ||
    (data.isNoteSubmitted && !!data.callReason) ||
    connection.status === twilioConnectionStatuses.READY);
/**
 * Only allow call center conversation change if call is not in progress or completed.
 * @param {String} status connection status
 */
export const isCallCenterConversationChangeAllowed = status =>
  ![
    twilioConnectionStatuses.RINGING,
    twilioConnectionStatuses.IN_PROGRESS,
    twilioConnectionStatuses.COMPLETED,
  ].includes(status);
/**
 * Check if twilio task router activity is available
 * @param {Object} taskRouter Twilio.TaskRouter
 */
export const isTaskRouterActivityOff = taskRouter =>
  [taskRouterActivities.UNAVAILABLE, taskRouterActivities.OFFLINE, null, undefined].includes(taskRouter.activityName);

/**
 * Check if twilio task router activity is busy
 * @param {String} taskRouter Twilio.TaskRouter
 */
export const isTaskRouterActivityBusy = taskRouter =>
  [taskRouterActivities.BUSY, taskRouterActivities.RESERVED].includes(taskRouter.activityName);

export const isTaskRouterActivityPaused = taskRouter => [taskRouterActivities.PAUSED].includes(taskRouter.activityName);

/**
 * Usually if staff missed a call they go offline, then they should be notified about that call missed.
 * Besides it should be warned they went off without their consent, so they should turn call center on again
 * Such notification should only be displayed when device is READY and taskrouter is OFFLINE
 * @param {Object} device callCenterPropShape.device
 * @param {Object} taskRouter callCenterPropShape.taskRouter
 */
export const shouldDisplayCallCenterOffWarning = (device, taskRouter) =>
  device.status === twilioDeviceStatuses.READY && taskRouter.activityName === taskRouterActivities.OFFLINE;

const callCenterHasError = (taskRouter, device) => taskRouter.status === twilioDeviceStatuses.ERROR || device.error;

const isCallCenterValid = (taskRouter, device) => taskRouter.activityName && !callCenterHasError(taskRouter, device);

const isTwilioDeviceReady = device => device.status === twilioDeviceStatuses.READY;

/**
 * Check if user flag is set and required tokens exist to allow enabling the call center
 * @param {Object} device callCenterPropShape.device
 * @param {Object} taskRouter callCenterPropShape.taskRouter
 * @param {Object} user userPropType
 */
export const canEnableCallCenter = ({ device, taskRouter }, user) =>
  !!device.callToken &&
  !!taskRouter.workerToken &&
  !callCenterHasError(taskRouter, device) &&
  (user.feature_flags || []).includes(featureFlags.TWILIO_CALL_CENTER);

/**
 * Check if call center is enabled according twilio device and user flags
 * @param {Object} device callCenterPropShape.device
 * @param {Object} taskRouter callCenterPropShape.taskRouter
 */
export const isCallCenterEnabled = ({ device, taskRouter }) =>
  isCallCenterValid(taskRouter, device) && isTwilioDeviceReady(device);

export const isCallCenterOn = (taskRouter, device) =>
  isCallCenterEnabled({ taskRouter, device }) && !isTaskRouterActivityOff(taskRouter);

/**
 * Validate if booking is eligible for special offer. It must be an inquiry and from Airbnb
 * @param {bookingPropType} booking
 */
export const isEligibleForSpecialOffer = booking =>
  booking &&
  !booking.booked &&
  [BOOKING_ORIGINS.AIRBNB_MESSAGE, BOOKING_ORIGINS.AIRBNB_EMAIL, BOOKING_ORIGINS.CHANNEL_MANAGER].includes(
    booking.origin,
  );

/**
 * Check if booking origin indicates it is a tentative
 * @param {bookingPropType} booking
 */
export const isTentativeOrigin = booking =>
  booking &&
  (booking.origin === BOOKING_ORIGINS.AIRBNB_TENTATIVE ||
    (booking.status === inquiryStatuses.PENDING && shouldBePreApproved(booking)));

/**
 * Check if inquiry can be declined based on origin, status & platform_name
 * @param {bookingPropType} booking
 */
export const canInquiryBeDeclined = booking =>
  booking && isEligibleForPreApproval(booking) && !isTentativeOrigin(booking);

/**
 * Get default reservation main info for conversation details and fetching
 * @param {Object} conversation conversationPropType
 * @response {Object}
 *    {Number} id
 *    {String} checkin_date
 *    {String} checkout_date
 */
export const getDefaultReservationFromConversation = conversation =>
  conversation.reservations && conversation.reservations.length && conversation.reservations[0];
/**
 * Get default inquiry main info for conversation details and fetching
 * @param {Object} conversation conversationPropType
 * @response {Object}
 *    {Number} id
 *    {String} checkin_date
 *    {String} checkout_date
 */
export const getDefaultInquiryFromConversation = conversation =>
  conversation.inquiries && conversation.inquiries.length && conversation.inquiries[0];

export const getDefaultRentalIdFromConversation = conversation => {
  if (!conversation) return null;

  const conversationBookings = [].concat(conversation.reservations).concat(conversation.inquiries);

  const booking = conversationBookings[0];
  if (!booking) return conversation.rentals[0];

  return conversation.rentals.find(rental => rental.id === booking.rental_id);
};

export const canForwardCall = ({ data, connection, callForwarding }) =>
  connection.type === connectionTypes.INBOUND &&
  callForwarding.status === callForwardingStatuses.READY &&
  !!data.call_sid;

export const amenityHasInfo = amenity =>
  ['model', 'brand', 'location', 'details'].reduce((res, attr) => res || !!amenity[attr], false);
