import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUserData } from 'selectors/user';
import { userPropType } from 'common/propTypes';
import { paths } from 'constants.js';

const withProtectedRoute = WrappedComponent => {
  const ProtectedRoute = ({ user, ...props }) =>
    !user ? <Redirect to={paths.LOGIN} /> : <WrappedComponent {...props} />;

  ProtectedRoute.propTypes = {
    user: userPropType,
  };

  return connect(mapStateToProps)(ProtectedRoute);
};

const mapStateToProps = state => ({
  user: getUserData(state),
});

export default withProtectedRoute;
