import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedRelative } from 'react-intl';
import moment from 'moment';

import { Text } from 'ready/components/typography';

import { twilioConnectionStatuses } from 'constants.js';

const StyledText = styled(Text)`
  margin: ${props => props.theme.spacing.xs};
  min-height: ${props => props.theme.typography.text.m['line-height']};
`;

const ActionDetails = ({ t, status, startedAt }) => {
  if (status === twilioConnectionStatuses.CALLING) {
    return (
      <StyledText variation={Text.variations.SUCCESS} size={Text.sizes.S}>
        {t.calling}
      </StyledText>
    );
  }

  if (status === twilioConnectionStatuses.RINGING) {
    return (
      <StyledText variation={Text.variations.SUCCESS} size={Text.sizes.S}>
        {t.ringing}
      </StyledText>
    );
  }

  if (status === twilioConnectionStatuses.IN_PROGRESS) {
    return (
      <FormattedRelative value={startedAt} updateInterval={1}>
        {() => <StyledText size={Text.sizes.S}>{moment.utc(moment().diff(startedAt)).format('HH:mm:ss')}</StyledText>}
      </FormattedRelative>
    );
  }

  if (status === twilioConnectionStatuses.COMPLETED) {
    return (
      <StyledText variation={Text.variations.ERROR} size={Text.sizes.S}>
        {t.callEnded}
      </StyledText>
    );
  }

  return <StyledText />;
};

ActionDetails.propTypes = {
  t: PropTypes.shape({
    calling: PropTypes.string.isRequired,
    ringing: PropTypes.string.isRequired,
    callEnded: PropTypes.string.isRequired,
  }),
  status: PropTypes.string.isRequired,
  startedAt: PropTypes.object,
};

export default ActionDetails;
