import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ToggleSwitch from './components/ToggleSwitch';
import { Collapsible, Text } from 'ready/components';

import { ContentWrapper } from '../../../../components';
import ContentWithLabel from 'components/ContentWithLabel/ContentWithLabel';
import { smartDevicesPropType } from 'common/propTypes';

const WrappedText = styled(Text)`
  max-width: 95px;
  white-space: normal;
  overflow-wrap: break-word;
`;

const DeviceToggleWithLabel = styled(ContentWithLabel)`
  min-width: 30%;
`;

const SmartDevices = ({ t, smart_devices, onToggleDevice }) => {
  if (!smart_devices || smart_devices.length === 0) return false;

  const canLockOrUnlock = device => {
    return device.online;
  };

  return (
    <Collapsible title={t.smartDeviceTitle} data-qa="Tab:Collapsible--smartDevices">
      <ContentWrapper data-qa="SmartDevices:__wrapper">
        {smart_devices.map((device, i) => (
          <>
            <ContentWithLabel>
              <WrappedText>{device.name}</WrappedText>
            </ContentWithLabel>
            <DeviceToggleWithLabel>
              <ToggleSwitch
                id={device.id}
                checked={device.locked}
                disabled={!canLockOrUnlock(device)}
                onClick={() => onToggleDevice(device.id)}
              />
            </DeviceToggleWithLabel>
          </>
        ))}
      </ContentWrapper>
    </Collapsible>
  );
};

SmartDevices.propTypes = {
  smart_devices: smartDevicesPropType,
  onToggleDevice: PropTypes.func.isRequired,
  t: PropTypes.shape({
    smartDeviceTitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default SmartDevices;
