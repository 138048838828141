import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsConversationListOpen } from 'selectors/view';
import { getSelectedConversationId } from 'selectors/conversation';

import Drawer from 'ready/components/Drawer/Drawer';
import ConversationList from './components/ConversationList';

const Wrapper = styled(Drawer)`
  grid-area: content;
  position: relative;
  top: auto;
  height: 100%;
`;

const LeftSidebarWrapper = styled.div`
  display: grid;
  grid-auto-rows: 0;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  ${props => filterSidebarOpenStyle(props) || filterSidebarClosedStyle()};
`;

const filterSidebarOpenStyle = ({ isFilterSidebarOpen }) =>
  isFilterSidebarOpen &&
  css`
    grid-template-columns: 4rem 16.4rem 1fr;
    grid-template-areas: 'filter-toggle filter-header conversation-list-header' 'filter filter conversation-list';
  `;

const filterSidebarClosedStyle = () => css`
  grid-template-columns: 4rem 1fr;
  grid-template-areas: 'filter-toggle conversation-list-header' 'conversation-list conversation-list';
`;

const ConversationListDrawer = ({ zIndex, isConversationListOpen, selectedConversationId }) => {
  return (
    <Wrapper zIndex={zIndex} show={!selectedConversationId || isConversationListOpen}>
      <LeftSidebarWrapper>
        <ConversationList />
      </LeftSidebarWrapper>
    </Wrapper>
  );
};

ConversationListDrawer.propTypes = {
  zIndex: PropTypes.number,
  isConversationListOpen: PropTypes.bool,
  selectedConversationId: PropTypes.number,
};

const mapStateToProps = state => ({
  isConversationListOpen: getIsConversationListOpen(state),
  selectedConversationId: getSelectedConversationId(state),
});

export default connect(mapStateToProps)(ConversationListDrawer);
