import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { intlShape } from 'react-intl';

import { getFilters, getFiltersCounter } from 'selectors/filters';
import { getFilterPresets } from 'selectors/filterPresets';
import { getIsMoreFiltersOpen } from 'selectors/view';
import { changeIsMoreFiltersOpen } from 'ducks/view';
import { changeAndApplySelectedFilter, changeFilters, applySelectedFilters } from 'ducks/filters';
import { changeConversationsSortKey } from 'ducks/conversations';
import {
  saveFilterPresetRequested,
  fetchFilterPresetsRequested,
  deleteFilterPresetRequested,
  changeIsSavePresetOpen,
  changeIsLoadPresetOpen,
} from 'ducks/filterPresets';
import FlagList from './components/FlaggedConversation/FlaggedConversationList';
import {
  CategoryList,
  SectionHeading,
  MultiSelectFilter,
  CenteredContentWrapper,
  Wrapper,
  FiltersWrapper,
  CategoryListWrapper,
  MoreFiltersButton,
  MoreFilters,
} from './components';
import { Text, LoadingIndicator } from 'ready/components';
import { RequestError } from 'components';

import { filtersPropTypeWithState } from 'common/propTypes';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { filterKeys } from 'constants.js';
import { variationsCssProps } from 'ready/utils/constants';
import { getVariationOf } from 'ready/styles/helpers';

const FiltersPanel = ({
  isMoreFiltersOpen,
  filterPresets,
  filters,
  filtersCounter,
  dispatchChangeAndApplySelectedFilters,
  dispatchChangeConversationsSortKey,
  dispatchChangeIsMoreFiltersOpen,
  dispatchChangeIsSavePresetOpen,
  dispatchChangeIsLoadPresetOpen,
  dispatchChangeFiltersAndApply,
  dispatchSaveFilterPresetRequested,
  dispatchDeleteFilterPresetRequested,
  dispatchFetchFilterPresetsRequested,
  t,
  intl,
}) => {
  if (filters.isFetching) {
    return (
      <CenteredContentWrapper>
        <LoadingIndicator />
      </CenteredContentWrapper>
    );
  }

  if (filters.didInvalidate) {
    return (
      <CenteredContentWrapper>
        <RequestError errors={filters.errors} />
      </CenteredContentWrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <SectionHeading>{t.bookingStatus}</SectionHeading>
        <CategoryListWrapper>
          <CategoryList
            categories={filters.config.categories}
            onChangeSelectedFilter={(values, sortKey) => {
              dispatchChangeConversationsSortKey(sortKey);
              dispatchChangeAndApplySelectedFilters(filterKeys.CATEGORY_ID, values);
            }}
          />
        </CategoryListWrapper>
        {filters.config.flags.length > 0 && (
          <>
            <SectionHeading>{t.flaggedConversationSectionTitle}</SectionHeading>
            <CategoryListWrapper>
              <FlagList
                flags={filters.config.flags}
                onChangeSelectedFilter={(values, sortKey) => {
                  dispatchChangeAndApplySelectedFilters(filterKeys.FLAG_ID, values);
                }}
              />
            </CategoryListWrapper>
          </>
        )}
        <SectionHeading>{t.filters}</SectionHeading>
        <FiltersWrapper data-qa="shared:Filters__FiltersWrapper">
          <MultiSelectFilter
            closeMenuOnSelect
            hideSelectedOptions
            isResizable
            options={filters.config.cities}
            t={t}
            name={filterKeys.CITIES}
            label={t[filterKeys.CITIES]}
            selected={filters.dataOptions[filterKeys.CITIES]}
            onChangeSelectedFilter={values => dispatchChangeAndApplySelectedFilters(filterKeys.CITIES, values)}
          />
          <MultiSelectFilter
            closeMenuOnSelect
            hideSelectedOptions
            isResizable
            options={filters.config.languages}
            t={t}
            name={filterKeys.LANGUAGES}
            label={t[filterKeys.LANGUAGES]}
            selected={filters.dataOptions[filterKeys.LANGUAGES]}
            onChangeSelectedFilter={values => dispatchChangeAndApplySelectedFilters(filterKeys.LANGUAGES, values)}
          />
          <div data-qa="shared:Filters__div-moreFiltersWrapper">
            <MoreFiltersButton
              variation={MoreFiltersButton.variations.OUTLINE}
              disabled={isMoreFiltersOpen}
              onClick={() => dispatchChangeIsMoreFiltersOpen(true)}
              data-qa="shared:Filters__moreFiltersButton"
            >
              {t.moreFilters}
            </MoreFiltersButton>
            {!!filtersCounter && (
              <Text
                size={Text.sizes.S}
                bold
                variation={getVariationOf(Text.variations.OUTLINE, variationsCssProps.ACTIVE)}
              >
                {intl.formatMessage({ id: 'FILTERS.MORE_FILTERS_SELECTED' }, { counter: filtersCounter })}
              </Text>
            )}
          </div>
        </FiltersWrapper>
        {isMoreFiltersOpen && (
          <MoreFilters
            t={t}
            data={filters.data}
            filterPresets={filterPresets}
            dataOptions={filters.dataOptions}
            config={filters.config}
            onClose={() => dispatchChangeIsMoreFiltersOpen(false)}
            onApply={dispatchChangeFiltersAndApply}
            onSavePreset={dispatchSaveFilterPresetRequested}
            onFetchPresets={dispatchFetchFilterPresetsRequested}
            onDeletePreset={dispatchDeleteFilterPresetRequested}
            onChangeIsLoadPresetOpen={dispatchChangeIsLoadPresetOpen}
            onChangeIsSavePresetOpen={dispatchChangeIsSavePresetOpen}
          />
        )}
      </Wrapper>
    </>
  );
};

FiltersPanel.propTypes = {
  isMoreFiltersOpen: PropTypes.bool,
  filters: filtersPropTypeWithState.isRequired,
  filtersCounter: PropTypes.number,
  dispatchChangeAndApplySelectedFilters: PropTypes.func.isRequired,
  dispatchChangeConversationsSortKey: PropTypes.func.isRequired,
  dispatchChangeIsMoreFiltersOpen: PropTypes.func.isRequired,
  dispatchChangeFiltersAndApply: PropTypes.func.isRequired,
  dispatchFetchFilterPresetsRequested: PropTypes.func.isRequired,
  t: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    bookingStatus: PropTypes.string.isRequired,
    filters: PropTypes.string.isRequired,
  }).isRequired,
  intl: intlShape,
};

const mapStateToProps = state => ({
  filters: getFilters(state),
  filtersCounter: getFiltersCounter(state),
  isMoreFiltersOpen: getIsMoreFiltersOpen(state),
  filterPresets: getFilterPresets(state),
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeAndApplySelectedFilters: (key, value) => dispatch(changeAndApplySelectedFilter(key, value)),
  dispatchChangeConversationsSortKey: sortKey => dispatch(changeConversationsSortKey(sortKey)),
  dispatchChangeIsMoreFiltersOpen: open => dispatch(changeIsMoreFiltersOpen(open)),
  dispatchChangeIsSavePresetOpen: open => dispatch(changeIsSavePresetOpen(open)),
  dispatchChangeIsLoadPresetOpen: open => dispatch(changeIsLoadPresetOpen(open)),
  dispatchSaveFilterPresetRequested: preset => dispatch(saveFilterPresetRequested(preset)),
  dispatchFetchFilterPresetsRequested: () => dispatch(fetchFilterPresetsRequested()),
  dispatchDeleteFilterPresetRequested: id => dispatch(deleteFilterPresetRequested(id)),
  dispatchChangeFiltersAndApply: filters => {
    dispatch(changeFilters(filters));
    dispatch(applySelectedFilters());
  },
});

export default compose(
  withMappedTranslations({
    placeholder: 'TEXT.SHARED.SELECT_OPTION',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
    bookingStatus: 'FILTERS.BOOKING_STATUS',
    opened: 'FILTERS.OPENED',
    snoozed: 'FILTERS.SNOOZED',
    [filterKeys.CITIES]: 'FILTERS.CITIES',
    [filterKeys.LANGUAGES]: 'FILTERS.LANGUAGES',
    [filterKeys.PROPERTY_NAME]: 'FILTERS.PROPERTY_NAME',
    [filterKeys.RESERVATION_ID]: 'FILTERS.RESERVATION_ID',
    [filterKeys.RESERVATION_STATUSES]: 'FILTERS.RESERVATION_STATUSES',
    [filterKeys.INQUIRY_STATUSES]: 'FILTERS.INQUIRY_STATUSES',
    [filterKeys.RESERVATION_PLATFORMS]: 'FILTERS.RESERVATION_PLATFORMS',
    [filterKeys.RESERVATION_HAS_REVIEW]: 'FILTERS.RESERVATION_HAS_REVIEW',
    [filterKeys.GUEST_FIRST_NAME]: 'FILTERS.GUEST_FIRST_NAME',
    [filterKeys.GUEST_LAST_NAME]: 'FILTERS.GUEST_LAST_NAME',
    [filterKeys.GUEST_PHONE_NUMBER]: 'FILTERS.GUEST_PHONE_NUMBER',
    reservationCreatedBetween: 'FILTERS.RESERVATION_CREATED_BETWEEN',
    reservationCheckInBetween: 'FILTERS.RESERVATION_CHECK_IN_BETWEEN',
    reservationCheckOutBetween: 'FILTERS.RESERVATION_CHECK_OUT_BETWEEN',
    sectionGeneral: 'FILTERS.SECTION_GENERAL',
    sectionReservation: 'FILTERS.SECTION_RESERVATION',
    sectionGuest: 'FILTERS.SECTION_GUEST',
    sectionDates: 'FILTERS.SECTION_DATES',
    savePreset: 'FILTERS.SAVE_PRESET',
    loadPreset: 'FILTERS.LOAD_PRESET',
    clearFilters: 'FILTERS.CLEAR_FILTERS',
    applyFilters: 'FILTERS.APPLY_FILTERS',
    moreFilters: 'FILTERS.MORE_FILTERS',
    filters: 'FILTERS.FILTERS',
    to: 'TEXT.SHARED.TO',
    from: 'TEXT.SHARED.FROM',
    all: 'TEXT.SHARED.ALL',
    load: 'TEXT.SHARED.LOAD',
    save: 'TEXT.SHARED.SAVE',
    cancel: 'TEXT.SHARED.CANCEL',
    newPresetPlaceholder: 'FILTERS.NEW_PRESET_PLACEHOLDER',
    selectPresetPlaceholder: 'FILTERS.SELECT_PRESET_PLACEHOLDER',
    flaggedConversationSectionTitle: 'CONVERSATION.FLAG_CONVERSATION.SECTION_TITLE',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FiltersPanel);
