import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon, Text, Tooltip } from 'ready/components';

const TimeConfigurationWrapper = styled(Text)`
  text-decoration-line: ${props => (props.overridden ? 'line-through' : 'none')};
  text-decoration-color: ${props => props.theme.colors.n600};
  color: ${props => (props.overridden ? props.theme.colors.n600 : props.theme.colors.b900_v2)};
  display: inline-flex;
  align-items: center;
  column-gap: ${props => props.theme.spacing.xs};
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
`;

const TimeConfiguration = ({ icon, hour = null, overridden, hint }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  let text = '–';

  if (hour !== null) {
    const time = moment()
      .hours(hour)
      .minutes(0);

    text = time.format('HH:mm');
  }

  return (
    <TimeConfigurationWrapper overridden={overridden}>
      <TooltipWrapper onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        {hint && showTooltip && (
          <Tooltip
            position={Tooltip.positions.RIGHT}
            variation={Tooltip.variations.WHITE}
            data-qa="PremiumClassTag:Tooltip"
          >
            <Text>{hint}</Text>
          </Tooltip>
        )}
        <Icon icon={icon} color={overridden ? Icon.colors.n600 : Icon.colors.b900_v2} size={Icon.sizes.M} />
      </TooltipWrapper>
      {text}
    </TimeConfigurationWrapper>
  );
};

TimeConfiguration.propTypes = {
  icon: PropTypes.string.isRequired,
  hour: PropTypes.number,
  overridden: PropTypes.bool,
  hint: PropTypes.string,
};

const TimeConfigurationList = ({ times }) => {
  let filteredTimes = times.filter(time => time.hour !== null);
  filteredTimes = filteredTimes.reduce((acc, val, index) => {
    if (!acc.length || val.hour !== acc[index - 1].hour) {
      return [...acc, val];
    }
    return acc;
  }, []);

  if (!filteredTimes.length) {
    return <Text>–</Text>;
  }

  return filteredTimes.map((time, index) => (
    <TimeConfiguration key={index} overridden={index < filteredTimes.length - 1} {...time} />
  ));
};

TimeConfigurationList.icons = Icon.icons;

export default TimeConfigurationList;
