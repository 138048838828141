import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

import Header from './components/Header';
import CalendarDays from './components/CalendarDays';

import { datePropType, sizePropType } from 'ready/utils/propTypes';
import { DATE_FORMAT, sizes } from 'ready/utils/constants';

const { S, M, L, XL } = sizes;

const customSizes = {
  [S]: {
    header: M,
    text: S,
  },
  [M]: {
    header: M,
    text: S,
  },
  [L]: {
    header: XL,
    text: M,
  },
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 5fr;
  height: 100%;
  width: 100%;
`;

const getStartOfDay = date => moment.utc(date).startOf('day');

const DateRangeCalendar = ({
  className,
  size,
  startDate,
  dateRanges,
  onClickNext,
  onClickPrevious,
  disableOnClickNext,
  disableOnClickPrevious,
  dateRangeVariations,
}) => {
  const [mDate, setMDate] = useState(getStartOfDay(startDate));

  useEffect(() => {
    setMDate(getStartOfDay(startDate));
  }, [startDate]);

  const handleClickNext = useCallback(() => {
    if (disableOnClickNext) return;

    const nextMonth = mDate.clone().add(1, 'month');
    setMDate(nextMonth);
    onClickNext && onClickNext(nextMonth);
  }, [mDate]);

  const handleClickPrev = useCallback(() => {
    if (disableOnClickPrevious) return;

    const previousMonth = mDate.clone().subtract(1, 'month');
    setMDate(previousMonth);
    onClickNext && onClickPrevious(previousMonth);
  }, [mDate]);

  return (
    <Container
      className={className}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Header size={customSizes[size].header} onClickNext={handleClickNext} onClickPrev={handleClickPrev}>
        {mDate.format('MMMM YYYY')}
      </Header>
      <CalendarDays
        size={customSizes[size].text}
        date={mDate}
        dateRanges={dateRanges}
        dateRangeVariations={dateRangeVariations}
      />
    </Container>
  );
};

DateRangeCalendar.sizes = { S, M, L };

DateRangeCalendar.propTypes = {
  className: PropTypes.string,
  size: sizePropType(DateRangeCalendar.sizes),
  startDate: datePropType,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func,
  disableOnClickNext: PropTypes.bool,
  disableOnClickPrevious: PropTypes.bool,
  dateRanges: PropTypes.arrayOf(
    PropTypes.shape({ from: PropTypes.string, to: PropTypes.string, variation: PropTypes.string }),
  ),
  dateRangeVariations: PropTypes.object,
};

DateRangeCalendar.defaultProps = {
  size: M,
  startDate: moment().format(DATE_FORMAT),
};

export default DateRangeCalendar;
