import { css } from 'styled-components';

import { sizes, variations } from 'ready/utils/constants';
import { themes } from 'ready/styles/helpers';

const { S, M, L, XL } = sizes;

export const SIZES = {
  s: {
    hint: S,
    icon: S,
    text: S,
    button: ({ theme }) => css`
      height: 2.8rem;
      padding: 0 ${theme.spacing.m};
    `,
  },
  m: {
    hint: S,
    icon: M,
    text: M,
    button: ({ theme }) => css`
      height: 3.6rem;
      padding: 0 ${theme.spacing.l};
    `,
  },
  l: {
    hint: M,
    icon: L,
    text: XL,
    button: ({ theme }) => css`
      height: 4rem;
      padding: 0 ${theme.spacing.l};
    `,
  },
};

export const COLORS = {
  ...themes,
  secondary: {
    backgroundColor: 't800',
    color: variations.WHITE,
  },
  warning: {
    backgroundColor: 'y500',
    color: variations.DARK,
  },
};
