import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible } from 'ready/components';

import { fullRentalPropType } from 'common/propTypes';

const Parking = ({ property, t }) => (
  <Collapsible title={t.parkingTitle} data-qa="Tab:Collapsible--parking" collapsed>
    <ContentWrapper minHeight="6.4rem">
      <ParkingContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

Parking.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    parkingTitle: PropTypes.string.isRequired,
    privateParking: PropTypes.string.isRequired,
    parkingInstructions: PropTypes.string.isRequired,
    yes: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
  }).isRequired,
};

const ParkingContent = ({ property, t }) => (
  <>
    <FullWidthContentWithLabel label={t.privateParking}>
      {property.is_private_parking ? t.yes : t.no}
    </FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.parkingInstructions} ifBlank={FullWidthContentWithLabel.ifBlank.HIDE}>
      {property.parking_directions}
    </FullWidthContentWithLabel>
  </>
);

ParkingContent.propTypes = Parking.propTypes;

export default Parking;
