import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, Button, LoadingIndicator } from 'ready/components';
import { callCenterPropShape } from 'common/propTypes';
import ForwardList from './ForwardList';
import { callCenterPages, callForwardingStatuses } from 'constants.js';
import RequestError from 'components/RequestError/RequestError';

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  margin-bottom: 15%;
  flex-grow: 1;
  align-content: center;
  justify-items: center;
  text-align: center;
  max-width: 22rem;
  padding: 1rem;
`;

const BackButton = styled(Button)`
  margin: ${props => props.theme.spacing.xs};
  align-self: flex-start;
`;

const Container = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Content = ({ view, callForwarding, actions, t }) => {
  if (callForwarding.status === callForwardingStatuses.FETCHING)
    return (
      <ContentWrapper>
        <LoadingIndicator />
      </ContentWrapper>
    );

  if (callForwarding.status === callForwardingStatuses.ERROR)
    return (
      <ContentWrapper>
        <RequestError errors={callForwarding.errors} />
      </ContentWrapper>
    );

  if (view.page === callCenterPages.FORWARD_LIST) {
    if (callForwarding.workers.length === 0) {
      return (
        <ContentWrapper>
          <Text variation={Text.variations.ERROR}>{t.noAgentsAvailable}</Text>
        </ContentWrapper>
      );
    } else {
      return <ForwardList callForwarding={callForwarding} actions={actions} t={t} />;
    }
  }

  if (callForwarding.status === callForwardingStatuses.FORWARDING) {
    return (
      <ContentWrapper>
        <div>
          <Text element="p">{t.forwardingTo}</Text>
          <Text size={Text.sizes.L} bold>
            {callForwarding.target.username}
          </Text>
        </div>
      </ContentWrapper>
    );
  }

  return null;
};

Content.propTypes = {
  callForwarding: callCenterPropShape.callForwarding,
  view: callCenterPropShape.view,
  actions: PropTypes.shape({
    forwardCall: PropTypes.func.isRequired,
  }),
  t: PropTypes.shape({
    forwardingTo: PropTypes.string.isRequired,
  }).isRequired,
};

const CallForwarding = ({ view, actions, callForwarding, t }) => {
  return (
    <Container>
      {callForwarding.status !== callForwardingStatuses.FORWARDING && (
        <BackButton
          icon={{ icon: Button.icon.icons.ARROW_LEFT }}
          variation={Button.variations.OUTLINE}
          clean
          onClick={() => {
            actions.clearCallForwardingErrors();
            actions.changePage();
          }}
          inline
        >
          {t.back}
        </BackButton>
      )}
      <Content view={view} actions={actions} callForwarding={callForwarding} t={t} />
    </Container>
  );
};

CallForwarding.propTypes = {
  callForwarding: callCenterPropShape.callForwarding,
  view: callCenterPropShape.view,
  actions: PropTypes.shape({
    forwardCall: PropTypes.func.isRequired,
  }),
  t: PropTypes.shape({
    forwardingTo: PropTypes.string.isRequired,
  }).isRequired,
};

export default CallForwarding;
