import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mapKeysToLowerCase } from 'ready/utils';
import { OuterWrapper, InnerWrapper, ErrorPanel, LoadingPanel, ManualLoadMore } from './components';

const handleLoadMore = ({ setIsLoading, onLoadMore }) => {
  setIsLoading(true);
  onLoadMore();
};

const shouldLoadMoreAtTop = (e, loadMoreThreshold) => {
  const threshold = e.target.scrollHeight * loadMoreThreshold;
  return e.target.scrollTop < threshold;
};

const shouldLoadMoreAtBottom = (e, loadMoreThreshold) => {
  const threshold = e.target.scrollHeight * (1 - loadMoreThreshold);
  const scrollPosition = e.target.clientHeight + e.target.scrollTop;
  return scrollPosition > threshold;
};

const handleScroll = (
  e,
  { reversedDirection, loadMoreThreshold, isLoading, setIsLoading, onLoadMore, hasNextPage },
) => {
  if (isLoading || !hasNextPage) return;
  const shouldLoadMore = reversedDirection ? shouldLoadMoreAtTop : shouldLoadMoreAtBottom;
  if (!shouldLoadMore(e, loadMoreThreshold)) return;
  handleLoadMore({ setIsLoading, onLoadMore });
};

const InfiniteScroll = ({
  reversed,
  className,
  direction,
  renderItems,
  items,
  onLoadMore,
  hasNextPage,
  hasError,
  t,
  loadMoreThreshold,
  scrollRef,
  'data-qa': dataQA,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemCount, setItemCount] = useState(items.length);
  const reversedDirection = direction === InfiniteScroll.directions.BOTTOM_TO_TOP;

  useEffect(() => {
    if (reversedDirection) {
      const el = scrollRef.current;
      el.scroll({
        left: 0,
        top: el.scrollHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (itemCount < items.length) {
      setItemCount(items.length);
      setIsLoading(false);
    }
  }, [items]);

  return (
    <OuterWrapper
      ref={scrollRef}
      className={className}
      onScroll={e =>
        handleScroll(e, {
          reversedDirection,
          loadMoreThreshold,
          isLoading,
          setIsLoading,
          onLoadMore,
          hasNextPage,
        })
      }
      data-qa={`[ready]${dataQA}`}
    >
      <InnerWrapper reversed={reversed}>
        {renderItems(items)}
        {(hasError && <ErrorPanel t={t} onLoadMore={onLoadMore} setIsLoading={setIsLoading} />) ||
          (isLoading && <LoadingPanel />) ||
          (hasNextPage && (
            <ManualLoadMore handleLoadMore={handleLoadMore} t={t} onLoadMore={onLoadMore} setIsLoading={setIsLoading} />
          ))}
      </InnerWrapper>
    </OuterWrapper>
  );
};

InfiniteScroll.directions = {
  TOP_TO_BOTTOM: 'top_to_bottom',
  BOTTOM_TO_TOP: 'bottom_to_top',
};

InfiniteScroll.thresholds = {
  // defines when to load more messages on scroll
  // eg. if direction is TOP_TO_BOTTOM and threshold is 0.25,
  // new messages will be loaded when the container is scrolled to >= 75% of its height.
  // if direction is BOTTOM_TO_TOP and threshold is 0.25,
  // new messages will be loaded when the container is scrolled to <= 25% of its height
  DEFAULT: 0.25,
};

InfiniteScroll.propTypes = {
  className: PropTypes.string,
  reversed: PropTypes.bool,
  items: PropTypes.array.isRequired,
  renderItems: PropTypes.func.isRequired,
  loadMoreThreshold: PropTypes.number.isRequired,
  onLoadMore: PropTypes.func,
  hasNextPage: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(mapKeysToLowerCase(InfiniteScroll.directions)),
  scrollRef: PropTypes.object,
  'data-qa': PropTypes.string,
};

InfiniteScroll.defaultProps = {
  loadMoreThreshold: InfiniteScroll.thresholds.DEFAULT,
  direction: InfiniteScroll.directions.TOP_TO_BOTTOM,
  scrollRef: React.createRef(),
  'data-qa': 'InfiniteScroll__outerWrapper',
};

export default InfiniteScroll;
