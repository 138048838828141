import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { Modal } from 'ready/components';
import { OverlayBackground } from 'components';
import { getShowProposeAlterationModal } from 'selectors/conversationDrawer';
import { changeShowProposeAlterationModal } from 'ducks/conversationDrawer';
import { proposeReservationAlterationRequested } from 'ducks/reservationAlteration';
import { Text, Button } from 'ready/components';

const StyledOverlayBackground = styled(OverlayBackground)`
  z-index: ${props => props.theme.zIndex.drawer[2]};
`;
const Content = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.m} 0;
`;

const ProposeAlterationModal = ({
  showProposeAlterationModal,
  dispatchChangeShowProposeAlterationModal,
  dispatchProposeReservationAlteration,
  t,
}) => {
  function proposeChanges() {
    dispatchChangeShowProposeAlterationModal(false);
    dispatchProposeReservationAlteration();
  }
  return (
    <>
      {showProposeAlterationModal && (
        <StyledOverlayBackground>
          <Modal heading={t.proposeHeader} size={Modal.sizes.M}>
            <Content>
              <DetailsWrapper>
                <Text>{t.proposeContentNotification}</Text>
                <Text>{t.proposeContentConfirmation}</Text>
              </DetailsWrapper>
              <ButtonsWrapper>
                <Button
                  variation={Button.variations.OUTLINE}
                  size={Button.sizes.M}
                  inline
                  onClick={() => dispatchChangeShowProposeAlterationModal(false)}
                >
                  {t.cancel}
                </Button>
                <Button variation={Button.variations.PRIMARY} size={Button.sizes.M} inline onClick={proposeChanges}>
                  {t.proposeAction}
                </Button>
              </ButtonsWrapper>
            </Content>
          </Modal>
        </StyledOverlayBackground>
      )}
    </>
  );
};

ProposeAlterationModal.propTypes = {
  showProposeAlterationModal: PropTypes.bool.isRequired,
  dispatchChangeShowProposeAlterationModal: PropTypes.func.isRequired,
  dispatchProposeReservationAlteration: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  showProposeAlterationModal: getShowProposeAlterationModal(state),
});

const mapDispatchToProps = {
  dispatchChangeShowProposeAlterationModal: changeShowProposeAlterationModal,
  dispatchProposeReservationAlteration: proposeReservationAlterationRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    proposeHeader: 'BOOKING.ALTERATION.FORM.MODAL.PROPOSE_HEADER',
    proposeContentNotification: 'BOOKING.ALTERATION.FORM.MODAL.PROPOSE_CONTENT_NOTIFICATION',
    proposeContentConfirmation: 'BOOKING.ALTERATION.FORM.MODAL.PROPOSE_CONTENT_CONFIRMATION',
    proposeAction: 'BOOKING.ALTERATION.FORM.MODAL.PROPOSE_ACTION',
    cancel: 'TEXT.SHARED.CANCEL',
  }),
)(ProposeAlterationModal);
