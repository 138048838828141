import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';
import FlagIcon, { formatClassName } from 'components/FlagConversation/FlagIcon';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DATE_TIME_FORMAT } from 'ready/utils/constants';

import { getConversationData } from 'selectors/conversation';
import { makeGetRentalSelector } from 'selectors/decoupling';
import { getBookingData } from 'selectors/booking';

import { Text, TruncatedText, TextUppercase, Icon, Tag, Tooltip } from 'ready/components';
import PremiumClassTag from 'components/PremiumClassTag/PremiumClassTag';
import ChannelLogo from 'components/ChannelLogo/ChannelLogo';

import { variationsCssProps, variations } from 'ready/utils/constants';
import { getColor } from 'ready/styles/helpers';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

import { conversationPropType, bookingPropType } from 'common/propTypes';
import { getUserFullName } from 'utils/user';
import { getBookingCheckInDate, getBookingCheckOutDate, getRentalName } from 'utils/serializers';

const selectedStyle = ({ selected, theme }) =>
  selected &&
  css`
    background-color: ${theme.colors.white};
    border-left: ${theme.spacing.xxs} solid ${getColor(variations.OUTLINE, variationsCssProps.ACTIVE)};
    &:hover {
      box-shadow: ${theme.boxShadow.s};
    }
  `;

const BookingsWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
`;

const StyledTag = styled(Tag)`
  display: inline-flex;
  min-width: auto;
`;

const unselectedStyle = ({ theme }) =>
  css`
    border-left: ${theme.spacing.xxs} solid transparent;
    &:hover {
      background-color: ${props => props.theme.colors.n200};
    }
  `;

const Grid = styled.div`
  display: grid;
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.s}`};
  box-sizing: border-box;
  border-bottom: ${props => props.theme.app.border.default};
  cursor: pointer;
  grid-row-gap: ${props => props.theme.spacing.xxs};
  ${props => selectedStyle(props) || unselectedStyle(props)};
  ${props =>
    props.permalink &&
    `padding-top: calc(${props.theme.spacing.s} + ${props.theme.typography.textUppercase.s['line-height']})`};
  min-width: 20rem;
  position: relative;
`;

const withMarginTopStyles = ({ theme, marginTop }) =>
  marginTop &&
  css`
    margin-top: ${theme.spacing.xxs};
  `;
const SpacedRow = styled.div`
  align-items: center;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: ${props => props.theme.spacing.xxs};
  ${withMarginTopStyles};
`;

const withMinWidth = ({ minWidth }) =>
  minWidth &&
  css`
    min-width: 6.6rem;
  `;
const DoubleColumn = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.xxs};
  grid-template-columns: min-content auto;
  ${withMinWidth};
`;

const GuestNameWrapper = styled.div`
  display: flex;
  column-gap: ${props => props.theme.spacing.xxs};
`;

const PropertyTextColumn = styled(DoubleColumn)`
  grid-template-columns: min-content auto min-content;
`;

const PermalinkWrapper = styled.div`
  background-color: ${props => props.theme.colors.n200};
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.xs}`};
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ConversationRow = ({
  conversation: {
    id,
    rentals,
    guest,
    last_message,
    reservations,
    inquiries,
    permalink,
    office_name,
    default_channel,
    flag,
  },
  isSelected,
  booking,
  onClick,
  t,
}) => {
  const ref = useRef(makeGetRentalSelector({ rentals, reservations, inquiries }, isSelected, booking));
  const [rental, setRental] = useState(ref.current());
  const [visibility, setVisibility] = useState(false);
  /**
   * Make selector approach is used in order to avoid redrawing the whole list when selected or booking changes
   */
  useEffect(() => {
    ref.current = makeGetRentalSelector({ rentals, reservations, inquiries }, isSelected, booking);
    setRental(ref.current());
  }, [isSelected, (booking || {}).id]);

  return (
    <Grid
      selected={isSelected}
      onClick={onClick}
      data-qa={`ConversationList:ConversationRow--${id}`}
      permalink={permalink}
    >
      {permalink && (
        <PermalinkWrapper>
          <TextUppercase variation={TextUppercase.variations.GREY} size={TextUppercase.sizes.S}>
            {t.permalink}
          </TextUppercase>
        </PermalinkWrapper>
      )}
      <SpacedRow>
        <TextUppercase bold size={TextUppercase.sizes.S}>
          {office_name}
        </TextUppercase>
        {flag && <FlagIcon color={formatClassName(flag.class_name)} />}
      </SpacedRow>
      <SpacedRow>
        <GuestNameWrapper>
          <ChannelLogo channel={default_channel} />
          <Text
            size={Text.sizes.S}
            bold
            variation={!guest ? Text.variations.ERROR : Text.defaultProps.variation}
            data-qa="ConversationRow:Text--guestName"
          >
            {getUserFullName(guest) || t.unknownGuestName}
          </Text>
          {reservations[0] &&
            (reservations[0].is_within_top_five_reservations_except_first || reservations[0].is_first_reservation) && (
              <BookingsWrapper onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
                {visibility && (
                  <Tooltip
                    variation={Tooltip.variations.WHITE}
                    position={Tooltip.positions.BOTTOM}
                    data-qa="Bookings:Tooltip"
                  >
                    <Text size={Text.sizes.S} variation={Text.variations.DARK} data-qa="Bookings:Text">
                      {reservations[0].is_within_top_five_reservations_except_first
                        ? t.first_reservations_tooltip
                        : t.first_reservation_tooltip}
                    </Text>
                  </Tooltip>
                )}
                <StyledTag
                  variation={Tag.variations.VIOLET}
                  widthBounds
                  size={Tag.sizes.XS}
                  data-qa="FirstReservations:Tag"
                >
                  {reservations[0].is_within_top_five_reservations_except_first
                    ? t.first_reservations
                    : t.first_reservation}
                </StyledTag>
              </BookingsWrapper>
            )}
        </GuestNameWrapper>
        <DoubleColumn minWidth>
          <Icon size={Icon.sizes.S} icon={Icon.icons.CHECK_IN} />
          <Text size={Text.sizes.S}>{getBookingCheckInDate({ reservations, inquiries }, t.unknown)}</Text>
        </DoubleColumn>
      </SpacedRow>
      <SpacedRow>
        <PropertyTextColumn>
          <Icon size={Icon.sizes.S} icon={Icon.icons.HOUSE} />
          <TruncatedText size={Text.sizes.S} data-qa="ConversationRow:TruncatedText--propertyName">
            {getRentalName(rental, t.unknown)}
          </TruncatedText>
          <PremiumClassTag short premiumClass={(rental || {}).premium_class} />
        </PropertyTextColumn>
        <DoubleColumn minWidth>
          <Icon size={Icon.sizes.S} icon={Icon.icons.CHECK_OUT} />
          <Text size={Text.sizes.S}>{getBookingCheckOutDate({ reservations, inquiries }, t.unknown)}</Text>
        </DoubleColumn>
      </SpacedRow>
      {last_message && (
        <SpacedRow marginTop>
          <TruncatedText size={Text.sizes.S}>{(last_message.content || '').trim()}</TruncatedText>
          <Text size={Text.sizes.S}>
            {last_message.sent_at &&
              moment(last_message.sent_at)
                .local()
                .format(DATE_TIME_FORMAT)}
          </Text>
        </SpacedRow>
      )}
    </Grid>
  );
};

ConversationRow.propTypes = {
  conversation: conversationPropType.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  rental: PropTypes.shape({
    id: PropTypes.string.isRequired,
    premium_class: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  booking: bookingPropType,
  t: PropTypes.shape({
    unknown: PropTypes.string.isRequired,
    unknownGuestName: PropTypes.string.isRequired,
  }),
};

// Connect ConversationRow directly to state in order
// to avoid ConversationList to re-rendering when
// selected conversation changes
const mapStateToProps = (state, { conversation }) => {
  const isSelected = (getConversationData(state) || {}).id === conversation.id;
  return {
    isSelected,
    booking: isSelected ? getBookingData(state) : null,
  };
};

export default compose(
  withMappedTranslations({
    unknown: 'TEXT.SHARED.UNKNOWN',
    unknownGuestName: 'CONVERSATION.SENDER.UNKNOWN_GUEST_NAME',
    permalink: 'CONVERSATIONS.PERMALINK',
    first_reservations: 'CONVERSATIONS.FIRST_RESERVATIONS',
    first_reservation: 'CONVERSATIONS.FIRST_RESERVATION',
    first_reservations_tooltip: 'CONVERSATIONS.FIRST_RESERVATIONS_TOOLTIP',
    first_reservation_tooltip: 'CONVERSATIONS.FIRST_RESERVATION_TOOLTIP',
  }),
  connect(mapStateToProps),
)(ConversationRow);
