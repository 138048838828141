import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Notification from 'ready/components/Notification/Notification';
import { SHORT_DATE_FORMAT, SHORT_TIME_FORMAT } from 'ready/utils/constants.js';
import { inquiryPropShape } from 'common/propTypes';
import { Text } from 'ready/components/typography';

const StyledText = styled(Text)`
  margin: 0 ${({ theme }) => theme.spacing.xs};
`;

const SpecialOfferNotification = ({ specialOffer, ...props }) =>
  !specialOffer || !specialOffer.id ? (
    false
  ) : (
    <FormattedMessage
      id="SPECIAL_OFFER.NOTIFICATION"
      defaultMessage=""
      values={{
        price: specialOffer.price || 'Not set',
        currency: specialOffer.native_currency || 'EUR',
        expire_date: moment(specialOffer.expires_at).format(SHORT_DATE_FORMAT),
        expire_time: moment(specialOffer.expires_at).format(SHORT_TIME_FORMAT),
      }}
    >
      {text => (
        <Notification
          variation={Notification.variations.INFO}
          icon={Notification.icons.GIFT}
          data-qa="Notifications:Notification--specialOffer"
          {...props}
        >
          <StyledText variation={Text.variations.DARK}>{text}</StyledText>
        </Notification>
      )}
    </FormattedMessage>
  );

SpecialOfferNotification.propTypes = {
  specialOffer: inquiryPropShape.special_offer,
};

export default SpecialOfferNotification;
