import { css } from 'styled-components';
export const withPaddingRight = ({ paddingRight, theme }) =>
  paddingRight &&
  css`
    padding-right: ${theme.spacing[paddingRight]};
  `;
export const withPaddingLeft = ({ paddingLeft, theme }) =>
  paddingLeft &&
  css`
    padding-left: ${theme.spacing[paddingLeft]};
  `;
export const withPaddingTop = ({ paddingTop, theme }) =>
  paddingTop &&
  css`
    padding-top: ${theme.spacing[paddingTop]};
  `;
export const withPaddingBottom = ({ paddingBottom, theme }) =>
  paddingBottom &&
  css`
    padding-bottom: ${theme.spacing[paddingBottom]};
  `;
export const withPaddingAxis = ({ paddingV, paddingH, theme }) =>
  paddingV &&
  paddingH &&
  css`
    padding: ${theme.spacing[paddingV]} ${theme.spacing[paddingH]};
  `;
