import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Text, TextUppercase, Toggle, Button } from 'ready/components';
import { SHORT_DATE_FORMAT } from 'ready/utils/constants';
import { dateTimePropType } from 'ready/utils/propTypes';

const withDisabledStyle = ({ disabled }) =>
  disabled &&
  css`
    opacity: 0.5;
    pointer-events: none;
  `;

const Wrapper = styled.div`
  border-top: ${props => props.theme.app.border.default};
  padding: ${props => `${props.theme.spacing.s} ${props.theme.spacing.l}`};
  ${withDisabledStyle};
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spacing.xxs};
`;

const Title = styled(Text)`
  word-break: break-word;
`;

const AutomatedMessage = ({ automatedMessage: { trigger, enable, sent_date }, t, disabled, onToggle, sendMessage }) => {
  return (
    <Wrapper disabled={disabled}>
      <Title size={Text.sizes.L} bold element="p">
        {trigger}
      </Title>
      <Row>
        <Button
          data-qa="AutomatedMessage:Send"
          variation={Button.variations.OUTLINE}
          onClick={sendMessage}
          inline
          size={Button.sizes.S}
        >
          {sent_date ? t.resend : t.send}
        </Button>
        {sent_date ? (
          <TextUppercase size={TextUppercase.sizes.M} bold>
            {sent_date && `${t.sentOn} ${moment.utc(sent_date).format(SHORT_DATE_FORMAT)}`}
          </TextUppercase>
        ) : (
          <Toggle
            label={enable ? t.enabled : t.disabled}
            onClick={onToggle}
            enabled={enable}
            data-qa="AutomatedMessage:Toggle"
          />
        )}
      </Row>
    </Wrapper>
  );
};

AutomatedMessage.propTypes = {
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  automatedMessage: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    trigger: PropTypes.string,
    sent_date: dateTimePropType,
    enable: PropTypes.bool,
  }),
  t: PropTypes.shape({
    sentOn: PropTypes.string.isRequired,
    enabled: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
  }),
  updating: PropTypes.bool,
};

export default AutomatedMessage;
