import { variations, sizes } from 'ready/utils/constants';
import Icon from '../Icon/Icon';

export const VARIATIONS = {
  [variations.SUCCESS]: {
    icon: Icon.icons.CHECK_CIRCLE,
    bgColor: 'g100',
    color: 'g700',
  },
  [variations.INFO]: {
    icon: Icon.icons.INFO,
    bgColor: 'b100',
    color: 'b700',
  },
  [variations.WARNING]: {
    icon: Icon.icons.EXCLAMATION_MARK,
    bgColor: 'o100',
    color: 'o700',
  },
  [variations.ERROR]: {
    icon: Icon.icons.DANGER,
    bgColor: 'r100',
    color: 'r700',
  },
  [variations.LINK]: {
    icon: Icon.icons.LINK,
    bgColor: 'info_bg',
    color: 'b500_v2',
  },
};

export const SIZES = {
  [sizes.S]: {
    icon: Icon.sizes.S,
    text: sizes.S,
    close: Icon.sizes.XS,
  },
  [sizes.M]: {
    icon: Icon.sizes.M,
    text: sizes.M,
    close: Icon.sizes.S,
  },
  [sizes.L]: {
    icon: Icon.sizes.L,
    text: sizes.L,
    close: Icon.sizes.M,
  },
  [sizes.XL]: {
    icon: Icon.sizes.XL,
    text: sizes.XL,
    close: Icon.sizes.L,
  },
};
