import React from 'react';
import styled from 'styled-components';

import MessageList from './components/MessageList';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  background-color: ${props => props.theme.colors.n100};
`;

const MessageArea = props => (
  <Wrapper data-qa="Conversation:MessageArea__wrapper">
    <MessageList {...props} />
  </Wrapper>
);

export default MessageArea;
