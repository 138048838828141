import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

const _getRental = state => state.rental;
export const getRental = createSelector(
  [_getRental],
  rental => rental.toJS(),
);

export const _getRentalData = state => state.rental.get('data');
export const getRentalData = createSelector(
  [_getRentalData],
  rental => (rental && rental.toJS()) || null,
);

export const getIssuesCount = createSelector(
  [_getRentalData],
  rental => (rental && rental.get('issues', List()).size) || 0,
);

export const getOfficePolicies = createSelector(
  [_getRentalData],
  rental => (rental && (rental.get('office_policies', Map()) || Map()).toJS()) || {},
);

export const getRentalId = createSelector(
  [_getRentalData],
  rental => rental && rental.get('id'),
);
