export const turquoise = {
  t100: '#EAFAFC',
  t300: '#92E4ED',
  t500: '#2CCADD',
  t600: '#1DA6B9',
  t700: '#0E8194',
  t800: '#0C6475',
  t900: '#002C31',
};

export const _blue = {
  b100: '#E9F4FF',
  b300: '#72BAFF',
  b500: '#0080FA',
  b700: '#0055A5',
  b900: '#002950',
};

export const blue_v2 = {
  b50_v2: '#f2f6fa',
  b75_v2: '#edf0f7',
  b100_v2: '#dbe0ed',
  b200_v2: '#c0cff0',
  b300_v2: '#8ba9e3',
  b400_v2: '#6e8fdb',
  b500_v2: '#4b6ccc',
  b600_v2: '#3a56a2',
  b700_v2: '#344a7d',
  b800_v2: '#283755',
  b900_v2: '#21273d',
};

export const red = {
  r100: '#FEECEC',
  r300: '#FC9494',
  r500: '#F16664',
  r700: '#CD4543',
  r900: '#320302',
};

export const green = {
  g100: '#DCFBEC',
  g300: '#8CE5BF',
  g500: '#3EAF7B',
  g700: '#1A845E',
  g900: '#013C1A',
};

export const _yellow = {
  y100: '#FFF2E2',
  y300: '#FFD39E',
  y500: '#FFB400',
  y700: '#A1680C',
  y900: '#362600',
};

export const orange = {
  o100: '#FFF2E2',
  o300: '#FFD39E',
  o500: '#FFA538',
  o700: '#D17300',
  o900: '#7C4400',
};

export const _purple = {
  pu100: '#F0E7F4',
  pu300: '#C6A0D5',
  pu500: '#944EB0',
  pu700: '#633475',
  pu900: '#311A3A',
};

export const neutral = {
  n100: '#F9FAFB',
  n200: '#EEF0F4',
  n300: '#CFD0D5',
  n400: '#C0C1C6',
  n500: '#868A96',
  n600: '#656A76',
  n700: '#545862',
  n800: '#3D3F46',
  n900: '#222428',
};

export const accent_colors = {
  ac_pink: '#dc267fff',
  ac_purple: '#aa4399ff',
  ac_violet: '#775ff0ff',
  ac_blue: '#339cfaff',
  ac_green: '#1cc75cff',
  ac_yellow: '#ffb000ff',
  ac_orange: '#fe6100ff',
  ac_black: '#242933ff',
};

export const white = '#FFFFFF';
export const black = '#0E0E10';
export const dark = blue_v2.b900_v2;
export const primary = blue_v2.b500_v2;
export const secondary = dark;
export const success = '#2F9E6A';
export const error = '#f44336';
export const warning = '#ed6c02';
export const grey = neutral.n500;
export const info = '#0288D1';
export const info_bg = '#E5F3FA';
export const blue = '#0288D1';
export const purple = _purple.pu500;
export const yellow = _yellow.y500;
export const outline = dark;
export const violet = '#775ff0ff';

/*
 * DEPRECATED THEMES BELLOW: to be removed
 */
export const deprecated = {
  darkCyan: blue_v2.b500_v2,
  mediumTurquoise: '#50E3C2',
  neonCarrot: '#FFA538',
  tomato: '#FF5252',
  bittersweet: '#F16664',
  splashedWhite: '#FCFCFC',
  ghostWhite: '#F7F8FC',
  isabelline: '#EBEBEB',
  grey: '#BBBBBB',
  battleshipGrey: '#828282',
  quartz: blue_v2.b900_v2,
};

/*
 * DEPRECATED THEMES BELLOW: to be removed
 */
export const paletteDeprecated = {
  primary: {
    default: blue_v2.b500_v2,
    dark: deprecated.darkCyan,
  },
  danger: {
    default: deprecated.tomato,
    dark: deprecated.bittersweet,
  },
  blurOverlay: 'rgba(216, 216, 216, 0.6)',
};

export default {
  white,
  black,
  primary,
  secondary,
  success,
  error,
  warning,
  dark,
  blue,
  purple,
  violet,
  yellow,
  info,
  info_bg,
  outline,
  ...neutral,
  ...turquoise,
  ..._blue,
  ...blue_v2,
  ...green,
  ..._yellow,
  ...red,
  ...orange,
  ..._purple,
  ...deprecated,
  ...accent_colors,
};

// darkCyan: '#4b6ccc', -------------------------> b500_v2
// mediumTurquoise: '#50E3C2',  -------------> g500
// neonCarrot: '#FFA538', -----------------------> o500
// tomato: '#FF5252',------------------------------> r500
// bittersweet: '#F16664', ------------------------> r500
// splashedWhite: '#FCFCFC', -----------------> n100
// ghostWhite: '#F7F8FC', ----------------------> n200
// isabelline: '#EBEBEB', -----------------------> n300
// grey: '#BBBBBB', ------------------------------> n700
// battleshipGrey: '#828282', ------------------> n800
// quartz: '#21273d', ----------------------------> b900_v2
// white: '#FFFFFF',  ----------------------------> white
