import qs from 'qs';

import { paths, REG_EXP } from 'constants.js';

const convert = value => {
  if (!isNaN(value)) return Number(value);
  if (value === 'true') return Boolean(value);
  if (Array.isArray(value)) return value.map(v => convert(v));
  return value;
};

/**
 * Get search query string with "?" from store state filters
 * Note: arrayFormat is 'brackets' because qs then automatically map it to array,
 * otherwise we would have to have a special treat for them
 * @param {filterPropType} search store state filters
 */
export const getSearchQuery = (search = {}) => {
  const query = qs.stringify(search, { arrayFormat: 'brackets' });
  return (query && '?').concat(query);
};

export const getSearchObj = (search = window.location.search) => {
  const queryString = qs.parse(search.replace('?', ''));
  return Object.keys(queryString).reduce((acc, key) => {
    acc[key] = convert(queryString[key]);
    return acc;
  }, {});
};

/**
 * Get url path with filters as query string
 * @param {String} pathname constants » paths
 * @param {Object} filters selectors » filters » getSelectedFilters
 */
export const getPathnameWithFilters = (pathname, filters = {}) => {
  const path = splitPathname(pathname);
  if (!path) return pathname;

  const query = getSearchQuery(filters);
  return `${pathname}${query}`;
};

// validPaths must be in appropriate order where more generic is down
const validPaths = [
  `(${paths.APP})/([0-9]+)(${paths.SAVED_REPLIES})/([0-9]+)`,
  `(${paths.APP})/([0-9]+)(${paths.SAVED_REPLIES})`,
  `(${paths.APP})/([0-9]+)`,
  `(${paths.APP})`,
];
export const splitPathname = pathname => {
  return (
    validPaths.reduce((exec, path) => {
      if (exec) {
        return exec;
      }
      const regExp = new RegExp(`^${path}$`);
      const _exec = regExp.exec(pathname);
      return (_exec && _exec.slice(1, _exec.length)) || _exec;
    }, null) || []
  );
};

export const getFullRoute = () => `${window.location.pathname}${window.location.search}`;

export const changePathnameSubRoute = (regExp, replacer, url = getFullRoute()) => {
  const r = new RegExp(regExp, 'g');
  return url.replace(r, replacer);
};

export const getSavedRepliesFullRoute = id => {
  return changePathnameSubRoute(REG_EXP.SAVED_REPLIES, (_, m1, _m2, m3) => {
    if (!id) return `${m1}${m3}`;
    return `${m1}/${id}${m3}`;
  });
};

export const getConversationsFullRoute = id =>
  changePathnameSubRoute(REG_EXP.CONVERSATION_ID, (_, m1, m2, m3, m4) => {
    if (id === '') return `${m1}${m2}${m4}`;
    if (!id) return `${m1}${m4}`;
    return `${m1}/${id}${m3}${m4}`;
  });

export const getConversationIdFromPathname = (url = window.location.pathname) => {
  const [, conversationId] = splitPathname(url);
  return (conversationId && Number(conversationId)) || null;
};
