import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { sizePropType, variationPropType } from 'ready/utils/propTypes';
import { mapKeysToLowerCase } from 'ready/utils';
import { childrenPropType } from 'ready/utils/propTypes';
import { sizes, variations, variationsCssProps } from 'ready/utils/constants';
import { getColor } from 'ready/styles/helpers';

import Button from '../Button/Button';

const { S, M } = sizes;

export const Tab = ({ children }) => <>{children}</>;

Tab.propTypes = {
  title: PropTypes.string,
  children: childrenPropType,
};

const TabList = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: ${props => `${props.theme.borderWidth.m} solid ${props.theme.colors.n200}`};
  width: 100%;
`;

const withTabSize = ({ alignTabs }) =>
  alignTabs === Tabs.alignment.FIT_CONTENT &&
  css`
    flex: 1 1 auto;
  `;

const withActiveBorder = ({ active }) =>
  active &&
  css`
    border-bottom-color: ${getColor(variations.OUTLINE, variationsCssProps.ACTIVE)};
  `;

const withBadge = ({ badge }) =>
  badge &&
  badge.show &&
  css`
    &::after {
      display: inline-block;
      content: '${badge.count >= 9 ? '9+' : badge.count}';
      color: ${getColor(badge.variation, variationsCssProps.COLOR)};
      background-color: ${getColor(badge.variation, variationsCssProps.BG)};
      height: 1.4rem;
      line-height: 1.4rem;
      width: 1.4rem;
      position: absolute;
      top: ${props => props.theme.spacing.xs};
      right: ${props => props.theme.spacing.xxs};
      border-radius: 50%;
    }
  `;

export const TabButton = styled(Button)`
  cursor: pointer;
  position: relative;
  padding: ${props => props.theme.spacing.s};
  text-align: center;
  margin-bottom: -${props => props.theme.borderWidth.m};
  border-bottom: ${props => props.theme.borderWidth.l} solid transparent;
  user-select: none;
  ${withActiveBorder};
  ${withTabSize};
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  ${withBadge};
`;

const isTabEnabled = tab => tab && !tab.props.disabled;
const getTabEnabledTabIndex = tabs => tabs.findIndex(isTabEnabled);
const getInitTab = (children, indexes) =>
  children[indexes.find(index => isTabEnabled(children[index])) || getTabEnabledTabIndex(children)];
const Tabs = ({ tabIndex, children, fixedComponents, alignTabs, size, 'data-qa': dataQA, onChange, variation }) => {
  const [visibleTabIndex, setVisibleTabIndex] = useState(tabIndex);
  const visibleTab = getInitTab(children, [visibleTabIndex, tabIndex]);

  useEffect(() => {
    const _tabIndex = isTabEnabled(children[tabIndex]) ? tabIndex : getTabEnabledTabIndex(children);
    setVisibleTabIndex(_tabIndex);
  }, [tabIndex]);

  return (
    <Fragment>
      <TabList data-qa={`[ready]${dataQA}`}>
        {React.Children.map(children, (child, index) => {
          if (!child) return false;
          const active = visibleTabIndex === index;
          const { icon, hint, title, disabled, badge } = child.props;
          return (
            <TabButton
              alignTabs={alignTabs}
              onClick={() => {
                onChange && onChange(index);
                setVisibleTabIndex(index);
              }}
              active={active}
              disabled={disabled}
              clean
              variation={variation}
              icon={icon}
              hint={hint}
              size={size}
              badge={badge}
            >
              {title}
            </TabButton>
          );
        })}
      </TabList>
      {fixedComponents}
      {visibleTab}
    </Fragment>
  );
};

Tabs.sizes = { S, M };
Tabs.alignment = {
  FIT_CONTENT: 'fit_content',
  START: 'start',
};
Tabs.icons = Button.icon.icons;
Tabs.variations = variations;
Tabs.hint = {
  positions: Button.hint.positions,
};

Tabs.propTypes = {
  children: childrenPropType.isRequired,
  fixedComponents: childrenPropType,
  size: sizePropType(Tabs.sizes),
  alignTabs: PropTypes.oneOf(mapKeysToLowerCase(Tabs.alignment)),
  'data-qa': PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  variation: variationPropType.isRequired,
  badge: PropTypes.shape({
    count: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    variation: variationPropType.isRequired,
  }),
};

Tabs.defaultProps = {
  size: Tabs.sizes.M,
  alignTabs: Tabs.alignment.START,
  'data-qa': '[ready]Tabs__list',
  tabIndex: 0,
  variation: variations.OUTLINE,
};

export default Tabs;
