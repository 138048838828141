import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import DropdownButton, { DropdownList, DropdownListItem } from 'ready/components/DropdownButton/DropdownButton';
import { conversations } from 'constants.js';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  background-color: white;
  border-bottom: ${props => props.theme.app.border.default};
  height: 3.2rem;
  justify-content: flex-end;
  padding: 0 ${props => props.theme.spacing.m};
`;

const options = Object.keys(conversations.sort.keys).map(key => key);

const SortBar = ({ onChange, intl, sortKey }) => (
  <Wrapper>
    <DropdownButton
      data-qa="SortBar:Dropdown"
      horizontalPosition={DropdownButton.horizontalPositions.LEFT}
      clean
      size={DropdownButton.sizes.S}
      icon={{ icon: DropdownButton.icon.icons.ARROW_DOWN, position: DropdownButton.icon.positions.RIGHT }}
      variation={DropdownButton.variations.OUTLINE}
      hint={{ text: intl.formatMessage({ id: 'CONVERSATIONS.SORT.TOOLTIP' }) }}
      renderButtonContent={() => intl.formatMessage({ id: `CONVERSATIONS.SORT.OPTIONS.${sortKey}` })}
      renderDropdownContent={({ closeDropdown }) => (
        <DropdownList>
          {options.map(key => {
            const selected = key === sortKey;
            return (
              <DropdownListItem
                key={key}
                onClick={() => {
                  onChange(key);
                  closeDropdown();
                }}
                selected={selected}
              >
                {intl.formatMessage({ id: `CONVERSATIONS.SORT.OPTIONS.${key}` })}
              </DropdownListItem>
            );
          })}
        </DropdownList>
      )}
    />
  </Wrapper>
);

SortBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  intl: intlShape,
  sortKey: PropTypes.oneOf(Object.keys(conversations.sort.keys)).isRequired,
};

export default SortBar;
