import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../../components';
import InPersonForm from './components/InPersonForm';
import SelfForm from './components/SelfForm';
import { Collapsible, Text } from 'ready/components';

import { bookingPropTypeWithState } from 'common/propTypes';
import { checkInMissionTypes, contexts } from 'constants.js';
import { hasErrors } from 'utils';

const Wrapper = styled(ContentWrapper)`
  min-height: 15rem;
  overflow-y: auto;
`;

const CheckIn = ({ t, onCommentBlur, booking }) => {
  if (!booking.data || !booking.data.check_in_mission || !booking.data.check_in_mission.id) return false;

  const checkIn = {
    ...booking.data.check_in_mission,
    type: booking.data.traveller_status,
    comment: booking.data.check_in_external_comment,
    self_checkin_instructions: booking.data.rental.self_checkin_instructions,
    lockbox_code: booking.data.rental.lockbox_code,
  };

  const Component = checkIn.type === checkInMissionTypes.SELF ? SelfForm : InPersonForm;

  // TODO: Temporarily removed: backend does not support it
  // const tOptions = useMemo(
  //   () =>
  //     t.typeOptions.split(';').map(text => {
  //       const [value, label] = text.split(':');
  //       return { label, value };
  //     }),
  //   [],
  // );

  // // const selected = tOptions.find(option => option.value === checkIn.type) || null;
  // // const handleOnChange = option => {
  // //   dispatchUpdateReservationRequested(booking.data.id, { traveller_status: option.value });
  // // };

  return (
    <Collapsible title={t.checkInTitle} data-qa="Tab:Collapsible--checkInMission" autoResize={!booking.isUpdating}>
      <Wrapper data-qa="CheckIn:__wrapper">
        <Component
          t={t}
          checkIn={checkIn}
          onBlur={onCommentBlur}
          hasError={hasErrors(booking.errors, contexts.infoSidebar.BOOKING)}
        >
          <FullWidthContentWithLabel label={t.type} data-qa="CheckIn:FullWidthContentWithLabel">
            <Text data-qa="CheckIn:Text--type">{t[checkIn.type]}</Text>
            {/**
              TODO: Temporarily removed: backend does not support it
              <Select
              isDisabled={booking.isUpdating}
              value={selected}
              onChange={handleOnChange}
              options={tOptions}
              t={{ noResultsText: '-', placeholder: '-' }}
              data-qa="CheckIn:Select--checkInMissionType"
            />*/}
          </FullWidthContentWithLabel>
        </Component>
      </Wrapper>
    </Collapsible>
  );
};

CheckIn.propTypes = {
  onCommentBlur: PropTypes.func.isRequired,
  booking: bookingPropTypeWithState.isRequired,
  t: PropTypes.shape({
    checkInTitle: PropTypes.string.isRequired,
    agentName: PropTypes.string.isRequired,
    agentPhoneNumber: PropTypes.string.isRequired,
    instructions: PropTypes.string.isRequired,
    lockboxCode: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    comments: PropTypes.string.isRequired,
    NOT_ARRIVED: PropTypes.string.isRequired,
    ARRIVED: PropTypes.string.isRequired,
    LATE: PropTypes.string.isRequired,
    UNKNOWN: PropTypes.string.isRequired,
    NO_SHOW: PropTypes.string.isRequired,
    CANCELLED: PropTypes.string.isRequired,
    SELF: PropTypes.string.isRequired,
  }).isRequired,
};

export default CheckIn;
