import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Text, Button, Notification, LoadingIndicator, Input } from 'ready/components';
import { FormattedMessage } from 'react-intl';
import { bookingPropTypeWithState } from 'common/propTypes';

const Container = styled.div`
  padding: 0 ${props => props.theme.spacing.xs};
`;
const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: ${props => props.theme.spacing.s} 0;
  gap: ${props => props.theme.spacing.xxs};
`;
export const StyledNotification = styled(Notification)`
  width: 100%;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.m} 0;
`;
const StyledTable = styled.table`
  width: 100%;
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.l};
  border-collapse: separate;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.s};
  margin: ${props => props.theme.spacing.l} 0;
`;
const StyledBody = styled.tbody`
  tr:not(:first-child) td {
    border-top: ${props => props.theme.app.border.default};
  }
`;
const StyledRow = styled.tr``;
const StyledCell = styled.td`
  padding: ${props => props.theme.spacing.xs};
`;
const StyledCellWithDetails = styled(StyledCell)`
  display: flex;
  flex-flow: column wrap;
`;
const StyledHeading = styled(Text)`
  color: ${props => props.theme.colors.n600};
`;
const LoadingWrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.l};
  justify-content: center;
  width: 100%;
`;
const StyledInput = styled(Input)`
  padding: ${props => props.theme.spacing.m} ${props => props.theme.spacing.s};
  border: ${props => props.theme.app.border.accent};
`;

const getCurrency = booking => {
  return booking.data ? booking.data.currency : '';
};

const CreateSpecialOffer = ({
  booking,
  specialOffer,
  dispatchPostSpecialOfferRequested,
  dispatchCloseConversationDrawer,
  t,
}) => {
  const [newPrice, setNewPrice] = useState(0);

  useEffect(() => {
    if (specialOffer.priceData.totalPrice) {
      setNewPrice(specialOffer.priceData.totalPrice);
    }
  }, [specialOffer.priceData]);

  useEffect(() => {
    if (!booking.data) {
      dispatchCloseConversationDrawer();
    }
  }, [booking]);

  return (
    <Container>
      <DetailsWrapper>
        <Text size={Text.sizes.XL}>{t.specialOffer}</Text>
        <Text>{t.specialOfferFormDetails}</Text>
        <StyledNotification variation={Notification.variations.INFO}>{t.specialOfferFormInfo}</StyledNotification>
      </DetailsWrapper>
      {specialOffer.priceData.isFetching ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        <StyledTable>
          <StyledBody>
            <StyledRow>
              <StyledCell>
                <StyledHeading>
                  <FormattedMessage
                    id="SPECIAL_OFFER.FORM.INCOME"
                    values={{
                      nights: booking.data ? booking.data.duration : '',
                    }}
                  />
                </StyledHeading>
              </StyledCell>
              <StyledCell>
                <Text>
                  {specialOffer.priceData.rentalIncome} {getCurrency(booking)}
                </Text>
              </StyledCell>
            </StyledRow>
            <StyledRow>
              <StyledCell>
                <StyledHeading>{t.specialOfferFormCleaning}</StyledHeading>
              </StyledCell>
              <StyledCell>
                <Text>
                  {specialOffer.priceData.cleaningFee} {getCurrency(booking)}
                </Text>
              </StyledCell>
            </StyledRow>
            <StyledRow>
              <StyledCell>
                <StyledHeading>{t.specialOfferFormExtra}</StyledHeading>
              </StyledCell>
              <StyledCell>
                <Text>
                  {specialOffer.priceData.extraFee} {getCurrency(booking)}
                </Text>
              </StyledCell>
            </StyledRow>
            <StyledRow>
              <StyledCell>
                <StyledHeading>{t.specialOfferFormTotal}</StyledHeading>
              </StyledCell>
              <StyledCell>
                <Text>
                  {specialOffer.priceData.totalPrice} {getCurrency(booking)}
                </Text>
              </StyledCell>
            </StyledRow>
            <StyledRow>
              <StyledCellWithDetails>
                <StyledHeading>{t.specialOfferFormEditTotal}</StyledHeading>
                <Text size={Text.sizes.S}>{t.specialOfferFormEditTotalDetails}</Text>
              </StyledCellWithDetails>
              <StyledCell>
                <StyledInput name="price" type="number" onChange={e => setNewPrice(e.target.value)} value={newPrice} />
              </StyledCell>
            </StyledRow>
          </StyledBody>
        </StyledTable>
      )}
      {specialOffer.didInvalidate && specialOffer.errors && (
        <Text variation={Text.variations.ERROR}>
          {specialOffer.errors.context.error ? specialOffer.errors.context.error : t.updateError}
        </Text>
      )}
      <ButtonsWrapper>
        <Button
          variation={Button.variations.OUTLINE}
          size={Button.sizes.M}
          inline
          onClick={dispatchCloseConversationDrawer}
        >
          {t.cancel}
        </Button>
        <Button
          variation={Button.variations.PRIMARY}
          size={Button.sizes.M}
          inline
          onClick={() => dispatchPostSpecialOfferRequested({ inquiry: booking.data.id, price: Number(newPrice) })}
        >
          {t.sendSpecialOffer}
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

CreateSpecialOffer.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  specialOffer: PropTypes.object.isRequired,
  dispatchPostSpecialOfferRequested: PropTypes.func.isRequired,
  dispatchCloseConversationDrawer: PropTypes.func.isRequired,
  t: PropTypes.shape({
    specialOffer: PropTypes.string,
    cancel: PropTypes.string,
    specialOfferFormDetails: PropTypes.string.isRequired,
    specialOfferFormInfo: PropTypes.string.isRequired,
    specialOfferFormCleaning: PropTypes.string.isRequired,
    specialOfferFormExtra: PropTypes.string.isRequired,
    specialOfferFormTotal: PropTypes.string.isRequired,
    specialOfferFormEditTotal: PropTypes.string.isRequired,
    specialOfferFormEditTotalDetails: PropTypes.string.isRequired,
  }),
};

export default CreateSpecialOffer;
