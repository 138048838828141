import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getSavedReplies } from 'selectors/savedReplies';
import {
  fetchMoreSavedRepliesRequested,
  fetchSavedRepliesRequested,
  savedReplyAddedToMessage,
} from 'ducks/savedReplies';

import Item from '../components/Item';
import { RequestError } from 'components';
import { Text, Search, InfiniteScroll, LoadingIndicator } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { savedRepliesPropTypeWithState } from 'common/propTypes';
import browserHistory from 'browserHistory';

const CenteredContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing.l};
`;

const OverflowContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: ${props => props.theme.spacing.xs};
  grid-template-rows: auto minmax(0, 1fr);
`;

const List = ({
  savedReplies,
  t,
  dispatchFetchMoreSavedRepliesRequested,
  location,
  dispatchSavedReplyAddedToMessage,
}) => {
  if (savedReplies.isFetching) {
    return (
      <CenteredContentWrapper>
        <LoadingIndicator />
      </CenteredContentWrapper>
    );
  }

  if (savedReplies.didInvalidate) {
    return (
      <CenteredContentWrapper>
        <RequestError errors={savedReplies.errors} />
      </CenteredContentWrapper>
    );
  }

  if (!savedReplies.data.length) {
    return (
      <CenteredContentWrapper>
        <Text>{t.noResultsFound}</Text>
      </CenteredContentWrapper>
    );
  }

  return (
    <OverflowContainer>
      <InfiniteScroll
        data-qa="SavedReplies:List__InfiniteScroll"
        onLoadMore={dispatchFetchMoreSavedRepliesRequested}
        items={savedReplies.data}
        hasNextPage={!!savedReplies.page.nextLink}
        hasError={savedReplies.page.didInvalidate}
        t={t}
        renderItems={_savedReplies =>
          _savedReplies.map(savedReply => (
            <Item
              key={savedReply.id}
              savedReply={savedReply}
              onViewClick={({ id }) => browserHistory.push(`${location.pathname}/${id}${location.search}`)}
              onAddClick={savedReply => dispatchSavedReplyAddedToMessage(savedReply)}
              t={t}
            />
          ))
        }
      />
    </OverflowContainer>
  );
};

List.propTypes = {
  savedReplies: savedRepliesPropTypeWithState.isRequired,
  dispatchFetchMoreSavedRepliesRequested: PropTypes.func.isRequired,
  dispatchSavedReplyAddedToMessage: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
  }).isRequired,
};

const SearchList = ({ t, dispatchFetchSavedReplyRequested, ...props }) => (
  <Container>
    <Search
      onChange={e => dispatchFetchSavedReplyRequested(e.target.value)}
      placeholder={t.placeholder}
      data-qa="SavedReplies:SearchInput"
    />
    <List t={t} {...props} />
  </Container>
);

SearchList.propTypes = {
  t: List.propTypes.t,
  dispatchFetchSavedReplyRequested: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  savedReplies: getSavedReplies(state),
});

const mapDispatchToProps = {
  dispatchFetchMoreSavedRepliesRequested: fetchMoreSavedRepliesRequested,
  dispatchSavedReplyAddedToMessage: savedReplyAddedToMessage,
  dispatchFetchSavedReplyRequested: fetchSavedRepliesRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    loadMoreError: 'CONVERSATIONS.LOAD_MORE_ERROR',
    loadMore: 'TEXT.SHARED.LOAD_MORE',
    view: 'SAVED_REPLIES.LIST.ITEM.VIEW',
    add: 'SAVED_REPLIES.LIST.ITEM.ADD',
    placeholder: 'SAVED_REPLIES.SEARCH_INPUT.PLACEHOLDER',
    noResultsFound: 'TEXT.SHARED.NO_RESULTS',
  }),
)(SearchList);
