import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible } from 'ready/components';

import { fullRentalPropType } from 'common/propTypes';

const Maintenance = ({ property, t }) => (
  <Collapsible title={t.maintenanceTitle} data-qa="Tab:Collapsible--maintenance" collapsed>
    <ContentWrapper minHeight="19.2rem">
      <MaintenanceContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

Maintenance.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    maintenanceTitle: PropTypes.string.isRequired,
    breakerLocation: PropTypes.string.isRequired,
    waterLocation: PropTypes.string.isRequired,
    trashLocation: PropTypes.string.isRequired,
  }).isRequired,
};

const MaintenanceContent = ({ property, t }) => (
  <>
    <FullWidthContentWithLabel label={t.breakerLocation}>{property.breaker_location}</FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.waterLocation}>{property.water_location}</FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.trashLocation}>{property.trash_location}</FullWidthContentWithLabel>
  </>
);

MaintenanceContent.propTypes = Maintenance.propTypes;

export default Maintenance;
