import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon/Icon';
import { variations, sizes } from 'ready/utils/constants';
import { mapKeysToLowerCase } from 'ready/utils';
import withMappedProps from './hoc/withMappedProps';
import withTextWrapper from './hoc/withTextWrapper';
import { _variationPropType, _sizePropType, notificationSizePropType } from './propTypes';

const { XL, L, M, S, XS } = sizes;

const Div = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors[props.variation]};
  border-radius: ${props => props.theme.borderRadius.m};
  display: flex;
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.m}`};
`;

const StyledIcon = styled(Icon)`
  flex: 1 0 auto;
  flex-grow: initial;
`;

const CloseIcon = styled(StyledIcon)`
  cursor: pointer;
  opacity: 0.3;
`;

const Component = ({ children, onClose, _variation, _size, icon, ...props }) => {
  return (
    <Div variation={_variation.bgColor} data-qa="[ready]Notification:__div" {...props}>
      <StyledIcon icon={icon || _variation.icon} color={_variation.color} size={_size.icon} />
      {children}
      {onClose && <CloseIcon icon={Icon.icons.CROSS} color={_variation.color} size={_size.close} onClick={onClose} />}
    </Div>
  );
};

Component.propTypes = {
  onClose: PropTypes.func,
  /**
   * Internal mapped variations according component
   */
  _variation: _variationPropType.isRequired,
  /**
   * Internal mapped size according component
   */
  _size: _sizePropType.isRequired,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const Notification = withMappedProps(withTextWrapper(Component));

Notification.sizes = { XL, L, M, S, XS };
Notification.variations = {
  SUCCESS: variations.SUCCESS,
  WARNING: variations.WARNING,
  ERROR: variations.ERROR,
  INFO: variations.INFO,
  LINK: variations.LINK,
};
Notification.icons = Icon.icons;

Notification.propTypes = {
  variation: PropTypes.oneOf(mapKeysToLowerCase(Notification.variations)).isRequired,
  size: notificationSizePropType,
};

Notification.defaultProps = {
  size: M,
  variation: variations.WARNING,
};

export default Notification;
