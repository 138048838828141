import React from 'react';
import PropTypes from 'prop-types';

import ConversationRow from '../containers/ConversationRow';
import InfiniteScroll from 'ready/components/InfiniteScroll/InfiniteScroll';

const List = ({ didInvalidate, conversations, onItemClick, onLoadMore, hasNextPage, t }) => (
  <InfiniteScroll
    onLoadMore={onLoadMore}
    items={conversations}
    hasNextPage={hasNextPage}
    hasError={didInvalidate}
    t={t}
    renderItems={items =>
      items.map(conversation => (
        <ConversationRow
          key={conversation.id}
          conversation={conversation}
          onClick={() => {
            onItemClick(conversation.id);
          }}
        />
      ))
    }
  />
);

List.propTypes = {
  conversations: PropTypes.array.isRequired,
  selectedId: PropTypes.number,
  onItemClick: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  didInvalidate: PropTypes.bool,
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
  }).isRequired,
};

export default List;
