import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Text, Icon } from 'ready/components';

const Container = styled.div`
  display: grid;
  grid-template-rows: 4.4rem 1fr auto;
  height: 30rem;
  position: relative;
  justify-items: center;
`;

const Keyboard = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.xxs};
  grid-row-gap: ${props => props.theme.spacing.xxs};
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, auto);
  margin-bottom: ${props => props.theme.spacing.xs};
  width: 18.2rem;
`;

const DigitsText = styled(Text)`
  margin: ${props => `${props.theme.spacing.xxs} 0 ${props.theme.spacing.s}`} 0;
  text-align: center;
`;

const DigitButton = styled(Button)`
  background-color: ${props => props.theme.colors.n200};
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  justify-self: center;
`;

const EraseButton = styled.span`
  align-items: center;
  background-color: ${props => props.theme.colors.n200};
  cursor: pointer;
  display: flex;
  height: 2rem;
  grid-column: 3 / -1;
  justify-content: center;
  justify-self: center;
  margin-left: 1rem;
  position: relative;
  width: 2.7rem;
  &::before {
    border-width: 1rem;
    border-bottom-color: transparent;
    border-right-color: ${props => props.theme.colors.n200};
    border-top-color: transparent;
    border-style: solid;
    border-left-color: transparent;
    left: -2rem;
    content: '';
    position: absolute;
  }
`;

const BackButton = styled(Button)`
  justify-self: flex-start;
`;

const Keypad = ({ t, digits, onDigitClick, onClose, onEraseClick }) => (
  <Container>
    <DigitsText size={Text.sizes.L} bold>
      {digits}
    </DigitsText>
    <Keyboard>
      {Array.from(Array(9).keys()).map(key => {
        const number = key + 1;
        return (
          <DigitButton key={number} clean variation={Button.variations.GREY} onClick={() => onDigitClick(number)}>
            {number}
          </DigitButton>
        );
      })}
      <DigitButton clean variation={Button.variations.GREY} onClick={() => onDigitClick('*')}>
        *
      </DigitButton>
      <DigitButton clean variation={Button.variations.GREY} onClick={() => onDigitClick(0)}>
        0
      </DigitButton>
      <DigitButton clean variation={Button.variations.GREY} onClick={() => onDigitClick('#')}>
        #
      </DigitButton>
      <EraseButton variation={Button.variations.GREY} onClick={onEraseClick} size={Button.sizes.S} clean inline>
        <Icon icon={Icon.icons.CROSS} size={Icon.sizes.S} color={Icon.colors.dark} />
      </EraseButton>
    </Keyboard>
    <BackButton
      icon={{ icon: Button.icon.icons.ARROW_LEFT }}
      variation={Button.variations.OUTLINE}
      clean
      onClick={onClose}
      inline
    >
      {t.back}
    </BackButton>
  </Container>
);

Keypad.propTypes = {
  digits: PropTypes.string.isRequired,
  onDigitClick: PropTypes.func.isRequired,
  onEraseClick: PropTypes.func.isRequired,
  t: PropTypes.shape({
    back: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default Keypad;
