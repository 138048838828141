import ApiModule from './ApiModule';

import { messages, savedReplies } from 'constants.js';

class DataApi extends ApiModule {
  static apiInstance({ authorization, locale } = {}) {
    if (this.instance) return this.instance;
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_PROJECT_API_URL}`;
    this.instance = new DataApi({ baseUrl, authorization, locale });
    return this.instance;
  }

  setDefaultLocale(locale) {
    super._locale = locale;
  }

  setDefaultAuthorization(authorization) {
    super._authorization = authorization;
  }

  getTokenByLogin({ username, password }) {
    return this.post('/api-token-auth/', { username, password });
  }

  getUserByToken() {
    return this.get('/authenticated_user/');
  }

  getConversationsFlaggedCount(filters = {}) {
    return this.get('/conversations/flagged_count/', { search: { ...filters } });
  }

  getConversations(filters = {}, options = {}) {
    return this.get(`/conversations/`, { search: { ...filters, ...options } });
  }

  getConversation(id, filters) {
    return this.get(`/conversations/${id}/`, { search: { ...filters } });
  }

  updateConversation(id, payload) {
    return this.put(`/conversations/${id}/`, payload);
  }

  getFilters(filters = {}) {
    return this.get(`/categories/`, { search: { ...filters } });
  }

  getSuggestedReply(id, message) {
    const idString = id.toString();
    return this.post(`/conversations/${idString}/assisted_answer/`, { message });
  }

  retrieveSuggestedReply(id, ai_answer_pk) {
    const idString = id.toString();
    return this.post(`/conversations/${idString}/retrieve_ai_answer/`, { ai_answer_pk });
  }

  getReservation(id) {
    return this.get(`/reservations/${id}/`);
  }

  updateReservation(id, payload) {
    return this.put(`/reservations/${id}/`, payload);
  }

  getInquiry(id) {
    return this.get(`/inquiries/${id}/`);
  }

  updateInquiry(id, payload) {
    return this.put(`/inquiries/${id}/`, payload);
  }

  getSavedReplies(filters = {}, page = { limit: savedReplies.page.LIMIT }) {
    return this.get(`/saved_replies/`, { search: { ...filters, ...page } });
  }

  getFilterPresets() {
    return this.get('/filter_presets/');
  }

  postFilterPreset({ name, filters }) {
    return this.post('/filter_presets/', { name, filters });
  }

  updateFilterPreset({ id, filters }) {
    return this.patch(`/filter_presets/${id}/`, { id, filters });
  }

  deleteFilterPreset(id) {
    return this.delete('/filter_presets', id);
  }

  updateSavedReplyClickCount(id) {
    return this.patch(`/saved_replies/${id}/`, { increase_counter: true });
  }

  getMessages(conversation, limit = messages.page.LIMIT) {
    return this.get('/messages/', { search: { conversation, limit } });
  }

  getFromUrl(url) {
    return this.get(url, { ignoreBaseUrl: true });
  }

  postMessage(message) {
    if (message.attachments && message.attachments.length > 0) {
      const options = {
        headers: {
          contentType: 'multipart/form-data',
        },
      };

      return this.post('/messages/', message, options);
    }
    return this.post('/messages/', message);
  }

  getGuest(id) {
    return this.get(`/guests/${id}/`);
  }

  updateGuest(id, payload) {
    return this.patch(`/guests/${id}/`, payload);
  }

  postSpecialOffer(payload) {
    return this.post(`/special_offers/`, payload);
  }

  deleteSpecialOffer(id) {
    return this.delete(`/special_offers`, id);
  }

  withdrawSpecialOffer(id) {
    return this.patch(`/special_offers/${id}/withdraw/`);
  }

  retrieveSpecialOfferPrice(id) {
    return this.get(`/inquiries/${id}/retrieve_special_offer_price/`);
  }

  getAutomatedMessages(params) {
    return this.get('/automated_messages/', { search: { ...params } });
  }

  putAutomatedMessage(id, payload) {
    return this.put(`/automated_messages/${id}/`, payload);
  }

  sendAutomatedMessage(reservationId, trigger) {
    return this.put(`/reservations/${reservationId}/`, { notify: trigger });
  }

  getRental(id) {
    return this.get(`/rentals/${id}/`);
  }

  getSmartDevices(rental) {
    return this.get('/smart_devices/', { search: { rental } });
  }

  toggleSmartDevice(deviceId) {
    return this.get(`/smart_devices/${deviceId}/toggle/`);
  }

  getAttachments(conversation) {
    return this.get('/attachments/', { search: { conversation } });
  }

  getReservationAlteration(id) {
    return this.get(`/reservation_alterations/${id}/`);
  }

  acceptReservationAlteration(id) {
    return this.post(`/reservation_alterations/${id}/accept/`);
  }

  rejectReservationAlteration(id) {
    return this.post(`/reservation_alterations/${id}/reject/`);
  }

  proposeReservationAlteration(data) {
    return this.post(`/reservation_alterations/`, data);
  }

  retrieveReservationAlterationPrice(id, data) {
    return this.post(`/reservations/${id}/retrieve_alteration_price/`, data);
  }
}

export default DataApi;
