import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { contexts } from 'constants.js';

import { Text } from 'ready/components/typography';
import { Button, LoadingIndicator } from 'ready/components';
import CollapsibleWithState from '../../../components/CollapsibleWithState';
import { cancelGuestAutoReviewPayload } from 'utils/deserializers';
import { bookingPropTypeWithState } from 'common/propTypes';

const ContentWrapper = styled.div`
  padding: ${props => `0 ${props.theme.spacing.l}`};
`;

const StyledButton = styled(Button)`
  margin-bottom: ${props => props.theme.spacing.s};
`;

const StyledText = styled(Text)`
  margin-bottom: ${props => props.theme.spacing.s};
  display: block;
`;

const AutoReview = ({ booking, dispatchUpdateReservationRequested, t }) => {
  const { isFetching, isUpdating, didInvalidate, data, errors } = booking;
  const disabled = data && data.auto_review_sent_or_disabled;

  return (
    <CollapsibleWithState
      title={t.autoReviewTitle}
      isFetching={isFetching}
      didInvalidate={didInvalidate}
      errors={errors}
      data-qa="Tab:Collapsible--autoReview"
    >
      <ContentWrapper>
        <StyledText size={Text.sizes.S} bold>
          {t.guestAutoReview}
        </StyledText>
        <StyledButton
          data-qa="AutoReview:Cancel"
          variation={Button.variations.ERROR}
          disabled={disabled}
          size={Button.sizes.S}
          hint={{ position: Button.hint.positions.TOP, enabled: disabled, text: t.autoReviewDisabled }}
          onClick={() =>
            dispatchUpdateReservationRequested(
              data.id,
              cancelGuestAutoReviewPayload(),
              contexts.infoSidebar.AUTO_REVIEW,
            )
          }
        >
          {isUpdating ? <LoadingIndicator color="white" size={LoadingIndicator.sizes.XS} /> : t.cancelAutoReview}
        </StyledButton>
      </ContentWrapper>
    </CollapsibleWithState>
  );
};

AutoReview.propTypes = {
  t: PropTypes.shape({
    autoReviewTitle: PropTypes.string.isRequired,
    guestAutoReview: PropTypes.string.isRequired,
    cancelAutoReview: PropTypes.string.isRequired,
    autoReviewDisabled: PropTypes.string.isRequired,
  }).isRequired,
  dispatchUpdateReservationRequested: PropTypes.func.isRequired,
  booking: bookingPropTypeWithState.isRequired,
};

export default AutoReview;
