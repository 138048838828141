import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingIndicator from 'ready/components/LoadingIndicator/LoadingIndicator';
import { Text } from 'ready/components/typography';

export const OuterWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  max-height: 100%;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.reversed ? 'column-reverse' : 'column')};
  flex-shrink: 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.l};
  justify-content: center;
  width: 100%;
`;

const LoadMoreWrapper = styled.div`
  padding: ${props => props.theme.spacing.xs};
  cursor: ${props => !!props.onClick && 'pointer'};
  text-align: center;
  word-break: break-word;
`;

export const LoadingPanel = () => (
  <LoadingWrapper>
    <LoadingIndicator />
  </LoadingWrapper>
);

export const ErrorPanel = ({ t }) => (
  <LoadMoreWrapper>
    <Text variation={Text.variations.ERROR}>{t.loadMoreError}</Text>
  </LoadMoreWrapper>
);

ErrorPanel.propTypes = {
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
  }).isRequired,
};

export const ManualLoadMore = ({ onLoadMore, setIsLoading, t, handleLoadMore }) => (
  <LoadMoreWrapper onClick={() => handleLoadMore({ setIsLoading, onLoadMore })}>
    <Text variation={Text.variations.PRIMARY}>{t.loadMore}</Text>
  </LoadMoreWrapper>
);

ManualLoadMore.propTypes = {
  handleLoadMore: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  t: PropTypes.shape({
    loadMore: PropTypes.string.isRequired,
  }).isRequired,
};
