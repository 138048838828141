import React from 'react';

import HintWrapper from '../components/HintWrapper';

function withHintWrapper(EnhancedComponent) {
  function wrapHint({ hint, ...props }) {
    if (!hint) return <EnhancedComponent {...props} />;

    return (
      <HintWrapper {...hint} size={props.size}>
        <EnhancedComponent {...props} />
      </HintWrapper>
    );
  }

  wrapHint.propTypes = HintWrapper.propTypes;

  return wrapHint;
}

withHintWrapper.positions = HintWrapper.positions;

export default withHintWrapper;
