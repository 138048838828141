import React from 'react';
import PropTypes from 'prop-types';

import ContentWithLabel from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible } from 'ready/components';

import { fullRentalPropType } from 'common/propTypes';
import BedroomsComponent from 'scenes/shared/InfoSidebar/components/Bedrooms';

const Bedrooms = ({ property, t }) => (
  <Collapsible title={t.bedroomsTitle} data-qa="Tab:Collapsible--Bedrooms" collapsed>
    <ContentWrapper minHeight="19.2rem">
      <BedroomsContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

Bedrooms.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    bedroomsTitle: PropTypes.string.isRequired,
    numberOfBedrooms: PropTypes.string.isRequired,
  }).isRequired,
};

const BedroomsContent = ({ property, t }) => {
  return (
    <>
      <ContentWithLabel label={t.numberOfBedrooms}>{property.bedroom_set.length}</ContentWithLabel>
      <BedroomsComponent bedrooms={property.bedroom_set} />
    </>
  );
};

BedroomsContent.propTypes = Bedrooms.propTypes;

export default Bedrooms;
