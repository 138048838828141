import { createSelector } from 'reselect';

const _getBooking = state => state.booking;
export const getBooking = createSelector(
  [_getBooking],
  booking => booking.toJS(),
);

export const _getBookingData = state => _getBooking(state).get('data');
export const getBookingData = createSelector(
  [_getBookingData],
  booking => (booking && booking.toJS()) || null,
);

export const getBookingId = createSelector(
  [_getBookingData],
  booking => booking && booking.get('id'),
);

export const getBookingIntegrationPlatform = createSelector(
  [_getBookingData],
  booking => booking && booking.get('integration_platform'),
);

export const getBookingSpecialOffer = createSelector(
  [_getBookingData],
  booking => {
    if (!booking || !booking.get('special_offer')) return {};
    return booking.get('special_offer').toJS();
  },
);
