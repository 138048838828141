import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import {
  fetchReservationAlterationSuccessed,
  fetchReservationAlterationFailed,
  acceptReservationAlterationSuccessed,
  acceptReservationAlterationFailed,
  rejectReservationAlterationSuccessed,
  rejectReservationAlterationFailed,
  proposeReservationAlterationSuccessed,
  proposeReservationAlterationFailed,
  fetchAlterationPriceSuccessed,
  fetchAlterationPriceFailed,
  FETCH_RESERVATION_ALTERATION_REQUESTED,
  ACCEPT_RESERVATION_ALTERATION_REQUESTED,
  REJECT_RESERVATION_ALTERATION_REQUESTED,
  PROPOSE_RESERVATION_ALTERATION_REQUESTED,
  FETCH_ALTERATION_PRICE_REQUESTED,
} from 'ducks/reservationAlteration';
import { closeConversationDrawer, openReservationAlterationCreateForm } from 'ducks/conversationDrawer';
import { fetchMessagesRequested } from 'ducks/messages';
import DataApi from 'api/DataApi';
import { getBooking } from 'selectors/booking';
import { fetchBookingRequested } from 'ducks/booking';
import { bookingSelectTypes } from 'constants.js';
import { getReservationAlteration } from 'selectors/reservationAlteration';

export function* handleFetchReservationAlterationRequested({ payload: { id } }) {
  try {
    const reservationAlteration = yield call([DataApi.apiInstance(), 'getReservationAlteration'], id);
    yield put(fetchReservationAlterationSuccessed(reservationAlteration));
  } catch (e) {
    yield put(fetchReservationAlterationFailed(e.errors));
  }
}

export function* handleAcceptReservationAlterationRequested({ payload: { id } }) {
  try {
    yield call([DataApi.apiInstance(), 'acceptReservationAlteration'], id);
    yield put(acceptReservationAlterationSuccessed());
    yield put(closeConversationDrawer());
    yield put(fetchMessagesRequested());
  } catch (e) {
    yield put(acceptReservationAlterationFailed(e.errors));
  }
}

export function* handleRejectReservationAlterationRequested({ payload: { id, propose, bookingId } }) {
  try {
    yield call([DataApi.apiInstance(), 'rejectReservationAlteration'], id);
    yield put(rejectReservationAlterationSuccessed());
    yield put(closeConversationDrawer());
    yield put(fetchMessagesRequested());
    if (propose && bookingId) {
      const booking = yield select(getBooking);
      if (booking.data.id !== bookingId) {
        yield put(fetchBookingRequested({ type: bookingSelectTypes.RESERVATION, id: bookingId }));
      }
      yield put(openReservationAlterationCreateForm(bookingId));
    }
  } catch (e) {
    yield put(rejectReservationAlterationFailed(e.errors));
  }
}

export function* handleProposeReservationAlterationRequested() {
  try {
    const reservationAlteration = yield select(getReservationAlteration);
    if (reservationAlteration && reservationAlteration.proposal) {
      yield call([DataApi.apiInstance(), 'proposeReservationAlteration'], reservationAlteration.proposal);
    }
    yield put(proposeReservationAlterationSuccessed());
    yield put(closeConversationDrawer());
    yield put(fetchMessagesRequested());
  } catch (e) {
    yield put(proposeReservationAlterationFailed(e.errors));
  }
}

export function* handleFetchAlterationPriceRequested({ payload: { id, data } }) {
  try {
    const response = yield call([DataApi.apiInstance(), 'retrieveReservationAlterationPrice'], id, data);
    yield put(fetchAlterationPriceSuccessed(response));
  } catch (e) {
    yield put(fetchAlterationPriceFailed(e.errors));
  }
}

export default function* watchReservationAlteration() {
  yield all([takeLatest(FETCH_RESERVATION_ALTERATION_REQUESTED, handleFetchReservationAlterationRequested)]);
  yield all([takeLatest(ACCEPT_RESERVATION_ALTERATION_REQUESTED, handleAcceptReservationAlterationRequested)]);
  yield all([takeLatest(REJECT_RESERVATION_ALTERATION_REQUESTED, handleRejectReservationAlterationRequested)]);
  yield all([takeLatest(PROPOSE_RESERVATION_ALTERATION_REQUESTED, handleProposeReservationAlterationRequested)]);
  yield all([takeLatest(FETCH_ALTERATION_PRICE_REQUESTED, handleFetchAlterationPriceRequested)]);
}
