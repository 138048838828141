import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'ready/components/typography/Text/Text';

import { sizes } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';

const { XL, L, M, S } = sizes;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.b500_v2};
  ${props => ({ ...props.theme.typography.text[props.size] })};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  text-decoration: none;
`;

const Link = ({ href, ...props }) => (href ? <StyledLink href={href} {...props} /> : <Text {...props} />);

Link.sizes = { XL, L, M, S };

Link.propTypes = {
  href: PropTypes.string,
  size: sizePropType(Link.sizes),
  className: PropTypes.string,
  bold: PropTypes.bool,
};

Link.defaultProps = {
  size: Link.sizes.M,
  className: '',
  bold: false,
};

export default Link;
