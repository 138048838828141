import pick from 'lodash.pick';

export const hasErrors = (errors, context) =>
  errors &&
  !!Object.keys(errors).filter(key => !['fetching', 'updating', 'context'].includes(key)).length &&
  (!context || errors.context === context);

export const getErrors = errors =>
  errors && pick(errors, Object.keys(errors).filter(key => !['fetching', 'updating', 'context'].includes(key)));

const processMask = (text, mask) => `${text}`.match(mask).join();
const numberFormatter = /^[0-9]*/g;
export const numberMask = number => processMask(number, numberFormatter);
const phoneNumberFormatter = /^\+{0,1}[0-9]*/g;
export const phoneNumberMask = phone => processMask(phone, phoneNumberFormatter);

export const appendString = (base, string, index) => `${base.slice(0, index)}${string}${base.slice(index)}`;

/**
 * Remove undefined, null, empty attributes from object;
 * @param {Object} object Object without nested attributes
 */
export const sanitizePayload = object => {
  const payload = { ...object };
  Object.keys(object).forEach(key => {
    if (!object[key] || (Array.isArray(object[key]) && !object[key].length)) delete payload[key];
  });
  return payload;
};
