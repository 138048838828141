import React from 'react';
import PropTypes from 'prop-types';
import { Collapsible } from 'ready/components';
import { ContentWrapper, Paragraph } from 'scenes/shared/InfoSidebar/components';
import styled from 'styled-components';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 2.4rem;
`;

const Policy = ({ title, content, collapsed, 'data-qa': dataQA }) => (
  <Collapsible title={title} data-qa={dataQA} collapsed={collapsed}>
    <ContentWrapper minHeight="0">
      {content ? (
        <StyledParagraph element="div" dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <StyledParagraph>–</StyledParagraph>
      )}
    </ContentWrapper>
  </Collapsible>
);

Policy.propTypes = {
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  content: PropTypes.string.isRequired,
  'data-qa': PropTypes.string,
};

export default Policy;
