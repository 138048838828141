import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';

import { sizePropType } from 'ready/utils/propTypes';

const MuteAction = ({ t, muted, onClick, size, noLabel }) => {
  const variation = muted ? ActionButton.variations.GREY : ActionButton.variations.OUTLINE;

  let label = '';
  if (!noLabel) label = muted ? t.unmute : t.mute;

  return (
    <ActionButton variation={variation} icon={ActionButton.icons.MUTE} size={size} onClick={onClick} label={label} />
  );
};

MuteAction.sizes = ActionButton.sizes;

MuteAction.propTypes = {
  t: PropTypes.shape({
    unmute: PropTypes.string.isRequired,
    mute: PropTypes.string.isRequired,
  }),
  muted: PropTypes.bool,
  noLabel: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  size: sizePropType(MuteAction.sizes).isRequired,
};

MuteAction.defaultProps = {
  size: MuteAction.sizes.M,
};

export default MuteAction;
