import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { getMessageData } from 'selectors/message';
import {
  sendMessageSuccessed,
  sendMessageFailed,
  SEND_MESSAGE_REQUESTED,
  clearMessageContent,
  fetchSuggestedReplySuccessed,
  FETCH_SUGGESTED_REPLY_REQUESTED,
  fetchSuggestedReplyFailed,
  clearAttachments,
} from 'ducks/message';
import DataApi from 'api/DataApi';
import { getLastMessage } from 'selectors/messages';
import { fetchAttachmentsRequested } from 'ducks/attachments';

export function* handleSendMessageRequested({ payload }) {
  try {
    const messageData = (payload && payload.message) || (yield select(getMessageData));
    const hasAttachments = messageData.attachments && messageData.attachments.length > 0;
    const last_message = yield select(getLastMessage);
    messageData.last_message_id = last_message ? last_message.id : null;
    if (hasAttachments) {
      messageData.content = '';
    }
    const message = yield call([DataApi.apiInstance(), 'postMessage'], messageData);
    yield put(sendMessageSuccessed(message));
    if (!hasAttachments) {
      yield put(clearMessageContent());
    }
    yield put(clearAttachments());
    yield put(fetchAttachmentsRequested(messageData.conversation));
  } catch (e) {
    yield put(sendMessageFailed(e.errors));
  }
}

export function* handleFetchSuggestedReplyRequested({ payload: { conversationId, message } }) {
  const MAX_TRIES = 10;
  try {
    const { ai_answer_pk: aiAnswerPk } = yield call(
      [DataApi.apiInstance(), 'getSuggestedReply'],
      conversationId,
      message,
    );
    let tries = 0;
    let textValue = '';
    do {
      const { message: responseMessage } = yield call(
        [DataApi.apiInstance(), 'retrieveSuggestedReply'],
        conversationId,
        aiAnswerPk,
      );
      textValue = responseMessage;
      if (textValue) {
        break;
      }
      if (tries > MAX_TRIES) {
        yield put(fetchSuggestedReplyFailed());
        break;
      }
      tries++;
      // Wait for some time before making the next call
      yield new Promise(resolve => setTimeout(resolve, 4000));
    } while (true);
    yield put(fetchSuggestedReplySuccessed(textValue));
  } catch (e) {
    yield put(fetchSuggestedReplyFailed(e.errors));
  }
}

export default function* watchMessage() {
  yield all([
    takeLatest(SEND_MESSAGE_REQUESTED, handleSendMessageRequested),
    takeLatest(FETCH_SUGGESTED_REPLY_REQUESTED, handleFetchSuggestedReplyRequested),
  ]);
}
