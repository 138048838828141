import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import { Text, Icon } from 'ready/components';

const BedWrapper = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.xs};
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
`;

const bedIconAlias = {
  'S-COUCH-BED': 'SINGLE_SOFA_BED',
  'D-COUCH-BED': 'DOUBLE_SOFA_BED',
};

const Bed = ({ bed_type, label, count }) => (
  <BedWrapper>
    <Icon color="primary" size={Icon.sizes.L} icon={Icon.icons[bedIconAlias[bed_type] || `${bed_type}_BED`]} />
    <Text>
      {count} x {label}
    </Text>
  </BedWrapper>
);

Bed.propTypes = {
  bed_type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const BedroomWrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.xs};
`;

export const Bedroom = ({ beds, label }) => (
  <BedroomWrapper>
    <Text size={Text.sizes.S} bold>
      {label}
    </Text>
    {Object.keys(beds).map(bedType => (
      <Bed key={beds[bedType].id} {...beds[bedType]} />
    ))}
  </BedroomWrapper>
);

Bedroom.propTypes = {
  beds: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

const BedroomsWrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.spacing.l};
  margin-bottom: ${props => props.theme.spacing.l};
  min-width: 100%;
`;

const Bedrooms = ({ bedrooms }) =>
  !!bedrooms &&
  bedrooms.length > 0 && (
    <BedroomsWrapper>
      {bedrooms.map((group, i) => (
        <Bedroom key={`${group.label}_${i}`} label={group.label} beds={groupBedsByType(group.bed_set)} />
      ))}
    </BedroomsWrapper>
  );

export const groupBedsByType = beds =>
  fromJS(beds)
    .groupBy(bed => bed.get('bed_type'))
    .mapEntries(([key, value]) => [key, value.get(0).merge({ count: value.size })])
    .toJS();

Bedrooms.propTypes = {
  bedrooms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      bed_set: PropTypes.array.isRequired,
    }),
  ),
};

export default Bedrooms;
