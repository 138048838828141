import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { variationsCssProps } from 'ready/utils/constants';
import { variationPropType, sizePropType } from 'ready/utils/propTypes';
import { getVariationOf } from 'ready/styles/helpers';
import { mapKeysToLowerCase } from 'ready/utils';

import Button from 'ready/components/Button/Button';
import { Text } from 'ready/components/typography';
import Icon from 'ready/components/Icon/Icon';
import DropdownButton from 'ready/components/DropdownButton/DropdownButton';
export { DropdownList, DropdownListItem } from 'ready/components/DropdownButton/DropdownButton';

export const splitDropdownButtonSizeVariations = {
  s: {
    textSize: Text.sizes.S,
  },
  m: {
    textSize: Text.sizes.M,
  },
  l: {
    textSize: Text.sizes.XL,
  },
};

const Wrapper = styled.div`
  display: inline-flex;
`;

const StyledButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: ${props => props.theme.colors[getVariationOf(props.variation, variationsCssProps.BORDER_COLOR)]};
`;

const StyledDropdownButton = styled(DropdownButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: ${props => props.theme.spacing.xs};
  padding-right: ${props => props.theme.spacing.xs};
  border-left: none;
`;

const SplitDropdownButton = ({
  size,
  variation,
  renderButtonContent,
  renderDropdownButtonContent,
  renderDropdownContent,
  onClick,
  className,
  horizontalPosition,
  verticalPosition,
  isOpen,
  disabled,
  'data-qa': dataQA,
}) => (
  <Wrapper className={className} data-qa={`[ready]${dataQA}`}>
    <StyledButton
      size={size}
      onClick={onClick}
      variation={variation}
      disabled={disabled}
      data-qa="SplitDropdownButton:Button"
    >
      {renderButtonContent({ variation, textSize: splitDropdownButtonSizeVariations[size].textSize })}
    </StyledButton>
    <StyledDropdownButton
      variation={variation}
      size={size}
      renderButtonContent={renderDropdownButtonContent}
      renderDropdownContent={renderDropdownContent}
      horizontalPosition={horizontalPosition}
      verticalPosition={verticalPosition}
      isOpen={isOpen}
      disabled={disabled}
      data-qa="SplitDropdownButton:DropdownButton"
    />
  </Wrapper>
);

SplitDropdownButton.sizes = DropdownButton.sizes;
SplitDropdownButton.horizontalPositions = DropdownButton.horizontalPositions;
SplitDropdownButton.verticalPositions = DropdownButton.verticalPositions;
SplitDropdownButton.variations = DropdownButton.variations;

const defaultDropdownButtonContent = ({ variation, size, _isOpen }) => (
  <Icon
    size={size}
    color={getVariationOf(variation, _isOpen ? variationsCssProps.ACTIVE : variationsCssProps.COLOR)}
    icon={Icon.icons.ARROW_DOWN}
  />
);

defaultDropdownButtonContent.propTypes = {
  variation: variationPropType,
  size: sizePropType(SplitDropdownButton.sizes),
  'data-qa': 'SplitDropdownButton:__wrapper',
  _isOpen: PropTypes.bool,
};

SplitDropdownButton.propTypes = {
  size: sizePropType(SplitDropdownButton.sizes),
  variation: variationPropType,
  children: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  renderDropdownContent: PropTypes.func.isRequired,
  renderDropdownButtonContent: PropTypes.func,
  renderButtonContent: PropTypes.func.isRequired,
  verticalPosition: PropTypes.oneOf(mapKeysToLowerCase(SplitDropdownButton.verticalPositions)),
  horizontalPosition: PropTypes.oneOf(mapKeysToLowerCase(SplitDropdownButton.horizontalPositions)),
  disabled: PropTypes.bool,
  'data-qa': PropTypes.string,
};

SplitDropdownButton.defaultProps = {
  renderDropdownButtonContent: defaultDropdownButtonContent,
  size: SplitDropdownButton.sizes.M,
  variation: SplitDropdownButton.variations.PRIMARY,
  disabled: false,
};

export default SplitDropdownButton;
