import { Map } from 'immutable';
import { createSelector } from 'reselect';

export const getUser = state => state.user.toJS();
export const getUserData = state => state.user.toJS().data;
export const getUserIsFetching = state => state.user.get('isFetching');
export const getUserErrors = state => state.user.get('error', Map({})).toJS();
export const getUserFeatureFlags = state => (state.user.get('data') || Map({})).toJS().feature_flags || [];

export const _getTwilioPhoneNumbers = state =>
  state.user.getIn(['data', 'twilio_phone_numbers'], []).sortBy(phone => phone.get('name'));
export const getTwilioPhoneNumbers = createSelector(
  [_getTwilioPhoneNumbers],
  phone_numbers => (phone_numbers && phone_numbers.toJS()) || [],
);
