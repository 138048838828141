import { createSelector } from 'reselect';
import { filterKeys } from 'constants.js';

export const getSelectedCategoryId = state => state.filters.getIn(['data', filterKeys.CATEGORY_ID]);
export const getSelectedFlagId = state => state.filters.getIn(['data', filterKeys.FLAG_ID]);

const _getFilters = state => state.filters;
export const getFilters = createSelector(
  [_getFilters],
  filters => filters.toJS(),
);

export const getFiltersData = createSelector(
  [_getFilters],
  filters => filters.get('data').toJS(),
);

export const getFiltersLastUpdated = createSelector(
  [_getFilters],
  filters => filters.get('lastUpdated'),
);

export const getFiltersCounter = createSelector(
  [_getFilters],
  filters =>
    filters.get('data').reduce((counter, filterValue, filterKey) => {
      if (
        filterKey === filterKeys.CATEGORY_ID ||
        !filterValue ||
        (!Number.isInteger(filterValue) && (!filterValue.size && !filterValue.length))
      )
        return counter;
      return counter + 1;
    }, 0),
);
