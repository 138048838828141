import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { sizes, variations } from 'ready/utils/constants';
import { variationPropType, sizePropType } from 'ready/utils/propTypes';
import Button from 'ready/components/Button/Button';
import { mapKeysToLowerCase } from 'ready/utils';

const { S, M, L } = sizes;

const withRightAlignment = ({ horizontalPosition }) =>
  horizontalPosition === DropdownButton.horizontalPositions.RIGHT &&
  css`
    right: 0;
  `;

const withCenteredHorizontally = ({ horizontalPosition }) =>
  horizontalPosition === DropdownButton.horizontalPositions.CENTER &&
  css`
    left: 50%;
    transform: translateX(-50%);
  `;

const withTopPosition = ({ verticalPosition, theme }) =>
  verticalPosition === DropdownButton.verticalPositions.TOP &&
  css`
    bottom: 100%;
    margin-bottom: ${theme.spacing.s};
  `;

const withBottomPosition = ({ verticalPosition, theme }) =>
  verticalPosition === DropdownButton.verticalPositions.BOTTOM &&
  css`
    margin-top: ${theme.spacing.s};
  `;

const DropdownWrapper = styled.div`
  width: max-content;
  position: absolute;
  box-shadow: ${props => props.theme.boxShadow.s};
  border: ${props => `${props.theme.borderWidth.m} solid ${props.theme.colors.n200}`};
  border-radius: ${props => props.theme.borderRadius.m};
  background: ${props => props.theme.colors.white};
  ${props => withCenteredHorizontally(props) || withRightAlignment(props)};
  ${props => withTopPosition(props) || withBottomPosition(props)};
  z-index: ${props => props.theme.zIndex.topLayer};
`;

const Wrapper = styled.div`
  position: relative;
`;

export const DropdownList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: max-content;
  background: ${props => props.theme.colors.white};
`;

const withSelectedStyles = ({ theme, selected }) =>
  selected &&
  css`
    background-color: ${theme.colors.primary};
    &:not(:hover) {
      color: ${theme.colors.white};
    }
  `;
export const DropdownListItem = styled.li`
  ${props => props.theme.typography.text.s};
  cursor: pointer;
  color: ${props => props.theme.colors.n800};
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.m}`};
  ${withSelectedStyles}
  &:hover {
    background: ${props => props.theme.colors.n200};
  }
`;

const DropdownButton = ({
  variation,
  renderButtonContent,
  renderDropdownContent,
  onClick,
  verticalPosition,
  horizontalPosition,
  isOpen,
  className,
  size,
  hint,
  ...props
}) => {
  const [_isOpen, setIsOpen] = useState(isOpen);
  const dropdownRef = useRef(null);

  const handleClick = useCallback(() => {
    setIsOpen(!_isOpen);
    onClick(!_isOpen);
  }, [_isOpen]);

  const handleClickOutside = useCallback(event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (_isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [_isOpen, handleClickOutside]);

  return (
    <Wrapper ref={dropdownRef}>
      <Button
        active={_isOpen}
        className={className}
        variation={variation}
        onClick={handleClick}
        size={size}
        hint={hint && { ...hint, enabled: !_isOpen }}
        {...props}
      >
        {renderButtonContent({ variation, size, _isOpen })}
      </Button>
      {_isOpen && (
        <DropdownWrapper horizontalPosition={horizontalPosition} verticalPosition={verticalPosition}>
          {renderDropdownContent({ variation, size, _isOpen, closeDropdown: () => setIsOpen(false) })}
        </DropdownWrapper>
      )}
    </Wrapper>
  );
};

DropdownButton.sizes = { S, M, L };
DropdownButton.variations = Button.variations;
DropdownButton.verticalPositions = {
  TOP: 'top',
  BOTTOM: 'bottom',
};
DropdownButton.horizontalPositions = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};
DropdownButton.icon = Button.icon;
DropdownButton.hint = Button.hint;

DropdownButton.propTypes = {
  size: sizePropType(DropdownButton.sizes),
  variation: variationPropType,
  children: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  renderDropdownContent: PropTypes.func.isRequired,
  renderButtonContent: PropTypes.func.isRequired,
  verticalPosition: PropTypes.oneOf(mapKeysToLowerCase(DropdownButton.verticalPositions)),
  horizontalPosition: PropTypes.oneOf(mapKeysToLowerCase(DropdownButton.horizontalPositions)),
  hint: PropTypes.shape({ text: PropTypes.string, enabled: PropTypes.bool }),
};

DropdownButton.defaultProps = {
  size: DropdownButton.sizes.M,
  variation: variations.PRIMARY,
  isOpen: false,
  onClick: () => {},
  verticalPosition: DropdownButton.verticalPositions.BOTTOM,
  horizontalPosition: DropdownButton.horizontalPositions.CENTER,
  renderButtonContent: () => false,
};

export default DropdownButton;
