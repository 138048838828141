import styled from 'styled-components';

const ActionsContainer = styled.div`
  align-items: flex-end;
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.m};
  grid-template-columns: repeat(3, auto);
  justify-content: center;
`;

export default ActionsContainer;
