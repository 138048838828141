import { all, fork } from 'redux-saga/effects';

// import health from './health';
import decoupling from './decoupling';
import user from './user';
import conversations from './conversations';
import conversation from './conversation';
import filters from './filters';
import filterPresets from './filterPresets';
import messages from './messages';
import message from './message';
import booking from './booking';
import savedReplies from './savedReplies';
import callCenter from './callCenter';
import smartDevices from './smartDevices';
import specialOffer from './specialOffer';
import automatedMessages from './automatedMessages';
import rental from './rental';
import guest from './guest';
import attachments from './attachments';
import reservationAlteration from './reservationAlteration';
import conversationDrawer from './conversationDrawer';

export default function* rootSaga() {
  yield all([
    fork(decoupling),
    fork(user),
    fork(filters),
    fork(filterPresets),
    fork(conversation),
    fork(conversations),
    fork(messages),
    fork(message),
    fork(booking),
    fork(savedReplies),
    fork(callCenter),
    fork(smartDevices),
    fork(specialOffer),
    fork(automatedMessages),
    fork(rental),
    fork(guest),
    fork(attachments),
    fork(reservationAlteration),
    fork(conversationDrawer),
  ]);
}
