import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from 'ready/components';
import { FormattedMessage } from 'react-intl';
import Select from 'ready/components/Input/Select/Select';

const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: min-content;
  grid-column-gap: ${props => props.theme.spacing.s};
  grid-auto-flow: column;
  align-items: center;
`;

const FilterPresetOption = ({ onDelete, label, value, ...props }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = e => {
    e.stopPropagation();
    setIsDeleting(true);
  };

  const handleConfirmDelete = e => {
    e.stopPropagation();
    onDelete(value);
    setIsDeleting(false);
  };

  const handleCancel = e => {
    e.stopPropagation();
    setIsDeleting(false);
  };

  const textContent = (
    <FormattedMessage id="FILTERS.DELETE_PRESET_CONFIRMATION" values={{ preset: label }}>
      {txt => (isDeleting ? txt : label)}
    </FormattedMessage>
  );
  const actionButtons = isDeleting ? (
    <>
      <Icon data-qa="LoadFilterPreset:Button--confirm-delete" onClick={handleConfirmDelete} icon={Icon.icons.CHECK} />
      <Icon data-qa="LoadFilterPreset:Button--cancel" onClick={handleCancel} icon={Icon.icons.CROSS} />
    </>
  ) : (
    <Icon data-qa="LoadFilterPreset:Button--delete" icon={Icon.icons.TRASH} onClick={handleDelete} />
  );
  return (
    <Select.components.Option className="data-qa_ReactSelect-Option" {...props}>
      <OptionWrapper>
        {textContent}
        {actionButtons}
      </OptionWrapper>
    </Select.components.Option>
  );
};

FilterPresetOption.propTypes = {
  onDelete: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default FilterPresetOption;
