import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { getVariationOf } from 'ready/styles/helpers';
import { variationsCssProps, sizes, variations } from 'ready/utils/constants';
import HtmlElement from '../HtmlElement';
import { Text } from '../typography';
import Icon from 'ready/components/Icon/Icon';

const selectedStyle = ({ selected, theme, variation }) =>
  selected &&
  css`
    border: ${theme.borderWidth.m} solid ${theme.colors[getVariationOf(variation, variationsCssProps.BG)]};
  `;

const unselectedStyle = ({ theme }) =>
  css`
    border: ${theme.borderWidth.m} solid ${theme.colors.n300};
  `;

const Wrapper = styled(HtmlElement)`
  background-color: ${props => props.theme.colors[getVariationOf(props.variation, variationsCssProps.BG)]};
  border-radius: ${props => props.theme.borderRadius.m};
  height: 2.4rem;
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.xs}`};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${props => selectedStyle(props) || unselectedStyle(props)};
`;

const StyledIcon = styled(Icon)`
  margin-left: ${props => props.theme.spacing.xs};
  transition: ${props => props.theme.transition.default};
  transform: ${props => props.selected && 'rotate(45deg)'};
`;

const RemovableTag = ({ selected, className, label, onAdd, onRemove }) => {
  const variation = selected ? variations.PRIMARY : variations.WHITE;
  return (
    <Wrapper
      element="div"
      blacklist={{ selected: true, variation: true }}
      selected={selected}
      variation={variation}
      className={className}
      onClick={selected ? onRemove : onAdd}
    >
      <Text size={sizes.S} variation={getVariationOf(variation, variationsCssProps.COLOR)}>
        {label}
      </Text>
      <StyledIcon
        color={getVariationOf(variation, variationsCssProps.COLOR)}
        selected={selected}
        icon={Icon.icons.PLUS}
        size={Icon.sizes.XS}
      />
    </Wrapper>
  );
};

RemovableTag.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

RemovableTag.defaultProps = {
  selected: false,
};

export default RemovableTag;
