import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { bookingPropTypeWithState } from 'common/propTypes';

import { FormattedMessage } from 'react-intl';
import { Text, Button } from 'ready/components';
import AlterationDetailsTable from './components/AlterationDetailsTable';
import EditPriceForm from './components/EditPriceForm';
import EditGuestsForm from './components/EditGuestsForm';
import EditDateForm, { DateTypes } from './components/EditDateForm';

const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 ${props => props.theme.spacing.s} ${props => props.theme.spacing.s} ${props => props.theme.spacing.s};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.s};
  margin: ${props => props.theme.spacing.m} 0;
`;

const CreateReservationAlteration = ({
  booking,
  selectedBookingId,
  suggestedAlterationPrice,
  dispatchChangeShowProposeAlterationModal,
  dispatchFetchAlterationPriceRequested,
  setReservationAlterationProposal,
  errors,
  isCreating,
  t,
}) => {
  const [showDefaultForm, setShowDefaultForm] = useState(true);
  const [showEditCheckInForm, setShowEditCheckInForm] = useState(false);
  const [showEditCheckOutForm, setShowEditCheckOutForm] = useState(false);
  const [showEditGuestsForm, setShowEditGuestsForm] = useState(false);
  const [showEditPriceForm, setShowEditPriceForm] = useState(false);
  const [showError, setShowError] = useState(false);

  const [reservation, setReservation] = useState(null);
  const [reservationAlteration, setReservationAlteration] = useState({
    start_date: null,
    end_date: null,
    adults: 0,
    children: 0,
    pets: 0,
    total_price: 0,
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const [newCheckIn, setNewCheckIn] = useState(null);
  const [newCheckOut, setNewCheckOut] = useState(null);
  const [newGuests, setNewGuests] = useState({
    adults: 0,
    children: 0,
    pets: 0,
  });
  const [newPrice, setNewPrice] = useState(0);

  function getTotalPrice() {
    return (
      parseInt(newPrice) +
      suggestedAlterationPrice.cleaningFee +
      suggestedAlterationPrice.extraFees -
      suggestedAlterationPrice.platformFee
    );
  }

  useEffect(() => {
    if (booking.data && booking.data.id === selectedBookingId && !reservation) {
      setReservation(booking.data);
    }
  }, [booking]);

  useEffect(() => {
    if (reservation) {
      setNewCheckIn(reservation.checkin_date);
      setNewCheckOut(reservation.checkout_date);
      setNewGuests({
        adults: reservation.adults,
        children: reservation.children,
        pets: reservation.pets,
      });
      setNewPrice(reservation.price);
    }
  }, [reservation]);

  useEffect(() => {
    setReservationAlteration({
      start_date: newCheckIn,
      end_date: newCheckOut,
      adults: newGuests.adults,
      children: newGuests.children,
      pets: newGuests.pets,
      total_price: newPrice,
    });
    if (reservation) {
      const hasChanges =
        newCheckIn !== reservation.checkin_date ||
        newCheckOut !== reservation.checkout_date ||
        newGuests.adults !== reservation.adults ||
        newGuests.children !== reservation.children ||
        newGuests.pets !== reservation.pets ||
        getTotalPrice() !== reservation.price;
      setIsUpdated(hasChanges);
    }
  }, [newCheckIn, newCheckOut, newGuests, newPrice]);

  useEffect(() => {
    if (reservation) {
      const requestData = {
        start_date: newCheckIn,
        end_date: newCheckOut,
        adults: newGuests.adults,
        children: newGuests.children,
      };
      dispatchFetchAlterationPriceRequested(reservation.id, requestData);
    }
  }, [newCheckIn, newCheckOut, newGuests]);

  useEffect(() => {
    if (suggestedAlterationPrice.newPrice) {
      setNewPrice(suggestedAlterationPrice.newPrice);
    }
  }, [suggestedAlterationPrice.newPrice]);

  useEffect(() => {
    if (reservation) {
      setReservationAlterationProposal({
        reservation: reservation.id,
        start_date: reservationAlteration.start_date,
        end_date: reservationAlteration.end_date,
        total_price: reservationAlteration.total_price,
        adults: reservationAlteration.adults,
        children: reservationAlteration.children,
        pets: reservationAlteration.pets,
      });
    }
  }, [reservationAlteration]);

  useEffect(() => {
    const canShowError =
      ((!suggestedAlterationPrice.isFetching && errors.fetchingPrice) || (!isCreating && errors.creating)) &&
      errors.context;
    setShowError(canShowError);
  }, [isCreating, errors]);

  useEffect(() => {
    setShowDefaultForm(!showEditCheckInForm && !showEditCheckOutForm && !showEditGuestsForm && !showEditPriceForm);
  }, [showEditCheckInForm, showEditCheckOutForm, showEditGuestsForm, showEditPriceForm]);

  return reservation ? (
    <>
      {showDefaultForm && (
        <>
          <DetailsWrapper>
            <Text size={Text.sizes.XL}>
              <FormattedMessage
                id="BOOKING.ALTERATION.PROPOSED_BY_PM"
                values={{ property_manager: reservation.rental.office.property_manager.name }}
              />
            </Text>
            <Text>
              {t.reservation} {reservation.id}
            </Text>
          </DetailsWrapper>
          <AlterationDetailsTable
            reservation={reservation}
            reservationAlteration={reservationAlteration}
            setShowEditPriceForm={setShowEditPriceForm}
            setShowEditGuestsForm={setShowEditGuestsForm}
            setShowEditCheckInForm={setShowEditCheckInForm}
            setShowEditCheckOutForm={setShowEditCheckOutForm}
            loadingFetchAlterationPrice={suggestedAlterationPrice.isFetching}
            getTotalPrice={getTotalPrice}
            t={t}
            create
          />
          {showError && (
            <Text variation={Text.variations.ERROR}>{errors.context.error ? errors.context.error : t.updateError}</Text>
          )}
          <ButtonsWrapper>
            <Button
              variation={Button.variations.PRIMARY}
              size={Button.sizes.M}
              inline
              disabled={
                (reservation.rental.maximum_stay &&
                  reservation.rental.maximum_stay <= moment.utc(newCheckOut).diff(moment.utc(newCheckIn), 'days')) ||
                moment.utc(newCheckOut).diff(moment.utc(newCheckIn), 'days') <= 0 ||
                !isUpdated ||
                suggestedAlterationPrice.isFetching ||
                isCreating
              }
              onClick={() => dispatchChangeShowProposeAlterationModal(true)}
            >
              {t.propose}
            </Button>
          </ButtonsWrapper>
        </>
      )}
      {showEditCheckInForm && (
        <EditDateForm
          date={newCheckIn}
          setDate={setNewCheckIn}
          setShowDateForm={setShowEditCheckInForm}
          minStay={reservation.rental.minimum_stay}
          type={DateTypes.CHECK_IN}
          t={t}
        />
      )}
      {showEditCheckOutForm && (
        <EditDateForm
          date={newCheckOut}
          setDate={setNewCheckOut}
          setShowDateForm={setShowEditCheckOutForm}
          minStay={reservation.rental.minimum_stay}
          type={DateTypes.CHECK_OUT}
          t={t}
        />
      )}
      {showEditGuestsForm && (
        <EditGuestsForm
          guests={newGuests}
          setGuests={setNewGuests}
          setShowEditGuestsForm={setShowEditGuestsForm}
          petsAllowed={reservation.rental.pets_allowed}
          maxGuests={reservation.rental.max_number_of_guests}
          t={t}
        />
      )}
      {showEditPriceForm && (
        <EditPriceForm
          price={parseInt(newPrice)}
          setPrice={setNewPrice}
          setShowEditPriceForm={setShowEditPriceForm}
          currency={reservation.currency}
          numberOfNights={moment.utc(newCheckOut).diff(moment.utc(newCheckIn), 'days')}
          suggestedAlterationPrice={suggestedAlterationPrice}
          getTotalPrice={getTotalPrice}
          t={t}
        />
      )}
    </>
  ) : null;
};

CreateReservationAlteration.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  selectedBookingId: PropTypes.number.isRequired,
  dispatchChangeShowProposeAlterationModal: PropTypes.func.isRequired,
  dispatchFetchAlterationPriceRequested: PropTypes.func.isRequired,
  setReservationAlterationProposal: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  suggestedAlterationPrice: PropTypes.object.isRequired,
  isCreating: PropTypes.bool.isRequired,
  t: PropTypes.shape({
    reservation: PropTypes.string.isRequired,
    propose: PropTypes.string.isRequired,
    updateError: PropTypes.string.isRequired,
  }),
};

export default CreateReservationAlteration;
