import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { OverlayBackground, Field } from 'components';
import { Modal, Text, Button, TextUppercase } from 'ready/components';
import MultiSelectFilter from '../MultiSelectFilter';

import { filterKeys } from 'constants.js';
import { filtersConfigPropType } from 'common/propTypes';

import reducer, { changeFilter, clearFilters, loadFilters } from './reducer';
import MaskedDateInput from '../MaskedDateInput';
import { phoneNumberMask } from 'utils/index';
import { Column, Container, DateWrapper, DateLabel, ActionsWrapper, Footer, ResponsiveModal } from './components';
import SaveFilterPreset from './components/SaveFilterPreset';
import LoadFilterPreset from './components/LoadFilterPreset';

const {
  CITIES,
  LANGUAGES,
  RESERVATION_ID,
  PROPERTY_NAME,
  RESERVATION_STATUSES,
  INQUIRY_STATUSES,
  RESERVATION_PLATFORMS,
  GUEST_FIRST_NAME,
  GUEST_LAST_NAME,
  GUEST_PHONE_NUMBER,
  RESERVATION_HAS_REVIEW,
} = filterKeys;

const MoreFilters = ({
  data,
  dataOptions,
  config,
  onApply,
  onClose,
  onSavePreset,
  onFetchPresets,
  onDeletePreset,
  filterPresets,
  onChangeIsLoadPresetOpen,
  onChangeIsSavePresetOpen,
  t,
}) => {
  const [state, dispatch] = useReducer(reducer, { data, dataOptions, config });

  const handleFieldChange = e => {
    dispatch(changeFilter(e.target.name, e.target.value));
  };

  const handleSelectChange = (options, name) => {
    dispatch(changeFilter(name, options));
  };

  const handleClearFilters = () => dispatch(clearFilters());

  const handleApplyFilters = () => {
    onApply(state.data);
    onClose();
  };

  const handleSaveFilterPreset = value => {
    onSavePreset({
      name: value.label,
      filters: state.data,
      id: value.__isNew__ ? null : value.value,
    });
  };

  const handleLoadFilterPreset = id => {
    dispatch(loadFilters(filterPresets.data.find(preset => preset.id === id).filters));
    dispatch(onChangeIsLoadPresetOpen(false));
  };

  const handleOpenLoadPreset = () => {
    onFetchPresets();
    dispatch(onChangeIsLoadPresetOpen(true));
  };

  const handleOpenSavePreset = () => {
    onFetchPresets();
    dispatch(onChangeIsSavePresetOpen(true));
  };

  const handleCloseLoadPreset = () => dispatch(onChangeIsLoadPresetOpen(false));
  const handleCloseSavePreset = () => dispatch(onChangeIsSavePresetOpen(false));

  return (
    <OverlayBackground>
      <ResponsiveModal
        data-qa="MoreFilters:Modal"
        size={Modal.sizes.M}
        closeEnabled
        heading={t.filters}
        onClose={onClose}
      >
        <Container data-qa="MoreFilters:__container">
          <Column>
            <TextUppercase bold variation={TextUppercase.variations.GREY}>
              {t.sectionGeneral}
            </TextUppercase>
            <MultiSelectFilter
              name={filterKeys.CITIES}
              options={config.cities}
              t={t}
              label={t[CITIES]}
              selected={state.dataOptions[filterKeys.CITIES]}
              onChangeSelectedFilter={handleSelectChange}
            />
            <MultiSelectFilter
              name={filterKeys.LANGUAGES}
              options={config.languages}
              t={t}
              label={t[LANGUAGES]}
              selected={state.dataOptions[filterKeys.LANGUAGES]}
              onChangeSelectedFilter={handleSelectChange}
            />
            <Field
              name={filterKeys.PROPERTY_NAME}
              placeholder={t.all}
              label={t[PROPERTY_NAME]}
              value={state.data[filterKeys.PROPERTY_NAME]}
              onChange={handleFieldChange}
            />
          </Column>
          <Column>
            <TextUppercase bold variation={TextUppercase.variations.GREY}>
              {t.sectionReservation}
            </TextUppercase>
            <Field
              name={filterKeys.RESERVATION_ID}
              placeholder={t.all}
              label={t[RESERVATION_ID]}
              value={state.data[filterKeys.RESERVATION_ID]}
              onChange={handleFieldChange}
            />
            <MultiSelectFilter
              closeMenuOnSelect={false}
              name={filterKeys.RESERVATION_STATUSES}
              options={config.reservationStatuses}
              t={t}
              label={t[RESERVATION_STATUSES]}
              selected={state.dataOptions[filterKeys.RESERVATION_STATUSES]}
              onChangeSelectedFilter={handleSelectChange}
            />
            <MultiSelectFilter
              closeMenuOnSelect={false}
              name={filterKeys.INQUIRY_STATUSES}
              options={config.inquiryStatuses}
              t={t}
              label={t[INQUIRY_STATUSES]}
              selected={state.dataOptions[filterKeys.INQUIRY_STATUSES]}
              onChangeSelectedFilter={handleSelectChange}
            />
            <MultiSelectFilter
              closeMenuOnSelect={false}
              options={config.reservationPlatforms}
              name={filterKeys.RESERVATION_PLATFORMS}
              t={t}
              label={t[RESERVATION_PLATFORMS]}
              selected={state.dataOptions[filterKeys.RESERVATION_PLATFORMS]}
              onChangeSelectedFilter={handleSelectChange}
            />
            <MultiSelectFilter
              closeMenuOnSelect={false}
              options={config.reservationHasReview}
              name={filterKeys.RESERVATION_HAS_REVIEW}
              t={t}
              label={t[RESERVATION_HAS_REVIEW]}
              selected={state.dataOptions[filterKeys.RESERVATION_HAS_REVIEW]}
              onChangeSelectedFilter={handleSelectChange}
            />
          </Column>
          <Column>
            <TextUppercase bold variation={TextUppercase.variations.GREY}>
              {t.sectionGuest}
            </TextUppercase>
            <Field
              name={filterKeys.GUEST_FIRST_NAME}
              placeholder={t.all}
              label={t[GUEST_FIRST_NAME]}
              value={state.data[filterKeys.GUEST_FIRST_NAME]}
              onChange={handleFieldChange}
            />
            <Field
              name={filterKeys.GUEST_LAST_NAME}
              placeholder={t.all}
              label={t[GUEST_LAST_NAME]}
              value={state.data[filterKeys.GUEST_LAST_NAME]}
              onChange={handleFieldChange}
            />
            <Field
              name={filterKeys.GUEST_PHONE_NUMBER}
              placeholder={t.all}
              label={t[GUEST_PHONE_NUMBER]}
              value={state.data[filterKeys.GUEST_PHONE_NUMBER]}
              onChange={e => {
                e.target.value = phoneNumberMask(e.target.value);
                handleFieldChange(e);
              }}
            />
          </Column>
          <Column>
            <TextUppercase bold variation={TextUppercase.variations.GREY}>
              {t.sectionDates}
            </TextUppercase>
            <DateWrapper>
              <DateLabel bold size={DateLabel.sizes.S}>
                {t.reservationCreatedBetween}
              </DateLabel>
              <MaskedDateInput
                placeholder={t.from}
                value={state.data[filterKeys.RESERVATION_CREATED_FROM]}
                name={filterKeys.RESERVATION_CREATED_FROM}
                onChange={handleFieldChange}
              />
              <MaskedDateInput
                placeholder={t.to}
                value={state.data[filterKeys.RESERVATION_CREATED_TO]}
                name={filterKeys.RESERVATION_CREATED_TO}
                onChange={handleFieldChange}
              />
            </DateWrapper>
            <DateWrapper>
              <DateLabel bold size={DateLabel.sizes.S}>
                {t.reservationCheckInBetween}
              </DateLabel>
              <MaskedDateInput
                placeholder={t.from}
                value={state.data[filterKeys.RESERVATION_CHECK_IN_FROM]}
                name={filterKeys.RESERVATION_CHECK_IN_FROM}
                onChange={handleFieldChange}
              />
              <MaskedDateInput
                placeholder={t.to}
                value={state.data[filterKeys.RESERVATION_CHECK_IN_TO]}
                name={filterKeys.RESERVATION_CHECK_IN_TO}
                onChange={handleFieldChange}
              />
            </DateWrapper>
            <DateWrapper>
              <DateLabel bold size={DateLabel.sizes.S}>
                {t.reservationCheckOutBetween}
              </DateLabel>
              <MaskedDateInput
                placeholder={t.from}
                value={state.data[filterKeys.RESERVATION_CHECK_OUT_FROM]}
                name={filterKeys.RESERVATION_CHECK_OUT_FROM}
                onChange={handleFieldChange}
              />
              <MaskedDateInput
                placeholder={t.to}
                value={state.data[filterKeys.RESERVATION_CHECK_OUT_TO]}
                name={filterKeys.RESERVATION_CHECK_OUT_TO}
                onChange={handleFieldChange}
              />
            </DateWrapper>
          </Column>
        </Container>
        <Footer>
          <ActionsWrapper>
            <Button
              size={Button.sizes.S}
              variation={Button.variations.OUTLINE}
              onClick={handleOpenSavePreset}
              data-qa="MoreFilters:Button--savePreset"
            >
              {t.savePreset}
            </Button>
            <Button
              size={Button.sizes.S}
              variation={Button.variations.OUTLINE}
              onClick={handleOpenLoadPreset}
              data-qa="MoreFilters:Button--loadPreset"
            >
              {t.loadPreset}
            </Button>
          </ActionsWrapper>
          <ActionsWrapper>
            <Button
              size={Button.sizes.S}
              variation={Button.variations.OUTLINE}
              clean
              onClick={handleClearFilters}
              data-qa="MoreFilters:Button--clear"
            >
              <Text size={Button.sizes.S} variation={Text.variations.PRIMARY}>
                {t.clearFilters}
              </Text>
            </Button>
            <Button size={Button.sizes.S} onClick={handleApplyFilters} data-qa="MoreFilters:Button--apply">
              {t.applyFilters}
            </Button>
          </ActionsWrapper>
        </Footer>
      </ResponsiveModal>
      {filterPresets.view.isSavePresetOpen && (
        <SaveFilterPreset
          t={t}
          filterPresets={filterPresets}
          onClose={handleCloseSavePreset}
          onSave={handleSaveFilterPreset}
        />
      )}
      {filterPresets.view.isLoadPresetOpen && (
        <LoadFilterPreset
          t={t}
          filterPresets={filterPresets}
          onClose={handleCloseLoadPreset}
          onLoad={handleLoadFilterPreset}
          onDelete={onDeletePreset}
        />
      )}
    </OverlayBackground>
  );
};

MoreFilters.propTypes = {
  data: PropTypes.object,
  dataOptions: PropTypes.object,
  config: filtersConfigPropType,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSavePreset: PropTypes.func.isRequired,
  onFetchPresets: PropTypes.func.isRequired,
  onDeletePreset: PropTypes.func.isRequired,
  filterPresets: PropTypes.object,
  onChangeIsLoadPresetOpen: PropTypes.func.isRequired,
  onChangeIsSavePresetOpen: PropTypes.func.isRequired,
  t: PropTypes.shape({
    filters: PropTypes.string.isRequired,
    [CITIES]: PropTypes.string.isRequired,
    [LANGUAGES]: PropTypes.string.isRequired,
    [PROPERTY_NAME]: PropTypes.string.isRequired,
    [RESERVATION_ID]: PropTypes.string.isRequired,
    [RESERVATION_STATUSES]: PropTypes.string.isRequired,
    [INQUIRY_STATUSES]: PropTypes.string.isRequired,
    [RESERVATION_PLATFORMS]: PropTypes.string.isRequired,
    [RESERVATION_HAS_REVIEW]: PropTypes.string.isRequired,
    [GUEST_FIRST_NAME]: PropTypes.string.isRequired,
    [GUEST_LAST_NAME]: PropTypes.string.isRequired,
    [GUEST_PHONE_NUMBER]: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    reservationCreatedBetween: PropTypes.string.isRequired,
    reservationCheckInBetween: PropTypes.string.isRequired,
    reservationCheckOutBetween: PropTypes.string.isRequired,
    sectionGeneral: PropTypes.string.isRequired,
    sectionReservation: PropTypes.string.isRequired,
    sectionGuest: PropTypes.string.isRequired,
    sectionDates: PropTypes.string.isRequired,
    savePreset: PropTypes.string.isRequired,
    loadPreset: PropTypes.string.isRequired,
    clearFilters: PropTypes.string.isRequired,
    applyFilters: PropTypes.string.isRequired,
    selectPresetPlaceholder: PropTypes.string.isRequired,
    newPresetPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
};

export default MoreFilters;
