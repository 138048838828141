import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AttachmentPreview } from 'components';

import { messagePropType } from 'common/propTypes';

const Wrapper = styled.div`
  margin-top: ${props => props.theme.spacing.xs};
`;

const MediaAttachmentsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${props => props.theme.spacing.s};
  margin-top: ${props => props.theme.spacing.s};
`;

const FileAttachmentsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: ${props => props.theme.spacing.s};
  margin-top: ${props => props.theme.spacing.s};
`;

const hasAttachment = ({ attachments }) => attachments && attachments.length;

const Attachments = ({
  message,
  label,
  conversationAttachments,
  dispatchShowAttachmentsGallery,
  dispatchSetAttachmentsGalleryIndex,
}) => {
  const mediaAttachments =
    conversationAttachments &&
    conversationAttachments.data.filter(item => item.file_content_type && item.file_content_type !== 'application/pdf');
  const handleAttachmentClick = attachmentId => {
    const attachmentIndex = mediaAttachments.map(attachment => attachment.id).indexOf(attachmentId);
    dispatchSetAttachmentsGalleryIndex(attachmentIndex >= 0 ? attachmentIndex : 0);
    dispatchShowAttachmentsGallery(!conversationAttachments.gallery.toggler);
  };

  return (
    !!hasAttachment(message) && (
      <Wrapper data-qa="Message:__attachments">
        <MediaAttachmentsWrapper>
          {message.attachments
            .filter(item => item.file_content_type !== 'application/pdf')
            .map((attachment, i) => (
              <AttachmentPreview
                key={attachment.id}
                size={AttachmentPreview.sizes.M}
                src={attachment.url}
                name={attachment.name}
                type={attachment.file_content_type}
                onClick={() => handleAttachmentClick(attachment.id)}
              />
            ))}
        </MediaAttachmentsWrapper>
        <FileAttachmentsWrapper>
          {message.attachments
            .filter(item => item.file_content_type === 'application/pdf')
            .map((attachment, i) => (
              <AttachmentPreview
                key={attachment.id}
                name={attachment.name}
                type={attachment.file_content_type}
                onClick={() => window.open(attachment.url)}
              />
            ))}
        </FileAttachmentsWrapper>
      </Wrapper>
    )
  );
};

Attachments.propTypes = {
  message: messagePropType.isRequired,
  label: PropTypes.string.isRequired,
  conversationAttachments: PropTypes.object,
  dispatchShowAttachmentsGallery: PropTypes.func,
  dispatchSetAttachmentsGalleryIndex: PropTypes.func,
};

export default Attachments;
