import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HtmlElement from 'ready/components/HtmlElement';
import { sizes, variations } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';
import { variationPropType } from 'ready/utils/propTypes';

const { L, M, S } = sizes;

const withFontStyle = ({ theme, size }) => ({ ...theme.typography.heading[size] });

const StyledHeading = styled(HtmlElement)`
  color: ${props => props.theme.colors[props.variation]};
  margin: 0;
  ${withFontStyle};
`;

const Heading = props => <StyledHeading {...props} blacklist={{ size: true, variation: true }} />;

Heading.sizes = { L, M, S };
Heading.variations = variations;

Heading.propTypes = {
  size: sizePropType(Heading.sizes),
  className: PropTypes.string,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variation: variationPropType,
};

Heading.defaultProps = {
  element: 'h2',
  size: Heading.sizes.M,
  className: '',
  children: null,
  variation: variations.DARK,
};

export default Heading;
