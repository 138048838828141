import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { DATE_TIME_FORMAT } from 'ready/utils/constants';
import { Text, LoadingIndicator } from 'ready/components';

import { getUserFullName } from 'utils/user';
import { getPropertyManagerName } from 'utils/serializers';

import { messagePropType, conversationPropTypeWithState } from 'common/propTypes';
import { messageTypes, messageOrigins } from 'constants.js';

const MessageInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spacing.s};
  justify-content: flex-start;
  margin-top: ${props => props.theme.spacing.xs};
  padding-left: ${props => props.theme.spacing.l};
`;

const getSenderName = ({ sender, sender_user }, conversation, t) => {
  if (conversation.isFetching) return <LoadingIndicator size={LoadingIndicator.sizes.XS} />;
  if (sender === messageTypes.GUEST)
    return (conversation.data && getUserFullName(conversation.data.guest)) || t.unknownSenderName;

  const property_manager = getPropertyManagerName(conversation.data);
  const propertyManagerSender = !!property_manager && (
    <FormattedMessage id="CONVERSATION.SENDER.PROPERTY_MANAGER_STAFF" values={{ property_manager }} />
  );

  return getUserFullName(sender_user) || propertyManagerSender || t.staffSenderPlaceholder;
};

const Info = ({ message, conversation, t }) => (
  <MessageInfo data-qa="Message:__messageInfo">
    <Text bold size={Text.sizes.S} data-qa="Message:Text--senderName">
      {getSenderName(message, conversation, t)}
    </Text>
    <Text size={Text.sizes.S} variation={Text.variations.DARK}>
      {moment(message.sent_at)
        .local()
        .format(DATE_TIME_FORMAT)}
    </Text>
    {message.category === messageTypes.INTERNAL_NOTE && <Text size={Text.sizes.S}>{message.category_display}</Text>}
    {message.origin !== messageOrigins.BOOKINGSYNC && (
      <Text size={Text.sizes.S}>
        <FormattedMessage id="CONVERSATION.SENT_VIA" values={{ origin: message.origin_display }} />
      </Text>
    )}
  </MessageInfo>
);

Info.propTypes = {
  message: messagePropType.isRequired,
  conversation: conversationPropTypeWithState.isRequired,
  t: PropTypes.shape({
    unknownSenderName: PropTypes.string.isRequired,
    staffSenderPlaceholder: PropTypes.string.isRequired,
  }),
};

export default Info;
