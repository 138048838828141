import { fromJS } from 'immutable';
import { SETUP_INIT_DATA } from './view';
import { FETCH_USER_SUCCESSED } from './user';
import { mapStringToSelectOptionObj, mapArrayToSelectOptionObj } from 'utils/serializers';
import { filterKeys } from 'constants.js';

export const CHANGE_SELECTED_CITIES = 'CHANGE_SELECTED_CITIES';
export const changeSelectedCities = cities => ({ type: CHANGE_SELECTED_CITIES, payload: { cities } });

export const CHANGE_SELECTED_LANGUAGES = 'CHANGE_SELECTED_LANGUAGES';
export const changeSelectedLanguages = languages => ({ type: CHANGE_SELECTED_LANGUAGES, payload: { languages } });

export const FETCH_CATEGORIES_REQUESTED = 'FETCH_CATEGORIES_REQUESTED';
export const fetchCategoriesRequested = (options = {}) => ({ type: FETCH_CATEGORIES_REQUESTED, options });

export const FETCH_CATEGORIES_SUCCESSED = 'FETCH_CATEGORIES_SUCCESSED';
export const fetchCategoriesSuccessed = categories => ({
  type: FETCH_CATEGORIES_SUCCESSED,
  payload: { categories },
});

export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';
export const fetchCategoriesFailed = errors => ({
  type: FETCH_CATEGORIES_FAILED,
  payload: { errors },
});

export const FETCH_FLAGGED_CONVERSATIONS_COUNT_REQUESTED = 'FETCH_FLAGGED_CONVERSATIONS_COUNT_REQUESTED';
export const fetchFlaggedConversationsCountRequested = () => ({
  type: FETCH_FLAGGED_CONVERSATIONS_COUNT_REQUESTED,
});

export const FETCH_FLAGGED_CONVERSATIONS_COUNT_SUCCEEDED = 'FETCH_FLAGGED_CONVERSATIONS_COUNT_SUCCEEDED';
export const fetchFlaggedConversationsCountSucceeded = flags => ({
  type: FETCH_FLAGGED_CONVERSATIONS_COUNT_SUCCEEDED,
  payload: { flags },
});

export const FETCH_FLAGGED_CONVERSATIONS_COUNT_FAILED = 'FETCH_FLAGGED_CONVERSATIONS_COUNT_FAILED';
export const fetchFlaggedConversationsCountFailed = errors => ({
  type: FETCH_FLAGGED_CONVERSATIONS_COUNT_FAILED,
  payload: { errors },
});

export const STOP_AUTO_REFRESH = 'STOP_AUTO_REFRESH';
export const stopAutoRefreshFilters = () => ({ type: STOP_AUTO_REFRESH });

export const START_AUTO_REFRESH = 'START_AUTO_REFRESH';
export const startAutoRefreshFilters = () => ({ type: START_AUTO_REFRESH });

export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const changeFilters = filters => ({ type: CHANGE_FILTERS, payload: { filters } });

export const CHANGE_SELECTED_FILTER = 'CHANGE_SELECTED_FILTER';
/**
 * Change selected filter state data key to value
 * @param {String} key filter key name to be changed
 * @param {String} value filter value to be updated
 */
export const changeSelectedFilter = (key, value) => ({ type: CHANGE_SELECTED_FILTER, payload: { key, value } });

export const APPLY_SELECTED_FILTERS = 'APPLY_SELECTED_FILTERS';
/**
 * Trigger integration actions that apply selected filter state data
 */
export const applySelectedFilters = () => ({ type: APPLY_SELECTED_FILTERS });

export const CHANGE_AND_APPLY_SELECTED_FILTER = 'CHANGE_AND_APPLY_SELECTED_FILTER';
/**
 * Change selected filter state data key to value and immediately trigger integration actions that apply them
 * @param {String} key filter key name to be changed
 * @param {String} value filter value to be updated
 */
export const changeAndApplySelectedFilter = (key, value) => ({
  type: CHANGE_AND_APPLY_SELECTED_FILTER,
  payload: { key, value },
});

const {
  CITIES,
  LANGUAGES,
  PROPERTY_NAME,
  CATEGORY_ID,
  RESERVATION_ID,
  RESERVATION_STATUSES,
  INQUIRY_STATUSES,
  RESERVATION_PLATFORMS,
  GUEST_FIRST_NAME,
  GUEST_LAST_NAME,
  GUEST_PHONE_NUMBER,
  RESERVATION_CREATED_FROM,
  RESERVATION_CREATED_TO,
  RESERVATION_CHECK_IN_FROM,
  RESERVATION_CHECK_IN_TO,
  RESERVATION_CHECK_OUT_FROM,
  RESERVATION_CHECK_OUT_TO,
  RESERVATION_HAS_REVIEW,
  FLAG_ID,
} = filterKeys;

export const initialState = fromJS({
  /**
   * selected option values
   */
  data: {
    [CITIES]: [],
    [LANGUAGES]: [],
    [PROPERTY_NAME]: '',
    [CATEGORY_ID]: undefined,
    [RESERVATION_ID]: undefined,
    [FLAG_ID]: undefined,
    [RESERVATION_STATUSES]: [],
    [INQUIRY_STATUSES]: [],
    [RESERVATION_PLATFORMS]: [],
    [GUEST_FIRST_NAME]: '',
    [GUEST_LAST_NAME]: '',
    [GUEST_PHONE_NUMBER]: '',
    [RESERVATION_CREATED_FROM]: '',
    [RESERVATION_CREATED_TO]: '',
    [RESERVATION_CHECK_IN_FROM]: '',
    [RESERVATION_CHECK_IN_TO]: '',
    [RESERVATION_CHECK_OUT_FROM]: '',
    [RESERVATION_CHECK_OUT_TO]: '',
    [RESERVATION_HAS_REVIEW]: '',
  },
  /**
   * selected option { label, value }
   */
  dataOptions: {
    [CITIES]: [],
    [LANGUAGES]: [],
    [RESERVATION_STATUSES]: [],
    [INQUIRY_STATUSES]: [],
    [RESERVATION_PLATFORMS]: [],
    [RESERVATION_HAS_REVIEW]: [],
  },
  /**
   * filter options [{ label, value }, ...]
   */
  config: {
    categories: [],
    cities: [],
    flags: [],
    languages: [],
    reservationStatuses: [],
    inquiryStatuses: [],
    reservationPlatforms: [],
    reservationHasReview: [{ value: false, label: 'No' }, { value: true, label: 'Yes' }],
  },
  lastUpdated: null,
  isFetching: false,
  didInvalidate: false,
  errors: {},
});

export default (state = initialState, { type, payload, options }) => {
  switch (type) {
    case SETUP_INIT_DATA:
      return state
        .update('data', updateData(payload))
        .update('dataOptions', updateDataOptions(payload, state.get('config')))
        .set('isFetching', true)
        .set('didInvalidate', false);

    case FETCH_USER_SUCCESSED:
      return state
        .setIn(['config', CITIES], fromJS((payload.user.cities || []).map(mapStringToSelectOptionObj)))
        .setIn(['config', LANGUAGES], fromJS((payload.user.languages || []).map(mapArrayToSelectOptionObj)))
        .setIn(
          ['config', RESERVATION_STATUSES],
          fromJS((payload.user.reservation_status || []).map(mapArrayToSelectOptionObj)),
        )
        .setIn(['config', INQUIRY_STATUSES], fromJS((payload.user.inquiry_status || []).map(mapArrayToSelectOptionObj)))
        .setIn(
          ['config', RESERVATION_PLATFORMS],
          fromJS((payload.user.reservation_platforms || []).map(mapStringToSelectOptionObj)),
        );

    case FETCH_CATEGORIES_REQUESTED:
      return state.set('isFetching', !options.silent).set('didInvalidate', false);

    case FETCH_CATEGORIES_SUCCESSED:
      return state
        .set('isFetching', false)
        .setIn(['config', 'categories'], fromJS(payload.categories))
        .set('lastUpdated', new Date());

    case FETCH_CATEGORIES_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case CHANGE_FILTERS:
      return state
        .update('data', updateData(payload.filters))
        .update('dataOptions', updateDataOptions(payload.filters, state.get('config')));

    case CHANGE_AND_APPLY_SELECTED_FILTER:
    case CHANGE_SELECTED_FILTER: {
      const { key, value } = payload;

      let newState = state;

      if (key === FLAG_ID && newState.hasIn(['data', CATEGORY_ID])) {
        newState = newState.deleteIn(['data', CATEGORY_ID]);
      } else if (key === CATEGORY_ID && newState.hasIn(['data', FLAG_ID])) {
        newState = newState.deleteIn(['data', FLAG_ID]);
      }

      if (!Array.isArray(value)) {
        return newState.setIn(['data', key], fromJS(value));
      }
      return newState
        .setIn(['dataOptions', key], fromJS(value))
        .setIn(['data', key], fromJS(value.map(({ value }) => value)));
    }

    case FETCH_FLAGGED_CONVERSATIONS_COUNT_REQUESTED:
      return state.set('isFetching', true);

    case FETCH_FLAGGED_CONVERSATIONS_COUNT_SUCCEEDED:
      return state.set('isFetching', false).setIn(['config', 'flags'], fromJS(payload.flags));

    case FETCH_FLAGGED_CONVERSATIONS_COUNT_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    default:
      return state;
  }
};

const updateData = payload => value => value.map((_, key) => fromJS(payload[key]) || initialState.getIn(['data', key]));

const updateDataOptions = (payload, options) => value => {
  return value.map(
    (_, key) =>
      (payload[key] &&
        fromJS(payload[key].map(selected => options.get(key).find(option => option.get('value') === selected)))) ||
      initialState.getIn(['dataOptions', key]),
  );
};
