import { all, takeLatest, put, call, select, debounce } from 'redux-saga/effects';

import {
  fetchConversationsFailed,
  fetchConversationsSuccessed,
  FETCH_CONVERSATIONS_REQUESTED,
  fetchMoreConversationsSuccessed,
  fetchMoreConversationsFailed,
  FETCH_MORE_CONVERSATIONS_REQUESTED,
  SORT_CONVERSATIONS_REQUESTED,
  CHANGE_CONVERSATIONS_SEARCH_TERM,
  fetchConversationsRequested,
} from 'ducks/conversations';
import { getNextPageLink, getConversationsSortKey, getConversationsSearchTerm } from 'selectors/conversations';
import { getFilters } from 'selectors/filters';
import DataApi from 'api/DataApi';
import { getInitConversation } from 'selectors/conversation';
import { getFiltersData } from 'selectors/filters';
import { filterKeys, conversations } from 'constants.js';
import { changeSearchRouteParam } from 'browserHistory';
import { filtersParams } from 'utils/deserializers';

export function* handleFetchConversationsRequested() {
  try {
    const selectedFilters = yield select(getFiltersData);
    const initConversation = yield select(getInitConversation);
    const sortKey = yield select(getConversationsSortKey);
    const searchTerm = yield select(getConversationsSearchTerm);
    const filters = yield select(getFilters);

    const results = initConversation ? [initConversation] : [];

    if (!selectedFilters[filterKeys.CATEGORY_ID] && !selectedFilters[filterKeys.FLAG_ID]) {
      return yield put(fetchConversationsSuccessed({ results }));
    }

    const query = { limit: conversations.page.LIMIT };
    searchTerm && (query.search = searchTerm);

    const categories = filters.config.categories;
    const selectedCategory = categories.find(category => category.id === selectedFilters[filterKeys.CATEGORY_ID]);
    if (selectedCategory && selectedCategory.is_preapproval && sortKey === conversations.sort.keys.CHECK_IN_DATE) {
      query.sort = conversations.sort.values.INQUIRIES_CHECK_IN_DATE;
    } else {
      conversations.sort.values[sortKey] && (query.sort = conversations.sort.values[sortKey]);
    }

    const params = yield call(filtersParams, selectedFilters);
    const response = yield call([DataApi.apiInstance(), 'getConversations'], params, query);
    if (response.results && response.results.length && initConversation) {
      response.results = results.concat(
        response.results.filter(conversation => conversation.id !== initConversation.id),
      );
    }

    yield put(fetchConversationsSuccessed(response));
  } catch (e) {
    yield put(fetchConversationsFailed(e.errors));
  }
}

export function* handleFetchMoreConversationsRequested() {
  try {
    const nextLink = yield select(getNextPageLink);
    const response = yield call([DataApi.apiInstance(), 'getFromUrl'], nextLink);

    const initConversation = yield select(getInitConversation);
    if (response.results && response.results.length && initConversation) {
      response.results = response.results.filter(conversation => conversation.id !== initConversation.id);
    }
    yield put(fetchMoreConversationsSuccessed(response));
  } catch (e) {
    yield put(fetchMoreConversationsFailed(e.errors));
  }
}

export function* handleSearchConversationsRequested({ payload: { term } }) {
  yield put(fetchConversationsRequested());
  yield call(changeSearchRouteParam, term);
}

export default function* watchConversations() {
  yield all([
    takeLatest([FETCH_CONVERSATIONS_REQUESTED, SORT_CONVERSATIONS_REQUESTED], handleFetchConversationsRequested),
    takeLatest(FETCH_MORE_CONVERSATIONS_REQUESTED, handleFetchMoreConversationsRequested),
    debounce(500, CHANGE_CONVERSATIONS_SEARCH_TERM, handleSearchConversationsRequested),
  ]);
}
