import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { missionPropType } from 'common/propTypes';
import { TextOrLink } from 'components';

const PreviousCleaningForm = ({ previousCleaning, t, children }) => {
  const checklistTitle = previousCleaning.checklist_url && (
    <TextOrLink href={previousCleaning.checklist_url} target="_blank">
      {t.checklistTitle}
    </TextOrLink>
  );

  return (
    <>
      <FullWidthContentWithLabel label={t.agentName}>{previousCleaning.agent_full_name}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.agentPhoneNumber}>
        {previousCleaning.agent_phone_number}
      </FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.missionID}>{previousCleaning.id}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.checklistStatus}>
        {t[previousCleaning.checklist_status]}
      </FullWidthContentWithLabel>
      <FullWidthContentWithLabel>{checklistTitle}</FullWidthContentWithLabel>
      {children}
    </>
  );
};

PreviousCleaningForm.propTypes = {
  previousCleaning: missionPropType,
  hasError: PropTypes.bool,
  children: PropTypes.node,
};

export default PreviousCleaningForm;
