import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import omit from 'lodash.omit';

import { sizes } from 'ready/utils/constants';

const withCenterStyle = ({ center }) =>
  center &&
  css`
    display: flex;
    justify-content: center;
    width: 100%;
  `;

const Container = styled.div`
  ${withCenterStyle}
`;

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ellipsis2 = offset => keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(${offset}, 0);
  }
`;

const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  };
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => `calc(${props.theme.iconSize[props.size]} * 6)`};
  height: ${props => props.theme.iconSize[props.size]};

  div {
    position: absolute;
    width: ${props => props.theme.iconSize[props.size]};
    height: ${props => props.theme.iconSize[props.size]};
    border-radius: 50%;
    background: ${props => props.color || props.theme.colors.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 0;
      animation: ${ellipsis1} 0.6s infinite;
    }

    &:nth-child(2) {
      left: 0;
      animation: ${props => ellipsis2(`calc(${props.theme.iconSize[props.size]} * 2.4)`)} 0.6s infinite;
    }

    &:nth-child(3) {
      left: ${props => `calc(${props.theme.iconSize[props.size]} * 2.4)`};
      animation: ${props => ellipsis2(`calc(${props.theme.iconSize[props.size]} * 2.4)`)} 0.6s infinite;
    }

    &:nth-child(4) {
      left: ${props => `calc(${props.theme.iconSize[props.size]} * 4.8)`};
      animation: ${ellipsis3} 0.6s infinite;
    }
  }
`;

const LoadingIndicator = ({ size, color, className, center }) => (
  <Container center={center}>
    <Wrapper className={className} size={size} color={color}>
      <div />
      <div />
      <div />
      <div />
    </Wrapper>
  </Container>
);

LoadingIndicator.sizes = omit(sizes, ['XXXS', 'XXXL']);

LoadingIndicator.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  size: sizes.S,
};

export default LoadingIndicator;
