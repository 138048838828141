import React from 'react';
import PropTypes from 'prop-types';

import { categoryPropType } from 'common/propTypes';

import FilterRow from './CategoryRow';

const CategoriesList = ({ categories, onChangeSelectedFilter }) =>
  categories.map(category => (
    <FilterRow
      key={category.id}
      onClick={() => {
        onChangeSelectedFilter(category.id, category.default_sorting);
      }}
      category={category}
    />
  ));

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(categoryPropType).isRequired,
  onChangeSelectedFilter: PropTypes.func.isRequired,
};

export default CategoriesList;
