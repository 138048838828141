import React from 'react';
import PropTypes from 'prop-types';
import { conversationPropType } from 'common/propTypes';
import { Button } from 'ready/components';

const RefreshMsgsButton = ({ conversation, dispatchFetchMessagesRequested, text }) => {
  return (
    <Button
      disabled={!conversation}
      size={Button.sizes.L}
      icon={{ icon: Button.icon.icons.REFRESH }}
      onClick={dispatchFetchMessagesRequested}
      hint={{ text }}
      clean
    />
  );
};

RefreshMsgsButton.propTypes = {
  conversation: conversationPropType,
  dispatchFetchMessagesRequested: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default RefreshMsgsButton;
