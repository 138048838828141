import React from 'react';
import styled from 'styled-components';

import { Text } from 'ready/components/typography';
import { childrenPropType } from 'ready/utils/propTypes';
import { _sizePropType, _variationPropType } from '../propTypes';

const StyledText = styled(Text)`
  margin: ${props => `0 ${props.theme.spacing.xs}`};
  width: 100%;
`;

function withTextWrapper(EnhancedComponent) {
  function wrapText({ children, ...props }) {
    return (
      <EnhancedComponent {...props}>
        {typeof children === 'string' ? (
          <StyledText size={props._size.text} variation={props._variation.color}>
            {children}
          </StyledText>
        ) : (
          children
        )}
      </EnhancedComponent>
    );
  }

  wrapText.propTypes = {
    children: childrenPropType,
    _size: _sizePropType.isRequired,
    _variation: _variationPropType.isRequired,
  };

  return wrapText;
}

export default withTextWrapper;
