import React from 'react';
import PropTypes from 'prop-types';

import { Text, Link } from 'ready/components';

import { sizes } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';

const { XL, L, M, S } = sizes;

const TextOrLink = ({ href, ...props }) => (href ? <Link href={href} {...props} /> : <Text {...props} />);

TextOrLink.sizes = { XL, L, M, S };

TextOrLink.propTypes = {
  href: PropTypes.string,
  size: sizePropType(TextOrLink.sizes),
  className: PropTypes.string,
  bold: PropTypes.bool,
};

TextOrLink.defaultProps = {
  size: TextOrLink.sizes.M,
  className: '',
  bold: false,
};

export default TextOrLink;
