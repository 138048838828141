import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Logo } from 'components';
import { paths } from 'constants.js';
import browserHistory from 'browserHistory';

const Wrapper = styled.div`
  align-items: center;
  grid-area: top-bar-left;
  display: flex;
  padding: 0 ${props => props.theme.spacing.s};
  cursor: pointer;
  width: 100%;
`;

const StyledLogo = styled(Logo)`
  height: 5rem;
  width: auto;
`;

const TopBarLeft = ({ className }) => (
  <Wrapper className={className} onClick={() => browserHistory.push(paths.ROOT)}>
    <StyledLogo variation={Logo.variations.INLINE} />
  </Wrapper>
);

TopBarLeft.propTypes = {
  className: PropTypes.string,
};

export default TopBarLeft;
