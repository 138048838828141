import * as theme from 'ready/styles/theme';
import { css } from 'styled-components';

export const border = {
  default: `${theme.borderWidth.m} solid ${theme.colors.n200}`,
  accent: `${theme.borderWidth.l} solid ${theme.colors.n400}`,
};

export const gradient = {
  filterSidebarShadow: 'linear-gradient(to right, rgba(33, 43, 54, 0.08), rgba(33, 43, 54, 0.02), rgba(33, 43, 54, 0))',
};

export const padding = {
  default: `${theme.spacing.xxs} ${theme.spacing.xs}`,
};

export const card = css`
  background-color: ${theme.colors.white};
  border: ${border.default};
  border-radius: ${theme.borderRadius.m};
`;
