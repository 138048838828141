import ApiModule from './ApiModule';

class TaskRouterApi extends ApiModule {
  static apiInstance({ authorization, locale } = {}) {
    if (this.instance) return this.instance;
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_TASK_ROUTER_API_URL}`;
    this.instance = new TaskRouterApi({ baseUrl, authorization, locale });
    return this.instance;
  }

  setDefaultLocale(locale) {
    super._locale = locale;
  }

  setDefaultAuthorization(authorization) {
    super._authorization = authorization;
  }

  getTokens() {
    return this.get('/token/');
  }

  getManagerToken() {
    return this.get('/manager_token/');
  }

  transferCall({ target, call_sid }) {
    return this.post('/calls/transfer/', { target, call_sid });
  }

  sendFeedbackSurvey({ call_sid }) {
    return this.post('/calls/send_feedback_survey/', { call_sid });
  }

  getTransfer(phone) {
    return this.get(`/call_transfer/${phone}/`);
  }

  getCallDetails(phone) {
    return this.get(`/call_settings/${phone}/`);
  }

  getCallDetailsBySid(call_sid) {
    return this.get(`/call_settings/?call_sid=${call_sid}`);
  }

  getCallReasons() {
    return this.get(`/call_reason/`);
  }

  setCallData({ call_sid, call_reason }) {
    return this.post(`/calls/call_data/`, { call_sid, call_reason });
  }

  postWorkerActivityLog(activityName) {
    return this.post(`/worker_activity_log/`, { activity_name: activityName });
  }
}

export default TaskRouterApi;
