import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { sizePropType } from 'ready/utils/propTypes';
import SearchInput from '../Input/Search/Search';

const withFocusedStyle = ({ theme, focused }) =>
  focused &&
  css`
    border-bottom: ${theme.borderWidth.m} solid ${theme.colors.primary};
  `;

const withBaseStyle = ({ theme }) => css`
  border-bottom: ${theme.borderWidth.m} solid ${theme.colors.n300};
`;

const Wrapper = styled.div`
  width: 100%;
  ${props => !props.clean && (withFocusedStyle(props) || withBaseStyle(props))};
`;

const SearchBar = ({ className, clean, ...props }) => {
  const [focused, setFocused] = useState(false);
  const elementRef = useRef(null);
  return (
    <Wrapper onClick={() => elementRef.current.focus()} focused={focused} className={className} clean={clean}>
      <SearchInput
        elementRef={elementRef}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        clean
        {...props}
      />
    </Wrapper>
  );
};

SearchBar.sizes = SearchInput.sizes;

SearchBar.propTypes = {
  onChange: PropTypes.func,
  size: sizePropType(SearchBar.sizes),
  className: PropTypes.string,
  clean: PropTypes.bool,
};

SearchBar.defaultProps = {
  size: SearchBar.sizes.M,
};

export default SearchBar;
