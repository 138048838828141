import { fromJS } from 'immutable';

import { SETUP_INIT_DATA } from './view';
import { conversations } from 'constants.js';

export const FETCH_CONVERSATIONS_REQUESTED = 'FETCH_CONVERSATIONS_REQUESTED';
export const fetchConversationsRequested = () => {
  return { type: FETCH_CONVERSATIONS_REQUESTED };
};

export const FETCH_CONVERSATIONS_SUCCESSED = 'FETCH_CONVERSATIONS_SUCCESSED';
export const fetchConversationsSuccessed = conversations => ({
  type: FETCH_CONVERSATIONS_SUCCESSED,
  payload: { conversations },
});

export const FETCH_CONVERSATIONS_FAILED = 'FETCH_CONVERSATIONS_FAILED';
export const fetchConversationsFailed = errors => ({ type: FETCH_CONVERSATIONS_FAILED, payload: { errors } });

export const FETCH_MORE_CONVERSATIONS_REQUESTED = 'FETCH_MORE_CONVERSATIONS_REQUESTED';
export const fetchMoreConversationsRequested = () => ({ type: FETCH_MORE_CONVERSATIONS_REQUESTED });

export const FETCH_MORE_CONVERSATIONS_SUCCESSED = 'FETCH_MORE_CONVERSATIONS_SUCCESSED';
export const fetchMoreConversationsSuccessed = conversations => ({
  type: FETCH_MORE_CONVERSATIONS_SUCCESSED,
  payload: { conversations },
});

export const FETCH_MORE_CONVERSATIONS_FAILED = 'FETCH_MORE_CONVERSATIONS_FAILED';
export const fetchMoreConversationsFailed = errors => ({ type: FETCH_MORE_CONVERSATIONS_FAILED, payload: { errors } });

export const UPDATE_CONVERSATIONS_CONVERSATION_SUCCESSED = 'UPDATE_CONVERSATIONS_CONVERSATION_SUCCESSED';
export const updateConversationsConversationSuccessed = conversation => ({
  type: UPDATE_CONVERSATIONS_CONVERSATION_SUCCESSED,
  payload: { conversation },
});

export const CHANGE_CONVERSATIONS_SEARCH_TERM = 'CHANGE_CONVERSATIONS_SEARCH_TERM';
export const changeConversationsSearchTerm = term => ({
  type: CHANGE_CONVERSATIONS_SEARCH_TERM,
  payload: { term },
});

export const CHANGE_CONVERSATIONS_SORT_KEY = 'CHANGE_CONVERSATIONS_SORT_KEY';
export const changeConversationsSortKey = sortKey => ({
  type: CHANGE_CONVERSATIONS_SORT_KEY,
  payload: { sortKey },
});

export const SORT_CONVERSATIONS_REQUESTED = 'SORT_CONVERSATIONS_REQUESTED';
export const sortConversationsRequested = key => ({ type: SORT_CONVERSATIONS_REQUESTED, payload: { key } });

export const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  data: [],
  errors: {},
  page: {
    nextLink: '',
    previousLink: '',
    didInvalidate: false,
    isFetching: false,
    errors: {},
  },
  sort: {
    key: conversations.sort.keys.CHECK_IN_DATE,
  },
  search: {
    term: '',
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SETUP_INIT_DATA:
      return state
        .set('isFetching', true)
        .set('didInvalidate', false)
        .updateIn(['sort', 'key'], value => payload.sort || value)
        .setIn(['search', 'term'], payload.search || '');

    case FETCH_CONVERSATIONS_REQUESTED:
      return state.set('didInvalidate', false).set('isFetching', true);

    case FETCH_MORE_CONVERSATIONS_REQUESTED:
      return state.setIn(['page', 'isFetching'], true).setIn(['page', 'didInvalidate'], false);

    case FETCH_CONVERSATIONS_SUCCESSED:
      return state
        .set('isFetching', false)
        .set('data', fromJS(payload.conversations.results))
        .setIn(['page', 'nextLink'], payload.conversations.next);

    case FETCH_MORE_CONVERSATIONS_SUCCESSED:
      return state
        .setIn(['page', 'isFetching'], false)
        .update('data', value => value.concat(fromJS(payload.conversations.results)))
        .setIn(['page', 'nextLink'], payload.conversations.next);

    case FETCH_CONVERSATIONS_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case FETCH_MORE_CONVERSATIONS_FAILED:
      return state.update('page', () =>
        initialState
          .get('page')
          .set('didInvalidate', true)
          .set('errors', fromJS(payload.errors)),
      );

    case UPDATE_CONVERSATIONS_CONVERSATION_SUCCESSED: {
      const index = state.get('data').findIndex(c => c.get('id') === payload.conversation.id);
      return state.setIn(['data', index], fromJS(payload.conversation));
    }

    case CHANGE_CONVERSATIONS_SEARCH_TERM:
      return state.setIn(['search', 'term'], payload.term || '');

    case CHANGE_CONVERSATIONS_SORT_KEY:
      return state.setIn(['sort', 'key'], payload.sortKey);

    case SORT_CONVERSATIONS_REQUESTED:
      return state.set('isFetching', true).setIn(['sort', 'key'], payload.key);

    default:
      return state;
  }
};
