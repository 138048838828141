import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import pick from 'lodash.pick';

import { getSpecialOffer } from 'selectors/specialOffer';
import { getBookingSpecialOffer, getBookingData } from 'selectors/booking';
import {
  postSpecialOfferRequested,
  changeSpecialOfferFormVisibility,
  deleteSpecialOfferRequested,
  updateSpecialOfferRequested,
  fetchSpecialOfferPriceRequested,
} from 'ducks/specialOffer';

import { OverlayBackground, SpecialOfferNotification, RequestError } from 'components';
import { Modal, Button, Input, Text, LoadingIndicator } from 'ready/components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { isEligibleForSpecialOffer } from 'utils/businessLogic';
import { specialOfferPropType, inquiryPropShape, reservationPropType, inquiryPropType } from 'common/propTypes';
import { numberMask } from 'utils/index';
import { getRentalCleaningFees } from 'utils/serializers';
import { FormattedMessage } from 'react-intl';
import { openSpecialOfferCreateForm } from 'ducks/conversationDrawer';

const orientations = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL',
};

const position = {
  LEFT: 'flex-start',
  CENTER: 'center',
  RIGHT: 'flex-end',
};

const Container = styled.div`
  padding: ${props => props.theme.spacing.s};
  width: 35rem;
`;

const withOrientation = ({ orientation }) =>
  (orientation === orientations.HORIZONTAL &&
    css`
      margin: 0 -${props => props.theme.spacing.xxs};
      & > * {
        margin: 0 ${props => props.theme.spacing.xxs};
      }
    `) ||
  css`
    flex-direction: column;
    & > * {
      margin: ${props => props.theme.spacing.xxs} 0;
    }
  `;

const withSelfAlign = ({ selfPosition = position.LEFT }) =>
  css`
    justify-self: ${selfPosition};
  `;

const withAlignItems = ({ childrenPosition = position.LEFT }) =>
  css`
    justify-content: ${childrenPosition};
  `;

const FormGroup = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.s};
  ${withAlignItems};
  ${withSelfAlign};
  ${withOrientation};
`;

const StyledSpecialOfferNotification = styled(SpecialOfferNotification)`
  margin-left: -${props => props.theme.spacing.s};
  margin-right: -${props => props.theme.spacing.s};
  margin-top: -${props => props.theme.spacing.s};
  margin-bottom: ${props => props.theme.spacing.s};
`;

const SpecialOffer = ({
  specialOffer,
  specialOfferForm,
  booking,
  t,
  dispatchPostSpecialOfferRequested,
  dispatchChangeForm,
  dispatchDeleteSpecialOfferRequested,
  dispatchUpdateSpecialOfferRequested,
  dispatchOpenSpecialOfferCreateForm,
  dispatchFetchSpecialOfferPriceRequested,
}) => {
  const { form, isDeleting, isFetching, didInvalidate, errors } = specialOfferForm;

  const handleOpenSpecialOfferCreateForm = id => {
    dispatchOpenSpecialOfferCreateForm(id);
    dispatchFetchSpecialOfferPriceRequested(id);
  };
  return (
    <>
      {form.visible && (
        <OverlayBackground>
          <Modal
            data-qa="SpecialOffer:Modal"
            heading={t.title}
            onClose={() => dispatchChangeForm('visible', false)}
            closeEnabled
          >
            <Container>
              <StyledSpecialOfferNotification specialOffer={specialOffer} t={pick(t, ['editSpecialOffer'])} />
              <FormGroup>
                <Text bold size={Text.sizes.S}>
                  {t.currentAmount}
                </Text>
                <Text data-qa="SpecialOffer:Text--currentPrice">
                  {specialOffer.price || booking.price || '-'} {booking.currency_symbol}
                </Text>
              </FormGroup>
              <FormGroup>
                <Text bold size={Text.sizes.S}>
                  {t.newAmount}
                </Text>
                <Input
                  name="special-offer-price"
                  placeholder={t.newAmount}
                  value={form.price}
                  onChange={e => dispatchChangeForm('price', numberMask(e.target.value))}
                />
                <Text
                  element="p"
                  size={Text.sizes.S}
                  variation={Text.variations.ERROR}
                  data-qa="SpecialOffer:Text--newPriceHint"
                >
                  <FormattedMessage id="BOOKING.FRAIS_MENAGE" values={{ fees: getRentalCleaningFees(booking) }} />
                </Text>
              </FormGroup>
              {didInvalidate && (
                <FormGroup>
                  <RequestError errors={errors} />
                </FormGroup>
              )}
              <FormGroup orientation={orientations.HORIZONTAL} childrenPosition={position.RIGHT}>
                {specialOffer.id && (
                  <Button
                    variation={Button.variations.ERROR}
                    size={Button.sizes.S}
                    inline
                    onClick={() => dispatchDeleteSpecialOfferRequested(specialOffer.id)}
                    data-qa="SpecialOffer:Button--delete"
                  >
                    {isDeleting ? <LoadingIndicator color="white" size={LoadingIndicator.sizes.XS} /> : t.deleteOffer}
                  </Button>
                )}
                <Button
                  variation={Button.variations.OUTLINE}
                  size={Button.sizes.S}
                  inline
                  onClick={() => {
                    dispatchChangeForm('visible', false);
                    dispatchChangeForm('price', 0);
                  }}
                  data-qa="SpecialOffer:Button--cancel"
                >
                  {t.cancel}
                </Button>
                {specialOffer.id ? (
                  <Button
                    inline
                    size={Button.sizes.S}
                    onClick={() =>
                      dispatchUpdateSpecialOfferRequested(specialOffer.id, {
                        inquiry: booking.id,
                        price: Number(form.price),
                      })
                    }
                    disabled={!form.price || isFetching}
                    data-qa="SpecialOffer:Button--update"
                  >
                    {isFetching ? <LoadingIndicator color="white" size={LoadingIndicator.sizes.XS} /> : t.updateOffer}
                  </Button>
                ) : (
                  <Button
                    inline
                    size={Button.sizes.S}
                    onClick={() =>
                      dispatchPostSpecialOfferRequested({ inquiry: booking.id, price: Number(form.price) })
                    }
                    disabled={!form.price || isFetching}
                    data-qa="SpecialOffer:Button--send"
                  >
                    {isFetching ? <LoadingIndicator color="white" size={LoadingIndicator.sizes.XS} /> : t.send}
                  </Button>
                )}
              </FormGroup>
            </Container>
          </Modal>
        </OverlayBackground>
      )}
      <Button
        disabled={!isEligibleForSpecialOffer(booking)}
        size={Button.sizes.L}
        icon={{ icon: Button.icon.icons.GIFT }}
        hint={{ text: t.hintGift }}
        clean
        onClick={() => handleOpenSpecialOfferCreateForm(booking.id)}
        data-qa="SpecialOffer:Button--specialOffer"
      />
    </>
  );
};

SpecialOffer.propTypes = {
  booking: PropTypes.oneOfType([inquiryPropType, reservationPropType, PropTypes.object]),
  specialOffer: inquiryPropShape.special_offer,
  specialOfferForm: specialOfferPropType.isRequired,
  t: PropTypes.shape({
    title: PropTypes.string.isRequired,
    currentAmount: PropTypes.string.isRequired,
    newAmount: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    send: PropTypes.string.isRequired,
    deleteOffer: PropTypes.string.isRequired,
    updateOffer: PropTypes.string.isRequired,
    hintGift: PropTypes.string.isRequired,
  }).isRequired,
  dispatchPostSpecialOfferRequested: PropTypes.func.isRequired,
  dispatchChangeForm: PropTypes.func.isRequired,
  dispatchDeleteSpecialOfferRequested: PropTypes.func.isRequired,
  dispatchUpdateSpecialOfferRequested: PropTypes.func.isRequired,
  dispatchOpenSpecialOfferCreateForm: PropTypes.func.isRequired,
  dispatchFetchSpecialOfferPriceRequested: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  booking: getBookingData(state) || {},
  specialOffer: getBookingSpecialOffer(state),
  specialOfferForm: getSpecialOffer(state),
});

const mapDispatchToProps = {
  dispatchPostSpecialOfferRequested: postSpecialOfferRequested,
  dispatchChangeForm: changeSpecialOfferFormVisibility,
  dispatchDeleteSpecialOfferRequested: deleteSpecialOfferRequested,
  dispatchUpdateSpecialOfferRequested: updateSpecialOfferRequested,
  dispatchOpenSpecialOfferCreateForm: openSpecialOfferCreateForm,
  dispatchFetchSpecialOfferPriceRequested: fetchSpecialOfferPriceRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    title: 'SPECIAL_OFFER.TITLE',
    currentAmount: 'SPECIAL_OFFER.CURRENT_AMOUNT',
    newAmount: 'SPECIAL_OFFER.NEW_AMOUNT',
    cancel: 'TEXT.SHARED.CANCEL',
    send: 'SPECIAL_OFFER.SEND',
    deleteOffer: 'SPECIAL_OFFER.DELETE',
    updateOffer: 'SPECIAL_OFFER.UPDATE',
    editSpecialOffer: 'SPECIAL_OFFER.EDIT',
    hintGift: 'TOP_BAR.HINT.GIFT',
  }),
)(SpecialOffer);
