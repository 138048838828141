import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useMobileView from 'hooks/useMobileView';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { bookingPropTypeWithState } from 'common/propTypes';

import { Modal } from 'ready/components';
import ConversationDrawerContent from './components/ConversationDrawerContent';

import { getBooking } from 'selectors/booking';
import {
  getIsConversationDrawerOpen,
  getConversationDrawerReservationAlteration,
  getConversationDrawerBooking,
  getConversationDrawerInquiry,
} from 'selectors/conversationDrawer';
import { getReservationAlteration } from 'selectors/reservationAlteration';
import { getSpecialOffer } from 'selectors/specialOffer';

import {
  changeShowAcceptAlterationModal,
  changeShowRejectAlterationModal,
  changeShowProposeAlterationModal,
  closeConversationDrawer,
} from 'ducks/conversationDrawer';
import { setReservationAlterationProposal, fetchAlterationPriceRequested } from 'ducks/reservationAlteration';
import { postSpecialOfferRequested } from 'ducks/specialOffer';

const StyledModal = styled(Modal)`
  top: 0;
  right: 0;
  width: 40rem;
  height: 100%;
  z-index: ${props => props.theme.zIndex.overlay};
`;

const ConversationDrawer = ({
  booking,
  reservationAlteration,
  specialOffer,
  isConversationDrawerOpen,
  dispatchCloseConversationDrawer,
  t,
  ...props
}) => {
  const isMobileView = useMobileView();

  return (
    <>
      {isConversationDrawerOpen && !isMobileView && (
        <StyledModal size={Modal.sizes.M} closeEnabled={true} onClose={dispatchCloseConversationDrawer}>
          <ConversationDrawerContent
            booking={booking}
            reservationAlteration={reservationAlteration}
            specialOffer={specialOffer}
            dispatchCloseConversationDrawer={dispatchCloseConversationDrawer}
            t={t}
            {...props}
          />
        </StyledModal>
      )}
    </>
  );
};

ConversationDrawer.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  reservationAlteration: PropTypes.object.isRequired,
  isConversationDrawerOpen: PropTypes.bool.isRequired,
  conversationDrawerBooking: PropTypes.number,
  conversationDrawerReservationAlteration: PropTypes.number,
  dispatchCloseConversationDrawer: PropTypes.func.isRequired,
  dispatchChangeShowAcceptAlterationModal: PropTypes.func.isRequired,
  dispatchChangeShowRejectAlterationModal: PropTypes.func.isRequired,
  dispatchChangeShowProposeAlterationModal: PropTypes.func.isRequired,
  dispatchFetchAlterationPriceRequested: PropTypes.func.isRequired,
  setReservationAlterationProposal: PropTypes.func.isRequired,
  dispatchPostSpecialOfferRequested: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  booking: getBooking(state),
  reservationAlteration: getReservationAlteration(state),
  specialOffer: getSpecialOffer(state),
  isConversationDrawerOpen: getIsConversationDrawerOpen(state),
  conversationDrawerBooking: getConversationDrawerBooking(state),
  conversationDrawerReservationAlteration: getConversationDrawerReservationAlteration(state),
  conversationDrawerInquiry: getConversationDrawerInquiry(state),
});

const mapDispatchToProps = {
  dispatchCloseConversationDrawer: closeConversationDrawer,
  dispatchChangeShowAcceptAlterationModal: changeShowAcceptAlterationModal,
  dispatchChangeShowRejectAlterationModal: changeShowRejectAlterationModal,
  dispatchChangeShowProposeAlterationModal: changeShowProposeAlterationModal,
  dispatchFetchAlterationPriceRequested: fetchAlterationPriceRequested,
  setReservationAlterationProposal: setReservationAlterationProposal,
  dispatchPostSpecialOfferRequested: postSpecialOfferRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    reservation: 'BOOKING.TITLE',
    reject: 'TEXT.SHARED.REJECT',
    approve: 'TEXT.SHARED.APPROVE',
    reset: 'TEXT.SHARED.RESET',
    apply: 'TEXT.SHARED.APPLY',
    back: 'TEXT.SHARED.BACK',
    cancel: 'TEXT.SHARED.CANCEL',
    requestedByGuest: 'BOOKING.ALTERATION.REQUESTED_BY_GUEST',
    propose: 'BOOKING.ALTERATION.FORM.PROPOSE',
    original: 'BOOKING.ALTERATION.FORM.ORIGINAL',
    proposal: 'BOOKING.ALTERATION.FORM.PROPOSAL',
    checkIn: 'BOOKING.ALTERATION.FORM.CHECK_IN',
    checkOut: 'BOOKING.ALTERATION.FORM.CHECK_OUT',
    guests: 'BOOKING.ALTERATION.FORM.GUESTS',
    adults: 'BOOKING.ALTERATION.FORM.ADULTS',
    children: 'BOOKING.ALTERATION.FORM.CHILDREN',
    pets: 'BOOKING.ALTERATION.FORM.PETS',
    property: 'BOOKING.ALTERATION.FORM.PROPERTY',
    price: 'BOOKING.ALTERATION.FORM.PRITCE',
    seeCalendar: 'BOOKING.ALTERATION.FORM.SEE_CALENDAR',
    edit: 'BOOKING.EDIT',
    updateError: 'BOOKING.ALTERATION.FORM.UPDATE_ERROR',
    totalPrice: 'BOOKING.ALTERATION.FORM.EDIT.PRICE.TOTAL_PRICE',
    rentalIncome: 'BOOKING.ALTERATION.FORM.EDIT.PRICE.RENTAL_INCOME',
    proposePrice: 'BOOKING.ALTERATION.FORM.EDIT.PRICE.PROPOSE_PRICE',
    selectGuests: 'BOOKING.ALTERATION.FORM.EDIT.GUESTS.SELECT_GUESTS',
    noPetsAllowed: 'BOOKING.ALTERATION.FORM.EDIT.GUESTS.NO_PETS_ALLOWED',
    selectCheckIn: 'BOOKING.ALTERATION.FORM.EDIT.CALENDAR.SELECT_CHECK_IN',
    selectCheckOut: 'BOOKING.ALTERATION.FORM.EDIT.CALENDAR.SELECT_CHECK_OUT',
    accepted: 'BOOKING.ALTERATION.ACCEPTED',
    rejected: 'BOOKING.ALTERATION.REJECTED',
    pending: 'BOOKING.ALTERATION.PENDING',
    specialOffer: 'SPECIAL_OFFER.TITLE',
    sendSpecialOffer: 'SPECIAL_OFFER.SEND',
    specialOfferFormDetails: 'SPECIAL_OFFER.FORM.DETAILS',
    specialOfferFormInfo: 'SPECIAL_OFFER.FORM.INFO',
    specialOfferFormCleaning: 'SPECIAL_OFFER.FORM.CLEANING',
    specialOfferFormExtra: 'SPECIAL_OFFER.FORM.EXTRA',
    specialOfferFormTotal: 'SPECIAL_OFFER.FORM.TOTAL',
    specialOfferFormEditTotal: 'SPECIAL_OFFER.FORM.EDIT_TOTAL',
    specialOfferFormEditTotalDetails: 'SPECIAL_OFFER.FORM.EDIT_TOTAL_DETAILS',
  }),
)(ConversationDrawer);
