import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { missionPropType } from 'common/propTypes';

const SelfForm = ({ checkIn, t, children }) => (
  <>
    <FullWidthContentWithLabel label={t.instructions}>{checkIn.self_checkin_instructions}</FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.lockboxCode}>{checkIn.lockbox_code}</FullWidthContentWithLabel>
    {children}
  </>
);

SelfForm.propTypes = {
  checkIn: missionPropType,
  t: PropTypes.shape({
    instructions: PropTypes.string.isRequired,
    lockboxCode: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
};

export default SelfForm;
