import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { conversationDefaultChannels } from 'constants.js';
import Icon from 'ready/components/Icon/Icon';

import airbnbLogo from 'assets/images/channel_logos/airbnb.png';
import bookingComLogo from 'assets/images/channel_logos/booking-com.png';
import homeawayLogo from 'assets/images/channel_logos/homeaway.png';

const Logo = styled.img`
  width: ${props => props.theme.iconSize.s};
  height: ${props => props.theme.iconSize.s};
`;

const LOGOS = {
  [conversationDefaultChannels.AIRBNB]: airbnbLogo,
  [conversationDefaultChannels.BOOKING_COM]: bookingComLogo,
  [conversationDefaultChannels.HOMEAWAY]: homeawayLogo,
};

const ChannelLogo = ({ channel }) => {
  const logoFile = LOGOS[channel];
  if (logoFile) return <Logo src={logoFile} alt={channel} />;

  if (channel === conversationDefaultChannels.EMAIL) {
    return <Icon size={Icon.sizes.S} icon={Icon.icons.MESSAGE} />;
  }

  return false;
};

ChannelLogo.propTypes = {
  channel: PropTypes.string,
};

export default ChannelLogo;
