import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'ready/components/typography';
import { variationsCssProps, variations } from 'ready/utils/constants';
import { variationPropType } from 'ready/utils/propTypes';
import useWindowSize from 'hooks/useWindowSize';
import { getColor } from '../../ready/styles/helpers.js';

const Wrapper = styled.div`
  min-height: 3.6rem;
  background-color: ${props => getColor(props.variation, variationsCssProps.FADE_BG)};
  border-bottom: ${props => props.theme.app.border.default};
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.m}`};
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${props => props.theme.spacing.xxs};
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    border-left: 0.3rem solid ${props => getColor(props.variation, variationsCssProps.BG)};
    position: absolute;
    left: 0;
    top: -0.05rem;
    bottom: -0.05rem;
  }
`;

const withCollapsedStyle = ({ isCollapsed, collapseDisabled }) =>
  isCollapsed &&
  !collapseDisabled &&
  css`
    overflow: hidden;
    white-space: nowrap;
  `;

const ContentWrapper = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  ${withCollapsedStyle}
`;

const Toggle = styled(Text)`
  color: ${props => props.theme.colors.b500_v2};
  cursor: pointer;
  user-select: none;
`;

const ChatNotification = ({ variation, children, t, show, collapseDisabled, 'data-qa': dataQA }) => {
  if (!show) return false;

  const [isCollapsed, setCollapsed] = useState(true);
  const [isCollapsible, setIsCollapsible] = useState(!collapseDisabled);

  const measuredRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (measuredRef.current !== null && !collapseDisabled) {
      const isContentOverflowing = measuredRef.current.offsetWidth < measuredRef.current.scrollWidth;
      setIsCollapsible(isContentOverflowing);
    }
  }, [children, windowSize.width]);

  const toggleText = isCollapsed ? t.showMore : t.showLess;

  return (
    <Wrapper variation={variation} data-qa={`[app]${dataQA}`}>
      {children && (
        <ContentWrapper
          collapseDisabled={collapseDisabled}
          isCollapsed={isCollapsed}
          ref={measuredRef}
          data-qa="ChatNotification:__contentWrapper"
        >
          {children}
        </ContentWrapper>
      )}
      {isCollapsible && (
        <Toggle bold onClick={() => setCollapsed(!isCollapsed)} data-qa="ChatNotification:Text__toggle">
          {toggleText}
        </Toggle>
      )}
    </Wrapper>
  );
};

ChatNotification.variations = variations;

ChatNotification.propTypes = {
  variation: variationPropType,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  t: PropTypes.shape({
    showMore: PropTypes.string,
    showLess: PropTypes.string,
  }),
  show: PropTypes.bool,
  collapseDisabled: PropTypes.bool,
  'data-qa': PropTypes.string,
};

ChatNotification.defaultProps = {
  variation: variations.PRIMARY,
  show: true,
  'data-qa': 'ChatNotification:__wrapper',
};

export default ChatNotification;
