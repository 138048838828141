import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedRelative } from 'react-intl';
import moment from 'moment';

import ActionButton from './ActionButton';
import MuteAction from './MuteAction';
import { Button, Text, Tag, TruncatedText } from 'ready/components';
import { connectionTypes, callCenterPages } from 'constants.js';
import { variationsCssProps, variations } from 'ready/utils/constants';
import { getColor } from 'ready/styles/helpers.js';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6.6rem;
  width: 47.6rem;
  z-index: ${props => props.theme.zIndex.modal};
`;

const Content = styled.div`
  align-items: center;
  background-color: white;
  border-left: ${props => props.theme.borderWidth.xl} solid ${props => props.theme.colors.success};
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.xs};
  grid-template-columns: repeat(4, auto);
  justify-content: stretch;
  justify-items: center;
  height: 100%;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: ${props => props.theme.spacing.s};
`;

const StyledTruncatedText = styled(TruncatedText)`
  width: 15rem;
`;

const Timer = styled(Text)`
  width: 5rem;
`;

export const Banner = styled.div`
  position: absolute;
  top: -3.6rem;
  left: 0;
  height: 3.6rem;
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  text-align: center;
  font-size: 1.2rem;
  background-color: ${getColor(variations.PRIMARY, variationsCssProps.FADE_BG)};
  border-left: ${props => props.theme.borderWidth.xl} solid ${props => props.theme.colors.success};
  z-index: ${props => props.theme.zIndex.modal};
`;

const MinimizedCall = ({ text, t, actions, startedAt, isMuted, connectionType, isRecorded }) => (
  <Container>
    {isRecorded && <Banner>{t.recordingWarning}</Banner>}
    <Content>
      <StyledTruncatedText bold>{text}</StyledTruncatedText>
      <ButtonsContainer>
        <MuteAction t={t} onClick={actions.muteCall} muted={isMuted} noLabel />
        <ActionButton
          icon={ActionButton.icons.PHONE}
          size={ActionButton.sizes.S}
          onClick={() => {
            actions.endCall();
            actions.expandCall();
          }}
          rotate={135}
          variation={ActionButton.variations.ERROR}
        />
        <ActionButton
          icon={ActionButton.icons.CALL_FORWARD}
          size={ActionButton.sizes.S}
          onClick={() => {
            actions.changePage(callCenterPages.FORWARD_LIST);
            actions.expandCall();
          }}
          disabled={connectionType !== connectionTypes.INBOUND}
        />
      </ButtonsContainer>
      <FormattedRelative value={startedAt} updateInterval={1}>
        {() => <Timer size={Text.sizes.S}>{moment.utc(moment().diff(startedAt)).format('HH:mm:ss')}</Timer>}
      </FormattedRelative>
      <Button clean onClick={actions.expandCall}>
        <Tag size={Tag.sizes.XS} icon={Tag.icons.EXPAND}>
          {t.expand}
        </Tag>
      </Button>
    </Content>
  </Container>
);

MinimizedCall.propTypes = {
  t: PropTypes.shape({
    expand: PropTypes.string.isRequired,
    recordingWarning: PropTypes.string.isRequired,
  }),
  text: PropTypes.string,
  isMuted: PropTypes.bool,
  actions: PropTypes.shape({
    muteCall: PropTypes.func.isRequired,
    endCall: PropTypes.func.isRequired,
    forwardCall: PropTypes.func.isRequired,
    expandCall: PropTypes.func.isRequired,
  }),
  startedAt: PropTypes.object.isRequired,
  connectionType: PropTypes.string.isRequired,
  isRecorded: PropTypes.bool,
};

export default MinimizedCall;
