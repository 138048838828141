import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible } from 'ready/components';

import { fullRentalPropType } from 'common/propTypes';

const Nearby = ({ property, t }) => (
  <Collapsible title={t.nearbyTitle} data-qa="Tab:Collapsible--nearby" collapsed>
    <ContentWrapper minHeight="6.4rem">
      <NearbyContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

Nearby.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    nearbyTitle: PropTypes.string.isRequired,
    poi: PropTypes.string.isRequired,
  }).isRequired,
};

const NearbyContent = ({ property, t }) => (
  <>
    <FullWidthContentWithLabel label={t.poi}>{property.indication_poi_view}</FullWidthContentWithLabel>
  </>
);

NearbyContent.propTypes = Nearby.propTypes;

export default Nearby;
