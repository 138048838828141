import { createSelector } from 'reselect';
import { createDeepEqualSelector } from './helpers';
import { contexts } from 'constants.js';
import { Map } from 'immutable';

export const _getBookingErrors = state => state.booking.get('errors');
export const _getAutomatedMessagesErrors = state => state.automatedMessages.get('errors');

export const _getAutomationTabErrors = createSelector(
  [_getBookingErrors, _getAutomatedMessagesErrors],
  (bookingErrors, automatedMessagesErrors) => {
    let errors = automatedMessagesErrors;
    if (bookingErrors.get('context') === contexts.infoSidebar.AUTO_REVIEW) {
      errors = errors.merge(bookingErrors);
    }
    return errors;
  },
);

export const _getOverviewTabErrors = createSelector(
  [_getBookingErrors],
  bookingErrors => {
    if (bookingErrors.get('context') === contexts.infoSidebar.BOOKING) {
      return bookingErrors;
    }
    return Map({});
  },
);

export const getInfoSidebarErrors = createDeepEqualSelector(
  [_getOverviewTabErrors, _getAutomationTabErrors],
  (overviewTabErrors, automationTabErrors) => ({
    overview: overviewTabErrors.toJS(),
    automation: automationTabErrors.toJS(),
  }),
);
