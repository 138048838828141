import { fromJS } from 'immutable';

export const FETCH_AUTOMATED_MESSAGES_REQUESTED = 'FETCH_AUTOMATED_MESSAGES_REQUESTED';
export const fetchAutomatedMessagesRequested = reservationId => ({
  type: FETCH_AUTOMATED_MESSAGES_REQUESTED,
  payload: { reservationId },
});

export const FETCH_AUTOMATED_MESSAGES_SUCCESSED = 'FETCH_AUTOMATED_MESSAGES_SUCCESSED';
export const fetchAutomatedMessagesSuccessed = automatedMessages => ({
  type: FETCH_AUTOMATED_MESSAGES_SUCCESSED,
  payload: { automatedMessages },
});

export const FETCH_AUTOMATED_MESSAGES_FAILED = 'FETCH_AUTOMATED_MESSAGES_FAILED';
export const fetchAutomatedMessagesFailed = errors => ({
  type: FETCH_AUTOMATED_MESSAGES_FAILED,
  payload: { errors },
});

export const UPDATE_AUTOMATED_MESSAGE_REQUESTED = 'UPDATE_AUTOMATED_MESSAGE_REQUESTED';
export const updateAutomatedMessageRequested = automatedMessage => ({
  type: UPDATE_AUTOMATED_MESSAGE_REQUESTED,
  payload: { automatedMessage },
});

export const UPDATE_AUTOMATED_MESSAGE_SUCCESSED = 'UPDATE_AUTOMATED_MESSAGE_SUCCESSED';
export const updateAutomatedMessageSuccessed = automatedMessage => ({
  type: UPDATE_AUTOMATED_MESSAGE_SUCCESSED,
  payload: { automatedMessage },
});

export const UPDATE_AUTOMATED_MESSAGE_FAILED = 'UPDATE_AUTOMATED_MESSAGE_FAILED';
export const updateAutomatedMessageFailed = errors => ({
  type: UPDATE_AUTOMATED_MESSAGE_FAILED,
  payload: { errors },
});

export const SEND_AUTOMATED_MESSAGE_REQUESTED = 'SEND_AUTOMATED_MESSAGE_REQUESTED';
export const sendAutomatedMessageRequested = automatedMessage => ({
  type: SEND_AUTOMATED_MESSAGE_REQUESTED,
  payload: { automatedMessage },
});

export const SEND_AUTOMATED_MESSAGE_SUCCESSED = 'SEND_AUTOMATED_MESSAGE_SUCCESSED';
export const sendAutomatedMessageSuccessed = automatedMessage => ({
  type: SEND_AUTOMATED_MESSAGE_SUCCESSED,
  payload: { automatedMessage },
});

export const SEND_AUTOMATED_MESSAGE_FAILED = 'SEND_AUTOMATED_MESSAGE_FAILED';
export const sendAutomatedMessageFailed = errors => ({
  type: SEND_AUTOMATED_MESSAGE_FAILED,
  payload: { errors },
});

export const CLEAR_AUTOMATED_MESSAGES = 'CLEAR_AUTOMATED_MESSAGES';
export const clearAutomatedMessages = () => ({
  type: CLEAR_AUTOMATED_MESSAGES,
});

export const initialState = fromJS({
  isFetching: true,
  isUpdating: false,
  didInvalidate: false,
  selectedId: null,
  data: [],
  errors: {
    fetching: false,
    updating: false,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_AUTOMATED_MESSAGES:
      return state.set('isFetching', true).set('data', fromJS([]));

    case FETCH_AUTOMATED_MESSAGES_REQUESTED:
      return state.set('isFetching', true).set('didInvalidate', false);

    case FETCH_AUTOMATED_MESSAGES_SUCCESSED:
      return state.set('isFetching', false).set('data', fromJS(payload.automatedMessages));

    case FETCH_AUTOMATED_MESSAGES_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case SEND_AUTOMATED_MESSAGE_REQUESTED:
    case UPDATE_AUTOMATED_MESSAGE_REQUESTED:
      return state
        .set('selectedId', payload.automatedMessage.id)
        .set('errors', initialState.get('errors'))
        .set('isUpdating', true);

    case UPDATE_AUTOMATED_MESSAGE_SUCCESSED:
    case SEND_AUTOMATED_MESSAGE_SUCCESSED: {
      const updateIndex = state.get('data').findIndex(am => am.get('id') === state.get('selectedId'));
      return state
        .set('isUpdating', false)
        .set('selectedId', null)
        .setIn(['data', updateIndex], fromJS(payload.automatedMessage));
    }

    case UPDATE_AUTOMATED_MESSAGE_FAILED:
    case SEND_AUTOMATED_MESSAGE_FAILED:
      return state
        .set('isUpdating', false)
        .update('errors', value => value.set('updating', true).merge(payload.errors));

    default:
      return state;
  }
};
