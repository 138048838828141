import { fromJS } from 'immutable';

export const FETCH_ATTACHMENTS_REQUESTED = 'FETCH_ATTACHMENTS_REQUESTED';
export const fetchAttachmentsRequested = conversationId => ({
  type: FETCH_ATTACHMENTS_REQUESTED,
  payload: { conversationId },
});

export const FETCH_ATTACHMENTS_SUCCESSED = 'FETCH_ATTACHMENTS_SUCCESSED';
export const fetchAttachmentsSuccessed = attachments => ({
  type: FETCH_ATTACHMENTS_SUCCESSED,
  payload: { attachments },
});

export const FETCH_ATTACHMENTS_FAILED = 'FETCH_ATTACHMENTS_FAILED';
export const fetchAttachmentsFailed = errors => ({
  type: FETCH_ATTACHMENTS_FAILED,
  payload: { errors },
});

export const CHANGE_ATTACHMENTS_MODAL_VISIBILITY = 'CHANGE_ATTACHMENTS_MODAL_VISIBILITY';
export const changeAttachmentsModalVisibility = value => ({
  type: CHANGE_ATTACHMENTS_MODAL_VISIBILITY,
  payload: { value },
});

export const CHANGE_ATTACHMENTS_GALLERY_TOGGLER = 'CHANGE_ATTACHMENTS_GALLERY_TOGGLER';
export const changeAttachmentsGalleryToggler = value => ({
  type: CHANGE_ATTACHMENTS_GALLERY_TOGGLER,
  payload: { value },
});

export const CHANGE_ATTACHMENTS_GALLERY_INDEX = 'CHANGE_ATTACHMENTS_GALLERY_INDEX';
export const changeAttachmentsGalleryIndex = value => ({
  type: CHANGE_ATTACHMENTS_GALLERY_INDEX,
  payload: { value },
});

export const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  errors: {},
  data: [],
  modal: {
    visible: false,
  },
  gallery: {
    toggler: false,
    sourceIndex: undefined,
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ATTACHMENTS_REQUESTED:
      return state.set('isFetching', true).set('didInvalidate', false);

    case FETCH_ATTACHMENTS_SUCCESSED:
      return state.set('isFetching', false).set('data', fromJS(payload.attachments));

    case FETCH_ATTACHMENTS_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case CHANGE_ATTACHMENTS_MODAL_VISIBILITY:
      return state.setIn(['modal', 'visible'], payload.value);

    case CHANGE_ATTACHMENTS_GALLERY_TOGGLER:
      return state.setIn(['gallery', 'toggler'], payload.value);

    case CHANGE_ATTACHMENTS_GALLERY_INDEX:
      return state.setIn(['gallery', 'sourceIndex'], payload.value);

    default:
      return state;
  }
};
