import { initialState as storeInitialState } from 'ducks/filters';
import { filterKeys } from 'constants.js';
import { fromJS, List } from 'immutable';

export const CHANGE_FILTER = 'CHANGE_FILTER';
export const changeFilter = (key, value) => ({ type: CHANGE_FILTER, payload: { key, value } });

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const clearFilters = () => ({ type: CLEAR_FILTERS });

export const LOAD_FILTERS = 'LOAD_FILTERS';
export const loadFilters = filters => ({ type: LOAD_FILTERS, payload: { filters } });

const initialState = storeInitialState.filter((_, key) => ['data', 'dataOptions', 'config'].includes(key)).toJS();
delete initialState.data[filterKeys.CATEGORY_ID];

export const getDataOptionsFromConfig = (data, config) => {
  const arrayData = fromJS(data).filter(item => List.isList(item) && item.size);
  const dataOptions = arrayData.map((val, key) => config[key].filter(item => val.contains(item.value)));
  return dataOptions.toJS();
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHANGE_FILTER: {
      if (!Array.isArray(payload.value)) {
        return {
          ...state,
          data: {
            ...state.data,
            [payload.key]: payload.value,
          },
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          [payload.key]: payload.value.map(({ value }) => value),
        },
        dataOptions: {
          ...state.dataOptions,
          [payload.key]: payload.value,
        },
      };
    }

    case CLEAR_FILTERS:
      return {
        ...state,
        data: {
          [filterKeys.CATEGORY_ID]: state.data[filterKeys.CATEGORY_ID],
          ...initialState.data,
        },
        dataOptions: {
          ...initialState.dataOptions,
        },
      };

    case LOAD_FILTERS: {
      const dataOptions = getDataOptionsFromConfig(payload.filters, state.config);
      return {
        ...state,
        data: {
          [filterKeys.CATEGORY_ID]: state.data[filterKeys.CATEGORY_ID],
          ...initialState.data,
          ...payload.filters,
        },
        dataOptions: {
          ...initialState.dataOptions,
          ...dataOptions,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
