import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Text, LoadingIndicator } from 'ready/components';
import PropTypes from 'prop-types';
import { bookingPropType } from 'common/propTypes';

const clickable = ({ onClick }) =>
  onClick &&
  css`
    cursor: pointer;
    user-select: none;
  `;
const StyledTable = styled.table`
  width: 100%;
  border: ${props => props.theme.app.border.default};
  border-radius: ${props => props.theme.borderRadius.l};
  border-collapse: separate;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.s};
`;
const StyledRow = styled.tr``;
const StyledHeader = styled.th`
  font-weight: normal;
  text-align: left;
  padding: ${props => props.theme.spacing.xs};
`;
const StyledCell = styled.td`
  border-top: ${props => props.theme.app.border.default};
  padding: ${props => props.theme.spacing.xs};
`;
const StyledHeading = styled(Text)`
  color: ${props => props.theme.colors.n600};
`;

const GuestsWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
`;
const StyledTextLink = styled(Text)`
  margin: ${props => props.theme.spacing.m} 0;
  color: ${props => props.theme.colors.primary};
  ${clickable}
`;

// eslint-disable-next-line react/prop-types
const Guests = ({ data, proposal }) => {
  return (
    <GuestsWrapper>
      {data.map(function(item) {
        return (
          <li key={item.label}>
            <Text bold={proposal && item.original !== item.proposal}>
              {proposal ? item.proposal : item.original} {item.label}
            </Text>
          </li>
        );
      })}
    </GuestsWrapper>
  );
};

const AlterationDetailsTable = ({
  reservation,
  reservationAlteration,
  loadingFetchAlterationPrice,
  create,
  t,
  ...props
}) => {
  const [guestsData, setGuestsData] = useState([]);
  useEffect(() => {
    setGuestsData([
      {
        original: reservation.adults,
        proposal: reservationAlteration.adults,
        label: t.adults,
      },
      {
        original: reservation.children,
        proposal: reservationAlteration.children,
        label: t.children,
      },
      {
        original: reservation.pets,
        proposal: reservationAlteration.pets,
        label: t.pets,
      },
    ]);
  }, [reservation, reservationAlteration]);
  return (
    <StyledTable>
      <tbody>
        <StyledRow>
          <StyledHeader />
          <StyledHeader>
            <StyledHeading>{t.original}</StyledHeading>
          </StyledHeader>
          <StyledHeader>
            <StyledHeading>{t.proposal}</StyledHeading>
          </StyledHeader>
        </StyledRow>
        <StyledRow>
          <StyledCell>
            <StyledHeading>{t.checkIn}</StyledHeading>
          </StyledCell>
          <StyledCell>
            <Text>{reservation.checkin_date}</Text>
          </StyledCell>
          <StyledCell>
            <Text bold={reservation.checkin_date !== reservationAlteration.start_date}>
              {reservationAlteration.start_date}
            </Text>
          </StyledCell>
          {create && (
            <StyledCell>
              <StyledTextLink onClick={() => props.setShowEditCheckInForm(true)}>{t.edit}</StyledTextLink>
            </StyledCell>
          )}
        </StyledRow>
        <StyledRow>
          <StyledCell>
            <StyledHeading>{t.checkOut}</StyledHeading>
          </StyledCell>
          <StyledCell>
            <Text>{reservation.checkout_date}</Text>
          </StyledCell>
          <StyledCell>
            <Text bold={reservation.checkout_date !== reservationAlteration.end_date}>
              {reservationAlteration.end_date}
            </Text>
          </StyledCell>
          {create && (
            <StyledCell>
              <StyledTextLink onClick={() => props.setShowEditCheckOutForm(true)}>{t.edit}</StyledTextLink>
            </StyledCell>
          )}
        </StyledRow>
        <StyledRow>
          <StyledCell>
            <StyledHeading>{t.guests}</StyledHeading>
          </StyledCell>
          <StyledCell>
            <Guests data={guestsData} />
          </StyledCell>
          <StyledCell>
            <Guests data={guestsData} proposal />
          </StyledCell>
          {create && (
            <StyledCell>
              <StyledTextLink onClick={() => props.setShowEditGuestsForm(true)}>{t.edit}</StyledTextLink>
            </StyledCell>
          )}
        </StyledRow>
        <StyledRow>
          <StyledCell>
            <StyledHeading>{t.property}</StyledHeading>
          </StyledCell>
          <StyledCell>
            <Text>{reservation.rental.id}</Text>
          </StyledCell>
          <StyledCell>
            <StyledTextLink
              onClick={() =>
                window.open(`${process.env.REACT_APP_BASE_API_URL}/staffing/booking_calendar/${reservation.rental.id}/`)
              }
            >
              {t.seeCalendar}
            </StyledTextLink>
          </StyledCell>
        </StyledRow>
        <StyledRow>
          <StyledCell>
            <StyledHeading>{t.price}</StyledHeading>
          </StyledCell>
          <StyledCell>
            <Text>{reservation.price}</Text>
          </StyledCell>
          {!loadingFetchAlterationPrice ? (
            <>
              <StyledCell>
                {create ? (
                  <Text bold={reservation.price != props.getTotalPrice()}>{props.getTotalPrice()}</Text>
                ) : (
                  <Text bold={reservation.price != reservationAlteration.total_price}>
                    {reservationAlteration.total_price}
                  </Text>
                )}
              </StyledCell>
              {create && (
                <StyledCell>
                  <StyledTextLink onClick={() => props.setShowEditPriceForm(true)}>{t.edit}</StyledTextLink>
                </StyledCell>
              )}
            </>
          ) : (
            <StyledCell>
              <LoadingIndicator />
            </StyledCell>
          )}
        </StyledRow>
      </tbody>
    </StyledTable>
  );
};

AlterationDetailsTable.propTypes = {
  reservation: bookingPropType.isRequired,
  reservationAlteration: PropTypes.object,
  setShowEditPriceForm: PropTypes.func,
  setShowEditGuestsForm: PropTypes.func,
  setShowEditCheckInForm: PropTypes.func,
  setShowEditCheckOutForm: PropTypes.func,
  loadingFetchAlterationPrice: PropTypes.bool,
  create: PropTypes.bool,
  t: PropTypes.shape({
    propose: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired,
    proposal: PropTypes.string.isRequired,
    checkIn: PropTypes.string.isRequired,
    checkOut: PropTypes.string.isRequired,
    guests: PropTypes.string.isRequired,
    adults: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    pets: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    seeCalendar: PropTypes.string.isRequired,
    edit: PropTypes.string.isRequired,
  }),
};

export default AlterationDetailsTable;
