import { all, takeLatest, put } from 'redux-saga/effects';

import { CLOSE_CONVERSATION_DRAWER, OPEN_RESERVATION_ALTERATION_EDIT_FORM } from 'ducks/conversationDrawer';
import { clearReservationAlteration, fetchReservationAlterationRequested } from 'ducks/reservationAlteration';

export function* handleOpenReservationAlterationEditForm({ payload: { id } }) {
  yield put(fetchReservationAlterationRequested(id));
}

export function* handleCloseConversationDrawer() {
  yield put(clearReservationAlteration());
}

export default function* watchConversationDrawer() {
  yield all([takeLatest(OPEN_RESERVATION_ALTERATION_EDIT_FORM, handleOpenReservationAlterationEditForm)]);
  yield all([takeLatest(CLOSE_CONVERSATION_DRAWER, handleCloseConversationDrawer)]);
}
