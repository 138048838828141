import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import withInlineEdition from 'ready/hoc/withInlineEdition';
import Select from 'ready/components/Input/Select/Select';
import { borderWidth, typography, spacing } from 'ready/styles/theme';
import { sizes, LONG_TIME_FORMAT, SHORT_TIME_FORMAT, variations } from 'ready/utils/constants';
import { timePropType } from 'ready/utils/propTypes';

const InlineSelect = withInlineEdition(
  Select,
  value => value && moment.utc(value.label, LONG_TIME_FORMAT).format(SHORT_TIME_FORMAT),
);

const InlineTimeSelect = ({ start, end, range, ...props }) => {
  const options = useMemo(() => {
    const _start = moment.utc(start, LONG_TIME_FORMAT);
    const _end = moment.utc(end, LONG_TIME_FORMAT);
    const interval = _end.diff(_start, 'minutes') / range;
    return Array.apply(null, { length: interval }).map(() => {
      const value = _start.format(SHORT_TIME_FORMAT);
      const option = { label: value, value };
      _start.add(range, 'minutes');
      return option;
    });
  }, []);

  return (
    <InlineSelect
      openMenuOnFocus
      options={options}
      size={Select.sizes.S}
      isSearchable={false}
      styles={{
        container: {
          marginLeft: -borderWidth.m,
          marginTop: -borderWidth.m,
        },
        control: {
          justifyContent: 'center',
          height: typography.text[sizes.M]['line-height'],
          width: `calc(${typography.text[sizes.M]['font-size']} * 3)`,
          padding: 0,
        },
        valueContainer: {
          justifyContent: 'center',
          minHeight: typography.text[sizes.S]['line-height'],
          padding: 0,
        },
        indicatorsContainer: {
          display: 'none',
        },
        singleValue: {
          textOverflow: 'none',
          margin: 0,
          padding: 0,
        },
        placeholder: {
          margin: 0,
          padding: 0,
        },
        menu: {
          height: '10rem',
          width: `calc(${typography.text[sizes.M]['font-size']} * 3 + ${spacing.xs})`,
        },
        menuList: {
          height: '10rem',
        },
        option: {
          display: 'flex',
          alignItem: 'center',
          padding: `${spacing.xs} ${spacing.xs} 0 ${spacing.xxs}`,
        },
      }}
      {...props}
    />
  );
};

InlineTimeSelect.variations = variations;

InlineTimeSelect.propTypes = {
  start: timePropType.isRequired,
  end: timePropType.isRequired,
  range: PropTypes.number.isRequired,
};

export default InlineTimeSelect;
