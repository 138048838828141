import { all, takeLatest, put, call, select, take, cancel, fork, delay, cancelled } from 'redux-saga/effects';

import {
  fetchCategoriesFailed,
  fetchCategoriesSuccessed,
  startAutoRefreshFilters,
  stopAutoRefreshFilters,
  FETCH_CATEGORIES_REQUESTED,
  STOP_AUTO_REFRESH,
  START_AUTO_REFRESH,
  CHANGE_FILTERS,
  CHANGE_AND_APPLY_SELECTED_FILTER,
  APPLY_SELECTED_FILTERS,
  fetchFlaggedConversationsCountFailed,
  fetchFlaggedConversationsCountSucceeded,
  FETCH_FLAGGED_CONVERSATIONS_COUNT_REQUESTED,
} from 'ducks/filters';
import { SET_IS_TAB_ACTIVE } from 'ducks/view';
import DataApi from 'api/DataApi';
import { getFiltersData, getFiltersLastUpdated } from 'selectors/filters';
import { CODE_CRASHED } from 'ducks/view';
import { filtersParams } from 'utils/deserializers';
import { filterKeys, FILTER_REFRESH_INTERVAL } from 'constants.js';
import omit from 'lodash.omit';

export function* handleFetchCategoriesRequested() {
  try {
    const selectedFilters = yield select(getFiltersData);

    const params = yield call(filtersParams, omit(selectedFilters, [filterKeys.CATEGORY_ID, filterKeys.FLAG_ID]));

    const categories = yield call([DataApi.apiInstance(), 'getFilters'], params);
    yield put(fetchCategoriesSuccessed(categories));
    return categories;
  } catch (e) {
    yield put(fetchCategoriesFailed(e.errors));
  }
}

export function* handleFetchConversationCountRequested() {
  try {
    const selectedFilters = yield select(getFiltersData);
    const params = yield call(filtersParams, omit(selectedFilters, [filterKeys.CATEGORY_ID, filterKeys.FLAG_ID]));

    const response = yield call([DataApi.apiInstance(), 'getConversationsFlaggedCount'], params);
    yield put(fetchFlaggedConversationsCountSucceeded(response));
  } catch (e) {
    yield put(fetchFlaggedConversationsCountFailed(e.errors));
  }
}

export function* runAutoRefreshFilters() {
  try {
    while (true) {
      yield delay(FILTER_REFRESH_INTERVAL);
      yield call(handleFetchCategoriesRequested);
      yield call(handleFetchConversationCountRequested);
    }
  } finally {
    if (yield cancelled()) {
      yield put(stopAutoRefreshFilters());
    }
  }
}

export function* autoRefreshFilters() {
  const instance = yield fork(runAutoRefreshFilters);
  yield take([STOP_AUTO_REFRESH, CODE_CRASHED]);
  yield cancel(instance);
}

export function* handleTabIsActive({ payload: { value } }) {
  yield put(stopAutoRefreshFilters());

  if (value) {
    const filtersLastUpdated = yield select(getFiltersLastUpdated);
    const timeSinceLastUpdate = new Date() - filtersLastUpdated;
    if (timeSinceLastUpdate >= FILTER_REFRESH_INTERVAL) {
      yield call(handleFetchCategoriesRequested);
    }
    yield put(startAutoRefreshFilters());
  }
}

export default function* watchFilters() {
  yield all([
    takeLatest(FETCH_FLAGGED_CONVERSATIONS_COUNT_REQUESTED, handleFetchConversationCountRequested),
    takeLatest(START_AUTO_REFRESH, autoRefreshFilters),
    takeLatest(SET_IS_TAB_ACTIVE, handleTabIsActive),
    takeLatest(
      [CHANGE_FILTERS, FETCH_CATEGORIES_REQUESTED, CHANGE_AND_APPLY_SELECTED_FILTER, APPLY_SELECTED_FILTERS],
      handleFetchCategoriesRequested,
    ),
  ]);
}
