import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { transparentize } from 'polished';

import { sizePropType } from 'ready/utils/propTypes';

import { sizes, variations } from 'ready/utils/constants';
import { Text } from 'ready/components/typography';
import Button from 'ready/components/Button/Button';

const modalVariations = {
  [sizes.S]: {
    iconSize: sizes.XS,
    headingSize: Text.sizes.S,
    paddingSize: sizes.XXS,
  },
  [sizes.M]: {
    iconSize: sizes.M,
    headingSize: Text.sizes.XL,
    paddingSize: sizes.S,
  },
};

const { S, M } = sizes;

const withBgColor = ({ theme, variation }) => css`
  background-color: ${theme.colors[variation]};
`;

const withBorder = ({ theme }) => css`
  border: ${theme.borderWidth.m} solid ${theme.colors.n200};
  border-radius: ${theme.borderRadius.m};
`;

const withOpacity = ({ theme, variation, opacity }) =>
  opacity &&
  css`
    background-color: ${transparentize(0.25, theme.colors[variation])};
    border-color: ${transparentize(0.25, theme.colors.n200)};
  `;

const basePadding = ({ theme }) => css`
  padding: ${props => theme.spacing[props.size]};
`;

const withBorderBottom = ({ theme, hasHeading }) =>
  hasHeading &&
  css`
    border-bottom: ${theme.borderWidth.m} solid ${theme.colors.n200};
  `;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${basePadding};
  ${withBorderBottom};
`;

const StyledModal = styled.div`
  position: absolute;
  z-index: ${props => props.theme.zIndex.modal};
  box-shadow: ${props => props.theme.boxShadow.s};
  ${withBgColor};
  ${withBorder};
  ${withOpacity};
`;

const withMarginRight = ({ theme, marginRight, size }) =>
  marginRight &&
  css`
    margin-right: ${theme.spacing[size]};
  `;

const Heading = styled(Text)`
  ${withMarginRight};
`;

const ActionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: -${props => props.theme.spacing.xxs};
  & > * {
    margin: 0 ${props => props.theme.spacing.xxs};
  }
`;

const Modal = ({ onClose, onMinimize, minimizeEnabled, closeEnabled, children, size, heading, ...props }) => {
  return (
    <StyledModal {...props} size={size}>
      {(heading || closeEnabled || minimizeEnabled) && (
        <HeaderWrapper hasHeading={!!heading} size={modalVariations[size].paddingSize}>
          {heading &&
            (React.isValidElement(heading) ? (
              heading
            ) : (
              <Heading
                size={modalVariations[size].headingSize}
                marginRight={!closeEnabled}
                blacklist={{ closeEnabled: true, marginRight: true }}
              >
                {heading}
              </Heading>
            ))}
          <ActionsContainer>
            {minimizeEnabled && (
              <Button
                size={modalVariations[size].iconSize}
                variation={Button.variations.OUTLINE}
                icon={{ icon: Button.icon.icons.MINUS }}
                onClick={onMinimize}
                clean
                inline
              />
            )}
            {closeEnabled && (
              <Button
                size={modalVariations[size].iconSize}
                variation={Button.variations.OUTLINE}
                icon={{ icon: Button.icon.icons.CROSS }}
                onClick={onClose}
                clean
                inline
              />
            )}
          </ActionsContainer>
        </HeaderWrapper>
      )}
      {children}
    </StyledModal>
  );
};

Modal.sizes = { S, M };
Modal.variations = variations;
Modal.basePadding = basePadding;

Modal.propTypes = {
  onClose: PropTypes.func,
  onMinimize: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  heading: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string, PropTypes.node]),
  size: sizePropType(Modal.sizes),
  closeEnabled: PropTypes.bool,
  minimizeEnabled: PropTypes.bool,
};

Modal.defaultProps = {
  variation: variations.WHITE,
  size: Modal.sizes.M,
  closeEnabled: false,
  minimizeEnabled: false,
};

export default Modal;
