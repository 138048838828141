import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text } from 'ready/components/typography';
import { getVariationOf } from 'ready/styles/helpers';
import { variations, variationsCssProps, DATE_FORMAT } from 'ready/utils/constants';
import { getWeekDays, getWeeksOfMonth, isSameMonth, isSameDate } from 'ready/utils';

const Container = styled.div`
  display: grid;
  grid-row-gap: 0.8rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  width: 100%;
`;

const dayWithSelectedStyle = ({ selected, theme }) =>
  selected &&
  css`
    background-color: ${theme.colors[getVariationOf(variations.PRIMARY, variationsCssProps.BG)]};
  `;

const Day = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  display: flex;
  height: 2.8rem;
  justify-content: center;
  text-align: center;
  width: 2.8rem;
  ${dayWithSelectedStyle};
`;

const getDayTextVariation = (selected, isDayOfMonth) =>
  (selected && variations.WHITE) || (!isDayOfMonth && 'n300') || variations.DARK;

const CalendarDays = ({ date, selectedDate, onSelectDay }) => {
  const week = getWeekDays(date);
  const weeksOfMonth = getWeeksOfMonth(date);
  return (
    <Container>
      <Row>
        {week.map(day => (
          <Text key={`names-${day.format('ddd')}`} bold variation={variations.GREY}>
            {day.format('ddd')}
          </Text>
        ))}
      </Row>
      {weeksOfMonth.map(week => (
        <Row key={week[0].format('DD/MM/YY')}>
          {week.map(day => {
            const selected = day.isSame(selectedDate);
            const bold = isSameDate(moment(day.format(DATE_FORMAT)), moment()) || selected;
            const isDayOfMonth = isSameMonth(date, day);
            return (
              <Day key={day.format('DD/MM/YYYY')} selected={selected} onClick={() => onSelectDay(day)}>
                <Text variation={getDayTextVariation(selected, isDayOfMonth)} bold={bold}>
                  {day.format('DD')}
                </Text>
              </Day>
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

CalendarDays.propTypes = {
  date: PropTypes.object.isRequired,
  selectedDate: PropTypes.object,
  onSelectDay: PropTypes.func.isRequired,
};

export default CalendarDays;
