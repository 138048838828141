import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Icon from 'ready/components/Icon/Icon';
import { mapKeysToLowerCase } from 'ready/utils';
import { childrenPropType } from 'common/propTypes';
import { SIZES } from '../constants';

const positions = {
  RIGHT: 'right',
  LEFT: 'left',
};

const withColumnGap = ({ multiColumn }) =>
  multiColumn &&
  css`
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    grid-column-gap: ${props => props.theme.spacing.xxs};
    justify-items: center;
  `;

const Wrapper = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  ${withColumnGap};
`;

const IconWrapper = ({ icon, size, position, color, children }) => {
  const _size = (SIZES[size] || { icon: size }).icon;
  return (
    <Wrapper data-qa="[ready]Button:IconWrapper" multiColumn={!!children}>
      {position === IconWrapper.positions.LEFT && <Icon icon={icon} size={_size} color={color} />}
      {children}
      {position === IconWrapper.positions.RIGHT && <Icon icon={icon} size={_size} color={color} />}
    </Wrapper>
  );
};

IconWrapper.icons = Icon.icons;
IconWrapper.sizes = Icon.sizes;
IconWrapper.positions = positions;
IconWrapper.colors = Icon.colors;

IconWrapper.propTypes = {
  icon: Icon.propTypes.icon,
  size: Icon.propTypes.size,
  position: PropTypes.oneOf(mapKeysToLowerCase(IconWrapper.positions)),
  color: Icon.propTypes.color,
  children: childrenPropType,
};

IconWrapper.defaultProps = {
  position: IconWrapper.positions.LEFT,
  size: Icon.defaultProps.size,
};

export default IconWrapper;
