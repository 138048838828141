import React from 'react';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text } from 'ready/components/typography';

const GuestIsVIPWarning = ({ t, ...props }) => (
  <ChatNotification
    variation={ChatNotification.variations.WARNING}
    data-qa="Notification:GuestIsVIPWarning__ChatNotification"
    t={t}
    {...props}
  >
    <Text bold data-qa="GuestIsVIPWarning:Text--instructions">
      {t.vipGuestLabel}
    </Text>
    <Text data-qa="GuestIsVIPWarning:Text--instructions"> {t.vipGuestText}</Text>
  </ChatNotification>
);

GuestIsVIPWarning.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
    vipGuestLabel: PropTypes.string.isRequired,
    vipGuestText: PropTypes.string.isRequired,
  }),
};

export default GuestIsVIPWarning;
