import { fromJS } from 'immutable';

export const FETCH_USER_BY_TOKEN_REQUESTED = 'FETCH_USER_BY_TOKEN_REQUESTED';
export const fetchUserByTokenRequested = () => ({ type: FETCH_USER_BY_TOKEN_REQUESTED });

export const FETCH_USER_BY_TOKEN_FAILED = 'FETCH_USER_BY_TOKEN_FAILED';
export const fetchUserByTokenFailed = () => ({ type: FETCH_USER_BY_TOKEN_FAILED });

export const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED';
export const fetchUserRequested = user => ({ type: FETCH_USER_REQUESTED, payload: { user } });

export const FETCH_USER_SUCCESSED = 'FETCH_USER_SUCCESSED';
export const fetchUserSuccessed = user => ({ type: FETCH_USER_SUCCESSED, payload: { user } });

export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export const fetchUserFailed = errors => ({ type: FETCH_USER_FAILED, payload: { errors } });

export const LOGOUT_USER_REQUESTED = 'LOGOUT_USER_REQUESTED';
export const logoutUserRequested = () => ({ type: LOGOUT_USER_REQUESTED });

export const LOGOUT_USER_SUCCESSED = 'LOGOUT_USER_SUCCESSED';
export const logoutUserSuccessed = () => ({ type: LOGOUT_USER_SUCCESSED });

export const REQUEST_GOOGLE_SIGNIN_SUCCESSED = 'REQUEST_GOOGLE_SIGNIN_SUCCESSED';
export const requestGoogleSignInSuccessed = user => ({ type: REQUEST_GOOGLE_SIGNIN_SUCCESSED, payload: { user } });

export const REQUEST_GOOGLE_SIGNIN_FAILED = 'REQUEST_GOOGLE_SIGNIN_FAILED';
export const requestGoogleSignInFailed = errors => ({ type: REQUEST_GOOGLE_SIGNIN_FAILED, payload: { errors } });

export const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  data: null,
  errors: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_REQUESTED:
    case FETCH_USER_BY_TOKEN_REQUESTED:
      return state.set('isFetching', true).set('didInvalidate', false);

    case FETCH_USER_SUCCESSED:
      return state.set('isFetching', false).set('data', fromJS(payload.user));

    case FETCH_USER_FAILED:
      return state
        .set('isFetching', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case FETCH_USER_BY_TOKEN_FAILED:
    case LOGOUT_USER_SUCCESSED:
      return state.set('isFetching', false).set('data', null);

    default:
      return state;
  }
};
