import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { zIndex } from 'ready/styles/theme';
import { childrenPropType } from 'ready/utils/propTypes';

import { Transition } from 'react-transition-group';

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: ${props => props.zIndex};
  visibility: hidden;
`;

const withTransitioningStyle = ({ state }) =>
  ['entered'].indexOf(state) > -1 &&
  css`
    transform: translateX(0);
  `;

export const Panel = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: visible;
  background-color: ${props => props.theme.colors.white};
  transition: ${props => `transform ${props.duration}ms ${props.theme.transition.timingFunction.default}`};
  transform: translateX(${props => (props.from === Drawer.from.LEFT ? '-100%' : '100%')});
  ${props => props.from}: 0;
  ${withTransitioningStyle};
`;

const withOverlayTransitionStyle = ({ state }) =>
  ['entered'].indexOf(state) > -1 &&
  css`
    opacity: 1;
  `;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: ${props => props.theme.overlayColor.default};
  position: absolute;
  visibility: visible;
  transition: ${props => props.theme.transition.default};
  ${withOverlayTransitionStyle};
`;

const Drawer = ({
  children,
  renderPanel,
  show,
  from,
  zIndex,
  unmountOnExit,
  mountOnEnter,
  className,
  hasOverlay,
  handleClose,
  duration,
}) => (
  <Transition mountOnEnter={mountOnEnter} unmountOnExit={unmountOnExit} timeout={show ? 0 : duration} in={show}>
    {state => (
      <Wrapper className={className} zIndex={zIndex}>
        {hasOverlay && state !== 'exited' && <Overlay onClick={handleClose} state={state} />}
        {renderPanel ? (
          renderPanel({ state, from, duration })
        ) : (
          <Panel state={state} from={from} duration={duration}>
            {children}
          </Panel>
        )}
      </Wrapper>
    )}
  </Transition>
);

Drawer.from = {
  LEFT: 'left',
  RIGHT: 'right',
};

Drawer.transitionDurations = {
  DEFAULT: 300,
};

Drawer.propTypes = {
  from: PropTypes.oneOf(Object.values(Drawer.from)),
  show: PropTypes.bool,
  zIndex: PropTypes.number,
  unmountOnExit: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  className: PropTypes.string,
  renderPanel: PropTypes.func,
  children: childrenPropType,
  handleClose: PropTypes.func,
  duration: PropTypes.number,
};

Drawer.defaultProps = {
  from: Drawer.from.LEFT,
  zIndex: zIndex.drawer[0],
  unmountOnExit: true,
  mountOnEnter: true,
  hasOverlay: false,
  duration: Drawer.transitionDurations.DEFAULT,
};

export default Drawer;
