import { createSelector } from 'reselect';

const _getGuest = state => state.guest;
export const getGuest = createSelector(
  [_getGuest],
  guest => guest.toJS(),
);

export const _getGuestData = state => state.guest.get('data');
export const getGuestData = createSelector(
  [_getGuestData],
  guest => (guest && guest.toJS()) || null,
);
