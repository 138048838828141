import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ContentWrapper } from '../../../../components';
import CheckOutForm from './components/CheckOutForm';
import { Collapsible } from 'ready/components';

import { bookingPropTypeWithState } from 'common/propTypes';
import { contexts } from 'constants.js';
import { hasErrors } from 'utils';

const Wrapper = styled(ContentWrapper)`
  min-height: 15rem;
  overflow-y: auto;
`;

const CheckOut = ({ t, booking }) => {
  if (!booking.data || !booking.data.check_out_mission || !booking.data.check_out_mission.id) return false;

  const checkOut = {
    ...booking.data.check_out_mission,
  };

  return (
    <Collapsible title={t.checkOutTitle} data-qa="Tab:Collapsible--checkOutMission" autoResize={!booking.isUpdating}>
      <Wrapper data-qa="CheckOut:__wrapper">
        <CheckOutForm t={t} checkOut={checkOut} hasError={hasErrors(booking.errors, contexts.infoSidebar.BOOKING)} />
      </Wrapper>
    </Collapsible>
  );
};

CheckOut.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  t: PropTypes.shape({
    agentName: PropTypes.string.isRequired,
    agentPhoneNumber: PropTypes.string.isRequired,
    missionID: PropTypes.string.isRequired,
    checklistStatus: PropTypes.string.isRequired,
    checklistUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default CheckOut;
