import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import InfoSidebar from 'scenes/shared/InfoSidebar/InfoSidebar';
import { getIsInfoSidebarOpen } from 'selectors/view';

const withHiddenStyle = ({ isOpen }) =>
  !isOpen &&
  css`
    visibility: hidden;
    position: absolute;
  `;

const Wrapper = styled.div`
  grid-area: info-sidebar;
  border-left: ${props => props.theme.app.border.default};
  border-top: ${props => props.theme.app.border.default};
  width: 28rem;
  display: flex;
  flex-direction: column;
  ${withHiddenStyle};
`;

const MainInfoSidebarScene = ({ isInfoSidebarOpen }) => (
  <Wrapper isOpen={isInfoSidebarOpen}>
    <InfoSidebar />
  </Wrapper>
);

MainInfoSidebarScene.propTypes = {
  isInfoSidebarOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  isInfoSidebarOpen: getIsInfoSidebarOpen(state),
});

export default connect(mapStateToProps)(MainInfoSidebarScene);
