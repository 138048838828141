import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

import { getBooking } from 'selectors/booking';
import { getReservationAlteration } from 'selectors/reservationAlteration';
import {
  getIsConversationDrawerOpen,
  getConversationDrawerReservationAlteration,
  getConversationDrawerBooking,
  getConversationDrawerInquiry,
} from 'selectors/conversationDrawer';
import { getSpecialOffer } from 'selectors/specialOffer';

import {
  changeShowAcceptAlterationModal,
  changeShowRejectAlterationModal,
  changeShowProposeAlterationModal,
  closeConversationDrawer,
} from 'ducks/conversationDrawer';
import { postSpecialOfferRequested } from 'ducks/specialOffer';

import Drawer, { Panel } from 'ready/components/Drawer/Drawer';
import { bookingPropTypeWithState } from 'common/propTypes';
import ConversationDrawerContent from 'scenes/shared/ConversationDrawer/components/ConversationDrawerContent';
import { setReservationAlterationProposal, fetchAlterationPriceRequested } from 'ducks/reservationAlteration';

const ConversationSidebarDrawer = styled(Drawer)`
  grid-area: content;
  position: relative;
`;

const ConversationSidebarPanel = styled(Panel)`
  width: 28rem;
`;

const MobileConversationSidebarScene = ({
  zIndex,
  isConversationDrawerOpen,
  dispatchCloseConversationDrawer,
  dispatchPostSpecialOfferRequested,
  booking,
  reservationAlteration,
  specialOffer,
  t,
  ...props
}) => (
  <ConversationSidebarDrawer
    handleClose={dispatchCloseConversationDrawer}
    hasOverlay
    from={Drawer.from.RIGHT}
    zIndex={zIndex}
    show={isConversationDrawerOpen}
    renderPanel={properties => (
      <ConversationSidebarPanel {...properties}>
        <ConversationDrawerContent
          booking={booking}
          reservationAlteration={reservationAlteration}
          specialOffer={specialOffer}
          dispatchCloseConversationDrawer={dispatchCloseConversationDrawer}
          t={t}
          {...props}
        />
      </ConversationSidebarPanel>
    )}
  />
);

MobileConversationSidebarScene.propTypes = {
  zIndex: PropTypes.number,
  booking: bookingPropTypeWithState.isRequired,
  reservationAlteration: PropTypes.object,
  isConversationDrawerOpen: PropTypes.bool,
  conversationDrawerBooking: PropTypes.number,
  conversationDrawerReservationAlteration: PropTypes.number,
  dispatchCloseConversationDrawer: PropTypes.func.isRequired,
  dispatchChangeShowAcceptAlterationModal: PropTypes.func.isRequired,
  dispatchChangeShowRejectAlterationModal: PropTypes.func.isRequired,
  dispatchChangeShowProposeAlterationModal: PropTypes.func.isRequired,
  dispatchFetchAlterationPriceRequested: PropTypes.func.isRequired,
  setReservationAlterationProposal: PropTypes.func.isRequired,
  dispatchPostSpecialOfferRequested: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  booking: getBooking(state),
  reservationAlteration: getReservationAlteration(state),
  specialOffer: getSpecialOffer(state),
  isConversationDrawerOpen: getIsConversationDrawerOpen(state),
  conversationDrawerBooking: getConversationDrawerBooking(state),
  conversationDrawerReservationAlteration: getConversationDrawerReservationAlteration(state),
  conversationDrawerInquiry: getConversationDrawerInquiry(state),
});

const mapDispatchToProps = {
  dispatchCloseConversationDrawer: closeConversationDrawer,
  dispatchChangeShowAcceptAlterationModal: changeShowAcceptAlterationModal,
  dispatchChangeShowRejectAlterationModal: changeShowRejectAlterationModal,
  dispatchChangeShowProposeAlterationModal: changeShowProposeAlterationModal,
  dispatchFetchAlterationPriceRequested: fetchAlterationPriceRequested,
  setReservationAlterationProposal: setReservationAlterationProposal,
  dispatchPostSpecialOfferRequested: postSpecialOfferRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    reservation: 'BOOKING.TITLE',
    reject: 'TEXT.SHARED.REJECT',
    approve: 'TEXT.SHARED.APPROVE',
    reset: 'TEXT.SHARED.RESET',
    apply: 'TEXT.SHARED.APPLY',
    back: 'TEXT.SHARED.BACK',
    cancel: 'TEXT.SHARED.CANCEL',
    requestedByGuest: 'BOOKING.ALTERATION.REQUESTED_BY_GUEST',
    propose: 'BOOKING.ALTERATION.FORM.PROPOSE',
    original: 'BOOKING.ALTERATION.FORM.ORIGINAL',
    proposal: 'BOOKING.ALTERATION.FORM.PROPOSAL',
    checkIn: 'BOOKING.ALTERATION.FORM.CHECK_IN',
    checkOut: 'BOOKING.ALTERATION.FORM.CHECK_OUT',
    guests: 'BOOKING.ALTERATION.FORM.GUESTS',
    adults: 'BOOKING.ALTERATION.FORM.ADULTS',
    children: 'BOOKING.ALTERATION.FORM.CHILDREN',
    pets: 'BOOKING.ALTERATION.FORM.PETS',
    property: 'BOOKING.ALTERATION.FORM.PROPERTY',
    price: 'BOOKING.ALTERATION.FORM.PRITCE',
    seeCalendar: 'BOOKING.ALTERATION.FORM.SEE_CALENDAR',
    edit: 'BOOKING.EDIT',
    updateError: 'BOOKING.ALTERATION.FORM.UPDATE_ERROR',
    totalPrice: 'BOOKING.ALTERATION.FORM.EDIT.PRICE.TOTAL_PRICE',
    rentalIncome: 'BOOKING.ALTERATION.FORM.EDIT.PRICE.RENTAL_INCOME',
    proposePrice: 'BOOKING.ALTERATION.FORM.EDIT.PRICE.PROPOSE_PRICE',
    selectGuests: 'BOOKING.ALTERATION.FORM.EDIT.GUESTS.SELECT_GUESTS',
    noPetsAllowed: 'BOOKING.ALTERATION.FORM.EDIT.GUESTS.NO_PETS_ALLOWED',
    selectCheckIn: 'BOOKING.ALTERATION.FORM.EDIT.CALENDAR.SELECT_CHECK_IN',
    selectCheckOut: 'BOOKING.ALTERATION.FORM.EDIT.CALENDAR.SELECT_CHECK_OUT',
    accepted: 'BOOKING.ALTERATION.ACCEPTED',
    rejected: 'BOOKING.ALTERATION.REJECTED',
    pending: 'BOOKING.ALTERATION.PENDING',
    specialOffer: 'SPECIAL_OFFER.TITLE',
    sendSpecialOffer: 'SPECIAL_OFFER.SEND',
    specialOfferFormDetails: 'SPECIAL_OFFER.FORM.DETAILS',
    specialOfferFormInfo: 'SPECIAL_OFFER.FORM.INFO',
    specialOfferFormCleaning: 'SPECIAL_OFFER.FORM.CLEANING',
    specialOfferFormExtra: 'SPECIAL_OFFER.FORM.EXTRA',
    specialOfferFormTotal: 'SPECIAL_OFFER.FORM.TOTAL',
    specialOfferFormEditTotal: 'SPECIAL_OFFER.FORM.EDIT_TOTAL',
    specialOfferFormEditTotalDetails: 'SPECIAL_OFFER.FORM.EDIT_TOTAL_DETAILS',
  }),
)(MobileConversationSidebarScene);
