import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Text } from '../typography';
import { getVariationOf, getColor } from 'ready/styles/helpers';
import { variations } from 'ready/utils/constants';
import { variationsCssProps } from 'ready/utils/constants';
import Icon from '../Icon/Icon';

const fadedBG = ({ variation, faded, _theme }) =>
  faded &&
  css`
    background-color: ${getColor(variation, variationsCssProps.FADE_BG, _theme)};
  `;

const baseBG = ({ variation, _theme }) => css`
  background-color: ${getColor(variation, variationsCssProps.BG, _theme)};
`;

const basePadding = ({ theme }) => css`
  padding: ${theme.spacing.xxxs} ${theme.spacing.xs};
`;

const withSmallPadding = ({ theme, widthBounds }) =>
  widthBounds &&
  css`
    padding: ${theme.spacing.xxxs} ${theme.spacing.xxs};
  `;

const withWidthBounds = ({ widthBounds }) =>
  widthBounds &&
  css`
    min-width: 8.5rem;
    max-width: 11.5rem;
  `;

const StyledTag = styled.span`
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.m};
  display: flex;
  justify-content: center;
  ${props => fadedBG(props) || baseBG(props)};
  ${props => withSmallPadding(props) || basePadding(props)};
  ${withWidthBounds};
`;

const StyledTagText = styled(Text)`
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacing.xxs};
`;

const Tag = ({ variation, size, children, icon, _theme, ...props }) => (
  <StyledTag {...props} variation={variation} _theme={_theme}>
    {icon && <StyledIcon color={getVariationOf(variation, variationsCssProps.COLOR, _theme)} icon={icon} />}
    <StyledTagText variation={getVariationOf(variation, variationsCssProps.COLOR, _theme)} size={size} element="span">
      {children}
    </StyledTagText>
  </StyledTag>
);

Tag.sizes = Text.sizes;
Tag.variations = variations;
Tag.icons = Icon.icons;

Tag.propTypes = {
  variation: PropTypes.string,
  size: Text.propTypes.size,
  children: PropTypes.string.isRequired,
  faded: PropTypes.bool,
  icon: PropTypes.string,
  _theme: PropTypes.object,
};

Tag.defaultProps = {
  size: Tag.sizes.S,
  variation: variations.PRIMARY,
  faded: false,
  hovered: false,
};

export default Tag;
