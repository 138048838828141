import React from 'react';
import styled from 'styled-components';

import { Text } from 'ready/components/typography';

const PropertyManagerContainer = styled.div`
  align-items: center;
  border: ${props => props.theme.app.border.default};
  display: flex;
  margin-bottom: ${props => props.theme.spacing.l};
  min-height: 6rem;
  padding: ${props => props.theme.spacing.xxs} 0;
  width: 100%;
  & > * {
    display: inline-block;
    margin: 0 ${props => props.theme.spacing.xxs};
    vertical-align: middle;
    word-break: break-word;
  }
`;

const PropertyManager = ({ label, office }) =>
  !!office &&
  !!office.property_manager && (
    <PropertyManagerContainer data-qa="PropertyManager:__propertyManagerContainer">
      {office.property_manager.logo && (
        <img
          src={office.property_manager.logo}
          width="50"
          height="auto"
          alt="Property manager logo"
          onError={e => (e.target.style.display = 'none')}
        />
      )}
      <div>
        <Text element="p" size={Text.sizes.S} bold data-qa="PropertyManager:Text--label">
          {label}
        </Text>
        <Text element="p" data-qa="PropertyManager:Text--name">
          {office.property_manager.name}
        </Text>
      </div>
    </PropertyManagerContainer>
  );

export default PropertyManager;
