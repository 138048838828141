import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible, Icon, Text } from 'ready/components';
import { amenityHasInfo } from 'utils/businessLogic';

const StyledCollapsible = styled(Collapsible)`
  box-shadow: none;
  border-radius: none;
  border: none;
  border-bottom: ${props => props.theme.app.border.default};
  &:last-child {
    border-bottom: none;
  }
`;

const CollapsibleTitleWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${props => props.theme.spacing.xs};
`;

const CollapsibleTitle = ({ svg, children }) => (
  <CollapsibleTitleWrapper>
    <Icon icon={svg || Icon.icons.SHADED_CIRCLE} color={Icon.colors.primary} />
    <Text data-qa="Amenity:Collapsible__Text--title">{children}</Text>
  </CollapsibleTitleWrapper>
);

CollapsibleTitle.propTypes = {
  svg: PropTypes.string,
  children: PropTypes.string.isRequired,
};

const AmenityTitle = styled.div`
  border-bottom: ${props => props.theme.app.border.default};
  padding: ${props => props.theme.spacing.s} 0;

  &:last-child {
    border-bottom: none;
  }
`;

const Amenity = ({ amenity, t }) =>
  !amenityHasInfo(amenity) ? (
    <AmenityTitle>
      <CollapsibleTitle svg={amenity.amenity.svg_icon}>{amenity.amenity.title}</CollapsibleTitle>
    </AmenityTitle>
  ) : (
    <StyledCollapsible
      title={<CollapsibleTitle svg={amenity.amenity.svg_icon}>{amenity.amenity.title}</CollapsibleTitle>}
      data-qa="Tab:Collapsible--amenity"
      collapsed
      fontSize={Collapsible.sizes.M}
      paddingH={Collapsible.sizes.NONE}
      paddingV={Collapsible.sizes.S}
    >
      <ContentWrapper minHeight="19.2rem" paddingV={ContentWrapper.sizes.NONE} paddingH={ContentWrapper.sizes.NONE}>
        <AmenityContent amenity={amenity} t={t} />
      </ContentWrapper>
    </StyledCollapsible>
  );

Amenity.propTypes = {
  amenity: PropTypes.object,
  t: PropTypes.shape({
    amenitiesTitle: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }).isRequired,
};

const AmenityContent = ({ amenity, t }) => (
  <>
    <ContentWithLabel label={t.model}>{amenity.serial_number}</ContentWithLabel>
    <ContentWithLabel label={t.brand}>{amenity.brand}</ContentWithLabel>
    <FullWidthContentWithLabel label={t.location}>{amenity.location}</FullWidthContentWithLabel>
    <FullWidthContentWithLabel label={t.details}>{amenity.details}</FullWidthContentWithLabel>
  </>
);

AmenityContent.propTypes = Amenity.propTypes;

export default Amenity;
