import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Text } from 'ready/components/typography';
import { getColor, getVariationOf } from 'ready/styles/helpers';
import { variations, variationsCssProps, DATE_FORMAT, sizes } from 'ready/utils/constants';
import { isSameMonth } from 'ready/utils';
import { sizePropType, datePropType } from 'ready/utils/propTypes';

const { S, M, L } = sizes;

const DayCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
`;

const maxRangeHeight = ({ theme, size }) =>
  `calc(${theme.typography.text[size]['font-size']} + calc(${theme.typography.text[size]['font-size']} * 0.71))`;
const withRangeStyles = ({ theme, variation, dateRangeVariations }) => css`
  background-color: ${theme.colors[getVariationOf(variation, variationsCssProps.BG, dateRangeVariations)]}};
  height: ${maxRangeHeight};
  opacity: 0.85;
  position: absolute;
  width: 100%;
`;

const withStartRangeStyles = ({ theme, startRange, size }) =>
  startRange &&
  css`
    border-bottom-left-radius: ${theme.typography.text[size]['font-size']};
    border-top-left-radius: ${theme.typography.text[size]['font-size']};
    border-right: none;
  `;

const withEndRangeStyles = ({ theme, endRange, size }) =>
  endRange &&
  css`
    border-bottom-right-radius: ${theme.typography.text[size]['font-size']};
    border-top-right-radius: ${theme.typography.text[size]['font-size']};
    border-left: none;
  `;

const withOverlapRangeStyles = ({ variation, overlap }) =>
  overlap &&
  css`
    border-color: ${getColor(variation, variationsCssProps.BORDER_COLOR)};
    border-width: 0.1rem;
    border-style: solid;
    height: calc(${maxRangeHeight} + 0.2rem);
  `;

const DayRange = styled.span`
  ${withRangeStyles};
  ${withOverlapRangeStyles};
  ${withStartRangeStyles};
  ${withEndRangeStyles};
`;

const getDayTextVariation = (selected, isDayOfMonth, variation, dateRangeVariations) =>
  (selected && getVariationOf(variation, variationsCssProps.COLOR, dateRangeVariations)) ||
  (!isDayOfMonth && 'n300') ||
  variations.DARK;

const DayRangeCell = ({ date, day, dateRanges, size, dateRangeVariations }) => {
  const isDayOfMonth = isSameMonth(date, day);

  if (!dateRanges.length) {
    return (
      <DayCell>
        <Text
          size={size}
          style={{ zIndex: 1 }}
          variation={getDayTextVariation(false, isDayOfMonth, Text.variations.OUTLINE)}
        >
          {day.format('DD')}
        </Text>
      </DayCell>
    );
  }

  const from = moment.utc(dateRanges[0].from, DATE_FORMAT).startOf('day');
  const to = moment.utc(dateRanges[0].to, DATE_FORMAT).startOf('day');

  const inRange = day.isAfter(from) && day.isBefore(to);
  const startRange = !inRange && day.isSame(from);
  const endRange = !inRange && day.isSame(to);
  const overlap =
    endRange && dateRanges.length > 1 && day.isSame(moment.utc(dateRanges[1].from, DATE_FORMAT).startOf('day'));

  const isSelected = startRange || inRange || endRange;

  return (
    <DayCell>
      {isSelected && (
        <DayRange
          size={size}
          startRange={startRange}
          inRange={inRange}
          endRange={endRange}
          variation={dateRanges[0].variation}
          dateRangeVariations={dateRangeVariations}
        />
      )}
      {overlap && (
        <DayRange
          size={size}
          startRange
          overlap
          variation={dateRanges[1].variation}
          dateRangeVariations={dateRangeVariations}
        />
      )}
      <Text
        size={size}
        style={{ zIndex: 1 }}
        variation={getDayTextVariation(isSelected, isDayOfMonth, dateRanges[0].variation, dateRangeVariations)}
      >
        {day.format('DD')}
      </Text>
    </DayCell>
  );
};

DayRangeCell.sizes = { S, M, L };

DayRangeCell.propTypes = {
  date: PropTypes.object,
  day: PropTypes.object,
  dateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      from: datePropType,
      to: datePropType,
      variation: PropTypes.string,
    }),
  ),
  dateRangeVariations: PropTypes.object,
  size: sizePropType(DayRangeCell.sizes),
};

DayRangeCell.defaultProps = {
  size: M,
};

export default DayRangeCell;
