import styled from 'styled-components';

export { default as SpecialOfferNotification } from './SpecialOfferNotification';
export { default as TextOrLink } from './TextOrLink/TextOrLink';
export { default as SidebarToggle } from './SidebarToggle/SidebarToggle';
export { default as RequestError } from './RequestError/RequestError';
export { default as InlineTimeSelect } from './InlineTimeSelect/InlineTimeSelect';
export { default as Logo } from './GRCommunicationLogo/GRCommunicationLogo';
export { default as Field, FieldGroup } from './Field/Field';
export { default as ChatNotification } from './ChatNotification/ChatNotification';
export { default as PremiumClassTag } from './PremiumClassTag/PremiumClassTag';
export { default as SelfCheckInTag } from './SelfCheckInTag/SelfCheckInTag';
export { default as OfficeTag } from './OfficeTag/OfficeTag';
export { default as AttachmentPreview } from './AttachmentPreview/AttachmentPreview';

export const OverlayBackground = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.overlayColor.default};
  position: absolute;
  transition: ${props => props.theme.transition.default};
  z-index: ${props => props.theme.zIndex.modal};
`;
