import React from 'react';
import styled, { css } from 'styled-components';
import ReactTextMask from 'react-text-mask';
import PropTypes from 'prop-types';

const withErrorStyle = ({ error, theme }) =>
  error &&
  css`
    border: ${theme.borderWidth.m} solid ${theme.colors.r500};
    background-color: ${theme.colors.r100};
    ::placeholder {
      color: ${theme.colors.r500};
    }
  `;

export const inputCss = css`
  border: ${props => `${props.theme.borderWidth.m} solid ${props.theme.colors.n300}`};
  color: ${props => props.theme.colors.b900_v2};
  background-color: ${props => props.theme.colors.white};
  box-shadow: none;
  outline: none;
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.s}`};
  border-radius: ${props => props.theme.borderRadius.m};
  transition: ${props => props.theme.transition.default};
  width: 100%;
  appearance: none;
  height: 3.6rem;
  ${props => props.theme.typography.text.m};
  ${withErrorStyle};

  &::placeholder {
    color: ${props => props.theme.colors.n500};
  }
  &:focus {
    box-shadow: ${props => props.theme.boxShadow.m};
  }
  &[disabled] {
    border-color: ${props => props.theme.colors.n200};
    &,
    &::placeholder {
      color: ${props => props.theme.colors.n300};
    }
  }
`;

const StyledInput = styled.input`
  ${inputCss}
`;

const Input = ({ mask, guide, ...props }) =>
  (mask && (
    <ReactTextMask
      mask={mask}
      guide={guide}
      placeholder={props.placeholder}
      {...props}
      render={(ref, maskProps) => <StyledInput ref={ref} {...maskProps} />}
    />
  )) || <StyledInput {...props} />;

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
