import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ChatNotification, OverlayBackground } from 'components';
import { Text, Button, Modal } from 'ready/components';
import { FormattedMessage } from 'react-intl';
import { DATE_TIME_FORMAT } from 'ready/utils/constants';
import moment from 'moment';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spacing.xs};
`;

const StyledOverlayBackground = styled(OverlayBackground)`
  z-index: ${props => props.theme.zIndex.drawer[2]};
`;
const StyledModal = styled(Modal)`
  max-width: 40rem;
`;
const Content = styled.div`
  padding: ${props => props.theme.spacing.s};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.m} 0;
`;

const EditSpecialOffer = ({
  t,
  expiresAt,
  onEditOffer,
  onWithdrawOffer,
  disabled,
  showSpecialOfferWithdrawModal,
  dispatchChangeShowSpecialOfferWithdrawModal,
}) => {
  const handleWithdrawOffer = () => {
    onWithdrawOffer();
    dispatchChangeShowSpecialOfferWithdrawModal();
  };
  return (
    <>
      <ChatNotification t={t} data-qa="Notification:AcceptEnquiry__ChatNotification">
        <ContentWrapper>
          <Text bold data-qa="EditSpecialOffer:Text">
            <FormattedMessage
              id="SPECIAL_OFFER.PENDING_SPECIAL_OFFER"
              values={{
                expire_date: moment(expiresAt)
                  .local()
                  .format(DATE_TIME_FORMAT),
              }}
            />
          </Text>
          <ActionsWrapper>
            <Button
              inline
              size={Button.sizes.S}
              variation={Button.variations.OUTLINE}
              onClick={dispatchChangeShowSpecialOfferWithdrawModal}
              disabled={disabled}
              data-qa="EditSpecialOffer:Button--withdraw"
            >
              {t.withdraw}
            </Button>
            <Button
              inline
              size={Button.sizes.S}
              variation={Button.variations.OUTLINE}
              onClick={onEditOffer}
              disabled={disabled}
              data-qa="EditSpecialOffer:Button--edit"
            >
              {t.edit}
            </Button>
          </ActionsWrapper>
        </ContentWrapper>
      </ChatNotification>
      {showSpecialOfferWithdrawModal && (
        <StyledOverlayBackground>
          <StyledModal heading={t.confirmWithdraw} size={Modal.sizes.M}>
            <Content>
              <Text>{t.withdrawDetails}</Text>
              <ButtonsWrapper>
                <Button
                  variation={Button.variations.OUTLINE}
                  size={Button.sizes.M}
                  inline
                  onClick={dispatchChangeShowSpecialOfferWithdrawModal}
                >
                  {t.keepIt}
                </Button>
                <Button variation={Button.variations.ERROR} size={Button.sizes.M} inline onClick={handleWithdrawOffer}>
                  {t.withdraw}
                </Button>
              </ButtonsWrapper>
            </Content>
          </StyledModal>
        </StyledOverlayBackground>
      )}
    </>
  );
};

EditSpecialOffer.propTypes = {
  onDecline: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  onSendSpecialOffer: PropTypes.func,
  disabled: PropTypes.bool,
  t: PropTypes.shape({
    decline: PropTypes.string,
    accept: PropTypes.string,
    showMore: PropTypes.string,
    showLess: PropTypes.string,
    edit: PropTypes.string,
    withdraw: PropTypes.string,
    keepIt: PropTypes.string,
    confirmWithdraw: PropTypes.string,
    withdrawDetails: PropTypes.string,
  }),
};

export default EditSpecialOffer;
