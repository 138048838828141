import { all, takeLatest, put, call } from 'redux-saga/effects';

import DataApi from 'api/DataApi';
import { FETCH_RENTAL_REQUESTED, fetchRentalSuccessed, fetchRentalFailed } from 'ducks/rental';

export function* handleFetchRentalRequested({ payload: { id } }) {
  try {
    const rental = yield call([DataApi.apiInstance(), 'getRental'], id);
    yield put(fetchRentalSuccessed(rental));
  } catch (e) {
    yield put(fetchRentalFailed(e));
  }
}

export default function* watchFilters() {
  yield all([takeLatest(FETCH_RENTAL_REQUESTED, handleFetchRentalRequested)]);
}
