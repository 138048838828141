import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { fetchConversationRequested } from 'ducks/conversation';
import { fetchMoreConversationsRequested, sortConversationsRequested } from 'ducks/conversations';
import { getConversations, getConversationsSortKey } from 'selectors/conversations';

import List from './components/List';
import SortBar from './components/SortBar';
import { RequestError } from 'components';
import LoadingIndicator from 'ready/components/LoadingIndicator/LoadingIndicator';

import { withMemoMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { conversationsPropTypeWithState } from 'common/propTypes';
import { changeConversationRoute, changeSortRouteParam } from 'browserHistory';

const CenteredContentWrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.l};
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: conversation-list;
  grid-template-rows: auto minmax(0, 1fr);
  border-right: ${props => props.theme.app.border.default};
  background-color: ${props => props.theme.colors.n100};
`;

const ListWrapper = ({
  onItemClick,
  conversations,
  dispatchFetchConversationRequested,
  dispatchFetchMoreConversationsRequested,
  t,
}) => {
  const handleItemClick = useCallback(id => {
    onItemClick(id);
    changeConversationRoute(id);
    dispatchFetchConversationRequested(id);
  });

  if (conversations.isFetching) {
    return (
      <CenteredContentWrapper>
        <LoadingIndicator />
      </CenteredContentWrapper>
    );
  }

  if (conversations.didInvalidate) {
    return (
      <CenteredContentWrapper>
        <RequestError errors={conversations.errors} />
      </CenteredContentWrapper>
    );
  }

  return (
    <List
      conversations={conversations.data}
      onItemClick={handleItemClick}
      hasNextPage={!!conversations.page.nextLink}
      onLoadMore={dispatchFetchMoreConversationsRequested}
      didInvalidate={conversations.page.didInvalidate}
      t={t}
    />
  );
};

ListWrapper.propTypes = {
  onItemClick: PropTypes.func,
  conversations: conversationsPropTypeWithState.isRequired,
  dispatchFetchMoreConversationsRequested: PropTypes.func.isRequired,
  dispatchFetchConversationRequested: PropTypes.func.isRequired,
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
  }).isRequired,
};

const ConversationList = ({
  onItemClick,
  conversations,
  dispatchFetchConversationRequested,
  dispatchFetchMoreConversationsRequested,
  dispatchSortConversationsRequested,
  t,
  sortKey,
  intl,
}) => (
  <ContentWrapper>
    <SortBar
      intl={intl}
      onChange={key => {
        dispatchSortConversationsRequested(key);
        changeSortRouteParam(key);
      }}
      sortKey={sortKey}
    />
    <ListWrapper
      conversations={conversations}
      onItemClick={onItemClick}
      dispatchFetchConversationRequested={dispatchFetchConversationRequested}
      dispatchFetchMoreConversationsRequested={dispatchFetchMoreConversationsRequested}
      t={t}
    />
  </ContentWrapper>
);

ConversationList.propTypes = {
  ...ListWrapper.propTypes,
  dispatchSortConversationsRequested: PropTypes.func.isRequired,
  intl: SortBar.propTypes.intl,
  sortKey: SortBar.propTypes.sortKey,
};

ConversationList.defaultProps = {
  onItemClick: () => {},
};

const mapStateToProps = state => ({
  conversations: getConversations(state),
  sortKey: getConversationsSortKey(state),
});

const mapDispatchToProps = {
  dispatchFetchMoreConversationsRequested: fetchMoreConversationsRequested,
  dispatchFetchConversationRequested: fetchConversationRequested,
  dispatchSortConversationsRequested: sortConversationsRequested,
};

export default compose(
  withMemoMappedTranslations({ loadMoreError: 'CONVERSATIONS.LOAD_MORE_ERROR', loadMore: 'TEXT.SHARED.LOAD_MORE' }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ConversationList);
