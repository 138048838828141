import { fromJS } from 'immutable';

export const POST_SPECIAL_OFFER_REQUESTED = 'POST_SPECIAL_OFFER_REQUESTED';
export const postSpecialOfferRequested = payload => ({
  type: POST_SPECIAL_OFFER_REQUESTED,
  payload: { payload },
});

export const POST_SPECIAL_OFFER_SUCCESSED = 'POST_SPECIAL_OFFER_SUCCESSED';
export const postSpecialOfferSuccessed = specialOffer => ({
  type: POST_SPECIAL_OFFER_SUCCESSED,
  payload: { specialOffer },
});

export const POST_SPECIAL_OFFER_FAILED = 'POST_SPECIAL_OFFER_FAILED';
export const postSpecialOfferFailed = errors => ({
  type: POST_SPECIAL_OFFER_FAILED,
  payload: { errors },
});

export const UPDATE_SPECIAL_OFFER_REQUESTED = 'UPDATE_SPECIAL_OFFER_REQUESTED';
export const updateSpecialOfferRequested = (id, payload) => ({
  type: UPDATE_SPECIAL_OFFER_REQUESTED,
  payload: { id, payload },
});

export const CHANGE_SPECIAL_OFFER_FORM_VISIBILITY = 'CHANGE_SPECIAL_OFFER_FORM_VISIBILITY';
export const changeSpecialOfferFormVisibility = (key, value) => ({
  type: CHANGE_SPECIAL_OFFER_FORM_VISIBILITY,
  payload: { key, value },
});

export const DELETE_SPECIAL_OFFER_REQUESTED = 'DELETE_SPECIAL_OFFER_REQUESTED';
export const deleteSpecialOfferRequested = id => ({ type: DELETE_SPECIAL_OFFER_REQUESTED, payload: { id } });

export const DELETE_SPECIAL_OFFER_SUCCESSED = 'DELETE_SPECIAL_OFFER_SUCCESSED';
export const deleteSpecialOfferSuccessed = () => ({ type: DELETE_SPECIAL_OFFER_SUCCESSED });

export const DELETE_SPECIAL_OFFER_FAILED = 'DELETE_SPECIAL_OFFER_FAILED';
export const deleteSpecialOfferFailed = errors => ({
  type: DELETE_SPECIAL_OFFER_FAILED,
  payload: { errors },
});

export const FETCH_SPECIAL_OFFER_PRICE_REQUESTED = 'FETCH_SPECIAL_OFFER_PRICE_REQUESTED';
export const fetchSpecialOfferPriceRequested = (id, data) => ({
  type: FETCH_SPECIAL_OFFER_PRICE_REQUESTED,
  payload: { id, data },
});

export const FETCH_SPECIAL_OFFER_PRICE_SUCCESSED = 'FETCH_SPECIAL_OFFER_PRICE_SUCCESSED';
export const fetchSpecialOfferPriceSuccessed = (rentalIncome, cleaningFee, extraFee, totalPrice) => ({
  type: FETCH_SPECIAL_OFFER_PRICE_SUCCESSED,
  payload: { rentalIncome, cleaningFee, extraFee, totalPrice },
});

export const FETCH_SPECIAL_OFFER_PRICE_FAILED = 'FETCH_SPECIAL_OFFER_PRICE_FAILED';
export const fetchSpecialOfferPriceFailed = errors => ({
  type: FETCH_SPECIAL_OFFER_PRICE_FAILED,
  payload: { errors },
});

export const CHANGE_SHOW_SPECIAL_OFFER_WITHDRAW_MODAL = 'CHANGE_SHOW_SPECIAL_OFFER_WITHDRAW_MODAL';
export const changeShowSpecialOfferWithdrawModal = value => ({
  type: CHANGE_SHOW_SPECIAL_OFFER_WITHDRAW_MODAL,
  payload: { value },
});

export const initialState = fromJS({
  isFetching: false,
  isDeleting: false,
  didInvalidate: false,
  data: {},
  priceData: {
    rentalIncome: null,
    cleaningFee: null,
    extraFee: null,
    totalPrice: null,
    isFetching: false,
  },
  form: {
    price: '',
    visible: false,
  },
  showSpecialOfferWithdrawModal: false,
  errors: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POST_SPECIAL_OFFER_REQUESTED:
    case UPDATE_SPECIAL_OFFER_REQUESTED:
      return state.set('isFetching', true).set('didInvalidate', false);

    case POST_SPECIAL_OFFER_SUCCESSED:
      return state
        .set('isFetching', false)
        .set('data', fromJS(payload.specialOffer))
        .setIn(['form', 'price'], '');

    case POST_SPECIAL_OFFER_FAILED:
      return state
        .set('didInvalidate', true)
        .set('isFetching', false)
        .set('errors', fromJS(payload.errors));

    case DELETE_SPECIAL_OFFER_REQUESTED:
      return state.set('isDeleting', true).set('didInvalidate', false);

    case DELETE_SPECIAL_OFFER_SUCCESSED:
      return state.set('isDeleting', false).set('data', fromJS({}));

    case DELETE_SPECIAL_OFFER_FAILED:
      return state
        .set('isDeleting', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case FETCH_SPECIAL_OFFER_PRICE_REQUESTED:
      return state
        .setIn(['priceData', 'rentalIncome'], null)
        .setIn(['priceData', 'cleaningFee'], null)
        .setIn(['priceData', 'extraFee'], null)
        .setIn(['priceData', 'totalPrice'], null)
        .setIn(['priceData', 'isFetching'], true)
        .set('didInvalidate', false);

    case FETCH_SPECIAL_OFFER_PRICE_SUCCESSED:
      return state
        .setIn(['priceData', 'isFetching'], false)
        .setIn(['priceData', 'rentalIncome'], fromJS(payload.rentalIncome))
        .setIn(['priceData', 'cleaningFee'], fromJS(payload.cleaningFee))
        .setIn(['priceData', 'extraFee'], fromJS(payload.extraFee))
        .setIn(['priceData', 'totalPrice'], fromJS(payload.totalPrice));

    case FETCH_SPECIAL_OFFER_PRICE_FAILED:
      return state
        .set('didInvalidate', true)
        .setIn(['priceData', 'isFetching'], false)
        .setIn(['errors', 'context'], fromJS(payload.errors));

    case CHANGE_SHOW_SPECIAL_OFFER_WITHDRAW_MODAL:
      return state.update('showSpecialOfferWithdrawModal', updateBoolValue.bind(null, payload));

    case CHANGE_SPECIAL_OFFER_FORM_VISIBILITY:
      return state.setIn(['form', payload.key], payload.value);

    default:
      return state;
  }
};

const updateBoolValue = (payload, value) => (typeof payload.value === 'boolean' ? payload.value : !value);
