import React from 'react';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text } from 'ready/components/typography';
import { FormattedMessage } from 'react-intl';

const BlacklistWarning = ({ t, reasons, ...props }) => (
  <FormattedMessage
    id="CONVERSATION.BLACKLIST.TEXT"
    values={{
      reasons,
    }}
  >
    {txt => (
      <ChatNotification
        variation={ChatNotification.variations.ERROR}
        data-qa="Notification:BlacklistWarning__ChatNotification"
        t={t}
        {...props}
      >
        <Text bold data-qa="BlacklistWarning:Text--instructions">
          {t.blacklistLabel}:
        </Text>
        <Text data-qa="BlacklistWarning:Text--instructions"> {txt}</Text>
      </ChatNotification>
    )}
  </FormattedMessage>
);

BlacklistWarning.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
    blacklistLabel: PropTypes.string.isRequired,
  }),
  reasons: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default BlacklistWarning;
