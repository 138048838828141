import React from 'react';
import styled from 'styled-components';

import ConversationList from 'scenes/shared/ConversationList/ConversationList';
import ConversationsSearchBar from 'scenes/shared/ConversationList/containers/ConversationsSearchBar';

const HeaderWrapper = styled.div`
  grid-area: conversation-list-header;
  border-right: ${props => props.theme.app.border.default};
  border-bottom: ${props => props.theme.app.border.default};
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.spacing.xxs};
`;

const MainConversationListScene = () => (
  <>
    <HeaderWrapper>
      <ConversationsSearchBar />
    </HeaderWrapper>
    <ConversationList />
  </>
);

export default MainConversationListScene;
