import React from 'react';
import PropTypes from 'prop-types';

import { ChatNotification } from 'components';
import { Text } from 'ready/components/typography';

const PreApprovalInstructions = ({ t, children, ...props }) => (
  <ChatNotification
    variation={ChatNotification.variations.WARNING}
    data-qa="Notification:PreApprovalInstructions__ChatNotification"
    t={t}
    {...props}
  >
    <Text bold data-qa="PreApprovalInstructions:Text--instructions">
      {t.preApprovalLabel}:
    </Text>
    <Text data-qa="PreApprovalInstructions:Text--instructions"> {children}</Text>
  </ChatNotification>
);

PreApprovalInstructions.propTypes = {
  t: PropTypes.shape({
    showMore: PropTypes.string.isRequired,
    showLess: PropTypes.string.isRequired,
    preApprovalLabel: PropTypes.string.isRequired,
  }),
  children: PropTypes.string.isRequired,
};

export default PreApprovalInstructions;
