import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { Modal } from 'ready/components';
import { OverlayBackground } from 'components';
import { getShowAcceptAlterationModal } from 'selectors/conversationDrawer';
import { changeShowAcceptAlterationModal } from 'ducks/conversationDrawer';
import { Text, Button } from 'ready/components';
import { acceptReservationAlterationRequested } from 'ducks/reservationAlteration';
import { getReservationAlteration } from 'selectors/reservationAlteration';

const StyledOverlayBackground = styled(OverlayBackground)`
  z-index: ${props => props.theme.zIndex.drawer[2]};
`;
const Content = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.m} 0;
`;

const AcceptAlterationModal = ({
  showAcceptAlterationModal,
  reservationAlteration,
  dispatchChangeShowAcceptAlterationModal,
  dispatchAcceptReservationAlteration,
  t,
}) => {
  function acceptChanges() {
    dispatchChangeShowAcceptAlterationModal(false);
    dispatchAcceptReservationAlteration(reservationAlteration.data.id);
  }
  return (
    <>
      {showAcceptAlterationModal && (
        <StyledOverlayBackground>
          <Modal heading={t.acceptHeader} size={Modal.sizes.M}>
            <Content>
              <Text>{t.acceptContent}</Text>
              <ButtonsWrapper>
                <Button
                  variation={Button.variations.OUTLINE}
                  size={Button.sizes.M}
                  inline
                  onClick={() => dispatchChangeShowAcceptAlterationModal(false)}
                >
                  {t.cancel}
                </Button>
                <Button variation={Button.variations.PRIMARY} size={Button.sizes.M} inline onClick={acceptChanges}>
                  {t.acceptAction}
                </Button>
              </ButtonsWrapper>
            </Content>
          </Modal>
        </StyledOverlayBackground>
      )}
    </>
  );
};

AcceptAlterationModal.propTypes = {
  showAcceptAlterationModal: PropTypes.bool.isRequired,
  reservationAlteration: PropTypes.object,
  dispatchChangeShowAcceptAlterationModal: PropTypes.func.isRequired,
  dispatchAcceptReservationAlteration: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  showAcceptAlterationModal: getShowAcceptAlterationModal(state),
  reservationAlteration: getReservationAlteration(state),
});

const mapDispatchToProps = {
  dispatchChangeShowAcceptAlterationModal: changeShowAcceptAlterationModal,
  dispatchAcceptReservationAlteration: acceptReservationAlterationRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    acceptHeader: 'BOOKING.ALTERATION.FORM.MODAL.ACCEPT_HEADER',
    acceptContent: 'BOOKING.ALTERATION.FORM.MODAL.ACCEPT_CONTENT',
    acceptAction: 'BOOKING.ALTERATION.FORM.MODAL.ACCEPT_ACTION',
    cancel: 'TEXT.SHARED.CANCEL',
  }),
)(AcceptAlterationModal);
