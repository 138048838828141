// Accessing localStorage triggers an error on certain browsers.
// This proxy can be used in cases where accessing localStorage should fail silently

export const localStorageProxy = {
  setItem: (...args) => {
    try {
      return localStorage.setItem(...args);
    } catch (e) {
      return undefined;
    }
  },
  getItem: (...args) => {
    try {
      return localStorage.getItem(...args);
    } catch (e) {
      return undefined;
    }
  },
  removeItem: (...args) => {
    try {
      return localStorage.removeItem(...args);
    } catch (e) {
      return undefined;
    }
  },
};
