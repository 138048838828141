import React from 'react';
import PropTypes from 'prop-types';

import { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Collapsible } from 'ready/components';

import { fullRentalPropType } from 'common/propTypes';

const Wifi = ({ property, t }) => (
  <Collapsible title={t.wifiTitle} data-qa="Tab:Collapsible--wifi" collapsed>
    <ContentWrapper minHeight="12.8rem">
      <WifiContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

Wifi.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    wifiTitle: PropTypes.string.isRequired,
    wifiName: PropTypes.string.isRequired,
    wifiPassword: PropTypes.string.isRequired,
    wifiInfo: PropTypes.string.isRequired,
  }).isRequired,
};

const WifiContent = ({ property, t }) => {
  return (
    <>
      <FullWidthContentWithLabel label={t.wifiName}>{property.wifi_name}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.wifiPassword}>{property.wifi_password}</FullWidthContentWithLabel>
    </>
  );
};

WifiContent.propTypes = Wifi.propTypes;

export default Wifi;
