import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Text from 'ready/components/typography/Text/Text';
import Icon from 'ready/components/Icon/Icon';
import PropTypes from 'prop-types';

import { sizes } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';
import { withPadding } from 'ready/styles/helpers';

const Wrapper = styled.div`
  box-shadow: ${props => props.theme.boxShadow.s};
  border-radius: ${props => props.theme.borderRadius.m};
  border: ${props => props.theme.app.border.default};
  width: 100%;
`;

const Accordion = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  cursor: pointer;
  user-select: none;
  ${withPadding};
`;

const Arrow = styled(Icon)`
  transform: rotate(${props => (props.collapsed ? '0' : '90')}deg);
  transition: transform 0.1s linear;
`;

const expandedStyle = ({ collapsed, isTransitioning }) =>
  !collapsed &&
  !isTransitioning &&
  css`
    height: auto;
    overflow: visible;
  `;

const onExpandingStyle = ({ collapsed, height }) =>
  !collapsed &&
  css`
    height: ${height}px;
  `;

const collapsedStyle = () =>
  css`
    height: 0;
  `;

const ContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: ${props => props.theme.transition.custom('height', 'fast')};
  ${props => expandedStyle(props) || onExpandingStyle(props) || collapsedStyle(props)};
`;

const Collapsible = ({
  title,
  children,
  autoResize,
  'data-qa': dataQA,
  collapsed,
  paddingV,
  paddingH,
  fontSize,
  iconComponent,
  ...props
}) => {
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [height, setHeight] = useState(0);
  const elementRef = useRef();
  const measuredRef = useCallback(
    node => {
      if (node !== null && autoResize) {
        elementRef.current = node;
        setHeight(node.getBoundingClientRect().height);
      }
    },
    [children, autoResize],
  );

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const handleAccordionClick = () => {
    setIsTransitioning(true);
    setHeight(elementRef.current.getBoundingClientRect().height);
  };

  useEffect(() => {
    if (isTransitioning) setCollapsed(!isCollapsed);
  }, [isTransitioning]);

  return (
    <Wrapper data-qa={`[ready]${dataQA}`} {...props}>
      <Accordion
        paddingV={paddingV}
        paddingH={paddingH}
        onClick={handleAccordionClick}
        data-qa="Collapsible:__accordion"
      >
        {(typeof title === 'string' && (
          <Text size={fontSize} bold data-qa="ready:Collapsible__Text">
            {title}
          </Text>
        )) ||
          title}
        {iconComponent || <Arrow icon={Icon.icons.ARROW_RIGHT} collapsed={isCollapsed} data-qa="Collapsible:__Icon" />}
      </Accordion>
      <ContentWrapper
        collapsed={isCollapsed}
        height={height}
        isTransitioning={isTransitioning}
        data-qa="Collapsible:__contentWrapper"
        onTransitionEnd={handleTransitionEnd}
      >
        <div ref={measuredRef}>{children}</div>
      </ContentWrapper>
    </Wrapper>
  );
};

Collapsible.sizes = sizes;

Collapsible.propTypes = {
  'data-qa': PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  autoResize: PropTypes.bool,
  collapsed: PropTypes.bool,
  paddingV: sizePropType(sizes),
  paddingH: sizePropType(sizes),
  fontSize: sizePropType(Text.sizes),
  iconComponent: PropTypes.node,
};

Collapsible.defaultProps = {
  'data-qa': 'Collapsible:__wrapper',
  autoResize: true,
  collapsed: false,
  paddingV: sizes.M,
  paddingH: sizes.L,
  fontSize: sizes.L,
};

export default Collapsible;
