import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { fetchRentalRequested } from 'ducks/rental';
import { getRental, getOfficePolicies } from 'selectors/rental';

import { withMemoMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { fullRentalPropTypeWithState, fullRentalPropType, officePoliciesPropType } from 'common/propTypes';

import {
  CenteredLoadingIndicator,
  CenteredRequestError,
  TabContentWrapper,
  TabWithForm,
  FormRow,
} from '../../components';
import SelectProperty from '../../components/SelectProperty';

import { SubHeading } from './components';
import CheckIn from './components/CheckIn';
import CheckOut from './components/CheckOut';
import SecurityDeposit from './components/SecurityDeposit';
import Policy from './components/Policy';

const OfficePoliciesContent = ({ t, rental, officePolicies }) => {
  if (rental.isFetching) return <CenteredLoadingIndicator />;

  if (rental.didInvalidate) return <CenteredRequestError errors={rental.errors} />;

  if (!rental.data) return false;

  return (
    <TabContentWrapper>
      <SubHeading>
        <FormattedMessage
          id="INFO_SIDEBAR.OFFICE_POLICIES.SUBTITLE"
          values={{ office: rental.data.office.name, property_manager: rental.data.office.property_manager.name }}
        />
      </SubHeading>
      <CheckIn t={t} officePolicies={officePolicies} rental={rental.data} />
      <CheckOut t={t} officePolicies={officePolicies} rental={rental.data} />
      <Policy
        title={t.luggageDropTitle}
        data-qa="Tab:Collapsible--luggageDrop"
        content={officePolicies.luggage_drop_policy}
        collapsed
      />
      <Policy
        title={t.guestVettingTitle}
        data-qa="Tab:Collapsible--guestVetting"
        content={officePolicies.guest_vetting_process}
        collapsed
      />
      <SecurityDeposit t={t} rental={rental.data} officePolicies={officePolicies} />
      <Policy
        title={t.discountPolicyTitle}
        data-qa="Tab:Collapsible--discountPolicy"
        content={officePolicies.discount_policy}
        collapsed
      />
      <Policy
        title={t.specialRequestsTitle}
        data-qa="Tab:Collapsible--specialRequests"
        content={officePolicies.special_requests}
        collapsed
      />
      <Policy
        title={t.eventsTitle}
        data-qa="Tab:Collapsible--eventsPolicy"
        content={officePolicies.events_policy}
        collapsed
      />
      <Policy
        title={t.inStayCleaningPriceTitle}
        data-qa="Tab:Collapsible--inStayCleaningPrice"
        content={officePolicies.instay_cleaning_price}
        collapsed
      />
      <Policy title={t.petsTitle} data-qa="Tab:Collapsible--pets" content={officePolicies.pets_policy} collapsed />
      <Policy
        title={t.emergencyContactTitle}
        data-qa="Tab:Collapsible--emergencyContact"
        content={officePolicies.emergency_contact}
        collapsed
      />
      <Policy
        title={t.extraInformationTitle}
        data-qa="Tab:Collapsible--extraInformation"
        content={officePolicies.extra_information}
        collapsed
      />
    </TabContentWrapper>
  );
};

OfficePoliciesContent.propTypes = {
  rental: fullRentalPropTypeWithState.isRequired,
  t: PropTypes.object.isRequired,
};

const OfficePolicies = ({ t, rental, rentals, officePolicies, dispatchFetchRentalRequested }) => {
  return (
    <TabWithForm data-qa="Tab:__innerWrapper--officePolicies">
      {rentals.length > 1 ? (
        <FormRow>
          <SelectProperty
            rentals={rentals}
            rental={rental.data}
            t={t}
            onChange={option => dispatchFetchRentalRequested(option.value)}
          />
        </FormRow>
      ) : (
        <div />
      )}
      <OfficePoliciesContent officePolicies={officePolicies} rental={rental} t={t} />
    </TabWithForm>
  );
};

OfficePolicies.propTypes = {
  dispatchFetchRentalRequested: PropTypes.func.isRequired,
  rentals: PropTypes.arrayOf(fullRentalPropType).isRequired,
  officePolicies: officePoliciesPropType,
  ...OfficePoliciesContent.propTypes,
};

const mapStateToProps = state => ({
  rental: getRental(state),
  officePolicies: getOfficePolicies(state),
});

const mapDispatchToProps = {
  dispatchFetchRentalRequested: fetchRentalRequested,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMemoMappedTranslations({
    checkInTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_IN.TITLE',
    minCheckInTime: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_IN.MIN_CHECK_IN_TIME',
    maxCheckInTime: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_IN.MAX_CHECK_IN_TIME',
    officeLevel: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_IN.OFFICE_LEVEL',
    propertyLevel: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_IN.PROPERTY_LEVEL',
    checkInProcess: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_IN.CHECK_IN_PROCESS',
    checkOutTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_OUT.TITLE',
    maxCheckOutTime: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_OUT.MAX_CHECK_OUT_TIME',
    checkOutProcess: 'INFO_SIDEBAR.OFFICE_POLICIES.CHECK_OUT.CHECK_OUT_PROCESS',
    luggageDropTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.LUGGAGE_DROP.TITLE',
    guestVettingTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.GUEST_VETTING.TITLE',
    securityDepositTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.SECURITY_DEPOSIT.TITLE',
    airbnbGuestIdPolicy: 'INFO_SIDEBAR.OFFICE_POLICIES.SECURITY_DEPOSIT.AIRBNB_GUEST_ID_POLICY',
    guestIdReview: 'INFO_SIDEBAR.OFFICE_POLICIES.SECURITY_DEPOSIT.GUEST_ID_REVIEW',
    discountPolicyTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.DISCOUNT_POLICY.TITLE',
    specialRequestsTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.SPECIAL_REQUESTS.TITLE',
    eventsTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.EVENTS.TITLE',
    inStayCleaningPriceTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.IN_STAY_CLEANING_PRICE.TITLE',
    petsTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.PETS.TITLE',
    emergencyContactTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.EMERGENCY_CONTACT.TITLE',
    extraInformationTitle: 'INFO_SIDEBAR.OFFICE_POLICIES.EXTRA_INFORMATION.TITLE',
    yes: 'TEXT.SHARED.YES',
    no: 'TEXT.SHARED.NO',
  }),
)(OfficePolicies);
