import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Notification from 'ready/components/Notification/Notification';
import { StyledNotification } from './GuestVerifiedNotification';

const StyledLink = styled.a`
  width: 100%;
  display: inline-block;
  text-decoration: none;
`;

const GuestProfileNotification = ({ platform_id, t }) => {
  return (
    <StyledLink href={`https://www.airbnb.com/users/show/${platform_id}`} target="_blank">
      <StyledNotification variation={Notification.variations.LINK}>{t.airbnbProfile}</StyledNotification>
    </StyledLink>
  );
};

GuestProfileNotification.propTypes = {
  platform_id: PropTypes.string,
  t: PropTypes.shape({
    airbnbProfile: PropTypes.string.isRequired,
  }).isRequired,
};

export default GuestProfileNotification;
