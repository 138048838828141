import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConversationList from 'scenes/shared/ConversationList/ConversationList';
import { changeIsConversationListOpen } from 'ducks/view';
import { conversationPropType } from 'common/propTypes';
import { getConversation } from 'selectors/conversation';

const MobileConversationListScene = ({ dispatchChangeIsConversationListOpen }) => (
  <ConversationList onItemClick={dispatchChangeIsConversationListOpen} />
);

MobileConversationListScene.propTypes = {
  selectedConversation: conversationPropType,
  dispatchChangeIsConversationListOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedConversation: getConversation(state).data,
});

const mapDispatchToProps = {
  dispatchChangeIsConversationListOpen: changeIsConversationListOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileConversationListScene);
