import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HtmlElement from 'ready/components/HtmlElement';
import { sizes } from 'ready/utils/constants';
import { variationPropType } from 'ready/utils/propTypes';
import { variations } from 'ready/utils/constants';
import { sizePropType } from 'ready/utils/propTypes';

const { XL, L, M, S, XS } = sizes;

const StyledText = styled(HtmlElement)`
  color: ${props => props.theme.colors[props.variation]};
  margin: 0;
  ${props => ({ ...props.theme.typography.text[props.size] })};
  font-weight: ${props => props.theme.typography.fontWeight[props.bold ? 'medium' : 'normal']};
`;

const Text = ({ blacklist, ...props }) => (
  <StyledText {...props} blacklist={{ ...blacklist, bold: true, size: true, variation: true }} />
);

Text.sizes = { XL, L, M, S, XS };
Text.variations = variations;

Text.propTypes = {
  variation: variationPropType,
  size: sizePropType(Text.sizes),
  className: PropTypes.string,
  element: PropTypes.oneOf(['span', 'p']),
  bold: PropTypes.bool,
  blacklist: PropTypes.object,
};

Text.defaultProps = {
  variation: variations.DARK,
  element: 'span',
  size: Text.sizes.M,
  className: '',
  children: null,
  bold: false,
  blacklist: {},
};

export default Text;
