import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sizePropType } from 'ready/utils/propTypes';
import { sizes } from 'ready/utils/constants';
import Icon from 'ready/components/Icon/Icon';
import { inputCss } from '../Input';

const { S, M } = sizes;

const withInputCss = ({ clean }) => !clean && inputCss;

export const InputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${props => props.theme.spacing.xxxs};
  justify-content: start;
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.s}`};
  ${withInputCss};
`;

const StyledInput = styled.input`
  background: none;
  border: none;
  width: 100%;
  outline: none;
  font-size: ${props => props.theme.typography.text[props.size]['font-size']};
`;

const SearchInput = ({ elementRef, size, className, clean, ...props }) => (
  <InputWrapper className={className} clean={clean}>
    <StyledInput ref={elementRef} size={size} {...props} />
    <Icon size={size} icon={Icon.icons.MAGNIFIER} />
  </InputWrapper>
);

SearchInput.sizes = { S, M };

SearchInput.propTypes = {
  size: sizePropType(SearchInput.sizes),
  className: PropTypes.string,
  elementRef: PropTypes.object,
  clean: PropTypes.bool,
};

SearchInput.defaultProps = {
  size: SearchInput.sizes.M,
};

export default SearchInput;
