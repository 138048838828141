import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { OverlayBackground } from 'components';
import { Modal, Button } from 'ready/components';
import { CreatableSelect } from 'ready/components/Input/Select/Select';
import { StyledRequestError, SmallStyledRequestError, ResponsiveModal, Footer, ActionsWrapper } from '.';

export const Container = styled.div`
  padding: ${props => props.theme.spacing.s};
`;

const Content = ({ filterPresets, t, onChange, value, onClose, onSave }) => {
  if (filterPresets.didInvalidate && filterPresets.errors.fetching)
    return <StyledRequestError errors={filterPresets.errors} />;

  return (
    <>
      <Container data-qa="SaveFilterPreset:__container">
        {filterPresets.didInvalidate && filterPresets.errors.updating && (
          <SmallStyledRequestError errors={filterPresets.errors} />
        )}
        <CreatableSelect
          closeMenuOnSelect
          isClearable
          placeholder={t.newPresetPlaceholder}
          options={filterPresets.data.map(preset => ({ value: preset.id, label: preset.name }))}
          value={[value]}
          onChange={onChange}
          styles={{ container: { width: '100%' }, control: { display: 'inline-flex', width: '100%' } }}
          t={t}
        />
      </Container>
      <Footer>
        <ActionsWrapper>
          <Button
            size={Button.sizes.S}
            variation={Button.variations.OUTLINE}
            onClick={onClose}
            data-qa="SaveFilterPreset:Button--clear"
          >
            {t.cancel}
          </Button>
          <Button onClick={onSave} size={Button.sizes.S} data-qa="SaveFilterPreset:Button--save">
            {t.save}
          </Button>
        </ActionsWrapper>
      </Footer>
    </>
  );
};

Content.propTypes = {
  filterPresets: PropTypes.shape({
    data: PropTypes.array,
    dataOptions: PropTypes.array,
  }).isRequired,
  t: PropTypes.shape({
    cancel: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    newPresetPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    __isNew__: PropTypes.bool,
  }),
};

const SaveFilterPreset = ({ onSave, onClose, t, filterPresets }) => {
  const [value, setValue] = useState();

  return (
    <OverlayBackground>
      <ResponsiveModal
        data-qa="SaveFilterPreset:Modal"
        size={Modal.sizes.M}
        closeEnabled
        heading={t.savePreset}
        onClose={onClose}
      >
        <Content
          t={t}
          value={value}
          onClose={onClose}
          onSave={() => onSave(value)}
          onChange={val => setValue(val)}
          filterPresets={filterPresets}
        />
      </ResponsiveModal>
    </OverlayBackground>
  );
};

SaveFilterPreset.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filterPresets: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  t: PropTypes.shape({
    savePreset: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    newPresetPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
};

export default SaveFilterPreset;
