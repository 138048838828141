import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import '../../../../../../../node_modules/flag-icons/css/flag-icons.min.css';

import { Tag, Text } from 'ready/components';

const Container = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-rows: repeat(3, minmax(2.4rem, auto));
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
`;

const CallInfo = ({ category, name, phone, country_code, country_name, language }) => (
  <Container>
    {category && (
      <Tag size={Tag.sizes.XS} variation={Tag.variations.WARNING}>
        {category}
      </Tag>
    )}
    {name && (
      <Text size={Text.sizes.L} bold element="span">
        {name}
      </Text>
    )}
    <Text size={Text.sizes.L} element="span">
      {phone}
    </Text>
    <Container>
      {country_code && (
        <Text size={Text.sizes.S} element="span">
          <FormattedMessage id="CALL_INFO.COUNTRY" values={{ country_name }} />{' '}
          <span className={`fi fi-${country_code.toLowerCase()}`} />
        </Text>
      )}
      {language && (
        <Text size={Text.sizes.S} element="span">
          <FormattedMessage id="CALL_INFO.LANGUAGE" values={{ language }} />
        </Text>
      )}
    </Container>
  </Container>
);

CallInfo.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string.isRequired,
  country_code: PropTypes.string,
  country_name: PropTypes.string,
  language: PropTypes.string,
};

export default CallInfo;
