import React from 'react';
import styled from 'styled-components';

import Conversation from 'scenes/shared/Conversation/Conversation';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: 'sub-top-bar' 'conversation';
  grid-area: content;
`;

const SubTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const MobileConversationScene = () => {
  return (
    <Wrapper>
      <SubTopBar />
      <Conversation />
    </Wrapper>
  );
};

export default MobileConversationScene;
