import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { codeCrashed } from 'ducks/view';
import rootReducer from 'ducks';
import rootSaga from 'sagas';

const initialState = {};
const enhancers = [];
const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    store.dispatch(codeCrashed());
    // eslint-disable-next-line no-console
    console.error(error, sagaStack);
    window.onerror('SagaError: ', error, sagaStack);
  },
});

const middleware = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }

  middleware.push(createLogger());
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(rootReducer(), initialState, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
