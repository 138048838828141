import React from 'react';
import PropTypes from 'prop-types';

import { SIZES, VARIATIONS } from '../constants';
import { childrenPropType } from 'ready/utils/propTypes';

function withMappedProps(EnhancedComponent) {
  function mapProps({ size, variation, ...props }) {
    const _variation = VARIATIONS[variation];
    const _size = SIZES[size];
    return <EnhancedComponent {...props} _size={_size} _variation={_variation} />;
  }

  mapProps.propTypes = {
    children: childrenPropType,
    size: PropTypes.string,
    variation: PropTypes.string,
  };

  return mapProps;
}

export default withMappedProps;
