import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedRelative } from 'react-intl';

import { updateConversationRequested } from 'ducks/conversation';
import { updateTaskRouterStatusRequested, setupCallCenterRequested } from 'ducks/callCenter';
import { updateGuestRequested } from 'ducks/guest';
import { getConversationData } from 'selectors/conversation';
import { getCallCenter } from 'selectors/callCenter';
import { getGuest } from 'selectors/guest';
import { getUserData } from 'selectors/user';

import CallCenter from './containers/CallCenter/CallCenter';
import CallCenterButton from './components/CallCenterButton';
import CallCenterPauseButton from './components/CallCenterPauseButton';
import SpecialOffer from './containers/SpecialOffer';
import AssignToMe from './containers/AssignToMe';
import Attachments from './containers/Attachments';
import { Button, DropdownButton, DropdownList, DropdownListItem } from 'ready/components';

import { conversationStatusPayload, guestLanguagePayload, conversationSnoozePayload } from 'utils/deserializers';
import { conversationPropType, baseGuestPropTypeWithState, userPropType, callCenterPropType } from 'common/propTypes';
import { snoozeDurations, conversationStatuses, languageSwitcherOptions } from 'constants.js';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { fetchMessagesRequested } from 'ducks/messages';
import { featureFlags } from 'constants.js';
import RefreshMsgsButton from 'components/RefreshMsgsButton/RefreshMsgsButton';
import FlagConversationButton from 'components/FlagConversation/FlagConversationButton';

const grid = css`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spacing.s};
`;

const Wrapper = styled.div`
  grid-area: top-bar-center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.xs};
  width: 100%;
  ${grid};
`;

const RightButtonsWrapper = styled.div`
  justify-content: flex-end;
  ${grid};
`;

const LeftButtonsWrapper = styled.div`
  justify-content: flex-start;
  ${grid};
`;

const getSnoozedDurations = () => snoozeDurations.map(duration => moment().add(duration, 'minutes'));

const TopBarCenter = ({
  className,
  dispatchUpdateConversation,
  dispatchUpdateGuestRequested,
  conversation,
  guest,
  dispatchUpdateTaskRouterStatusRequested,
  dispatchFetchMessagesRequested,
  dispatchSetupCallCenterRequested,
  callCenter: { taskRouter, device },
  user,
  t,
}) => (
  <Wrapper>
    <LeftButtonsWrapper>
      <CallCenter />
      <CallCenterButton
        device={device}
        taskRouter={taskRouter}
        user={user}
        dispatchSetupCallCenterRequested={dispatchSetupCallCenterRequested}
        dispatchUpdateTaskRouterStatusRequested={dispatchUpdateTaskRouterStatusRequested}
        t={t}
      />
      <CallCenterPauseButton
        device={device}
        taskRouter={taskRouter}
        user={user}
        dispatchUpdateTaskRouterStatusRequested={dispatchUpdateTaskRouterStatusRequested}
        t={t}
      />
    </LeftButtonsWrapper>
    <RightButtonsWrapper className={className} data-qa="shared:TopBarCenter__wrapper">
      {conversation &&
        conversation.status === conversationStatuses.OPENED &&
        (user.feature_flags || []).includes(featureFlags.ASSIGNMENT_IN_MAILBOX) && <AssignToMe />}
      {conversation && <FlagConversationButton />}
      <RefreshMsgsButton
        conversation={conversation}
        dispatchFetchMessagesRequested={dispatchFetchMessagesRequested}
        text={t.hintRefreshMessages}
      />
      <SpecialOffer />
      <DropdownButton
        disabled={!conversation}
        horizontalPosition={DropdownButton.horizontalPositions.LEFT}
        clean
        size={DropdownButton.sizes.L}
        icon={{ icon: DropdownButton.icon.icons.BELL_AND_CLOCK }}
        variation={DropdownButton.variations.OUTLINE}
        hint={{ text: t.hintSnooze }}
        data-qa="TopBarCenter:DropdownButton--snooze"
        renderDropdownContent={({ closeDropdown }) => (
          <DropdownList data-qa="TopBarCenter:DropdownList--snooze">
            {getSnoozedDurations().map(duration => (
              <DropdownListItem
                data-qa="TopBarCenter:DropdownListItem--snooze"
                key={duration}
                onClick={() => {
                  dispatchUpdateConversation(conversationSnoozePayload(duration));
                  closeDropdown();
                }}
              >
                <FormattedRelative value={duration} updateIntervalInSeconds={false} />
              </DropdownListItem>
            ))}
          </DropdownList>
        )}
      />
      <Button
        disabled={!conversation || conversation.status !== conversationStatuses.OPENED}
        size={Button.sizes.L}
        icon={{ icon: Button.icon.icons.MESSAGE_CROSS }}
        onClick={() => dispatchUpdateConversation(conversationStatusPayload(conversationStatuses.CLOSED))}
        hint={{ text: t.hintCloseConversation }}
        clean
        data-qa="TopBarCenter:Button--close"
      />
      <Button
        disabled={!conversation || conversation.status !== conversationStatuses.CLOSED}
        size={Button.sizes.L}
        icon={{ icon: Button.icon.icons.MESSAGE_CHECKED }}
        onClick={() => dispatchUpdateConversation(conversationStatusPayload(conversationStatuses.OPENED))}
        hint={{ text: t.hintOpenConversation }}
        clean
        data-qa="TopBarCenter:Button--open"
      />
      {conversation && <Attachments />}
      <DropdownButton
        data-qa="TopBarCenter:Dropdown--languageSwitcher"
        horizontalPosition={DropdownButton.horizontalPositions.LEFT}
        clean
        disabled={!conversation || (guest.data && !guest.data.id) || guest.isUpdating}
        size={DropdownButton.sizes.L}
        icon={{ icon: DropdownButton.icon.icons.ARROW_DOWN, position: DropdownButton.icon.positions.RIGHT }}
        variation={DropdownButton.variations.OUTLINE}
        hint={{ text: t.hintChangeLanguage }}
        renderButtonContent={() => guest.data.language.toUpperCase()}
        renderDropdownContent={({ closeDropdown }) => (
          <DropdownList>
            {languageSwitcherOptions.map(({ label, value }) => {
              const selected = value === guest.data.language;
              return (
                <DropdownListItem
                  key={value}
                  onClick={() => {
                    dispatchUpdateGuestRequested(guest.data.id, guestLanguagePayload(value));
                    closeDropdown();
                  }}
                  selected={selected}
                >
                  {label}
                </DropdownListItem>
              );
            })}
          </DropdownList>
        )}
      />
    </RightButtonsWrapper>
  </Wrapper>
);

TopBarCenter.propTypes = {
  className: PropTypes.string,
  dispatchUpdateConversation: PropTypes.func.isRequired,
  dispatchUpdateGuestRequested: PropTypes.func.isRequired,
  conversation: conversationPropType,
  guest: baseGuestPropTypeWithState.isRequired,
  dispatchUpdateTaskRouterStatusRequested: PropTypes.func.isRequired,
  dispatchFetchMessagesRequested: PropTypes.func.isRequired,
  dispatchSetupCallCenterRequested: PropTypes.func.isRequired,
  user: userPropType.isRequired,
  callCenter: callCenterPropType,
  t: PropTypes.shape({
    callCenterOn: PropTypes.string.isRequired,
    callCenterOff: PropTypes.string.isRequired,
    callCenterDisabled: PropTypes.string.isRequired,
    callCenterStart: PropTypes.string.isRequired,
    callCenterPause: PropTypes.string.isRequired,
    callCenterUnpause: PropTypes.string.isRequired,
    callCenterTurnOff: PropTypes.string.isRequired,
    hintChangeLanguage: PropTypes.string.isRequired,
    hintCloseConversation: PropTypes.string.isRequired,
    hintOpenConversation: PropTypes.string.isRequired,
    hintSnooze: PropTypes.string.isRequired,
    hintCallCenterDisabled: PropTypes.string.isRequired,
    hintCallCenterNotStarted: PropTypes.string.isRequired,
    hintCallCenterPaused: PropTypes.string.isRequired,
    hintPauseCallCenter: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => ({
  user: getUserData(state),
  conversation: getConversationData(state),
  callCenter: getCallCenter(state),
  guest: getGuest(state),
});

const mapDispatchToProps = {
  dispatchUpdateConversation: updateConversationRequested,
  dispatchUpdateGuestRequested: updateGuestRequested,
  dispatchUpdateTaskRouterStatusRequested: updateTaskRouterStatusRequested,
  dispatchFetchMessagesRequested: fetchMessagesRequested,
  dispatchSetupCallCenterRequested: setupCallCenterRequested,
};

export default compose(
  withMappedTranslations({
    callCenterOn: 'TOP_BAR.CALL_CENTER_ON',
    callCenterOff: 'TOP_BAR.CALL_CENTER_OFF',
    callCenterDisabled: 'TOP_BAR.CALL_CENTER_DISABLED',
    callCenterTurnOff: 'TOP_BAR.TURN_OFF_CALL_CENTER',
    callCenterStart: 'TOP_BAR.START_CALL_CENTER',
    callCenterPause: 'TOP_BAR.PAUSE_CALL_CENTER',
    callCenterUnpause: 'TOP_BAR.UNPAUSE_CALL_CENTER',
    hintChangeLanguage: 'TOP_BAR.HINT.CHANGE_LANGUAGE',
    hintCloseConversation: 'TOP_BAR.HINT.CLOSE_CONVERSATION',
    hintOpenConversation: 'TOP_BAR.HINT.OPEN_CONVERSATION',
    hintSnooze: 'TOP_BAR.HINT.SNOOZE',
    hintCallCenterDisabled: 'TOP_BAR.HINT.CALL_CENTER_DISABLED',
    hintCallCenterNotStarted: 'TOP_BAR.HINT.CALL_CENTER_NOT_STARTED',
    hintCallCenterPaused: 'TOP_BAR.HINT.CALL_CENTER_PAUSED',
    hintPauseCallCenter: 'TOP_BAR.HINT.PAUSE_CALL_CENTER',
    hintRefreshMessages: 'TOP_BAR.HINT.REFRESH_MESSAGES',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TopBarCenter);
