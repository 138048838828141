import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text } from 'ready/components/typography';
import { variations, sizes } from 'ready/utils/constants';
import { getWeekDays, getWeeksOfMonth } from 'ready/utils';
import { sizePropType } from 'ready/utils/propTypes';
import DayRangeCell from './DayRangeCell';

const { S, M, L } = sizes;

const Container = styled.div`
  display: grid;
`;

const Row = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  justify-items: center;
  width: 100%;
`;

const getDayRangeIndex = (day, dateRanges, currentIndex) => {
  if (!dateRanges.length) return 0;
  if (currentIndex >= dateRanges.length) return dateRanges.length - 1;
  if (moment.utc(day).isAfter(moment.utc(dateRanges[currentIndex].to)))
    return getDayRangeIndex(day, dateRanges, currentIndex + 1);
  return currentIndex;
};

const CalendarDays = ({ date, dateRanges, size, dateRangeVariations }) => {
  const weekDays = getWeekDays(date);
  const weeksOfMonth = getWeeksOfMonth(date);

  let index = 0;
  return (
    <Container>
      <Row>
        {weekDays.map(day => (
          <Text key={`names-${day.format('ddd')}`} size={size} bold variation={variations.GREY}>
            {day.format('ddd')}
          </Text>
        ))}
      </Row>
      {weeksOfMonth.map(week => (
        <Row key={week[0].format('DD/MM/YY')}>
          {week.map(day => {
            index = getDayRangeIndex(day, dateRanges, index);
            return (
              <DayRangeCell
                key={day.format('DD/MM/YYYY')}
                size={size}
                date={date}
                day={day}
                dateRanges={dateRanges.slice(index, index + 2)}
                dateRangeVariations={dateRangeVariations}
              />
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

CalendarDays.sizes = { S, M, L };

CalendarDays.propTypes = {
  date: PropTypes.object.isRequired,
  dateRanges: PropTypes.array,
  size: sizePropType(CalendarDays.sizes),
  dateRangeVariations: PropTypes.object,
};

CalendarDays.defaultProps = {
  size: M,
};

export default CalendarDays;
