import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from 'ready/components/typography';
import { Toggle } from 'ready/components';
import CollapsibleWithState from '../../../components/CollapsibleWithState';
import { conversationAutomaticAIEnabledPayload } from 'utils/deserializers';
import { conversationPropTypeWithState } from 'common/propTypes';

const ContentWrapper = styled.div`
  padding: ${props => `0 ${props.theme.spacing.l}`};
`;

const StyledText = styled(Text)`
  margin-bottom: ${props => props.theme.spacing.s};
  display: block;
`;

const AutoReply = ({ conversation, dispatchUpdateConversationRequested, t }) => {
  const { isFetching, didInvalidate, data, errors } = conversation;

  const enabled = data && data.automatic_ai_enabled;

  return (
    <CollapsibleWithState
      title={t.autoReplyTitle}
      isFetching={isFetching}
      didInvalidate={didInvalidate}
      errors={errors}
      data-qa="Tab:Collapsible--autoReply"
    >
      <ContentWrapper>
        <StyledText size={Text.sizes.S} bold>
          {t.autoReplySettingIsRelated}
        </StyledText>
        <Toggle
          data-pa="AutomaticAIReplyToggle"
          label={enabled ? t.enableAutomaticAIReply : t.disableAutomaticAIReply}
          onClick={() =>
            dispatchUpdateConversationRequested(conversationAutomaticAIEnabledPayload(!enabled), {
              removeConversationFromList: false,
            })
          }
          enabled={enabled}
          position={Toggle.positions.LEFT}
          data-qa="AutoReply:Toggle"
        />
      </ContentWrapper>
    </CollapsibleWithState>
  );
};

AutoReply.propTypes = {
  t: PropTypes.shape({
    autoReviewTitle: PropTypes.string.isRequired,
    guestAutoReview: PropTypes.string.isRequired,
    cancelAutoReview: PropTypes.string.isRequired,
    enableAutomaticAIReply: PropTypes.string.isRequired,
    disableAutomaticAIReply: PropTypes.string.isRequired,
  }).isRequired,
  conversation: conversationPropTypeWithState.isRequired,
  dispatchUpdateConversationRequested: PropTypes.func,
};

export default AutoReply;
