import { fromJS } from 'immutable';
import { appendString } from 'utils/index';

export const SEND_MESSAGE_REQUESTED = 'SEND_MESSAGE_REQUESTED';
export const sendMessageRequested = ({ close } = {}) => ({
  type: SEND_MESSAGE_REQUESTED,
  payload: { close },
});

export const SETUP_MESSAGE = 'SETUP_MESSAGE';
export const setupMessage = ({ channels, conversationId }) => ({
  type: SETUP_MESSAGE,
  payload: { channels, conversationId },
});

export const SEND_MESSAGE_SUCCESSED = 'SEND_MESSAGE_SUCCESSED';
export const sendMessageSuccessed = message => ({
  type: SEND_MESSAGE_SUCCESSED,
  payload: { message },
});

export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export const sendMessageFailed = errors => ({
  type: SEND_MESSAGE_FAILED,
  payload: { errors },
});

export const APPEND_ATTACHMENTS = 'APPEND_ATTACHMENTS';
export const appendAttachments = attachments => ({ type: APPEND_ATTACHMENTS, payload: { attachments } });

export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';
export const clearAttachments = () => ({ type: CLEAR_ATTACHMENTS });

export const CHANGE_MESSAGE_CONTENT = 'CHANGE_MESSAGE_CONTENT';
export const changeMessageContent = message => ({ type: CHANGE_MESSAGE_CONTENT, payload: { message } });

export const APPEND_MESSAGE_CONTENT = 'APPEND_MESSAGE_CONTENT';
export const appendMessageContent = message => ({ type: APPEND_MESSAGE_CONTENT, payload: { message } });

export const CHANGE_MESSAGE_CURSOR_POSITION = 'CHANGE_MESSAGE_CURSOR_POSITION';
export const changeMessageCursorPosition = position => ({
  type: CHANGE_MESSAGE_CURSOR_POSITION,
  payload: { position },
});

export const CHANGE_MESSAGE_CHANNEL = 'CHANGE_MESSAGE_CHANNEL';
export const changeMessageChannel = channel => ({
  type: CHANGE_MESSAGE_CHANNEL,
  payload: { channel },
});

export const CLEAR_MESSAGE_CONTENT = 'CLEAR_MESSAGE_CONTENT';
export const clearMessageContent = () => ({ type: CLEAR_MESSAGE_CONTENT });

export const FETCH_SUGGESTED_REPLY_REQUESTED = 'FETCH_SUGGESTED_REPLY_REQUESTED';
export const fetchSuggestedReplyRequested = (conversationId, message) => ({
  type: FETCH_SUGGESTED_REPLY_REQUESTED,
  payload: { conversationId, message },
});

export const FETCH_SUGGESTED_REPLY_SUCCESSED = 'FETCH_SUGGESTED_REPLY_SUCCESSED';
export const fetchSuggestedReplySuccessed = suggestedReply => ({
  type: FETCH_SUGGESTED_REPLY_SUCCESSED,
  payload: { suggestedReply },
});

export const FETCH_SUGGESTED_REPLY_FAILED = 'FETCH_SUGGESTED_REPLY_FAILED';
export const fetchSuggestedReplyFailed = errors => ({
  type: FETCH_SUGGESTED_REPLY_FAILED,
  payload: { errors },
});

export const initialState = fromJS({
  isSending: false,
  didInvalidate: false,
  suggestedReply: null,
  errors: {},
  data: {
    attachments: [],
    content: '',
    channel: null,
    conversation: null,
  },
  config: {
    cursorPosition: 0,
    channels: [],
  },
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SETUP_MESSAGE:
      return state
        .setIn(['data', 'channel'], payload.channels[0])
        .setIn(['data', 'conversation'], payload.conversationId)
        .setIn(['config', 'channels'], fromJS(payload.channels));

    case APPEND_MESSAGE_CONTENT:
      return state.updateIn(['data', 'content'], value =>
        appendString(value, payload.message, state.getIn(['config', 'cursorPosition'])),
      );

    case CHANGE_MESSAGE_CURSOR_POSITION:
      return state.setIn(['config', 'cursorPosition'], payload.position);

    case CHANGE_MESSAGE_CONTENT:
      return state.setIn(['data', 'content'], payload.message);

    case CHANGE_MESSAGE_CHANNEL:
      return state.setIn(['data', 'channel'], payload.channel);

    case CLEAR_MESSAGE_CONTENT:
      return state.setIn(['data', 'content'], '');

    case SEND_MESSAGE_REQUESTED:
      return state
        .set('isSending', true)
        .set('didInvalidate', false)
        .set('errors', fromJS({}));

    case SEND_MESSAGE_SUCCESSED:
      return state.set('isSending', false);

    case APPEND_ATTACHMENTS:
      return state.setIn(['data', 'attachments'], payload.attachments);

    case CLEAR_ATTACHMENTS:
      return state.setIn(['data', 'attachments'], []);

    case SEND_MESSAGE_FAILED:
      return state
        .set('isSending', false)
        .set('didInvalidate', true)
        .set('errors', fromJS(payload.errors));

    case FETCH_SUGGESTED_REPLY_REQUESTED:
      return state.set('didInvalidate', false).set('errors', fromJS({}));
    case FETCH_SUGGESTED_REPLY_SUCCESSED:
      return state.set('suggestedReply', payload.suggestedReply);
    case FETCH_SUGGESTED_REPLY_FAILED:
      return state.set('didInvalidate', true).set('errors', payload.errors);

    default:
      return state;
  }
};
