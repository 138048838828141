import React from 'react';
import styled from 'styled-components';
import { TextUppercase } from 'ready/components/typography';

import { childrenPropType } from 'common/propTypes';

const FilterSubHeadingWrapper = styled.div`
  padding-top: ${props => props.theme.spacing.m};
  padding-bottom: ${props => props.theme.spacing.xs};
`;

const FilterSubHeading = styled(TextUppercase)`
  color: ${props => props.theme.colors.n500};
  font-weight: 500;
  letter-spacing: 0.03rem;
`;

const FilterSectionHeading = ({ children }) => (
  <FilterSubHeadingWrapper>
    <FilterSubHeading size={TextUppercase.sizes.S}>{children}</FilterSubHeading>
  </FilterSubHeadingWrapper>
);

FilterSectionHeading.propTypes = {
  children: childrenPropType,
};

export default FilterSectionHeading;
