import { createSelector } from 'reselect';

export const _getMessage = state => state.message;
export const getMessage = createSelector(
  [_getMessage],
  message => message.toJS(),
);
export const getMessageData = state => state.message.get('data').toJS();
export const getMessageContent = state => state.message.getIn(['data', 'content']);
export const getAttachments = state => state.message.getIn(['data', 'attachments']);

export const _getSuggestedReply = state => state.message.get('suggestedReply');
export const getSuggestedReply = createSelector(
  [_getSuggestedReply],
  suggestedReply => suggestedReply || null,
);
