import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

import { createDeepEqualSelector } from 'selectors/helpers';

export const withFallback = (intl, id, descriptor = {}) => {
  const values =
    descriptor.values &&
    Map(descriptor.values)
      .filter(value => value)
      .toJS();

  const msg = id && intl.formatMessage({ id: id, ...descriptor }, values);
  return (!intl.messages[id] || !msg || msg.match(/\{*.\}/)) &&
    [null, undefined].indexOf(descriptor.defaultMessage) === -1
    ? descriptor.defaultMessage
    : msg;
};

export const withMappedTranslations = ids =>
  compose(
    injectIntl,
    withProps(props => ({
      t: Map(ids).reduce((translations, tid, key) => {
        translations[key] = props.intl.formatMessage({ id: tid });
        return translations;
      }, {}),
    })),
  );

const memoTranslations = createDeepEqualSelector([params => params], ({ ids, intl }) => {
  return Map(ids).reduce((translations, tid, key) => {
    translations[key] = intl.formatMessage({ id: tid });
    return translations;
  }, {});
});
export const withMemoMappedTranslations = ids => {
  return Component => {
    const EnhancedComponent = ({ intl, ...props }) => {
      const t = memoTranslations({ ids, intl });

      return <Component t={t} intl={intl} {...props} />;
    };

    EnhancedComponent.propTypes = {
      intl: PropTypes.object,
    };

    return injectIntl(EnhancedComponent);
  };
};

export default compose(
  injectIntl,
  withProps(props => ({
    t: withFallback.bind(this, props.intl),
  })),
);
