import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Notification from 'ready/components/Notification/Notification';

export const StyledNotification = styled(Notification)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.s};
`;

const GuestVerifiedNotification = ({ verified, t }) => {
  if (typeof verified !== 'boolean') return false;
  const variation = verified ? Notification.variations.SUCCESS : Notification.variations.ERROR;
  const text = verified ? t.verified : t.notVerified;
  return <StyledNotification variation={variation}>{text}</StyledNotification>;
};

GuestVerifiedNotification.propTypes = {
  verified: PropTypes.bool,
  t: PropTypes.shape({
    verified: PropTypes.string.isRequired,
    notVerified: PropTypes.string.isRequired,
  }).isRequired,
};

export default GuestVerifiedNotification;
