import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Icon, Button, Text } from 'ready/components';
import { getVariationOf } from 'ready/styles/helpers';
import { sizePropType } from 'ready/utils/propTypes';
import { sizes, variationsCssProps } from 'ready/utils/constants';

const { XL, L, M, S } = sizes;

const actionButtonSizes = {
  [L]: css`
    height: 5.6rem;
    width: 5.6rem;
  `,
  [M]: css`
    height: 4rem;
    width: 4rem;
  `,
  [S]: css`
    height: 3.6rem;
    width: 3.6rem;
  `,
};

const containerSizes = {
  [L]: css`
    width: 5.6rem;
  `,
  [M]: css`
    width: 4rem;
  `,
  [S]: css`
    width: 3.6rem;
  `,
};

const iconSizes = { [L]: XL, [M]: M, [S]: M };

const withButtonSizes = ({ size }) => actionButtonSizes[size];
const withContainerSizes = ({ size }) => containerSizes[size];

const withGapStyles = ({ withGap }) =>
  withGap &&
  css`
    grid-row-gap: ${props => props.theme.spacing.xs};
    grid-template-rows: repeat(2, auto);
    text-align: center;
  `;
const Container = styled.div`
  display: grid;
  ${withContainerSizes};
  ${withGapStyles};
`;

const StyledActionButton = styled(Button)`
  border-radius: 50%;
  padding: 0;
  ${withButtonSizes};
`;

const withRingingStyles = ({ ringing }) =>
  ringing &&
  css`
    animation: phoneRing-alo-circle-img-anim 1s infinite ease-in-out;
    transform-origin: 50% 50%;

    @keyframes phoneRing-alo-circle-img-anim {
      0% {
        transform: rotate(0) scale(1) skew(1deg);
      }

      10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
      }

      20% {
        transform: rotate(25deg) scale(1) skew(1deg);
      }

      30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
      }

      40% {
        transform: rotate(25deg) scale(1) skew(1deg);
      }

      50% {
        transform: rotate(0) scale(1) skew(1deg);
      }

      100% {
        transform: rotate(0) scale(1) skew(1deg);
      }
    }
  `;

const StyledIcon = styled(Icon)`
  transform: ${props => `rotate(${props.rotate}deg)`};
  ${withRingingStyles}
`;

const ActionButton = ({ label, icon, variation, size, rotate, ringing, ...props }) => (
  <Container withGap={!!label} size={size}>
    <StyledActionButton variation={variation} size={size} {...props}>
      <StyledIcon
        ringing={ringing}
        rotate={rotate}
        icon={icon}
        size={iconSizes[size]}
        color={getVariationOf(variation, variationsCssProps.COLOR)}
      />
    </StyledActionButton>
    {label && <Text size={Text.sizes.S}>{label}</Text>}
  </Container>
);

ActionButton.sizes = { L, M, S };
ActionButton.variations = Button.variations;
ActionButton.icons = Icon.icons;

ActionButton.propTypes = {
  icon: PropTypes.string.isRequired,
  variation: Button.propTypes.variation,
  size: sizePropType(ActionButton.sizes),
  label: PropTypes.string,
  rotate: PropTypes.number,
  ringing: PropTypes.bool,
};

ActionButton.defaultProps = {
  size: M,
  variation: Button.variations.OUTLINE,
  rotate: 0,
};

export default ActionButton;
