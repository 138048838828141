import styled from 'styled-components';

import Text from 'ready/components/typography/Text/Text';
const TruncatedText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default TruncatedText;
