import { createSelector } from 'reselect';
import { fromJS, List, OrderedMap } from 'immutable';

export const _getCallCenter = state => state.callCenter;
export const getCallCenter = createSelector(
  [_getCallCenter],
  callCenter => callCenter.toJS(),
);
export const getCallCenterViewStatus = state => state.callCenter.getIn(['view', 'status']);
export const getCallCenterData = state => state.callCenter.get('data').toJS();
export const getCallCenterConversation = state => state.callCenter.get('data').toJS().conversation;
export const getCallCenterGuest = state => state.callCenter.get('data').toJS().guest;
export const getCallCenterOutgoingPhoneNumber = state => state.callCenter.get('data').toJS().outgoingPhoneNumber;
export const getCallCenterNote = state => state.callCenter.getIn(['data', 'note']);
export const getCallCenterConnectionStatus = state => state.callCenter.getIn(['connection', 'status']);
export const getCallCenterCallReason = state => state.callCenter.getIn(['data', 'callReason']).toJS();
export const getCallSid = state => state.callCenter.getIn(['data', 'call_sid']);
export const getTaskRouterActivitySid = (state, name) => state.callCenter.getIn(['taskRouter', 'activities', name]);
export const getTaskRouterActivityName = state => state.callCenter.getIn(['taskRouter', 'activityName']);
export const getTaskRouterOnPause = state => state.callCenter.getIn(['taskRouter', 'onPause']);
export const getTaskRouterWorkerToken = state => state.callCenter.getIn(['taskRouter', 'workerToken']);
export const getCallCenterDeviceToken = state => state.callCenter.getIn(['device', 'callToken']);
export const getCallCenterErrors = state =>
  state.callCenter
    .get('errors')
    .delete('visible')
    .toList()
    .toJS();

export const getTaskRouterWorkers = state =>
  state.callCenter
    .getIn(['callForwarding', 'workers'])
    .sortBy(worker => worker.get('status'))
    .sortBy(worker => worker.get('username'));

/**
 * Map call reasons for use with react-select and handle nesting
 * @param {callReasonsPropType} callReasons
 */
export const getCallReasons = createSelector(
  [_getCallCenter],
  callCenter =>
    callCenter
      .getIn(['config', 'callReasons'], List([]))
      .sort((a, b) => (b.get('parent') === null) - (a.get('parent') === null))
      .map(reason => fromJS({ label: reason.get('content'), value: reason }))
      .reduce((options, reason) => {
        const value = reason.get('value');
        if (value.get('parent') === null) {
          return options.set(value.get('id'), reason);
        } else {
          return options.updateIn([value.get('parent'), 'options'], valueOptions =>
            (valueOptions || List()).push(reason),
          );
        }
      }, OrderedMap())
      .toList()
      .toJS(),
);
