export const getIsFilterSidebarOpen = state => state.view.get('isFilterSidebarOpen');
export const getIsInfoSidebarOpen = state => state.view.get('isInfoSidebarOpen');
export const getIsConversationListOpen = state => state.view.get('isConversationListOpen');
export const getIsMoreFiltersOpen = state => state.view.get('isMoreFiltersOpen');
export const getUrl = state => state.view.get('url');
export const getOutgoingMessageTypes = state =>
  state.view
    .get('outgoingMessageTypes')
    .toList()
    .toJS();
