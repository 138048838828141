import { fromJS } from 'immutable';

export const FETCH_CONVERSATION_REQUESTED = 'FETCH_CONVERSATION_REQUESTED';
export const fetchConversationRequested = (id, options) => ({
  type: FETCH_CONVERSATION_REQUESTED,
  payload: { id, options },
});

export const FETCH_CONVERSATION_SUCCESSED = 'FETCH_CONVERSATION_SUCCESSED';
export const fetchConversationSuccessed = conversation => ({
  type: FETCH_CONVERSATION_SUCCESSED,
  payload: { conversation },
});

export const FETCH_CONVERSATION_FAILED = 'FETCH_CONVERSATION_FAILED';
export const fetchConversationFailed = errors => ({
  type: FETCH_CONVERSATION_FAILED,
  payload: { errors },
});

export const FETCH_INIT_CONVERSATION_FAILED = 'FETCH_INIT_CONVERSATION_FAILED';
export const fetchInitConversationFailed = errors => ({
  type: FETCH_INIT_CONVERSATION_FAILED,
  payload: { errors },
});

export const FETCH_INIT_CONVERSATION_REQUESTED = 'FETCH_INIT_CONVERSATION_REQUESTED';
export const fetchInitConversationRequested = id => ({
  type: FETCH_INIT_CONVERSATION_REQUESTED,
  payload: { id },
});

export const FETCH_INIT_CONVERSATION_SUCCESSED = 'FETCH_INIT_CONVERSATION_SUCCESSED';
export const fetchInitConversationSuccessed = conversation => ({
  type: FETCH_INIT_CONVERSATION_SUCCESSED,
  payload: { conversation },
});

export const UPDATE_CONVERSATION_REQUESTED = 'UPDATE_CONVERSATION_REQUESTED';
export const updateConversationRequested = (data, options = {}) => ({
  type: UPDATE_CONVERSATION_REQUESTED,
  payload: { data, options },
});

export const UPDATE_CONVERSATION_SUCCESSED = 'UPDATE_CONVERSATION_SUCCESSED';
export const updateConversationSuccessed = (conversation, options = {}) => {
  const DEFAULT_OPTIONS = { removeConversationFromList: true };

  return {
    type: UPDATE_CONVERSATION_SUCCESSED,
    payload: { conversation, options: { ...DEFAULT_OPTIONS, ...options } },
  };
};

export const UPDATE_CONVERSATION_FAILED = 'UPDATE_CONVERSATION_FAILED';
export const updateConversationFailed = errors => ({
  type: UPDATE_CONVERSATION_FAILED,
  payload: { errors },
});

export const initialState = fromJS({
  isUpdating: false,
  isFetching: false,
  didInvalidate: false,
  data: null,
  initConversation: null,
  errors: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONVERSATION_REQUESTED:
      return initialState.set('isFetching', true);

    case FETCH_CONVERSATION_SUCCESSED:
      return initialState.set('data', fromJSOrNull(payload.conversation));

    case FETCH_CONVERSATION_FAILED:
      return initialState.set('didInvalidate', true).set('errors', fromJS(payload.errors));

    case FETCH_INIT_CONVERSATION_REQUESTED:
      return initialState.set('isFetching', true);

    case FETCH_INIT_CONVERSATION_SUCCESSED:
      return initialState
        .set('initConversation', fromJSOrNull(payload.conversation))
        .set('data', fromJSOrNull(payload.conversation));

    case FETCH_INIT_CONVERSATION_FAILED:
      return initialState.set('didInvalidate', true).set('errors', fromJS(payload.errors));

    case UPDATE_CONVERSATION_REQUESTED:
      return state.set('isUpdating', true).set('didInvalidate', false);

    case UPDATE_CONVERSATION_SUCCESSED:
      return state.set('isUpdating', false).set('data', fromJS(payload.conversation));

    case UPDATE_CONVERSATION_FAILED:
      return state.set('isUpdating', false).set('didInvalidate', true);

    default:
      return state;
  }
};

const fromJSOrNull = value => (value && fromJS(value)) || null;
