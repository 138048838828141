import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DropdownButton, DropdownList, DropdownListItem } from 'ready/components';
import { updateConversationRequested } from 'ducks/conversation';
import { conversationFlagPayload } from 'utils/deserializers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FlagIcon, { formatClassName } from './FlagIcon';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { getConversationData } from 'selectors/conversation';
import useMobileView from 'hooks/useMobileView';

const FlagDropdownTitle = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
  margin: 0px;
`;

const FlagDropdownSubtitle = styled.h4`
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.dark};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FlagDropdownList = styled(DropdownList)`
  padding: 20px;
  width: 400px;
  border-radius: 0.4rempx;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const FlagConversationListItem = styled(DropdownListItem)`
  border: 1px solid ${props => props.theme.colors.b100_v2};
  border-radius: 0.4rem;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  &:active {
    border-color: ${props => props.theme.colors.black};
  }
`;

const FlagConversationButton = ({ t, conversation, dispatchUpdateConversation }) => {
  const currentFlag = conversation && conversation.flag && formatClassName(conversation.flag.class_name);
  const enabledFlags = conversation && conversation.rentals[0].office.enabled_conversation_flags;
  const isMobileView = useMobileView();

  if (enabledFlags && enabledFlags.length === 0) return null;

  const DropdownComponent = isMobileView ? DropdownList : FlagDropdownList;
  const ListItemComponent = isMobileView ? DropdownListItem : FlagConversationListItem;

  return (
    <DropdownButton
      disabled={!conversation || enabledFlags.length === 0}
      clean
      size={DropdownButton.sizes.S}
      variation={DropdownButton.variations.OUTLINE}
      renderButtonContent={() => <FlagIcon color={currentFlag} />}
      renderDropdownContent={({ closeDropdown }) => (
        <DropdownComponent>
          {!isMobileView && (
            <>
              <FlagDropdownTitle>{t.title}</FlagDropdownTitle>
              <FlagDropdownSubtitle>{t.helpText}</FlagDropdownSubtitle>
            </>
          )}

          <ListItemComponent
            onClick={() => {
              dispatchUpdateConversation(conversationFlagPayload(null), { removeConversationFromList: false });
              closeDropdown();
            }}
          >
            <FlagIcon /> {t.noFlag}
          </ListItemComponent>

          {conversation &&
            enabledFlags.map((color, _index) => (
              <ListItemComponent
                key={color.id}
                onClick={() => {
                  dispatchUpdateConversation(conversationFlagPayload(color.id), { removeConversationFromList: false });
                  closeDropdown();
                }}
              >
                <FlagIcon color={formatClassName(color.class_name)} />{' '}
                <FormattedMessage id="CONVERSATION.FLAG_CONVERSATION.FLAG" values={{ color: color.name }} />
              </ListItemComponent>
            ))}
        </DropdownComponent>
      )}
    />
  );
};

const mapStateToProps = state => ({
  conversation: getConversationData(state),
});

const mapDispatchToProps = {
  dispatchUpdateConversation: updateConversationRequested,
};

export default compose(
  withMappedTranslations({
    title: 'CONVERSATION.FLAG_CONVERSATION.TITLE',
    helpText: 'CONVERSATION.FLAG_CONVERSATION.HELP_TEXT',
    noFlag: 'CONVERSATION.FLAG_CONVERSATION.NO_FLAG',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FlagConversationButton);

FlagConversationButton.propTypes = {
  t: PropTypes.shape({
    title: PropTypes.string.isRequired,
    helpText: PropTypes.string.isRequired,
    noFlag: PropTypes.string.isRequired,
  }).isRequired,
  conversation: PropTypes.shape({
    flag: PropTypes.shape({
      name: PropTypes.string,
      class_name: PropTypes.string,
    }),
    rentals: PropTypes.arrayOf(
      PropTypes.shape({
        office: PropTypes.shape({
          enabled_conversation_flags: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              class_name: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }),
  dispatchUpdateConversation: PropTypes.func.isRequired,
};
