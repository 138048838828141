import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'ready/components/typography';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { getErrors } from 'utils';

const Div = styled.div`
  text-align: center;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const GenericError = ({ size, bold, t, ...props }) => (
  <Div {...props}>
    <StyledText
      element="p"
      variation={Text.variations.ERROR}
      size={size}
      bold={bold}
      data-qa="RequestError:Text--generic"
    >
      {t.header}
    </StyledText>
    <StyledText
      element="p"
      variation={Text.variations.ERROR}
      size={size}
      bold={bold}
      data-qa="RequestError:Text--generic"
    >
      {t.text}
    </StyledText>
  </Div>
);

GenericError.propTypes = {
  t: PropTypes.shape({
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  size: Text.propTypes.size,
  bold: PropTypes.bool,
};

const NonFieldErrors = ({ className, element, errors, size, bold, 'data-qa': dataQA }) =>
  errors.map((error, index) => (
    <StyledText
      className={className}
      element={element}
      key={index}
      variation={Text.variations.ERROR}
      size={size}
      bold={bold}
      data-qa={dataQA}
    >
      {error}
    </StyledText>
  ));

NonFieldErrors.defaultProps = {
  element: 'p',
};

const RequestError = ({ errors, size, t, bold, ...props }) => {
  const _errors = getErrors(errors);
  const keyErrors = Object.keys(_errors);

  if (!_errors || !keyErrors.length) {
    return <GenericError t={t} size={size} bold={bold} {...props} />;
  }

  if (_errors.non_field_errors) {
    if (Array.isArray(_errors.non_field_errors)) {
      return (
        <NonFieldErrors
          t={t}
          errors={_errors.non_field_errors}
          size={size}
          bold={bold}
          data-qa="RequestError:Text--nonFieldErrors"
          {...props}
        />
      );
    } else {
      return <GenericError t={t} size={size} bold={bold} {...props} />;
    }
  }

  if (_errors.detail || _errors.message)
    return (
      <StyledText
        element="p"
        variation={Text.variations.ERROR}
        size={size}
        bold={bold}
        data-qa="RequestError:Text--detail"
        {...props}
      >
        {_errors.detail || _errors.message}
      </StyledText>
    );

  if (keyErrors.length)
    return (
      <Div {...props}>
        {keyErrors.map(key => (
          <div key={key}>
            <StyledText
              variation={Text.variations.ERROR}
              size={size}
              bold={bold}
              data-qa="RequestError:Text--field"
              {...props}
            >
              {`${key}: `}
            </StyledText>
            <NonFieldErrors
              element="span"
              errors={_errors[key]}
              size={size}
              bold={bold}
              data-qa="RequestError:Text--fieldErrors"
            />
          </div>
        ))}
      </Div>
    );

  return false;
};

RequestError.sizes = Text.sizes;

RequestError.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.shape({
    non_field_errors: PropTypes.arrayOf(PropTypes.string.isRequired),
    detail: PropTypes.string,
    message: PropTypes.string,
  }),
  t: PropTypes.shape({
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  size: Text.propTypes.size,
  bold: PropTypes.bool,
  'data-qa': PropTypes.string,
};

RequestError.defaultProps = {
  size: RequestError.sizes.M,
  errors: {},
};

export default withMappedTranslations({
  header: 'SERVER_ERROR.HEADER',
  text: 'SERVER_ERROR.MESSAGES_3',
})(RequestError);
