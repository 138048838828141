import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { messagesPropType } from 'common/propTypes';

import Message from '../Message/Message';
import InfiniteScroll from 'ready/components/InfiniteScroll/InfiniteScroll';

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
  align-self: flex-end;
`;

const StyledMessage = styled(Message)`
  margin: ${props => `${props.theme.spacing.s} ${props.theme.spacing.m}`};
`;

const MessageList = ({ messages, ...props }) => (
  <StyledInfiniteScroll
    {...props}
    data-qa="MessageArea:MessageList__InfiniteScroll"
    items={messages}
    direction={InfiniteScroll.directions.BOTTOM_TO_TOP}
    renderItems={items => items.map(item => <StyledMessage key={item.id} {...props} message={item} />)}
  />
);

MessageList.propTypes = {
  messages: messagesPropType.isRequired,
  t: PropTypes.shape({
    loadMoreError: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
  }).isRequired,
};

export default MessageList;
