import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import useMobileView from 'hooks/useMobileView';
import Login from './Login/Login';
import Main from './Main/Main';
import Mobile from './Mobile/Mobile';
import withProtectedRoute from 'hoc/withProtectedRoute/withProtectedRoute';
import { setUrl } from 'ducks/view';
import { paths } from 'constants.js';
import { fetchUserByTokenRequested } from 'ducks/user';
import { splitPathname } from 'utils/location';
import history from 'browserHistory';
import Favicon from './Favicon/Favicon';

const ProtectedMobile = withProtectedRoute(Mobile);
const ProtectedMain = withProtectedRoute(Main);

const Scenes = ({ location, dispatchSetUrl, dispatchFetchUserByTokenRequested }) => {
  useEffect(() => {
    const route = splitPathname(location.pathname);
    dispatchSetUrl(
      (!!route.length && `${location.pathname}${location.search}`) ||
        (location.pathname === paths.FAVICON && paths.FAVICON) ||
        paths.APP,
    );
    dispatchFetchUserByTokenRequested();
  }, [window.location]);

  const isMobileView = useMobileView();
  const View = isMobileView ? ProtectedMobile : ProtectedMain;

  return (
    <Switch>
      <Route exact path={paths.LOGIN} render={props => <Login {...props} />} />
      <Route exact path={paths.ROOT} render={() => history.replace(paths.APP)} />
      <Route path={`${paths.APP}/:id?`} render={props => <View {...props} />} />
      <Route exact path={paths.FAVICON} render={props => <Favicon {...props} />} />
    </Switch>
  );
};

Scenes.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  dispatchSetUrl: PropTypes.func.isRequired,
  dispatchFetchUserByTokenRequested: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchSetUrl: setUrl,
  dispatchFetchUserByTokenRequested: fetchUserByTokenRequested,
};

export default connect(
  null,
  mapDispatchToProps,
)(Scenes);
