import PropTypes from 'prop-types';
import { dateTimePropType, datePropType, selectOptionPropType, timePropType } from 'ready/utils/propTypes';
import { mapKeysToLowerCase } from 'ready/utils';
import { messageTypes, checkInMissionTypes } from 'constants.js';

const withStateDataPropType = (data, props) => ({
  isFetching: PropTypes.bool.isRequired,
  didInvalidate: PropTypes.bool.isRequired,
  data,
  ...props,
});

export const userPropShape = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  email: PropTypes.string.isRequired,
};
export const userPropType = PropTypes.shape(userPropShape);

export const messagePropShape = {
  id: PropTypes.number.isRequired,
  created: dateTimePropType,
  modified: dateTimePropType.isRequired,
  conversation: PropTypes.number.isRequired,
  sender: PropTypes.oneOf(mapKeysToLowerCase(messageTypes).concat('')),
  sender_user: userPropType,
  content: PropTypes.string.isRequired,
  origin: PropTypes.string,
  origin_display: PropTypes.string,
  inquiry: PropTypes.number,
  category: PropTypes.string,
  category_display: PropTypes.string,
  channel: PropTypes.string,
};
export const messagePropType = PropTypes.shape(messagePropShape);

export const messagesPropType = PropTypes.arrayOf(messagePropType);
export const messagesPropTypeWithState = PropTypes.shape(withStateDataPropType(messagesPropType));

export const outgoingMessagePropShape = {
  conversation: PropTypes.number,
  sender: PropTypes.oneOf(mapKeysToLowerCase(messageTypes)),
  content: PropTypes.string,
  origin: PropTypes.string,
};
export const outgoingMessagePropType = PropTypes.shape(outgoingMessagePropShape);
export const outgoingMessagePropTypeWithState = PropTypes.shape({
  isSending: PropTypes.bool.isRequired,
  didInvalidate: PropTypes.bool.isRequired,
  data: PropTypes.shape(outgoingMessagePropShape),
});

export const conversationGuestPropShape = {
  id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone_number: PropTypes.string,
};
export const conversationGuestPropType = PropTypes.shape(conversationGuestPropShape);

export const guestPropShape = {
  id: PropTypes.number,
  picture_url: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone_number: PropTypes.string,
  number_of_adults: PropTypes.number,
  number_of_children: PropTypes.number,
  personal_email: PropTypes.string,
  platform_email: PropTypes.string,
  language: PropTypes.string,
  user: userPropType,
  address: PropTypes.number,
  verified: PropTypes.bool,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      comments: PropTypes.string,
      author: PropTypes.string,
    }),
  ),
  location: PropTypes.string,
};
export const guestPropType = PropTypes.shape(guestPropShape);
export const guestPropTypeWithState = PropTypes.shape(
  withStateDataPropType(guestPropType, { isUpdating: PropTypes.bool.isRequired }),
);
export const baseGuestPropTypeWithState = PropTypes.shape(
  withStateDataPropType(PropTypes.shape({ id: PropTypes.number, language: PropTypes.string.isRequired }), {
    isUpdating: PropTypes.bool.isRequired,
  }),
);

export const rentalPropShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
};
export const shortRentalPropType = PropTypes.shape(rentalPropShape);

export const categoryPropShape = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number,
};
export const categoryPropType = PropTypes.shape(categoryPropShape);
export const filtersConfigPropType = PropTypes.shape({
  categories: PropTypes.arrayOf(categoryPropType),
  cities: PropTypes.arrayOf(selectOptionPropType),
  languages: PropTypes.arrayOf(selectOptionPropType),
  reservationStatuses: PropTypes.arrayOf(selectOptionPropType),
  inquiryStatuses: PropTypes.arrayOf(selectOptionPropType),
  reservationPlatforms: PropTypes.arrayOf(selectOptionPropType),
});
export const filtersPropTypeWithState = PropTypes.shape(
  withStateDataPropType(PropTypes.object, { config: filtersConfigPropType }),
);

export const conversationPropShape = {
  id: PropTypes.number.isRequired,
  created: PropTypes.string,
  modified: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  guest: guestPropType,
  rentals: PropTypes.arrayOf(shortRentalPropType).isRequired,
  reservations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      checkin_date: datePropType.isRequired,
    }),
  ),
  inquiries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      checkin_date: datePropType.isRequired,
    }),
  ),
  last_message: messagePropType,
  default_channel: PropTypes.string,
  assignees: PropTypes.arrayOf(PropTypes.number).isRequired,
  l2_support: PropTypes.bool.isRequired,
  automatic_ai_enabled: PropTypes.bool.isRequired,
};
export const conversationPropType = PropTypes.shape(conversationPropShape);
export const conversationPropTypeWithState = PropTypes.shape(
  withStateDataPropType(conversationPropType, { isUpdating: PropTypes.bool.isRequired }),
);

export const conversationsPropType = PropTypes.arrayOf(conversationPropType);
export const conversationsPropShape = withStateDataPropType(conversationsPropType, {
  sort: PropTypes.shape({ value: PropTypes.string }),
});
export const conversationsPropTypeWithState = PropTypes.shape(conversationsPropShape);

export const reservationPropShape = {
  id: PropTypes.number.isRequired,
  checkin_date: datePropType.isRequired,
  checkout_date: datePropType.isRequired,
  guest_checkin_at: timePropType,
  guest_checkout_at: timePropType,
  price: PropTypes.number,
  platform_name: PropTypes.string,
  duration: PropTypes.number,
  currency: PropTypes.string,
  currency_symbol: PropTypes.string,
  detail_url: PropTypes.string,
  platform_url: PropTypes.string,
  guest_comment: PropTypes.string,
};
export const reservationPropType = PropTypes.shape(reservationPropShape);
export const reservationPropTypeWithState = PropTypes.shape(withStateDataPropType(reservationPropType));

export const reservationsPropType = PropTypes.arrayOf(reservationPropType);
export const reservationsPropTypeWithState = PropTypes.shape(withStateDataPropType(reservationsPropType));

export const inquiryPropShape = {
  id: PropTypes.number.isRequired,
  countdown_at: dateTimePropType,
  expires_at: dateTimePropType,
  updated_at: dateTimePropType,
  checkin_date: datePropType,
  checkout_date: datePropType,
  rental: shortRentalPropType,
  native_currency: PropTypes.string,
  native_price: PropTypes.number,
  number_of_guests: PropTypes.number,
  reservation: reservationPropType,
  guest: guestPropType,
  status: PropTypes.string,
  special_offer: PropTypes.shape({
    id: PropTypes.number,
    inquiry: PropTypes.number,
    price: PropTypes.number,
    start_date: PropTypes.string,
    expires_at: PropTypes.string,
  }),
};
export const inquiryPropType = PropTypes.shape(inquiryPropShape);

export const bookingPropType = PropTypes.oneOfType([reservationPropType, inquiryPropType]);
export const bookingPropTypeWithState = PropTypes.shape(withStateDataPropType(bookingPropType));

export const childrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

export const matchPropType = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
});

export const savedReplyPropShape = {
  id: PropTypes.number.isRequired,
  created: dateTimePropType,
  modified: dateTimePropType,
  templated: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  click_count: PropTypes.number,
  name: PropTypes.string.isRequired,
};
export const savedReplyPropType = PropTypes.shape(savedReplyPropShape);
export const savedRepliesPropType = PropTypes.arrayOf(savedReplyPropType);
export const savedRepliesPropTypeWithState = PropTypes.shape(withStateDataPropType(savedRepliesPropType));

export const missionPropShape = {
  id: PropTypes.number.isRequired,
  agent_full_name: PropTypes.string,
  agent_phone_number: PropTypes.string,
  checklist_status: PropTypes.string,
  checklist_url: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(checkInMissionTypes)),
};
export const missionPropType = PropTypes.shape(missionPropShape);

export const callReasonPropShape = {
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  content_en: PropTypes.string.isRequired,
};
export const callReasonPropType = PropTypes.shape(callReasonPropShape);
export const callReasonsPropType = PropTypes.arrayOf(callReasonPropType);

export const callCenterPropShape = {
  isFetching: PropTypes.bool,
  didInvalidate: PropTypes.bool,
  taskRouter: PropTypes.shape({
    activities: PropTypes.object,
    status: PropTypes.string,
    errors: PropTypes.object,
  }),
  device: PropTypes.shape({
    status: PropTypes.string,
    errors: PropTypes.object,
  }),
  connection: PropTypes.shape({
    status: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    startedAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    isMuted: PropTypes.bool,
    errors: PropTypes.object,
  }),
  callForwarding: PropTypes.shape({
    workers: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
        friendlyName: PropTypes.string.isRequired,
      }),
    ),
    status: PropTypes.string,
    target: PropTypes.shape({
      friendlyName: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }),
  }),
  view: PropTypes.shape({
    status: PropTypes.string,
  }),
  data: PropTypes.shape({
    note: PropTypes.string,
    isNoteSubmitted: PropTypes.bool,
    conversation: conversationPropType,
    guest: guestPropType,
    category: PropTypes.string,
  }),
  config: PropTypes.shape({
    callReasons: callReasonsPropType,
  }),
  errors: PropTypes.object,
};
export const callCenterPropType = PropTypes.shape(callCenterPropShape);

export const specialOfferPropShape = {
  isFetching: PropTypes.bool,
  didInvalidate: PropTypes.bool,
  form: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visible: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.object,
};
export const specialOfferPropType = PropTypes.shape(specialOfferPropShape);

export const bedPropShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bed_type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};
export const bedroomPropShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bedroom_type: PropTypes.string.isRequired,
  window_coverage: PropTypes.string,
  universal_adapter: PropTypes.bool.isRequired,
  bed_set: PropTypes.arrayOf(PropTypes.shape(bedPropShape)).isRequired,
  label: PropTypes.string.isRequired,
};
export const bathroomPropShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hair_dryer: PropTypes.bool.isRequired,
  shower: PropTypes.bool.isRequired,
  bath: PropTypes.bool.isRequired,
  jacuzzi: PropTypes.bool.isRequired,
  spa_bath: PropTypes.bool.isRequired,
  mirror: PropTypes.bool.isRequired,
  toothbrush_holder: PropTypes.bool.isRequired,
  wastebasket: PropTypes.bool.isRequired,
  towel_warmer: PropTypes.bool.isRequired,
  toilet: PropTypes.bool.isRequired,
};
export const officePropShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  property_manager: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    logo_white: PropTypes.string,
  }),
  guest_id_airbnb_policy: PropTypes.string,
  min_check_in_time: PropTypes.number,
  max_check_in_time: PropTypes.number,
  reviewed_id_as_trigger_for_sci: PropTypes.bool,
};
export const officePoliciesPropShape = {
  check_in_process_comment: PropTypes.string,
  check_out_process_comment: PropTypes.string,
  luggage_drop_policy: PropTypes.string,
  guest_vetting_process: PropTypes.string,
  security_deposit_guest_id_process: PropTypes.string,
  discount_policy: PropTypes.string,
  instay_cleaning_price: PropTypes.string,
  pets_policy: PropTypes.string,
  emergency_contact: PropTypes.string,
  extra_information: PropTypes.string,
  special_requests: PropTypes.string,
  events_policy: PropTypes.string,
};
export const officePoliciesPropType = PropTypes.shape(officePoliciesPropShape);
export const futureReservationPropShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  checkin_date: datePropType,
  checkout_date: datePropType,
  type_reservation: PropTypes.string,
  rental_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
export const fullRentalPropShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  bedroom_set: PropTypes.arrayOf(PropTypes.shape(bedroomPropShape)),
  bathroom_set: PropTypes.arrayOf(PropTypes.shape(bathroomPropShape)),
  detail_url: PropTypes.string,
  address: PropTypes.string,
  office: PropTypes.shape(officePropShape),
  office_policies: PropTypes.shape(officePoliciesPropShape),
  max_number_of_guests: PropTypes.number,
  indication_mailbox_bnblord: PropTypes.string,
  indication_pre_approval: PropTypes.string,
  premium_class: PropTypes.string,
  future_reservations: PropTypes.arrayOf(PropTypes.shape(futureReservationPropShape)),
  position: PropTypes.string,
  postal_code: PropTypes.string,
  floor: PropTypes.number,
  surface_area: PropTypes.number,
  metro_station: PropTypes.string,
  directions: PropTypes.string,
  access_code_1: PropTypes.string,
  access_code_2: PropTypes.string,
  wifi_name: PropTypes.string,
  wifi_password: PropTypes.string,
  parking_directions: PropTypes.string,
  is_private_parking: PropTypes.bool,
  breaker_location: PropTypes.string,
  water_location: PropTypes.string,
  trash_location: PropTypes.string,
  min_check_in_time: timePropType,
  max_check_in_time: timePropType,
  check_out_time: timePropType,
  checkout_instructions: PropTypes.string,
  checkin_instructions: PropTypes.string,
  key_deposit_instructions: PropTypes.string,
  cleaning_key_deposit_instructions: PropTypes.string,
  cleaning_instructions: PropTypes.string,
  default_agent_cleaning: PropTypes.string,
  indication_poi_view: PropTypes.string,
};
export const fullRentalPropType = PropTypes.shape(fullRentalPropShape);
export const fullRentalPropTypeWithState = PropTypes.shape(withStateDataPropType(fullRentalPropType));
export const smartDevicePropShape = {
  id: PropTypes.number,
  name: PropTypes.string,
  capabilities_supported: PropTypes.string,
  locked: PropTypes.bool,
  online: PropTypes.bool,
};
export const smartDevicePropType = PropTypes.shape(smartDevicePropShape);
export const smartDevicesPropType = PropTypes.arrayOf(smartDevicePropType);
