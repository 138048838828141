import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ContentWrapper } from '../../../../components';
import PreviousCleaningForm from './components/PreviousCleaningForm';
import { Collapsible } from 'ready/components';

import { bookingPropTypeWithState } from 'common/propTypes';
import { contexts } from 'constants.js';
import { hasErrors } from 'utils';

const Wrapper = styled(ContentWrapper)`
  min-height: 15rem;
  overflow-y: auto;
`;

const PreviousCleaning = ({ t, booking }) => {
  if (!booking.data || !booking.data.previous_cleaning_mission || !booking.data.previous_cleaning_mission.id)
    return false;

  const previousCleaning = {
    ...booking.data.previous_cleaning_mission,
  };

  return (
    <Collapsible
      title={t.previousCleaningTitle}
      data-qa="Tab:Collapsible--cleaningMission"
      autoResize={!booking.isUpdating}
    >
      <Wrapper data-qa="Cleaning:__wrapper">
        <PreviousCleaningForm
          t={t}
          previousCleaning={previousCleaning}
          hasError={hasErrors(booking.errors, contexts.infoSidebar.BOOKING)}
        />
      </Wrapper>
    </Collapsible>
  );
};

PreviousCleaning.propTypes = {
  booking: bookingPropTypeWithState.isRequired,
  t: PropTypes.shape({
    agentName: PropTypes.string.isRequired,
    agentPhoneNumber: PropTypes.string.isRequired,
    missionID: PropTypes.string.isRequired,
    checklistStatus: PropTypes.string.isRequired,
    checklistUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default PreviousCleaning;
