import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { variations } from 'ready/utils/constants';
import { mapKeysToLowerCase } from 'ready/utils';
import { variationPropType } from 'ready/utils/propTypes';
import { withThemeColor } from 'ready/styles/helpers';

const withCenteredHorizontally = ({ position }) =>
  [Tooltip.positions.TOP, Tooltip.positions.BOTTOM].indexOf(position) > -1 &&
  css`
    left: 50%;
    transform: translateX(-50%);
  `;

const withCenteredVertically = ({ position }) =>
  [Tooltip.positions.RIGHT, Tooltip.positions.LEFT].indexOf(position) > -1 &&
  css`
    top: 50%;
    transform: translateY(-50%);
  `;

const withTooltipBottomArrow = ({ position }) =>
  position === Tooltip.positions.TOP &&
  css`
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    bottom: -0.6rem;
  `;

const withTooltipTopArrow = ({ position }) =>
  position === Tooltip.positions.BOTTOM &&
  css`
    left: 50%;
    transform: translateX(-50%) rotate(225deg);
    top: -0.6rem;
  `;

const withTooltipLeftArrow = ({ position }) =>
  position === Tooltip.positions.RIGHT &&
  css`
    top: 50%;
    left: -0.6rem;
    transform: translateY(-50%) rotate(-225deg);
  `;

const withTooltipRightArrow = ({ position }) =>
  position === Tooltip.positions.LEFT &&
  css`
    top: 50%;
    right: -0.6rem;
    transform: translateY(-50%) rotate(-45deg);
  `;

const withTooltipArrow = ({ theme, ...props }) => css`
  content: '';
  background-color: ${withThemeColor(props)};
  height: ${theme.spacing.xs};
  position: absolute;
  width: ${theme.spacing.xs};
  box-shadow: 0.2rem 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.16);
`;

const withBaseStyle = ({ theme, center, ...props }) => css`
  background-color: ${withThemeColor(props)};
  border: ${theme.spacing.xxxs} solid ${withThemeColor(props)};
  border-radius: ${theme.borderRadius.m};
  box-shadow: 0 0 0.4rem 0.2rem rgba(0, 0, 0, 0.16);
  display: inline-block;
  max-width: 11.5rem;
  min-width: 8.5rem;
  width: max-content;
  padding: 0 ${theme.spacing.xxs};
  position: absolute;
  text-align: center;
  z-index: ${theme.zIndex.tooltip};
  ${bounds[props.position]}: calc(100% + ${theme.spacing.xs});
  ${props => center && (withCenteredHorizontally(props) || withCenteredVertically(props))}
`;

const Tooltip = styled.div`
  ${withBaseStyle};
  &::after {
    ${withTooltipArrow};
    ${withTooltipBottomArrow};
    ${withTooltipTopArrow};
    ${withTooltipLeftArrow};
    ${withTooltipRightArrow};
  }
`;

Tooltip.positions = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

const bounds = {
  [Tooltip.positions.TOP]: Tooltip.positions.BOTTOM,
  [Tooltip.positions.BOTTOM]: Tooltip.positions.TOP,
  [Tooltip.positions.LEFT]: Tooltip.positions.RIGHT,
  [Tooltip.positions.RIGHT]: Tooltip.positions.LEFT,
};

Tooltip.defaultProps = {
  position: Tooltip.positions.TOP,
  variation: variations.BLUE,
  center: true,
};

Tooltip.variations = variations;

Tooltip.propTypes = {
  position: PropTypes.oneOf(mapKeysToLowerCase(Tooltip.positions)),
  variation: variationPropType,
  center: PropTypes.bool.isRequired,
};

export default Tooltip;
