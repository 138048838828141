import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../../components';
import Bedrooms from '../../../../components/Bedrooms';

import { Collapsible, DateRangeCalendar } from 'ready/components';
import { replaceInUrl } from 'ready/utils';
import { TextOrLink, PremiumClassTag, SelfCheckInTag, OfficeTag } from 'components';

import { bookingPropType } from 'common/propTypes';

import { getRentalName, getRentalArea, getRentalOfficeName, getFutureReservationsDateRanges } from 'utils/serializers';
import { links, reservationCalendarDateRangeVariations } from 'constants.js';

const Wrapper = styled(ContentWrapper)`
  min-height: 26.2rem;
`;

const TagGroup = styled.div`
  align-items: center;
  display: flex;
  margin: ${props => `-${props.theme.spacing.xxxs}`};
  flex-wrap: wrap;
  & > * {
    margin: ${props => props.theme.spacing.xxxs};
  }
`;

const StyledDateRangeCalendar = styled(DateRangeCalendar)`
  margin-bottom: ${props => props.theme.spacing.l};
  height: 20rem;
  width: 100%;
`;

const Property = ({ booking, t }) => (
  <Collapsible title={t.propertyTitle} data-qa="Tab:Collapsible--property">
    <Wrapper>
      <PropertyContent booking={booking} t={t} />
    </Wrapper>
  </Collapsible>
);

Property.propTypes = {
  booking: bookingPropType.isRequired,
  t: PropTypes.shape({
    propertyTitle: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    numberOfBedrooms: PropTypes.string.isRequired,
  }).isRequired,
};

const PropertyContent = ({ booking, t }) => {
  const property = booking.rental;
  const propertyPath = replaceInUrl({
    url: links.PROPERTY,
    placeholder: '{id}',
    value: property.id,
  });
  const propertyName = getRentalName(property);
  const propertyArea = getRentalArea(property);
  const propertyOfficeName = getRentalOfficeName(property);
  const dateRanges = getFutureReservationsDateRanges(property.future_reservations);
  return (
    <>
      <FullWidthContentWithLabel label={t.propertyName}>
        {propertyName && (
          <TextOrLink target="_blank" href={propertyPath} data-qa="Property:TextOrLink--propertyName">
            {propertyName}
          </TextOrLink>
        )}
        <TagGroup>
          <OfficeTag office={propertyOfficeName} />
          <PremiumClassTag premiumClass={(property || {}).premium_class} />
          <SelfCheckInTag
            selfCheckIn={(property || {}).self_check_in}
            t={{
              label: t.selfCheckInLabel,
              tooltip: t.selfCheckInTooltip,
            }}
          />
        </TagGroup>
      </FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.address}>{property.address}</FullWidthContentWithLabel>
      <ContentWithLabel label={t.size}>{propertyArea}</ContentWithLabel>
      <ContentWithLabel label={t.floor}>{property.floor}</ContentWithLabel>
      <FullWidthContentWithLabel label={t.numberOfBedrooms}>{property.bedroom_set.length}</FullWidthContentWithLabel>
      <Bedrooms bedrooms={property.bedroom_set} />
      <StyledDateRangeCalendar dateRanges={dateRanges} dateRangeVariations={reservationCalendarDateRangeVariations} />
    </>
  );
};

PropertyContent.propTypes = Property.propTypes;

export default Property;
