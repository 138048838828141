import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { childrenPropType, sizePropType } from 'ready/utils/propTypes';

import Tooltip from 'ready/components/Tooltip/Tooltip';
import { Text } from 'ready/components/typography';
import { SIZES } from '../constants';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: ${props => props.theme.zIndex.tooltip};
`;

const HintWrapper = ({
  children,
  text,
  position,
  onClick,
  onMouseEnter,
  onMouseLeave,
  size,
  show,
  disabled,
  enabled,
}) => {
  const [visibility, setVisibility] = useState(show);

  useEffect(() => {
    setVisibility(show);
  }, [show]);

  const handleClick = e => {
    setVisibility(false);
    !disabled && onClick && onClick(e);
    e.stopPropagation();
  };

  const handleMouseEnter = e => {
    setVisibility(true);
    onMouseEnter && onMouseEnter(e);
  };

  const handleMouseLeave = e => {
    setVisibility(false);
    onMouseLeave && onMouseLeave(e);
  };

  return (
    <Wrapper
      data-qa="[ready]Button:HintWrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {enabled && visibility && text && (
        <Tooltip data-qa="[ready]Button:Hint" variation={Tooltip.variations.WHITE} position={position}>
          <Text size={SIZES[size].hint}>{text}</Text>
        </Tooltip>
      )}
      {children}
    </Wrapper>
  );
};

HintWrapper.positions = Tooltip.positions;

HintWrapper.propTypes = {
  children: childrenPropType,
  text: PropTypes.string,
  position: Tooltip.propTypes.position,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  size: sizePropType(Text.sizes),
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
};

HintWrapper.defaultProps = {
  position: Tooltip.positions.BOTTOM,
  show: false,
  enabled: true,
};

export default HintWrapper;
