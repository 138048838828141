import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContentWithLabel, { FullWidthContentWithLabel } from 'components/ContentWithLabel/ContentWithLabel';
import { ContentWrapper } from '../../../components';

import { Button, Collapsible } from 'ready/components';
import { replaceInUrl } from 'ready/utils';
import { TextOrLink, PremiumClassTag, SelfCheckInTag } from 'components';

import { fullRentalPropType } from 'common/propTypes';

import { getRentalName, getRentalArea } from 'utils/serializers';
import { links } from 'constants.js';

const TagGroup = styled.div`
  align-items: center;
  display: flex;
  margin: ${props => `${props.theme.spacing.xxs} -${props.theme.spacing.xxxs} 0`};
  & > * {
    margin: 0 ${props => props.theme.spacing.xxxs};
  }
`;

const PdfDownloadButton = styled(Button)`
  margin-top: ${props => props.theme.spacing.xs};
`;

const General = ({ property, t }) => (
  <Collapsible title={t.generalTitle} data-qa="Tab:Collapsible--general" collapsed>
    <ContentWrapper minHeight="49.6rem">
      <GeneralContent property={property} t={t} />
    </ContentWrapper>
  </Collapsible>
);

General.propTypes = {
  property: fullRentalPropType.isRequired,
  t: PropTypes.shape({
    generalTitle: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    numberOfBedrooms: PropTypes.string.isRequired,
    maxGuestsNumber: PropTypes.string.isRequired,
    closestStation: PropTypes.string.isRequired,
    welcomePickupsUrl: PropTypes.string.isRequired,
    directions: PropTypes.string.isRequired,
    accessCode1: PropTypes.string.isRequired,
    accessCode2: PropTypes.string.isRequired,
    selfCheckInLabel: PropTypes.string.isRequired,
    selfCheckInTooltip: PropTypes.string.isRequired,
  }).isRequired,
};

const GeneralContent = ({ property, t }) => {
  const propertyPath = replaceInUrl({
    url: links.PROPERTY,
    placeholder: '{id}',
    value: property.id,
  });
  const propertyName = getRentalName(property);
  const propertyArea = getRentalArea(property);
  return (
    <>
      <FullWidthContentWithLabel label={t.propertyName}>
        {propertyName && (
          <TextOrLink target="_blank" href={propertyPath} data-qa="General:TextOrLink--propertyName">
            {propertyName}
          </TextOrLink>
        )}
        <TagGroup>
          <PremiumClassTag premiumClass={(property || {}).premium_class} />
          <SelfCheckInTag
            selfCheckIn={(property || {}).self_check_in}
            t={{
              label: t.selfCheckInLabel,
              tooltip: t.selfCheckInTooltip,
            }}
          />
        </TagGroup>
      </FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.propertyAddress}>{property.address}</FullWidthContentWithLabel>
      <iframe
        title="property map location"
        width="100%"
        height="auto"
        frameBorder="0"
        src={`${process.env.REACT_APP_GOOGLE_MAPS_URL}?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${
          property.position
        }`}
        allowFullScreen
        style={{ marginBottom: '2rem' }}
      />
      <ContentWithLabel label={t.floor}>{property.floor}</ContentWithLabel>
      <ContentWithLabel label={t.size}>{propertyArea}</ContentWithLabel>
      <ContentWithLabel label={t.maxGuestsNumber}>{property.max_number_of_guests}</ContentWithLabel>
      <ContentWithLabel label={t.numberOfBedrooms}>{property.bedroom_set.length}</ContentWithLabel>
      <FullWidthContentWithLabel label={t.closestStation}>{property.metro_station}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.welcomePickupsUrl}>{property.taxi_url}</FullWidthContentWithLabel>
      <FullWidthContentWithLabel label={t.directions}>{property.directions}</FullWidthContentWithLabel>
      <ContentWithLabel label={t.accessCode1}>{property.access_code_1}</ContentWithLabel>
      <ContentWithLabel label={t.accessCode2}>{property.access_code_2}</ContentWithLabel>
      {property.house_manual && (
        <ContentWithLabel label={t.pdfDownloadLabel}>
          <PdfDownloadButton
            onClick={() => window.open(`${process.env.REACT_APP_BASE_API_URL}/staffing/house_manual/${property.uuid}/`)}
          >
            {t.pdfDownloadButton}
          </PdfDownloadButton>
        </ContentWithLabel>
      )}
      {property.midterm_house_manual && (
        <ContentWithLabel label={t.pdfDownloadLabelMidTerm}>
          <PdfDownloadButton
            onClick={() =>
              window.open(`${process.env.REACT_APP_BASE_API_URL}/staffing/midterm_house_manual/${property.uuid}/`)
            }
          >
            {t.pdfDownloadButton}
          </PdfDownloadButton>
        </ContentWithLabel>
      )}
    </>
  );
};

GeneralContent.propTypes = General.propTypes;

export default General;
