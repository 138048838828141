import PropTypes from 'prop-types';
import { sizePropType } from 'ready/utils/propTypes';
import { sizes } from 'ready/utils/constants';

export const notificationSizePropType = sizePropType(sizes);

export const _variationPropType = PropTypes.shape({
  icon: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
});

export const _sizePropType = PropTypes.shape({
  icon: notificationSizePropType,
  text: notificationSizePropType,
  close: notificationSizePropType,
});
