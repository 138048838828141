import { fromJS } from 'immutable';

export const CHANGE_IS_CONVERSATION_DRAWER_OPEN = 'CHANGE_IS_CONVERSATION_DRAWER_OPEN';
export const changeIsConversationDrawerOpen = value => ({
  type: CHANGE_IS_CONVERSATION_DRAWER_OPEN,
  payload: { value },
});

export const CLOSE_CONVERSATION_DRAWER = 'CLOSE_CONVERSATION_DRAWER';
export const closeConversationDrawer = () => ({
  type: CLOSE_CONVERSATION_DRAWER,
});

export const OPEN_RESERVATION_ALTERATION_CREATE_FORM = 'OPEN_RESERVATION_ALTERATION_CREATE_FORM';
export const openReservationAlterationCreateForm = id => ({
  type: OPEN_RESERVATION_ALTERATION_CREATE_FORM,
  payload: { id },
});

export const OPEN_RESERVATION_ALTERATION_EDIT_FORM = 'OPEN_RESERVATION_ALTERATION_EDIT_FORM';
export const openReservationAlterationEditForm = id => ({
  type: OPEN_RESERVATION_ALTERATION_EDIT_FORM,
  payload: { id },
});

export const CHANGE_SHOW_ACCEPT_ALTERATION_MODAL = 'CHANGE_SHOW_ACCEPT_ALTERATION_MODAL';
export const changeShowAcceptAlterationModal = value => ({
  type: CHANGE_SHOW_ACCEPT_ALTERATION_MODAL,
  payload: { value },
});

export const CHANGE_SHOW_REJECT_ALTERATION_MODAL = 'CHANGE_SHOW_REJECT_ALTERATION_MODAL';
export const changeShowRejectAlterationModal = value => ({
  type: CHANGE_SHOW_REJECT_ALTERATION_MODAL,
  payload: { value },
});

export const CHANGE_SHOW_PROPOSE_ALTERATION_MODAL = 'CHANGE_SHOW_PROPOSE_ALTERATION_MODAL';
export const changeShowProposeAlterationModal = value => ({
  type: CHANGE_SHOW_PROPOSE_ALTERATION_MODAL,
  payload: { value },
});

export const OPEN_SPECIAL_OFFER_CREATE_FORM = 'OPEN_SPECIAL_OFFER_CREATE_FORM';
export const openSpecialOfferCreateForm = id => ({
  type: OPEN_SPECIAL_OFFER_CREATE_FORM,
  payload: { id },
});

export const initialState = fromJS({
  isConversationDrawerOpen: false,
  reservationAlteration: null,
  booking: null,
  inquiry: null,
  showAcceptAlterationModal: false,
  showRejectAlterationModal: false,
  showProposeAlterationModal: false,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLOSE_CONVERSATION_DRAWER:
      return state
        .set('isConversationDrawerOpen', false)
        .set('booking', null)
        .set('inquiry', null)
        .set('reservationAlteration', null);
    case CHANGE_IS_CONVERSATION_DRAWER_OPEN:
      return state.update('isConversationDrawerOpen', updateBoolValue.bind(null, payload));
    case OPEN_RESERVATION_ALTERATION_CREATE_FORM:
      return state
        .set('isConversationDrawerOpen', true)
        .set('reservationAlteration', null)
        .set('booking', payload.id)
        .set('inquiry', null);
    case OPEN_RESERVATION_ALTERATION_EDIT_FORM:
      return state
        .set('isConversationDrawerOpen', true)
        .set('reservationAlteration', payload.id)
        .set('booking', null)
        .set('inquiry', null);
    case OPEN_SPECIAL_OFFER_CREATE_FORM:
      return state
        .set('isConversationDrawerOpen', true)
        .set('booking', null)
        .set('reservationAlteration', null)
        .set('inquiry', payload.id);
    case CHANGE_SHOW_ACCEPT_ALTERATION_MODAL:
      return state.update('showAcceptAlterationModal', updateBoolValue.bind(null, payload));
    case CHANGE_SHOW_REJECT_ALTERATION_MODAL:
      return state.update('showRejectAlterationModal', updateBoolValue.bind(null, payload));
    case CHANGE_SHOW_PROPOSE_ALTERATION_MODAL:
      return state.update('showProposeAlterationModal', updateBoolValue.bind(null, payload));
    default:
      return state;
  }
};

const updateBoolValue = (payload, value) => (typeof payload.value === 'boolean' ? payload.value : !value);
